import { useRouter } from "next/router";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { debounce } from "lodash";
import {
  DraftScenarioStatus,
  Exact,
  GetDraftAssetsQuery,
  GetDraftScenarioByIdQuery,
  GetEvaluatedDraftPicksQuery,
  useDraftScenarioUpdatedSubscription,
  useGetDraftAssetsQuery,
  useGetDraftLastUpdatedQuery,
  useGetDraftScenarioByIdQuery,
  useGetEvaluatedDraftPicksQuery,
} from "../../../graphql/generated/graphql";
import React from "react";
import { QueryResult } from "@apollo/client";

export const DDBQueryContext = createContext<{
  isSimulation: boolean;
  draftId: string;
  loading: boolean;
  allInSync: boolean;
  draftComplete: boolean;
  queryResult?: QueryResult<
    GetDraftScenarioByIdQuery,
    Exact<{
      id: string;
    }>
  >;
  draftScenario: GetDraftScenarioByIdQuery["draftScenarioById"] | undefined;
  allDraftAssets?:
    | GetDraftAssetsQuery["draftScenarioById"]["allDraftAssets"]
    | undefined;
  clubDraftAssets?:
    | GetDraftAssetsQuery["draftScenarioById"]["clubDraftAssets"]
    | undefined;
  assetsQueryResult?: QueryResult<
    GetDraftAssetsQuery,
    Exact<{
      draftId: string;
    }>
  >;
  // clubDraftablePlayersAtNextPick?:
  //   | GetClubDraftablePlayersAtNextPickQuery["draftScenarioById"]["clubDraftablePlayersAtNextPick"]
  //   | undefined;
  evaluatedDraftPicks?:
    | GetEvaluatedDraftPicksQuery["draftScenarioById"]["evaluatedDraftPicks"]
    | undefined;
}>({
  isSimulation: false,
  draftId: "no-id",
  loading: false,
  draftScenario: undefined,
  allInSync: false,
  draftComplete: false,
});

export const DraftDashboardQueryProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // Debugging cache interactions
  // const client = useApolloClient();
  // console.log(client.extract());
  const router = useRouter();
  const { id, sim } = router.query;
  const draftId: string = id?.toString() || "no-id";
  const noDraftId = id === undefined || draftId === "no-id";

  const isSimulation = sim === "true";

  /**
   * Setup some query to poll for last updated / aggregate versions
   * Use these fields to determine when to re-poll the deeper state
   */
  const lastUpdatedQuery = useGetDraftLastUpdatedQuery({
    fetchPolicy: "no-cache",
    pollInterval: 5000,
    skip: noDraftId,
    variables: {
      draftId,
    },
  });

  const draftScenarioQuery = useGetDraftScenarioByIdQuery({
    variables: {
      id: draftId,
    },
    skip: noDraftId,
  });

  const draftAssetsQuery = useGetDraftAssetsQuery({
    variables: {
      draftId,
    },
    skip: noDraftId,
  });

  const evaluatedDraftPicksQuery = useGetEvaluatedDraftPicksQuery({
    variables: {
      draftId,
    },
    skip: noDraftId,
  });

  const draftSubscription = useDraftScenarioUpdatedSubscription({
    skip: !id,
    variables: {
      id: id?.toString() || "no-id",
    },
    onComplete: () => {
      console.log("onComplete");
    },
    onSubscriptionData: (data) => {
      console.log("onSubscriptionData", data);
    },
    onError: (error) => {
      console.log("onError", error);
    },
    onSubscriptionComplete: () => {
      console.log("onSubscriptionComplete");
    },
    onData: (data) => {
      console.log(`${new Date()} subscription-onData`, data);
      const subscriptionVersion =
        data.data.data?.draftScenarioUpdatesById.version;
      if (!subscriptionVersion) return;

      if (subscriptionVersion > (pollVersion || -1)) {
        console.log(`subscription-refetch`);
        refetchDraftAssets();
        refetchEvaluatedDraftPicks();
      }
    },
  });

  const subscriptionVersion =
    draftSubscription.data?.draftScenarioUpdatesById.version;
  const pollVersion = lastUpdatedQuery.data?.draftScenarioById.version;
  const isPollVersionNewer = (pollVersion ?? -1) > (subscriptionVersion ?? -1);

  const draftScenario = isPollVersionNewer
    ? draftScenarioQuery.data?.draftScenarioById
    : draftSubscription.data?.draftScenarioUpdatesById;

  // These MUST be pickled out -- the refetch has referential stability even though
  // the containing object does not and this is very important for deps arrays
  const refetchLastUpdated = lastUpdatedQuery.refetch;
  const refetchDraftScenario = draftScenarioQuery.refetch;
  const refetchDraftAssets = draftAssetsQuery.refetch;
  const refetchEvaluatedDraftPicks = evaluatedDraftPicksQuery.refetch;

  const refreshAllQueries = useMemo(() => {
    return debounce(() => {
      console.log(`executing refresh`);
      refetchDraftScenario();
      refetchLastUpdated();
      refetchDraftAssets();
      refetchEvaluatedDraftPicks();
    }, 500);
  }, [
    refetchDraftScenario,
    refetchDraftAssets,
    refetchEvaluatedDraftPicks,
    refetchLastUpdated,
  ]);

  // Causes queries to refresh if we detect a new aggregate version from our polling
  useEffect(() => {
    if (id && isPollVersionNewer) {
      console.log(`triggered-refetch`);
      refreshAllQueries();
    }
  }, [
    draftScenarioQuery.data?.draftScenarioById.version,
    id,
    isPollVersionNewer,
    refreshAllQueries,
  ]);

  const latestVersion = Math.max(pollVersion ?? -1, subscriptionVersion ?? -1);
  const allInSync =
    latestVersion === draftScenarioQuery.data?.draftScenarioById.version &&
    latestVersion === draftAssetsQuery.data?.draftScenarioById.version &&
    latestVersion === evaluatedDraftPicksQuery.data?.draftScenarioById.version;

  const modelsAreRunning =
    lastUpdatedQuery.data?.draftScenarioById.modelRunStatus === "STALE";

  const loading = draftScenarioQuery.loading || modelsAreRunning;

  // Only for deep debugging
  // console.log({
  //   loading,
  //   allInSync,
  //   modelsAreRunning,
  //   latestVersion,
  //   lastUpdatedQueryNetwork: lastUpdatedQuery.networkStatus,
  //   queryResultVersion: draftScenarioQuery.data?.draftScenarioById.version,
  //   assetsQueryResultVersion: draftAssetsQuery.data?.draftScenarioById.version,
  //   evalPicksResultVersion:
  //     evaluatedDraftPicksQuery.data?.draftScenarioById.version,
  //   latestMRS: lastUpdatedQuery.data?.draftScenarioById.modelRunStatus,
  //   queryResultMRS: draftScenarioQuery.data?.draftScenarioById.modelRunStatus,
  // });

  return (
    <DDBQueryContext.Provider
      value={{
        isSimulation,
        loading,
        allInSync,
        draftId,
        draftScenario: draftScenario,
        allDraftAssets: draftAssetsQuery.data?.draftScenarioById.allDraftAssets,
        clubDraftAssets:
          draftAssetsQuery.data?.draftScenarioById.clubDraftAssets,
        evaluatedDraftPicks:
          evaluatedDraftPicksQuery.data?.draftScenarioById.evaluatedDraftPicks,
        draftComplete: draftScenario?.status === DraftScenarioStatus.COMPLETE,
      }}
    >
      {children}
    </DDBQueryContext.Provider>
  );
};

export const useDraftDashboard = () => useContext(DDBQueryContext);
