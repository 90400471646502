import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import {
  Box,
  Button,
  Fade,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import Head from "next/head";
import { NavBar } from "./NavBar";
import { clientConfig } from "../../config/config";
import SimIcon from "../../public/sim_icon.svg";
import SimFootball from "../../public/sim_football.svg";
import { ClubThemedHeader } from "../draft/home/DraftTypeSelection";

export const PageContainer = (props: {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}) => {
  const theme = useTheme();
  const { instance } = useMsal();

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <>
          <NavBar />
          <Fade in>
            <SignInPage>
              <Head>
                <title>Sign In</title>
                <meta
                  name="description"
                  content="Sign-in page for Draft Dashboard Home"
                />
              </Head>
              <Stack
                borderRadius="4px"
                flex={1}
                mt={10}
                ml={10}
                mr={10}
                bgcolor={theme.palette.common.white}
                boxShadow={`8px 8px 10px 0px ${theme.palette.grey[300]}`}
              >
                <ClubThemedHeader
                  icon={SimIcon}
                  image={SimFootball}
                  text={"Sign-In"}
                />
                <Box pb={5}>
                  <Stack padding={5}>
                    <Typography
                      fontSize="1.5em"
                      fontWeight="bold"
                      color={theme.palette.grey[800]}
                    >
                      Sign-In
                    </Typography>
                    <Typography mt={1} mb={5} fontSize="1em">
                      Sign-in to practice for the Live Draft, familiarize
                      yourself with the process and make various picks for your
                      team to see how Marval&apos;s recommendations change.
                    </Typography>
                    <SignInButton
                      onClick={() => {
                        instance.loginPopup({
                          scopes: clientConfig.azureDirectory.customerScopes,
                        });
                      }}
                      data-testid="sign-in-button"
                    >
                      Sign-In to Start
                    </SignInButton>
                  </Stack>
                </Box>
              </Stack>
            </SignInPage>
          </Fade>
        </>
      </UnauthenticatedTemplate>
    </>
  );
};

const SignInPage = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.grey[50],
  alignItems: "start",
  height: "calc(100vh - 50px)",
}));

const SignInButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[500]}`,
  backgroundColor: theme.palette.grey[900],
  borderRadius: 5,
  fontSize: "1em",
  fontWeight: 400,
  textTransform: "none",
  paddingLeft: 20,
  paddingRight: 20,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.grey[800],
  },
  "&:disabled": {
    color: theme.palette.common.white,
  },
  maxWidth: 250,
}));
