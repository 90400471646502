import { useState } from "react";
import Link from "next/link";
import {
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Box,
  Container,
  Fade,
} from "@mui/material";
import { SignOutButton } from "../SignOutButton";
import { SignInButton } from "./../SignInButton";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { UserInfo } from "../UserInfo";
import { AccountCircle } from "@mui/icons-material";
//import { SearchInput } from "../globalSearch/SearchInput";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { NavBarMobileDrawer } from "./NavBarMobileDrawer";
import router from "next/router";

const SEARCH_BAR_PADDING = 1;

export const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /* Disabling nav bar menu items for beta */
  // const [navAnchorEl, setNavAnchorEl] = useState<{
  //   [index: number]: HTMLElement;
  // } | null>(null);
  const [mobileDrawerOpen, setDrawerMobileOpen] = React.useState(false);

  const handleMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  /* Disabling nav bar menu items for beta */
  // const handleNavMenu = (index: number, e: React.MouseEvent<HTMLElement>) => {
  //   setNavAnchorEl({ [index]: e.currentTarget });
  // };
  // const handleNavClose = () => {
  //   setNavAnchorEl(null);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setDrawerMobileOpen(!mobileDrawerOpen);
  };

  const handleDrawerOpen = (value: boolean) => {
    setDrawerMobileOpen(value);
  };
  return (
    <>
      <Fade in>
        <AppBar
          component="nav"
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Container maxWidth="xl">
            <Toolbar
              variant="dense"
              disableGutters
              sx={{
                paddingTop: SEARCH_BAR_PADDING,
                paddingBottom: SEARCH_BAR_PADDING,
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { sm: "none" }, mr: 1 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h5" component="div" mr={1}>
                <Link href="/home">MARVAL</Link>
              </Typography>
              <AuthenticatedTemplate>
                <Stack
                  flex={1}
                  alignItems="center"
                  direction="row"
                  justifyContent={"space-around"}
                >
                  <Box
                    sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}
                  >
                    {/* Disabling nav bar menu items for beta */}
                    {/* {authenticatedMenu.map((m, index) => (
                      <React.Fragment key={`mbtn-${m.id}`}>
                        <Button
                          onClick={(e) => handleNavMenu(index, e)}
                          sx={{ color: "inherit" }}
                          href={m.nav}
                          disabled={m.disabled}
                        >
                          {m.name}
                          {m.subItems && m.subItems?.length > 0 ? (
                            navAnchorEl && navAnchorEl[index] ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )
                          ) : null}
                        </Button>
                        {m.subItems && m.subItems?.length > 0 ? (
                          <Menu
                            keepMounted
                            anchorEl={navAnchorEl && navAnchorEl[index]}
                            open={Boolean(navAnchorEl && navAnchorEl[index])}
                            onClose={handleNavClose}
                            sx={{ marginTop: 1.6 }}
                          >
                            {m.subItems &&
                              m.subItems.map((si) => (
                                <MenuItem
                                  key={`mi-${si.id}`}
                                  onClick={handleNavClose}
                                  sx={{ minWidth: 150 }}
                                  disabled={si.disabled}
                                >
                                  <Link key={`mil-${si.id}`} href={si.nav}>
                                    {si.name}
                                  </Link>
                                </MenuItem>
                              ))}
                          </Menu>
                        ) : null}
                      </React.Fragment>
                    ))} */}
                  </Box>
                  {/* Disabling global search for beta */}
                  {/* <SearchInput /> */}
                  <IconButton
                    size="small"
                    aria-label="Settings"
                    onClick={() => router.push("/settings")}
                    color="inherit"
                    sx={{ marginLeft: 2, display: { xs: "block", sm: "flex" } }}
                  >
                    <SettingsIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    sx={{ marginLeft: 2, display: { xs: "block", sm: "flex" } }}
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose} disabled>
                      <UserInfo />
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <SignOutButton />
                    </MenuItem>
                  </Menu>
                </Stack>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <div style={{ flex: 6 }} />
                <SignInButton />
              </UnauthenticatedTemplate>
            </Toolbar>
          </Container>
        </AppBar>
      </Fade>
      <NavBarMobileDrawer
        open={mobileDrawerOpen}
        handleDrawerOpen={handleDrawerOpen}
      />

      <Toolbar
        variant="dense"
        sx={{
          paddingTop: SEARCH_BAR_PADDING,
          paddingBottom: SEARCH_BAR_PADDING,
        }}
      />
    </>
  );
};
