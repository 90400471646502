import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: number;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  /** The built-in `Decimal` scalar type. */
  Decimal: number;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: number;
  UUID: string;
};

export type ActivateUserRequest = {
  authenticatedId: Scalars["UUID"];
  userId: Scalars["UUID"];
};

export type AddCutAfterDateAssumptionCommandRequest = {
  contractMinimumEndDate: Scalars["Date"];
  playerId: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

export type AddCutPlayerAssumptionCommandRequest = {
  playerId: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

export type AddDoNotDraftAssumptionCommandRequest = {
  playerId: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

export type AddDoNotDraftUntilRoundAssumptionCommandRequest = {
  draftRoundMinimum: Scalars["Int"];
  playerId: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

export type AddDraftWithPickAssumptionCommandRequest = {
  draftPick: DraftPickInput;
  playerId: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

export type AddGlobalCutAssumptionRequest = {
  playerId: Scalars["String"];
};

export type AddGlobalDoNotDraftAssumptionRequest = {
  playerId: Scalars["String"];
};

export type AddGlobalDraftWithPickAssumptionRequest = {
  draftPick: DraftPickInput;
  playerId: Scalars["String"];
};

export type AddGlobalKeepAssumptionRequest = {
  contractMinimumEndDate: Scalars["Date"];
  playerId: Scalars["String"];
};

export type AddGlobalWaitToDraftAssumptionRequest = {
  draftRoundMinimum: Scalars["Int"];
  playerId: Scalars["String"];
};

export type AddKeepUntilDateAssumptionCommandRequest = {
  contractMinimumEndDate: Scalars["Date"];
  playerId: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

export type AddKeepWithContractAssumptionCommandRequest = {
  contracts: Array<NymInput>;
  playerId: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

export type AddKeepWithContractDurationAssumptionCommandRequest = {
  contractMinimumEndDate: Scalars["Date"];
  contracts: Array<NymInput>;
  playerId: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

export type AddLiveDraftCutAssumptionRequest = {
  playerId: Scalars["String"];
};

export type AddLiveDraftDoNotDraftAssumptionRequest = {
  playerId: Scalars["String"];
};

export type AddLiveDraftDraftWithPickAssumptionRequest = {
  draftPick: DraftPickInput;
  playerId: Scalars["String"];
};

export type AddLiveDraftKeepAssumptionRequest = {
  contractMinimumEndDate: Scalars["Date"];
  playerId: Scalars["String"];
};

export type AddLiveDraftWaitToDraftAssumptionRequest = {
  draftRoundMinimum: Scalars["Int"];
  playerId: Scalars["String"];
};

export type AddMustNotSignPlayerCommandRequest = {
  playerId: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

export type AddMustSignPlayerAssumptionCommandRequest = {
  contractMinimumEndDate: Scalars["Date"];
  contracts: Array<NymInput>;
  playerId: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

export type AddMustSignPlayerThatCantBeCutAssumptionCommandRequest = {
  contracts: Array<NymInput>;
  playerId: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

export type AddSignPlayerToAnyContractAssumptionCommandRequest = {
  contractMinimumEndDate: Scalars["Date"];
  playerId: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

export type AddUserDraftScenarioCutAssumptionRequest = {
  playerId: Scalars["String"];
};

export type AddUserDraftScenarioDoNotDraftAssumptionRequest = {
  playerId: Scalars["String"];
};

export type AddUserDraftScenarioDraftWithPickAssumptionRequest = {
  draftPick: DraftPickInput;
  playerId: Scalars["String"];
};

export type AddUserDraftScenarioKeepAssumptionRequest = {
  contractMinimumEndDate: Scalars["Date"];
  playerId: Scalars["String"];
};

export type AddUserDraftScenarioWaitToDraftAssumptionRequest = {
  draftRoundMinimum: Scalars["Int"];
  playerId: Scalars["String"];
};

export type Agent = {
  __typename?: "Agent";
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  cellPhoneNumber?: Maybe<Scalars["String"]>;
  /**
   * A red flag - if an agent is no longer certified. Clubs cannot negotiate with a third party
   * that has not been certified by the NFLPA
   */
  certified: Scalars["Boolean"];
  city?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastName?: Maybe<Scalars["String"]>;
  lastUpdated: Scalars["DateTime"];
  leagueAgentId: Scalars["Int"];
  middleName?: Maybe<Scalars["String"]>;
  rowVersion: Array<Scalars["Byte"]>;
  state?: Maybe<Scalars["String"]>;
  update: Agent;
  workPhone?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type AgentUpdateArgs = {
  details: AgentDetailsInput;
};

export type AgentDetailsInput = {
  address1?: InputMaybe<Scalars["String"]>;
  address2?: InputMaybe<Scalars["String"]>;
  cellPhoneNumber?: InputMaybe<Scalars["String"]>;
  certified: Scalars["Boolean"];
  city?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  fax?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  leagueAgentId: Scalars["Int"];
  middleName?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  workPhone?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type AgentFilterInput = {
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AgentFilterInput>>;
  cellPhoneNumber?: InputMaybe<StringOperationFilterInput>;
  /**
   * A red flag - if an agent is no longer certified. Clubs cannot negotiate with a third party
   * that has not been certified by the NFLPA
   */
  certified?: InputMaybe<BooleanOperationFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  fax?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueAgentId?: InputMaybe<IntOperationFilterInput>;
  middleName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AgentFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
  workPhone?: InputMaybe<StringOperationFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

export type AgentSortInput = {
  address1?: InputMaybe<SortEnumType>;
  address2?: InputMaybe<SortEnumType>;
  cellPhoneNumber?: InputMaybe<SortEnumType>;
  /**
   * A red flag - if an agent is no longer certified. Clubs cannot negotiate with a third party
   * that has not been certified by the NFLPA
   */
  certified?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  company?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  fax?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueAgentId?: InputMaybe<SortEnumType>;
  middleName?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  workPhone?: InputMaybe<SortEnumType>;
  zip?: InputMaybe<SortEnumType>;
};

/** Player alerts */
export type Alert = {
  __typename?: "Alert";
  abbreviation: Scalars["String"];
  caption: Scalars["String"];
  created: Scalars["DateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  sort?: Maybe<Scalars["Int"]>;
  update: Alert;
};

/** Player alerts */
export type AlertUpdateArgs = {
  details: AlertDetailsInput;
};

export type AlertDetailsInput = {
  abbreviation: Scalars["String"];
  caption: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["UUID"];
  isDeleted: Scalars["Boolean"];
  sort?: InputMaybe<Scalars["Int"]>;
};

/** Player alerts */
export type AlertFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AlertFilterInput>>;
  caption?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AlertFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
};

/** Player alerts */
export type AlertSortInput = {
  abbreviation?: InputMaybe<SortEnumType>;
  caption?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  sort?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type AllDraftScenariosPagedConnection = {
  __typename?: "AllDraftScenariosPagedConnection";
  /** A list of edges. */
  edges?: Maybe<Array<AllDraftScenariosPagedEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<DraftScenario>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type AllDraftScenariosPagedEdge = {
  __typename?: "AllDraftScenariosPagedEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: DraftScenario;
};

export type AnyEntitySearchResult = {
  __typename?: "AnyEntitySearchResult";
  /** The club search result entity (if that is the type of the particular result row) */
  club?: Maybe<SearchResultOfClubSearchDocument>;
  /** The player search result entity (if that is the type of the particular result row) */
  player?: Maybe<SearchResultOfPlayerSearchDocument>;
  /** The school search result entity (if that is the type of the particular result row) */
  school?: Maybe<SearchResultOfSchoolSearchDocument>;
  /** The score of the search result from its respective index */
  score?: Maybe<Scalars["Float"]>;
};

export type AnyEntitySearchResultFilterInput = {
  and?: InputMaybe<Array<AnyEntitySearchResultFilterInput>>;
  /** The club search result entity (if that is the type of the particular result row) */
  club?: InputMaybe<SearchResultOfClubSearchDocumentFilterInput>;
  or?: InputMaybe<Array<AnyEntitySearchResultFilterInput>>;
  /** The player search result entity (if that is the type of the particular result row) */
  player?: InputMaybe<SearchResultOfPlayerSearchDocumentFilterInput>;
  /** The school search result entity (if that is the type of the particular result row) */
  school?: InputMaybe<SearchResultOfSchoolSearchDocumentFilterInput>;
  /** The score of the search result from its respective index */
  score?: InputMaybe<FloatOperationFilterInput>;
};

export type AnyEntitySearchResultSortInput = {
  /** The club search result entity (if that is the type of the particular result row) */
  club?: InputMaybe<SearchResultOfClubSearchDocumentSortInput>;
  /** The player search result entity (if that is the type of the particular result row) */
  player?: InputMaybe<SearchResultOfPlayerSearchDocumentSortInput>;
  /** The school search result entity (if that is the type of the particular result row) */
  school?: InputMaybe<SearchResultOfSchoolSearchDocumentSortInput>;
  /** The score of the search result from its respective index */
  score?: InputMaybe<SortEnumType>;
};

/**
 * A role is a certain job function (like scout, GM, or admin) that can be assigned to a user.  Each role
 * has a collection of permissions associated with it that the user will have actions to perform.
 */
export type AppRole = {
  __typename?: "AppRole";
  created: Scalars["DateTime"];
  createdById: Scalars["UUID"];
  /** Details about what the role is for */
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  /** Denotes if the role has been soft deleted */
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  lastUpdatedById: Scalars["UUID"];
  /** The name of the role */
  name: Scalars["String"];
  /** Permission list assigned to role */
  permissions: Array<Permission>;
  rowVersion: Array<Scalars["Byte"]>;
  type: RoleType;
};

/**
 * A role is a certain job function (like scout, GM, or admin) that can be assigned to a user.  Each role
 * has a collection of permissions associated with it that the user will have actions to perform.
 */
export type AppRoleFilterInput = {
  and?: InputMaybe<Array<AppRoleFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  /** Details about what the role is for */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Denotes if the role has been soft deleted */
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedById?: InputMaybe<UuidOperationFilterInput>;
  /** The name of the role */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppRoleFilterInput>>;
  /** Permission list assigned to role */
  permissions?: InputMaybe<ListFilterInputTypeOfPermissionFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  type?: InputMaybe<RoleTypeOperationFilterInput>;
};

/**
 * A role is a certain job function (like scout, GM, or admin) that can be assigned to a user.  Each role
 * has a collection of permissions associated with it that the user will have actions to perform.
 */
export type AppRoleSortInput = {
  created?: InputMaybe<SortEnumType>;
  createdById?: InputMaybe<SortEnumType>;
  /** Details about what the role is for */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Denotes if the role has been soft deleted */
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  lastUpdatedById?: InputMaybe<SortEnumType>;
  /** The name of the role */
  name?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export enum ApplyPolicy {
  AFTER_RESOLVER = "AFTER_RESOLVER",
  BEFORE_RESOLVER = "BEFORE_RESOLVER",
  VALIDATION = "VALIDATION",
}

export type AssignPermissionsToRoleRequest = {
  permissionIds: Array<Scalars["UUID"]>;
  roleId: Scalars["UUID"];
};

/** Request to assign a current season draft asset to a club */
export type AssignPickToClubRequest = {
  /** The club to assign the pick to */
  clubId: Scalars["UUID"];
  /** The ID of the draft scenario to update */
  draftId: Scalars["UUID"];
  /** The overall number of the pick to update */
  overall: Scalars["Int"];
};

export type AssignPlayerOverrideRequest = {
  details: PlayerOverrideDetailsInput;
};

/** Request to create a local assumption to assume that the player specified will not be drafted by the club. */
export type AssumeDoNotDraftPlayerRequest = {
  /** The ID of the draft scenario */
  draftId: Scalars["UUID"];
  /** The player to not draft */
  playerId: Scalars["UUID"];
};

/** Request to assume that a given player will be drafted at the specified pick. */
export type AssumePlayerDraftedAtPickRequest = {
  /** The ID of the draft scenario */
  draftId: Scalars["UUID"];
  /** The overall pick the player is assumed to be chosen at */
  overall: Scalars["Int"];
  /** The ID of the player to be picked */
  playerId: Scalars["UUID"];
};

export type Assumption = {
  __typename?: "Assumption";
  constraint: Constraint;
  contractMinimumEndDate?: Maybe<Scalars["Date"]>;
  contracts: Array<Nym>;
  description: Scalars["String"];
  draftPick?: Maybe<DraftPick>;
  draftPickTrade?: Maybe<Array<DraftPickTransaction>>;
  draftRoundMinimum?: Maybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  isValid: Scalars["Boolean"];
  name: Scalars["String"];
  playerId?: Maybe<Scalars["String"]>;
  subject: Subject;
};

export type AssumptionFilterInput = {
  and?: InputMaybe<Array<AssumptionFilterInput>>;
  constraint?: InputMaybe<ConstraintOperationFilterInput>;
  contractMinimumEndDate?: InputMaybe<DateOperationFilterInput>;
  contracts?: InputMaybe<ListFilterInputTypeOfNymFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  draftPick?: InputMaybe<DraftPickFilterInput>;
  draftPickTrade?: InputMaybe<ListFilterInputTypeOfDraftPickTransactionFilterInput>;
  draftRoundMinimum?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AssumptionFilterInput>>;
  playerId?: InputMaybe<StringOperationFilterInput>;
  subject?: InputMaybe<SubjectOperationFilterInput>;
};

export type AssumptionSet = {
  __typename?: "AssumptionSet";
  assumptions: Array<Assumption>;
  id: Scalars["UUID"];
};

/** Player availability is the probability that a player will be available at a given draft pick. */
export type Availability = {
  __typename?: "Availability";
  /** Probability that the player is available to be drafted */
  availabilityPercentage: Scalars["Float"];
  /** The draft pick context */
  draftPick: CurrentSeasonDraftAsset;
  /** Probability that the player is selected as the associated pick */
  selectedPercentage: Scalars["Float"];
};

/** Player availability is the probability that a player will be available at a given draft pick. */
export type AvailabilityFilterInput = {
  and?: InputMaybe<Array<AvailabilityFilterInput>>;
  /** Probability that the player is available to be drafted */
  availabilityPercentage?: InputMaybe<FloatOperationFilterInput>;
  /** The draft pick context */
  draftPick?: InputMaybe<CurrentSeasonDraftAssetFilterInput>;
  or?: InputMaybe<Array<AvailabilityFilterInput>>;
  /** Probability that the player is selected as the associated pick */
  selectedPercentage?: InputMaybe<FloatOperationFilterInput>;
};

/** Player availability is the probability that a player will be available at a given draft pick. */
export type AvailabilityInput = {
  /** Probability that the player is available to be drafted */
  availabilityPercentage: Scalars["Float"];
  /** The draft pick context */
  draftPick: CurrentSeasonDraftAssetInput;
  /** Probability that the player is selected as the associated pick */
  selectedPercentage: Scalars["Float"];
};

export type BaseScenarioState = {
  __typename?: "BaseScenarioState";
  clubDraftPicks: Array<DraftPick>;
  clubDraftPicksStale: Scalars["Boolean"];
  draftAvailabilities: Array<DraftAvailability>;
  draftAvailabilitiesStale: Scalars["Boolean"];
};

export type BasicPlayerDetailsInput = {
  birthDate?: InputMaybe<Scalars["DateTime"]>;
  height?: InputMaybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  speed?: InputMaybe<Scalars["Decimal"]>;
  weight?: InputMaybe<Scalars["Int"]>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars["Boolean"]>;
  neq?: InputMaybe<Scalars["Boolean"]>;
};

export type ByteOperationFilterInput = {
  eq?: InputMaybe<Scalars["Byte"]>;
  gt?: InputMaybe<Scalars["Byte"]>;
  gte?: InputMaybe<Scalars["Byte"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Byte"]>>>;
  lt?: InputMaybe<Scalars["Byte"]>;
  lte?: InputMaybe<Scalars["Byte"]>;
  neq?: InputMaybe<Scalars["Byte"]>;
  ngt?: InputMaybe<Scalars["Byte"]>;
  ngte?: InputMaybe<Scalars["Byte"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Byte"]>>>;
  nlt?: InputMaybe<Scalars["Byte"]>;
  nlte?: InputMaybe<Scalars["Byte"]>;
};

export type Calendar = {
  __typename?: "Calendar";
  /** The calendar date */
  calendarDate: Scalars["DateTime"];
  created: Scalars["DateTime"];
  /** Description of event associated with the date */
  description?: Maybe<Scalars["String"]>;
  /** The draft season the calendar date belongs */
  draftSeason: Scalars["Int"];
  id: Scalars["ID"];
  /** Is calendar date part of the draft days */
  isDraft: Scalars["Boolean"];
  /** Is calendar date part of the post season */
  isPostSeason: Scalars["Boolean"];
  /** Is calendar date part of the regular season */
  isRegSeason: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  /** The season the calendar date belongs */
  season: Scalars["Int"];
};

export type CareerStat = {
  __typename?: "CareerStat";
  gameId?: Maybe<Scalars["UUID"]>;
  lastUpdated: Scalars["DateTime"];
  leagueType: LeagueType;
  leagueTypeId: Scalars["Int"];
  perGameStatsGamesPlayed?: Maybe<Scalars["Int"]>;
  perGameStatsPassAttemptsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsPassingYardsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsReceivingYardsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsReceptionsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsRushAttemptsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsRushingYardsPerGame?: Maybe<Scalars["Decimal"]>;
  playerId: Scalars["UUID"];
  playerStat?: Maybe<PlayerStat>;
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  seasonType: SeasonType;
  seasonTypeId: Scalars["Int"];
  statType: StatType;
  statTypeId: Scalars["Int"];
};

export type CareerStatFilterInput = {
  and?: InputMaybe<Array<CareerStatFilterInput>>;
  gameId?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueType?: InputMaybe<LeagueTypeFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CareerStatFilterInput>>;
  perGameStatsGamesPlayed?: InputMaybe<IntOperationFilterInput>;
  perGameStatsPassAttemptsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsPassingYardsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsReceivingYardsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsReceptionsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsRushAttemptsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsRushingYardsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  playerStat?: InputMaybe<PlayerStatFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  seasonType?: InputMaybe<SeasonTypeFilterInput>;
  seasonTypeId?: InputMaybe<IntOperationFilterInput>;
  statType?: InputMaybe<StatTypeFilterInput>;
  statTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type CareerStatSortInput = {
  gameId?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueType?: InputMaybe<LeagueTypeSortInput>;
  leagueTypeId?: InputMaybe<SortEnumType>;
  perGameStatsGamesPlayed?: InputMaybe<SortEnumType>;
  perGameStatsPassAttemptsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsPassingYardsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsReceivingYardsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsReceptionsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsRushAttemptsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsRushingYardsPerGame?: InputMaybe<SortEnumType>;
  playerId?: InputMaybe<SortEnumType>;
  playerStat?: InputMaybe<PlayerStatSortInput>;
  season?: InputMaybe<SortEnumType>;
  seasonType?: InputMaybe<SeasonTypeSortInput>;
  seasonTypeId?: InputMaybe<SortEnumType>;
  statType?: InputMaybe<StatTypeSortInput>;
  statTypeId?: InputMaybe<SortEnumType>;
};

/** A club is an encapsulation of a team within the NFL, such as the Green Bay Packers or the Atlanta Falcons. */
export type Club = {
  __typename?: "Club";
  /** The NFL's designated club code */
  clubCode: Scalars["String"];
  /** The club's full name */
  clubName: Scalars["String"];
  /** A URL to the team's website */
  clubRosterUrl: Scalars["String"];
  /** The conference the club belongs to */
  conference: Scalars["String"];
  created: Scalars["DateTime"];
  /** The current coach of the club */
  currentCoach: Scalars["String"];
  /** The division the club belongs to */
  division: Scalars["String"];
  id: Scalars["ID"];
  /** Indicates whether or not the club is still active in the league */
  isActive: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** Where the club is located */
  location: Scalars["String"];
  /** A URL to a logo of the club */
  logoUrl: Scalars["String"];
  /** The club's nickname/mascot */
  nickName: Scalars["String"];
  /** Indicates whether team plays in a dome (indoor stadium) */
  outdoorStadium?: Maybe<Scalars["Boolean"]>;
  /** Who owns the club */
  owners: Scalars["String"];
  /** A hex color of the team's primary color in their color scheme */
  primaryColor?: Maybe<Scalars["String"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** A hex color of the team's secondary color in their color scheme */
  secondaryColor?: Maybe<Scalars["String"]>;
  /** The type of weather team regularly plays in in their stadium. */
  stadiumTurf?: Maybe<Scalars["String"]>;
  /** The timezone the team plays in. */
  timeZone?: Maybe<Scalars["String"]>;
  /**
   * Updates a club using all the supplied values in the details
   *
   *
   * **Returns:**
   * The updated club entity
   */
  updateClubDetails: Club;
  /** The primary stadium the club plays home games at */
  venue: Scalars["String"];
  /** The year the club was formed */
  yearEstablished: Scalars["Int"];
};

/** A club is an encapsulation of a team within the NFL, such as the Green Bay Packers or the Atlanta Falcons. */
export type ClubUpdateClubDetailsArgs = {
  request: UpdateClubDetailsRequest;
};

export type ClubDetailsInput = {
  abbreviation: Scalars["String"];
  clubRosterUrl: Scalars["String"];
  conference: Scalars["String"];
  currentCoach: Scalars["String"];
  currentLogo: Scalars["String"];
  division: Scalars["String"];
  endSeason?: InputMaybe<Scalars["Int"]>;
  gsisAbbreviation?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  league?: InputMaybe<Scalars["String"]>;
  leagueClubId?: InputMaybe<Scalars["Int"]>;
  leagueTeamId?: InputMaybe<Scalars["UUID"]>;
  location: Scalars["String"];
  name: Scalars["String"];
  nickName: Scalars["String"];
  officialWebsiteUrl: Scalars["String"];
  outdoorStadium?: InputMaybe<Scalars["Boolean"]>;
  owners: Scalars["String"];
  pffFranchiseId: Scalars["Int"];
  pffLeagueId?: InputMaybe<Scalars["Int"]>;
  pffTeamId: Scalars["Int"];
  primaryColor?: InputMaybe<Scalars["String"]>;
  secondaryColor?: InputMaybe<Scalars["String"]>;
  stadiumTurf?: InputMaybe<Scalars["String"]>;
  startSeason: Scalars["Int"];
  venue: Scalars["String"];
};

/**
 * A player like ClubDraftablePlayerAtPick but evaluated across
 * all picks in the current DAM window.
 */
export type ClubDraftablePlayer = {
  __typename?: "ClubDraftablePlayer";
  /** The player's availability at all all draft picks in the availability window. */
  availabilities: Array<Availability>;
  /** The player's fair value on the market */
  marketValue: Value;
  /** The player's individual performance value on a given roster */
  performanceValue?: Maybe<Value>;
  /** The ID of the player */
  player: Player;
  /** The player's expected draft position before the draft starts.  Null implies we expect them to be undrafted */
  preDraftExpectedPick?: Maybe<Scalars["Int"]>;
  /** The expected total roster value by the player being on the team */
  projectedRosterValueDollars?: Maybe<Scalars["Float"]>;
  /** The net roster value change by the player being on the team */
  rosterValueAdded?: Maybe<Value>;
};

/**
 * A player that can be drafted at a pick that is owned by the client club.
 * Extends DraftablePlayerAtPick with additional club-specific information.
 */
export type ClubDraftablePlayerAtPick = {
  __typename?: "ClubDraftablePlayerAtPick";
  /** The player's availability at some draft pick as if the club were to own it via trade (unless they already do).  Null if outside the availability/trade back window. */
  availability?: Maybe<Availability>;
  /** Player availability at the club's next pick after Availability.  Null if club owns no picks after the availability pick or outside trade back/avail windows. */
  availabilityAtClubsNextPick?: Maybe<Availability>;
  /** The player's draftability status for the client club */
  draftableStatus: DraftableStatus;
  /** Indicates whether this player was in the DAM recommendations for the respective pick */
  isDamRecommendedPlayer: Scalars["Boolean"];
  /** The player's market value at a given draft pick */
  marketValue: Value;
  /** The player's individual performance value on a given roster */
  performanceValue?: Maybe<Value>;
  /** The ID of the player */
  player: Player;
  /** The player's expected draft position before the draft starts.  Null implies we expect them to be undrafted */
  preDraftExpectedPick?: Maybe<Scalars["Int"]>;
  /** The expected total roster value by the player being on the team */
  projectedRosterValueDollars?: Maybe<Scalars["Float"]>;
  /** The player's recommendation score is used to rank players by recommended order at the respective pick */
  recommendationScore: Scalars["Float"];
  /** The net roster value change by the player being on the team */
  rosterValueAdded?: Maybe<Value>;
};

/**
 * A player that can be drafted at a pick that is owned by the client club.
 * Extends DraftablePlayerAtPick with additional club-specific information.
 */
export type ClubDraftablePlayerAtPickFilterInput = {
  and?: InputMaybe<Array<ClubDraftablePlayerAtPickFilterInput>>;
  /** The player's availability at some draft pick as if the club were to own it via trade (unless they already do).  Null if outside the availability/trade back window. */
  availability?: InputMaybe<AvailabilityFilterInput>;
  /** Player availability at the club's next pick after Availability.  Null if club owns no picks after the availability pick or outside trade back/avail windows. */
  availabilityAtClubsNextPick?: InputMaybe<AvailabilityFilterInput>;
  /** The player's draftability status for the client club */
  draftableStatus?: InputMaybe<DraftableStatusOperationFilterInput>;
  /** Indicates whether this player was in the DAM recommendations for the respective pick */
  isDamRecommendedPlayer?: InputMaybe<BooleanOperationFilterInput>;
  /** The player's market value at a given draft pick */
  marketValue?: InputMaybe<ValueFilterInput>;
  or?: InputMaybe<Array<ClubDraftablePlayerAtPickFilterInput>>;
  /** The player's individual performance value on a given roster */
  performanceValue?: InputMaybe<ValueFilterInput>;
  /** The ID of the player */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** The player's expected draft position before the draft starts.  Null implies we expect them to be undrafted */
  preDraftExpectedPick?: InputMaybe<IntOperationFilterInput>;
  /** The expected total roster value by the player being on the team */
  projectedRosterValueDollars?: InputMaybe<FloatOperationFilterInput>;
  /** The player's recommendation score is used to rank players by recommended order at the respective pick */
  recommendationScore?: InputMaybe<FloatOperationFilterInput>;
  /** The net roster value change by the player being on the team */
  rosterValueAdded?: InputMaybe<ValueFilterInput>;
};

/**
 * A player that can be drafted at a pick that is owned by the client club.
 * Extends DraftablePlayerAtPick with additional club-specific information.
 */
export type ClubDraftablePlayerAtPickInput = {
  /** The player's availability at some draft pick as if the club were to own it via trade (unless they already do).  Null if outside the availability/trade back window. */
  availability?: InputMaybe<AvailabilityInput>;
  /** Player availability at the club's next pick after Availability.  Null if club owns no picks after the availability pick or outside trade back/avail windows. */
  availabilityAtClubsNextPick?: InputMaybe<AvailabilityInput>;
  /** The player's draftability status for the client club */
  draftableStatus: DraftableStatus;
  /** Indicates whether this player was in the DAM recommendations for the respective pick */
  isDamRecommendedPlayer: Scalars["Boolean"];
  /** The player's market value at a given draft pick */
  marketValue: ValueInput;
  /** The player's individual performance value on a given roster */
  performanceValue?: InputMaybe<ValueInput>;
  /** The ID of the player */
  playerId: Scalars["UUID"];
  /** The player's expected draft position before the draft starts.  Null implies we expect them to be undrafted */
  preDraftExpectedPick?: InputMaybe<Scalars["Int"]>;
  /** The expected total roster value by the player being on the team */
  projectedRosterValueDollars?: InputMaybe<Scalars["Float"]>;
  /** The player's recommendation score is used to rank players by recommended order at the respective pick */
  recommendationScore: Scalars["Float"];
  /** The net roster value change by the player being on the team */
  rosterValueAdded?: InputMaybe<ValueInput>;
};

/** A club is an encapsulation of a team within the NFL, such as the Green Bay Packers or the Atlanta Falcons. */
export type ClubFilterInput = {
  and?: InputMaybe<Array<ClubFilterInput>>;
  /** The NFL's designated club code */
  clubCode?: InputMaybe<StringOperationFilterInput>;
  /** The club's full name */
  clubName?: InputMaybe<StringOperationFilterInput>;
  /** A URL to the team's website */
  clubRosterUrl?: InputMaybe<StringOperationFilterInput>;
  /** The conference the club belongs to */
  conference?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** The current coach of the club */
  currentCoach?: InputMaybe<StringOperationFilterInput>;
  /** The division the club belongs to */
  division?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Indicates whether or not the club is still active in the league */
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** Where the club is located */
  location?: InputMaybe<StringOperationFilterInput>;
  /** A URL to a logo of the club */
  logoUrl?: InputMaybe<StringOperationFilterInput>;
  /** The club's nickname/mascot */
  nickName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClubFilterInput>>;
  /** Indicates whether team plays in a dome (indoor stadium) */
  outdoorStadium?: InputMaybe<BooleanOperationFilterInput>;
  /** Who owns the club */
  owners?: InputMaybe<StringOperationFilterInput>;
  /** A hex color of the team's primary color in their color scheme */
  primaryColor?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** A hex color of the team's secondary color in their color scheme */
  secondaryColor?: InputMaybe<StringOperationFilterInput>;
  /** The type of weather team regularly plays in in their stadium. */
  stadiumTurf?: InputMaybe<StringOperationFilterInput>;
  /** The timezone the team plays in. */
  timeZone?: InputMaybe<StringOperationFilterInput>;
  /** The primary stadium the club plays home games at */
  venue?: InputMaybe<StringOperationFilterInput>;
  /** The year the club was formed */
  yearEstablished?: InputMaybe<IntOperationFilterInput>;
};

/** A draft pick's value to a club. */
export type ClubPickValue = {
  __typename?: "ClubPickValue";
  /** The players backing the derived value */
  backingPlayers: Array<ClubDraftablePlayerAtPick>;
  /** The value of the pick */
  value: Value;
};

/** A draft pick's value to a club. */
export type ClubPickValueFilterInput = {
  and?: InputMaybe<Array<ClubPickValueFilterInput>>;
  /** The players backing the derived value */
  backingPlayers?: InputMaybe<ListFilterInputTypeOfClubDraftablePlayerAtPickFilterInput>;
  or?: InputMaybe<Array<ClubPickValueFilterInput>>;
  /** The value of the pick */
  value?: InputMaybe<ValueFilterInput>;
};

/** A draft pick's value to a club. */
export type ClubPickValueInput = {
  /** The players backing the derived value */
  backingPlayers: Array<ClubDraftablePlayerAtPickInput>;
  /** The value of the pick */
  value: ValueInput;
};

/** Encapsulates a flattened view of a club that can be searched against.  Annotated with Azure Search attributes. */
export type ClubSearchDocument = {
  __typename?: "ClubSearchDocument";
  /** The NFL's designated club code */
  clubCode: Scalars["String"];
  /** The club's full name */
  clubName: Scalars["String"];
  /** The conference the club belongs to */
  conference?: Maybe<Scalars["String"]>;
  /** The division the club belongs to */
  division?: Maybe<Scalars["String"]>;
  /** Club's internal ID */
  id: Scalars["String"];
  /** Indicates whether or not the club is still active in the league */
  isActive: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** The NFL's unique ID for this club */
  leagueId?: Maybe<Scalars["String"]>;
};

/** Encapsulates a flattened view of a club that can be searched against.  Annotated with Azure Search attributes. */
export type ClubSearchDocumentFilterInput = {
  and?: InputMaybe<Array<ClubSearchDocumentFilterInput>>;
  /** The NFL's designated club code */
  clubCode?: InputMaybe<StringOperationFilterInput>;
  /** The club's full name */
  clubName?: InputMaybe<StringOperationFilterInput>;
  /** The conference the club belongs to */
  conference?: InputMaybe<StringOperationFilterInput>;
  /** The division the club belongs to */
  division?: InputMaybe<StringOperationFilterInput>;
  /** Club's internal ID */
  id?: InputMaybe<StringOperationFilterInput>;
  /** Indicates whether or not the club is still active in the league */
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** The NFL's unique ID for this club */
  leagueId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClubSearchDocumentFilterInput>>;
};

/** Encapsulates a flattened view of a club that can be searched against.  Annotated with Azure Search attributes. */
export type ClubSearchDocumentSortInput = {
  /** The NFL's designated club code */
  clubCode?: InputMaybe<SortEnumType>;
  /** The club's full name */
  clubName?: InputMaybe<SortEnumType>;
  /** The conference the club belongs to */
  conference?: InputMaybe<SortEnumType>;
  /** The division the club belongs to */
  division?: InputMaybe<SortEnumType>;
  /** Club's internal ID */
  id?: InputMaybe<SortEnumType>;
  /** Indicates whether or not the club is still active in the league */
  isActive?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The NFL's unique ID for this club */
  leagueId?: InputMaybe<SortEnumType>;
};

/** Encapsulates a result set of clubs from the search appliance. */
export type ClubSearchResults = {
  __typename?: "ClubSearchResults";
  /** The collection of matched clubs, ordered by relevance. */
  clubs: Array<SearchResultOfClubSearchDocument>;
  /** The total number of clubs that would match the search query (excluding paging parameters) */
  total: Scalars["Long"];
};

/** Encapsulates a result set of clubs from the search appliance. */
export type ClubSearchResultsFilterInput = {
  and?: InputMaybe<Array<ClubSearchResultsFilterInput>>;
  /** The collection of matched clubs, ordered by relevance. */
  clubs?: InputMaybe<ListFilterInputTypeOfSearchResultOfClubSearchDocumentFilterInput>;
  or?: InputMaybe<Array<ClubSearchResultsFilterInput>>;
  /** The total number of clubs that would match the search query (excluding paging parameters) */
  total?: InputMaybe<LongOperationFilterInput>;
};

/** Encapsulates a result set of clubs from the search appliance. */
export type ClubSearchResultsSortInput = {
  /** The total number of clubs that would match the search query (excluding paging parameters) */
  total?: InputMaybe<SortEnumType>;
};

/** A club is an encapsulation of a team within the NFL, such as the Green Bay Packers or the Atlanta Falcons. */
export type ClubSortInput = {
  /** The NFL's designated club code */
  clubCode?: InputMaybe<SortEnumType>;
  /** The club's full name */
  clubName?: InputMaybe<SortEnumType>;
  /** A URL to the team's website */
  clubRosterUrl?: InputMaybe<SortEnumType>;
  /** The conference the club belongs to */
  conference?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** The current coach of the club */
  currentCoach?: InputMaybe<SortEnumType>;
  /** The division the club belongs to */
  division?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Indicates whether or not the club is still active in the league */
  isActive?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Where the club is located */
  location?: InputMaybe<SortEnumType>;
  /** A URL to a logo of the club */
  logoUrl?: InputMaybe<SortEnumType>;
  /** The club's nickname/mascot */
  nickName?: InputMaybe<SortEnumType>;
  /** Indicates whether team plays in a dome (indoor stadium) */
  outdoorStadium?: InputMaybe<SortEnumType>;
  /** Who owns the club */
  owners?: InputMaybe<SortEnumType>;
  /** A hex color of the team's primary color in their color scheme */
  primaryColor?: InputMaybe<SortEnumType>;
  /** A hex color of the team's secondary color in their color scheme */
  secondaryColor?: InputMaybe<SortEnumType>;
  /** The type of weather team regularly plays in in their stadium. */
  stadiumTurf?: InputMaybe<SortEnumType>;
  /** The timezone the team plays in. */
  timeZone?: InputMaybe<SortEnumType>;
  /** The primary stadium the club plays home games at */
  venue?: InputMaybe<SortEnumType>;
  /** The year the club was formed */
  yearEstablished?: InputMaybe<SortEnumType>;
};

export type CollegeReportPlayer = {
  __typename?: "CollegeReportPlayer";
  agentCompany?: Maybe<Scalars["String"]>;
  agentName?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["DateTime"]>;
  eligibility?: Maybe<Scalars["String"]>;
  evaluations: Array<Evaluation>;
  expectedDraftPosition?: Maybe<Scalars["Decimal"]>;
  firstName: Scalars["String"];
  headshotUrl?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["Int"]>;
  hometownCity?: Maybe<Scalars["String"]>;
  hometownCountry?: Maybe<Scalars["String"]>;
  hometownState?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  jersey?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  latestBirthDate?: Maybe<Scalars["String"]>;
  primaryEvaluation?: Maybe<Evaluation>;
  sage?: Maybe<Scalars["Decimal"]>;
  schoolCode?: Maybe<Scalars["String"]>;
  speed?: Maybe<Scalars["Decimal"]>;
  sumerAtlasPosition?: Maybe<Scalars["String"]>;
  sumerGeneralPosition?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["Int"]>;
  workouts: Array<WorkoutPlayerMeasure>;
};

/**
 * A player that is evaluated in the context of being picked at a club's subsequent pick if they make
 * a particular pick at the first pick.
 */
export type ComboDraftablePlayer = {
  __typename?: "ComboDraftablePlayer";
  /** True if this is the highest value option for the club */
  isTopCombo: Scalars["Boolean"];
  /** The player that is evaluated */
  playerAtPick: ClubDraftablePlayerAtPick;
};

/**
 * A player that is evaluated in the context of being picked at a club's subsequent pick if they make
 * a particular pick at the first pick.
 */
export type ComboDraftablePlayerFilterInput = {
  and?: InputMaybe<Array<ComboDraftablePlayerFilterInput>>;
  /** True if this is the highest value option for the club */
  isTopCombo?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ComboDraftablePlayerFilterInput>>;
  /** The player that is evaluated */
  playerAtPick?: InputMaybe<ClubDraftablePlayerAtPickFilterInput>;
};

export enum Constraint {
  CUT = "CUT",
  DO_NOT_DRAFT = "DO_NOT_DRAFT",
  DO_NOT_DRAFT_UNTIL_ROUND = "DO_NOT_DRAFT_UNTIL_ROUND",
  DRAFT_PICK_TRADE = "DRAFT_PICK_TRADE",
  KEEP = "KEEP",
  MUST_DRAFT = "MUST_DRAFT",
  MUST_NOT_SIGN = "MUST_NOT_SIGN",
  MUST_SIGN = "MUST_SIGN",
}

export type ConstraintOperationFilterInput = {
  eq?: InputMaybe<Constraint>;
  in?: InputMaybe<Array<Constraint>>;
  neq?: InputMaybe<Constraint>;
  nin?: InputMaybe<Array<Constraint>>;
};

/** A request to create a new draft scenario instance within Marval. */
export type CreateDraftScenarioRequest = {
  /** The club to create the draft scenario for.  If null, the tenant default is used. */
  clubId?: InputMaybe<Scalars["UUID"]>;
  /** The type of draft taking place */
  draftType: DraftTypeEnum;
  /** The name of the draft instance to be created. Default name will be used if null. */
  name?: InputMaybe<Scalars["String"]>;
  /** The season the draft is for.  Upcoming draft season used if null. */
  season?: InputMaybe<Scalars["Int"]>;
};

export type CreateEvaluationRequest = {
  details: EvaluationDetailsInput;
};

export type CreateFavoriteRequest = {
  playerId: Scalars["UUID"];
};

export type CreateGameEvaluationRequest = {
  details: EvaluationGameDetailsInput;
};

export type CreateGradeWeightRequest = {
  gradeWeightType: GradeWeightEnum;
  value?: Scalars["Int"];
};

export type CreatePermissionRequest = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  name: Scalars["String"];
};

export type CreatePlayerInjuryColWithDetailsRequest = {
  /** Details about the injury */
  details: PlayerInjuryCollegeDetailsInput;
  /** Marval's internal ID for the injury */
  id?: InputMaybe<Scalars["UUID"]>;
};

export type CreatePlayerInjuryProWithDetailsRequest = {
  /** Details about the injury */
  details: PlayerInjuryProDetailsInput;
  /** Marval's internal ID for the injury */
  id?: InputMaybe<Scalars["UUID"]>;
};

export type CreatePlayerRequest = {
  /** Details about the player */
  details: PlayerDetailsInput;
  /** Marval's internal ID for the player */
  id?: InputMaybe<Scalars["UUID"]>;
};

export type CreatePositionAtlasNodeRequest = {
  nodeId: Scalars["String"];
  parentNodeId: Scalars["String"];
};

export type CreateRoleRequest = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  name: Scalars["String"];
};

export type CreateScenarioRequestInput = {
  name?: InputMaybe<Scalars["String"]>;
  shouldBeHidden?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateScoutingAssignmentRequest = {
  details: ScoutingAssignmentDetailsInput;
};

export type CreateUserRequest = {
  authenticatedId?: InputMaybe<Scalars["UUID"]>;
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  id?: InputMaybe<Scalars["UUID"]>;
  lastName: Scalars["String"];
  onboardingLink?: InputMaybe<Scalars["String"]>;
  roleId?: InputMaybe<Scalars["UUID"]>;
};

/**
 * A draft asset is draft capital owned by a club in the current season where overall/pick in round is guaranteed
 * to be known.
 */
export type CurrentSeasonDraftAsset = {
  __typename?: "CurrentSeasonDraftAsset";
  /** The ID of the current owning club */
  club: Club;
  /** The ID of the club the pick originated with */
  originalClub: Club;
  /** The overall pick number */
  overall: Scalars["Int"];
  /** The pick number within the round */
  pickInRound: Scalars["Int"];
  /** The round of the draft pick */
  round: Scalars["Int"];
  /** The season of the draft pick */
  season: Scalars["Int"];
};

/**
 * A draft asset is draft capital owned by a club in the current season where overall/pick in round is guaranteed
 * to be known.
 */
export type CurrentSeasonDraftAssetFilterInput = {
  and?: InputMaybe<Array<CurrentSeasonDraftAssetFilterInput>>;
  /** The ID of the current owning club */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CurrentSeasonDraftAssetFilterInput>>;
  /** The ID of the club the pick originated with */
  originalClubId?: InputMaybe<UuidOperationFilterInput>;
  /** The overall pick number */
  overall?: InputMaybe<IntOperationFilterInput>;
  /** The pick number within the round */
  pickInRound?: InputMaybe<IntOperationFilterInput>;
  /** The round of the draft pick */
  round?: InputMaybe<IntOperationFilterInput>;
  /** The season of the draft pick */
  season?: InputMaybe<IntOperationFilterInput>;
};

/**
 * A draft asset is draft capital owned by a club in the current season where overall/pick in round is guaranteed
 * to be known.
 */
export type CurrentSeasonDraftAssetInput = {
  /** The ID of the current owning club */
  clubId: Scalars["UUID"];
  /** The ID of the club the pick originated with */
  originalClubId: Scalars["UUID"];
  /** The overall pick number */
  overall: Scalars["Int"];
  /** The pick number within the round */
  pickInRound: Scalars["Int"];
  /** The round of the draft pick */
  round: Scalars["Int"];
  /** The season of the draft pick */
  season: Scalars["Int"];
};

/**
 * A draft asset is draft capital owned by a club in the current season where overall/pick in round is guaranteed
 * to be known.
 */
export type CurrentSeasonDraftAssetSortInput = {
  /** The ID of the current owning club */
  clubId?: InputMaybe<SortEnumType>;
  /** The ID of the club the pick originated with */
  originalClubId?: InputMaybe<SortEnumType>;
  /** The overall pick number */
  overall?: InputMaybe<SortEnumType>;
  /** The pick number within the round */
  pickInRound?: InputMaybe<SortEnumType>;
  /** The round of the draft pick */
  round?: InputMaybe<SortEnumType>;
  /** The season of the draft pick */
  season?: InputMaybe<SortEnumType>;
};

/** A workspace player that is currently in the current workspace on the UI for the user */
export type CurrentWorkspacePlayer = {
  __typename?: "CurrentWorkspacePlayer";
  /** The slot the player is in on the UI.  0-based index is used when derived on back end. */
  slot: Scalars["Int"];
  /** The associated workspace player */
  workspacePlayer: WorkspacePlayer;
};

/** A workspace player that is currently in the current workspace on the UI for the user */
export type CurrentWorkspacePlayerFilterInput = {
  and?: InputMaybe<Array<CurrentWorkspacePlayerFilterInput>>;
  or?: InputMaybe<Array<CurrentWorkspacePlayerFilterInput>>;
  /** The slot the player is in on the UI.  0-based index is used when derived on back end. */
  slot?: InputMaybe<IntOperationFilterInput>;
  /** The associated workspace player */
  workspacePlayer?: InputMaybe<WorkspacePlayerFilterInput>;
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars["Date"]>;
  gt?: InputMaybe<Scalars["Date"]>;
  gte?: InputMaybe<Scalars["Date"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  lt?: InputMaybe<Scalars["Date"]>;
  lte?: InputMaybe<Scalars["Date"]>;
  neq?: InputMaybe<Scalars["Date"]>;
  ngt?: InputMaybe<Scalars["Date"]>;
  ngte?: InputMaybe<Scalars["Date"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  nlt?: InputMaybe<Scalars["Date"]>;
  nlte?: InputMaybe<Scalars["Date"]>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  neq?: InputMaybe<Scalars["DateTime"]>;
  ngt?: InputMaybe<Scalars["DateTime"]>;
  ngte?: InputMaybe<Scalars["DateTime"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  nlt?: InputMaybe<Scalars["DateTime"]>;
  nlte?: InputMaybe<Scalars["DateTime"]>;
};

export type DeactivateUserRequest = {
  userId: Scalars["UUID"];
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars["Decimal"]>;
  gt?: InputMaybe<Scalars["Decimal"]>;
  gte?: InputMaybe<Scalars["Decimal"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Decimal"]>>>;
  lt?: InputMaybe<Scalars["Decimal"]>;
  lte?: InputMaybe<Scalars["Decimal"]>;
  neq?: InputMaybe<Scalars["Decimal"]>;
  ngt?: InputMaybe<Scalars["Decimal"]>;
  ngte?: InputMaybe<Scalars["Decimal"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Decimal"]>>>;
  nlt?: InputMaybe<Scalars["Decimal"]>;
  nlte?: InputMaybe<Scalars["Decimal"]>;
};

export type DeleteAssumptionCommandRequest = {
  assumptionId: Scalars["UUID"];
  scenarioId: Scalars["UUID"];
};

export type DeleteFavoriteRequest = {
  playerId: Scalars["UUID"];
};

export type DeleteGlobalAssumptionRequest = {
  assumptionId: Scalars["UUID"];
};

export type DeleteLiveDraftAssumptionRequest = {
  assumptionId: Scalars["UUID"];
};

export type DeletePermissionRequest = {
  permissionId: Scalars["UUID"];
};

export type DeleteRoleRequest = {
  roleId: Scalars["UUID"];
};

export type DeleteScoutingAssignmentRequest = {
  id: Scalars["UUID"];
};

export type DeleteUserDraftScenarioAssumptionRequest = {
  assumptionId: Scalars["UUID"];
};

/** Discard the saved draft so the user can no longer view it */
export type DiscardDraftScenarioRequest = {
  /** The ID of the draft scenario to discard */
  draftId: Scalars["UUID"];
};

export type DomainEventSummary = {
  __typename?: "DomainEventSummary";
  id: Scalars["UUID"];
  payload: Scalars["String"];
  streamId: Scalars["UUID"];
  timestamp: Scalars["DateTime"];
  type: Scalars["String"];
  version: Scalars["Long"];
};

export type DomainEventSummaryFilterInput = {
  and?: InputMaybe<Array<DomainEventSummaryFilterInput>>;
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<DomainEventSummaryFilterInput>>;
  payload?: InputMaybe<StringOperationFilterInput>;
  streamId?: InputMaybe<UuidOperationFilterInput>;
  timestamp?: InputMaybe<DateTimeOperationFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
  version?: InputMaybe<LongOperationFilterInput>;
};

export type DomainEventSummarySortInput = {
  id?: InputMaybe<SortEnumType>;
  payload?: InputMaybe<SortEnumType>;
  streamId?: InputMaybe<SortEnumType>;
  timestamp?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  version?: InputMaybe<SortEnumType>;
};

/** A draft asset is draft capital owned by a club in any season. */
export type DraftAsset = {
  __typename?: "DraftAsset";
  /** The ID of the current owning club */
  club: Club;
  /** The ID of the club the pick originated with */
  originalClub: Club;
  /** The overall pick number */
  overall?: Maybe<Scalars["Int"]>;
  /** The pick number within the round */
  pickInRound?: Maybe<Scalars["Int"]>;
  /** The round of the draft pick */
  round: Scalars["Int"];
  /** The season of the draft pick */
  season: Scalars["Int"];
};

/** A draft asset is draft capital owned by a club in any season. */
export type DraftAssetFilterInput = {
  and?: InputMaybe<Array<DraftAssetFilterInput>>;
  /** The ID of the current owning club */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<DraftAssetFilterInput>>;
  /** The ID of the club the pick originated with */
  originalClubId?: InputMaybe<UuidOperationFilterInput>;
  /** The overall pick number */
  overall?: InputMaybe<IntOperationFilterInput>;
  /** The pick number within the round */
  pickInRound?: InputMaybe<IntOperationFilterInput>;
  /** The round of the draft pick */
  round?: InputMaybe<IntOperationFilterInput>;
  /** The season of the draft pick */
  season?: InputMaybe<IntOperationFilterInput>;
};

/** A draft asset is draft capital owned by a club in any season. */
export type DraftAssetInput = {
  /** The ID of the current owning club */
  clubId: Scalars["UUID"];
  /** The ID of the club the pick originated with */
  originalClubId: Scalars["UUID"];
  /** The overall pick number */
  overall?: InputMaybe<Scalars["Int"]>;
  /** The pick number within the round */
  pickInRound?: InputMaybe<Scalars["Int"]>;
  /** The round of the draft pick */
  round: Scalars["Int"];
  /** The season of the draft pick */
  season: Scalars["Int"];
};

export type DraftAvailability = {
  __typename?: "DraftAvailability";
  pick: DraftPick;
  playerId: Scalars["String"];
};

/** A connection to a list of items. */
export type DraftDashboardPlayerListWithFiltersConnection = {
  __typename?: "DraftDashboardPlayerListWithFiltersConnection";
  /** A list of edges. */
  edges?: Maybe<Array<DraftDashboardPlayerListWithFiltersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Player>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type DraftDashboardPlayerListWithFiltersEdge = {
  __typename?: "DraftDashboardPlayerListWithFiltersEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Player;
};

export type DraftPick = {
  __typename?: "DraftPick";
  /** The number of the pick in the round.  Null in future seasons. */
  number?: Maybe<Scalars["Int"]>;
  /** The pick number overall.  Null in future seasons. */
  overall?: Maybe<Scalars["Int"]>;
  /** The round the pick is in. */
  round?: Maybe<Scalars["Int"]>;
  /** The season the pick belongs to. */
  season: Scalars["Int"];
};

export type DraftPickFilterInput = {
  and?: InputMaybe<Array<DraftPickFilterInput>>;
  /** The number of the pick in the round.  Null in future seasons. */
  number?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DraftPickFilterInput>>;
  /** The pick number overall.  Null in future seasons. */
  overall?: InputMaybe<IntOperationFilterInput>;
  /** The round the pick is in. */
  round?: InputMaybe<IntOperationFilterInput>;
  /** The season the pick belongs to. */
  season?: InputMaybe<IntOperationFilterInput>;
};

export type DraftPickInput = {
  /** The number of the pick in the round.  Null in future seasons. */
  number?: InputMaybe<Scalars["Int"]>;
  /** The pick number overall.  Null in future seasons. */
  overall?: InputMaybe<Scalars["Int"]>;
  /** The round the pick is in. */
  round?: InputMaybe<Scalars["Int"]>;
  /** The season the pick belongs to. */
  season: Scalars["Int"];
};

export type DraftPickTransaction = {
  __typename?: "DraftPickTransaction";
  clubId: Scalars["UUID"];
  draftPicksGained: Array<DraftPick>;
  draftPicksLost: Array<DraftPick>;
};

export type DraftPickTransactionFilterInput = {
  and?: InputMaybe<Array<DraftPickTransactionFilterInput>>;
  clubId?: InputMaybe<UuidOperationFilterInput>;
  draftPicksGained?: InputMaybe<ListFilterInputTypeOfDraftPickFilterInput>;
  draftPicksLost?: InputMaybe<ListFilterInputTypeOfDraftPickFilterInput>;
  or?: InputMaybe<Array<DraftPickTransactionFilterInput>>;
};

export enum DraftPlayerSortCriteriaEnum {
  CLUB_GRADE = "CLUB_GRADE",
  ENSEMBLE_GRADE = "ENSEMBLE_GRADE",
  FOOTBALL_VALUE = "FOOTBALL_VALUE",
  MARVAL_RECOMMENDED = "MARVAL_RECOMMENDED",
}

/** A command to draft a set of players to a given set of picks within a draft scenario. */
export type DraftPlayersRequest = {
  /** The ID of the draft */
  draftId: Scalars["UUID"];
  /** The selected players at their accompanying picks */
  draftedPlayers: Array<DraftedPlayerInput>;
};

/**
 * The draft scenario domain is an event-sourced solution for running a draft that may be a live, simulation, or mock
 * run for a given club.  Draft scenarios contain all the domain logic for a club working in a draft and using analytical
 * model outputs to understand player values and availabilities as well as evaluate trades.
 *
 * The implementation in Marval splits DraftScenario across multiple partial classes to improve readability by grouping
 * partial classes into general areas of functionality.
 *
 * This core DraftScenario implementation contains basic logic for creating and managing draft scenarios on the whole.
 */
export type DraftScenario = {
  __typename?: "DraftScenario";
  /** The current pick on the clock. */
  activePick?: Maybe<CurrentSeasonDraftAsset>;
  /** A history of the overall pick number that is active, used to support undo functionality. */
  activePickOverallHistory: Array<Maybe<Scalars["Int"]>>;
  /** All draft picks for all clubs from the current season and out to the projection horizon. */
  allDraftAssets: Array<DraftAsset>;
  /** Team needs for all clubs, including the client club */
  allTeamNeeds: Array<DraftScenarioTeamNeeds>;
  /**
   * A subset of PinnedTrades where the status of the trade is not invalid, meaning the assets
   * in the trade are still relevant to be traded.
   */
  applicablePinnedTrades: Array<Trade>;
  /**
   * The assumptions currently in effect for this draft, including global assumptions defined in advance and local
   * changes such as players drafted by the club.
   */
  assumptions: Array<Assumption>;
  /** The Marval ID of the club running the draft. */
  club: Club;
  /** The draft assets a club owns in all seasons. */
  clubDraftAssets: Array<DraftAsset>;
  /** The list of players drafted by the club ordered by the order in which they are drafted. */
  clubDraftedPlayers: Array<DraftedPlayer>;
  /** The recommended workspace player sort by position.  If null, then it is sorted by overall, regardless of position. */
  clubGradingPositionRecommendedSort?: Maybe<Scalars["String"]>;
  /** Team needs for the client club */
  clubTeamNeeds: DraftScenarioTeamNeeds;
  /**
   * The club's upcoming draft pick in this current draft.  If the club is on the clock, this is the same as
   * ActivePick.
   */
  clubsNextPick?: Maybe<CurrentSeasonDraftAsset>;
  /** The club's subsequent draft pick in this current draft after ClubsNextPick */
  clubsSubsequentPick?: Maybe<CurrentSeasonDraftAsset>;
  /** Configuration parameters for this instance. */
  configuration: DraftScenarioConfiguration;
  /** The number of total picks in the draft */
  countOfPicksInDraft: Scalars["Int"];
  /** The UTC timestamp when the draft was created */
  createdAt: Scalars["DateTime"];
  /** The user ID responsible for creating this.  Empty Guid if system-generated. */
  createdBy: Scalars["UUID"];
  /** Current roster value based on drafted players and trades thus far */
  currentRosterValue?: Maybe<Value>;
  /** Players currently in the workspace being evaluated */
  currentWorkspacePlayers: Array<CurrentWorkspacePlayer>;
  /** Draft order of remaining picks in the draft, ordered from upcoming to last.  Active pick equals the first item in the list. */
  draftOrder: Array<CurrentSeasonDraftAsset>;
  /** Essentially a deterministic hash of the DraftOrder */
  draftOrderId: Scalars["UUID"];
  /** The type of draft taking place. */
  draftType: DraftTypeEnum;
  /** The list of players that were drafted, ordered by the order in which they are drafted. */
  draftedPlayers: Array<DraftedPlayer>;
  /** Draft picks reflective of draft order that have had data model runs performed on them for evaluation. */
  evaluatedDraftPicks: Array<EvaluatedDraftPick>;
  /** The number of trades executed in the draft scenario */
  executedTradeCount: Scalars["Int"];
  /** A collection of all the trades executed within the draft scenario. */
  executedTrades: Array<Trade>;
  id: Scalars["UUID"];
  /** ID of the last executed trade, if any */
  lastExecutedTradeId?: Maybe<Scalars["Int"]>;
  /** ID of the last pinned trade, if any */
  lastPinnedTradeId?: Maybe<Scalars["Int"]>;
  /** The league ID of the trade that was processed at the time this draft scenario was created */
  lastProcessedLeagueTradeId: Scalars["Int"];
  /** The last time this entity was updated. */
  lastUpdated: Scalars["DateTime"];
  /** The latest ID assigned to a run of the draft scenario models for core changes to state (traded picks and drafted players) */
  latestModelRunRequestId: Scalars["UUID"];
  /** Metadata about the most recent successful model runs */
  modelMetadata: ModelMetadata;
  /** The status of the most recent draft scenario model run for core changes (traded picks and drafted players) */
  modelRunStatus: DraftScenarioDataStatus;
  /** The name of the draft scenario instance. */
  name: Scalars["String"];
  /** The delta of how much roster value has changed since the start in dollars */
  netRosterValueChangeDollars?: Maybe<Scalars["Float"]>;
  /** Positional performance value measurements */
  performanceGaps: Array<PerformanceGap>;
  /** A collection of all the trades that are pinned (but not yet executed) */
  pinnedTrades: Array<Trade>;
  /** Players gained by the club through trades */
  playersGainedInTrades: Array<TradedPlayer>;
  /** Players lost by the club through trades */
  playersLostInTrades: Array<TradedPlayer>;
  /** Recommended players that can be added to the workspace across each position. */
  recommendedWorkspacePlayers: Array<RecommendedWorkspacePlayer>;
  /** The season of the draft this is being run for. */
  season: Scalars["Int"];
  /** The seasonal salary caps for all draft scenario projected seasons, used for dollars calculations. */
  seasonalSalaryCaps: Array<SeasonalSalaryCap>;
  /** Roster value of the roster at the start of the draft */
  startingRosterValue?: Maybe<Value>;
  /** Status of the draft scenario */
  status: DraftScenarioStatus;
  /** A collection of opportunities for the club to trade out of their current pick based on club and owner values of another pick.  Ordered by value. */
  tradeOpportunities: Array<PickTradeOpportunity>;
  /** Current version of the aggregate, automatically set by aggregate commands. */
  version: Scalars["Int"];
  /** The sorted mode of the current workspace players */
  workspaceSortMode: WorkspaceSortMode;
};

/** Various configuration items for a draft scenario. */
export type DraftScenarioConfiguration = {
  __typename?: "DraftScenarioConfiguration";
  /** The availability threshold used to filter players from recommendations */
  availabilityThreshold: Scalars["Float"];
  /** Parameter for the DAM sim on how many best players to evaluate */
  bestPlayersCountPerPick: Scalars["Int"];
  /** The depth of unfulfilled team needs we evaluate when deriving club demand for a pick */
  clubDemandPositionsNeedDepth: Scalars["Int"];
  /** The maximum number of clubs to derive demand for a given draft pick */
  maxClubDemandForPick: Scalars["Int"];
  /** Maximum number of players that can be in the workspace at one time */
  maximumWorkspacePlayers: Scalars["Int"];
  /** The max number of owner players to request from the DAM sim per pick.  Owner pick value derivation is based on these numbers, so higher is likely better. */
  numOwnerPlayers: Scalars["Int"];
  /** How many seasons to project values for */
  projectedSeasons: Scalars["Int"];
  /** How many recommended players for all the various lists, used globally */
  recommendedPlayersCount: Scalars["Int"];
  /** Value 1-32 for the nth best sumer general position group by club across the league to be used to set the target value */
  targetPerformanceValueRank: Scalars["Int"];
  /** The number of picks to evaluate to trade back for */
  tradeBackWindow: Scalars["Int"];
  /** The trade value delta threshold in GAPYPOC.  Trade opps are derived when the net value is greater than the max of this and TradeOpportunityThresholdMinPercent */
  tradeOpportunityThresholdMinGapypoc: Scalars["Float"];
  /** The trade value delta threshold in percent.  Trade opps are derived when the net value is greater than the max of this and TradeOpportunityThresholdMinGapypoc */
  tradeOpportunityThresholdMinPercent: Scalars["Float"];
};

/** Various configuration items for a draft scenario. */
export type DraftScenarioConfigurationFilterInput = {
  and?: InputMaybe<Array<DraftScenarioConfigurationFilterInput>>;
  /** The availability threshold used to filter players from recommendations */
  availabilityThreshold?: InputMaybe<FloatOperationFilterInput>;
  /** Parameter for the DAM sim on how many best players to evaluate */
  bestPlayersCountPerPick?: InputMaybe<IntOperationFilterInput>;
  /** The depth of unfulfilled team needs we evaluate when deriving club demand for a pick */
  clubDemandPositionsNeedDepth?: InputMaybe<IntOperationFilterInput>;
  /** The maximum number of clubs to derive demand for a given draft pick */
  maxClubDemandForPick?: InputMaybe<IntOperationFilterInput>;
  /** Maximum number of players that can be in the workspace at one time */
  maximumWorkspacePlayers?: InputMaybe<IntOperationFilterInput>;
  /** The max number of owner players to request from the DAM sim per pick.  Owner pick value derivation is based on these numbers, so higher is likely better. */
  numOwnerPlayers?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DraftScenarioConfigurationFilterInput>>;
  /** How many seasons to project values for */
  projectedSeasons?: InputMaybe<IntOperationFilterInput>;
  /** How many recommended players for all the various lists, used globally */
  recommendedPlayersCount?: InputMaybe<IntOperationFilterInput>;
  /** Value 1-32 for the nth best sumer general position group by club across the league to be used to set the target value */
  targetPerformanceValueRank?: InputMaybe<IntOperationFilterInput>;
  /** The number of picks to evaluate to trade back for */
  tradeBackWindow?: InputMaybe<IntOperationFilterInput>;
  /** The trade value delta threshold in GAPYPOC.  Trade opps are derived when the net value is greater than the max of this and TradeOpportunityThresholdMinPercent */
  tradeOpportunityThresholdMinGapypoc?: InputMaybe<FloatOperationFilterInput>;
  /** The trade value delta threshold in percent.  Trade opps are derived when the net value is greater than the max of this and TradeOpportunityThresholdMinGapypoc */
  tradeOpportunityThresholdMinPercent?: InputMaybe<FloatOperationFilterInput>;
};

/** Various configuration items for a draft scenario. */
export type DraftScenarioConfigurationInput = {
  /** The availability threshold used to filter players from recommendations */
  availabilityThreshold: Scalars["Float"];
  /** Parameter for the DAM sim on how many best players to evaluate */
  bestPlayersCountPerPick: Scalars["Int"];
  /** The depth of unfulfilled team needs we evaluate when deriving club demand for a pick */
  clubDemandPositionsNeedDepth: Scalars["Int"];
  /** The maximum number of clubs to derive demand for a given draft pick */
  maxClubDemandForPick: Scalars["Int"];
  /** Maximum number of players that can be in the workspace at one time */
  maximumWorkspacePlayers: Scalars["Int"];
  /** The max number of owner players to request from the DAM sim per pick.  Owner pick value derivation is based on these numbers, so higher is likely better. */
  numOwnerPlayers: Scalars["Int"];
  /** How many seasons to project values for */
  projectedSeasons: Scalars["Int"];
  /** How many recommended players for all the various lists, used globally */
  recommendedPlayersCount: Scalars["Int"];
  /** Value 1-32 for the nth best sumer general position group by club across the league to be used to set the target value */
  targetPerformanceValueRank: Scalars["Int"];
  /** The number of picks to evaluate to trade back for */
  tradeBackWindow: Scalars["Int"];
  /** The trade value delta threshold in GAPYPOC.  Trade opps are derived when the net value is greater than the max of this and TradeOpportunityThresholdMinPercent */
  tradeOpportunityThresholdMinGapypoc: Scalars["Float"];
  /** The trade value delta threshold in percent.  Trade opps are derived when the net value is greater than the max of this and TradeOpportunityThresholdMinGapypoc */
  tradeOpportunityThresholdMinPercent: Scalars["Float"];
};

/** Various configuration items for a draft scenario. */
export type DraftScenarioConfigurationSortInput = {
  /** The availability threshold used to filter players from recommendations */
  availabilityThreshold?: InputMaybe<SortEnumType>;
  /** Parameter for the DAM sim on how many best players to evaluate */
  bestPlayersCountPerPick?: InputMaybe<SortEnumType>;
  /** The depth of unfulfilled team needs we evaluate when deriving club demand for a pick */
  clubDemandPositionsNeedDepth?: InputMaybe<SortEnumType>;
  /** The maximum number of clubs to derive demand for a given draft pick */
  maxClubDemandForPick?: InputMaybe<SortEnumType>;
  /** Maximum number of players that can be in the workspace at one time */
  maximumWorkspacePlayers?: InputMaybe<SortEnumType>;
  /** The max number of owner players to request from the DAM sim per pick.  Owner pick value derivation is based on these numbers, so higher is likely better. */
  numOwnerPlayers?: InputMaybe<SortEnumType>;
  /** How many seasons to project values for */
  projectedSeasons?: InputMaybe<SortEnumType>;
  /** How many recommended players for all the various lists, used globally */
  recommendedPlayersCount?: InputMaybe<SortEnumType>;
  /** Value 1-32 for the nth best sumer general position group by club across the league to be used to set the target value */
  targetPerformanceValueRank?: InputMaybe<SortEnumType>;
  /** The number of picks to evaluate to trade back for */
  tradeBackWindow?: InputMaybe<SortEnumType>;
  /** The trade value delta threshold in GAPYPOC.  Trade opps are derived when the net value is greater than the max of this and TradeOpportunityThresholdMinPercent */
  tradeOpportunityThresholdMinGapypoc?: InputMaybe<SortEnumType>;
  /** The trade value delta threshold in percent.  Trade opps are derived when the net value is greater than the max of this and TradeOpportunityThresholdMinGapypoc */
  tradeOpportunityThresholdMinPercent?: InputMaybe<SortEnumType>;
};

/** The state of analytical model data in a draft scenario. */
export enum DraftScenarioDataStatus {
  CURRENT = "CURRENT",
  FAILED = "FAILED",
  STALE = "STALE",
}

export type DraftScenarioDataStatusOperationFilterInput = {
  eq?: InputMaybe<DraftScenarioDataStatus>;
  in?: InputMaybe<Array<DraftScenarioDataStatus>>;
  neq?: InputMaybe<DraftScenarioDataStatus>;
  nin?: InputMaybe<Array<DraftScenarioDataStatus>>;
};

/** A player in hte context of a draft scenario */
export type DraftScenarioExportedPlayer = {
  __typename?: "DraftScenarioExportedPlayer";
  clubAbbreviation: Scalars["String"];
  clubGradeDollars: Scalars["String"];
  clubGradeRaw: Scalars["String"];
  collegeAbbreviation: Scalars["String"];
  gsisPlayerId: Scalars["String"];
  marvalPlayerId: Scalars["String"];
  overallPickNumber: Scalars["String"];
  performanceValue: Scalars["String"];
  pffPlayerId: Scalars["String"];
  playerFirstName: Scalars["String"];
  playerLastName: Scalars["String"];
  position: Scalars["String"];
  sageGradeDollars: Scalars["String"];
  sumerScoutGradeDollars: Scalars["String"];
  sumerScoutGradeRaw: Scalars["String"];
};

/**
 * The draft scenario domain is an event-sourced solution for running a draft that may be a live, simulation, or mock
 * run for a given club.  Draft scenarios contain all the domain logic for a club working in a draft and using analytical
 * model outputs to understand player values and availabilities as well as evaluate trades.
 *
 * The implementation in Marval splits DraftScenario across multiple partial classes to improve readability by grouping
 * partial classes into general areas of functionality.
 *
 * This core DraftScenario implementation contains basic logic for creating and managing draft scenarios on the whole.
 */
export type DraftScenarioFilterInput = {
  /** The current pick on the clock. */
  activePick?: InputMaybe<CurrentSeasonDraftAssetFilterInput>;
  /** A history of the overall pick number that is active, used to support undo functionality. */
  activePickOverallHistory?: InputMaybe<ListIntOperationFilterInput>;
  /** All draft picks for all clubs from the current season and out to the projection horizon. */
  allDraftAssets?: InputMaybe<ListFilterInputTypeOfDraftAssetFilterInput>;
  /** Team needs for all clubs, including the client club */
  allTeamNeeds?: InputMaybe<ListFilterInputTypeOfDraftScenarioTeamNeedsFilterInput>;
  and?: InputMaybe<Array<DraftScenarioFilterInput>>;
  /**
   * A subset of PinnedTrades where the status of the trade is not invalid, meaning the assets
   * in the trade are still relevant to be traded.
   */
  applicablePinnedTrades?: InputMaybe<ListFilterInputTypeOfTradeFilterInput>;
  /**
   * The assumptions currently in effect for this draft, including global assumptions defined in advance and local
   * changes such as players drafted by the club.
   */
  assumptions?: InputMaybe<ListFilterInputTypeOfAssumptionFilterInput>;
  /** The draft assets a club owns in all seasons. */
  clubDraftAssets?: InputMaybe<ListFilterInputTypeOfDraftAssetFilterInput>;
  /** The list of players drafted by the club ordered by the order in which they are drafted. */
  clubDraftedPlayers?: InputMaybe<ListFilterInputTypeOfDraftedPlayerFilterInput>;
  /** The recommended workspace player sort by position.  If null, then it is sorted by overall, regardless of position. */
  clubGradingPositionRecommendedSort?: InputMaybe<StringOperationFilterInput>;
  /** The Marval ID of the club running the draft. */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  /** Team needs for the client club */
  clubTeamNeeds?: InputMaybe<DraftScenarioTeamNeedsFilterInput>;
  /**
   * The club's upcoming draft pick in this current draft.  If the club is on the clock, this is the same as
   * ActivePick.
   */
  clubsNextPick?: InputMaybe<CurrentSeasonDraftAssetFilterInput>;
  /** The club's subsequent draft pick in this current draft after ClubsNextPick */
  clubsSubsequentPick?: InputMaybe<CurrentSeasonDraftAssetFilterInput>;
  /** Configuration parameters for this instance. */
  configuration?: InputMaybe<DraftScenarioConfigurationFilterInput>;
  /** The number of total picks in the draft */
  countOfPicksInDraft?: InputMaybe<IntOperationFilterInput>;
  /** The UTC timestamp when the draft was created */
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** The user ID responsible for creating this.  Empty Guid if system-generated. */
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  /** Current roster value based on drafted players and trades thus far */
  currentRosterValue?: InputMaybe<ValueFilterInput>;
  /** Players currently in the workspace being evaluated */
  currentWorkspacePlayers?: InputMaybe<ListFilterInputTypeOfCurrentWorkspacePlayerFilterInput>;
  /** Draft order of remaining picks in the draft, ordered from upcoming to last.  Active pick equals the first item in the list. */
  draftOrder?: InputMaybe<ListFilterInputTypeOfCurrentSeasonDraftAssetFilterInput>;
  /** Essentially a deterministic hash of the DraftOrder */
  draftOrderId?: InputMaybe<UuidOperationFilterInput>;
  /** The type of draft taking place. */
  draftType?: InputMaybe<DraftTypeEnumOperationFilterInput>;
  /** The list of players that were drafted, ordered by the order in which they are drafted. */
  draftedPlayers?: InputMaybe<ListFilterInputTypeOfDraftedPlayerFilterInput>;
  /** Draft picks reflective of draft order that have had data model runs performed on them for evaluation. */
  evaluatedDraftPicks?: InputMaybe<ListFilterInputTypeOfEvaluatedDraftPickFilterInput>;
  /** The number of trades executed in the draft scenario */
  executedTradeCount?: InputMaybe<IntOperationFilterInput>;
  /** A collection of all the trades executed within the draft scenario. */
  executedTrades?: InputMaybe<ListFilterInputTypeOfTradeFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** ID of the last executed trade, if any */
  lastExecutedTradeId?: InputMaybe<IntOperationFilterInput>;
  /** ID of the last pinned trade, if any */
  lastPinnedTradeId?: InputMaybe<IntOperationFilterInput>;
  /** The league ID of the trade that was processed at the time this draft scenario was created */
  lastProcessedLeagueTradeId?: InputMaybe<IntOperationFilterInput>;
  /** The last time this entity was updated. */
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** The latest ID assigned to a run of the draft scenario models for core changes to state (traded picks and drafted players) */
  latestModelRunRequestId?: InputMaybe<UuidOperationFilterInput>;
  /** Metadata about the most recent successful model runs */
  modelMetadata?: InputMaybe<ModelMetadataFilterInput>;
  /** The status of the most recent draft scenario model run for core changes (traded picks and drafted players) */
  modelRunStatus?: InputMaybe<DraftScenarioDataStatusOperationFilterInput>;
  /** The name of the draft scenario instance. */
  name?: InputMaybe<StringOperationFilterInput>;
  /** The delta of how much roster value has changed since the start in dollars */
  netRosterValueChangeDollars?: InputMaybe<FloatOperationFilterInput>;
  or?: InputMaybe<Array<DraftScenarioFilterInput>>;
  /** Positional performance value measurements */
  performanceGaps?: InputMaybe<ListFilterInputTypeOfPerformanceGapFilterInput>;
  /** A collection of all the trades that are pinned (but not yet executed) */
  pinnedTrades?: InputMaybe<ListFilterInputTypeOfTradeFilterInput>;
  /** Players gained by the club through trades */
  playersGainedInTrades?: InputMaybe<ListFilterInputTypeOfTradedPlayerFilterInput>;
  /** Players lost by the club through trades */
  playersLostInTrades?: InputMaybe<ListFilterInputTypeOfTradedPlayerFilterInput>;
  /** Recommended players that can be added to the workspace across each position. */
  recommendedWorkspacePlayers?: InputMaybe<ListFilterInputTypeOfRecommendedWorkspacePlayerFilterInput>;
  /** The season of the draft this is being run for. */
  season?: InputMaybe<IntOperationFilterInput>;
  /** The seasonal salary caps for all draft scenario projected seasons, used for dollars calculations. */
  seasonalSalaryCaps?: InputMaybe<ListFilterInputTypeOfSeasonalSalaryCapFilterInput>;
  /** Roster value of the roster at the start of the draft */
  startingRosterValue?: InputMaybe<ValueFilterInput>;
  /** Status of the draft scenario */
  status?: InputMaybe<DraftScenarioStatusOperationFilterInput>;
  /** A collection of opportunities for the club to trade out of their current pick based on club and owner values of another pick.  Ordered by value. */
  tradeOpportunities?: InputMaybe<ListFilterInputTypeOfPickTradeOpportunityFilterInput>;
  /** Current version of the aggregate, automatically set by aggregate commands. */
  version?: InputMaybe<IntOperationFilterInput>;
  /** The sorted mode of the current workspace players */
  workspaceSortMode?: InputMaybe<WorkspaceSortModeOperationFilterInput>;
};

/** A need at a given position. */
export type DraftScenarioPositionalNeed = {
  __typename?: "DraftScenarioPositionalNeed";
  /** Whether or not this specific need has been fulfilled. */
  isFulfilled: Scalars["Boolean"];
  /** The ordered rank of the need (0-based) */
  rank: Scalars["Int"];
  /** The sumer general position */
  sumerGeneralPositionShortName: Scalars["String"];
};

/** A need at a given position. */
export type DraftScenarioPositionalNeedFilterInput = {
  and?: InputMaybe<Array<DraftScenarioPositionalNeedFilterInput>>;
  /** Whether or not this specific need has been fulfilled. */
  isFulfilled?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DraftScenarioPositionalNeedFilterInput>>;
  /** The ordered rank of the need (0-based) */
  rank?: InputMaybe<IntOperationFilterInput>;
  /** The sumer general position */
  sumerGeneralPositionShortName?: InputMaybe<StringOperationFilterInput>;
};

/** A need at a given position. */
export type DraftScenarioPositionalNeedInput = {
  /** Whether or not this specific need has been fulfilled. */
  isFulfilled: Scalars["Boolean"];
  /** The ordered rank of the need (0-based) */
  rank: Scalars["Int"];
  /** The sumer general position */
  sumerGeneralPositionShortName: Scalars["String"];
};

/** Read model projection of a draft scenario for efficient queries. */
export type DraftScenarioReadModel = {
  __typename?: "DraftScenarioReadModel";
  clubId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["UUID"];
  currentRosterValueDollars?: Maybe<Scalars["Float"]>;
  id: Scalars["UUID"];
  /** The overall of the last player drafted.  Only correct in live drafts. */
  lastDraftedPlayerAtOverall: Scalars["Int"];
  /** The most recently processed league trade */
  lastLeagueTradeId: Scalars["Int"];
  /** The league ID last processed when the draft first started */
  lastLeagueTradeIdAtStart: Scalars["Int"];
  /** The general last updated timestamp of the scenario.  Not guaranteed to be exact as not every event is tracked for this like the aggregate */
  lastUpdated: Scalars["DateTime"];
  name: Scalars["String"];
  season: Scalars["Int"];
  startingRosterValueDollars?: Maybe<Scalars["Float"]>;
  status: Scalars["String"];
  tradesExecuted: Scalars["Int"];
  type: Scalars["String"];
};

/** Read model projection of a draft scenario for efficient queries. */
export type DraftScenarioReadModelFilterInput = {
  and?: InputMaybe<Array<DraftScenarioReadModelFilterInput>>;
  clubId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  currentRosterValueDollars?: InputMaybe<FloatOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** The overall of the last player drafted.  Only correct in live drafts. */
  lastDraftedPlayerAtOverall?: InputMaybe<IntOperationFilterInput>;
  /** The most recently processed league trade */
  lastLeagueTradeId?: InputMaybe<IntOperationFilterInput>;
  /** The league ID last processed when the draft first started */
  lastLeagueTradeIdAtStart?: InputMaybe<IntOperationFilterInput>;
  /** The general last updated timestamp of the scenario.  Not guaranteed to be exact as not every event is tracked for this like the aggregate */
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DraftScenarioReadModelFilterInput>>;
  season?: InputMaybe<IntOperationFilterInput>;
  startingRosterValueDollars?: InputMaybe<FloatOperationFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  tradesExecuted?: InputMaybe<IntOperationFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
};

/** Read model projection of a draft scenario for efficient queries. */
export type DraftScenarioReadModelSortInput = {
  clubId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  currentRosterValueDollars?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** The overall of the last player drafted.  Only correct in live drafts. */
  lastDraftedPlayerAtOverall?: InputMaybe<SortEnumType>;
  /** The most recently processed league trade */
  lastLeagueTradeId?: InputMaybe<SortEnumType>;
  /** The league ID last processed when the draft first started */
  lastLeagueTradeIdAtStart?: InputMaybe<SortEnumType>;
  /** The general last updated timestamp of the scenario.  Not guaranteed to be exact as not every event is tracked for this like the aggregate */
  lastUpdated?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  startingRosterValueDollars?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  tradesExecuted?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

/**
 * The draft scenario domain is an event-sourced solution for running a draft that may be a live, simulation, or mock
 * run for a given club.  Draft scenarios contain all the domain logic for a club working in a draft and using analytical
 * model outputs to understand player values and availabilities as well as evaluate trades.
 *
 * The implementation in Marval splits DraftScenario across multiple partial classes to improve readability by grouping
 * partial classes into general areas of functionality.
 *
 * This core DraftScenario implementation contains basic logic for creating and managing draft scenarios on the whole.
 */
export type DraftScenarioSortInput = {
  /** The current pick on the clock. */
  activePick?: InputMaybe<CurrentSeasonDraftAssetSortInput>;
  /** The recommended workspace player sort by position.  If null, then it is sorted by overall, regardless of position. */
  clubGradingPositionRecommendedSort?: InputMaybe<SortEnumType>;
  /** The Marval ID of the club running the draft. */
  clubId?: InputMaybe<SortEnumType>;
  /** Team needs for the client club */
  clubTeamNeeds?: InputMaybe<DraftScenarioTeamNeedsSortInput>;
  /**
   * The club's upcoming draft pick in this current draft.  If the club is on the clock, this is the same as
   * ActivePick.
   */
  clubsNextPick?: InputMaybe<CurrentSeasonDraftAssetSortInput>;
  /** The club's subsequent draft pick in this current draft after ClubsNextPick */
  clubsSubsequentPick?: InputMaybe<CurrentSeasonDraftAssetSortInput>;
  /** Configuration parameters for this instance. */
  configuration?: InputMaybe<DraftScenarioConfigurationSortInput>;
  /** The number of total picks in the draft */
  countOfPicksInDraft?: InputMaybe<SortEnumType>;
  /** The UTC timestamp when the draft was created */
  createdAt?: InputMaybe<SortEnumType>;
  /** The user ID responsible for creating this.  Empty Guid if system-generated. */
  createdBy?: InputMaybe<SortEnumType>;
  /** Current roster value based on drafted players and trades thus far */
  currentRosterValue?: InputMaybe<ValueSortInput>;
  /** Essentially a deterministic hash of the DraftOrder */
  draftOrderId?: InputMaybe<SortEnumType>;
  /** The type of draft taking place. */
  draftType?: InputMaybe<SortEnumType>;
  /** The number of trades executed in the draft scenario */
  executedTradeCount?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** ID of the last executed trade, if any */
  lastExecutedTradeId?: InputMaybe<SortEnumType>;
  /** ID of the last pinned trade, if any */
  lastPinnedTradeId?: InputMaybe<SortEnumType>;
  /** The league ID of the trade that was processed at the time this draft scenario was created */
  lastProcessedLeagueTradeId?: InputMaybe<SortEnumType>;
  /** The last time this entity was updated. */
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The latest ID assigned to a run of the draft scenario models for core changes to state (traded picks and drafted players) */
  latestModelRunRequestId?: InputMaybe<SortEnumType>;
  /** Metadata about the most recent successful model runs */
  modelMetadata?: InputMaybe<ModelMetadataSortInput>;
  /** The status of the most recent draft scenario model run for core changes (traded picks and drafted players) */
  modelRunStatus?: InputMaybe<SortEnumType>;
  /** The name of the draft scenario instance. */
  name?: InputMaybe<SortEnumType>;
  /** The delta of how much roster value has changed since the start in dollars */
  netRosterValueChangeDollars?: InputMaybe<SortEnumType>;
  /** The season of the draft this is being run for. */
  season?: InputMaybe<SortEnumType>;
  /** Roster value of the roster at the start of the draft */
  startingRosterValue?: InputMaybe<ValueSortInput>;
  /** Status of the draft scenario */
  status?: InputMaybe<SortEnumType>;
  /** Current version of the aggregate, automatically set by aggregate commands. */
  version?: InputMaybe<SortEnumType>;
  /** The sorted mode of the current workspace players */
  workspaceSortMode?: InputMaybe<SortEnumType>;
};

/** General status of a draft scenario */
export enum DraftScenarioStatus {
  ACTIVE = "ACTIVE",
  COMPLETE = "COMPLETE",
  DISCARDED = "DISCARDED",
}

export type DraftScenarioStatusOperationFilterInput = {
  eq?: InputMaybe<DraftScenarioStatus>;
  in?: InputMaybe<Array<DraftScenarioStatus>>;
  neq?: InputMaybe<DraftScenarioStatus>;
  nin?: InputMaybe<Array<DraftScenarioStatus>>;
};

/** A team's needs at ranked positions in the draft */
export type DraftScenarioTeamNeeds = {
  __typename?: "DraftScenarioTeamNeeds";
  /** The club that has the team needs */
  club: Club;
  /** The ranked list of most needed positions.  Can contain duplicates. */
  positionalNeeds: Array<DraftScenarioPositionalNeed>;
};

/** A team's needs at ranked positions in the draft */
export type DraftScenarioTeamNeedsFilterInput = {
  and?: InputMaybe<Array<DraftScenarioTeamNeedsFilterInput>>;
  /** The club that has the team needs */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<DraftScenarioTeamNeedsFilterInput>>;
  /** The ranked list of most needed positions.  Can contain duplicates. */
  positionalNeeds?: InputMaybe<ListFilterInputTypeOfDraftScenarioPositionalNeedFilterInput>;
};

/** A team's needs at ranked positions in the draft */
export type DraftScenarioTeamNeedsInput = {
  /** The club that has the team needs */
  clubId: Scalars["UUID"];
  /** The ranked list of most needed positions.  Can contain duplicates. */
  positionalNeeds: Array<DraftScenarioPositionalNeedInput>;
};

/** A team's needs at ranked positions in the draft */
export type DraftScenarioTeamNeedsSortInput = {
  /** The club that has the team needs */
  clubId?: InputMaybe<SortEnumType>;
};

/** The type of draft scenario */
export enum DraftTypeEnum {
  LIVE = "LIVE",
  MOCK = "MOCK",
  SIMULATION = "SIMULATION",
}

export type DraftTypeEnumOperationFilterInput = {
  eq?: InputMaybe<DraftTypeEnum>;
  in?: InputMaybe<Array<DraftTypeEnum>>;
  neq?: InputMaybe<DraftTypeEnum>;
  nin?: InputMaybe<Array<DraftTypeEnum>>;
};

/** A player that is evaluated at any draft pick. */
export type DraftablePlayerAtPick = {
  __typename?: "DraftablePlayerAtPick";
  /** The player's availability at some draft pick.  Null if outside the availability window. */
  availability?: Maybe<Availability>;
  /** The player's fair value on the market */
  marketValue: Value;
  /** The ID of the player */
  player: Player;
  /** The player's expected draft position before the draft starts.  Null implies we expect them to be undrafted */
  preDraftExpectedPick?: Maybe<Scalars["Int"]>;
};

/** A player that is evaluated at any draft pick. */
export type DraftablePlayerAtPickFilterInput = {
  and?: InputMaybe<Array<DraftablePlayerAtPickFilterInput>>;
  /** The player's availability at some draft pick.  Null if outside the availability window. */
  availability?: InputMaybe<AvailabilityFilterInput>;
  /** The player's fair value on the market */
  marketValue?: InputMaybe<ValueFilterInput>;
  or?: InputMaybe<Array<DraftablePlayerAtPickFilterInput>>;
  /** The ID of the player */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** The player's expected draft position before the draft starts.  Null implies we expect them to be undrafted */
  preDraftExpectedPick?: InputMaybe<IntOperationFilterInput>;
};

/**
 * A draftable player pool is a collection of players and their values (like availability and market value) at a given pick
 * in the current draft.
 */
export type DraftablePlayerPool = {
  __typename?: "DraftablePlayerPool";
  /** The draftable players at the specified pick */
  atPick?: Maybe<CurrentSeasonDraftAsset>;
  /** The collection of draftable players the club could draft */
  players: Array<ClubDraftablePlayerAtPick>;
};

export enum DraftableStatus {
  ASSUME_DO_NOT_DRAFT = "ASSUME_DO_NOT_DRAFT",
  DRAFTABLE = "DRAFTABLE",
  UNDRAFTABLE = "UNDRAFTABLE",
}

export type DraftableStatusOperationFilterInput = {
  eq?: InputMaybe<DraftableStatus>;
  in?: InputMaybe<Array<DraftableStatus>>;
  neq?: InputMaybe<DraftableStatus>;
  nin?: InputMaybe<Array<DraftableStatus>>;
};

/** Encapsulates a player that was drafted within a DraftScenario */
export type DraftedPlayer = {
  __typename?: "DraftedPlayer";
  /** The pick at which the player was chosen */
  draftPick: CurrentSeasonDraftAsset;
  /** The Marval ID of the player drafted */
  player: Player;
};

/** Encapsulates a player that was drafted within a DraftScenario */
export type DraftedPlayerFilterInput = {
  and?: InputMaybe<Array<DraftedPlayerFilterInput>>;
  /** The pick at which the player was chosen */
  draftPick?: InputMaybe<CurrentSeasonDraftAssetFilterInput>;
  or?: InputMaybe<Array<DraftedPlayerFilterInput>>;
  /** The Marval ID of the player drafted */
  playerId?: InputMaybe<UuidOperationFilterInput>;
};

/** Represents a player being drafted at some pick */
export type DraftedPlayerInput = {
  /** The club that selected the player.  Will be ignored in a sim. */
  clubId?: InputMaybe<Scalars["UUID"]>;
  /** The overall pick number of the relevant pick */
  overall: Scalars["Int"];
  /** The Marval ID of the player */
  playerId: Scalars["UUID"];
  /** The sumer general position the player plays */
  sumerGeneralPositionShortName: Scalars["String"];
};

export enum EligibilityType {
  FR = "FR",
  G = "G",
  JR = "JR",
  SO = "SO",
  SR = "SR",
}

/** Specifies the type of evaluation in which grading will take place against. */
export type EvalType = {
  __typename?: "EvalType";
  created: Scalars["DateTime"];
  /** Details about what type of evaluation - a scout or coach */
  description: Scalars["String"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Details about what type of evaluation - college or pro */
  type: EvalTypeEnum;
};

/** Denotes an eval type of pro, college, etc. */
export enum EvalTypeEnum {
  COLLEGE_CROSS_CHECK = "COLLEGE_CROSS_CHECK",
  COLLEGE_SCOUT = "COLLEGE_SCOUT",
  PRO_CROSS_CHECK = "PRO_CROSS_CHECK",
  PRO_SCOUT = "PRO_SCOUT",
}

export type EvalTypeEnumOperationFilterInput = {
  eq?: InputMaybe<EvalTypeEnum>;
  in?: InputMaybe<Array<EvalTypeEnum>>;
  neq?: InputMaybe<EvalTypeEnum>;
  nin?: InputMaybe<Array<EvalTypeEnum>>;
};

/** Specifies the type of evaluation in which grading will take place against. */
export type EvalTypeFilterInput = {
  and?: InputMaybe<Array<EvalTypeFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Details about what type of evaluation - a scout or coach */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvalTypeFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Details about what type of evaluation - college or pro */
  type?: InputMaybe<EvalTypeEnumOperationFilterInput>;
};

/** Specifies the type of evaluation in which grading will take place against. */
export type EvalTypeSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** Details about what type of evaluation - a scout or coach */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Details about what type of evaluation - college or pro */
  type?: InputMaybe<SortEnumType>;
};

/** A wrapper of a draft asset being traded. */
export type EvaluatedDraftAsset = {
  __typename?: "EvaluatedDraftAsset";
  /** The pick being traded */
  draftAsset: DraftAsset;
  /** The JJ points associated with the individual pick.  Set internally on trade creation. */
  jimmyJohnsonPoints: Scalars["Float"];
  /** The OTC points associated with the individual pick  Set internally on trade creation. */
  otcPoints: Scalars["Float"];
};

/** A wrapper of a draft asset being traded. */
export type EvaluatedDraftAssetFilterInput = {
  and?: InputMaybe<Array<EvaluatedDraftAssetFilterInput>>;
  /** The pick being traded */
  draftAsset?: InputMaybe<DraftAssetFilterInput>;
  /** The JJ points associated with the individual pick.  Set internally on trade creation. */
  jimmyJohnsonPoints?: InputMaybe<FloatOperationFilterInput>;
  or?: InputMaybe<Array<EvaluatedDraftAssetFilterInput>>;
  /** The OTC points associated with the individual pick  Set internally on trade creation. */
  otcPoints?: InputMaybe<FloatOperationFilterInput>;
};

/** A draft pick that has had an analytical evaluation performed on it to derive various metrics surrounding its value. */
export type EvaluatedDraftPick = {
  __typename?: "EvaluatedDraftPick";
  /** Players recommended to be drafted by the club */
  clubRecommendedPlayers: Array<ClubDraftablePlayerAtPick>;
  /** The value of the pick to the club.  Null when outside availability window. */
  clubValue?: Maybe<ClubPickValue>;
  /** The value of the pick to the current owner of the pick.  Null outside of availability window. */
  currentOwnerValue?: Maybe<Value>;
  /** The draft pick in the current season */
  draftPick: CurrentSeasonDraftAsset;
  /** If true, the pick is close enough to the active pick to contain availability information */
  isInAvailabilityWindow: Scalars["Boolean"];
  /** This pick is a candidate to be traded for/by the club */
  isTradeOpportunity: Scalars["Boolean"];
  /** The best players available to be drafted by the league, only when club owns the pick */
  leagueBestAvailablePlayers: Array<DraftablePlayerAtPick>;
  /** The value of the pick to the rest of the league, only for club's next pick, otherwise null. */
  leagueValue?: Maybe<Value>;
  /** The historical value of the pick to the general market */
  marketValue: Value;
  /** The players most likely to be chosen by the club owning the pick */
  ownerMostLikelyPlayers: Array<DraftablePlayerAtPick>;
  /** The team needs for the club that owns the pick */
  teamNeeds: DraftScenarioTeamNeeds;
  /** Club IDs that may have interesting in the pick, only when club owns the pick */
  topClubDemandForPick: Array<Club>;
};

/** A draft pick that has had an analytical evaluation performed on it to derive various metrics surrounding its value. */
export type EvaluatedDraftPickFilterInput = {
  and?: InputMaybe<Array<EvaluatedDraftPickFilterInput>>;
  /** Players recommended to be drafted by the club */
  clubRecommendedPlayers?: InputMaybe<ListFilterInputTypeOfClubDraftablePlayerAtPickFilterInput>;
  /** The value of the pick to the club.  Null when outside availability window. */
  clubValue?: InputMaybe<ClubPickValueFilterInput>;
  /** The value of the pick to the current owner of the pick.  Null outside of availability window. */
  currentOwnerValue?: InputMaybe<ValueFilterInput>;
  /** The draft pick in the current season */
  draftPick?: InputMaybe<CurrentSeasonDraftAssetFilterInput>;
  /** If true, the pick is close enough to the active pick to contain availability information */
  isInAvailabilityWindow?: InputMaybe<BooleanOperationFilterInput>;
  /** This pick is a candidate to be traded for/by the club */
  isTradeOpportunity?: InputMaybe<BooleanOperationFilterInput>;
  /** The best players available to be drafted by the league, only when club owns the pick */
  leagueBestAvailablePlayers?: InputMaybe<ListFilterInputTypeOfDraftablePlayerAtPickFilterInput>;
  /** The value of the pick to the rest of the league, only for club's next pick, otherwise null. */
  leagueValue?: InputMaybe<ValueFilterInput>;
  /** The historical value of the pick to the general market */
  marketValue?: InputMaybe<ValueFilterInput>;
  or?: InputMaybe<Array<EvaluatedDraftPickFilterInput>>;
  /** The players most likely to be chosen by the club owning the pick */
  ownerMostLikelyPlayers?: InputMaybe<ListFilterInputTypeOfDraftablePlayerAtPickFilterInput>;
  /** The team needs for the club that owns the pick */
  teamNeeds?: InputMaybe<DraftScenarioTeamNeedsFilterInput>;
  /** Club IDs that may have interesting in the pick, only when club owns the pick */
  topClubIdsDemandForPick?: InputMaybe<ListUuidOperationFilterInput>;
};

/**
 * This represents an evaluation of a given player.  An evaluation is an aggregation of grades across
 * different configured metrics.
 */
export type Evaluation = {
  __typename?: "Evaluation";
  /** Player alerts associated with evaluation */
  alerts: Array<EvaluationAlert>;
  /** One-liner for the player. More concise than the summary */
  bottomLine?: Maybe<Scalars["String"]>;
  /** Player team fits associated with evaluation */
  clubFits: Array<EvaluationClubFit>;
  created: Scalars["DateTime"];
  /** The evaluation type that determines what criteria is graded within this evaluation */
  evalType: EvalType;
  /** Foreign key to EvalType */
  evalTypeId: Scalars["UUID"];
  finalizedOn?: Maybe<Scalars["DateTime"]>;
  /** Player fits associated with evaluation */
  fits: Array<EvaluationFit>;
  /** Games graded in for evaluation */
  gameGrades: Array<EvaluationGame>;
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  /** Flag determining if the evaluation is marked as final. If true, it will no longer be editable. */
  isFinal: Scalars["Boolean"];
  /** Flag to track primary evaluation */
  isPrimary: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** The player being evaluated */
  player: Player;
  /** Foreign key to Player */
  playerId: Scalars["UUID"];
  /** The position being evaluated */
  position: Position;
  /** Foreign key to Position */
  positionId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  scoutGrade?: Maybe<ScoutGrade>;
  /** The overall grade for this evaluation */
  scoutGradeId?: Maybe<Scalars["UUID"]>;
  /** The season of which the player is being evaluated in */
  season: Scalars["Int"];
  /** Evaluation Summary */
  summary?: Maybe<Scalars["String"]>;
  /** Evaluation trait grades */
  traitGrades: Array<EvaluationTrait>;
  /** Evaluation trait group grades */
  traitGroupGrades: Array<EvaluationTraitGroup>;
  user: User;
  /** Internal Marval ID of the user that created the evaluation */
  userId: Scalars["UUID"];
};

export type EvaluationAlert = {
  __typename?: "EvaluationAlert";
  alert: Alert;
  alertId: Scalars["UUID"];
  created: Scalars["DateTime"];
  evaluation: Evaluation;
  evaluationId: Scalars["UUID"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
};

export type EvaluationAlertFilterInput = {
  alert?: InputMaybe<AlertFilterInput>;
  alertId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<EvaluationAlertFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  evaluation?: InputMaybe<EvaluationFilterInput>;
  evaluationId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationAlertFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

export type EvaluationClubFit = {
  __typename?: "EvaluationClubFit";
  club: Club;
  clubId: Scalars["UUID"];
  created: Scalars["DateTime"];
  evaluation: Evaluation;
  /** Evaluation game grade belongs */
  evaluationId: Scalars["UUID"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
};

export type EvaluationClubFitFilterInput = {
  and?: InputMaybe<Array<EvaluationClubFitFilterInput>>;
  club?: InputMaybe<ClubFilterInput>;
  clubId?: InputMaybe<UuidOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  evaluation?: InputMaybe<EvaluationFilterInput>;
  /** Evaluation game grade belongs */
  evaluationId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationClubFitFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

export type EvaluationDetailsInput = {
  bottomLine?: InputMaybe<Scalars["String"]>;
  isFinal?: InputMaybe<Scalars["Boolean"]>;
  playerId?: InputMaybe<Scalars["UUID"]>;
  /** Position being graded */
  positionId?: InputMaybe<Scalars["UUID"]>;
  /** Final Grade of evaluation */
  scoutGradeId?: InputMaybe<Scalars["UUID"]>;
  /** The season of the evaluation.  If null, the current season will be used. */
  season?: InputMaybe<Scalars["Int"]>;
  summary?: InputMaybe<Scalars["String"]>;
};

/**
 * This represents an evaluation of a given player.  An evaluation is an aggregation of grades across
 * different configured metrics.
 */
export type EvaluationFilterInput = {
  /** Player alerts associated with evaluation */
  alerts?: InputMaybe<ListFilterInputTypeOfEvaluationAlertFilterInput>;
  and?: InputMaybe<Array<EvaluationFilterInput>>;
  /** One-liner for the player. More concise than the summary */
  bottomLine?: InputMaybe<StringOperationFilterInput>;
  /** Player team fits associated with evaluation */
  clubFits?: InputMaybe<ListFilterInputTypeOfEvaluationClubFitFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** The evaluation type that determines what criteria is graded within this evaluation */
  evalType?: InputMaybe<EvalTypeFilterInput>;
  /** Foreign key to EvalType */
  evalTypeId?: InputMaybe<UuidOperationFilterInput>;
  finalizedOn?: InputMaybe<DateTimeOperationFilterInput>;
  /** Player fits associated with evaluation */
  fits?: InputMaybe<ListFilterInputTypeOfEvaluationFitFilterInput>;
  /** Games graded in for evaluation */
  gameGrades?: InputMaybe<ListFilterInputTypeOfEvaluationGameFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  /** Flag determining if the evaluation is marked as final. If true, it will no longer be editable. */
  isFinal?: InputMaybe<BooleanOperationFilterInput>;
  /** Flag to track primary evaluation */
  isPrimary?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationFilterInput>>;
  /** The player being evaluated */
  player?: InputMaybe<PlayerFilterInput>;
  /** Foreign key to Player */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** The position being evaluated */
  position?: InputMaybe<PositionFilterInput>;
  /** Foreign key to Position */
  positionId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  scoutGrade?: InputMaybe<ScoutGradeFilterInput>;
  /** The overall grade for this evaluation */
  scoutGradeId?: InputMaybe<UuidOperationFilterInput>;
  /** The season of which the player is being evaluated in */
  season?: InputMaybe<IntOperationFilterInput>;
  /** Evaluation Summary */
  summary?: InputMaybe<StringOperationFilterInput>;
  /** Evaluation trait grades */
  traitGrades?: InputMaybe<ListFilterInputTypeOfEvaluationTraitFilterInput>;
  /** Evaluation trait group grades */
  traitGroupGrades?: InputMaybe<ListFilterInputTypeOfEvaluationTraitGroupFilterInput>;
  /** Internal Marval ID of the user that created the evaluation */
  userId?: InputMaybe<UuidOperationFilterInput>;
};

/** Evaluation player fits (if more than one) */
export type EvaluationFit = {
  __typename?: "EvaluationFit";
  created: Scalars["DateTime"];
  evaluation: Evaluation;
  evaluationId: Scalars["UUID"];
  fit: Fit;
  fitId: Scalars["UUID"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** The priority order of the fit where 0 is the highest priority, 1 is the next highest, etc. */
  priority: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
};

/** Evaluation player fits (if more than one) */
export type EvaluationFitFilterInput = {
  and?: InputMaybe<Array<EvaluationFitFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  evaluation?: InputMaybe<EvaluationFilterInput>;
  evaluationId?: InputMaybe<UuidOperationFilterInput>;
  fit?: InputMaybe<FitFilterInput>;
  fitId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationFitFilterInput>>;
  /** The priority order of the fit where 0 is the highest priority, 1 is the next highest, etc. */
  priority?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

/** Table to store game grades */
export type EvaluationGame = {
  __typename?: "EvaluationGame";
  /** Comments about game performance */
  comments?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  evaluation: Evaluation;
  /** Evaluation game grade belongs */
  evaluationId: Scalars["UUID"];
  game: Game;
  /** Game associated to evaluation */
  gameId: Scalars["UUID"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  scoutGrade?: Maybe<ScoutGrade>;
  /** Grade given to game */
  scoutGradeId?: Maybe<Scalars["UUID"]>;
  update: EvaluationGame;
};

/** Table to store game grades */
export type EvaluationGameUpdateArgs = {
  details: EvaluationGameDetailsInput;
};

export type EvaluationGameDetailsInput = {
  comments?: InputMaybe<Scalars["String"]>;
  evaluationId: Scalars["UUID"];
  gameId: Scalars["UUID"];
  scoutGradeId?: InputMaybe<Scalars["UUID"]>;
};

/** Table to store game grades */
export type EvaluationGameFilterInput = {
  and?: InputMaybe<Array<EvaluationGameFilterInput>>;
  /** Comments about game performance */
  comments?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  evaluation?: InputMaybe<EvaluationFilterInput>;
  /** Evaluation game grade belongs */
  evaluationId?: InputMaybe<UuidOperationFilterInput>;
  /** Game associated to evaluation */
  gameId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationGameFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  scoutGrade?: InputMaybe<ScoutGradeFilterInput>;
  /** Grade given to game */
  scoutGradeId?: InputMaybe<UuidOperationFilterInput>;
};

/** Table to store game grades */
export type EvaluationGameSortInput = {
  /** Comments about game performance */
  comments?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  evaluation?: InputMaybe<EvaluationSortInput>;
  /** Evaluation game grade belongs */
  evaluationId?: InputMaybe<SortEnumType>;
  /** Game associated to evaluation */
  gameId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  scoutGrade?: InputMaybe<ScoutGradeSortInput>;
  /** Grade given to game */
  scoutGradeId?: InputMaybe<SortEnumType>;
};

/**
 * This represents an evaluation of a given player.  An evaluation is an aggregation of grades across
 * different configured metrics.
 */
export type EvaluationSortInput = {
  /** One-liner for the player. More concise than the summary */
  bottomLine?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** The evaluation type that determines what criteria is graded within this evaluation */
  evalType?: InputMaybe<EvalTypeSortInput>;
  /** Foreign key to EvalType */
  evalTypeId?: InputMaybe<SortEnumType>;
  finalizedOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  /** Flag determining if the evaluation is marked as final. If true, it will no longer be editable. */
  isFinal?: InputMaybe<SortEnumType>;
  /** Flag to track primary evaluation */
  isPrimary?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The player being evaluated */
  player?: InputMaybe<PlayerSortInput>;
  /** Foreign key to Player */
  playerId?: InputMaybe<SortEnumType>;
  /** The position being evaluated */
  position?: InputMaybe<PositionSortInput>;
  /** Foreign key to Position */
  positionId?: InputMaybe<SortEnumType>;
  scoutGrade?: InputMaybe<ScoutGradeSortInput>;
  /** The overall grade for this evaluation */
  scoutGradeId?: InputMaybe<SortEnumType>;
  /** The season of which the player is being evaluated in */
  season?: InputMaybe<SortEnumType>;
  /** Evaluation Summary */
  summary?: InputMaybe<SortEnumType>;
  /** Internal Marval ID of the user that created the evaluation */
  userId?: InputMaybe<SortEnumType>;
};

/** Encapsulates a player's grade (or score) for a given metric (or trait) as part of an evaluation instance. */
export type EvaluationTrait = {
  __typename?: "EvaluationTrait";
  /** Free-form comments about the grade */
  comments?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  evaluation: Evaluation;
  /** Foreign key to Evaluation */
  evaluationId: Scalars["UUID"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  scoutGrade?: Maybe<ScoutGrade>;
  /** The grade of the player being evaluated for the given metric */
  scoutGradeId?: Maybe<Scalars["UUID"]>;
  /** The metric that was evaluated */
  traitDefinition: TraitDefinition;
  /** Foreign key to TraitDefinition */
  traitDefinitionId: Scalars["UUID"];
  update: EvaluationTrait;
};

/** Encapsulates a player's grade (or score) for a given metric (or trait) as part of an evaluation instance. */
export type EvaluationTraitUpdateArgs = {
  details: EvaluationTraitDetailsInput;
};

export type EvaluationTraitDetailsInput = {
  comment: Scalars["String"];
  evaluationId: Scalars["UUID"];
  scoutGradeId?: InputMaybe<Scalars["UUID"]>;
  traitDefinitionId: Scalars["UUID"];
};

/** Encapsulates a player's grade (or score) for a given metric (or trait) as part of an evaluation instance. */
export type EvaluationTraitFilterInput = {
  and?: InputMaybe<Array<EvaluationTraitFilterInput>>;
  /** Free-form comments about the grade */
  comments?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  evaluation?: InputMaybe<EvaluationFilterInput>;
  /** Foreign key to Evaluation */
  evaluationId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationTraitFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  scoutGrade?: InputMaybe<ScoutGradeFilterInput>;
  /** The grade of the player being evaluated for the given metric */
  scoutGradeId?: InputMaybe<UuidOperationFilterInput>;
  /** The metric that was evaluated */
  traitDefinition?: InputMaybe<TraitDefinitionFilterInput>;
  /** Foreign key to TraitDefinition */
  traitDefinitionId?: InputMaybe<UuidOperationFilterInput>;
};

export type EvaluationTraitGroup = {
  __typename?: "EvaluationTraitGroup";
  /** Free-form comments about the grade */
  comments?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  evaluation: Evaluation;
  /** Foreign key to Evaluation */
  evaluationId: Scalars["UUID"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  scoutGrade?: Maybe<ScoutGrade>;
  /** The grade of the player being evaluated for the given metric */
  scoutGradeId?: Maybe<Scalars["UUID"]>;
  traitGroup: TraitGroup;
  traitGroupId: Scalars["UUID"];
  update: EvaluationTraitGroup;
};

export type EvaluationTraitGroupUpdateArgs = {
  request: SaveEvaluationTraitGroupRequest;
};

export type EvaluationTraitGroupFilterInput = {
  and?: InputMaybe<Array<EvaluationTraitGroupFilterInput>>;
  /** Free-form comments about the grade */
  comments?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  evaluation?: InputMaybe<EvaluationFilterInput>;
  /** Foreign key to Evaluation */
  evaluationId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationTraitGroupFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  scoutGrade?: InputMaybe<ScoutGradeFilterInput>;
  /** The grade of the player being evaluated for the given metric */
  scoutGradeId?: InputMaybe<UuidOperationFilterInput>;
  traitGroup?: InputMaybe<TraitGroupFilterInput>;
  traitGroupId?: InputMaybe<UuidOperationFilterInput>;
};

/** Trades a set of assets within a draft scenario where some of the assets are draft picks. */
export type ExecuteTradeRequest = {
  /** The ID of the draft scenario where we have picks being traded */
  draftId: Scalars["UUID"];
  /** The ID of the trade from the league if in a live draft */
  leagueTradeId?: InputMaybe<Scalars["Int"]>;
  /** The ID of the pinned trade to execute.  If supplied, the TradePartners is ignored. */
  pinnedTradeId?: InputMaybe<Scalars["Int"]>;
  /** The assets within the grade */
  tradePartners?: InputMaybe<Array<TradePartnerInput>>;
};

/** The draft to export */
export type ExportDraftScenarioRequest = {
  draftId: Scalars["UUID"];
};

/** Collection of picks made */
export type ExportDraftScenarioResult = {
  __typename?: "ExportDraftScenarioResult";
  picks: Array<DraftScenarioExportedPlayer>;
};

export type Fit = {
  __typename?: "Fit";
  abbreviation: Scalars["String"];
  created: Scalars["DateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  name: Scalars["String"];
  position: Scalars["String"];
  rowVersion: Array<Scalars["Byte"]>;
  sort?: Maybe<Scalars["Int"]>;
  update: Fit;
};

export type FitUpdateArgs = {
  details: FitDetailsInput;
};

export type FitDetailsInput = {
  abbreviation: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["UUID"];
  isDeleted: Scalars["Boolean"];
  position: Scalars["String"];
  sort?: InputMaybe<Scalars["Int"]>;
};

export type FitFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<FitFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FitFilterInput>>;
  position?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
};

export type FitSortInput = {
  abbreviation?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  position?: InputMaybe<SortEnumType>;
  sort?: InputMaybe<SortEnumType>;
};

export type FlagEvaluationForDeleteRequest = {
  /** The ID of the evaluation to flag for delete. */
  evaluationId: Scalars["UUID"];
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  neq?: InputMaybe<Scalars["Float"]>;
  ngt?: InputMaybe<Scalars["Float"]>;
  ngte?: InputMaybe<Scalars["Float"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  nlt?: InputMaybe<Scalars["Float"]>;
  nlte?: InputMaybe<Scalars["Float"]>;
};

export enum FreeAgencyStatus {
  CAP_CASUALTY = "CAP_CASUALTY",
  ERFA = "ERFA",
  POTENTIAL_VOID = "POTENTIAL_VOID",
  RFA = "RFA",
  UFA = "UFA",
}

export type Game = {
  __typename?: "Game";
  awayTeam?: Maybe<Scalars["String"]>;
  awayTeamId?: Maybe<Scalars["UUID"]>;
  awayTeamScore?: Maybe<Scalars["Int"]>;
  gameStats: Array<GameStat>;
  gsisGameId?: Maybe<Scalars["Int"]>;
  homeTeam?: Maybe<Scalars["String"]>;
  homeTeamId?: Maybe<Scalars["UUID"]>;
  homeTeamScore?: Maybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  kickoffTime?: Maybe<Scalars["DateTime"]>;
  lastUpdated: Scalars["DateTime"];
  leagueType: LeagueType;
  leagueTypeId: Scalars["Int"];
  playerPlays: Array<PlayerPlay>;
  plays: Array<Play>;
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  seasonType: SeasonType;
  seasonTypeId: Scalars["Int"];
  venue?: Maybe<Scalars["String"]>;
  week?: Maybe<Scalars["Int"]>;
  weekType: WeekType;
  weekTypeId: Scalars["Int"];
};

export type GameFilterInput = {
  and?: InputMaybe<Array<GameFilterInput>>;
  awayTeam?: InputMaybe<StringOperationFilterInput>;
  awayTeamId?: InputMaybe<UuidOperationFilterInput>;
  awayTeamScore?: InputMaybe<IntOperationFilterInput>;
  gameStats?: InputMaybe<ListFilterInputTypeOfGameStatFilterInput>;
  gsisGameId?: InputMaybe<IntOperationFilterInput>;
  homeTeam?: InputMaybe<StringOperationFilterInput>;
  homeTeamId?: InputMaybe<UuidOperationFilterInput>;
  homeTeamScore?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  kickoffTime?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueType?: InputMaybe<LeagueTypeFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<GameFilterInput>>;
  playerPlays?: InputMaybe<ListFilterInputTypeOfPlayerPlayFilterInput>;
  plays?: InputMaybe<ListFilterInputTypeOfPlayFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  seasonType?: InputMaybe<SeasonTypeFilterInput>;
  seasonTypeId?: InputMaybe<IntOperationFilterInput>;
  venue?: InputMaybe<StringOperationFilterInput>;
  week?: InputMaybe<IntOperationFilterInput>;
  weekType?: InputMaybe<WeekTypeFilterInput>;
  weekTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type GameSortInput = {
  awayTeam?: InputMaybe<SortEnumType>;
  awayTeamId?: InputMaybe<SortEnumType>;
  awayTeamScore?: InputMaybe<SortEnumType>;
  gsisGameId?: InputMaybe<SortEnumType>;
  homeTeam?: InputMaybe<SortEnumType>;
  homeTeamId?: InputMaybe<SortEnumType>;
  homeTeamScore?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  kickoffTime?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueType?: InputMaybe<LeagueTypeSortInput>;
  leagueTypeId?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  seasonType?: InputMaybe<SeasonTypeSortInput>;
  seasonTypeId?: InputMaybe<SortEnumType>;
  venue?: InputMaybe<SortEnumType>;
  week?: InputMaybe<SortEnumType>;
  weekType?: InputMaybe<WeekTypeSortInput>;
  weekTypeId?: InputMaybe<SortEnumType>;
};

export type GameStat = {
  __typename?: "GameStat";
  game: Game;
  gameId: Scalars["UUID"];
  lastUpdated: Scalars["DateTime"];
  leagueType: LeagueType;
  leagueTypeId: Scalars["Int"];
  playerId: Scalars["UUID"];
  playerStat: PlayerStat;
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  seasonType: SeasonType;
  seasonTypeId: Scalars["Int"];
  statType: StatType;
  statTypeId: Scalars["Int"];
  teamId?: Maybe<Scalars["UUID"]>;
  weekType: WeekType;
  weekTypeId: Scalars["Int"];
};

export type GameStatFilterInput = {
  and?: InputMaybe<Array<GameStatFilterInput>>;
  game?: InputMaybe<GameFilterInput>;
  gameId?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueType?: InputMaybe<LeagueTypeFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<GameStatFilterInput>>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  playerStat?: InputMaybe<PlayerStatFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  seasonType?: InputMaybe<SeasonTypeFilterInput>;
  seasonTypeId?: InputMaybe<IntOperationFilterInput>;
  statType?: InputMaybe<StatTypeFilterInput>;
  statTypeId?: InputMaybe<IntOperationFilterInput>;
  teamId?: InputMaybe<UuidOperationFilterInput>;
  weekType?: InputMaybe<WeekTypeFilterInput>;
  weekTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type GameStatSortInput = {
  game?: InputMaybe<GameSortInput>;
  gameId?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueType?: InputMaybe<LeagueTypeSortInput>;
  leagueTypeId?: InputMaybe<SortEnumType>;
  playerId?: InputMaybe<SortEnumType>;
  playerStat?: InputMaybe<PlayerStatSortInput>;
  season?: InputMaybe<SortEnumType>;
  seasonType?: InputMaybe<SeasonTypeSortInput>;
  seasonTypeId?: InputMaybe<SortEnumType>;
  statType?: InputMaybe<StatTypeSortInput>;
  statTypeId?: InputMaybe<SortEnumType>;
  teamId?: InputMaybe<SortEnumType>;
  weekType?: InputMaybe<WeekTypeSortInput>;
  weekTypeId?: InputMaybe<SortEnumType>;
};

/** List of players in the roster to be evaluated by the model */
export type GetCurrentRosterValueRequest = {
  /** ID of the relevant club */
  clubId: Scalars["UUID"];
  /** Current season to evaluate against. Used to get the current season salary cap */
  currentSeason: Scalars["Int"];
  /**
   * All draftable players to be considered by the RV model.
   * If none are specified, will use the draft player pool for the passed in CurrentSeason
   */
  draftablePlayerIds?: InputMaybe<Array<Scalars["UUID"]>>;
  /** Season data so RV can use PGM and WPG over past seasons */
  seasons: Array<Scalars["Int"]>;
};

/** Get a draftable player and all their availabilities at all picks. */
export type GetDraftablePlayerRequest = {
  /** The ID of the draft scenario */
  draftScenarioId: Scalars["UUID"];
  /** The ID of the player to retrieve */
  playerId: Scalars["UUID"];
};

/** List of players whose WPG needs to be calculated and the grade weights needed for the calculation */
export type GetGradeWeightPreviewRequest = {
  /** Club grade weight used for calculating new weighted player grades */
  clubGradeWeight: Scalars["Int"];
  /** ID of the relevant club */
  clubId: Scalars["UUID"];
  /** Current season to evaluate against. Used to get the current season salary cap */
  currentSeason: Scalars["Int"];
  /**
   * All draftable players to be considered by the RV model.
   * If none are specified, will use the draft player pool for the passed in CurrentSeason
   */
  draftablePlayerIds?: InputMaybe<Array<Scalars["UUID"]>>;
  /** Sage grade weight used for calculating new weighted player grades */
  sageGradeWeight: Scalars["Int"];
  /** Season data so RV can use PGM and WPG over past seasons */
  seasons: Array<Scalars["Int"]>;
  /** Sumer Scout grade weight used for calculating new weighted player grades */
  sumerGradeWeight: Scalars["Int"];
};

/** Get Sumer's recommended team needs */
export type GetRecommendedTeamNeedsRequest = {
  /** The club ID of the recommended team needs to get.  If null, all clubs will be returned. */
  clubId?: InputMaybe<Scalars["UUID"]>;
  /** Draft season to pull needs for */
  draftSeason: Scalars["Int"];
};

/** Get compiled team needs with overrides that are available, else simply Sumer's latest recommendations. */
export type GetTeamNeedsRequest = {
  /** The club ID of the recommended team needs to get.  If null, all clubs will be returned. */
  clubId?: InputMaybe<Scalars["UUID"]>;
  /** Draft season team needs will be for */
  draftSeason: Scalars["Int"];
};

/** List of top college players by draft season */
export type GetTopCollegePlayersRequest = {
  count: Scalars["Int"];
  eligibilityYear: Scalars["Int"];
};

/** Request to calculate the trade chart values for a given set of trade partners and traded draft picks. */
export type GetTradeChartValuesRequest = {
  /** The season of the draft */
  draftSeason: Scalars["Int"];
  /** The club to calculate the overall value for */
  forClubId: Scalars["UUID"];
  /** All assets within the trade */
  tradePartners: Array<TradePartnerInput>;
};

/** Evaluate the specified trade without persisting it to the draft scenario.  Specifically, get the Sumer trade evaluation results. */
export type GetTradeEvaluationRequest = {
  /** ID of the draft scenario */
  draftId: Scalars["UUID"];
  /** The club to evaluate the trade for */
  forClubId: Scalars["UUID"];
  /** The assets within the trade */
  tradePartners: Array<TradePartnerInput>;
};

/** List of UFA players */
export type GetUfaPlayersRequest = {
  count: Scalars["Int"];
};

export type Grade = {
  __typename?: "Grade";
  created: Scalars["DateTime"];
  /** Salary cap dollars per point above replacement */
  dollars?: Maybe<Scalars["Decimal"]>;
  /** Grade type */
  gradeType: GradeTypeEnum;
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  player: Player;
  /** Marval Player Id */
  playerId: Scalars["UUID"];
  /** The position the player was evaluated */
  position?: Maybe<Scalars["String"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** Season the grade belongs. College club grade this is draft season. */
  season: Scalars["Int"];
  update: Grade;
  /** Grade value (or points) */
  value: Scalars["Decimal"];
};

export type GradeUpdateArgs = {
  dollars?: InputMaybe<Scalars["Decimal"]>;
  gradeType: GradeTypeEnum;
  playerId: Scalars["UUID"];
  points: Scalars["Decimal"];
  position?: InputMaybe<Scalars["String"]>;
  season: Scalars["Int"];
};

export type GradeFilterInput = {
  and?: InputMaybe<Array<GradeFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Salary cap dollars per point above replacement */
  dollars?: InputMaybe<DecimalOperationFilterInput>;
  /** Grade type */
  gradeType?: InputMaybe<GradeTypeEnumOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<GradeFilterInput>>;
  player?: InputMaybe<PlayerFilterInput>;
  /** Marval Player Id */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** The position the player was evaluated */
  position?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Season the grade belongs. College club grade this is draft season. */
  season?: InputMaybe<IntOperationFilterInput>;
  /** Grade value (or points) */
  value?: InputMaybe<DecimalOperationFilterInput>;
};

export enum GradeTypeEnum {
  COLLEGE_CLUB_GRADE = "COLLEGE_CLUB_GRADE",
  COLLEGE_TO_PRO_PROJECTION = "COLLEGE_TO_PRO_PROJECTION",
  ENSEMBLE_GRADE = "ENSEMBLE_GRADE",
  FOOTBALL_VALUE = "FOOTBALL_VALUE",
  PRO_CLUB_GRADE = "PRO_CLUB_GRADE",
}

export type GradeTypeEnumOperationFilterInput = {
  eq?: InputMaybe<GradeTypeEnum>;
  in?: InputMaybe<Array<GradeTypeEnum>>;
  neq?: InputMaybe<GradeTypeEnum>;
  nin?: InputMaybe<Array<GradeTypeEnum>>;
};

export type GradeWeight = {
  __typename?: "GradeWeight";
  created: Scalars["DateTime"];
  /** Type of grade to be weighted */
  gradeWeightType: GradeWeightEnum;
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  update: GradeWeight;
  /** Value of weighting for grade. Must be from 0 to 10000 and all 3 must total to 10000 */
  value: Scalars["Int"];
};

export type GradeWeightUpdateArgs = {
  details: GradeWeightDetailsInput;
};

export type GradeWeightDetailsInput = {
  gradeWeightType: GradeWeightEnum;
  id: Scalars["UUID"];
  value: Scalars["Int"];
};

export enum GradeWeightEnum {
  CLUB = "CLUB",
  SUMER_ANALYTICS_GRADE_ENSEMBLE = "SUMER_ANALYTICS_GRADE_ENSEMBLE",
  SUMER_SCOUT = "SUMER_SCOUT",
  WEIGHTED = "WEIGHTED",
}

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  neq?: InputMaybe<Scalars["Int"]>;
  ngt?: InputMaybe<Scalars["Int"]>;
  ngte?: InputMaybe<Scalars["Int"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  nlt?: InputMaybe<Scalars["Int"]>;
  nlte?: InputMaybe<Scalars["Int"]>;
};

/** The trade value according to the Jimmy Johnson chart */
export type JimmyJohnsonTradeValue = {
  __typename?: "JimmyJohnsonTradeValue";
  netPoints: Scalars["Float"];
  /** Points being offered by the club doing the analysis */
  offeringPoints: Scalars["Float"];
  /** Points being received by the club doing the analysis */
  receivingPoints: Scalars["Float"];
};

/** The trade value according to the Jimmy Johnson chart */
export type JimmyJohnsonTradeValueFilterInput = {
  and?: InputMaybe<Array<JimmyJohnsonTradeValueFilterInput>>;
  netPoints?: InputMaybe<FloatOperationFilterInput>;
  /** Points being offered by the club doing the analysis */
  offeringPoints?: InputMaybe<FloatOperationFilterInput>;
  or?: InputMaybe<Array<JimmyJohnsonTradeValueFilterInput>>;
  /** Points being received by the club doing the analysis */
  receivingPoints?: InputMaybe<FloatOperationFilterInput>;
};

export enum KeyPlay {
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
  POSITIVE = "POSITIVE",
}

export type KeyValuePairOfStringAndPositionAtlasNode = {
  __typename?: "KeyValuePairOfStringAndPositionAtlasNode";
  key: Scalars["String"];
  value: PositionAtlasNode;
};

export type LeagueType = {
  __typename?: "LeagueType";
  careerStats: Array<CareerStat>;
  description?: Maybe<Scalars["String"]>;
  gameStats: Array<GameStat>;
  games: Array<Game>;
  id: Scalars["Int"];
  playerPlays: Array<PlayerPlay>;
  plays: Array<Play>;
  seasonStats: Array<SeasonStat>;
};

export type LeagueTypeFilterInput = {
  and?: InputMaybe<Array<LeagueTypeFilterInput>>;
  careerStats?: InputMaybe<ListFilterInputTypeOfCareerStatFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  gameStats?: InputMaybe<ListFilterInputTypeOfGameStatFilterInput>;
  games?: InputMaybe<ListFilterInputTypeOfGameFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LeagueTypeFilterInput>>;
  playerPlays?: InputMaybe<ListFilterInputTypeOfPlayerPlayFilterInput>;
  plays?: InputMaybe<ListFilterInputTypeOfPlayFilterInput>;
  seasonStats?: InputMaybe<ListFilterInputTypeOfSeasonStatFilterInput>;
};

export type LeagueTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

export type ListByteOperationFilterInput = {
  all?: InputMaybe<ByteOperationFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<ByteOperationFilterInput>;
  some?: InputMaybe<ByteOperationFilterInput>;
};

export type ListFilterInputTypeOfAppRoleFilterInput = {
  all?: InputMaybe<AppRoleFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<AppRoleFilterInput>;
  some?: InputMaybe<AppRoleFilterInput>;
};

export type ListFilterInputTypeOfAssumptionFilterInput = {
  all?: InputMaybe<AssumptionFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<AssumptionFilterInput>;
  some?: InputMaybe<AssumptionFilterInput>;
};

export type ListFilterInputTypeOfCareerStatFilterInput = {
  all?: InputMaybe<CareerStatFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<CareerStatFilterInput>;
  some?: InputMaybe<CareerStatFilterInput>;
};

export type ListFilterInputTypeOfClubDraftablePlayerAtPickFilterInput = {
  all?: InputMaybe<ClubDraftablePlayerAtPickFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<ClubDraftablePlayerAtPickFilterInput>;
  some?: InputMaybe<ClubDraftablePlayerAtPickFilterInput>;
};

export type ListFilterInputTypeOfComboDraftablePlayerFilterInput = {
  all?: InputMaybe<ComboDraftablePlayerFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<ComboDraftablePlayerFilterInput>;
  some?: InputMaybe<ComboDraftablePlayerFilterInput>;
};

export type ListFilterInputTypeOfCurrentSeasonDraftAssetFilterInput = {
  all?: InputMaybe<CurrentSeasonDraftAssetFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<CurrentSeasonDraftAssetFilterInput>;
  some?: InputMaybe<CurrentSeasonDraftAssetFilterInput>;
};

export type ListFilterInputTypeOfCurrentWorkspacePlayerFilterInput = {
  all?: InputMaybe<CurrentWorkspacePlayerFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<CurrentWorkspacePlayerFilterInput>;
  some?: InputMaybe<CurrentWorkspacePlayerFilterInput>;
};

export type ListFilterInputTypeOfDraftAssetFilterInput = {
  all?: InputMaybe<DraftAssetFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<DraftAssetFilterInput>;
  some?: InputMaybe<DraftAssetFilterInput>;
};

export type ListFilterInputTypeOfDraftPickFilterInput = {
  all?: InputMaybe<DraftPickFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<DraftPickFilterInput>;
  some?: InputMaybe<DraftPickFilterInput>;
};

export type ListFilterInputTypeOfDraftPickTransactionFilterInput = {
  all?: InputMaybe<DraftPickTransactionFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<DraftPickTransactionFilterInput>;
  some?: InputMaybe<DraftPickTransactionFilterInput>;
};

export type ListFilterInputTypeOfDraftScenarioPositionalNeedFilterInput = {
  all?: InputMaybe<DraftScenarioPositionalNeedFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<DraftScenarioPositionalNeedFilterInput>;
  some?: InputMaybe<DraftScenarioPositionalNeedFilterInput>;
};

export type ListFilterInputTypeOfDraftScenarioTeamNeedsFilterInput = {
  all?: InputMaybe<DraftScenarioTeamNeedsFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<DraftScenarioTeamNeedsFilterInput>;
  some?: InputMaybe<DraftScenarioTeamNeedsFilterInput>;
};

export type ListFilterInputTypeOfDraftablePlayerAtPickFilterInput = {
  all?: InputMaybe<DraftablePlayerAtPickFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<DraftablePlayerAtPickFilterInput>;
  some?: InputMaybe<DraftablePlayerAtPickFilterInput>;
};

export type ListFilterInputTypeOfDraftedPlayerFilterInput = {
  all?: InputMaybe<DraftedPlayerFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<DraftedPlayerFilterInput>;
  some?: InputMaybe<DraftedPlayerFilterInput>;
};

export type ListFilterInputTypeOfEvaluatedDraftAssetFilterInput = {
  all?: InputMaybe<EvaluatedDraftAssetFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluatedDraftAssetFilterInput>;
  some?: InputMaybe<EvaluatedDraftAssetFilterInput>;
};

export type ListFilterInputTypeOfEvaluatedDraftPickFilterInput = {
  all?: InputMaybe<EvaluatedDraftPickFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluatedDraftPickFilterInput>;
  some?: InputMaybe<EvaluatedDraftPickFilterInput>;
};

export type ListFilterInputTypeOfEvaluationAlertFilterInput = {
  all?: InputMaybe<EvaluationAlertFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationAlertFilterInput>;
  some?: InputMaybe<EvaluationAlertFilterInput>;
};

export type ListFilterInputTypeOfEvaluationClubFitFilterInput = {
  all?: InputMaybe<EvaluationClubFitFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationClubFitFilterInput>;
  some?: InputMaybe<EvaluationClubFitFilterInput>;
};

export type ListFilterInputTypeOfEvaluationFilterInput = {
  all?: InputMaybe<EvaluationFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationFilterInput>;
  some?: InputMaybe<EvaluationFilterInput>;
};

export type ListFilterInputTypeOfEvaluationFitFilterInput = {
  all?: InputMaybe<EvaluationFitFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationFitFilterInput>;
  some?: InputMaybe<EvaluationFitFilterInput>;
};

export type ListFilterInputTypeOfEvaluationGameFilterInput = {
  all?: InputMaybe<EvaluationGameFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationGameFilterInput>;
  some?: InputMaybe<EvaluationGameFilterInput>;
};

export type ListFilterInputTypeOfEvaluationTraitFilterInput = {
  all?: InputMaybe<EvaluationTraitFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationTraitFilterInput>;
  some?: InputMaybe<EvaluationTraitFilterInput>;
};

export type ListFilterInputTypeOfEvaluationTraitGroupFilterInput = {
  all?: InputMaybe<EvaluationTraitGroupFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationTraitGroupFilterInput>;
  some?: InputMaybe<EvaluationTraitGroupFilterInput>;
};

export type ListFilterInputTypeOfGameFilterInput = {
  all?: InputMaybe<GameFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<GameFilterInput>;
  some?: InputMaybe<GameFilterInput>;
};

export type ListFilterInputTypeOfGameStatFilterInput = {
  all?: InputMaybe<GameStatFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<GameStatFilterInput>;
  some?: InputMaybe<GameStatFilterInput>;
};

export type ListFilterInputTypeOfGradeFilterInput = {
  all?: InputMaybe<GradeFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<GradeFilterInput>;
  some?: InputMaybe<GradeFilterInput>;
};

export type ListFilterInputTypeOfNymFilterInput = {
  all?: InputMaybe<NymFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<NymFilterInput>;
  some?: InputMaybe<NymFilterInput>;
};

export type ListFilterInputTypeOfPerformanceGapFilterInput = {
  all?: InputMaybe<PerformanceGapFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PerformanceGapFilterInput>;
  some?: InputMaybe<PerformanceGapFilterInput>;
};

export type ListFilterInputTypeOfPermissionFilterInput = {
  all?: InputMaybe<PermissionFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PermissionFilterInput>;
  some?: InputMaybe<PermissionFilterInput>;
};

export type ListFilterInputTypeOfPickTradeOpportunityFilterInput = {
  all?: InputMaybe<PickTradeOpportunityFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PickTradeOpportunityFilterInput>;
  some?: InputMaybe<PickTradeOpportunityFilterInput>;
};

export type ListFilterInputTypeOfPlayFilterInput = {
  all?: InputMaybe<PlayFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayFilterInput>;
  some?: InputMaybe<PlayFilterInput>;
};

export type ListFilterInputTypeOfPlayerGradeSeasonFilterInput = {
  all?: InputMaybe<PlayerGradeSeasonFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayerGradeSeasonFilterInput>;
  some?: InputMaybe<PlayerGradeSeasonFilterInput>;
};

export type ListFilterInputTypeOfPlayerOverrideFilterInput = {
  all?: InputMaybe<PlayerOverrideFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayerOverrideFilterInput>;
  some?: InputMaybe<PlayerOverrideFilterInput>;
};

export type ListFilterInputTypeOfPlayerPackagePositionFilterInput = {
  all?: InputMaybe<PlayerPackagePositionFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayerPackagePositionFilterInput>;
  some?: InputMaybe<PlayerPackagePositionFilterInput>;
};

export type ListFilterInputTypeOfPlayerPlayFilterInput = {
  all?: InputMaybe<PlayerPlayFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayerPlayFilterInput>;
  some?: InputMaybe<PlayerPlayFilterInput>;
};

export type ListFilterInputTypeOfPlayerProjectedGamesMissedFilterInput = {
  all?: InputMaybe<PlayerProjectedGamesMissedFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayerProjectedGamesMissedFilterInput>;
  some?: InputMaybe<PlayerProjectedGamesMissedFilterInput>;
};

export type ListFilterInputTypeOfPositionalNeedFilterInput = {
  all?: InputMaybe<PositionalNeedFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PositionalNeedFilterInput>;
  some?: InputMaybe<PositionalNeedFilterInput>;
};

export type ListFilterInputTypeOfRecommendedWorkspacePlayerFilterInput = {
  all?: InputMaybe<RecommendedWorkspacePlayerFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<RecommendedWorkspacePlayerFilterInput>;
  some?: InputMaybe<RecommendedWorkspacePlayerFilterInput>;
};

export type ListFilterInputTypeOfSearchResultOfClubSearchDocumentFilterInput = {
  all?: InputMaybe<SearchResultOfClubSearchDocumentFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<SearchResultOfClubSearchDocumentFilterInput>;
  some?: InputMaybe<SearchResultOfClubSearchDocumentFilterInput>;
};

export type ListFilterInputTypeOfSearchResultOfPlayerSearchDocumentFilterInput =
  {
    all?: InputMaybe<SearchResultOfPlayerSearchDocumentFilterInput>;
    any?: InputMaybe<Scalars["Boolean"]>;
    none?: InputMaybe<SearchResultOfPlayerSearchDocumentFilterInput>;
    some?: InputMaybe<SearchResultOfPlayerSearchDocumentFilterInput>;
  };

export type ListFilterInputTypeOfSearchResultOfSchoolSearchDocumentFilterInput =
  {
    all?: InputMaybe<SearchResultOfSchoolSearchDocumentFilterInput>;
    any?: InputMaybe<Scalars["Boolean"]>;
    none?: InputMaybe<SearchResultOfSchoolSearchDocumentFilterInput>;
    some?: InputMaybe<SearchResultOfSchoolSearchDocumentFilterInput>;
  };

export type ListFilterInputTypeOfSeasonStatFilterInput = {
  all?: InputMaybe<SeasonStatFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<SeasonStatFilterInput>;
  some?: InputMaybe<SeasonStatFilterInput>;
};

export type ListFilterInputTypeOfSeasonalSalaryCapFilterInput = {
  all?: InputMaybe<SeasonalSalaryCapFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<SeasonalSalaryCapFilterInput>;
  some?: InputMaybe<SeasonalSalaryCapFilterInput>;
};

export type ListFilterInputTypeOfSumerEvaluatedDraftAssetFilterInput = {
  all?: InputMaybe<SumerEvaluatedDraftAssetFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<SumerEvaluatedDraftAssetFilterInput>;
  some?: InputMaybe<SumerEvaluatedDraftAssetFilterInput>;
};

export type ListFilterInputTypeOfTradeAssetFilterInput = {
  all?: InputMaybe<TradeAssetFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<TradeAssetFilterInput>;
  some?: InputMaybe<TradeAssetFilterInput>;
};

export type ListFilterInputTypeOfTradeFilterInput = {
  all?: InputMaybe<TradeFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<TradeFilterInput>;
  some?: InputMaybe<TradeFilterInput>;
};

export type ListFilterInputTypeOfTradePartnerFilterInput = {
  all?: InputMaybe<TradePartnerFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<TradePartnerFilterInput>;
  some?: InputMaybe<TradePartnerFilterInput>;
};

export type ListFilterInputTypeOfTradedPlayerFilterInput = {
  all?: InputMaybe<TradedPlayerFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<TradedPlayerFilterInput>;
  some?: InputMaybe<TradedPlayerFilterInput>;
};

export type ListFilterInputTypeOfTraitGroupPositionFilterInput = {
  all?: InputMaybe<TraitGroupPositionFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<TraitGroupPositionFilterInput>;
  some?: InputMaybe<TraitGroupPositionFilterInput>;
};

export type ListFilterInputTypeOfWorkoutPlayerMeasureFilterInput = {
  all?: InputMaybe<WorkoutPlayerMeasureFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<WorkoutPlayerMeasureFilterInput>;
  some?: InputMaybe<WorkoutPlayerMeasureFilterInput>;
};

export type ListIntOperationFilterInput = {
  all?: InputMaybe<IntOperationFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<IntOperationFilterInput>;
  some?: InputMaybe<IntOperationFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type ListUuidOperationFilterInput = {
  all?: InputMaybe<UuidOperationFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<UuidOperationFilterInput>;
  some?: InputMaybe<UuidOperationFilterInput>;
};

/**
 * Request to get a draftable player pool at a given pick, with all player values contextualized to that pick.
 *
 * If overall is null, just gets the undrafted player pool without availabilities.
 */
export type LoadDraftablePlayerPoolRequest = {
  /** The ID of the draft scenario to get the pool for */
  draftScenarioId: Scalars["UUID"];
  /** The pick to get players and their values for */
  overall?: InputMaybe<Scalars["Int"]>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars["Long"]>;
  gt?: InputMaybe<Scalars["Long"]>;
  gte?: InputMaybe<Scalars["Long"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Long"]>>>;
  lt?: InputMaybe<Scalars["Long"]>;
  lte?: InputMaybe<Scalars["Long"]>;
  neq?: InputMaybe<Scalars["Long"]>;
  ngt?: InputMaybe<Scalars["Long"]>;
  ngte?: InputMaybe<Scalars["Long"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Long"]>>>;
  nlt?: InputMaybe<Scalars["Long"]>;
  nlte?: InputMaybe<Scalars["Long"]>;
};

export type MarkEvaluationAsPrimaryRequest = {
  /** The ID of the evaluation to mark as primary. */
  evaluationId: Scalars["UUID"];
};

export type MeasureType = {
  __typename?: "MeasureType";
  created: Scalars["DateTime"];
  /** Brief description of measure */
  description: Scalars["String"];
  id: Scalars["ID"];
  /** Whether the display should be a fraction */
  isFraction: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** Maximum value for validation */
  maxValue: Scalars["Decimal"];
  /** Minimum value for validation */
  minValue: Scalars["Decimal"];
  /** Name of measure */
  name: Scalars["String"];
  /** Decimal precision */
  precision: Scalars["Int"];
  /** Order of display/sort */
  priority: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Short name for display */
  shortName: Scalars["String"];
  updateMeasureType: MeasureType;
};

export type MeasureTypeUpdateMeasureTypeArgs = {
  request: UpdateMeasureTypeRequest;
};

export type MeasureTypeFilterInput = {
  and?: InputMaybe<Array<MeasureTypeFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Brief description of measure */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Whether the display should be a fraction */
  isFraction?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** Maximum value for validation */
  maxValue?: InputMaybe<DecimalOperationFilterInput>;
  /** Minimum value for validation */
  minValue?: InputMaybe<DecimalOperationFilterInput>;
  /** Name of measure */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MeasureTypeFilterInput>>;
  /** Decimal precision */
  precision?: InputMaybe<IntOperationFilterInput>;
  /** Order of display/sort */
  priority?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Short name for display */
  shortName?: InputMaybe<StringOperationFilterInput>;
};

export type MeasureTypeSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** Brief description of measure */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Whether the display should be a fraction */
  isFraction?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Maximum value for validation */
  maxValue?: InputMaybe<SortEnumType>;
  /** Minimum value for validation */
  minValue?: InputMaybe<SortEnumType>;
  /** Name of measure */
  name?: InputMaybe<SortEnumType>;
  /** Decimal precision */
  precision?: InputMaybe<SortEnumType>;
  /** Order of display/sort */
  priority?: InputMaybe<SortEnumType>;
  /** Short name for display */
  shortName?: InputMaybe<SortEnumType>;
};

/** Wrapping type of basic model metadata results */
export type ModelMetadata = {
  __typename?: "ModelMetadata";
  /** Path in blob storage to the most recent DAM request binary */
  latestDamRequestBlobLocation?: Maybe<Scalars["String"]>;
  /** Path in blob storage to the most recent DAM request binary for combo player derivation */
  latestDamRequestComboPlayerBlobLocation?: Maybe<Scalars["String"]>;
  /** Path in blob storage to the most recent DAM response binary */
  latestDamResponseBlobLocation?: Maybe<Scalars["String"]>;
  /** Path in blob storage to the most recent DAM response binary for combo player derivation */
  latestDamResponseComboPlayerBlobLocation?: Maybe<Scalars["String"]>;
  /** Path in blob storage to the most recent roster value request binary */
  latestRosterValueRequestBlobLocation?: Maybe<Scalars["String"]>;
  /** Path in blob storage to the most recent roster value response binary */
  latestRosterValueResponseBlobLocation?: Maybe<Scalars["String"]>;
  /** Path in blob storage to the most recent sample simulation request binary */
  latestSampleSimulationRequestBlobLocation?: Maybe<Scalars["String"]>;
  /** Path in blob storage to the most recent sample simulation response binary */
  latestSampleSimulationResponseBlobLocation?: Maybe<Scalars["String"]>;
  /** Path in blob storage to the most recent target value request binary */
  latestTargetValueRequestBlobLocation?: Maybe<Scalars["String"]>;
  /** Path in blob storage to the most recent target value response binary */
  latestTargetValueResponseBlobLocation?: Maybe<Scalars["String"]>;
};

/** Wrapping type of basic model metadata results */
export type ModelMetadataFilterInput = {
  and?: InputMaybe<Array<ModelMetadataFilterInput>>;
  /** Path in blob storage to the most recent DAM request binary */
  latestDamRequestBlobLocation?: InputMaybe<StringOperationFilterInput>;
  /** Path in blob storage to the most recent DAM request binary for combo player derivation */
  latestDamRequestComboPlayerBlobLocation?: InputMaybe<StringOperationFilterInput>;
  /** Path in blob storage to the most recent DAM response binary */
  latestDamResponseBlobLocation?: InputMaybe<StringOperationFilterInput>;
  /** Path in blob storage to the most recent DAM response binary for combo player derivation */
  latestDamResponseComboPlayerBlobLocation?: InputMaybe<StringOperationFilterInput>;
  /** Path in blob storage to the most recent roster value request binary */
  latestRosterValueRequestBlobLocation?: InputMaybe<StringOperationFilterInput>;
  /** Path in blob storage to the most recent roster value response binary */
  latestRosterValueResponseBlobLocation?: InputMaybe<StringOperationFilterInput>;
  /** Path in blob storage to the most recent sample simulation request binary */
  latestSampleSimulationRequestBlobLocation?: InputMaybe<StringOperationFilterInput>;
  /** Path in blob storage to the most recent sample simulation response binary */
  latestSampleSimulationResponseBlobLocation?: InputMaybe<StringOperationFilterInput>;
  /** Path in blob storage to the most recent target value request binary */
  latestTargetValueRequestBlobLocation?: InputMaybe<StringOperationFilterInput>;
  /** Path in blob storage to the most recent target value response binary */
  latestTargetValueResponseBlobLocation?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ModelMetadataFilterInput>>;
};

/** Wrapping type of basic model metadata results */
export type ModelMetadataSortInput = {
  /** Path in blob storage to the most recent DAM request binary */
  latestDamRequestBlobLocation?: InputMaybe<SortEnumType>;
  /** Path in blob storage to the most recent DAM request binary for combo player derivation */
  latestDamRequestComboPlayerBlobLocation?: InputMaybe<SortEnumType>;
  /** Path in blob storage to the most recent DAM response binary */
  latestDamResponseBlobLocation?: InputMaybe<SortEnumType>;
  /** Path in blob storage to the most recent DAM response binary for combo player derivation */
  latestDamResponseComboPlayerBlobLocation?: InputMaybe<SortEnumType>;
  /** Path in blob storage to the most recent roster value request binary */
  latestRosterValueRequestBlobLocation?: InputMaybe<SortEnumType>;
  /** Path in blob storage to the most recent roster value response binary */
  latestRosterValueResponseBlobLocation?: InputMaybe<SortEnumType>;
  /** Path in blob storage to the most recent sample simulation request binary */
  latestSampleSimulationRequestBlobLocation?: InputMaybe<SortEnumType>;
  /** Path in blob storage to the most recent sample simulation response binary */
  latestSampleSimulationResponseBlobLocation?: InputMaybe<SortEnumType>;
  /** Path in blob storage to the most recent target value request binary */
  latestTargetValueRequestBlobLocation?: InputMaybe<SortEnumType>;
  /** Path in blob storage to the most recent target value response binary */
  latestTargetValueResponseBlobLocation?: InputMaybe<SortEnumType>;
};

export type Mutation = {
  __typename?: "Mutation";
  activateUser: User;
  addGlobalCutAssumption: AssumptionSet;
  addGlobalDoNotDraftAssumption: AssumptionSet;
  addGlobalDraftWithPickAssumption: AssumptionSet;
  addGlobalKeepAssumption: AssumptionSet;
  addGlobalWaitToDraftAssumption: AssumptionSet;
  addLiveDraftCutAssumption: AssumptionSet;
  addLiveDraftDoNotDraftAssumption: AssumptionSet;
  addLiveDraftDraftWithPickAssumption: AssumptionSet;
  addLiveDraftKeepAssumption: AssumptionSet;
  addLiveDraftWaitToDraftAssumption: AssumptionSet;
  addLocalDraftScenarioAssumptionDoNotDraftPlayer: DraftScenario;
  addLocalDraftScenarioAssumptionPlayerDraftedAtPick: DraftScenario;
  addUserDraftScenarioCutAssumption: AssumptionSet;
  addUserDraftScenarioDoNotDraftAssumption: AssumptionSet;
  addUserDraftScenarioDraftWithPickAssumption: AssumptionSet;
  addUserDraftScenarioKeepAssumption: AssumptionSet;
  addUserDraftScenarioWaitToDraftAssumption: AssumptionSet;
  assignPermissionsToRole: AppRole;
  assignPickToClub: DraftScenario;
  /** Assign player field override */
  assignPlayerOverride?: Maybe<Player>;
  assumeDoNotDraft: Scenario;
  assumeDoNotDraftUntilRound: Scenario;
  assumeMustNotSignPlayer: Scenario;
  assumePlayerCut: Scenario;
  assumePlayerCutAfterDate: Scenario;
  assumePlayerDraftedAtPick: Scenario;
  assumePlayerKeptUntilDate: Scenario;
  assumePlayerKeptWithContract: Scenario;
  assumePlayerKeptWithContractUntilDate: Scenario;
  assumeSignedPlayer: Scenario;
  assumeSignedPlayerThatCantBeCut: Scenario;
  assumeSignedPlayerToAnyContract: Scenario;
  /** Create a new evaluation */
  createEvaluation?: Maybe<Evaluation>;
  createFavorite: PlayerFavorite;
  createGameEvaluation?: Maybe<Evaluation>;
  createGradeWeight?: Maybe<GradeWeight>;
  createInjuryCollege?: Maybe<PlayerInjuryCollege>;
  createInjuryPro?: Maybe<PlayerInjuryPro>;
  createPermission: Permission;
  /** Create a new player */
  createPlayer?: Maybe<Player>;
  createPositionAtlasNode?: Maybe<PositionAtlasNode>;
  createRole: AppRole;
  createScenario: Scenario;
  /** Create a new scout attribute */
  createScoutingAssignment?: Maybe<ScoutingAssignment>;
  createUser: User;
  deactivateUser: User;
  deleteAssumption: Scenario;
  deleteFavorite?: Maybe<PlayerFavorite>;
  /** Delete a game grade. */
  deleteGameEvaluation?: Maybe<Evaluation>;
  deleteGlobalAssumption: AssumptionSet;
  deleteLiveDraftAssumption: AssumptionSet;
  deletePermission: Permission;
  deleteRole: AppRole;
  /** Delete a scout attribute */
  deleteScoutingAssignment: ScoutingAssignment;
  deleteUserDraftScenarioAssumption: AssumptionSet;
  discardAllActiveNonSimDraftScenarios: Array<DraftScenario>;
  draftScenarioCreate: DraftScenario;
  draftScenarioDiscard: DraftScenario;
  draftScenarioDraftPlayers: DraftScenario;
  draftScenarioExecuteTrade: DraftScenario;
  draftScenarioPinTrade: DraftScenario;
  draftScenarioRebuildReadModel: Scalars["Boolean"];
  draftScenarioRename: DraftScenario;
  draftScenarioRequestEvaluationRefresh: DraftScenario;
  draftScenarioRewindToPick: DraftScenario;
  draftScenarioSetWorkspacePlayersCustom: DraftScenario;
  draftScenarioSetWorkspacePlayersRecommended: DraftScenario;
  draftScenarioSimulateToPick: DraftScenario;
  draftScenarioTriggerPerformanceGapRefresh: DraftScenario;
  draftScenarioUndoLastAction: DraftScenario;
  draftScenarioUnpinTrade: DraftScenario;
  draftScenarioUpdateConfiguration: DraftScenario;
  draftScenarioUpdateTeamNeeds: DraftScenario;
  /** Method to flag evaluation for delete */
  flagEvaluationForDelete?: Maybe<Evaluation>;
  hideScenario: Scenario;
  importGlobalAssumptions: Scenario;
  initAtlas?: Maybe<PositionAtlasNode>;
  /** Mark evaluation as primary, unmark existing primary evaluation */
  markEvaluationAsPrimary?: Maybe<Evaluation>;
  overridePlayerGrade?: Maybe<PlayerGrade>;
  overrideTeamNeeds: Array<TeamNeedsWrapper>;
  provisionUser: User;
  removeLocalDraftScenarioAssumption: DraftScenario;
  removePermissionsFromRole: AppRole;
  renameScenario: Scenario;
  /** Deletes, recreates and reloads all the data in the search indices for player-related data. */
  resetPlayerSearchIndices: Scalars["Boolean"];
  /** Reset position depth survey back to default settings. This will create an entirely new survey based on the club grading position set and will destroy any previous data. */
  resetPositionDepthSurvey: PositionDepthLimitSurvey;
  resetPositionalImportanceSurvey: PositionalImportanceSurvey;
  runOptimizer: Scenario;
  /** Create/update a evaluation alerts */
  saveEvaluationAlerts?: Maybe<Evaluation>;
  /** Create/update a evaluation team/club fit */
  saveEvaluationClubFits?: Maybe<Evaluation>;
  /** Create/update a evaluation fits */
  saveEvaluationFits?: Maybe<Evaluation>;
  /** Create/update a evaluation trait */
  saveEvaluationTrait?: Maybe<Evaluation>;
  /** Create/update a evaluation trait group grade */
  saveEvaluationTraitGroup?: Maybe<Evaluation>;
  /** Method to save a player's projected games missed */
  saveProjectedGamesMissed?: Maybe<PlayerProjectedGamesMissed>;
  setOptimizationSettings: Scenario;
  /** Initial sync for alerts table */
  syncAlerts: Scalars["Boolean"];
  /** Initial sync for eval tables */
  syncEvals: Scalars["Boolean"];
  /** Initial sync of fits table */
  syncFits: Scalars["Boolean"];
  /** Initial sync for positions table */
  syncPositions: Scalars["Boolean"];
  unHideScenario: Scenario;
  /** Sets the IsFinal flag to false, evaluation will be able to be edited again */
  unMarkEvaluationAsFinal?: Maybe<Evaluation>;
  /** Update an basic details of existing player */
  updateBasicPlayerDetails?: Maybe<Player>;
  /** Update an existing evaluation */
  updateEvaluation?: Maybe<Evaluation>;
  updateGameEvaluation?: Maybe<Evaluation>;
  updateGradeWeight: Array<GradeWeight>;
  updateInjuryCollege?: Maybe<PlayerInjuryCollege>;
  updateInjuryPro?: Maybe<PlayerInjuryPro>;
  updatePermission: Permission;
  /** Update an existing player */
  updatePlayer?: Maybe<Player>;
  /** Updates positional depth survey with new data. Array of limits is merged in using PositionNodeId as a matching key. Removing nodes using this function is not possible. */
  updatePositionDepthSurvey: PositionDepthLimitSurvey;
  /** Updates positional importance survey page with new data. The page is matched to an existing page by title. Metrics are matched by their ClubNodeId. It is not possible to add or remove metrics from a page using this. It is not required to submit all metrics on a page, only those submitted will be modified. */
  updatePositionalImportanceSurvey: PositionalImportanceSurvey;
  updateRole: AppRole;
  /** Update a scout attribute */
  updateScoutingAssignment?: Maybe<ScoutingAssignment>;
  updateUser: User;
};

export type MutationActivateUserArgs = {
  request: ActivateUserRequest;
};

export type MutationAddGlobalCutAssumptionArgs = {
  request: AddGlobalCutAssumptionRequest;
};

export type MutationAddGlobalDoNotDraftAssumptionArgs = {
  request: AddGlobalDoNotDraftAssumptionRequest;
};

export type MutationAddGlobalDraftWithPickAssumptionArgs = {
  request: AddGlobalDraftWithPickAssumptionRequest;
};

export type MutationAddGlobalKeepAssumptionArgs = {
  request: AddGlobalKeepAssumptionRequest;
};

export type MutationAddGlobalWaitToDraftAssumptionArgs = {
  request: AddGlobalWaitToDraftAssumptionRequest;
};

export type MutationAddLiveDraftCutAssumptionArgs = {
  request: AddLiveDraftCutAssumptionRequest;
};

export type MutationAddLiveDraftDoNotDraftAssumptionArgs = {
  request: AddLiveDraftDoNotDraftAssumptionRequest;
};

export type MutationAddLiveDraftDraftWithPickAssumptionArgs = {
  request: AddLiveDraftDraftWithPickAssumptionRequest;
};

export type MutationAddLiveDraftKeepAssumptionArgs = {
  request: AddLiveDraftKeepAssumptionRequest;
};

export type MutationAddLiveDraftWaitToDraftAssumptionArgs = {
  request: AddLiveDraftWaitToDraftAssumptionRequest;
};

export type MutationAddLocalDraftScenarioAssumptionDoNotDraftPlayerArgs = {
  request: AssumeDoNotDraftPlayerRequest;
};

export type MutationAddLocalDraftScenarioAssumptionPlayerDraftedAtPickArgs = {
  request: AssumePlayerDraftedAtPickRequest;
};

export type MutationAddUserDraftScenarioCutAssumptionArgs = {
  request: AddUserDraftScenarioCutAssumptionRequest;
};

export type MutationAddUserDraftScenarioDoNotDraftAssumptionArgs = {
  request: AddUserDraftScenarioDoNotDraftAssumptionRequest;
};

export type MutationAddUserDraftScenarioDraftWithPickAssumptionArgs = {
  request: AddUserDraftScenarioDraftWithPickAssumptionRequest;
};

export type MutationAddUserDraftScenarioKeepAssumptionArgs = {
  request: AddUserDraftScenarioKeepAssumptionRequest;
};

export type MutationAddUserDraftScenarioWaitToDraftAssumptionArgs = {
  request: AddUserDraftScenarioWaitToDraftAssumptionRequest;
};

export type MutationAssignPermissionsToRoleArgs = {
  request: AssignPermissionsToRoleRequest;
};

export type MutationAssignPickToClubArgs = {
  request: AssignPickToClubRequest;
};

export type MutationAssignPlayerOverrideArgs = {
  assignPlayerOverride: AssignPlayerOverrideRequest;
};

export type MutationAssumeDoNotDraftArgs = {
  request: AddDoNotDraftAssumptionCommandRequest;
};

export type MutationAssumeDoNotDraftUntilRoundArgs = {
  request: AddDoNotDraftUntilRoundAssumptionCommandRequest;
};

export type MutationAssumeMustNotSignPlayerArgs = {
  request: AddMustNotSignPlayerCommandRequest;
};

export type MutationAssumePlayerCutArgs = {
  request: AddCutPlayerAssumptionCommandRequest;
};

export type MutationAssumePlayerCutAfterDateArgs = {
  request: AddCutAfterDateAssumptionCommandRequest;
};

export type MutationAssumePlayerDraftedAtPickArgs = {
  request: AddDraftWithPickAssumptionCommandRequest;
};

export type MutationAssumePlayerKeptUntilDateArgs = {
  request: AddKeepUntilDateAssumptionCommandRequest;
};

export type MutationAssumePlayerKeptWithContractArgs = {
  request: AddKeepWithContractAssumptionCommandRequest;
};

export type MutationAssumePlayerKeptWithContractUntilDateArgs = {
  request: AddKeepWithContractDurationAssumptionCommandRequest;
};

export type MutationAssumeSignedPlayerArgs = {
  request: AddMustSignPlayerAssumptionCommandRequest;
};

export type MutationAssumeSignedPlayerThatCantBeCutArgs = {
  request: AddMustSignPlayerThatCantBeCutAssumptionCommandRequest;
};

export type MutationAssumeSignedPlayerToAnyContractArgs = {
  request: AddSignPlayerToAnyContractAssumptionCommandRequest;
};

export type MutationCreateEvaluationArgs = {
  request: CreateEvaluationRequest;
};

export type MutationCreateFavoriteArgs = {
  request: CreateFavoriteRequest;
};

export type MutationCreateGameEvaluationArgs = {
  request: CreateGameEvaluationRequest;
};

export type MutationCreateGradeWeightArgs = {
  createGradeWeightRequest: CreateGradeWeightRequest;
};

export type MutationCreateInjuryCollegeArgs = {
  createInjuryCollegeRequest: CreatePlayerInjuryColWithDetailsRequest;
};

export type MutationCreateInjuryProArgs = {
  createInjuryProRequest: CreatePlayerInjuryProWithDetailsRequest;
};

export type MutationCreatePermissionArgs = {
  request: CreatePermissionRequest;
};

export type MutationCreatePlayerArgs = {
  createPlayerRequest: CreatePlayerRequest;
};

export type MutationCreatePositionAtlasNodeArgs = {
  createPositionAtlasNodeRequest: CreatePositionAtlasNodeRequest;
};

export type MutationCreateRoleArgs = {
  request: CreateRoleRequest;
};

export type MutationCreateScenarioArgs = {
  request: CreateScenarioRequestInput;
};

export type MutationCreateScoutingAssignmentArgs = {
  request: CreateScoutingAssignmentRequest;
};

export type MutationCreateUserArgs = {
  request: CreateUserRequest;
};

export type MutationDeactivateUserArgs = {
  request: DeactivateUserRequest;
};

export type MutationDeleteAssumptionArgs = {
  request: DeleteAssumptionCommandRequest;
};

export type MutationDeleteFavoriteArgs = {
  request: DeleteFavoriteRequest;
};

export type MutationDeleteGameEvaluationArgs = {
  evaluationGameId: Scalars["UUID"];
  evaluationId: Scalars["UUID"];
};

export type MutationDeleteGlobalAssumptionArgs = {
  request: DeleteGlobalAssumptionRequest;
};

export type MutationDeleteLiveDraftAssumptionArgs = {
  request: DeleteLiveDraftAssumptionRequest;
};

export type MutationDeletePermissionArgs = {
  request: DeletePermissionRequest;
};

export type MutationDeleteRoleArgs = {
  request: DeleteRoleRequest;
};

export type MutationDeleteScoutingAssignmentArgs = {
  request: DeleteScoutingAssignmentRequest;
};

export type MutationDeleteUserDraftScenarioAssumptionArgs = {
  request: DeleteUserDraftScenarioAssumptionRequest;
};

export type MutationDiscardAllActiveNonSimDraftScenariosArgs = {
  season: Scalars["Int"];
};

export type MutationDraftScenarioCreateArgs = {
  request: CreateDraftScenarioRequest;
};

export type MutationDraftScenarioDiscardArgs = {
  request: DiscardDraftScenarioRequest;
};

export type MutationDraftScenarioDraftPlayersArgs = {
  request: DraftPlayersRequest;
};

export type MutationDraftScenarioExecuteTradeArgs = {
  request: ExecuteTradeRequest;
};

export type MutationDraftScenarioPinTradeArgs = {
  request: PinTradeRequest;
};

export type MutationDraftScenarioRenameArgs = {
  request: RenameDraftScenarioRequest;
};

export type MutationDraftScenarioRequestEvaluationRefreshArgs = {
  request: RequestEvaluationRefreshRequest;
};

export type MutationDraftScenarioRewindToPickArgs = {
  request: RewindToPickRequest;
};

export type MutationDraftScenarioSetWorkspacePlayersCustomArgs = {
  request: SetWorkspacePlayersCustomRequest;
};

export type MutationDraftScenarioSetWorkspacePlayersRecommendedArgs = {
  request: SetWorkspacePlayersRecommendedRequest;
};

export type MutationDraftScenarioSimulateToPickArgs = {
  request: SimulateToPickRequest;
};

export type MutationDraftScenarioTriggerPerformanceGapRefreshArgs = {
  draftId: Scalars["UUID"];
};

export type MutationDraftScenarioUndoLastActionArgs = {
  request: UndoLastActionRequest;
};

export type MutationDraftScenarioUnpinTradeArgs = {
  request: UnpinTradeRequest;
};

export type MutationDraftScenarioUpdateConfigurationArgs = {
  request: UpdateDraftScenarioConfigurationRequest;
};

export type MutationDraftScenarioUpdateTeamNeedsArgs = {
  request: UpdateTeamNeedsRequest;
};

export type MutationFlagEvaluationForDeleteArgs = {
  request: FlagEvaluationForDeleteRequest;
};

export type MutationHideScenarioArgs = {
  scenarioId: Scalars["UUID"];
};

export type MutationImportGlobalAssumptionsArgs = {
  scenarioId: Scalars["UUID"];
};

export type MutationMarkEvaluationAsPrimaryArgs = {
  request: MarkEvaluationAsPrimaryRequest;
};

export type MutationOverridePlayerGradeArgs = {
  request: OverridePlayerGradeRequest;
};

export type MutationOverrideTeamNeedsArgs = {
  request: OverrideTeamNeedsForClubRequest;
  where?: InputMaybe<TeamNeedsWrapperFilterInput>;
};

export type MutationProvisionUserArgs = {
  request: ProvisionUserRequest;
};

export type MutationRemoveLocalDraftScenarioAssumptionArgs = {
  request: RemoveAssumptionRequest;
};

export type MutationRemovePermissionsFromRoleArgs = {
  request: RemovePermissionsFromRoleRequest;
};

export type MutationRenameScenarioArgs = {
  request: RenameScenarioCommandRequest;
};

export type MutationResetPlayerSearchIndicesArgs = {
  resetPlayerSearchIndicesRequest: ResetPlayerSearchIndicesRequest;
};

export type MutationRunOptimizerArgs = {
  request: StartOptimizationCommandRequest;
};

export type MutationSaveEvaluationAlertsArgs = {
  request: SaveEvaluationAlertRequest;
};

export type MutationSaveEvaluationClubFitsArgs = {
  request: SaveEvaluationClubFitRequest;
};

export type MutationSaveEvaluationFitsArgs = {
  request: SaveEvaluationFitRequest;
};

export type MutationSaveEvaluationTraitArgs = {
  request: SaveEvaluationTraitRequest;
};

export type MutationSaveEvaluationTraitGroupArgs = {
  request: SaveEvaluationTraitGroupRequest;
};

export type MutationSaveProjectedGamesMissedArgs = {
  request: SaveProjectedGamesMissedRequest;
};

export type MutationSetOptimizationSettingsArgs = {
  request: SetOptimizationSettingsRequest;
};

export type MutationSyncAlertsArgs = {
  request: SyncAlertsRequestInput;
};

export type MutationSyncEvalsArgs = {
  request: SyncEvaluationMetricGroupsRequestInput;
};

export type MutationSyncFitsArgs = {
  request: SyncFitsRequestInput;
};

export type MutationSyncPositionsArgs = {
  request: SyncPositionsRequestInput;
};

export type MutationUnHideScenarioArgs = {
  scenarioId: Scalars["UUID"];
};

export type MutationUnMarkEvaluationAsFinalArgs = {
  request: UnMarkEvaluationAsFinalRequest;
};

export type MutationUpdateBasicPlayerDetailsArgs = {
  updateBasicPlayerDetailsRequest: UpdateBasicPlayerDetailsRequest;
};

export type MutationUpdateEvaluationArgs = {
  request: UpdateEvaluationRequest;
};

export type MutationUpdateGameEvaluationArgs = {
  request: UpdateGameEvaluationRequest;
};

export type MutationUpdateGradeWeightArgs = {
  updateGradeWeightRequest: UpdateGradeWeightRequest;
};

export type MutationUpdateInjuryCollegeArgs = {
  updateInjuryCollegeRequest: UpdatePlayerInjuryCollegeWithDetailsRequest;
};

export type MutationUpdateInjuryProArgs = {
  updateInjuryProRequest: UpdatePlayerInjuryProWithDetailsRequest;
};

export type MutationUpdatePermissionArgs = {
  request: UpdatePermissionRequest;
};

export type MutationUpdatePlayerArgs = {
  updatePlayerRequest: UpdatePlayerRequest;
};

export type MutationUpdatePositionDepthSurveyArgs = {
  newLimits: Array<PositionDepthLimitInput>;
};

export type MutationUpdatePositionalImportanceSurveyArgs = {
  newPage: PositionalImportancePageInput;
};

export type MutationUpdateRoleArgs = {
  request: UpdateRoleRequest;
};

export type MutationUpdateScoutingAssignmentArgs = {
  request: UpdateScoutingAssignmentRequest;
};

export type MutationUpdateUserArgs = {
  request: UpdateUserRequest;
};

/** A connection to a list of items. */
export type MyDraftScenariosPagedConnection = {
  __typename?: "MyDraftScenariosPagedConnection";
  /** A list of edges. */
  edges?: Maybe<Array<MyDraftScenariosPagedEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<DraftScenario>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type MyDraftScenariosPagedEdge = {
  __typename?: "MyDraftScenariosPagedEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: DraftScenario;
};

export type Nym = {
  __typename?: "NYM";
  id: Scalars["UUID"];
};

export type NymFilterInput = {
  and?: InputMaybe<Array<NymFilterInput>>;
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<NymFilterInput>>;
};

export type NymInput = {
  id: Scalars["UUID"];
};

export type NullableOfEligibilityTypeOperationFilterInput = {
  eq?: InputMaybe<EligibilityType>;
  in?: InputMaybe<Array<InputMaybe<EligibilityType>>>;
  neq?: InputMaybe<EligibilityType>;
  nin?: InputMaybe<Array<InputMaybe<EligibilityType>>>;
};

export type NullableOfFreeAgencyStatusOperationFilterInput = {
  eq?: InputMaybe<FreeAgencyStatus>;
  in?: InputMaybe<Array<InputMaybe<FreeAgencyStatus>>>;
  neq?: InputMaybe<FreeAgencyStatus>;
  nin?: InputMaybe<Array<InputMaybe<FreeAgencyStatus>>>;
};

export type NullableOfGradeWeightEnumOperationFilterInput = {
  eq?: InputMaybe<GradeWeightEnum>;
  in?: InputMaybe<Array<InputMaybe<GradeWeightEnum>>>;
  neq?: InputMaybe<GradeWeightEnum>;
  nin?: InputMaybe<Array<InputMaybe<GradeWeightEnum>>>;
};

export type NullableOfSideOfBallOperationFilterInput = {
  eq?: InputMaybe<SideOfBall>;
  in?: InputMaybe<Array<InputMaybe<SideOfBall>>>;
  neq?: InputMaybe<SideOfBall>;
  nin?: InputMaybe<Array<InputMaybe<SideOfBall>>>;
};

export type OptimizationSettings = {
  __typename?: "OptimizationSettings";
  horizonYears: Scalars["Int"];
  seasonWeights: Array<Scalars["Float"]>;
  startingSeason: Scalars["Int"];
};

export type OptimizationSettingsInput = {
  horizonYears: Scalars["Int"];
  seasonWeights: Array<Scalars["Float"]>;
  startingSeason: Scalars["Int"];
};

export type OptimizerRunError = {
  __typename?: "OptimizerRunError";
  error?: Maybe<Scalars["String"]>;
  errorCode?: Maybe<Scalars["Int"]>;
  inputVersion: Scalars["Int"];
  recordedAt: Scalars["DateTime"];
};

export type OptimizerRunResult = {
  __typename?: "OptimizerRunResult";
  inputVersion: Scalars["Int"];
  recordedAt: Scalars["DateTime"];
};

/** The trade value according to the Over the Cap chart */
export type OtcTradeValue = {
  __typename?: "OtcTradeValue";
  netPoints: Scalars["Float"];
  /** Points being offered by the club doing the analysis */
  offeringPoints: Scalars["Float"];
  /** Points being received by the club doing the analysis */
  receivingPoints: Scalars["Float"];
};

/** The trade value according to the Over the Cap chart */
export type OtcTradeValueFilterInput = {
  and?: InputMaybe<Array<OtcTradeValueFilterInput>>;
  netPoints?: InputMaybe<FloatOperationFilterInput>;
  /** Points being offered by the club doing the analysis */
  offeringPoints?: InputMaybe<FloatOperationFilterInput>;
  or?: InputMaybe<Array<OtcTradeValueFilterInput>>;
  /** Points being received by the club doing the analysis */
  receivingPoints?: InputMaybe<FloatOperationFilterInput>;
};

export type OverridePlayerGradeRequest = {
  playerId: Scalars["UUID"];
  rawValueOverride: Scalars["String"];
  season: Scalars["Int"];
};

/** Specify the Club IP of overridden team needs for a given club */
export type OverrideTeamNeedsForClubRequest = {
  /** The club to specify team needs overrides for */
  clubId: Scalars["UUID"];
  /** The ordered list (with first being most important) of positional needs where each position correlates to the NodeId of a Sumer general position */
  sumerGeneralPositions: Array<Scalars["String"]>;
};

/** Defines an offensive or defensive personnel group used by a team */
export type Package = {
  __typename?: "Package";
  /** Package abbreviation defined by PFF */
  abbreviation: Scalars["String"];
  created: Scalars["DateTime"];
  /** Package description defined by PFF */
  description: Scalars["String"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** Package name - defaulted to abbreviation */
  name: Scalars["String"];
  pffPackageId: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Package side of ball */
  sideOfBall: SideOfBall;
};

/** PFF's definition of packages a club commonly uses (per pff.packages_depthcharts_nfl) */
export type PackageClub = {
  __typename?: "PackageClub";
  club: Club;
  /** Club that runs the package according to PFF */
  clubId: Scalars["UUID"];
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  /**
   * Is this a base package for the club? Not set by default.
   * Might be able to pull from league data
   */
  isBase: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  package: Package;
  /** Package Id */
  packageId: Scalars["UUID"];
  /** Priority of the package for that club */
  priority?: Maybe<Scalars["Int"]>;
  rowVersion: Array<Scalars["Byte"]>;
};

/** PFF's definition of packages a club commonly uses (per pff.packages_depthcharts_nfl) */
export type PackageClubFilterInput = {
  and?: InputMaybe<Array<PackageClubFilterInput>>;
  club?: InputMaybe<ClubFilterInput>;
  /** Club that runs the package according to PFF */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /**
   * Is this a base package for the club? Not set by default.
   * Might be able to pull from league data
   */
  isBase?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PackageClubFilterInput>>;
  package?: InputMaybe<PackageFilterInput>;
  /** Package Id */
  packageId?: InputMaybe<UuidOperationFilterInput>;
  /** Priority of the package for that club */
  priority?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

/** PFF's definition of packages a club commonly uses (per pff.packages_depthcharts_nfl) */
export type PackageClubSortInput = {
  club?: InputMaybe<ClubSortInput>;
  /** Club that runs the package according to PFF */
  clubId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /**
   * Is this a base package for the club? Not set by default.
   * Might be able to pull from league data
   */
  isBase?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  package?: InputMaybe<PackageSortInput>;
  /** Package Id */
  packageId?: InputMaybe<SortEnumType>;
  /** Priority of the package for that club */
  priority?: InputMaybe<SortEnumType>;
};

/** Defines an offensive or defensive personnel group used by a team */
export type PackageFilterInput = {
  /** Package abbreviation defined by PFF */
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<PackageFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Package description defined by PFF */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** Package name - defaulted to abbreviation */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PackageFilterInput>>;
  pffPackageId?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Package side of ball */
  sideOfBall?: InputMaybe<SideOfBallOperationFilterInput>;
};

/** Positions defined within a package */
export type PackagePosition = {
  __typename?: "PackagePosition";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** One of the packages that contains the position. */
  package: Package;
  /** Foreign key to Package */
  packageId: Scalars["UUID"];
  /** One of the positions the player plays. */
  position: Position;
  /** Foreign key to Position */
  positionId: Scalars["UUID"];
  /** Order of display */
  priority?: Maybe<Scalars["Int"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** Position of package position for display */
  x?: Maybe<Scalars["Int"]>;
  y?: Maybe<Scalars["Int"]>;
};

/** Positions defined within a package */
export type PackagePositionFilterInput = {
  and?: InputMaybe<Array<PackagePositionFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PackagePositionFilterInput>>;
  /** One of the packages that contains the position. */
  package?: InputMaybe<PackageFilterInput>;
  /** Foreign key to Package */
  packageId?: InputMaybe<UuidOperationFilterInput>;
  /** One of the positions the player plays. */
  position?: InputMaybe<PositionFilterInput>;
  /** Foreign key to Position */
  positionId?: InputMaybe<UuidOperationFilterInput>;
  /** Order of display */
  priority?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Position of package position for display */
  x?: InputMaybe<IntOperationFilterInput>;
  y?: InputMaybe<IntOperationFilterInput>;
};

/** Positions defined within a package */
export type PackagePositionSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** One of the packages that contains the position. */
  package?: InputMaybe<PackageSortInput>;
  /** Foreign key to Package */
  packageId?: InputMaybe<SortEnumType>;
  /** One of the positions the player plays. */
  position?: InputMaybe<PositionSortInput>;
  /** Foreign key to Position */
  positionId?: InputMaybe<SortEnumType>;
  /** Order of display */
  priority?: InputMaybe<SortEnumType>;
  /** Position of package position for display */
  x?: InputMaybe<SortEnumType>;
  y?: InputMaybe<SortEnumType>;
};

/** Defines an offensive or defensive personnel group used by a team */
export type PackageSortInput = {
  /** Package abbreviation defined by PFF */
  abbreviation?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** Package description defined by PFF */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Package name - defaulted to abbreviation */
  name?: InputMaybe<SortEnumType>;
  pffPackageId?: InputMaybe<SortEnumType>;
  /** Package side of ball */
  sideOfBall?: InputMaybe<SortEnumType>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars["Boolean"];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** A performance gap is a positional view of value on a roster. */
export type PerformanceGap = {
  __typename?: "PerformanceGap";
  /** Current sum of performance values at the position */
  currentPerformanceValue: Value;
  /** The amount of performance value added to the position in the draft scenario */
  performanceValueAddedDollars: Scalars["Float"];
  /** The dollars gap to get to the target */
  remainingGapDollars: Scalars["Float"];
  /** The side of ball the position belongs to */
  sideOfBall?: Maybe<SideOfBall>;
  /** The positional performance value at the start of the draft scenario */
  startingPerformanceValue: Value;
  /** The corresponding sumer general position */
  sumerGeneralPositionShortName: Scalars["String"];
  /** Target performance value at the position */
  targetPerformanceValue: Value;
};

/** A performance gap is a positional view of value on a roster. */
export type PerformanceGapFilterInput = {
  and?: InputMaybe<Array<PerformanceGapFilterInput>>;
  /** Current sum of performance values at the position */
  currentPerformanceValue?: InputMaybe<ValueFilterInput>;
  or?: InputMaybe<Array<PerformanceGapFilterInput>>;
  /** The amount of performance value added to the position in the draft scenario */
  performanceValueAddedDollars?: InputMaybe<FloatOperationFilterInput>;
  /** The dollars gap to get to the target */
  remainingGapDollars?: InputMaybe<FloatOperationFilterInput>;
  /** The side of ball the position belongs to */
  sideOfBall?: InputMaybe<NullableOfSideOfBallOperationFilterInput>;
  /** The positional performance value at the start of the draft scenario */
  startingPerformanceValue?: InputMaybe<ValueFilterInput>;
  /** The corresponding sumer general position */
  sumerGeneralPositionShortName?: InputMaybe<StringOperationFilterInput>;
  /** Target performance value at the position */
  targetPerformanceValue?: InputMaybe<ValueFilterInput>;
};

/** A permission represents access to some feature or data element within the system. */
export type Permission = {
  __typename?: "Permission";
  created: Scalars["DateTime"];
  createdById: Scalars["UUID"];
  /** Description of permission */
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  lastUpdated: Scalars["DateTime"];
  lastUpdatedById: Scalars["UUID"];
  /** Permission name for application functionality */
  name: Scalars["String"];
  /** Roles with permission assigned */
  roles: Array<AppRole>;
  rowVersion: Array<Scalars["Byte"]>;
};

/** A permission represents access to some feature or data element within the system. */
export type PermissionFilterInput = {
  and?: InputMaybe<Array<PermissionFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  /** Description of permission */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedById?: InputMaybe<UuidOperationFilterInput>;
  /** Permission name for application functionality */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PermissionFilterInput>>;
  /** Roles with permission assigned */
  roles?: InputMaybe<ListFilterInputTypeOfAppRoleFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

/** A permission represents access to some feature or data element within the system. */
export type PermissionSortInput = {
  created?: InputMaybe<SortEnumType>;
  createdById?: InputMaybe<SortEnumType>;
  /** Description of permission */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  lastUpdatedById?: InputMaybe<SortEnumType>;
  /** Permission name for application functionality */
  name?: InputMaybe<SortEnumType>;
};

export enum Personnel {
  DEFENSE_BASE = "DEFENSE_BASE",
  DEFENSE_DIME = "DEFENSE_DIME",
  DEFENSE_NICKEL = "DEFENSE_NICKEL",
  OFFENSE01 = "OFFENSE01",
  OFFENSE02 = "OFFENSE02",
  OFFENSE11 = "OFFENSE11",
  OFFENSE12 = "OFFENSE12",
  OFFENSE13 = "OFFENSE13",
  OFFENSE20 = "OFFENSE20",
  OFFENSE21 = "OFFENSE21",
  OFFENSE22 = "OFFENSE22",
  OFFENSE_JUMBO = "OFFENSE_JUMBO",
}

/** An opportunity for the club to trade picks based on the value of the picks to each club. */
export type PickTradeOpportunity = {
  __typename?: "PickTradeOpportunity";
  /** The value of PickToTrade to the club */
  clubValue: ClubPickValue;
  /** The value of PickToTrade to the current owner */
  ownerValue: Value;
  /** The pick the club can trade for */
  pickToTrade: CurrentSeasonDraftAsset;
};

/** An opportunity for the club to trade picks based on the value of the picks to each club. */
export type PickTradeOpportunityFilterInput = {
  and?: InputMaybe<Array<PickTradeOpportunityFilterInput>>;
  /** The value of PickToTrade to the club */
  clubValue?: InputMaybe<ClubPickValueFilterInput>;
  or?: InputMaybe<Array<PickTradeOpportunityFilterInput>>;
  /** The value of PickToTrade to the current owner */
  ownerValue?: InputMaybe<ValueFilterInput>;
  /** The pick the club can trade for */
  pickToTrade?: InputMaybe<CurrentSeasonDraftAssetFilterInput>;
};

/** Request that a trade be pinned in a draft scenario.  Will also update an existing pinned trade when TradeId is supplied. */
export type PinTradeRequest = {
  /** Unique ID of the draft scenario */
  draftId: Scalars["UUID"];
  /** The club evaluating the trade.  Must have assets in the trade. */
  forClubId: Scalars["UUID"];
  /** The sumer trade value is refreshed after models are run, but if a user has already requested a sumer evaluation, it can optionally be supplied and set as the initial value.. */
  sumerTradeValue?: InputMaybe<SumerTradeValueInput>;
  /** Unique ID of the trade to be evaluated.  If not provided, set internally and forces creation flow.  Otherwise pinned trade is updated. */
  tradeId?: InputMaybe<Scalars["Int"]>;
  /** The assets within the trade */
  tradePartners: Array<TradePartnerInput>;
};

export type Play = {
  __typename?: "Play";
  clock?: Maybe<Scalars["String"]>;
  defense?: Maybe<Scalars["UUID"]>;
  defensivePersonnel?: Maybe<Scalars["String"]>;
  distance?: Maybe<Scalars["Int"]>;
  down?: Maybe<Scalars["Int"]>;
  fieldPosition?: Maybe<Scalars["Int"]>;
  game: Game;
  gameId: Scalars["UUID"];
  goalLine: Scalars["Boolean"];
  gsisGameId?: Maybe<Scalars["Int"]>;
  gsisPlayId?: Maybe<Scalars["Int"]>;
  lastModified?: Maybe<Scalars["DateTime"]>;
  leagueType: LeagueType;
  leagueTypeId: Scalars["Int"];
  offense?: Maybe<Scalars["UUID"]>;
  offensivePersonnel?: Maybe<Scalars["String"]>;
  playId: Scalars["UUID"];
  playerPlays: Array<PlayerPlay>;
  quarter?: Maybe<Scalars["Int"]>;
  redZone: Scalars["Boolean"];
  runPass?: Maybe<Scalars["String"]>;
  season?: Maybe<Scalars["Int"]>;
  shortYardage: Scalars["Boolean"];
  specialTeamsType?: Maybe<Scalars["String"]>;
  twoMinute?: Maybe<Scalars["Int"]>;
};

export type PlayFilterInput = {
  and?: InputMaybe<Array<PlayFilterInput>>;
  clock?: InputMaybe<StringOperationFilterInput>;
  defense?: InputMaybe<UuidOperationFilterInput>;
  defensivePersonnel?: InputMaybe<StringOperationFilterInput>;
  distance?: InputMaybe<IntOperationFilterInput>;
  down?: InputMaybe<IntOperationFilterInput>;
  fieldPosition?: InputMaybe<IntOperationFilterInput>;
  game?: InputMaybe<GameFilterInput>;
  gameId?: InputMaybe<UuidOperationFilterInput>;
  goalLine?: InputMaybe<BooleanOperationFilterInput>;
  gsisGameId?: InputMaybe<IntOperationFilterInput>;
  gsisPlayId?: InputMaybe<IntOperationFilterInput>;
  lastModified?: InputMaybe<DateTimeOperationFilterInput>;
  leagueType?: InputMaybe<LeagueTypeFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  offense?: InputMaybe<UuidOperationFilterInput>;
  offensivePersonnel?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PlayFilterInput>>;
  playId?: InputMaybe<UuidOperationFilterInput>;
  playerPlays?: InputMaybe<ListFilterInputTypeOfPlayerPlayFilterInput>;
  quarter?: InputMaybe<IntOperationFilterInput>;
  redZone?: InputMaybe<BooleanOperationFilterInput>;
  runPass?: InputMaybe<StringOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  shortYardage?: InputMaybe<BooleanOperationFilterInput>;
  specialTeamsType?: InputMaybe<StringOperationFilterInput>;
  twoMinute?: InputMaybe<IntOperationFilterInput>;
};

export type PlayFilterRequestInput = {
  distances?: InputMaybe<Array<Scalars["Int"]>>;
  downs?: InputMaybe<Array<Scalars["Int"]>>;
  gameIds?: InputMaybe<Array<Scalars["UUID"]>>;
  isGoalline?: InputMaybe<Scalars["Boolean"]>;
  isInTwoMinutes?: InputMaybe<Scalars["Boolean"]>;
  isRedzone?: InputMaybe<Scalars["Boolean"]>;
  isShortYardage?: InputMaybe<Scalars["Boolean"]>;
  keyPlays?: InputMaybe<Array<KeyPlay>>;
  personnels?: InputMaybe<Array<Personnel>>;
  playTypes?: InputMaybe<Array<PlayType>>;
  playerIds?: InputMaybe<Array<Scalars["UUID"]>>;
  seasons?: InputMaybe<Array<Scalars["Int"]>>;
  sidesOfBall?: InputMaybe<Array<SideOfBall>>;
  teamId?: InputMaybe<Scalars["UUID"]>;
};

export type PlaySortInput = {
  clock?: InputMaybe<SortEnumType>;
  defense?: InputMaybe<SortEnumType>;
  defensivePersonnel?: InputMaybe<SortEnumType>;
  distance?: InputMaybe<SortEnumType>;
  down?: InputMaybe<SortEnumType>;
  fieldPosition?: InputMaybe<SortEnumType>;
  game?: InputMaybe<GameSortInput>;
  gameId?: InputMaybe<SortEnumType>;
  goalLine?: InputMaybe<SortEnumType>;
  gsisGameId?: InputMaybe<SortEnumType>;
  gsisPlayId?: InputMaybe<SortEnumType>;
  lastModified?: InputMaybe<SortEnumType>;
  leagueType?: InputMaybe<LeagueTypeSortInput>;
  leagueTypeId?: InputMaybe<SortEnumType>;
  offense?: InputMaybe<SortEnumType>;
  offensivePersonnel?: InputMaybe<SortEnumType>;
  playId?: InputMaybe<SortEnumType>;
  quarter?: InputMaybe<SortEnumType>;
  redZone?: InputMaybe<SortEnumType>;
  runPass?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  shortYardage?: InputMaybe<SortEnumType>;
  specialTeamsType?: InputMaybe<SortEnumType>;
  twoMinute?: InputMaybe<SortEnumType>;
};

export enum PlayType {
  EXTRA_POINT = "EXTRA_POINT",
  FIELD_GOAL = "FIELD_GOAL",
  KICKOFF = "KICKOFF",
  NO_PLAY = "NO_PLAY",
  PASS = "PASS",
  PUNT = "PUNT",
  RUN = "RUN",
}

/** A football player. */
export type Player = {
  __typename?: "Player";
  /**
   * Accrued seasons in an NFL contract refer to the number of seasons a player has been on an active
   * roster or injured reserve for at least six games. These accrued seasons impact a player's eligibility
   * for various benefits, such as unrestricted free agency, pension plans, and the ability to qualify for
   * certain salary escalators or contract guarantees.
   */
  accruedSeasons?: Maybe<Scalars["Int"]>;
  agent?: Maybe<Agent>;
  /** Player's agent */
  agentId?: Maybe<Scalars["UUID"]>;
  assumptions: Array<Assumption>;
  /** The day the player was born */
  birthDate?: Maybe<Scalars["DateTime"]>;
  /** The team the player currently plays for */
  club?: Maybe<Club>;
  /**
   * The position (if any) that the club has graded the player at.  Expectation is that the application
   * will only ingest one graded position for a given player.
   */
  clubGradingPosition?: Maybe<Position>;
  /** FK ID of the club graded position */
  clubGradingPositionId?: Maybe<Scalars["UUID"]>;
  /** Foreign key to Club */
  clubId?: Maybe<Scalars["UUID"]>;
  /** The player's league replication ID */
  collegeGsisId?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  draftClub?: Maybe<Club>;
  /** The club that drafted the player (if drafted) */
  draftClubId?: Maybe<Scalars["UUID"]>;
  draftRound?: Maybe<Scalars["Int"]>;
  draftSelection?: Maybe<Scalars["Int"]>;
  /** The year the player was drafted into the NFL */
  draftYear?: Maybe<Scalars["Int"]>;
  /** College details - college class */
  eligibility?: Maybe<EligibilityType>;
  /** College details - year eligible for the draft */
  eligibilityYear?: Maybe<Scalars["Int"]>;
  /** All evaluations performed against this player */
  evaluations: Array<Evaluation>;
  favorite?: Maybe<PlayerFavorite>;
  /** The player's first name */
  firstName: Scalars["String"];
  freeAgencyStatus?: Maybe<FreeAgencyStatus>;
  grades: Array<Grade>;
  /** The player's league replication ID */
  gsisId?: Maybe<Scalars["String"]>;
  /** A URL to the player's headshot image */
  headshotUrl?: Maybe<Scalars["String"]>;
  /** The player's latest height measurement in inches according to pff/league */
  height?: Maybe<Scalars["Int"]>;
  highschool?: Maybe<Scalars["String"]>;
  /** Where the player is from */
  hometownCity?: Maybe<Scalars["String"]>;
  hometownCountry?: Maybe<Scalars["String"]>;
  hometownState?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** The player's current jersey number */
  jersey?: Maybe<Scalars["String"]>;
  /** The player's last name */
  lastName: Scalars["String"];
  lastUpdated: Scalars["DateTime"];
  latestBirthDate?: Maybe<Scalars["String"]>;
  latestClubId?: Maybe<Scalars["UUID"]>;
  latestEligibility?: Maybe<EligibilityType>;
  latestEligibilityYear?: Maybe<Scalars["Int"]>;
  latestSchoolId?: Maybe<Scalars["UUID"]>;
  /** The player's College league id (CollegePlayerId - nonprodb.college_player_extract) */
  leagueCollegeId?: Maybe<Scalars["Int"]>;
  /** The player's middle name */
  middleName?: Maybe<Scalars["String"]>;
  /**
   * Minimum salary credits refer to the financial benefit given to a team when they sign a player with a
   * higher salary than the league's minimum. These credits offset a portion of the player's salary cap hit,
   * allowing the team to allocate more funds to other player contracts while still meeting the league's salary floor.
   */
  minSalaryCredits?: Maybe<Scalars["Int"]>;
  /**
   * The most recent team a player played for
   * Solves issue when a club drops from a roster for reporting
   */
  mostRecentClub?: Maybe<Club>;
  /** Foreign key to Club */
  mostRecentClubId?: Maybe<Scalars["UUID"]>;
  namePronunciation?: Maybe<Scalars["String"]>;
  /** The number of years of professional playing experience the player has */
  nflExperience: Scalars["Int"];
  /** If player is on a club roster */
  onClubRoster: Scalars["Boolean"];
  /** If player is on a current roster */
  onCollegeRoster: Scalars["Boolean"];
  /** the player's overall and season grades */
  playerGrade?: Maybe<PlayerGrade>;
  /**
   * Overriden fields on the player.
   * Ex: Scout may override BirthDate for a player
   */
  playerOverrides: Array<PlayerOverride>;
  /** The depth chart package positions a player plays, based on PFF. */
  playerPffPackagePositions: Array<PlayerPackagePosition>;
  /** Games missed projection for the player */
  projectedGamesMissed: Array<PlayerProjectedGamesMissed>;
  /** College details - has redshirted */
  redshirt: Scalars["Boolean"];
  rowVersion: Array<Scalars["Byte"]>;
  /** The school the player is currently attending (college) or last college of pro player */
  school?: Maybe<School>;
  /** Foreign key to School */
  schoolId?: Maybe<Scalars["UUID"]>;
  /** The player's latest speed according to pff */
  speed?: Maybe<Scalars["Decimal"]>;
  /** The player's current status */
  status: PlayerStatus;
  /** The player's last name suffix */
  suffix?: Maybe<Scalars["String"]>;
  /**
   * The position (if any) of the player in the Sumer atlas position set.  This is a
   * subset of the SumerGeneralPosition that is significantly more granular.
   */
  sumerAtlasPosition?: Maybe<Position>;
  /** FK ID of the sumer atlas position */
  sumerAtlasPositionId?: Maybe<Scalars["UUID"]>;
  /** The position (if any) of the player in the Sumer general position set. */
  sumerGeneralPosition?: Maybe<Position>;
  /** FK ID of the sumer general position */
  sumerGeneralPositionId?: Maybe<Scalars["UUID"]>;
  /** The player's latest weight measurement in pounds according to pff/league */
  weight?: Maybe<Scalars["Int"]>;
  /** All measurable sources for this player */
  workouts: Array<WorkoutPlayerMeasure>;
};

export type PlayerDetailsInput = {
  accruedSeasons?: InputMaybe<Scalars["Int"]>;
  agentId?: InputMaybe<Scalars["UUID"]>;
  birthDate?: InputMaybe<Scalars["DateTime"]>;
  clubFranchiseId?: InputMaybe<Scalars["Int"]>;
  collegeClass?: InputMaybe<EligibilityType>;
  collegeGsisId?: InputMaybe<Scalars["String"]>;
  currentEligibleYear?: InputMaybe<Scalars["Int"]>;
  draftFranchiseId?: InputMaybe<Scalars["Int"]>;
  draftRound?: InputMaybe<Scalars["Int"]>;
  draftSelection?: InputMaybe<Scalars["Int"]>;
  draftYear?: InputMaybe<Scalars["Int"]>;
  firstName: Scalars["String"];
  freeAgencyStatus?: InputMaybe<FreeAgencyStatus>;
  gsisAltId?: InputMaybe<Scalars["String"]>;
  gsisId?: InputMaybe<Scalars["String"]>;
  headshot?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Int"]>;
  highschool?: InputMaybe<Scalars["String"]>;
  hometownCountry?: InputMaybe<Scalars["String"]>;
  hometownName?: InputMaybe<Scalars["String"]>;
  hometownState?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  jersey?: InputMaybe<Scalars["String"]>;
  lastModified?: InputMaybe<Scalars["Int"]>;
  lastName: Scalars["String"];
  league?: InputMaybe<Scalars["String"]>;
  leagueAgentId?: InputMaybe<Scalars["Int"]>;
  leagueCollegeId?: InputMaybe<Scalars["Int"]>;
  leagueNflId?: InputMaybe<Scalars["Int"]>;
  marvalClubId?: InputMaybe<Scalars["UUID"]>;
  marvalDraftClubId?: InputMaybe<Scalars["UUID"]>;
  marvalMostRecentClubId?: InputMaybe<Scalars["UUID"]>;
  middleName?: InputMaybe<Scalars["String"]>;
  minSalaryCredits?: InputMaybe<Scalars["Int"]>;
  namePronunciation?: InputMaybe<Scalars["String"]>;
  nflApiPersonId?: InputMaybe<Scalars["UUID"]>;
  nflExperience: Scalars["Int"];
  onClubRoster: Scalars["Boolean"];
  onCollegeRoster: Scalars["Boolean"];
  pffPlayerId: Scalars["Int"];
  redshirt: Scalars["Boolean"];
  retired?: InputMaybe<Scalars["Boolean"]>;
  retiredSeason?: InputMaybe<Scalars["Int"]>;
  schoolCode?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["UUID"]>;
  speed?: InputMaybe<Scalars["Decimal"]>;
  status: PlayerStatus;
  suffix?: InputMaybe<Scalars["String"]>;
  sumerAtlasPosition?: InputMaybe<Scalars["String"]>;
  sumerAtlasPositionId?: InputMaybe<Scalars["UUID"]>;
  sumerGeneralPosition?: InputMaybe<Scalars["String"]>;
  sumerGeneralPositionId?: InputMaybe<Scalars["UUID"]>;
  weight?: InputMaybe<Scalars["Int"]>;
};

/**
 * Player favorite store for the draft dashboard
 * TODO: Future release, we will add an UserId to this entity to store by user
 * to implement favorites by user vs global
 */
export type PlayerFavorite = {
  __typename?: "PlayerFavorite";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** The Player foreign key */
  player: Player;
  /** Player assigned to favorite */
  playerId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
};

/**
 * Player favorite store for the draft dashboard
 * TODO: Future release, we will add an UserId to this entity to store by user
 * to implement favorites by user vs global
 */
export type PlayerFavoriteFilterInput = {
  and?: InputMaybe<Array<PlayerFavoriteFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerFavoriteFilterInput>>;
  /** The Player foreign key */
  player?: InputMaybe<PlayerFilterInput>;
  /** Player assigned to favorite */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

/**
 * Player favorite store for the draft dashboard
 * TODO: Future release, we will add an UserId to this entity to store by user
 * to implement favorites by user vs global
 */
export type PlayerFavoriteSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The Player foreign key */
  player?: InputMaybe<PlayerSortInput>;
  /** Player assigned to favorite */
  playerId?: InputMaybe<SortEnumType>;
};

/** A football player. */
export type PlayerFilterInput = {
  /**
   * Accrued seasons in an NFL contract refer to the number of seasons a player has been on an active
   * roster or injured reserve for at least six games. These accrued seasons impact a player's eligibility
   * for various benefits, such as unrestricted free agency, pension plans, and the ability to qualify for
   * certain salary escalators or contract guarantees.
   */
  accruedSeasons?: InputMaybe<IntOperationFilterInput>;
  agent?: InputMaybe<AgentFilterInput>;
  /** Player's agent */
  agentId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<PlayerFilterInput>>;
  assumptions?: InputMaybe<ListFilterInputTypeOfAssumptionFilterInput>;
  /** The day the player was born */
  birthDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** The team the player currently plays for */
  club?: InputMaybe<ClubFilterInput>;
  /**
   * The position (if any) that the club has graded the player at.  Expectation is that the application
   * will only ingest one graded position for a given player.
   */
  clubGradingPosition?: InputMaybe<PositionFilterInput>;
  /** FK ID of the club graded position */
  clubGradingPositionId?: InputMaybe<UuidOperationFilterInput>;
  /** Foreign key to Club */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  /** The player's league replication ID */
  collegeGsisId?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  draftClub?: InputMaybe<ClubFilterInput>;
  /** The club that drafted the player (if drafted) */
  draftClubId?: InputMaybe<UuidOperationFilterInput>;
  draftRound?: InputMaybe<IntOperationFilterInput>;
  draftSelection?: InputMaybe<IntOperationFilterInput>;
  /** The year the player was drafted into the NFL */
  draftYear?: InputMaybe<IntOperationFilterInput>;
  /** College details - college class */
  eligibility?: InputMaybe<NullableOfEligibilityTypeOperationFilterInput>;
  /** College details - year eligible for the draft */
  eligibilityYear?: InputMaybe<IntOperationFilterInput>;
  /** All evaluations performed against this player */
  evaluations?: InputMaybe<ListFilterInputTypeOfEvaluationFilterInput>;
  favorite?: InputMaybe<PlayerFavoriteFilterInput>;
  /** The player's first name */
  firstName?: InputMaybe<StringOperationFilterInput>;
  freeAgencyStatus?: InputMaybe<NullableOfFreeAgencyStatusOperationFilterInput>;
  grades?: InputMaybe<ListFilterInputTypeOfGradeFilterInput>;
  /** The player's league replication ID */
  gsisId?: InputMaybe<StringOperationFilterInput>;
  /** A URL to the player's headshot image */
  headshotUrl?: InputMaybe<StringOperationFilterInput>;
  /** The player's latest height measurement in inches according to pff/league */
  height?: InputMaybe<IntOperationFilterInput>;
  highschool?: InputMaybe<StringOperationFilterInput>;
  /** Where the player is from */
  hometownCity?: InputMaybe<StringOperationFilterInput>;
  hometownCountry?: InputMaybe<StringOperationFilterInput>;
  hometownState?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** The player's current jersey number */
  jersey?: InputMaybe<StringOperationFilterInput>;
  /** The player's last name */
  lastName?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  latestBirthDate?: InputMaybe<StringOperationFilterInput>;
  latestClubId?: InputMaybe<UuidOperationFilterInput>;
  latestEligibility?: InputMaybe<NullableOfEligibilityTypeOperationFilterInput>;
  latestEligibilityYear?: InputMaybe<IntOperationFilterInput>;
  latestSchoolId?: InputMaybe<UuidOperationFilterInput>;
  /** The player's College league id (CollegePlayerId - nonprodb.college_player_extract) */
  leagueCollegeId?: InputMaybe<IntOperationFilterInput>;
  /** The player's middle name */
  middleName?: InputMaybe<StringOperationFilterInput>;
  /**
   * Minimum salary credits refer to the financial benefit given to a team when they sign a player with a
   * higher salary than the league's minimum. These credits offset a portion of the player's salary cap hit,
   * allowing the team to allocate more funds to other player contracts while still meeting the league's salary floor.
   */
  minSalaryCredits?: InputMaybe<IntOperationFilterInput>;
  /**
   * The most recent team a player played for
   * Solves issue when a club drops from a roster for reporting
   */
  mostRecentClub?: InputMaybe<ClubFilterInput>;
  /** Foreign key to Club */
  mostRecentClubId?: InputMaybe<UuidOperationFilterInput>;
  namePronunciation?: InputMaybe<StringOperationFilterInput>;
  /** The number of years of professional playing experience the player has */
  nflExperience?: InputMaybe<IntOperationFilterInput>;
  /** If player is on a club roster */
  onClubRoster?: InputMaybe<BooleanOperationFilterInput>;
  /** If player is on a current roster */
  onCollegeRoster?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<PlayerFilterInput>>;
  /** the player's overall and season grades */
  playerGrade?: InputMaybe<PlayerGradeFilterInput>;
  /**
   * Overriden fields on the player.
   * Ex: Scout may override BirthDate for a player
   */
  playerOverrides?: InputMaybe<ListFilterInputTypeOfPlayerOverrideFilterInput>;
  /** The depth chart package positions a player plays, based on PFF. */
  playerPffPackagePositions?: InputMaybe<ListFilterInputTypeOfPlayerPackagePositionFilterInput>;
  /** Games missed projection for the player */
  projectedGamesMissed?: InputMaybe<ListFilterInputTypeOfPlayerProjectedGamesMissedFilterInput>;
  /** College details - has redshirted */
  redshirt?: InputMaybe<BooleanOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** The school the player is currently attending (college) or last college of pro player */
  school?: InputMaybe<SchoolFilterInput>;
  /** Foreign key to School */
  schoolId?: InputMaybe<UuidOperationFilterInput>;
  /** The player's latest speed according to pff */
  speed?: InputMaybe<DecimalOperationFilterInput>;
  /** The player's current status */
  status?: InputMaybe<PlayerStatusOperationFilterInput>;
  /** The player's last name suffix */
  suffix?: InputMaybe<StringOperationFilterInput>;
  /**
   * The position (if any) of the player in the Sumer atlas position set.  This is a
   * subset of the SumerGeneralPosition that is significantly more granular.
   */
  sumerAtlasPosition?: InputMaybe<PositionFilterInput>;
  /** FK ID of the sumer atlas position */
  sumerAtlasPositionId?: InputMaybe<UuidOperationFilterInput>;
  /** The position (if any) of the player in the Sumer general position set. */
  sumerGeneralPosition?: InputMaybe<PositionFilterInput>;
  /** FK ID of the sumer general position */
  sumerGeneralPositionId?: InputMaybe<UuidOperationFilterInput>;
  /** The player's latest weight measurement in pounds according to pff/league */
  weight?: InputMaybe<IntOperationFilterInput>;
  /** All measurable sources for this player */
  workouts?: InputMaybe<ListFilterInputTypeOfWorkoutPlayerMeasureFilterInput>;
};

export type PlayerFiltersInput = {
  alertAbbreviation?: InputMaybe<Scalars["String"]>;
  clubCode?: InputMaybe<Scalars["String"]>;
  collegeOnly?: InputMaybe<Scalars["Boolean"]>;
  displayPositionShortName?: InputMaybe<Array<Scalars["String"]>>;
  draftYear?: InputMaybe<Scalars["Int"]>;
  evaluatedByUserId?: InputMaybe<Scalars["String"]>;
  maxClubGrade?: InputMaybe<Scalars["Float"]>;
  maxDraftYear?: InputMaybe<Scalars["Int"]>;
  maxHeight?: InputMaybe<Scalars["Int"]>;
  maxSpeed?: InputMaybe<Scalars["Float"]>;
  maxWeight?: InputMaybe<Scalars["Int"]>;
  minClubGrade?: InputMaybe<Scalars["Float"]>;
  minDraftYear?: InputMaybe<Scalars["Int"]>;
  minHeight?: InputMaybe<Scalars["Int"]>;
  minSpeed?: InputMaybe<Scalars["Float"]>;
  minWeight?: InputMaybe<Scalars["Int"]>;
  primarySchemeFitAbbreviation?: InputMaybe<Scalars["String"]>;
  primarySchemePositionShortName?: InputMaybe<Scalars["String"]>;
  proOnly?: InputMaybe<Scalars["Boolean"]>;
  schemeFitAbbreviation?: InputMaybe<Scalars["String"]>;
  schoolCode?: InputMaybe<Scalars["String"]>;
  sumerAtlasPositionShortName?: InputMaybe<Array<Scalars["String"]>>;
  sumerGeneralPositionShortName?: InputMaybe<Array<Scalars["String"]>>;
};

/** Represents a player grade, taking into account the weighted grade types, and n-seasons worth of projections. */
export type PlayerGrade = {
  __typename?: "PlayerGrade";
  /** average club gapypoc dollars */
  avgClubGapypocDollars: Scalars["Decimal"];
  /** average sage gapypoc dollars */
  avgSageGapypocDollars: Scalars["Decimal"];
  /** average sumer scout gapypoc dollars */
  avgSumerScoutGapypocDollars: Scalars["Decimal"];
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** the football player this grade corresponds to */
  player: Player;
  /** the marval player id */
  playerId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  /** grades by season */
  seasons: Array<PlayerGradeSeason>;
  /** overall weighted grade value */
  value: PlayerGradeValue;
};

/** Represents a player grade, taking into account the weighted grade types, and n-seasons worth of projections. */
export type PlayerGradeFilterInput = {
  and?: InputMaybe<Array<PlayerGradeFilterInput>>;
  /** average club gapypoc dollars */
  avgClubGapypocDollars?: InputMaybe<DecimalOperationFilterInput>;
  /** average sage gapypoc dollars */
  avgSageGapypocDollars?: InputMaybe<DecimalOperationFilterInput>;
  /** average sumer scout gapypoc dollars */
  avgSumerScoutGapypocDollars?: InputMaybe<DecimalOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerGradeFilterInput>>;
  /** the football player this grade corresponds to */
  player?: InputMaybe<PlayerFilterInput>;
  /** the marval player id */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** grades by season */
  seasons?: InputMaybe<ListFilterInputTypeOfPlayerGradeSeasonFilterInput>;
  /** overall weighted grade value */
  value?: InputMaybe<PlayerGradeValueFilterInput>;
};

export type PlayerGradeSeason = {
  __typename?: "PlayerGradeSeason";
  created: Scalars["DateTime"];
  gradeType?: Maybe<GradeWeightEnum>;
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  playerGrade: PlayerGrade;
  playerGradeId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  value: PlayerGradeValue;
};

export type PlayerGradeSeasonFilterInput = {
  and?: InputMaybe<Array<PlayerGradeSeasonFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  gradeType?: InputMaybe<NullableOfGradeWeightEnumOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerGradeSeasonFilterInput>>;
  playerGrade?: InputMaybe<PlayerGradeFilterInput>;
  playerGradeId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  value?: InputMaybe<PlayerGradeValueFilterInput>;
};

/** Represents a player grade, taking into account the weighted grade types, and n-seasons worth of projections. */
export type PlayerGradeSortInput = {
  /** average club gapypoc dollars */
  avgClubGapypocDollars?: InputMaybe<SortEnumType>;
  /** average sage gapypoc dollars */
  avgSageGapypocDollars?: InputMaybe<SortEnumType>;
  /** average sumer scout gapypoc dollars */
  avgSumerScoutGapypocDollars?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** the football player this grade corresponds to */
  player?: InputMaybe<PlayerSortInput>;
  /** the marval player id */
  playerId?: InputMaybe<SortEnumType>;
  /** overall weighted grade value */
  value?: InputMaybe<PlayerGradeValueSortInput>;
};

/** Represents a player grade in GAPYPOC and GAPYPOC dollars. */
export type PlayerGradeValue = {
  __typename?: "PlayerGradeValue";
  gapypoc: Scalars["Decimal"];
  gapypocDollars: Scalars["Decimal"];
  rawValue?: Maybe<Scalars["String"]>;
  rawValueOverride?: Maybe<Scalars["String"]>;
  /** the raw value to use (default to overridden value if exists) */
  value: Scalars["String"];
};

/** Represents a player grade in GAPYPOC and GAPYPOC dollars. */
export type PlayerGradeValueFilterInput = {
  and?: InputMaybe<Array<PlayerGradeValueFilterInput>>;
  gapypoc?: InputMaybe<DecimalOperationFilterInput>;
  gapypocDollars?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<PlayerGradeValueFilterInput>>;
  rawValue?: InputMaybe<StringOperationFilterInput>;
  rawValueOverride?: InputMaybe<StringOperationFilterInput>;
  /** the raw value to use (default to overridden value if exists) */
  value?: InputMaybe<StringOperationFilterInput>;
};

/** Represents a player grade in GAPYPOC and GAPYPOC dollars. */
export type PlayerGradeValueSortInput = {
  gapypoc?: InputMaybe<SortEnumType>;
  gapypocDollars?: InputMaybe<SortEnumType>;
  rawValue?: InputMaybe<SortEnumType>;
  rawValueOverride?: InputMaybe<SortEnumType>;
  /** the raw value to use (default to overridden value if exists) */
  value?: InputMaybe<SortEnumType>;
};

export type PlayerInjuryCollege = {
  __typename?: "PlayerInjuryCollege";
  created: Scalars["DateTime"];
  /** Date injury was reported by Rotowire */
  dateTime: Scalars["DateTime"];
  id: Scalars["ID"];
  /** Injury detail */
  injuryDetail?: Maybe<Scalars["String"]>;
  /** Injury side */
  injurySide?: Maybe<Scalars["String"]>;
  /** Injury status */
  injuryStatus?: Maybe<Scalars["String"]>;
  /** Injury type */
  injuryType?: Maybe<Scalars["String"]>;
  lastUpdated: Scalars["DateTime"];
  /** Player associated to injury */
  playerId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  school?: Maybe<School>;
  /** School the player was assocated with during injry */
  schoolId?: Maybe<Scalars["UUID"]>;
  /** Source i.e. League, Scout, etc. */
  source?: Maybe<Scalars["String"]>;
};

/** A connection to a list of items. */
export type PlayerInjuryCollegeConnection = {
  __typename?: "PlayerInjuryCollegeConnection";
  /** A list of edges. */
  edges?: Maybe<Array<PlayerInjuryCollegeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PlayerInjuryCollege>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

export type PlayerInjuryCollegeDetailsInput = {
  dateTime?: InputMaybe<Scalars["DateTime"]>;
  injuryDetail?: InputMaybe<Scalars["String"]>;
  injurySide?: InputMaybe<Scalars["String"]>;
  injuryStatus: Scalars["String"];
  injuryType?: InputMaybe<Scalars["String"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]>;
  playerId: Scalars["UUID"];
  schoolId: Scalars["UUID"];
  source?: InputMaybe<Scalars["String"]>;
};

/** An edge in a connection. */
export type PlayerInjuryCollegeEdge = {
  __typename?: "PlayerInjuryCollegeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: PlayerInjuryCollege;
};

export type PlayerInjuryCollegeFilterInput = {
  and?: InputMaybe<Array<PlayerInjuryCollegeFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Date injury was reported by Rotowire */
  dateTime?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Injury detail */
  injuryDetail?: InputMaybe<StringOperationFilterInput>;
  /** Injury side */
  injurySide?: InputMaybe<StringOperationFilterInput>;
  /** Injury status */
  injuryStatus?: InputMaybe<StringOperationFilterInput>;
  /** Injury type */
  injuryType?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerInjuryCollegeFilterInput>>;
  /** Player associated to injury */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  school?: InputMaybe<SchoolFilterInput>;
  /** School the player was assocated with during injry */
  schoolId?: InputMaybe<UuidOperationFilterInput>;
  /** Source i.e. League, Scout, etc. */
  source?: InputMaybe<StringOperationFilterInput>;
};

export type PlayerInjuryCollegeSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** Date injury was reported by Rotowire */
  dateTime?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Injury detail */
  injuryDetail?: InputMaybe<SortEnumType>;
  /** Injury side */
  injurySide?: InputMaybe<SortEnumType>;
  /** Injury status */
  injuryStatus?: InputMaybe<SortEnumType>;
  /** Injury type */
  injuryType?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Player associated to injury */
  playerId?: InputMaybe<SortEnumType>;
  school?: InputMaybe<SchoolSortInput>;
  /** School the player was assocated with during injry */
  schoolId?: InputMaybe<SortEnumType>;
  /** Source i.e. League, Scout, etc. */
  source?: InputMaybe<SortEnumType>;
};

export type PlayerInjuryPro = {
  __typename?: "PlayerInjuryPro";
  club?: Maybe<Club>;
  /** School the player was assocated with during injry */
  clubId?: Maybe<Scalars["UUID"]>;
  created: Scalars["DateTime"];
  /** Date for manually created injuries (not sync'd from league) */
  dateTime?: Maybe<Scalars["DateTime"]>;
  game?: Maybe<Game>;
  /** Game week injury is associated */
  gameId?: Maybe<Scalars["UUID"]>;
  id: Scalars["ID"];
  /** Injury body part */
  injury1?: Maybe<Scalars["String"]>;
  /** Injury body part if more than one */
  injury2?: Maybe<Scalars["String"]>;
  /** Injury body part with side */
  injuryDesc1?: Maybe<Scalars["String"]>;
  injuryDesc2?: Maybe<Scalars["String"]>;
  /** Injury status */
  injuryStatus: Scalars["String"];
  lastUpdated: Scalars["DateTime"];
  /** The league's injury id */
  leagueInjuryId?: Maybe<Scalars["Int"]>;
  /** Player associated to injury */
  playerId: Scalars["UUID"];
  /** Practice Day of game week */
  reportDay?: Maybe<Scalars["Int"]>;
  /** Injury context */
  reportType?: Maybe<Scalars["String"]>;
  rowVersion: Array<Scalars["Byte"]>;
  season?: Maybe<Scalars["Int"]>;
  /** Source i.e. League, Scout, etc. */
  source?: Maybe<Scalars["String"]>;
};

/** A connection to a list of items. */
export type PlayerInjuryProConnection = {
  __typename?: "PlayerInjuryProConnection";
  /** A list of edges. */
  edges?: Maybe<Array<PlayerInjuryProEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PlayerInjuryPro>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

export type PlayerInjuryProDetailsInput = {
  clubId?: InputMaybe<Scalars["UUID"]>;
  dateTime?: InputMaybe<Scalars["DateTime"]>;
  gameId?: InputMaybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  injury1: Scalars["String"];
  injury2?: InputMaybe<Scalars["String"]>;
  injuryDesc1: Scalars["String"];
  injuryDesc2?: InputMaybe<Scalars["String"]>;
  injuryStatus: Scalars["String"];
  leagueInjuryId?: InputMaybe<Scalars["Int"]>;
  playerId: Scalars["UUID"];
  reportDay?: InputMaybe<Scalars["Int"]>;
  reportType?: InputMaybe<Scalars["String"]>;
  season?: InputMaybe<Scalars["Int"]>;
  source?: InputMaybe<Scalars["String"]>;
};

/** An edge in a connection. */
export type PlayerInjuryProEdge = {
  __typename?: "PlayerInjuryProEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: PlayerInjuryPro;
};

export type PlayerInjuryProFilterInput = {
  and?: InputMaybe<Array<PlayerInjuryProFilterInput>>;
  club?: InputMaybe<ClubFilterInput>;
  /** School the player was assocated with during injry */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Date for manually created injuries (not sync'd from league) */
  dateTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Game week injury is associated */
  gameId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Injury body part */
  injury1?: InputMaybe<StringOperationFilterInput>;
  /** Injury body part if more than one */
  injury2?: InputMaybe<StringOperationFilterInput>;
  /** Injury body part with side */
  injuryDesc1?: InputMaybe<StringOperationFilterInput>;
  injuryDesc2?: InputMaybe<StringOperationFilterInput>;
  /** Injury status */
  injuryStatus?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** The league's injury id */
  leagueInjuryId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PlayerInjuryProFilterInput>>;
  /** Player associated to injury */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** Practice Day of game week */
  reportDay?: InputMaybe<IntOperationFilterInput>;
  /** Injury context */
  reportType?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  /** Source i.e. League, Scout, etc. */
  source?: InputMaybe<StringOperationFilterInput>;
};

export type PlayerInjuryProSortInput = {
  club?: InputMaybe<ClubSortInput>;
  /** School the player was assocated with during injry */
  clubId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** Date for manually created injuries (not sync'd from league) */
  dateTime?: InputMaybe<SortEnumType>;
  /** Game week injury is associated */
  gameId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Injury body part */
  injury1?: InputMaybe<SortEnumType>;
  /** Injury body part if more than one */
  injury2?: InputMaybe<SortEnumType>;
  /** Injury body part with side */
  injuryDesc1?: InputMaybe<SortEnumType>;
  injuryDesc2?: InputMaybe<SortEnumType>;
  /** Injury status */
  injuryStatus?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The league's injury id */
  leagueInjuryId?: InputMaybe<SortEnumType>;
  /** Player associated to injury */
  playerId?: InputMaybe<SortEnumType>;
  /** Practice Day of game week */
  reportDay?: InputMaybe<SortEnumType>;
  /** Injury context */
  reportType?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  /** Source i.e. League, Scout, etc. */
  source?: InputMaybe<SortEnumType>;
};

/** Player field override. */
export type PlayerOverride = {
  __typename?: "PlayerOverride";
  assignOverride: PlayerOverride;
  created: Scalars["DateTime"];
  /** Field on Player to override. Ex: BirthDate */
  field: Scalars["String"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  player: Player;
  playerId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  /**
   * Value of field on Player to override
   * Ex: 03/24/1997
   */
  value?: Maybe<Scalars["String"]>;
};

/** Player field override. */
export type PlayerOverrideAssignOverrideArgs = {
  details: PlayerOverrideDetailsInput;
};

export type PlayerOverrideDetailsInput = {
  field: Scalars["String"];
  playerId: Scalars["UUID"];
  value?: InputMaybe<Scalars["String"]>;
};

/** Player field override. */
export type PlayerOverrideFilterInput = {
  and?: InputMaybe<Array<PlayerOverrideFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Field on Player to override. Ex: BirthDate */
  field?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerOverrideFilterInput>>;
  player?: InputMaybe<PlayerFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /**
   * Value of field on Player to override
   * Ex: 03/24/1997
   */
  value?: InputMaybe<StringOperationFilterInput>;
};

/** Essentially a depth chart mapping for players in different scheme positions. */
export type PlayerPackagePosition = {
  __typename?: "PlayerPackagePosition";
  created: Scalars["DateTime"];
  /** The location of this player in this position on the team's depth chart */
  depth: Scalars["Decimal"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** One of the positions the player plays. */
  package: Package;
  /** The package the player position belongs */
  packageId?: Maybe<Scalars["UUID"]>;
  /** The player that is in some position */
  player: Player;
  /** Foreign key to Player */
  playerId: Scalars["UUID"];
  /** One of the positions the player plays. */
  position: Position;
  /** Foreign key to Position */
  positionId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
};

/** Essentially a depth chart mapping for players in different scheme positions. */
export type PlayerPackagePositionFilterInput = {
  and?: InputMaybe<Array<PlayerPackagePositionFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** The location of this player in this position on the team's depth chart */
  depth?: InputMaybe<DecimalOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerPackagePositionFilterInput>>;
  /** One of the positions the player plays. */
  package?: InputMaybe<PackageFilterInput>;
  /** The package the player position belongs */
  packageId?: InputMaybe<UuidOperationFilterInput>;
  /** The player that is in some position */
  player?: InputMaybe<PlayerFilterInput>;
  /** Foreign key to Player */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** One of the positions the player plays. */
  position?: InputMaybe<PositionFilterInput>;
  /** Foreign key to Position */
  positionId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

/** Essentially a depth chart mapping for players in different scheme positions. */
export type PlayerPackagePositionSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** The location of this player in this position on the team's depth chart */
  depth?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** One of the positions the player plays. */
  package?: InputMaybe<PackageSortInput>;
  /** The package the player position belongs */
  packageId?: InputMaybe<SortEnumType>;
  /** The player that is in some position */
  player?: InputMaybe<PlayerSortInput>;
  /** Foreign key to Player */
  playerId?: InputMaybe<SortEnumType>;
  /** One of the positions the player plays. */
  position?: InputMaybe<PositionSortInput>;
  /** Foreign key to Position */
  positionId?: InputMaybe<SortEnumType>;
};

export type PlayerPlay = {
  __typename?: "PlayerPlay";
  game: Game;
  gameId: Scalars["UUID"];
  lastModified: Scalars["DateTime"];
  leagueType: LeagueType;
  leagueTypeId: Scalars["Int"];
  pffGrade?: Maybe<Scalars["Float"]>;
  play: Play;
  playId: Scalars["UUID"];
  playerId: Scalars["UUID"];
  sideOfBall?: Maybe<Scalars["String"]>;
};

export type PlayerPlayFilterInput = {
  and?: InputMaybe<Array<PlayerPlayFilterInput>>;
  game?: InputMaybe<GameFilterInput>;
  gameId?: InputMaybe<UuidOperationFilterInput>;
  lastModified?: InputMaybe<DateTimeOperationFilterInput>;
  leagueType?: InputMaybe<LeagueTypeFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PlayerPlayFilterInput>>;
  pffGrade?: InputMaybe<FloatOperationFilterInput>;
  play?: InputMaybe<PlayFilterInput>;
  playId?: InputMaybe<UuidOperationFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  sideOfBall?: InputMaybe<StringOperationFilterInput>;
};

export type PlayerProjectedGamesMissed = {
  __typename?: "PlayerProjectedGamesMissed";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** Ability to override from application */
  override: PlayerProjectedGamesMissed;
  player: Player;
  playerId: Scalars["UUID"];
  /** Value from the ETL */
  projectedGamesMissed: Scalars["Decimal"];
  /** Value for override */
  projectedGamesMissedOverride?: Maybe<Scalars["Decimal"]>;
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  /** Update from ETL */
  update: PlayerProjectedGamesMissed;
  value: Scalars["Decimal"];
};

export type PlayerProjectedGamesMissedOverrideArgs = {
  overrideValue?: InputMaybe<Scalars["Decimal"]>;
};

export type PlayerProjectedGamesMissedUpdateArgs = {
  playerId: Scalars["UUID"];
  projectGamesMissed: Scalars["Decimal"];
  season: Scalars["Int"];
};

export type PlayerProjectedGamesMissedFilterInput = {
  and?: InputMaybe<Array<PlayerProjectedGamesMissedFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerProjectedGamesMissedFilterInput>>;
  player?: InputMaybe<PlayerFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** Value from the ETL */
  projectedGamesMissed?: InputMaybe<DecimalOperationFilterInput>;
  /** Value for override */
  projectedGamesMissedOverride?: InputMaybe<DecimalOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  value?: InputMaybe<DecimalOperationFilterInput>;
};

/** Encapsulates a flattened view of a player that can be searched against.  Annotated with Azure Search attributes. */
export type PlayerSearchDocument = {
  __typename?: "PlayerSearchDocument";
  /** All alerts this player has on evaluations */
  alertAbbreviations?: Maybe<Array<Scalars["String"]>>;
  /** Highest ProClubGrade or CollegeClubGrade from Grades */
  bestClubGrade?: Maybe<Scalars["Float"]>;
  /** Type of grade present in BestClubGrade, if any. ProClubGrade or CollegeClubGrade */
  bestClubGradeType?: Maybe<Scalars["String"]>;
  /** The code of the club the player belongs to, if any */
  clubCode?: Maybe<Scalars["String"]>;
  /** The ID of the club the player belongs to, if any */
  clubId?: Maybe<Scalars["String"]>;
  /** The full name of the club the player belongs to, if any */
  clubName?: Maybe<Scalars["String"]>;
  /** The player's club position's short name or Sumer position if club is missing */
  displayPositionShortName?: Maybe<Scalars["String"]>;
  /** The year the player was drafted */
  draftYear?: Maybe<Scalars["Int"]>;
  /**
   * College details - college class
   * One of Fr So Jr Sr
   */
  eligibility?: Maybe<Scalars["String"]>;
  /** College details - year eligible for the draft */
  eligibilityYear?: Maybe<Scalars["Int"]>;
  /** All ids of users who have written evaluations against this player */
  evaluationAuthorIds?: Maybe<Array<Scalars["String"]>>;
  /** Player's first name */
  firstName?: Maybe<Scalars["String"]>;
  /** A URL to the player's headshot image */
  headshotUrl?: Maybe<Scalars["String"]>;
  /** The latest height measurement of the player */
  height?: Maybe<Scalars["Int"]>;
  /** The town the player is from */
  hometown?: Maybe<Scalars["String"]>;
  /** The player's current jersey number */
  jersey?: Maybe<Scalars["String"]>;
  /** Player's last name */
  lastName?: Maybe<Scalars["String"]>;
  lastUpdated: Scalars["DateTime"];
  /** Player's internal ID */
  player: Player;
  /** Primary fit abbreviation */
  primarySchemeFitAbbreviation?: Maybe<Scalars["String"]>;
  /** The player's primary position name as defined by the club's scheme */
  primarySchemePositionShortName?: Maybe<Scalars["String"]>;
  /** College details - has redshirted */
  redshirt?: Maybe<Scalars["Boolean"]>;
  /** All scheme fit abbreviations that apply for this player */
  schemeFitAbbreviations?: Maybe<Array<Scalars["String"]>>;
  /** The code of the school the player belongs to or last attended */
  schoolCode?: Maybe<Scalars["String"]>;
  /** The ID of the school the player belongs to or last attended */
  schoolId?: Maybe<Scalars["String"]>;
  /** The full name of the school the player belongs to or last attended */
  schoolName?: Maybe<Scalars["String"]>;
  /** The nickname (mascot) of the school the player belongs to or last attended */
  schoolNickname?: Maybe<Scalars["String"]>;
  /** The latest speed measurement of the player */
  speed?: Maybe<Scalars["Float"]>;
  /** Player's current status within the league */
  status?: Maybe<Scalars["String"]>;
  /** The player's sumer atlas position's short name */
  sumerAtlasPositionShortName?: Maybe<Scalars["String"]>;
  /** The player's sumer general position's short name */
  sumerGeneralPositionShortName?: Maybe<Scalars["String"]>;
  /** The latest weight measurement of the player */
  weight?: Maybe<Scalars["Int"]>;
};

/** Encapsulates a flattened view of a player that can be searched against.  Annotated with Azure Search attributes. */
export type PlayerSearchDocumentFilterInput = {
  /** All alerts this player has on evaluations */
  alertAbbreviations?: InputMaybe<ListStringOperationFilterInput>;
  and?: InputMaybe<Array<PlayerSearchDocumentFilterInput>>;
  /** Highest ProClubGrade or CollegeClubGrade from Grades */
  bestClubGrade?: InputMaybe<FloatOperationFilterInput>;
  /** Type of grade present in BestClubGrade, if any. ProClubGrade or CollegeClubGrade */
  bestClubGradeType?: InputMaybe<StringOperationFilterInput>;
  /** The code of the club the player belongs to, if any */
  clubCode?: InputMaybe<StringOperationFilterInput>;
  /** The ID of the club the player belongs to, if any */
  clubId?: InputMaybe<StringOperationFilterInput>;
  /** The full name of the club the player belongs to, if any */
  clubName?: InputMaybe<StringOperationFilterInput>;
  /** The player's club position's short name or Sumer position if club is missing */
  displayPositionShortName?: InputMaybe<StringOperationFilterInput>;
  /** The year the player was drafted */
  draftYear?: InputMaybe<IntOperationFilterInput>;
  /**
   * College details - college class
   * One of Fr So Jr Sr
   */
  eligibility?: InputMaybe<StringOperationFilterInput>;
  /** College details - year eligible for the draft */
  eligibilityYear?: InputMaybe<IntOperationFilterInput>;
  /** All ids of users who have written evaluations against this player */
  evaluationAuthorIds?: InputMaybe<ListStringOperationFilterInput>;
  /** Player's first name */
  firstName?: InputMaybe<StringOperationFilterInput>;
  /** A URL to the player's headshot image */
  headshotUrl?: InputMaybe<StringOperationFilterInput>;
  /** The latest height measurement of the player */
  height?: InputMaybe<IntOperationFilterInput>;
  /** The town the player is from */
  hometown?: InputMaybe<StringOperationFilterInput>;
  /** Player's internal ID */
  id?: InputMaybe<StringOperationFilterInput>;
  /** The player's current jersey number */
  jersey?: InputMaybe<StringOperationFilterInput>;
  /** Player's last name */
  lastName?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerSearchDocumentFilterInput>>;
  /** Primary fit abbreviation */
  primarySchemeFitAbbreviation?: InputMaybe<StringOperationFilterInput>;
  /** The player's primary position name as defined by the club's scheme */
  primarySchemePositionShortName?: InputMaybe<StringOperationFilterInput>;
  /** College details - has redshirted */
  redshirt?: InputMaybe<BooleanOperationFilterInput>;
  /** All scheme fit abbreviations that apply for this player */
  schemeFitAbbreviations?: InputMaybe<ListStringOperationFilterInput>;
  /** The code of the school the player belongs to or last attended */
  schoolCode?: InputMaybe<StringOperationFilterInput>;
  /** The ID of the school the player belongs to or last attended */
  schoolId?: InputMaybe<StringOperationFilterInput>;
  /** The full name of the school the player belongs to or last attended */
  schoolName?: InputMaybe<StringOperationFilterInput>;
  /** The nickname (mascot) of the school the player belongs to or last attended */
  schoolNickname?: InputMaybe<StringOperationFilterInput>;
  /** The latest speed measurement of the player */
  speed?: InputMaybe<FloatOperationFilterInput>;
  /** Player's current status within the league */
  status?: InputMaybe<StringOperationFilterInput>;
  /** The player's sumer atlas position's short name */
  sumerAtlasPositionShortName?: InputMaybe<StringOperationFilterInput>;
  /** The player's sumer general position's short name */
  sumerGeneralPositionShortName?: InputMaybe<StringOperationFilterInput>;
  /** The latest weight measurement of the player */
  weight?: InputMaybe<IntOperationFilterInput>;
};

/** Encapsulates a flattened view of a player that can be searched against.  Annotated with Azure Search attributes. */
export type PlayerSearchDocumentSortInput = {
  /** Highest ProClubGrade or CollegeClubGrade from Grades */
  bestClubGrade?: InputMaybe<SortEnumType>;
  /** Type of grade present in BestClubGrade, if any. ProClubGrade or CollegeClubGrade */
  bestClubGradeType?: InputMaybe<SortEnumType>;
  /** The code of the club the player belongs to, if any */
  clubCode?: InputMaybe<SortEnumType>;
  /** The ID of the club the player belongs to, if any */
  clubId?: InputMaybe<SortEnumType>;
  /** The full name of the club the player belongs to, if any */
  clubName?: InputMaybe<SortEnumType>;
  /** The player's club position's short name or Sumer position if club is missing */
  displayPositionShortName?: InputMaybe<SortEnumType>;
  /** The year the player was drafted */
  draftYear?: InputMaybe<SortEnumType>;
  /**
   * College details - college class
   * One of Fr So Jr Sr
   */
  eligibility?: InputMaybe<SortEnumType>;
  /** College details - year eligible for the draft */
  eligibilityYear?: InputMaybe<SortEnumType>;
  /** Player's first name */
  firstName?: InputMaybe<SortEnumType>;
  /** A URL to the player's headshot image */
  headshotUrl?: InputMaybe<SortEnumType>;
  /** The latest height measurement of the player */
  height?: InputMaybe<SortEnumType>;
  /** The town the player is from */
  hometown?: InputMaybe<SortEnumType>;
  /** Player's internal ID */
  id?: InputMaybe<SortEnumType>;
  /** The player's current jersey number */
  jersey?: InputMaybe<SortEnumType>;
  /** Player's last name */
  lastName?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Primary fit abbreviation */
  primarySchemeFitAbbreviation?: InputMaybe<SortEnumType>;
  /** The player's primary position name as defined by the club's scheme */
  primarySchemePositionShortName?: InputMaybe<SortEnumType>;
  /** College details - has redshirted */
  redshirt?: InputMaybe<SortEnumType>;
  /** The code of the school the player belongs to or last attended */
  schoolCode?: InputMaybe<SortEnumType>;
  /** The ID of the school the player belongs to or last attended */
  schoolId?: InputMaybe<SortEnumType>;
  /** The full name of the school the player belongs to or last attended */
  schoolName?: InputMaybe<SortEnumType>;
  /** The nickname (mascot) of the school the player belongs to or last attended */
  schoolNickname?: InputMaybe<SortEnumType>;
  /** The latest speed measurement of the player */
  speed?: InputMaybe<SortEnumType>;
  /** Player's current status within the league */
  status?: InputMaybe<SortEnumType>;
  /** The player's sumer atlas position's short name */
  sumerAtlasPositionShortName?: InputMaybe<SortEnumType>;
  /** The player's sumer general position's short name */
  sumerGeneralPositionShortName?: InputMaybe<SortEnumType>;
  /** The latest weight measurement of the player */
  weight?: InputMaybe<SortEnumType>;
};

/** Encapsulates a result set of players from the search appliance. */
export type PlayerSearchResults = {
  __typename?: "PlayerSearchResults";
  /** The collection of matched players, ordered by relevance. */
  players: Array<SearchResultOfPlayerSearchDocument>;
  /** The total number of players that would match the search query (excluding paging parameters) */
  total: Scalars["Long"];
};

/** Encapsulates a result set of players from the search appliance. */
export type PlayerSearchResultsFilterInput = {
  and?: InputMaybe<Array<PlayerSearchResultsFilterInput>>;
  or?: InputMaybe<Array<PlayerSearchResultsFilterInput>>;
  /** The collection of matched players, ordered by relevance. */
  players?: InputMaybe<ListFilterInputTypeOfSearchResultOfPlayerSearchDocumentFilterInput>;
  /** The total number of players that would match the search query (excluding paging parameters) */
  total?: InputMaybe<LongOperationFilterInput>;
};

/** Encapsulates a result set of players from the search appliance. */
export type PlayerSearchResultsSortInput = {
  /** The total number of players that would match the search query (excluding paging parameters) */
  total?: InputMaybe<SortEnumType>;
};

export enum PlayerSearchSortCriteriaEnum {
  ALPHA = "ALPHA",
  CLUB_ALPHA = "CLUB_ALPHA",
  CLUB_POSITION = "CLUB_POSITION",
  SCHOOL_ALPHA = "SCHOOL_ALPHA",
  SCHOOL_POSITION = "SCHOOL_POSITION",
  SUMER_ATLAS_POSITION = "SUMER_ATLAS_POSITION",
  SUMER_GENERAL_POSITION = "SUMER_GENERAL_POSITION",
}

/** A football player. */
export type PlayerSortInput = {
  /**
   * Accrued seasons in an NFL contract refer to the number of seasons a player has been on an active
   * roster or injured reserve for at least six games. These accrued seasons impact a player's eligibility
   * for various benefits, such as unrestricted free agency, pension plans, and the ability to qualify for
   * certain salary escalators or contract guarantees.
   */
  accruedSeasons?: InputMaybe<SortEnumType>;
  agent?: InputMaybe<AgentSortInput>;
  /** Player's agent */
  agentId?: InputMaybe<SortEnumType>;
  /** The day the player was born */
  birthDate?: InputMaybe<SortEnumType>;
  /** The team the player currently plays for */
  club?: InputMaybe<ClubSortInput>;
  /**
   * The position (if any) that the club has graded the player at.  Expectation is that the application
   * will only ingest one graded position for a given player.
   */
  clubGradingPosition?: InputMaybe<PositionSortInput>;
  /** FK ID of the club graded position */
  clubGradingPositionId?: InputMaybe<SortEnumType>;
  /** Foreign key to Club */
  clubId?: InputMaybe<SortEnumType>;
  /** The player's league replication ID */
  collegeGsisId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  draftClub?: InputMaybe<ClubSortInput>;
  /** The club that drafted the player (if drafted) */
  draftClubId?: InputMaybe<SortEnumType>;
  draftRound?: InputMaybe<SortEnumType>;
  draftSelection?: InputMaybe<SortEnumType>;
  /** The year the player was drafted into the NFL */
  draftYear?: InputMaybe<SortEnumType>;
  /** College details - college class */
  eligibility?: InputMaybe<SortEnumType>;
  /** College details - year eligible for the draft */
  eligibilityYear?: InputMaybe<SortEnumType>;
  favorite?: InputMaybe<PlayerFavoriteSortInput>;
  /** The player's first name */
  firstName?: InputMaybe<SortEnumType>;
  freeAgencyStatus?: InputMaybe<SortEnumType>;
  /** The player's league replication ID */
  gsisId?: InputMaybe<SortEnumType>;
  /** A URL to the player's headshot image */
  headshotUrl?: InputMaybe<SortEnumType>;
  /** The player's latest height measurement in inches according to pff/league */
  height?: InputMaybe<SortEnumType>;
  highschool?: InputMaybe<SortEnumType>;
  /** Where the player is from */
  hometownCity?: InputMaybe<SortEnumType>;
  hometownCountry?: InputMaybe<SortEnumType>;
  hometownState?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** The player's current jersey number */
  jersey?: InputMaybe<SortEnumType>;
  /** The player's last name */
  lastName?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  latestBirthDate?: InputMaybe<SortEnumType>;
  latestClubId?: InputMaybe<SortEnumType>;
  latestEligibility?: InputMaybe<SortEnumType>;
  latestEligibilityYear?: InputMaybe<SortEnumType>;
  latestSchoolId?: InputMaybe<SortEnumType>;
  /** The player's College league id (CollegePlayerId - nonprodb.college_player_extract) */
  leagueCollegeId?: InputMaybe<SortEnumType>;
  /** The player's middle name */
  middleName?: InputMaybe<SortEnumType>;
  /**
   * Minimum salary credits refer to the financial benefit given to a team when they sign a player with a
   * higher salary than the league's minimum. These credits offset a portion of the player's salary cap hit,
   * allowing the team to allocate more funds to other player contracts while still meeting the league's salary floor.
   */
  minSalaryCredits?: InputMaybe<SortEnumType>;
  /**
   * The most recent team a player played for
   * Solves issue when a club drops from a roster for reporting
   */
  mostRecentClub?: InputMaybe<ClubSortInput>;
  /** Foreign key to Club */
  mostRecentClubId?: InputMaybe<SortEnumType>;
  namePronunciation?: InputMaybe<SortEnumType>;
  /** The number of years of professional playing experience the player has */
  nflExperience?: InputMaybe<SortEnumType>;
  /** If player is on a club roster */
  onClubRoster?: InputMaybe<SortEnumType>;
  /** If player is on a current roster */
  onCollegeRoster?: InputMaybe<SortEnumType>;
  /** the player's overall and season grades */
  playerGrade?: InputMaybe<PlayerGradeSortInput>;
  /** College details - has redshirted */
  redshirt?: InputMaybe<SortEnumType>;
  /** The school the player is currently attending (college) or last college of pro player */
  school?: InputMaybe<SchoolSortInput>;
  /** Foreign key to School */
  schoolId?: InputMaybe<SortEnumType>;
  /** The player's latest speed according to pff */
  speed?: InputMaybe<SortEnumType>;
  /** The player's current status */
  status?: InputMaybe<SortEnumType>;
  /** The player's last name suffix */
  suffix?: InputMaybe<SortEnumType>;
  /**
   * The position (if any) of the player in the Sumer atlas position set.  This is a
   * subset of the SumerGeneralPosition that is significantly more granular.
   */
  sumerAtlasPosition?: InputMaybe<PositionSortInput>;
  /** FK ID of the sumer atlas position */
  sumerAtlasPositionId?: InputMaybe<SortEnumType>;
  /** The position (if any) of the player in the Sumer general position set. */
  sumerGeneralPosition?: InputMaybe<PositionSortInput>;
  /** FK ID of the sumer general position */
  sumerGeneralPositionId?: InputMaybe<SortEnumType>;
  /** The player's latest weight measurement in pounds according to pff/league */
  weight?: InputMaybe<SortEnumType>;
};

export type PlayerStat = {
  __typename?: "PlayerStat";
  careerStats: Array<CareerStat>;
  defenseStatsBattedPasses?: Maybe<Scalars["Int"]>;
  defenseStatsBurns?: Maybe<Scalars["Int"]>;
  defenseStatsCoverageAttempts?: Maybe<Scalars["Int"]>;
  defenseStatsDefensiveTargets?: Maybe<Scalars["Int"]>;
  defenseStatsDroppedInterceptions?: Maybe<Scalars["Int"]>;
  defenseStatsExtraPointsBlocked?: Maybe<Scalars["Int"]>;
  defenseStatsFieldGoalsBlocked?: Maybe<Scalars["Int"]>;
  defenseStatsFumblesForced?: Maybe<Scalars["Int"]>;
  defenseStatsFumblesRecovered?: Maybe<Scalars["Int"]>;
  defenseStatsFumblesRecoveredForTouchdown?: Maybe<Scalars["Int"]>;
  defenseStatsInjuriesInGame?: Maybe<Scalars["Int"]>;
  defenseStatsInterceptionLongestReturn?: Maybe<Scalars["Int"]>;
  defenseStatsInterceptionPercentage?: Maybe<Scalars["Decimal"]>;
  defenseStatsInterceptionTouchdowns?: Maybe<Scalars["Int"]>;
  defenseStatsInterceptionYards?: Maybe<Scalars["Int"]>;
  defenseStatsInterceptions?: Maybe<Scalars["Int"]>;
  defenseStatsMissedTackles?: Maybe<Scalars["Int"]>;
  defenseStatsMissedTacklesOnQb?: Maybe<Scalars["Int"]>;
  defenseStatsPassBreakups?: Maybe<Scalars["Int"]>;
  defenseStatsPassRushAttempts?: Maybe<Scalars["Int"]>;
  defenseStatsPuntsBlocked?: Maybe<Scalars["Int"]>;
  defenseStatsQbHitPercentage?: Maybe<Scalars["Decimal"]>;
  defenseStatsQbHits?: Maybe<Scalars["Int"]>;
  defenseStatsQbHurries?: Maybe<Scalars["Int"]>;
  defenseStatsQbHurryPercentage?: Maybe<Scalars["Decimal"]>;
  defenseStatsQbPressurePercentage?: Maybe<Scalars["Decimal"]>;
  defenseStatsQbPressures?: Maybe<Scalars["Int"]>;
  defenseStatsSackPercentage?: Maybe<Scalars["Decimal"]>;
  defenseStatsSackPlays?: Maybe<Scalars["Int"]>;
  defenseStatsSacks?: Maybe<Scalars["Decimal"]>;
  defenseStatsSnapPercentage?: Maybe<Scalars["Decimal"]>;
  defenseStatsSnaps?: Maybe<Scalars["Int"]>;
  defenseStatsStops?: Maybe<Scalars["Int"]>;
  defenseStatsTacklesAssisted?: Maybe<Scalars["Int"]>;
  defenseStatsTacklesCombined?: Maybe<Scalars["Int"]>;
  defenseStatsTacklesForLoss?: Maybe<Scalars["Decimal"]>;
  defenseStatsTacklesSolo?: Maybe<Scalars["Int"]>;
  defenseStatsTeamSnaps?: Maybe<Scalars["Int"]>;
  defenseStatsTouchdowns?: Maybe<Scalars["Int"]>;
  gameId: Scalars["UUID"];
  gameStats: Array<GameStat>;
  kickingStatsBlockedFieldGoalsAndExtraPoints?: Maybe<Scalars["Int"]>;
  kickingStatsExtraPointAttempts?: Maybe<Scalars["Int"]>;
  kickingStatsExtraPointsBlocked?: Maybe<Scalars["Int"]>;
  kickingStatsExtraPointsMade?: Maybe<Scalars["Int"]>;
  kickingStatsExtraPointsPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoalAttempts?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoalMadeLongest?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoalPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoalSnapPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoalSnaps?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoalTeamSnaps?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoalYardsAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoals1To19Attempted?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals1To19Made?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals1To19Percentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoals20To29Attempted?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals20To29Made?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals20To29Percentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoals30To39Attempted?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals30To39Made?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals30To39Percentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoals40To49Attempted?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals40To49Made?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals40To49Percentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoals50To59Attempted?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals50To59Made?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals50To59Percentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoals60PlusAttempted?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals60PlusMade?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals60PlusPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoalsBlocked?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoalsMade?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffAttempts?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffDepthAttempts?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffDepthAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsKickoffOnsideKicks?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffOnsideKicksRecovered?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffReturnLong?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffReturnTouchdowns?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffReturnYards?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffReturnYardsAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsKickoffSnapPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsKickoffSnaps?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffTeamSnaps?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffTouchbackPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsKickoffTouchbacks?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffYards?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffYardsAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsKickoffsOutOfBounds?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffsReturned?: Maybe<Scalars["Int"]>;
  kickingStatsPuntAttempts?: Maybe<Scalars["Int"]>;
  kickingStatsPuntHangTimeAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsPuntOperationTimeAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsPuntReturnTouchdowns?: Maybe<Scalars["Int"]>;
  kickingStatsPuntReturnYards?: Maybe<Scalars["Int"]>;
  kickingStatsPuntReturnYardsAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsPuntSnapPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsPuntSnaps?: Maybe<Scalars["Int"]>;
  kickingStatsPuntTeamSnaps?: Maybe<Scalars["Int"]>;
  kickingStatsPuntYards?: Maybe<Scalars["Int"]>;
  kickingStatsPuntYardsAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsPuntYardsLongest?: Maybe<Scalars["Int"]>;
  kickingStatsPuntYardsNet?: Maybe<Scalars["Int"]>;
  kickingStatsPuntYardsNetAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsPuntsBlocked?: Maybe<Scalars["Int"]>;
  kickingStatsPuntsDowned?: Maybe<Scalars["Int"]>;
  kickingStatsPuntsFairCaught?: Maybe<Scalars["Int"]>;
  kickingStatsPuntsIn20?: Maybe<Scalars["Int"]>;
  kickingStatsPuntsOutOfBounds?: Maybe<Scalars["Int"]>;
  kickingStatsPuntsReturned?: Maybe<Scalars["Int"]>;
  kickingStatsPuntsTouchbacks?: Maybe<Scalars["Int"]>;
  lastUpdated: Scalars["DateTime"];
  leagueTypeId: Scalars["Int"];
  passingStatsAttempts?: Maybe<Scalars["Int"]>;
  passingStatsCatchableThrowPercentage?: Maybe<Scalars["Decimal"]>;
  passingStatsCatchableThrows?: Maybe<Scalars["Int"]>;
  passingStatsCompletionPercentage?: Maybe<Scalars["Decimal"]>;
  passingStatsCompletions?: Maybe<Scalars["Int"]>;
  passingStatsCompletionsFortyYardsPlus?: Maybe<Scalars["Int"]>;
  passingStatsCompletionsTwentyYardsPlus?: Maybe<Scalars["Int"]>;
  passingStatsDropbackAttempts?: Maybe<Scalars["Int"]>;
  passingStatsDropbackDepthAverage?: Maybe<Scalars["Decimal"]>;
  passingStatsDrops?: Maybe<Scalars["Int"]>;
  passingStatsFirstDownPercentage?: Maybe<Scalars["Decimal"]>;
  passingStatsFirstDowns?: Maybe<Scalars["Int"]>;
  passingStatsFumbles?: Maybe<Scalars["Int"]>;
  passingStatsFumblesRecovered?: Maybe<Scalars["Int"]>;
  passingStatsHurriesAllowed?: Maybe<Scalars["Int"]>;
  passingStatsInjuriesInGame?: Maybe<Scalars["Int"]>;
  passingStatsInterceptionPercentage?: Maybe<Scalars["Decimal"]>;
  passingStatsInterceptions?: Maybe<Scalars["Int"]>;
  passingStatsLongestCompletion?: Maybe<Scalars["Int"]>;
  passingStatsPassDepthAttempts?: Maybe<Scalars["Int"]>;
  passingStatsPassDepthAverage?: Maybe<Scalars["Decimal"]>;
  passingStatsPasserRating?: Maybe<Scalars["Decimal"]>;
  passingStatsPassingEfficiency?: Maybe<Scalars["Decimal"]>;
  passingStatsPenalties?: Maybe<Scalars["Int"]>;
  passingStatsPumpFakes?: Maybe<Scalars["Int"]>;
  passingStatsSackYards?: Maybe<Scalars["Int"]>;
  passingStatsSacks?: Maybe<Scalars["Int"]>;
  passingStatsSnapPercentage?: Maybe<Scalars["Decimal"]>;
  passingStatsSnaps?: Maybe<Scalars["Int"]>;
  passingStatsTeamSnaps?: Maybe<Scalars["Int"]>;
  passingStatsTimeToPressureAttempts?: Maybe<Scalars["Int"]>;
  passingStatsTimeToPressureAverage?: Maybe<Scalars["Decimal"]>;
  passingStatsTimeToThrowAttempts?: Maybe<Scalars["Int"]>;
  passingStatsTimeToThrowAverage?: Maybe<Scalars["Decimal"]>;
  passingStatsTouchdownInterceptionRatio?: Maybe<Scalars["Decimal"]>;
  passingStatsTouchdownPercentage?: Maybe<Scalars["Decimal"]>;
  passingStatsTouchdowns?: Maybe<Scalars["Int"]>;
  passingStatsTwoPointAttempts?: Maybe<Scalars["Int"]>;
  passingStatsTwoPointConversions?: Maybe<Scalars["Int"]>;
  passingStatsYards?: Maybe<Scalars["Int"]>;
  passingStatsYardsAfterCatch?: Maybe<Scalars["Int"]>;
  passingStatsYardsPerAttempt?: Maybe<Scalars["Decimal"]>;
  passingStatsYardsPerCompletionAverage?: Maybe<Scalars["Decimal"]>;
  playerId: Scalars["UUID"];
  receivingStatsCatchableThrows?: Maybe<Scalars["Int"]>;
  receivingStatsDrops?: Maybe<Scalars["Int"]>;
  receivingStatsDropsPercentage?: Maybe<Scalars["Decimal"]>;
  receivingStatsFirstDownPercentage?: Maybe<Scalars["Decimal"]>;
  receivingStatsFirstDowns?: Maybe<Scalars["Int"]>;
  receivingStatsInjuriesInGame?: Maybe<Scalars["Int"]>;
  receivingStatsLongestReception?: Maybe<Scalars["Int"]>;
  receivingStatsPassRouteDepthAverage?: Maybe<Scalars["Decimal"]>;
  receivingStatsPassRoutesRun?: Maybe<Scalars["Int"]>;
  receivingStatsPenalties?: Maybe<Scalars["Int"]>;
  receivingStatsReceivingFumbles?: Maybe<Scalars["Int"]>;
  receivingStatsReceptions?: Maybe<Scalars["Int"]>;
  receivingStatsReceptionsFortyYardsPlus?: Maybe<Scalars["Int"]>;
  receivingStatsReceptionsPerTouchdown?: Maybe<Scalars["Decimal"]>;
  receivingStatsReceptionsTwentyYardsPlus?: Maybe<Scalars["Int"]>;
  receivingStatsSnapPercentage?: Maybe<Scalars["Decimal"]>;
  receivingStatsSnaps?: Maybe<Scalars["Int"]>;
  receivingStatsTacklesAvoided?: Maybe<Scalars["Int"]>;
  receivingStatsTargets?: Maybe<Scalars["Int"]>;
  receivingStatsTeamSnaps?: Maybe<Scalars["Int"]>;
  receivingStatsTouchdowns?: Maybe<Scalars["Int"]>;
  receivingStatsTwoPointAttempts?: Maybe<Scalars["Int"]>;
  receivingStatsTwoPointConversions?: Maybe<Scalars["Int"]>;
  receivingStatsYardAfterCatchPerReception?: Maybe<Scalars["Decimal"]>;
  receivingStatsYards?: Maybe<Scalars["Int"]>;
  receivingStatsYardsAfterCatch?: Maybe<Scalars["Int"]>;
  receivingStatsYardsAfterContact?: Maybe<Scalars["Int"]>;
  receivingStatsYardsPerReception?: Maybe<Scalars["Decimal"]>;
  returnStatsKickReturnAttempts?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnFairCatches?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnFumbles?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnLongestYards?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnSnapPercentage?: Maybe<Scalars["Decimal"]>;
  returnStatsKickReturnSnaps?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnTeamSnaps?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnTouchdowns?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnYards?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnYardsAfterContact?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnYardsAfterContactAverage?: Maybe<Scalars["Decimal"]>;
  returnStatsKickReturnYardsAverage?: Maybe<Scalars["Decimal"]>;
  returnStatsKickReturnsFortyYardsPlus?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnsTwentyYardsPlus?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnAttempts?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnFairCatches?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnFumbles?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnLongestYards?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnSnapPercentage?: Maybe<Scalars["Decimal"]>;
  returnStatsPuntReturnSnaps?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnTeamSnaps?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnTouchdowns?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnYards?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnYardsAfterContact?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnYardsAfterContactAverage?: Maybe<Scalars["Decimal"]>;
  returnStatsPuntReturnYardsAverage?: Maybe<Scalars["Decimal"]>;
  returnStatsPuntReturnsFortyYardsPlus?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnsTwentyYardsPlus?: Maybe<Scalars["Int"]>;
  rowVersion: Array<Scalars["Byte"]>;
  rushingStatsAttempts?: Maybe<Scalars["Int"]>;
  rushingStatsFirstDownPercentage?: Maybe<Scalars["Decimal"]>;
  rushingStatsFirstDownRushes?: Maybe<Scalars["Int"]>;
  rushingStatsFumbles?: Maybe<Scalars["Int"]>;
  rushingStatsFumblesLost?: Maybe<Scalars["Int"]>;
  rushingStatsFumblesRecovered?: Maybe<Scalars["Int"]>;
  rushingStatsHurriesAllowed?: Maybe<Scalars["Int"]>;
  rushingStatsInjuriesInGame?: Maybe<Scalars["Int"]>;
  rushingStatsLongestRun?: Maybe<Scalars["Int"]>;
  rushingStatsPenalties?: Maybe<Scalars["Int"]>;
  rushingStatsPressuresAllowed?: Maybe<Scalars["Int"]>;
  rushingStatsRunsFortyYardsPlus?: Maybe<Scalars["Int"]>;
  rushingStatsRunsTwentyYardsPlus?: Maybe<Scalars["Int"]>;
  rushingStatsSacksAllowed?: Maybe<Scalars["Int"]>;
  rushingStatsSnapPercentage?: Maybe<Scalars["Decimal"]>;
  rushingStatsSnaps?: Maybe<Scalars["Int"]>;
  rushingStatsTacklesAvoided?: Maybe<Scalars["Int"]>;
  rushingStatsTeamSnaps?: Maybe<Scalars["Int"]>;
  rushingStatsTouchdowns?: Maybe<Scalars["Int"]>;
  rushingStatsTwoPointAttempts?: Maybe<Scalars["Int"]>;
  rushingStatsTwoPointConversions?: Maybe<Scalars["Int"]>;
  rushingStatsYards?: Maybe<Scalars["Int"]>;
  rushingStatsYardsAfterContact?: Maybe<Scalars["Int"]>;
  rushingStatsYardsAfterContactPerRush?: Maybe<Scalars["Decimal"]>;
  rushingStatsYardsPerRush?: Maybe<Scalars["Decimal"]>;
  season: Scalars["Int"];
  seasonStats: Array<SeasonStat>;
  seasonTypeId: Scalars["Int"];
  statTypeId: Scalars["Int"];
};

export type PlayerStatFilterInput = {
  and?: InputMaybe<Array<PlayerStatFilterInput>>;
  careerStats?: InputMaybe<ListFilterInputTypeOfCareerStatFilterInput>;
  defenseStatsBattedPasses?: InputMaybe<IntOperationFilterInput>;
  defenseStatsBurns?: InputMaybe<IntOperationFilterInput>;
  defenseStatsCoverageAttempts?: InputMaybe<IntOperationFilterInput>;
  defenseStatsDefensiveTargets?: InputMaybe<IntOperationFilterInput>;
  defenseStatsDroppedInterceptions?: InputMaybe<IntOperationFilterInput>;
  defenseStatsExtraPointsBlocked?: InputMaybe<IntOperationFilterInput>;
  defenseStatsFieldGoalsBlocked?: InputMaybe<IntOperationFilterInput>;
  defenseStatsFumblesForced?: InputMaybe<IntOperationFilterInput>;
  defenseStatsFumblesRecovered?: InputMaybe<IntOperationFilterInput>;
  defenseStatsFumblesRecoveredForTouchdown?: InputMaybe<IntOperationFilterInput>;
  defenseStatsInjuriesInGame?: InputMaybe<IntOperationFilterInput>;
  defenseStatsInterceptionLongestReturn?: InputMaybe<IntOperationFilterInput>;
  defenseStatsInterceptionPercentage?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsInterceptionTouchdowns?: InputMaybe<IntOperationFilterInput>;
  defenseStatsInterceptionYards?: InputMaybe<IntOperationFilterInput>;
  defenseStatsInterceptions?: InputMaybe<IntOperationFilterInput>;
  defenseStatsMissedTackles?: InputMaybe<IntOperationFilterInput>;
  defenseStatsMissedTacklesOnQb?: InputMaybe<IntOperationFilterInput>;
  defenseStatsPassBreakups?: InputMaybe<IntOperationFilterInput>;
  defenseStatsPassRushAttempts?: InputMaybe<IntOperationFilterInput>;
  defenseStatsPuntsBlocked?: InputMaybe<IntOperationFilterInput>;
  defenseStatsQbHitPercentage?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsQbHits?: InputMaybe<IntOperationFilterInput>;
  defenseStatsQbHurries?: InputMaybe<IntOperationFilterInput>;
  defenseStatsQbHurryPercentage?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsQbPressurePercentage?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsQbPressures?: InputMaybe<IntOperationFilterInput>;
  defenseStatsSackPercentage?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsSackPlays?: InputMaybe<IntOperationFilterInput>;
  defenseStatsSacks?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsSnaps?: InputMaybe<IntOperationFilterInput>;
  defenseStatsStops?: InputMaybe<IntOperationFilterInput>;
  defenseStatsTacklesAssisted?: InputMaybe<IntOperationFilterInput>;
  defenseStatsTacklesCombined?: InputMaybe<IntOperationFilterInput>;
  defenseStatsTacklesForLoss?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsTacklesSolo?: InputMaybe<IntOperationFilterInput>;
  defenseStatsTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  defenseStatsTouchdowns?: InputMaybe<IntOperationFilterInput>;
  gameId?: InputMaybe<UuidOperationFilterInput>;
  gameStats?: InputMaybe<ListFilterInputTypeOfGameStatFilterInput>;
  kickingStatsBlockedFieldGoalsAndExtraPoints?: InputMaybe<IntOperationFilterInput>;
  kickingStatsExtraPointAttempts?: InputMaybe<IntOperationFilterInput>;
  kickingStatsExtraPointsBlocked?: InputMaybe<IntOperationFilterInput>;
  kickingStatsExtraPointsMade?: InputMaybe<IntOperationFilterInput>;
  kickingStatsExtraPointsPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoalAttempts?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoalMadeLongest?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoalPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoalSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoalSnaps?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoalTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoalYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoals1To19Attempted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals1To19Made?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals1To19Percentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoals20To29Attempted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals20To29Made?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals20To29Percentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoals30To39Attempted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals30To39Made?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals30To39Percentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoals40To49Attempted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals40To49Made?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals40To49Percentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoals50To59Attempted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals50To59Made?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals50To59Percentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoals60PlusAttempted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals60PlusMade?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals60PlusPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoalsBlocked?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoalsMade?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffAttempts?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffDepthAttempts?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffDepthAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsKickoffOnsideKicks?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffOnsideKicksRecovered?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffReturnLong?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffReturnTouchdowns?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffReturnYards?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffReturnYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsKickoffSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsKickoffSnaps?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffTouchbackPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsKickoffTouchbacks?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffYards?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsKickoffsOutOfBounds?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffsReturned?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntAttempts?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntHangTimeAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsPuntOperationTimeAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsPuntReturnTouchdowns?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntReturnYards?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntReturnYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsPuntSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsPuntSnaps?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntYards?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsPuntYardsLongest?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntYardsNet?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntYardsNetAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsPuntsBlocked?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntsDowned?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntsFairCaught?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntsIn20?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntsOutOfBounds?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntsReturned?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntsTouchbacks?: InputMaybe<IntOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PlayerStatFilterInput>>;
  passingStatsAttempts?: InputMaybe<IntOperationFilterInput>;
  passingStatsCatchableThrowPercentage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsCatchableThrows?: InputMaybe<IntOperationFilterInput>;
  passingStatsCompletionPercentage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsCompletions?: InputMaybe<IntOperationFilterInput>;
  passingStatsCompletionsFortyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  passingStatsCompletionsTwentyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  passingStatsDropbackAttempts?: InputMaybe<IntOperationFilterInput>;
  passingStatsDropbackDepthAverage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsDrops?: InputMaybe<IntOperationFilterInput>;
  passingStatsFirstDownPercentage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsFirstDowns?: InputMaybe<IntOperationFilterInput>;
  passingStatsFumbles?: InputMaybe<IntOperationFilterInput>;
  passingStatsFumblesRecovered?: InputMaybe<IntOperationFilterInput>;
  passingStatsHurriesAllowed?: InputMaybe<IntOperationFilterInput>;
  passingStatsInjuriesInGame?: InputMaybe<IntOperationFilterInput>;
  passingStatsInterceptionPercentage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsInterceptions?: InputMaybe<IntOperationFilterInput>;
  passingStatsLongestCompletion?: InputMaybe<IntOperationFilterInput>;
  passingStatsPassDepthAttempts?: InputMaybe<IntOperationFilterInput>;
  passingStatsPassDepthAverage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsPasserRating?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsPassingEfficiency?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsPenalties?: InputMaybe<IntOperationFilterInput>;
  passingStatsPumpFakes?: InputMaybe<IntOperationFilterInput>;
  passingStatsSackYards?: InputMaybe<IntOperationFilterInput>;
  passingStatsSacks?: InputMaybe<IntOperationFilterInput>;
  passingStatsSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsSnaps?: InputMaybe<IntOperationFilterInput>;
  passingStatsTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  passingStatsTimeToPressureAttempts?: InputMaybe<IntOperationFilterInput>;
  passingStatsTimeToPressureAverage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsTimeToThrowAttempts?: InputMaybe<IntOperationFilterInput>;
  passingStatsTimeToThrowAverage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsTouchdownInterceptionRatio?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsTouchdownPercentage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsTouchdowns?: InputMaybe<IntOperationFilterInput>;
  passingStatsTwoPointAttempts?: InputMaybe<IntOperationFilterInput>;
  passingStatsTwoPointConversions?: InputMaybe<IntOperationFilterInput>;
  passingStatsYards?: InputMaybe<IntOperationFilterInput>;
  passingStatsYardsAfterCatch?: InputMaybe<IntOperationFilterInput>;
  passingStatsYardsPerAttempt?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsYardsPerCompletionAverage?: InputMaybe<DecimalOperationFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  receivingStatsCatchableThrows?: InputMaybe<IntOperationFilterInput>;
  receivingStatsDrops?: InputMaybe<IntOperationFilterInput>;
  receivingStatsDropsPercentage?: InputMaybe<DecimalOperationFilterInput>;
  receivingStatsFirstDownPercentage?: InputMaybe<DecimalOperationFilterInput>;
  receivingStatsFirstDowns?: InputMaybe<IntOperationFilterInput>;
  receivingStatsInjuriesInGame?: InputMaybe<IntOperationFilterInput>;
  receivingStatsLongestReception?: InputMaybe<IntOperationFilterInput>;
  receivingStatsPassRouteDepthAverage?: InputMaybe<DecimalOperationFilterInput>;
  receivingStatsPassRoutesRun?: InputMaybe<IntOperationFilterInput>;
  receivingStatsPenalties?: InputMaybe<IntOperationFilterInput>;
  receivingStatsReceivingFumbles?: InputMaybe<IntOperationFilterInput>;
  receivingStatsReceptions?: InputMaybe<IntOperationFilterInput>;
  receivingStatsReceptionsFortyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  receivingStatsReceptionsPerTouchdown?: InputMaybe<DecimalOperationFilterInput>;
  receivingStatsReceptionsTwentyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  receivingStatsSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  receivingStatsSnaps?: InputMaybe<IntOperationFilterInput>;
  receivingStatsTacklesAvoided?: InputMaybe<IntOperationFilterInput>;
  receivingStatsTargets?: InputMaybe<IntOperationFilterInput>;
  receivingStatsTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  receivingStatsTouchdowns?: InputMaybe<IntOperationFilterInput>;
  receivingStatsTwoPointAttempts?: InputMaybe<IntOperationFilterInput>;
  receivingStatsTwoPointConversions?: InputMaybe<IntOperationFilterInput>;
  receivingStatsYardAfterCatchPerReception?: InputMaybe<DecimalOperationFilterInput>;
  receivingStatsYards?: InputMaybe<IntOperationFilterInput>;
  receivingStatsYardsAfterCatch?: InputMaybe<IntOperationFilterInput>;
  receivingStatsYardsAfterContact?: InputMaybe<IntOperationFilterInput>;
  receivingStatsYardsPerReception?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsKickReturnAttempts?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnFairCatches?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnFumbles?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnLongestYards?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsKickReturnSnaps?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnTouchdowns?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnYards?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnYardsAfterContact?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnYardsAfterContactAverage?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsKickReturnYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsKickReturnsFortyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnsTwentyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnAttempts?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnFairCatches?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnFumbles?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnLongestYards?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsPuntReturnSnaps?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnTouchdowns?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnYards?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnYardsAfterContact?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnYardsAfterContactAverage?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsPuntReturnYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsPuntReturnsFortyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnsTwentyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  rushingStatsAttempts?: InputMaybe<IntOperationFilterInput>;
  rushingStatsFirstDownPercentage?: InputMaybe<DecimalOperationFilterInput>;
  rushingStatsFirstDownRushes?: InputMaybe<IntOperationFilterInput>;
  rushingStatsFumbles?: InputMaybe<IntOperationFilterInput>;
  rushingStatsFumblesLost?: InputMaybe<IntOperationFilterInput>;
  rushingStatsFumblesRecovered?: InputMaybe<IntOperationFilterInput>;
  rushingStatsHurriesAllowed?: InputMaybe<IntOperationFilterInput>;
  rushingStatsInjuriesInGame?: InputMaybe<IntOperationFilterInput>;
  rushingStatsLongestRun?: InputMaybe<IntOperationFilterInput>;
  rushingStatsPenalties?: InputMaybe<IntOperationFilterInput>;
  rushingStatsPressuresAllowed?: InputMaybe<IntOperationFilterInput>;
  rushingStatsRunsFortyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  rushingStatsRunsTwentyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  rushingStatsSacksAllowed?: InputMaybe<IntOperationFilterInput>;
  rushingStatsSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  rushingStatsSnaps?: InputMaybe<IntOperationFilterInput>;
  rushingStatsTacklesAvoided?: InputMaybe<IntOperationFilterInput>;
  rushingStatsTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  rushingStatsTouchdowns?: InputMaybe<IntOperationFilterInput>;
  rushingStatsTwoPointAttempts?: InputMaybe<IntOperationFilterInput>;
  rushingStatsTwoPointConversions?: InputMaybe<IntOperationFilterInput>;
  rushingStatsYards?: InputMaybe<IntOperationFilterInput>;
  rushingStatsYardsAfterContact?: InputMaybe<IntOperationFilterInput>;
  rushingStatsYardsAfterContactPerRush?: InputMaybe<DecimalOperationFilterInput>;
  rushingStatsYardsPerRush?: InputMaybe<DecimalOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  seasonStats?: InputMaybe<ListFilterInputTypeOfSeasonStatFilterInput>;
  seasonTypeId?: InputMaybe<IntOperationFilterInput>;
  statTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type PlayerStatSortInput = {
  defenseStatsBattedPasses?: InputMaybe<SortEnumType>;
  defenseStatsBurns?: InputMaybe<SortEnumType>;
  defenseStatsCoverageAttempts?: InputMaybe<SortEnumType>;
  defenseStatsDefensiveTargets?: InputMaybe<SortEnumType>;
  defenseStatsDroppedInterceptions?: InputMaybe<SortEnumType>;
  defenseStatsExtraPointsBlocked?: InputMaybe<SortEnumType>;
  defenseStatsFieldGoalsBlocked?: InputMaybe<SortEnumType>;
  defenseStatsFumblesForced?: InputMaybe<SortEnumType>;
  defenseStatsFumblesRecovered?: InputMaybe<SortEnumType>;
  defenseStatsFumblesRecoveredForTouchdown?: InputMaybe<SortEnumType>;
  defenseStatsInjuriesInGame?: InputMaybe<SortEnumType>;
  defenseStatsInterceptionLongestReturn?: InputMaybe<SortEnumType>;
  defenseStatsInterceptionPercentage?: InputMaybe<SortEnumType>;
  defenseStatsInterceptionTouchdowns?: InputMaybe<SortEnumType>;
  defenseStatsInterceptionYards?: InputMaybe<SortEnumType>;
  defenseStatsInterceptions?: InputMaybe<SortEnumType>;
  defenseStatsMissedTackles?: InputMaybe<SortEnumType>;
  defenseStatsMissedTacklesOnQb?: InputMaybe<SortEnumType>;
  defenseStatsPassBreakups?: InputMaybe<SortEnumType>;
  defenseStatsPassRushAttempts?: InputMaybe<SortEnumType>;
  defenseStatsPuntsBlocked?: InputMaybe<SortEnumType>;
  defenseStatsQbHitPercentage?: InputMaybe<SortEnumType>;
  defenseStatsQbHits?: InputMaybe<SortEnumType>;
  defenseStatsQbHurries?: InputMaybe<SortEnumType>;
  defenseStatsQbHurryPercentage?: InputMaybe<SortEnumType>;
  defenseStatsQbPressurePercentage?: InputMaybe<SortEnumType>;
  defenseStatsQbPressures?: InputMaybe<SortEnumType>;
  defenseStatsSackPercentage?: InputMaybe<SortEnumType>;
  defenseStatsSackPlays?: InputMaybe<SortEnumType>;
  defenseStatsSacks?: InputMaybe<SortEnumType>;
  defenseStatsSnapPercentage?: InputMaybe<SortEnumType>;
  defenseStatsSnaps?: InputMaybe<SortEnumType>;
  defenseStatsStops?: InputMaybe<SortEnumType>;
  defenseStatsTacklesAssisted?: InputMaybe<SortEnumType>;
  defenseStatsTacklesCombined?: InputMaybe<SortEnumType>;
  defenseStatsTacklesForLoss?: InputMaybe<SortEnumType>;
  defenseStatsTacklesSolo?: InputMaybe<SortEnumType>;
  defenseStatsTeamSnaps?: InputMaybe<SortEnumType>;
  defenseStatsTouchdowns?: InputMaybe<SortEnumType>;
  gameId?: InputMaybe<SortEnumType>;
  kickingStatsBlockedFieldGoalsAndExtraPoints?: InputMaybe<SortEnumType>;
  kickingStatsExtraPointAttempts?: InputMaybe<SortEnumType>;
  kickingStatsExtraPointsBlocked?: InputMaybe<SortEnumType>;
  kickingStatsExtraPointsMade?: InputMaybe<SortEnumType>;
  kickingStatsExtraPointsPercentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalAttempts?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalMadeLongest?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalPercentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalSnapPercentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalSnaps?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalTeamSnaps?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalYardsAverage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals1To19Attempted?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals1To19Made?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals1To19Percentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals20To29Attempted?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals20To29Made?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals20To29Percentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals30To39Attempted?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals30To39Made?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals30To39Percentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals40To49Attempted?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals40To49Made?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals40To49Percentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals50To59Attempted?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals50To59Made?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals50To59Percentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals60PlusAttempted?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals60PlusMade?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals60PlusPercentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalsBlocked?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalsMade?: InputMaybe<SortEnumType>;
  kickingStatsKickoffAttempts?: InputMaybe<SortEnumType>;
  kickingStatsKickoffDepthAttempts?: InputMaybe<SortEnumType>;
  kickingStatsKickoffDepthAverage?: InputMaybe<SortEnumType>;
  kickingStatsKickoffOnsideKicks?: InputMaybe<SortEnumType>;
  kickingStatsKickoffOnsideKicksRecovered?: InputMaybe<SortEnumType>;
  kickingStatsKickoffReturnLong?: InputMaybe<SortEnumType>;
  kickingStatsKickoffReturnTouchdowns?: InputMaybe<SortEnumType>;
  kickingStatsKickoffReturnYards?: InputMaybe<SortEnumType>;
  kickingStatsKickoffReturnYardsAverage?: InputMaybe<SortEnumType>;
  kickingStatsKickoffSnapPercentage?: InputMaybe<SortEnumType>;
  kickingStatsKickoffSnaps?: InputMaybe<SortEnumType>;
  kickingStatsKickoffTeamSnaps?: InputMaybe<SortEnumType>;
  kickingStatsKickoffTouchbackPercentage?: InputMaybe<SortEnumType>;
  kickingStatsKickoffTouchbacks?: InputMaybe<SortEnumType>;
  kickingStatsKickoffYards?: InputMaybe<SortEnumType>;
  kickingStatsKickoffYardsAverage?: InputMaybe<SortEnumType>;
  kickingStatsKickoffsOutOfBounds?: InputMaybe<SortEnumType>;
  kickingStatsKickoffsReturned?: InputMaybe<SortEnumType>;
  kickingStatsPuntAttempts?: InputMaybe<SortEnumType>;
  kickingStatsPuntHangTimeAverage?: InputMaybe<SortEnumType>;
  kickingStatsPuntOperationTimeAverage?: InputMaybe<SortEnumType>;
  kickingStatsPuntReturnTouchdowns?: InputMaybe<SortEnumType>;
  kickingStatsPuntReturnYards?: InputMaybe<SortEnumType>;
  kickingStatsPuntReturnYardsAverage?: InputMaybe<SortEnumType>;
  kickingStatsPuntSnapPercentage?: InputMaybe<SortEnumType>;
  kickingStatsPuntSnaps?: InputMaybe<SortEnumType>;
  kickingStatsPuntTeamSnaps?: InputMaybe<SortEnumType>;
  kickingStatsPuntYards?: InputMaybe<SortEnumType>;
  kickingStatsPuntYardsAverage?: InputMaybe<SortEnumType>;
  kickingStatsPuntYardsLongest?: InputMaybe<SortEnumType>;
  kickingStatsPuntYardsNet?: InputMaybe<SortEnumType>;
  kickingStatsPuntYardsNetAverage?: InputMaybe<SortEnumType>;
  kickingStatsPuntsBlocked?: InputMaybe<SortEnumType>;
  kickingStatsPuntsDowned?: InputMaybe<SortEnumType>;
  kickingStatsPuntsFairCaught?: InputMaybe<SortEnumType>;
  kickingStatsPuntsIn20?: InputMaybe<SortEnumType>;
  kickingStatsPuntsOutOfBounds?: InputMaybe<SortEnumType>;
  kickingStatsPuntsReturned?: InputMaybe<SortEnumType>;
  kickingStatsPuntsTouchbacks?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueTypeId?: InputMaybe<SortEnumType>;
  passingStatsAttempts?: InputMaybe<SortEnumType>;
  passingStatsCatchableThrowPercentage?: InputMaybe<SortEnumType>;
  passingStatsCatchableThrows?: InputMaybe<SortEnumType>;
  passingStatsCompletionPercentage?: InputMaybe<SortEnumType>;
  passingStatsCompletions?: InputMaybe<SortEnumType>;
  passingStatsCompletionsFortyYardsPlus?: InputMaybe<SortEnumType>;
  passingStatsCompletionsTwentyYardsPlus?: InputMaybe<SortEnumType>;
  passingStatsDropbackAttempts?: InputMaybe<SortEnumType>;
  passingStatsDropbackDepthAverage?: InputMaybe<SortEnumType>;
  passingStatsDrops?: InputMaybe<SortEnumType>;
  passingStatsFirstDownPercentage?: InputMaybe<SortEnumType>;
  passingStatsFirstDowns?: InputMaybe<SortEnumType>;
  passingStatsFumbles?: InputMaybe<SortEnumType>;
  passingStatsFumblesRecovered?: InputMaybe<SortEnumType>;
  passingStatsHurriesAllowed?: InputMaybe<SortEnumType>;
  passingStatsInjuriesInGame?: InputMaybe<SortEnumType>;
  passingStatsInterceptionPercentage?: InputMaybe<SortEnumType>;
  passingStatsInterceptions?: InputMaybe<SortEnumType>;
  passingStatsLongestCompletion?: InputMaybe<SortEnumType>;
  passingStatsPassDepthAttempts?: InputMaybe<SortEnumType>;
  passingStatsPassDepthAverage?: InputMaybe<SortEnumType>;
  passingStatsPasserRating?: InputMaybe<SortEnumType>;
  passingStatsPassingEfficiency?: InputMaybe<SortEnumType>;
  passingStatsPenalties?: InputMaybe<SortEnumType>;
  passingStatsPumpFakes?: InputMaybe<SortEnumType>;
  passingStatsSackYards?: InputMaybe<SortEnumType>;
  passingStatsSacks?: InputMaybe<SortEnumType>;
  passingStatsSnapPercentage?: InputMaybe<SortEnumType>;
  passingStatsSnaps?: InputMaybe<SortEnumType>;
  passingStatsTeamSnaps?: InputMaybe<SortEnumType>;
  passingStatsTimeToPressureAttempts?: InputMaybe<SortEnumType>;
  passingStatsTimeToPressureAverage?: InputMaybe<SortEnumType>;
  passingStatsTimeToThrowAttempts?: InputMaybe<SortEnumType>;
  passingStatsTimeToThrowAverage?: InputMaybe<SortEnumType>;
  passingStatsTouchdownInterceptionRatio?: InputMaybe<SortEnumType>;
  passingStatsTouchdownPercentage?: InputMaybe<SortEnumType>;
  passingStatsTouchdowns?: InputMaybe<SortEnumType>;
  passingStatsTwoPointAttempts?: InputMaybe<SortEnumType>;
  passingStatsTwoPointConversions?: InputMaybe<SortEnumType>;
  passingStatsYards?: InputMaybe<SortEnumType>;
  passingStatsYardsAfterCatch?: InputMaybe<SortEnumType>;
  passingStatsYardsPerAttempt?: InputMaybe<SortEnumType>;
  passingStatsYardsPerCompletionAverage?: InputMaybe<SortEnumType>;
  playerId?: InputMaybe<SortEnumType>;
  receivingStatsCatchableThrows?: InputMaybe<SortEnumType>;
  receivingStatsDrops?: InputMaybe<SortEnumType>;
  receivingStatsDropsPercentage?: InputMaybe<SortEnumType>;
  receivingStatsFirstDownPercentage?: InputMaybe<SortEnumType>;
  receivingStatsFirstDowns?: InputMaybe<SortEnumType>;
  receivingStatsInjuriesInGame?: InputMaybe<SortEnumType>;
  receivingStatsLongestReception?: InputMaybe<SortEnumType>;
  receivingStatsPassRouteDepthAverage?: InputMaybe<SortEnumType>;
  receivingStatsPassRoutesRun?: InputMaybe<SortEnumType>;
  receivingStatsPenalties?: InputMaybe<SortEnumType>;
  receivingStatsReceivingFumbles?: InputMaybe<SortEnumType>;
  receivingStatsReceptions?: InputMaybe<SortEnumType>;
  receivingStatsReceptionsFortyYardsPlus?: InputMaybe<SortEnumType>;
  receivingStatsReceptionsPerTouchdown?: InputMaybe<SortEnumType>;
  receivingStatsReceptionsTwentyYardsPlus?: InputMaybe<SortEnumType>;
  receivingStatsSnapPercentage?: InputMaybe<SortEnumType>;
  receivingStatsSnaps?: InputMaybe<SortEnumType>;
  receivingStatsTacklesAvoided?: InputMaybe<SortEnumType>;
  receivingStatsTargets?: InputMaybe<SortEnumType>;
  receivingStatsTeamSnaps?: InputMaybe<SortEnumType>;
  receivingStatsTouchdowns?: InputMaybe<SortEnumType>;
  receivingStatsTwoPointAttempts?: InputMaybe<SortEnumType>;
  receivingStatsTwoPointConversions?: InputMaybe<SortEnumType>;
  receivingStatsYardAfterCatchPerReception?: InputMaybe<SortEnumType>;
  receivingStatsYards?: InputMaybe<SortEnumType>;
  receivingStatsYardsAfterCatch?: InputMaybe<SortEnumType>;
  receivingStatsYardsAfterContact?: InputMaybe<SortEnumType>;
  receivingStatsYardsPerReception?: InputMaybe<SortEnumType>;
  returnStatsKickReturnAttempts?: InputMaybe<SortEnumType>;
  returnStatsKickReturnFairCatches?: InputMaybe<SortEnumType>;
  returnStatsKickReturnFumbles?: InputMaybe<SortEnumType>;
  returnStatsKickReturnLongestYards?: InputMaybe<SortEnumType>;
  returnStatsKickReturnSnapPercentage?: InputMaybe<SortEnumType>;
  returnStatsKickReturnSnaps?: InputMaybe<SortEnumType>;
  returnStatsKickReturnTeamSnaps?: InputMaybe<SortEnumType>;
  returnStatsKickReturnTouchdowns?: InputMaybe<SortEnumType>;
  returnStatsKickReturnYards?: InputMaybe<SortEnumType>;
  returnStatsKickReturnYardsAfterContact?: InputMaybe<SortEnumType>;
  returnStatsKickReturnYardsAfterContactAverage?: InputMaybe<SortEnumType>;
  returnStatsKickReturnYardsAverage?: InputMaybe<SortEnumType>;
  returnStatsKickReturnsFortyYardsPlus?: InputMaybe<SortEnumType>;
  returnStatsKickReturnsTwentyYardsPlus?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnAttempts?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnFairCatches?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnFumbles?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnLongestYards?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnSnapPercentage?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnSnaps?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnTeamSnaps?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnTouchdowns?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnYards?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnYardsAfterContact?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnYardsAfterContactAverage?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnYardsAverage?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnsFortyYardsPlus?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnsTwentyYardsPlus?: InputMaybe<SortEnumType>;
  rushingStatsAttempts?: InputMaybe<SortEnumType>;
  rushingStatsFirstDownPercentage?: InputMaybe<SortEnumType>;
  rushingStatsFirstDownRushes?: InputMaybe<SortEnumType>;
  rushingStatsFumbles?: InputMaybe<SortEnumType>;
  rushingStatsFumblesLost?: InputMaybe<SortEnumType>;
  rushingStatsFumblesRecovered?: InputMaybe<SortEnumType>;
  rushingStatsHurriesAllowed?: InputMaybe<SortEnumType>;
  rushingStatsInjuriesInGame?: InputMaybe<SortEnumType>;
  rushingStatsLongestRun?: InputMaybe<SortEnumType>;
  rushingStatsPenalties?: InputMaybe<SortEnumType>;
  rushingStatsPressuresAllowed?: InputMaybe<SortEnumType>;
  rushingStatsRunsFortyYardsPlus?: InputMaybe<SortEnumType>;
  rushingStatsRunsTwentyYardsPlus?: InputMaybe<SortEnumType>;
  rushingStatsSacksAllowed?: InputMaybe<SortEnumType>;
  rushingStatsSnapPercentage?: InputMaybe<SortEnumType>;
  rushingStatsSnaps?: InputMaybe<SortEnumType>;
  rushingStatsTacklesAvoided?: InputMaybe<SortEnumType>;
  rushingStatsTeamSnaps?: InputMaybe<SortEnumType>;
  rushingStatsTouchdowns?: InputMaybe<SortEnumType>;
  rushingStatsTwoPointAttempts?: InputMaybe<SortEnumType>;
  rushingStatsTwoPointConversions?: InputMaybe<SortEnumType>;
  rushingStatsYards?: InputMaybe<SortEnumType>;
  rushingStatsYardsAfterContact?: InputMaybe<SortEnumType>;
  rushingStatsYardsAfterContactPerRush?: InputMaybe<SortEnumType>;
  rushingStatsYardsPerRush?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  seasonTypeId?: InputMaybe<SortEnumType>;
  statTypeId?: InputMaybe<SortEnumType>;
};

export enum PlayerStatus {
  ACTIVE = "ACTIVE",
  ACTIVE_NON_FOOTBALL_INJURY = "ACTIVE_NON_FOOTBALL_INJURY",
  ACTIVE_PHYSICALLY_UNABLE_TO_PERFORM = "ACTIVE_PHYSICALLY_UNABLE_TO_PERFORM",
  COMMITTED_TO_TEAM = "COMMITTED_TO_TEAM",
  CUT = "CUT",
  DECLARED_FOR_DRAFT = "DECLARED_FOR_DRAFT",
  DEVELOPMENT_PRACTICE_SQUAD = "DEVELOPMENT_PRACTICE_SQUAD",
  ENTERED_TRANSFER_PORTAL = "ENTERED_TRANSFER_PORTAL",
  EXEMPT = "EXEMPT",
  FREE_AGENT = "FREE_AGENT",
  INACTIVE = "INACTIVE",
  INELIGIBLE = "INELIGIBLE",
  INJURED_RESERVE = "INJURED_RESERVE",
  INJURED_RESERVE_DESIGNATED_FOR_RETURN = "INJURED_RESERVE_DESIGNATED_FOR_RETURN",
  NON_FOOTBALL_RELATED_INJURED_RESERVE = "NON_FOOTBALL_RELATED_INJURED_RESERVE",
  NOT_WITH_TEAM = "NOT_WITH_TEAM",
  OUT_FOR_YEAR = "OUT_FOR_YEAR",
  PHYSICALLY_UNABLE_TO_PERFORM = "PHYSICALLY_UNABLE_TO_PERFORM",
  PRACTICE_SQUAD = "PRACTICE_SQUAD",
  PRACTICE_SQUAD_COVID = "PRACTICE_SQUAD_COVID",
  PRACTICE_SQUAD_INJURED = "PRACTICE_SQUAD_INJURED",
  PRACTICE_SQUAD_INTERNATIONAL = "PRACTICE_SQUAD_INTERNATIONAL",
  PRACTICE_SQUAD_SUSPENDED = "PRACTICE_SQUAD_SUSPENDED",
  RESERVE_COVID = "RESERVE_COVID",
  RESERVE_DETAILS_UNKNOWN = "RESERVE_DETAILS_UNKNOWN",
  RESERVE_DID_NOT_REPORT = "RESERVE_DID_NOT_REPORT",
  RESERVE_HIGHER_RISK_OPT_OUT = "RESERVE_HIGHER_RISK_OPT_OUT",
  RESERVE_ILLNESS = "RESERVE_ILLNESS",
  RESERVE_NON_FOOTBALL_INJURY = "RESERVE_NON_FOOTBALL_INJURY",
  RESERVE_PHYSICALLY_UNABLE_TO_PERFORM = "RESERVE_PHYSICALLY_UNABLE_TO_PERFORM",
  RESERVE_VOLUNTARY_OPT_OUT = "RESERVE_VOLUNTARY_OPT_OUT",
  RESTRICTED_FREE_AGENT = "RESTRICTED_FREE_AGENT",
  RETIRED = "RETIRED",
  SUSPENDED = "SUSPENDED",
  TRADED_TO_ANOTHER_CONFERENCE = "TRADED_TO_ANOTHER_CONFERENCE",
  TRADED_TO_ANOTHER_DIVISION = "TRADED_TO_ANOTHER_DIVISION",
  TRADED_TO_ANOTHER_LEAGUE = "TRADED_TO_ANOTHER_LEAGUE",
  TRADED_TO_ANOTHER_TEAM = "TRADED_TO_ANOTHER_TEAM",
  UNKNOWN = "UNKNOWN",
  UNRESTRICTED_FREE_AGENT = "UNRESTRICTED_FREE_AGENT",
  UNSIGNED_DRAFT_PICK = "UNSIGNED_DRAFT_PICK",
}

export type PlayerStatusOperationFilterInput = {
  eq?: InputMaybe<PlayerStatus>;
  in?: InputMaybe<Array<PlayerStatus>>;
  neq?: InputMaybe<PlayerStatus>;
  nin?: InputMaybe<Array<PlayerStatus>>;
};

/** A connection to a list of items. */
export type PlayersPagedConnection = {
  __typename?: "PlayersPagedConnection";
  /** A list of edges. */
  edges?: Maybe<Array<PlayersPagedEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Player>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type PlayersPagedEdge = {
  __typename?: "PlayersPagedEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Player;
};

/** A connection to a list of items. */
export type PlaysConnection = {
  __typename?: "PlaysConnection";
  /** A list of edges. */
  edges?: Maybe<Array<PlaysEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Play>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type PlaysEdge = {
  __typename?: "PlaysEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Play;
};

/** Defines a position a player can play in football. */
export type Position = {
  __typename?: "Position";
  created: Scalars["DateTime"];
  /** Details about the position */
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** The type of position */
  positionType: PositionType;
  rowVersion: Array<Scalars["Byte"]>;
  /** A short name of the position */
  shortName: Scalars["String"];
  /** Which team unit the position belongs to. */
  sideOfBall?: Maybe<SideOfBall>;
  /** The club's sort of the position. */
  sort?: Maybe<Scalars["Int"]>;
};

export type PositionAtlas = {
  __typename?: "PositionAtlas";
  nodes: Array<PositionAtlasNode>;
  nodesByNodeId: Array<KeyValuePairOfStringAndPositionAtlasNode>;
  root: PositionAtlasNode;
};

/**
 * Granular position and fit graph
 * See: https://miro.com/app/board/uXjVM0u4WHA=/
 */
export type PositionAtlasNode = {
  __typename?: "PositionAtlasNode";
  /**
   * A list of references to the node's children
   * Not stored in SQL directly
   */
  children: Array<PositionAtlasNode>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** Node id / name */
  nodeId: Scalars["String"];
  /**
   * Type of the node - roughly what level of the tree it is on
   * values: "root", "unit", "position_group", "position", "fit_group", "fit"
   */
  nodeType: Scalars["String"];
  /**
   * A reference to the node's parent
   * Not stored in SQL directly
   */
  parentNode: PositionAtlasNode;
  /** Parent node id */
  parentNodeId: Scalars["String"];
  rowVersion: Array<Scalars["Byte"]>;
};

export type PositionDepthLimit = {
  __typename?: "PositionDepthLimit";
  /** A grouping the metric belongs to eg "Defense", "Offense", "Punt Return" */
  group: Scalars["String"];
  /** The maximum depth allowed for a roster to be considered complete for the given position */
  maximumDepth: Scalars["Int"];
  /** The minimum depth required for a roster to be considered complete for the given position */
  minimumDepth: Scalars["Int"];
  /** The node id of the position this metric is for */
  positionNodeId: Scalars["String"];
  /** The total size of the roster that these definitions apply to. Typically either 53 or 90. */
  rosterSize: Scalars["Int"];
};

export type PositionDepthLimitInput = {
  /** A grouping the metric belongs to eg "Defense", "Offense", "Punt Return" */
  group: Scalars["String"];
  /** The maximum depth allowed for a roster to be considered complete for the given position */
  maximumDepth: Scalars["Int"];
  /** The minimum depth required for a roster to be considered complete for the given position */
  minimumDepth: Scalars["Int"];
  /** The node id of the position this metric is for */
  positionNodeId: Scalars["String"];
  /** The total size of the roster that these definitions apply to. Typically either 53 or 90. */
  rosterSize: Scalars["Int"];
};

export type PositionDepthLimitSurvey = {
  __typename?: "PositionDepthLimitSurvey";
  id: Scalars["UUID"];
  limits: Array<PositionDepthLimit>;
};

/** Defines a position a player can play in football. */
export type PositionFilterInput = {
  and?: InputMaybe<Array<PositionFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Details about the position */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PositionFilterInput>>;
  /** The type of position */
  positionType?: InputMaybe<PositionTypeOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** A short name of the position */
  shortName?: InputMaybe<StringOperationFilterInput>;
  /** Which team unit the position belongs to. */
  sideOfBall?: InputMaybe<NullableOfSideOfBallOperationFilterInput>;
  /** The club's sort of the position. */
  sort?: InputMaybe<IntOperationFilterInput>;
};

export type PositionFilterOption = {
  __typename?: "PositionFilterOption";
  shortName?: Maybe<Scalars["String"]>;
  sideOfBall?: Maybe<Scalars["String"]>;
};

export type PositionSetItem = {
  __typename?: "PositionSetItem";
  /** indicate whether the position set is specific to a club. */
  clubSpecific: Scalars["Boolean"];
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** Node id / name */
  nodeId: Scalars["String"];
  /** indicate whether there is exactly one such position for every player within the time_scale specified. yes/no. If no, each player may be associated with more than one of these positions. */
  oneToOne: Scalars["Boolean"];
  /** Parent node id */
  positionSetName: Scalars["String"];
  rowVersion: Array<Scalars["Byte"]>;
  /** frame, play, season, career. */
  timeScale: Scalars["String"];
};

/** Defines a position a player can play in football. */
export type PositionSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** Details about the position */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The type of position */
  positionType?: InputMaybe<SortEnumType>;
  /** A short name of the position */
  shortName?: InputMaybe<SortEnumType>;
  /** Which team unit the position belongs to. */
  sideOfBall?: InputMaybe<SortEnumType>;
  /** The club's sort of the position. */
  sort?: InputMaybe<SortEnumType>;
};

export enum PositionType {
  CLUB_GRADING = "CLUB_GRADING",
  SCHEME = "SCHEME",
  SUMER_ATLAS = "SUMER_ATLAS",
  SUMER_GENERAL = "SUMER_GENERAL",
}

export type PositionTypeOperationFilterInput = {
  eq?: InputMaybe<PositionType>;
  in?: InputMaybe<Array<PositionType>>;
  neq?: InputMaybe<PositionType>;
  nin?: InputMaybe<Array<PositionType>>;
};

export type PositionalImportanceMetric = {
  __typename?: "PositionalImportanceMetric";
  /** The club position this metric is for */
  clubNodeId: Scalars["String"];
  /** The default value for this metric given expert surveys and opinions */
  expertDefault: Scalars["Float"];
  /** A grouping the metric belongs to eg "Defense", "Offense", "Punt Return" */
  group: Scalars["String"];
  /** Id of the page */
  id: Scalars["UUID"];
  /** The default value for this metric given estimations of market worth */
  marketDefault: Scalars["Float"];
  /** The value of this metric, nullable if user has never submitted a choice */
  value?: Maybe<Scalars["Float"]>;
};

export type PositionalImportanceMetricInput = {
  /** The club position this metric is for */
  clubNodeId: Scalars["String"];
  /** The default value for this metric given expert surveys and opinions */
  expertDefault: Scalars["Float"];
  /** A grouping the metric belongs to eg "Defense", "Offense", "Punt Return" */
  group: Scalars["String"];
  /** Id of the page */
  id: Scalars["UUID"];
  /** The default value for this metric given estimations of market worth */
  marketDefault: Scalars["Float"];
  /** The value of this metric, nullable if user has never submitted a choice */
  value?: InputMaybe<Scalars["Float"]>;
};

export type PositionalImportancePage = {
  __typename?: "PositionalImportancePage";
  /** Id of the page */
  id: Scalars["UUID"];
  /** Metrics for the given page */
  metrics: Array<PositionalImportanceMetric>;
  /** Type of survey to be presented on this page */
  surveyType: Scalars["String"];
  /** Title of survey page */
  title: Scalars["String"];
};

export type PositionalImportancePageInput = {
  /** Id of the page */
  id: Scalars["UUID"];
  /** Metrics for the given page */
  metrics: Array<PositionalImportanceMetricInput>;
  /** Type of survey to be presented on this page */
  surveyType: Scalars["String"];
  /** Title of survey page */
  title: Scalars["String"];
};

export type PositionalImportanceSurvey = {
  __typename?: "PositionalImportanceSurvey";
  created: Scalars["DateTime"];
  id: Scalars["UUID"];
  lastUpdated: Scalars["DateTime"];
  pages: Array<PositionalImportancePage>;
  rowVersion: Array<Scalars["Byte"]>;
};

/** A single positional need */
export type PositionalNeed = {
  __typename?: "PositionalNeed";
  /** Positional needs priority */
  rank: Scalars["Int"];
  /** The node ID representing the Sumer general position */
  sumerGeneralPosition: Scalars["String"];
};

/** A single positional need */
export type PositionalNeedFilterInput = {
  and?: InputMaybe<Array<PositionalNeedFilterInput>>;
  or?: InputMaybe<Array<PositionalNeedFilterInput>>;
  /** Positional needs priority */
  rank?: InputMaybe<IntOperationFilterInput>;
  /** The node ID representing the Sumer general position */
  sumerGeneralPosition?: InputMaybe<StringOperationFilterInput>;
};

export type ProvisionUserRequest = {
  onboardingLink?: InputMaybe<Scalars["String"]>;
  userId: Scalars["UUID"];
};

export type Query = {
  __typename?: "Query";
  alerts: Array<Alert>;
  allDraftScenarios: Array<DraftScenario>;
  allDraftScenariosPaged?: Maybe<AllDraftScenariosPagedConnection>;
  atlas: PositionAtlas;
  authorizedIntercomKey: Scalars["String"];
  careerStats: Array<CareerStat>;
  clubById?: Maybe<Club>;
  /** Gets the last modified datetime of Club IP. */
  clubIpAuditAttributes: Scalars["DateTime"];
  clubs: Array<Club>;
  currentRosterValue: Array<RosterValuePlayer>;
  currentSeason?: Maybe<Calendar>;
  damComboPlayerResponse: Scalars["String"];
  damResponse: Scalars["String"];
  draftDashboardPlayerListWithFilters?: Maybe<DraftDashboardPlayerListWithFiltersConnection>;
  draftScenarioAtPointInTime?: Maybe<DraftScenario>;
  draftScenarioById: DraftScenario;
  draftScenarioDomainEvent: DomainEventSummary;
  draftScenarioDomainEvents: Array<DomainEventSummary>;
  draftScenarioResults: ExportDraftScenarioResult;
  draftScenarioSerialized: Scalars["String"];
  /** Returns the set of possible options to be used for DraftPlayerFilters.Positions */
  draftSearchPositionOptions: Array<PositionFilterOption>;
  draftablePlayer: ClubDraftablePlayer;
  draftablePlayerPool: DraftablePlayerPool;
  evalById?: Maybe<Evaluation>;
  evalGameGrades: Array<EvaluationGame>;
  evals: Array<Evaluation>;
  filteredPlays: Array<Play>;
  filteredPlaysCount: Scalars["Int"];
  fits: Array<Fit>;
  gameById?: Maybe<Game>;
  gameByNullableId?: Maybe<Game>;
  gameStats: Array<GameStat>;
  games: Array<Game>;
  globalAssumptions: AssumptionSet;
  globalAssumptionsWithPlayer: Array<Player>;
  gradeWeightPreview: Array<RosterValuePlayerWeighted>;
  gradeWeights: Array<GradeWeight>;
  hiddenScenarios: Array<Scenario>;
  indexStatus: SearchIndexStatus;
  liveDraftAssumptions: AssumptionSet;
  liveDraftAssumptionsWithPlayer: Array<Player>;
  measureTypes: Array<MeasureType>;
  myDraftScenarioReadModels: Array<DraftScenarioReadModel>;
  myDraftScenarios: Array<DraftScenario>;
  myDraftScenariosPaged?: Maybe<MyDraftScenariosPagedConnection>;
  packageClubs: Array<PackageClub>;
  packagePositions: Array<PackagePosition>;
  packages: Array<Package>;
  permissions: Array<Permission>;
  playerByGsisId?: Maybe<Player>;
  playerById?: Maybe<Player>;
  playerInjuryCollege?: Maybe<PlayerInjuryCollegeConnection>;
  playerInjuryPro?: Maybe<PlayerInjuryProConnection>;
  playerPositionDepths: Array<PlayerPackagePosition>;
  players: Array<Player>;
  playersPaged?: Maybe<PlayersPagedConnection>;
  plays?: Maybe<PlaysConnection>;
  positionDepthSurvey: PositionDepthLimitSurvey;
  positionMaps: Array<PositionSetItem>;
  positionalImportanceSurvey: PositionalImportanceSurvey;
  positions: Array<Position>;
  recommendedTeamNeeds: Array<TeamNeedsWrapper>;
  roles: Array<AppRole>;
  rosterPlayers: Array<Player>;
  rosterValueResponse: Scalars["String"];
  sampleSimResponse: Scalars["String"];
  scenarioById: Scenario;
  scenarios: Array<Scenario>;
  schoolById?: Maybe<School>;
  schools: Array<School>;
  scoutGrades: Array<ScoutGrade>;
  /** Get scout assignments by userId */
  scoutingAssignmentsByUserId: Array<ScoutingAssignment>;
  /** Searches across all entities and returns them ordered by relevance */
  searchAll: Array<AnyEntitySearchResult>;
  /** Searches for clubs based on the supplied search term. */
  searchClubs: ClubSearchResults;
  /** Searches for players based on the supplied search term. */
  searchPlayers: PlayerSearchResults;
  /** Searches for schools based on the supplied search term. */
  searchSchools: SchoolSearchResults;
  seasonStats: Array<SeasonStat>;
  targetValueResponse: Scalars["String"];
  teamNeeds: Array<TeamNeedsWrapper>;
  /** Get a list of top college players */
  topCollegePlayers: Array<CollegeReportPlayer>;
  tradeChartValues: TradeChartValues;
  tradeEvaluation: SumerTradeValue;
  traitGroups: Array<TraitGroup>;
  transactionsByDateRange?: Maybe<TransactionsByDateRangeConnection>;
  /** Get a list of top 300 UFAs */
  unRestrictedFreeAgents: Array<UfaPlayer>;
  user?: Maybe<User>;
  userByAuthenticatedId?: Maybe<User>;
  userById?: Maybe<User>;
  userDraftScenarioAssumptions: AssumptionSet;
  userDraftScenarioAssumptionsWithPlayer: Array<Player>;
  users: Array<User>;
  workoutMeasuresByPlayerId: Array<WorkoutMeasure>;
  /** get workout players and measures */
  workoutPlayers: Array<WorkoutPlayerMeasure>;
  /** get workouts */
  workouts: Array<Workout>;
  /** get player workouts at a club */
  workoutsByClubId: Array<WorkoutPlayerMeasure>;
  workoutsByPlayerId: Array<WorkoutPlayerMeasure>;
  /** get player workouts at a school */
  workoutsBySchoolId: Array<WorkoutPlayerMeasure>;
};

export type QueryAlertsArgs = {
  order?: InputMaybe<Array<AlertSortInput>>;
  where?: InputMaybe<AlertFilterInput>;
};

export type QueryAllDraftScenariosArgs = {
  order?: InputMaybe<Array<DraftScenarioSortInput>>;
  where?: InputMaybe<DraftScenarioFilterInput>;
};

export type QueryAllDraftScenariosPagedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<DraftScenarioSortInput>>;
  where?: InputMaybe<DraftScenarioFilterInput>;
};

export type QueryCareerStatsArgs = {
  order?: InputMaybe<Array<CareerStatSortInput>>;
  where?: InputMaybe<CareerStatFilterInput>;
};

export type QueryClubByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryClubsArgs = {
  order?: InputMaybe<Array<ClubSortInput>>;
  where?: InputMaybe<ClubFilterInput>;
};

export type QueryCurrentRosterValueArgs = {
  currentRosterValue: GetCurrentRosterValueRequest;
};

export type QueryDamComboPlayerResponseArgs = {
  draftId: Scalars["UUID"];
};

export type QueryDamResponseArgs = {
  draftId: Scalars["UUID"];
};

export type QueryDraftDashboardPlayerListWithFiltersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  draftYear: Scalars["Int"];
  excludePlayers?: InputMaybe<Array<Scalars["UUID"]>>;
  first?: InputMaybe<Scalars["Int"]>;
  includePlayers?: InputMaybe<Array<Scalars["UUID"]>>;
  last?: InputMaybe<Scalars["Int"]>;
  positionFilters?: InputMaybe<Array<Scalars["String"]>>;
  searchText?: InputMaybe<Scalars["String"]>;
  showFavorites: Scalars["Boolean"];
  sortCriteria: DraftPlayerSortCriteriaEnum;
};

export type QueryDraftScenarioAtPointInTimeArgs = {
  atEventVersion?: InputMaybe<Scalars["Long"]>;
  atTimestamp?: InputMaybe<Scalars["DateTime"]>;
  draftId: Scalars["UUID"];
};

export type QueryDraftScenarioByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryDraftScenarioDomainEventArgs = {
  draftId: Scalars["UUID"];
  eventId: Scalars["UUID"];
  order?: InputMaybe<Array<DomainEventSummarySortInput>>;
  where?: InputMaybe<DomainEventSummaryFilterInput>;
};

export type QueryDraftScenarioDomainEventsArgs = {
  draftId: Scalars["UUID"];
  endVersion?: InputMaybe<Scalars["Long"]>;
  order?: InputMaybe<Array<DomainEventSummarySortInput>>;
  startVersion?: InputMaybe<Scalars["Long"]>;
  where?: InputMaybe<DomainEventSummaryFilterInput>;
};

export type QueryDraftScenarioResultsArgs = {
  request: ExportDraftScenarioRequest;
};

export type QueryDraftScenarioSerializedArgs = {
  atEventVersion?: InputMaybe<Scalars["Long"]>;
  atTimestamp?: InputMaybe<Scalars["DateTime"]>;
  draftId: Scalars["UUID"];
};

export type QueryDraftablePlayerArgs = {
  request: GetDraftablePlayerRequest;
};

export type QueryDraftablePlayerPoolArgs = {
  request: LoadDraftablePlayerPoolRequest;
};

export type QueryEvalByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryEvalGameGradesArgs = {
  evaluationId: Scalars["UUID"];
  order?: InputMaybe<Array<EvaluationGameSortInput>>;
  where?: InputMaybe<EvaluationGameFilterInput>;
};

export type QueryEvalsArgs = {
  groupFilter?: InputMaybe<EvalTypeEnum>;
  order?: InputMaybe<Array<EvaluationSortInput>>;
  playerFilter?: InputMaybe<Scalars["UUID"]>;
  positionFilter?: InputMaybe<Scalars["UUID"]>;
  userFilter?: InputMaybe<Scalars["UUID"]>;
  where?: InputMaybe<EvaluationFilterInput>;
};

export type QueryFilteredPlaysArgs = {
  playFilterRequest: PlayFilterRequestInput;
  where?: InputMaybe<PlayFilterInput>;
};

export type QueryFilteredPlaysCountArgs = {
  playFilterRequest: PlayFilterRequestInput;
};

export type QueryFitsArgs = {
  order?: InputMaybe<Array<FitSortInput>>;
  where?: InputMaybe<FitFilterInput>;
};

export type QueryGameByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryGameByNullableIdArgs = {
  id?: InputMaybe<Scalars["UUID"]>;
};

export type QueryGameStatsArgs = {
  order?: InputMaybe<Array<GameStatSortInput>>;
  where?: InputMaybe<GameStatFilterInput>;
};

export type QueryGamesArgs = {
  order?: InputMaybe<Array<GameSortInput>>;
  where?: InputMaybe<GameFilterInput>;
};

export type QueryGradeWeightPreviewArgs = {
  previewGradeWeights: GetGradeWeightPreviewRequest;
};

export type QueryMyDraftScenarioReadModelsArgs = {
  order?: InputMaybe<Array<DraftScenarioReadModelSortInput>>;
  where?: InputMaybe<DraftScenarioReadModelFilterInput>;
};

export type QueryMyDraftScenariosArgs = {
  order?: InputMaybe<Array<DraftScenarioSortInput>>;
  where?: InputMaybe<DraftScenarioFilterInput>;
};

export type QueryMyDraftScenariosPagedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<DraftScenarioSortInput>>;
  where?: InputMaybe<DraftScenarioFilterInput>;
};

export type QueryPackageClubsArgs = {
  order?: InputMaybe<Array<PackageClubSortInput>>;
  where?: InputMaybe<PackageClubFilterInput>;
};

export type QueryPackagePositionsArgs = {
  order?: InputMaybe<Array<PackagePositionSortInput>>;
  where?: InputMaybe<PackagePositionFilterInput>;
};

export type QueryPackagesArgs = {
  order?: InputMaybe<Array<PackageSortInput>>;
  where?: InputMaybe<PackageFilterInput>;
};

export type QueryPermissionsArgs = {
  order?: InputMaybe<Array<PermissionSortInput>>;
  where?: InputMaybe<PermissionFilterInput>;
};

export type QueryPlayerByGsisIdArgs = {
  gsisId: Scalars["String"];
};

export type QueryPlayerByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryPlayerInjuryCollegeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<PlayerInjuryCollegeSortInput>>;
  where?: InputMaybe<PlayerInjuryCollegeFilterInput>;
};

export type QueryPlayerInjuryProArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<PlayerInjuryProSortInput>>;
  where?: InputMaybe<PlayerInjuryProFilterInput>;
};

export type QueryPlayerPositionDepthsArgs = {
  order?: InputMaybe<Array<PlayerPackagePositionSortInput>>;
  where?: InputMaybe<PlayerPackagePositionFilterInput>;
};

export type QueryPlayersArgs = {
  clubFilter?: InputMaybe<Scalars["String"]>;
  draftYearFilter?: InputMaybe<Scalars["String"]>;
  groupFilter?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<PlayerSortInput>>;
  schoolFilter?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
  sumerAtlasPositionFilter?: InputMaybe<Scalars["String"]>;
  sumerGeneralPositionFilter?: InputMaybe<Scalars["String"]>;
  where?: InputMaybe<PlayerFilterInput>;
};

export type QueryPlayersPagedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  clubFilter?: InputMaybe<Scalars["String"]>;
  draftYearFilter?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  groupFilter?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<PlayerSortInput>>;
  schoolFilter?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
  sumerAtlasPositionFilter?: InputMaybe<Scalars["String"]>;
  sumerGeneralPositionFilter?: InputMaybe<Scalars["String"]>;
  where?: InputMaybe<PlayerFilterInput>;
};

export type QueryPlaysArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<PlaySortInput>>;
  where?: InputMaybe<PlayFilterInput>;
};

export type QueryPositionsArgs = {
  order?: InputMaybe<Array<PositionSortInput>>;
  positionTypeFilter?: InputMaybe<PositionType>;
  where?: InputMaybe<PositionFilterInput>;
};

export type QueryRecommendedTeamNeedsArgs = {
  request: GetRecommendedTeamNeedsRequest;
  where?: InputMaybe<TeamNeedsWrapperFilterInput>;
};

export type QueryRolesArgs = {
  order?: InputMaybe<Array<AppRoleSortInput>>;
  where?: InputMaybe<AppRoleFilterInput>;
};

export type QueryRosterPlayersArgs = {
  order?: InputMaybe<Array<PlayerSortInput>>;
  where?: InputMaybe<PlayerFilterInput>;
};

export type QueryRosterValueResponseArgs = {
  draftId: Scalars["UUID"];
};

export type QuerySampleSimResponseArgs = {
  draftId: Scalars["UUID"];
  pickToSimulateTo?: InputMaybe<Scalars["Int"]>;
};

export type QueryScenarioByIdArgs = {
  id: Scalars["UUID"];
};

export type QuerySchoolByIdArgs = {
  id: Scalars["UUID"];
};

export type QuerySchoolsArgs = {
  order?: InputMaybe<Array<SchoolSortInput>>;
  where?: InputMaybe<SchoolFilterInput>;
};

export type QueryScoutGradesArgs = {
  order?: InputMaybe<Array<ScoutGradeSortInput>>;
  where?: InputMaybe<ScoutGradeFilterInput>;
};

export type QueryScoutingAssignmentsByUserIdArgs = {
  userId: Scalars["UUID"];
};

export type QuerySearchAllArgs = {
  order?: InputMaybe<Array<AnyEntitySearchResultSortInput>>;
  searchTerm: Scalars["String"];
  where?: InputMaybe<AnyEntitySearchResultFilterInput>;
};

export type QuerySearchClubsArgs = {
  options?: InputMaybe<SearchOptionsInput>;
  order?: InputMaybe<Array<ClubSearchResultsSortInput>>;
  searchTerm: Scalars["String"];
  where?: InputMaybe<ClubSearchResultsFilterInput>;
};

export type QuerySearchPlayersArgs = {
  filters?: InputMaybe<PlayerFiltersInput>;
  options?: InputMaybe<SearchOptionsInput>;
  order?: InputMaybe<Array<PlayerSearchResultsSortInput>>;
  orderByFields?: InputMaybe<Array<Scalars["String"]>>;
  searchTerm: Scalars["String"];
  where?: InputMaybe<PlayerSearchResultsFilterInput>;
};

export type QuerySearchSchoolsArgs = {
  options?: InputMaybe<SearchOptionsInput>;
  order?: InputMaybe<Array<SchoolSearchResultsSortInput>>;
  searchTerm: Scalars["String"];
  where?: InputMaybe<SchoolSearchResultsFilterInput>;
};

export type QuerySeasonStatsArgs = {
  order?: InputMaybe<Array<SeasonStatSortInput>>;
  where?: InputMaybe<SeasonStatFilterInput>;
};

export type QueryTargetValueResponseArgs = {
  draftId: Scalars["UUID"];
};

export type QueryTeamNeedsArgs = {
  request: GetTeamNeedsRequest;
  where?: InputMaybe<TeamNeedsWrapperFilterInput>;
};

export type QueryTopCollegePlayersArgs = {
  request: GetTopCollegePlayersRequest;
};

export type QueryTradeChartValuesArgs = {
  request: GetTradeChartValuesRequest;
};

export type QueryTradeEvaluationArgs = {
  request: GetTradeEvaluationRequest;
};

export type QueryTraitGroupsArgs = {
  order?: InputMaybe<Array<TraitGroupSortInput>>;
};

export type QueryTransactionsByDateRangeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<TransactionSortInput>>;
  where?: InputMaybe<TransactionFilterInput>;
};

export type QueryUnRestrictedFreeAgentsArgs = {
  request: GetUfaPlayersRequest;
};

export type QueryUserByAuthenticatedIdArgs = {
  id: Scalars["UUID"];
};

export type QueryUserByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryUsersArgs = {
  order?: InputMaybe<Array<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
};

export type QueryWorkoutMeasuresByPlayerIdArgs = {
  playerId: Scalars["UUID"];
};

export type QueryWorkoutPlayersArgs = {
  order?: InputMaybe<Array<WorkoutPlayerMeasureSortInput>>;
  where?: InputMaybe<WorkoutPlayerMeasureFilterInput>;
};

export type QueryWorkoutsArgs = {
  order?: InputMaybe<Array<WorkoutSortInput>>;
  where?: InputMaybe<WorkoutFilterInput>;
};

export type QueryWorkoutsByClubIdArgs = {
  clubId: Scalars["UUID"];
  order?: InputMaybe<Array<WorkoutPlayerMeasureSortInput>>;
};

export type QueryWorkoutsByPlayerIdArgs = {
  order?: InputMaybe<Array<WorkoutPlayerMeasureSortInput>>;
  playerId: Scalars["UUID"];
};

export type QueryWorkoutsBySchoolIdArgs = {
  order?: InputMaybe<Array<WorkoutPlayerMeasureSortInput>>;
  schoolId: Scalars["UUID"];
};

/**
 * A recommended workspace player is a normal workspace player grouped with a position to support
 * easier frontend filtering by position.
 */
export type RecommendedWorkspacePlayer = {
  __typename?: "RecommendedWorkspacePlayer";
  /** The club grading position of the player.  If null, it's overall recommendations regardless of position */
  clubGradingPositionShortName?: Maybe<Scalars["String"]>;
  /** True if this is an overall recommended player */
  overall: Scalars["Boolean"];
  /** The associated workspace player */
  workspacePlayer: WorkspacePlayer;
};

/**
 * A recommended workspace player is a normal workspace player grouped with a position to support
 * easier frontend filtering by position.
 */
export type RecommendedWorkspacePlayerFilterInput = {
  and?: InputMaybe<Array<RecommendedWorkspacePlayerFilterInput>>;
  /** The club grading position of the player.  If null, it's overall recommendations regardless of position */
  clubGradingPositionShortName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RecommendedWorkspacePlayerFilterInput>>;
  /** True if this is an overall recommended player */
  overall?: InputMaybe<BooleanOperationFilterInput>;
  /** The associated workspace player */
  workspacePlayer?: InputMaybe<WorkspacePlayerFilterInput>;
};

/** Request to remove a local assumption. */
export type RemoveAssumptionRequest = {
  /** The ID of the assumption to remove */
  assumptionId: Scalars["UUID"];
  /** The ID of the draft scenario */
  draftId: Scalars["UUID"];
};

export type RemovePermissionsFromRoleRequest = {
  permissionIdsToRemove: Array<Scalars["UUID"]>;
  roleId: Scalars["UUID"];
};

/** Give the specified draft a new name */
export type RenameDraftScenarioRequest = {
  /** The ID of the draft scenario to rename */
  draftId: Scalars["UUID"];
  /** The new name of the draft */
  name: Scalars["String"];
};

export type RenameScenarioCommandRequest = {
  name: Scalars["String"];
  scenarioId: Scalars["UUID"];
};

/** Request to refresh the evaluation of a draft scenario by re-running the models */
export type RequestEvaluationRefreshRequest = {
  /** The ID of the draft scenario to update */
  draftId: Scalars["UUID"];
};

/** Player to set in the workspace */
export type RequestedWorkspacePlayerInput = {
  /** ID of the player */
  playerId: Scalars["UUID"];
  /** Which slot they should be in (0-based index) */
  slot: Scalars["Int"];
};

/** Request to recreate and reload all search data to the players index. */
export type ResetPlayerSearchIndicesRequest = {
  /** Indicates whether or not the Clubs index should be refreshed */
  refreshClubs: Scalars["Boolean"];
  /** Indicates whether or not the Players index should be refreshed */
  refreshPlayers: Scalars["Boolean"];
  /** Indicates whether or not the Schools index should be refreshed */
  refreshSchools: Scalars["Boolean"];
  /** Indicates whether or not to delete/recreate indices before hydrating with create data */
  skipIndexRecreation: Scalars["Boolean"];
};

/** Request to rewind the draft scenario so that the specified overall pick becomes the active pick on the clock. */
export type RewindToPickRequest = {
  /** The ID of the draft scenario */
  draftId: Scalars["UUID"];
  /** The overall pick to rewind to.  Will be active pick after a successful request. */
  overallPickToRewindTo: Scalars["Int"];
};

export enum RoleType {
  SYSTEM = "SYSTEM",
  USER_DEFINED = "USER_DEFINED",
}

export type RoleTypeOperationFilterInput = {
  eq?: InputMaybe<RoleType>;
  in?: InputMaybe<Array<RoleType>>;
  neq?: InputMaybe<RoleType>;
  nin?: InputMaybe<Array<RoleType>>;
};

export type RosterValuePlayer = {
  __typename?: "RosterValuePlayer";
  player: Player;
  rosterValueAdded: Value;
};

export type RosterValuePlayerWeighted = {
  __typename?: "RosterValuePlayerWeighted";
  overallGrade: PlayerGrade;
  playerId: Scalars["UUID"];
  rosterValueAdded: Value;
};

export type SaveEvaluationAlertRequest = {
  alerts: Array<Scalars["UUID"]>;
  evaluationId: Scalars["UUID"];
};

export type SaveEvaluationClubFitRequest = {
  clubs: Array<Scalars["UUID"]>;
  evaluationId: Scalars["UUID"];
};

export type SaveEvaluationFitRequest = {
  evaluationId: Scalars["UUID"];
  fits: Array<Scalars["UUID"]>;
};

export type SaveEvaluationTraitGroupRequest = {
  comments?: InputMaybe<Scalars["String"]>;
  evaluationId: Scalars["UUID"];
  scoutGradeId?: InputMaybe<Scalars["UUID"]>;
  traitGroupId: Scalars["UUID"];
};

export type SaveEvaluationTraitRequest = {
  details: EvaluationTraitDetailsInput;
};

export type SaveProjectedGamesMissedRequest = {
  playerId: Scalars["UUID"];
  season: Scalars["Int"];
  value?: InputMaybe<Scalars["Decimal"]>;
};

export type Scenario = {
  __typename?: "Scenario";
  assumptions: Array<Assumption>;
  assumptionsImportPending: Scalars["Boolean"];
  assumptionsStale: Scalars["Boolean"];
  baseState: BaseScenarioState;
  clubDraftPicks: Array<DraftPick>;
  clubId: Scalars["UUID"];
  clubLeagueId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  isHidden: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  localAssumptions: Array<Assumption>;
  name: Scalars["String"];
  optimizationSettings: OptimizationSettings;
  optimizerInputVersion: Scalars["Int"];
  optimizerRunError?: Maybe<OptimizerRunError>;
  optimizerRunInProgress: Scalars["Boolean"];
  optimizerRunResult?: Maybe<OptimizerRunResult>;
  optimizerRunStartTime?: Maybe<Scalars["DateTime"]>;
  /** Current version of the aggregate, automatically set by aggregate commands. */
  version: Scalars["Int"];
};

export type ScenarioUpdatedEvent = {
  __typename?: "ScenarioUpdatedEvent";
  /** The Id for the event */
  id: Scalars["UUID"];
  /** The Id of the scenario that was updated */
  scenarioId: Scalars["UUID"];
  /** The last updated time of the scenario that was updated */
  scenarioLastUpdated: Scalars["DateTime"];
};

/** Represents a school that has an NCAA football team. */
export type School = {
  __typename?: "School";
  /** The conference the school belongs to. League data has this */
  conference?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  /** The division the school belongs to. League data has this */
  division?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Indicates whether or not this school has an active football program in the NCAA */
  isActive: Scalars["Boolean"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** The NCAA's unique ID for the school */
  ncaaId?: Maybe<Scalars["Int"]>;
  /** The school's nickname */
  nickname?: Maybe<Scalars["String"]>;
  /** A hex color of the team's primary color in their color scheme */
  primaryColor?: Maybe<Scalars["String"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** The NFL's league designated code for the school */
  schoolCode?: Maybe<Scalars["String"]>;
  /** The name of the school */
  schoolName: Scalars["String"];
  /** A URL to the school's website */
  schoolUrl?: Maybe<Scalars["String"]>;
  /** A hex color of the team's secondary color in their color scheme */
  secondaryColor?: Maybe<Scalars["String"]>;
  /** The short name of the school */
  shortName?: Maybe<Scalars["String"]>;
  /**
   * Updates a club using all the supplied values in the command.  Throws ValidationException if invalid.
   *
   *
   * **Returns:**
   * The updated club entity
   */
  updateSchoolDetails: School;
};

/** Represents a school that has an NCAA football team. */
export type SchoolUpdateSchoolDetailsArgs = {
  request: UpdateSchoolRequest;
};

export type SchoolDetailsInput = {
  abbreviation?: InputMaybe<Scalars["String"]>;
  conference?: InputMaybe<Scalars["String"]>;
  division?: InputMaybe<Scalars["String"]>;
  endSeason?: InputMaybe<Scalars["Int"]>;
  franchiseId?: InputMaybe<Scalars["Int"]>;
  gsisAbbreviation?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  league?: InputMaybe<Scalars["String"]>;
  leagueClubId?: InputMaybe<Scalars["Int"]>;
  leagueId?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickName?: InputMaybe<Scalars["String"]>;
  primaryColor?: InputMaybe<Scalars["String"]>;
  secondaryColor?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  startSeason?: InputMaybe<Scalars["Int"]>;
  teamId?: InputMaybe<Scalars["Int"]>;
};

/** Represents a school that has an NCAA football team. */
export type SchoolFilterInput = {
  and?: InputMaybe<Array<SchoolFilterInput>>;
  /** The conference the school belongs to. League data has this */
  conference?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** The division the school belongs to. League data has this */
  division?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Indicates whether or not this school has an active football program in the NCAA */
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** The NCAA's unique ID for the school */
  ncaaId?: InputMaybe<IntOperationFilterInput>;
  /** The school's nickname */
  nickname?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SchoolFilterInput>>;
  /** A hex color of the team's primary color in their color scheme */
  primaryColor?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** The NFL's league designated code for the school */
  schoolCode?: InputMaybe<StringOperationFilterInput>;
  /** The name of the school */
  schoolName?: InputMaybe<StringOperationFilterInput>;
  /** A URL to the school's website */
  schoolUrl?: InputMaybe<StringOperationFilterInput>;
  /** A hex color of the team's secondary color in their color scheme */
  secondaryColor?: InputMaybe<StringOperationFilterInput>;
  /** The short name of the school */
  shortName?: InputMaybe<StringOperationFilterInput>;
};

/** Encapsulates a flattened view of a school that can be searched against.  Annotated with Azure Search attributes. */
export type SchoolSearchDocument = {
  __typename?: "SchoolSearchDocument";
  /** School's internal ID */
  id: Scalars["String"];
  /** Indicates whether or not this school has an active football program in the NCAA */
  isActive: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** The NFL's unique ID for the school */
  leagueClubId?: Maybe<Scalars["Int"]>;
  /** The NCAA's unique ID for the school */
  ncaaId?: Maybe<Scalars["Int"]>;
  /** The school's nickname */
  nickname?: Maybe<Scalars["String"]>;
  /** The NFL's league designated code for the school */
  schoolCode?: Maybe<Scalars["String"]>;
  /** The name of the school */
  schoolName: Scalars["String"];
  /** The short name of the school */
  shortName?: Maybe<Scalars["String"]>;
};

/** Encapsulates a flattened view of a school that can be searched against.  Annotated with Azure Search attributes. */
export type SchoolSearchDocumentFilterInput = {
  and?: InputMaybe<Array<SchoolSearchDocumentFilterInput>>;
  /** School's internal ID */
  id?: InputMaybe<StringOperationFilterInput>;
  /** Indicates whether or not this school has an active football program in the NCAA */
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** The NFL's unique ID for the school */
  leagueClubId?: InputMaybe<IntOperationFilterInput>;
  /** The NCAA's unique ID for the school */
  ncaaId?: InputMaybe<IntOperationFilterInput>;
  /** The school's nickname */
  nickname?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SchoolSearchDocumentFilterInput>>;
  /** The NFL's league designated code for the school */
  schoolCode?: InputMaybe<StringOperationFilterInput>;
  /** The name of the school */
  schoolName?: InputMaybe<StringOperationFilterInput>;
  /** The short name of the school */
  shortName?: InputMaybe<StringOperationFilterInput>;
};

/** Encapsulates a flattened view of a school that can be searched against.  Annotated with Azure Search attributes. */
export type SchoolSearchDocumentSortInput = {
  /** School's internal ID */
  id?: InputMaybe<SortEnumType>;
  /** Indicates whether or not this school has an active football program in the NCAA */
  isActive?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The NFL's unique ID for the school */
  leagueClubId?: InputMaybe<SortEnumType>;
  /** The NCAA's unique ID for the school */
  ncaaId?: InputMaybe<SortEnumType>;
  /** The school's nickname */
  nickname?: InputMaybe<SortEnumType>;
  /** The NFL's league designated code for the school */
  schoolCode?: InputMaybe<SortEnumType>;
  /** The name of the school */
  schoolName?: InputMaybe<SortEnumType>;
  /** The short name of the school */
  shortName?: InputMaybe<SortEnumType>;
};

/** Encapsulates a result set of schools from the search appliance. */
export type SchoolSearchResults = {
  __typename?: "SchoolSearchResults";
  /** The collection of matched schools, ordered by relevance. */
  schools: Array<SearchResultOfSchoolSearchDocument>;
  /** The total number of schools that would match the search query (excluding paging parameters) */
  total: Scalars["Long"];
};

/** Encapsulates a result set of schools from the search appliance. */
export type SchoolSearchResultsFilterInput = {
  and?: InputMaybe<Array<SchoolSearchResultsFilterInput>>;
  or?: InputMaybe<Array<SchoolSearchResultsFilterInput>>;
  /** The collection of matched schools, ordered by relevance. */
  schools?: InputMaybe<ListFilterInputTypeOfSearchResultOfSchoolSearchDocumentFilterInput>;
  /** The total number of schools that would match the search query (excluding paging parameters) */
  total?: InputMaybe<LongOperationFilterInput>;
};

/** Encapsulates a result set of schools from the search appliance. */
export type SchoolSearchResultsSortInput = {
  /** The total number of schools that would match the search query (excluding paging parameters) */
  total?: InputMaybe<SortEnumType>;
};

/** Represents a school that has an NCAA football team. */
export type SchoolSortInput = {
  /** The conference the school belongs to. League data has this */
  conference?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** The division the school belongs to. League data has this */
  division?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Indicates whether or not this school has an active football program in the NCAA */
  isActive?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The NCAA's unique ID for the school */
  ncaaId?: InputMaybe<SortEnumType>;
  /** The school's nickname */
  nickname?: InputMaybe<SortEnumType>;
  /** A hex color of the team's primary color in their color scheme */
  primaryColor?: InputMaybe<SortEnumType>;
  /** The NFL's league designated code for the school */
  schoolCode?: InputMaybe<SortEnumType>;
  /** The name of the school */
  schoolName?: InputMaybe<SortEnumType>;
  /** A URL to the school's website */
  schoolUrl?: InputMaybe<SortEnumType>;
  /** A hex color of the team's secondary color in their color scheme */
  secondaryColor?: InputMaybe<SortEnumType>;
  /** The short name of the school */
  shortName?: InputMaybe<SortEnumType>;
};

export type ScoutGrade = {
  __typename?: "ScoutGrade";
  created: Scalars["DateTime"];
  /** Grade description */
  description: Scalars["String"];
  /** Grade */
  grade: Scalars["Decimal"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** Quality of player assigned to the grade */
  quality: Scalars["String"];
  rowVersion: Array<Scalars["Byte"]>;
  /** The scale the grade belongs */
  scale: Scalars["String"];
  sort?: Maybe<Scalars["Int"]>;
  /** Description of the Quality */
  tier: Scalars["String"];
  update: ScoutGrade;
};

export type ScoutGradeUpdateArgs = {
  details: ScoutGradeDetailsInput;
};

export type ScoutGradeDetailsInput = {
  /** Grade description */
  description: Scalars["String"];
  /** Grade */
  grade: Scalars["Decimal"];
  id: Scalars["UUID"];
  isDeleted: Scalars["Boolean"];
  /** Quality of player assigned to the grade */
  quality: Scalars["String"];
  scale: Scalars["String"];
  sort?: InputMaybe<Scalars["Int"]>;
  /** Description of the Quality */
  tier: Scalars["String"];
};

export type ScoutGradeFilterInput = {
  and?: InputMaybe<Array<ScoutGradeFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Grade description */
  description?: InputMaybe<StringOperationFilterInput>;
  /** Grade */
  grade?: InputMaybe<DecimalOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ScoutGradeFilterInput>>;
  /** Quality of player assigned to the grade */
  quality?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** The scale the grade belongs */
  scale?: InputMaybe<StringOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
  /** Description of the Quality */
  tier?: InputMaybe<StringOperationFilterInput>;
};

export type ScoutGradeSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** Grade description */
  description?: InputMaybe<SortEnumType>;
  /** Grade */
  grade?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Quality of player assigned to the grade */
  quality?: InputMaybe<SortEnumType>;
  /** The scale the grade belongs */
  scale?: InputMaybe<SortEnumType>;
  sort?: InputMaybe<SortEnumType>;
  /** Description of the Quality */
  tier?: InputMaybe<SortEnumType>;
};

export enum ScoutTypeEnum {
  COLLEGE = "COLLEGE",
  CROSS_CHECK = "CROSS_CHECK",
  OTHER = "OTHER",
  PRO = "PRO",
}

export type ScoutingAssignment = {
  __typename?: "ScoutingAssignment";
  club?: Maybe<Club>;
  /** Club assigned to the scout */
  clubId?: Maybe<Scalars["UUID"]>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  position?: Maybe<Position>;
  /** Position assigned to the scout */
  positionId?: Maybe<Scalars["UUID"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** Scout type */
  scoutType: ScoutTypeEnum;
  update: ScoutingAssignment;
  /** Scout the attributes belong */
  userId: Scalars["UUID"];
};

export type ScoutingAssignmentUpdateArgs = {
  request: UpdateScoutingAssignmentRequest;
};

export type ScoutingAssignmentDetailsInput = {
  /** Club assigned to the scout */
  clubId?: InputMaybe<Scalars["UUID"]>;
  /** Position assigned to the scout */
  positionId?: InputMaybe<Scalars["UUID"]>;
  /** Scout type */
  scoutType: ScoutTypeEnum;
  /** Scout the attributes belong */
  userId: Scalars["UUID"];
};

export type SearchIndexStatus = {
  __typename?: "SearchIndexStatus";
  clubsDatabaseCount: Scalars["Long"];
  clubsDocumentCount: Scalars["Long"];
  playersDatabaseCount: Scalars["Long"];
  playersDocumentCount: Scalars["Long"];
  schoolsDatabaseCount: Scalars["Long"];
  schoolsDocumentCount: Scalars["Long"];
};

/** Options used to configure search queries. */
export type SearchOptionsInput = {
  /** Indicates whether or not to include the total count of records, excluding any paging parameters */
  includeTotalCount: Scalars["Boolean"];
  /** Which page to retrieve from */
  page?: InputMaybe<Scalars["Int"]>;
  /** The size of the page to retrieve */
  pageSize?: InputMaybe<Scalars["Int"]>;
};

export type SearchResultOfClubSearchDocument = {
  __typename?: "SearchResultOfClubSearchDocument";
  record: ClubSearchDocument;
  searchScore?: Maybe<Scalars["Float"]>;
};

export type SearchResultOfClubSearchDocumentFilterInput = {
  and?: InputMaybe<Array<SearchResultOfClubSearchDocumentFilterInput>>;
  or?: InputMaybe<Array<SearchResultOfClubSearchDocumentFilterInput>>;
  record?: InputMaybe<ClubSearchDocumentFilterInput>;
  searchScore?: InputMaybe<FloatOperationFilterInput>;
};

export type SearchResultOfClubSearchDocumentSortInput = {
  record?: InputMaybe<ClubSearchDocumentSortInput>;
  searchScore?: InputMaybe<SortEnumType>;
};

export type SearchResultOfPlayerSearchDocument = {
  __typename?: "SearchResultOfPlayerSearchDocument";
  record: PlayerSearchDocument;
  searchScore?: Maybe<Scalars["Float"]>;
};

export type SearchResultOfPlayerSearchDocumentFilterInput = {
  and?: InputMaybe<Array<SearchResultOfPlayerSearchDocumentFilterInput>>;
  or?: InputMaybe<Array<SearchResultOfPlayerSearchDocumentFilterInput>>;
  record?: InputMaybe<PlayerSearchDocumentFilterInput>;
  searchScore?: InputMaybe<FloatOperationFilterInput>;
};

export type SearchResultOfPlayerSearchDocumentSortInput = {
  record?: InputMaybe<PlayerSearchDocumentSortInput>;
  searchScore?: InputMaybe<SortEnumType>;
};

export type SearchResultOfSchoolSearchDocument = {
  __typename?: "SearchResultOfSchoolSearchDocument";
  record: SchoolSearchDocument;
  searchScore?: Maybe<Scalars["Float"]>;
};

export type SearchResultOfSchoolSearchDocumentFilterInput = {
  and?: InputMaybe<Array<SearchResultOfSchoolSearchDocumentFilterInput>>;
  or?: InputMaybe<Array<SearchResultOfSchoolSearchDocumentFilterInput>>;
  record?: InputMaybe<SchoolSearchDocumentFilterInput>;
  searchScore?: InputMaybe<FloatOperationFilterInput>;
};

export type SearchResultOfSchoolSearchDocumentSortInput = {
  record?: InputMaybe<SchoolSearchDocumentSortInput>;
  searchScore?: InputMaybe<SortEnumType>;
};

export type SeasonStat = {
  __typename?: "SeasonStat";
  gameId?: Maybe<Scalars["UUID"]>;
  lastUpdated: Scalars["DateTime"];
  leagueType: LeagueType;
  leagueTypeId: Scalars["Int"];
  perGameStatsGamesPlayed?: Maybe<Scalars["Int"]>;
  perGameStatsPassAttemptsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsPassingYardsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsReceivingYardsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsReceptionsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsRushAttemptsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsRushingYardsPerGame?: Maybe<Scalars["Decimal"]>;
  playerId: Scalars["UUID"];
  playerStat?: Maybe<PlayerStat>;
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  seasonType: SeasonType;
  seasonTypeId: Scalars["Int"];
  statType: StatType;
  statTypeId: Scalars["Int"];
};

export type SeasonStatFilterInput = {
  and?: InputMaybe<Array<SeasonStatFilterInput>>;
  gameId?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueType?: InputMaybe<LeagueTypeFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SeasonStatFilterInput>>;
  perGameStatsGamesPlayed?: InputMaybe<IntOperationFilterInput>;
  perGameStatsPassAttemptsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsPassingYardsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsReceivingYardsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsReceptionsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsRushAttemptsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsRushingYardsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  playerStat?: InputMaybe<PlayerStatFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  seasonType?: InputMaybe<SeasonTypeFilterInput>;
  seasonTypeId?: InputMaybe<IntOperationFilterInput>;
  statType?: InputMaybe<StatTypeFilterInput>;
  statTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type SeasonStatSortInput = {
  gameId?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueType?: InputMaybe<LeagueTypeSortInput>;
  leagueTypeId?: InputMaybe<SortEnumType>;
  perGameStatsGamesPlayed?: InputMaybe<SortEnumType>;
  perGameStatsPassAttemptsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsPassingYardsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsReceivingYardsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsReceptionsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsRushAttemptsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsRushingYardsPerGame?: InputMaybe<SortEnumType>;
  playerId?: InputMaybe<SortEnumType>;
  playerStat?: InputMaybe<PlayerStatSortInput>;
  season?: InputMaybe<SortEnumType>;
  seasonType?: InputMaybe<SeasonTypeSortInput>;
  seasonTypeId?: InputMaybe<SortEnumType>;
  statType?: InputMaybe<StatTypeSortInput>;
  statTypeId?: InputMaybe<SortEnumType>;
};

export type SeasonType = {
  __typename?: "SeasonType";
  abbreviation?: Maybe<Scalars["String"]>;
  careerStats: Array<CareerStat>;
  description?: Maybe<Scalars["String"]>;
  gameStats: Array<GameStat>;
  games: Array<Game>;
  id: Scalars["Int"];
  seasonStats: Array<SeasonStat>;
};

export type SeasonTypeFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<SeasonTypeFilterInput>>;
  careerStats?: InputMaybe<ListFilterInputTypeOfCareerStatFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  gameStats?: InputMaybe<ListFilterInputTypeOfGameStatFilterInput>;
  games?: InputMaybe<ListFilterInputTypeOfGameFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SeasonTypeFilterInput>>;
  seasonStats?: InputMaybe<ListFilterInputTypeOfSeasonStatFilterInput>;
};

export type SeasonTypeSortInput = {
  abbreviation?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

/** Wrapper of projected salary cap for a season */
export type SeasonalSalaryCap = {
  __typename?: "SeasonalSalaryCap";
  /** Projected salary cap amount in dollars */
  salaryCapAmount: Scalars["Float"];
  /** The season */
  season: Scalars["Int"];
};

/** Wrapper of projected salary cap for a season */
export type SeasonalSalaryCapFilterInput = {
  and?: InputMaybe<Array<SeasonalSalaryCapFilterInput>>;
  or?: InputMaybe<Array<SeasonalSalaryCapFilterInput>>;
  /** Projected salary cap amount in dollars */
  salaryCapAmount?: InputMaybe<FloatOperationFilterInput>;
  /** The season */
  season?: InputMaybe<IntOperationFilterInput>;
};

export type SetOptimizationSettingsRequest = {
  optimizationSettings: OptimizationSettingsInput;
  scenarioId: Scalars["UUID"];
};

/** Set the current workspace players to the custom list. */
export type SetWorkspacePlayersCustomRequest = {
  /** ID of the draft scenario */
  draftId: Scalars["UUID"];
  /** The workspace players to set. */
  requestedWorkspacePlayers: Array<RequestedWorkspacePlayerInput>;
};

/** Set the current workspace players and sort mode for the draft scenario. */
export type SetWorkspacePlayersRecommendedRequest = {
  /** ID of the draft scenario */
  draftId: Scalars["UUID"];
  /** If the sort mode is by recommended, this is the club grading position of recommendations.  If null, then it is the overall recommendations across all positions. */
  recommendedClubGradingPositionSort?: InputMaybe<Scalars["String"]>;
};

/** Denotes a grouping of positions based on their role on the field. */
export enum SideOfBall {
  DEFENSE = "DEFENSE",
  OFFENSE = "OFFENSE",
  SPECIAL_TEAMS = "SPECIAL_TEAMS",
}

export type SideOfBallOperationFilterInput = {
  eq?: InputMaybe<SideOfBall>;
  in?: InputMaybe<Array<SideOfBall>>;
  neq?: InputMaybe<SideOfBall>;
  nin?: InputMaybe<Array<SideOfBall>>;
};

/** Request to auto pick players to fast forward to a particular pick. */
export type SimulateToPickRequest = {
  /** ID of the draft scenario */
  draftId: Scalars["UUID"];
  /** The overall pick to auto pick up to. Will be active pick after a successful request.  If null, simulates to end of draft. */
  overallPickToSimulateTo?: InputMaybe<Scalars["Int"]>;
};

export enum SortEnumType {
  ASC = "ASC",
  DESC = "DESC",
}

export type StartOptimizationCommandRequest = {
  scenarioId: Scalars["UUID"];
};

export type StatType = {
  __typename?: "StatType";
  careerStats: Array<CareerStat>;
  description?: Maybe<Scalars["String"]>;
  gameStats: Array<GameStat>;
  id: Scalars["Int"];
  seasonStats: Array<SeasonStat>;
};

export type StatTypeFilterInput = {
  and?: InputMaybe<Array<StatTypeFilterInput>>;
  careerStats?: InputMaybe<ListFilterInputTypeOfCareerStatFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  gameStats?: InputMaybe<ListFilterInputTypeOfGameStatFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<StatTypeFilterInput>>;
  seasonStats?: InputMaybe<ListFilterInputTypeOfSeasonStatFilterInput>;
};

export type StatTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  eq?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ncontains?: InputMaybe<Scalars["String"]>;
  nendsWith?: InputMaybe<Scalars["String"]>;
  neq?: InputMaybe<Scalars["String"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  nstartsWith?: InputMaybe<Scalars["String"]>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export enum Subject {
  DRAFT_PICK = "DRAFT_PICK",
  PLAYER = "PLAYER",
}

export type SubjectOperationFilterInput = {
  eq?: InputMaybe<Subject>;
  in?: InputMaybe<Array<Subject>>;
  neq?: InputMaybe<Subject>;
  nin?: InputMaybe<Array<Subject>>;
};

export type Subscription = {
  __typename?: "Subscription";
  draftScenarioUpdatesById: DraftScenario;
  scenarioUpdated: ScenarioUpdatedEvent;
  /** Subscribe to updates for all scenarios */
  scenarioUpdates: ScenarioUpdatedEvent;
};

export type SubscriptionDraftScenarioUpdatesByIdArgs = {
  id: Scalars["UUID"];
};

export type SubscriptionScenarioUpdatedArgs = {
  id: Scalars["UUID"];
};

/** A wrapper of a draft asset being traded that has some value as derived by Sumer. */
export type SumerEvaluatedDraftAsset = {
  __typename?: "SumerEvaluatedDraftAsset";
  /** The club's cost of the pick. */
  cost: Value;
  /** The pick being traded */
  draftAsset: DraftAsset;
  net: Value;
  /** The club value of the pick. */
  value: ClubPickValue;
};

/** A wrapper of a draft asset being traded that has some value as derived by Sumer. */
export type SumerEvaluatedDraftAssetFilterInput = {
  and?: InputMaybe<Array<SumerEvaluatedDraftAssetFilterInput>>;
  /** The club's cost of the pick. */
  cost?: InputMaybe<ValueFilterInput>;
  /** The pick being traded */
  draftAsset?: InputMaybe<DraftAssetFilterInput>;
  net?: InputMaybe<ValueFilterInput>;
  or?: InputMaybe<Array<SumerEvaluatedDraftAssetFilterInput>>;
  /** The club value of the pick. */
  value?: InputMaybe<ClubPickValueFilterInput>;
};

/** A wrapper of a draft asset being traded that has some value as derived by Sumer. */
export type SumerEvaluatedDraftAssetInput = {
  /** The club's cost of the pick. */
  cost: ValueInput;
  /** The pick being traded */
  draftAsset: DraftAssetInput;
  net: ValueInput;
  /** The club value of the pick. */
  value: ClubPickValueInput;
};

/**
 * The value of a trade according to Sumer's analysis.
 *
 * Private setters just discard the value.  For some reason, HC won't accept the type without the setter, even though
 * this pattern works for other things like PerformanceGap.  My guess is it has something to do with
 * the nullable values but unsure.
 */
export type SumerTradeValue = {
  __typename?: "SumerTradeValue";
  /** Rookie contract estimate sum incoming */
  costIncurring: Value;
  /** Rookie contract estimate sum outgoing */
  costOffloading: Value;
  netCostDollars: Scalars["Float"];
  netValueDollars?: Maybe<Scalars["Float"]>;
  sumerEvaluatedDraftAssets: Array<SumerEvaluatedDraftAsset>;
  tradeValueDollars: Scalars["Float"];
  /** Team value of outgoing draft pick assets.  Only applies when evaluating for client club. */
  valueOffering?: Maybe<Value>;
  /** Team value of incoming draft pick assets  Only applies when evaluating for client club. */
  valueReceiving?: Maybe<Value>;
};

/**
 * The value of a trade according to Sumer's analysis.
 *
 * Private setters just discard the value.  For some reason, HC won't accept the type without the setter, even though
 * this pattern works for other things like PerformanceGap.  My guess is it has something to do with
 * the nullable values but unsure.
 */
export type SumerTradeValueFilterInput = {
  and?: InputMaybe<Array<SumerTradeValueFilterInput>>;
  /** Rookie contract estimate sum incoming */
  costIncurring?: InputMaybe<ValueFilterInput>;
  /** Rookie contract estimate sum outgoing */
  costOffloading?: InputMaybe<ValueFilterInput>;
  netCostDollars?: InputMaybe<FloatOperationFilterInput>;
  netValueDollars?: InputMaybe<FloatOperationFilterInput>;
  or?: InputMaybe<Array<SumerTradeValueFilterInput>>;
  sumerEvaluatedDraftAssets?: InputMaybe<ListFilterInputTypeOfSumerEvaluatedDraftAssetFilterInput>;
  tradeValueDollars?: InputMaybe<FloatOperationFilterInput>;
  /** Team value of outgoing draft pick assets.  Only applies when evaluating for client club. */
  valueOffering?: InputMaybe<ValueFilterInput>;
  /** Team value of incoming draft pick assets  Only applies when evaluating for client club. */
  valueReceiving?: InputMaybe<ValueFilterInput>;
};

/**
 * The value of a trade according to Sumer's analysis.
 *
 * Private setters just discard the value.  For some reason, HC won't accept the type without the setter, even though
 * this pattern works for other things like PerformanceGap.  My guess is it has something to do with
 * the nullable values but unsure.
 */
export type SumerTradeValueInput = {
  /** Rookie contract estimate sum incoming */
  costIncurring: ValueInput;
  /** Rookie contract estimate sum outgoing */
  costOffloading: ValueInput;
  netCostDollars: Scalars["Float"];
  netValueDollars?: InputMaybe<Scalars["Float"]>;
  sumerEvaluatedDraftAssets: Array<SumerEvaluatedDraftAssetInput>;
  tradeValueDollars: Scalars["Float"];
  /** Team value of outgoing draft pick assets.  Only applies when evaluating for client club. */
  valueOffering?: InputMaybe<ValueInput>;
  /** Team value of incoming draft pick assets  Only applies when evaluating for client club. */
  valueReceiving?: InputMaybe<ValueInput>;
};

export type SyncAlertsRequestInput = {
  testId?: InputMaybe<Scalars["String"]>;
};

export type SyncEvaluationMetricGroupsRequestInput = {
  testId?: InputMaybe<Scalars["String"]>;
};

export type SyncFitsRequestInput = {
  testId?: InputMaybe<Scalars["String"]>;
};

export type SyncPositionsRequestInput = {
  dummyId?: InputMaybe<Scalars["String"]>;
};

/**
 * We store club-specified team needs internally to Marval, but we also need to retrieve Sumer's suggested team needs
 * for the UI.  Since those come from a separate database, a wrapper allows us to have a single entity returned
 * through the API.
 */
export type TeamNeedsWrapper = {
  __typename?: "TeamNeedsWrapper";
  /** The club the team needs belong to */
  clubId: Scalars["UUID"];
  /** True if team needs are Sumer's default (recommended) values */
  isSumerDefault: Scalars["Boolean"];
  /** The list of ranked positional needs for the club */
  positionalNeeds: Array<PositionalNeed>;
};

/**
 * We store club-specified team needs internally to Marval, but we also need to retrieve Sumer's suggested team needs
 * for the UI.  Since those come from a separate database, a wrapper allows us to have a single entity returned
 * through the API.
 */
export type TeamNeedsWrapperFilterInput = {
  and?: InputMaybe<Array<TeamNeedsWrapperFilterInput>>;
  /** The club the team needs belong to */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  /** True if team needs are Sumer's default (recommended) values */
  isSumerDefault?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<TeamNeedsWrapperFilterInput>>;
  /** The list of ranked positional needs for the club */
  positionalNeeds?: InputMaybe<ListFilterInputTypeOfPositionalNeedFilterInput>;
};

/** An trade is a trade between clubs with a set of metrics explaining the value it would provide to a club. */
export type Trade = {
  __typename?: "Trade";
  /** Timestamp the evaluation was last created */
  created: Scalars["DateTime"];
  /** A unique ID for the trade.  An int because that's what the underlying models use. */
  id: Scalars["Int"];
  /** Timestamp the evaluation was last updated */
  lastUpdated: Scalars["DateTime"];
  /** The league ID for the trade if it is a live trade */
  leagueTradeId?: Maybe<Scalars["Int"]>;
  /** Status of the trade */
  status: TradeStatus;
  /** The underlying assets traded between clubs */
  tradePartners: Array<TradePartner>;
  /** The valuation of the trade for one of the clubs */
  tradeValue?: Maybe<TradeValue>;
};

/** A trade asset that is either a player or a draft pick. */
export type TradeAsset = {
  __typename?: "TradeAsset";
  /** The traded draft pick.  Should represent the original pick in terms of club assignment. */
  draftPick?: Maybe<DraftAsset>;
  /** The traded player */
  tradedPlayer?: Maybe<TradedPlayer>;
};

/** A trade asset that is either a player or a draft pick. */
export type TradeAssetFilterInput = {
  and?: InputMaybe<Array<TradeAssetFilterInput>>;
  /** The traded draft pick.  Should represent the original pick in terms of club assignment. */
  draftPick?: InputMaybe<DraftAssetFilterInput>;
  or?: InputMaybe<Array<TradeAssetFilterInput>>;
  /** The traded player */
  tradedPlayer?: InputMaybe<TradedPlayerFilterInput>;
};

/** A trade asset that is either a player or a draft pick. */
export type TradeAssetInput = {
  /** The traded draft pick.  Should represent the original pick in terms of club assignment. */
  draftPick?: InputMaybe<DraftAssetInput>;
  /** The traded player */
  tradedPlayer?: InputMaybe<TradedPlayerInput>;
};

export type TradeChartValues = {
  __typename?: "TradeChartValues";
  evaluatedDraftAssets: Array<EvaluatedDraftAsset>;
  jimmyJohnsonTradeValue: JimmyJohnsonTradeValue;
  otcTradeValue: OtcTradeValue;
};

export type TradeChartValuesFilterInput = {
  and?: InputMaybe<Array<TradeChartValuesFilterInput>>;
  evaluatedDraftAssets?: InputMaybe<ListFilterInputTypeOfEvaluatedDraftAssetFilterInput>;
  jimmyJohnsonTradeValue?: InputMaybe<JimmyJohnsonTradeValueFilterInput>;
  or?: InputMaybe<Array<TradeChartValuesFilterInput>>;
  otcTradeValue?: InputMaybe<OtcTradeValueFilterInput>;
};

/** An trade is a trade between clubs with a set of metrics explaining the value it would provide to a club. */
export type TradeFilterInput = {
  and?: InputMaybe<Array<TradeFilterInput>>;
  /** Timestamp the evaluation was last created */
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** A unique ID for the trade.  An int because that's what the underlying models use. */
  id?: InputMaybe<IntOperationFilterInput>;
  /** Timestamp the evaluation was last updated */
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** The league ID for the trade if it is a live trade */
  leagueTradeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TradeFilterInput>>;
  /** Status of the trade */
  status?: InputMaybe<TradeStatusOperationFilterInput>;
  /** The underlying assets traded between clubs */
  tradePartners?: InputMaybe<ListFilterInputTypeOfTradePartnerFilterInput>;
  /** The valuation of the trade for one of the clubs */
  tradeValue?: InputMaybe<TradeValueFilterInput>;
};

/** All of the assets a club gains and loses within the context of a trade. */
export type TradePartner = {
  __typename?: "TradePartner";
  /** The club the assets apply to */
  club: Club;
  /** The assets the club is giving away in the trade */
  offering: Array<TradeAsset>;
  /** The assets that club was receiving in the trade */
  receiving: Array<TradeAsset>;
};

/** All of the assets a club gains and loses within the context of a trade. */
export type TradePartnerFilterInput = {
  and?: InputMaybe<Array<TradePartnerFilterInput>>;
  /** The club the assets apply to */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  /** The assets the club is giving away in the trade */
  offering?: InputMaybe<ListFilterInputTypeOfTradeAssetFilterInput>;
  or?: InputMaybe<Array<TradePartnerFilterInput>>;
  /** The assets that club was receiving in the trade */
  receiving?: InputMaybe<ListFilterInputTypeOfTradeAssetFilterInput>;
};

/** All of the assets a club gains and loses within the context of a trade. */
export type TradePartnerInput = {
  /** The club the assets apply to */
  clubId: Scalars["UUID"];
  /** The assets the club is giving away in the trade */
  offering: Array<TradeAssetInput>;
  /** The assets that club was receiving in the trade */
  receiving: Array<TradeAssetInput>;
};

/** The status of a trade */
export enum TradeStatus {
  EVALUATED = "EVALUATED",
  EXECUTED = "EXECUTED",
  PINNED = "PINNED",
  PINNED_INVALID = "PINNED_INVALID",
}

export type TradeStatusOperationFilterInput = {
  eq?: InputMaybe<TradeStatus>;
  in?: InputMaybe<Array<TradeStatus>>;
  neq?: InputMaybe<TradeStatus>;
  nin?: InputMaybe<Array<TradeStatus>>;
};

/** A wrapper of a trade's value over different measurement systems. */
export type TradeValue = {
  __typename?: "TradeValue";
  /** The evaluation metrics are in the context of this club */
  evaluatedForClub: Club;
  /** Unique ID of the of the requested model run to get the Sumer value */
  modelRequestId?: Maybe<Scalars["UUID"]>;
  /** Sumer's valuation of the trade */
  sumerTradeValue?: Maybe<SumerTradeValue>;
  /** The status of the sumer trade evaluation */
  sumerTradeValueStatus: DraftScenarioDataStatus;
  /** Draft pick values derived from trade charts */
  tradeChartValues: TradeChartValues;
};

/** A wrapper of a trade's value over different measurement systems. */
export type TradeValueFilterInput = {
  and?: InputMaybe<Array<TradeValueFilterInput>>;
  /** The evaluation metrics are in the context of this club */
  evaluatedForClubId?: InputMaybe<UuidOperationFilterInput>;
  /** Unique ID of the of the requested model run to get the Sumer value */
  modelRequestId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<TradeValueFilterInput>>;
  /** Sumer's valuation of the trade */
  sumerTradeValue?: InputMaybe<SumerTradeValueFilterInput>;
  /** The status of the sumer trade evaluation */
  sumerTradeValueStatus?: InputMaybe<DraftScenarioDataStatusOperationFilterInput>;
  /** Draft pick values derived from trade charts */
  tradeChartValues?: InputMaybe<TradeChartValuesFilterInput>;
};

/** Encapsulates a traded player */
export type TradedPlayer = {
  __typename?: "TradedPlayer";
  /** Player's Marval ID */
  player: Player;
};

/** Encapsulates a traded player */
export type TradedPlayerFilterInput = {
  and?: InputMaybe<Array<TradedPlayerFilterInput>>;
  or?: InputMaybe<Array<TradedPlayerFilterInput>>;
  /** Player's Marval ID */
  playerId?: InputMaybe<UuidOperationFilterInput>;
};

/** Encapsulates a traded player */
export type TradedPlayerInput = {
  /** Player's Marval ID */
  playerId: Scalars["UUID"];
};

/** Encapsulates a metric that a player could be evaluated against. */
export type TraitDefinition = {
  __typename?: "TraitDefinition";
  /** The metric to be evaluated */
  caption: Scalars["String"];
  created: Scalars["DateTime"];
  /** The metric to be evaluated */
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Shorter name for metric definition for display purposes */
  shortName: Scalars["String"];
  /** Order of display */
  sort: Scalars["Int"];
  update: TraitDefinition;
  /** The metric importance */
  weight: Scalars["Decimal"];
};

/** Encapsulates a metric that a player could be evaluated against. */
export type TraitDefinitionUpdateArgs = {
  details: TraitDefinitionDetailsInput;
};

export type TraitDefinitionDetailsInput = {
  /** The metric to be evaluated */
  caption: Scalars["String"];
  /** The metric to be evaluated */
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  isDeleted: Scalars["Boolean"];
  /** Shorter name for metric definition for display purposes */
  shortName: Scalars["String"];
  /** Order of display */
  sort: Scalars["Int"];
  /** The metric importance */
  weight: Scalars["Decimal"];
};

/** Encapsulates a metric that a player could be evaluated against. */
export type TraitDefinitionFilterInput = {
  and?: InputMaybe<Array<TraitDefinitionFilterInput>>;
  /** The metric to be evaluated */
  caption?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** The metric to be evaluated */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TraitDefinitionFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Shorter name for metric definition for display purposes */
  shortName?: InputMaybe<StringOperationFilterInput>;
  /** Order of display */
  sort?: InputMaybe<IntOperationFilterInput>;
  /** The metric importance */
  weight?: InputMaybe<DecimalOperationFilterInput>;
};

/** Encapsulates a metric that a player could be evaluated against. */
export type TraitDefinitionSortInput = {
  /** The metric to be evaluated */
  caption?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** The metric to be evaluated */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Shorter name for metric definition for display purposes */
  shortName?: InputMaybe<SortEnumType>;
  /** Order of display */
  sort?: InputMaybe<SortEnumType>;
  /** The metric importance */
  weight?: InputMaybe<SortEnumType>;
};

/**
 * Encapsulates a group of related metrics that will be graded and then weighted as a group
 * according to model specifications.
 */
export type TraitGroup = {
  __typename?: "TraitGroup";
  /** Details about what all the metrics in this group encompass */
  caption: Scalars["String"];
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Order of display */
  sort: Scalars["Int"];
  /** The list of metrics that apply to this metric group */
  traitGroupPositions: Array<TraitGroupPosition>;
  update: TraitGroup;
  /** The metric group importance */
  weight: Scalars["Decimal"];
};

/**
 * Encapsulates a group of related metrics that will be graded and then weighted as a group
 * according to model specifications.
 */
export type TraitGroupTraitGroupPositionsArgs = {
  order?: InputMaybe<Array<TraitGroupPositionSortInput>>;
};

/**
 * Encapsulates a group of related metrics that will be graded and then weighted as a group
 * according to model specifications.
 */
export type TraitGroupUpdateArgs = {
  details: TraitGroupDetailsInput;
};

export type TraitGroupDetailsInput = {
  /** Details about what all the metrics in this group encompass */
  caption: Scalars["String"];
  id: Scalars["UUID"];
  isDeleted: Scalars["Boolean"];
  /** Order of display */
  sort: Scalars["Int"];
  /** The metric group importance */
  weight: Scalars["Decimal"];
};

/**
 * Encapsulates a group of related metrics that will be graded and then weighted as a group
 * according to model specifications.
 */
export type TraitGroupFilterInput = {
  and?: InputMaybe<Array<TraitGroupFilterInput>>;
  /** Details about what all the metrics in this group encompass */
  caption?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TraitGroupFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Order of display */
  sort?: InputMaybe<IntOperationFilterInput>;
  /** The list of metrics that apply to this metric group */
  traitGroupPositions?: InputMaybe<ListFilterInputTypeOfTraitGroupPositionFilterInput>;
  /** The metric group importance */
  weight?: InputMaybe<DecimalOperationFilterInput>;
};

export type TraitGroupPosition = {
  __typename?: "TraitGroupPosition";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  position: Position;
  positionId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  sort: Scalars["Int"];
  traitDefinition: TraitDefinition;
  traitDefinitionId: Scalars["UUID"];
  traitGroup: TraitGroup;
  traitGroupId: Scalars["UUID"];
  update: TraitGroupPosition;
};

export type TraitGroupPositionUpdateArgs = {
  request: UpdateTraitGroupPositionRequest;
};

export type TraitGroupPositionFilterInput = {
  and?: InputMaybe<Array<TraitGroupPositionFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TraitGroupPositionFilterInput>>;
  position?: InputMaybe<PositionFilterInput>;
  positionId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
  traitDefinition?: InputMaybe<TraitDefinitionFilterInput>;
  traitDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  traitGroup?: InputMaybe<TraitGroupFilterInput>;
  traitGroupId?: InputMaybe<UuidOperationFilterInput>;
};

export type TraitGroupPositionSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  position?: InputMaybe<PositionSortInput>;
  positionId?: InputMaybe<SortEnumType>;
  sort?: InputMaybe<SortEnumType>;
  traitDefinition?: InputMaybe<TraitDefinitionSortInput>;
  traitDefinitionId?: InputMaybe<SortEnumType>;
  traitGroup?: InputMaybe<TraitGroupSortInput>;
  traitGroupId?: InputMaybe<SortEnumType>;
};

/**
 * Encapsulates a group of related metrics that will be graded and then weighted as a group
 * according to model specifications.
 */
export type TraitGroupSortInput = {
  /** Details about what all the metrics in this group encompass */
  caption?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Order of display */
  sort?: InputMaybe<SortEnumType>;
  /** The metric group importance */
  weight?: InputMaybe<SortEnumType>;
};

export type Transaction = {
  __typename?: "Transaction";
  /** Comments around the transaction */
  comments?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  initialStatus: TransactionStatus;
  /** Status of player before transaction (league status id) */
  initialStatusId: Scalars["UUID"];
  lastUpdated: Scalars["DateTime"];
  /** League transaction ID */
  leagueTransID: Scalars["Int"];
  player: Player;
  /** Marval player associated with transaction */
  playerId: Scalars["UUID"];
  potentialClub?: Maybe<Club>;
  /** Potential player could belong to  (better desc here) */
  potentialClubId?: Maybe<Scalars["UUID"]>;
  resultClub?: Maybe<Club>;
  /** Club that the player belongs to post transaction */
  resultClubId?: Maybe<Scalars["UUID"]>;
  resultStatus: TransactionStatus;
  /** Status of player post transaction */
  resultStatusId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Season transaction was executed */
  season: Scalars["Int"];
  startClub?: Maybe<Club>;
  /** Club that the player belongs to be before transaction */
  startClubId?: Maybe<Scalars["UUID"]>;
  /** Not sure. */
  toDate?: Maybe<Scalars["DateTime"]>;
  /** Description of transaction (league id) */
  transDescriptionId: Scalars["UUID"];
  /** Date transaction was executed */
  transactionDate: Scalars["DateTime"];
  transactionDescription: TransactionDescription;
  /** Transaction was voided */
  voided: Scalars["Boolean"];
};

export type TransactionDescription = {
  __typename?: "TransactionDescription";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  /** Whether current status is active or in use */
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** League's transaction description ID */
  leagueTransDescriptionID: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Longer description of transaction */
  transactionDesc: Scalars["String"];
  /** League's transaction desc abbr */
  transactionDescAbbr: Scalars["String"];
};

export type TransactionDescriptionFilterInput = {
  and?: InputMaybe<Array<TransactionDescriptionFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Whether current status is active or in use */
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** League's transaction description ID */
  leagueTransDescriptionID?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TransactionDescriptionFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Longer description of transaction */
  transactionDesc?: InputMaybe<StringOperationFilterInput>;
  /** League's transaction desc abbr */
  transactionDescAbbr?: InputMaybe<StringOperationFilterInput>;
};

export type TransactionDescriptionSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Whether current status is active or in use */
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** League's transaction description ID */
  leagueTransDescriptionID?: InputMaybe<SortEnumType>;
  /** Longer description of transaction */
  transactionDesc?: InputMaybe<SortEnumType>;
  /** League's transaction desc abbr */
  transactionDescAbbr?: InputMaybe<SortEnumType>;
};

export type TransactionFilterInput = {
  and?: InputMaybe<Array<TransactionFilterInput>>;
  /** Comments around the transaction */
  comments?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  initialStatus?: InputMaybe<TransactionStatusFilterInput>;
  /** Status of player before transaction (league status id) */
  initialStatusId?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** League transaction ID */
  leagueTransID?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TransactionFilterInput>>;
  player?: InputMaybe<PlayerFilterInput>;
  /** Marval player associated with transaction */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  potentialClub?: InputMaybe<ClubFilterInput>;
  /** Potential player could belong to  (better desc here) */
  potentialClubId?: InputMaybe<UuidOperationFilterInput>;
  resultClub?: InputMaybe<ClubFilterInput>;
  /** Club that the player belongs to post transaction */
  resultClubId?: InputMaybe<UuidOperationFilterInput>;
  resultStatus?: InputMaybe<TransactionStatusFilterInput>;
  /** Status of player post transaction */
  resultStatusId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Season transaction was executed */
  season?: InputMaybe<IntOperationFilterInput>;
  startClub?: InputMaybe<ClubFilterInput>;
  /** Club that the player belongs to be before transaction */
  startClubId?: InputMaybe<UuidOperationFilterInput>;
  /** Not sure. */
  toDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** Description of transaction (league id) */
  transDescriptionId?: InputMaybe<UuidOperationFilterInput>;
  /** Date transaction was executed */
  transactionDate?: InputMaybe<DateTimeOperationFilterInput>;
  transactionDescription?: InputMaybe<TransactionDescriptionFilterInput>;
  /** Transaction was voided */
  voided?: InputMaybe<BooleanOperationFilterInput>;
};

export type TransactionSortInput = {
  /** Comments around the transaction */
  comments?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  initialStatus?: InputMaybe<TransactionStatusSortInput>;
  /** Status of player before transaction (league status id) */
  initialStatusId?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** League transaction ID */
  leagueTransID?: InputMaybe<SortEnumType>;
  player?: InputMaybe<PlayerSortInput>;
  /** Marval player associated with transaction */
  playerId?: InputMaybe<SortEnumType>;
  potentialClub?: InputMaybe<ClubSortInput>;
  /** Potential player could belong to  (better desc here) */
  potentialClubId?: InputMaybe<SortEnumType>;
  resultClub?: InputMaybe<ClubSortInput>;
  /** Club that the player belongs to post transaction */
  resultClubId?: InputMaybe<SortEnumType>;
  resultStatus?: InputMaybe<TransactionStatusSortInput>;
  /** Status of player post transaction */
  resultStatusId?: InputMaybe<SortEnumType>;
  /** Season transaction was executed */
  season?: InputMaybe<SortEnumType>;
  startClub?: InputMaybe<ClubSortInput>;
  /** Club that the player belongs to be before transaction */
  startClubId?: InputMaybe<SortEnumType>;
  /** Not sure. */
  toDate?: InputMaybe<SortEnumType>;
  /** Description of transaction (league id) */
  transDescriptionId?: InputMaybe<SortEnumType>;
  /** Date transaction was executed */
  transactionDate?: InputMaybe<SortEnumType>;
  transactionDescription?: InputMaybe<TransactionDescriptionSortInput>;
  /** Transaction was voided */
  voided?: InputMaybe<SortEnumType>;
};

export type TransactionStatus = {
  __typename?: "TransactionStatus";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  /** Whether current status is active or in use */
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** League transaction status ID */
  leagueTransStatusID: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Longer description of transaction status */
  transStatusDesc: Scalars["String"];
  /** League transaction description abbr. */
  transStatusDescAbbr: Scalars["String"];
  /** Transaction status descriptions */
  transStatusShortDesc: Scalars["String"];
};

export type TransactionStatusFilterInput = {
  and?: InputMaybe<Array<TransactionStatusFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Whether current status is active or in use */
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** League transaction status ID */
  leagueTransStatusID?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TransactionStatusFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Longer description of transaction status */
  transStatusDesc?: InputMaybe<StringOperationFilterInput>;
  /** League transaction description abbr. */
  transStatusDescAbbr?: InputMaybe<StringOperationFilterInput>;
  /** Transaction status descriptions */
  transStatusShortDesc?: InputMaybe<StringOperationFilterInput>;
};

export type TransactionStatusSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Whether current status is active or in use */
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** League transaction status ID */
  leagueTransStatusID?: InputMaybe<SortEnumType>;
  /** Longer description of transaction status */
  transStatusDesc?: InputMaybe<SortEnumType>;
  /** League transaction description abbr. */
  transStatusDescAbbr?: InputMaybe<SortEnumType>;
  /** Transaction status descriptions */
  transStatusShortDesc?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type TransactionsByDateRangeConnection = {
  __typename?: "TransactionsByDateRangeConnection";
  /** A list of edges. */
  edges?: Maybe<Array<TransactionsByDateRangeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Transaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type TransactionsByDateRangeEdge = {
  __typename?: "TransactionsByDateRangeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Transaction;
};

export type UfaPlayer = {
  __typename?: "UfaPlayer";
  accruedSeasons?: Maybe<Scalars["Int"]>;
  agentCompany?: Maybe<Scalars["String"]>;
  agentName?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["DateTime"]>;
  club?: Maybe<Scalars["String"]>;
  draftClub?: Maybe<Scalars["String"]>;
  draftRound?: Maybe<Scalars["Int"]>;
  draftSelection?: Maybe<Scalars["Int"]>;
  draftYear?: Maybe<Scalars["Int"]>;
  evaluations: Array<Evaluation>;
  firstName: Scalars["String"];
  freeAgencyStatus?: Maybe<FreeAgencyStatus>;
  headshotUrl?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["Int"]>;
  hometownCity?: Maybe<Scalars["String"]>;
  hometownCountry?: Maybe<Scalars["String"]>;
  hometownState?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  jersey?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  latestBirthDate?: Maybe<Scalars["String"]>;
  primaryEvaluation?: Maybe<Evaluation>;
  sage?: Maybe<Scalars["Decimal"]>;
  school?: Maybe<Scalars["String"]>;
  speed?: Maybe<Scalars["Decimal"]>;
  sumerAtlasPosition?: Maybe<Scalars["String"]>;
  sumerGeneralPosition?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["Int"]>;
  workouts: Array<WorkoutPlayerMeasure>;
};

/** Undo marking an evaluation as final - intended use is for admin/leads to undo mistakes */
export type UnMarkEvaluationAsFinalRequest = {
  /** The ID of the evaluation to update. */
  evaluationId: Scalars["UUID"];
};

/** Request to undo the last action that changed the active pick in the draft scenario. */
export type UndoLastActionRequest = {
  /** ID of the draft scenario */
  draftId: Scalars["UUID"];
};

/** Unpin the specified trade from a draft scenario */
export type UnpinTradeRequest = {
  /** Unique ID of the draft scenario */
  draftId: Scalars["UUID"];
  /** Unique ID of the trade to be unpinned */
  tradeId: Scalars["Int"];
};

export type UpdateBasicPlayerDetailsRequest = {
  /** Attributes for the basic player details */
  details: BasicPlayerDetailsInput;
};

export type UpdateClubDetailsRequest = {
  /** Attributes for the club */
  details: ClubDetailsInput;
  /** The Marval ID of the club to update */
  id: Scalars["UUID"];
};

/** Request to update the inner configuration of a draft scenario */
export type UpdateDraftScenarioConfigurationRequest = {
  /** The ID of the draft scenario to update */
  draftId: Scalars["UUID"];
  newConfiguration: DraftScenarioConfigurationInput;
};

/** Update an evaluation by setting all defined properties on the underlying entity */
export type UpdateEvaluationRequest = {
  details: EvaluationDetailsInput;
  /** The ID of the evaluation to update. */
  evaluationId: Scalars["UUID"];
};

export type UpdateGameEvaluationRequest = {
  details: EvaluationGameDetailsInput;
  id: Scalars["UUID"];
};

export type UpdateGradeWeightRequest = {
  weights: Array<GradeWeightDetailsInput>;
};

export type UpdateMeasureTypeRequest = {
  /** Brief description of measure */
  description: Scalars["String"];
  id: Scalars["UUID"];
  /** Whether the display should be a fraction */
  isFraction: Scalars["Boolean"];
  /** Maximum value for validation */
  maxValue: Scalars["Decimal"];
  /** Minimum value for validation */
  minValue: Scalars["Decimal"];
  /** Name of measure */
  name: Scalars["String"];
  /** Decimal precision */
  precision: Scalars["Int"];
  /** Order of display/sort */
  priority: Scalars["Int"];
  /** Short name for display */
  shortName: Scalars["String"];
};

export type UpdatePermissionRequest = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  permissionId: Scalars["UUID"];
};

export type UpdatePlayerInjuryCollegeWithDetailsRequest = {
  /** Details about the injury */
  details: PlayerInjuryCollegeDetailsInput;
  /** Marval's internal ID for the injury */
  id: Scalars["UUID"];
};

export type UpdatePlayerInjuryProWithDetailsRequest = {
  /** Details about the injury */
  details: PlayerInjuryProDetailsInput;
  /** Marval's internal ID for the injury */
  id: Scalars["UUID"];
};

/** Update a player by setting all defined properties on the underlying entity */
export type UpdatePlayerRequest = {
  /** Details about the player */
  details: PlayerDetailsInput;
  /** Marval's internal ID for the player */
  id: Scalars["UUID"];
};

export type UpdateRoleRequest = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  roleId: Scalars["UUID"];
};

export type UpdateSchoolRequest = {
  /** Attributes for the club */
  details: SchoolDetailsInput;
  /** The Marval ID of the club to update */
  id: Scalars["UUID"];
};

export type UpdateScoutingAssignmentRequest = {
  details: ScoutingAssignmentDetailsInput;
  id: Scalars["UUID"];
};

/** Request to force update team needs */
export type UpdateTeamNeedsRequest = {
  /** The ID of the draft scenario to change */
  draftId: Scalars["UUID"];
  /** The updated team needs */
  updatedTeamNeeds: DraftScenarioTeamNeedsInput;
};

export type UpdateTraitGroupPositionRequest = {
  id: Scalars["UUID"];
  isDeleted: Scalars["Boolean"];
  positionId: Scalars["UUID"];
  sort: Scalars["Int"];
  traitDefinitionId: Scalars["UUID"];
  traitGroupId: Scalars["UUID"];
};

export type UpdateUserRequest = {
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  roleId?: InputMaybe<Scalars["UUID"]>;
  userId: Scalars["UUID"];
};

export type UpdateWorkoutPlayerMeasureWithDetailsCommandInput = {
  details: WorkoutPlayerMeasureDetailsInput;
};

export type UpdateWorkoutTypeRequest = {
  /** Brief description of workout */
  description: Scalars["String"];
  id: Scalars["UUID"];
  /** Name of workout */
  name: Scalars["String"];
  /** Order of display/sort */
  priority: Scalars["Int"];
};

/** A user is someone that uses the system and is authorized to make calls to the backend. */
export type User = {
  __typename?: "User";
  /** The user's unique ID from the authentication provider (currently oid in Azure AD) */
  authenticatedId?: Maybe<Scalars["UUID"]>;
  created: Scalars["DateTime"];
  createdById: Scalars["UUID"];
  /** The user's email */
  emailAddress: Scalars["String"];
  /** The user's first name */
  firstName: Scalars["String"];
  id: Scalars["UUID"];
  /** The user's last name */
  lastName: Scalars["String"];
  lastUpdated: Scalars["DateTime"];
  lastUpdatedById: Scalars["UUID"];
  role?: Maybe<AppRole>;
  /** Foreign key to Role. Role assigned to user. */
  roleId?: Maybe<Scalars["UUID"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /**
   * User's account status. Options: PROVISIONED, ACTIVE, INACTIVE
   * Newly created users will by default be provisioned, until they register into the system at which point they become active.
   */
  status: UserStatus;
};

/** A user is someone that uses the system and is authorized to make calls to the backend. */
export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  /** The user's unique ID from the authentication provider (currently oid in Azure AD) */
  authenticatedId?: InputMaybe<UuidOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  /** The user's email */
  emailAddress?: InputMaybe<StringOperationFilterInput>;
  /** The user's first name */
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** The user's last name */
  lastName?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedById?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  role?: InputMaybe<AppRoleFilterInput>;
  /** Foreign key to Role. Role assigned to user. */
  roleId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /**
   * User's account status. Options: PROVISIONED, ACTIVE, INACTIVE
   * Newly created users will by default be provisioned, until they register into the system at which point they become active.
   */
  status?: InputMaybe<UserStatusOperationFilterInput>;
};

/** A user is someone that uses the system and is authorized to make calls to the backend. */
export type UserSortInput = {
  /** The user's unique ID from the authentication provider (currently oid in Azure AD) */
  authenticatedId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  createdById?: InputMaybe<SortEnumType>;
  /** The user's email */
  emailAddress?: InputMaybe<SortEnumType>;
  /** The user's first name */
  firstName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** The user's last name */
  lastName?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  lastUpdatedById?: InputMaybe<SortEnumType>;
  role?: InputMaybe<AppRoleSortInput>;
  /** Foreign key to Role. Role assigned to user. */
  roleId?: InputMaybe<SortEnumType>;
  /**
   * User's account status. Options: PROVISIONED, ACTIVE, INACTIVE
   * Newly created users will by default be provisioned, until they register into the system at which point they become active.
   */
  status?: InputMaybe<SortEnumType>;
};

export enum UserStatus {
  /** User is active, account verification and registration is complete. */
  ACTIVE = "ACTIVE",
  /** Account has been unprovisioned/deactivated by an administrator. */
  INACTIVE = "INACTIVE",
  /**
   * Users have a provisioned status when an account and role has been assigned to them, but the user has
   * not completed verification by registering through the activation email and logging into the system.
   */
  PROVISIONED = "PROVISIONED",
}

export type UserStatusOperationFilterInput = {
  eq?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  neq?: InputMaybe<UserStatus>;
  nin?: InputMaybe<Array<UserStatus>>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars["UUID"]>;
  gt?: InputMaybe<Scalars["UUID"]>;
  gte?: InputMaybe<Scalars["UUID"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  lt?: InputMaybe<Scalars["UUID"]>;
  lte?: InputMaybe<Scalars["UUID"]>;
  neq?: InputMaybe<Scalars["UUID"]>;
  ngt?: InputMaybe<Scalars["UUID"]>;
  ngte?: InputMaybe<Scalars["UUID"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  nlt?: InputMaybe<Scalars["UUID"]>;
  nlte?: InputMaybe<Scalars["UUID"]>;
};

/** A generic wrapper of a value within a draft scenario that is based on GAPYPOC and dollars and may be summed over multiple seasons' worth of values. */
export type Value = {
  __typename?: "Value";
  /** Converted dollar amount of GAPYPOC */
  dollars: Scalars["Float"];
  /** Guaranteed APY percent of cap */
  gapypoc: Scalars["Float"];
};

/** A generic wrapper of a value within a draft scenario that is based on GAPYPOC and dollars and may be summed over multiple seasons' worth of values. */
export type ValueFilterInput = {
  and?: InputMaybe<Array<ValueFilterInput>>;
  /** Converted dollar amount of GAPYPOC */
  dollars?: InputMaybe<FloatOperationFilterInput>;
  /** Guaranteed APY percent of cap */
  gapypoc?: InputMaybe<FloatOperationFilterInput>;
  or?: InputMaybe<Array<ValueFilterInput>>;
};

/** A generic wrapper of a value within a draft scenario that is based on GAPYPOC and dollars and may be summed over multiple seasons' worth of values. */
export type ValueInput = {
  /** Converted dollar amount of GAPYPOC */
  dollars: Scalars["Float"];
  /** Guaranteed APY percent of cap */
  gapypoc: Scalars["Float"];
};

/** A generic wrapper of a value within a draft scenario that is based on GAPYPOC and dollars and may be summed over multiple seasons' worth of values. */
export type ValueSortInput = {
  /** Converted dollar amount of GAPYPOC */
  dollars?: InputMaybe<SortEnumType>;
  /** Guaranteed APY percent of cap */
  gapypoc?: InputMaybe<SortEnumType>;
};

export type WeekType = {
  __typename?: "WeekType";
  description?: Maybe<Scalars["String"]>;
  gameStats: Array<GameStat>;
  games: Array<Game>;
  id: Scalars["Int"];
};

export type WeekTypeFilterInput = {
  and?: InputMaybe<Array<WeekTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  gameStats?: InputMaybe<ListFilterInputTypeOfGameStatFilterInput>;
  games?: InputMaybe<ListFilterInputTypeOfGameFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<WeekTypeFilterInput>>;
};

export type WeekTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

export type Workout = {
  __typename?: "Workout";
  /** All-star game identifier */
  allStarGameId?: Maybe<Scalars["Int"]>;
  /**
   * Blesto identifier
   * Scouting service some club's subscribe to
   */
  blestoId?: Maybe<Scalars["UUID"]>;
  /** Combine identifier */
  combineId?: Maybe<Scalars["Int"]>;
  /** Comments from source */
  comments?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** Players included in the workout */
  players: Array<WorkoutPlayerMeasure>;
  /** Pro Day identifier */
  proDayId?: Maybe<Scalars["Int"]>;
  rowVersion: Array<Scalars["Byte"]>;
  school?: Maybe<School>;
  /** School measurable taken for Pro Day */
  schoolId?: Maybe<Scalars["UUID"]>;
  /** Season of Workout */
  season: Scalars["Int"];
  /** Scout identifier */
  userId?: Maybe<Scalars["UUID"]>;
  /** Season of measurable source */
  workoutDate: Scalars["DateTime"];
  workoutType: WorkoutType;
  /** Workout types: All-star game, Combine, Pro Day, Blesto, Scout */
  workoutTypeId: Scalars["UUID"];
};

export type WorkoutFilterInput = {
  /** All-star game identifier */
  allStarGameId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<WorkoutFilterInput>>;
  /**
   * Blesto identifier
   * Scouting service some club's subscribe to
   */
  blestoId?: InputMaybe<UuidOperationFilterInput>;
  /** Combine identifier */
  combineId?: InputMaybe<IntOperationFilterInput>;
  /** Comments from source */
  comments?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<WorkoutFilterInput>>;
  /** Players included in the workout */
  players?: InputMaybe<ListFilterInputTypeOfWorkoutPlayerMeasureFilterInput>;
  /** Pro Day identifier */
  proDayId?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  school?: InputMaybe<SchoolFilterInput>;
  /** School measurable taken for Pro Day */
  schoolId?: InputMaybe<UuidOperationFilterInput>;
  /** Season of Workout */
  season?: InputMaybe<IntOperationFilterInput>;
  /** Scout identifier */
  userId?: InputMaybe<UuidOperationFilterInput>;
  /** Season of measurable source */
  workoutDate?: InputMaybe<DateTimeOperationFilterInput>;
  workoutType?: InputMaybe<WorkoutTypeFilterInput>;
  /** Workout types: All-star game, Combine, Pro Day, Blesto, Scout */
  workoutTypeId?: InputMaybe<UuidOperationFilterInput>;
};

export type WorkoutMeasure = {
  __typename?: "WorkoutMeasure";
  measure: Scalars["Decimal"];
  name: Scalars["String"];
};

export type WorkoutPlayerMeasure = {
  __typename?: "WorkoutPlayerMeasure";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  /** Measures get marked as verified or estimate */
  isVerified: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  measureType: MeasureType;
  /** Foreign key to MeasureType */
  measureTypeId: Scalars["UUID"];
  player: Player;
  /** Foreign key to Player */
  playerId: Scalars["UUID"];
  /** update an existing workout player measure.  Throws ValidationException if invalid. */
  process: WorkoutPlayerMeasure;
  rowVersion: Array<Scalars["Byte"]>;
  /** Measure value */
  value: Scalars["Decimal"];
  workout: Workout;
  /** Foreign key to Workout */
  workoutId: Scalars["UUID"];
};

export type WorkoutPlayerMeasureProcessArgs = {
  command: UpdateWorkoutPlayerMeasureWithDetailsCommandInput;
};

export type WorkoutPlayerMeasureDetailsInput = {
  id?: InputMaybe<Scalars["UUID"]>;
  isVerified: Scalars["Boolean"];
  measureTypeId: Scalars["UUID"];
  playerId: Scalars["UUID"];
  value: Scalars["Decimal"];
  workoutId: Scalars["UUID"];
};

export type WorkoutPlayerMeasureFilterInput = {
  and?: InputMaybe<Array<WorkoutPlayerMeasureFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  /** Measures get marked as verified or estimate */
  isVerified?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  measureType?: InputMaybe<MeasureTypeFilterInput>;
  /** Foreign key to MeasureType */
  measureTypeId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<WorkoutPlayerMeasureFilterInput>>;
  player?: InputMaybe<PlayerFilterInput>;
  /** Foreign key to Player */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Measure value */
  value?: InputMaybe<DecimalOperationFilterInput>;
  workout?: InputMaybe<WorkoutFilterInput>;
  /** Foreign key to Workout */
  workoutId?: InputMaybe<UuidOperationFilterInput>;
};

export type WorkoutPlayerMeasureSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  /** Measures get marked as verified or estimate */
  isVerified?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  measureType?: InputMaybe<MeasureTypeSortInput>;
  /** Foreign key to MeasureType */
  measureTypeId?: InputMaybe<SortEnumType>;
  player?: InputMaybe<PlayerSortInput>;
  /** Foreign key to Player */
  playerId?: InputMaybe<SortEnumType>;
  /** Measure value */
  value?: InputMaybe<SortEnumType>;
  workout?: InputMaybe<WorkoutSortInput>;
  /** Foreign key to Workout */
  workoutId?: InputMaybe<SortEnumType>;
};

export type WorkoutSortInput = {
  /** All-star game identifier */
  allStarGameId?: InputMaybe<SortEnumType>;
  /**
   * Blesto identifier
   * Scouting service some club's subscribe to
   */
  blestoId?: InputMaybe<SortEnumType>;
  /** Combine identifier */
  combineId?: InputMaybe<SortEnumType>;
  /** Comments from source */
  comments?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Pro Day identifier */
  proDayId?: InputMaybe<SortEnumType>;
  school?: InputMaybe<SchoolSortInput>;
  /** School measurable taken for Pro Day */
  schoolId?: InputMaybe<SortEnumType>;
  /** Season of Workout */
  season?: InputMaybe<SortEnumType>;
  /** Scout identifier */
  userId?: InputMaybe<SortEnumType>;
  /** Season of measurable source */
  workoutDate?: InputMaybe<SortEnumType>;
  workoutType?: InputMaybe<WorkoutTypeSortInput>;
  /** Workout types: All-star game, Combine, Pro Day, Blesto, Scout */
  workoutTypeId?: InputMaybe<SortEnumType>;
};

export type WorkoutType = {
  __typename?: "WorkoutType";
  created: Scalars["DateTime"];
  /** Brief description of the source */
  description: Scalars["String"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** Source type name */
  name: Scalars["String"];
  /** Order of display/importance */
  priority: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
  updateWorkoutType: WorkoutType;
};

export type WorkoutTypeUpdateWorkoutTypeArgs = {
  request: UpdateWorkoutTypeRequest;
};

export type WorkoutTypeFilterInput = {
  and?: InputMaybe<Array<WorkoutTypeFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Brief description of the source */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** Source type name */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkoutTypeFilterInput>>;
  /** Order of display/importance */
  priority?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

export type WorkoutTypeSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** Brief description of the source */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Source type name */
  name?: InputMaybe<SortEnumType>;
  /** Order of display/importance */
  priority?: InputMaybe<SortEnumType>;
};

/** A player currently in the workspace to be analyzed. */
export type WorkspacePlayer = {
  __typename?: "WorkspacePlayer";
  /** ID of the model run that is generating the combo player recommendations */
  comboPlayerModelRequestId: Scalars["UUID"];
  /** Status of the combo player model run generation */
  comboPlayerModelRunStatus: DraftScenarioDataStatus;
  /** The player being analyzed */
  player: ClubDraftablePlayerAtPick;
  /** Players that are recommended to be picked if this current player is picked. */
  recommendedComboPlayers: Array<ComboDraftablePlayer>;
};

/** A player currently in the workspace to be analyzed. */
export type WorkspacePlayerFilterInput = {
  and?: InputMaybe<Array<WorkspacePlayerFilterInput>>;
  /** ID of the model run that is generating the combo player recommendations */
  comboPlayerModelRequestId?: InputMaybe<UuidOperationFilterInput>;
  /** Status of the combo player model run generation */
  comboPlayerModelRunStatus?: InputMaybe<DraftScenarioDataStatusOperationFilterInput>;
  or?: InputMaybe<Array<WorkspacePlayerFilterInput>>;
  /** The player being analyzed */
  player?: InputMaybe<ClubDraftablePlayerAtPickFilterInput>;
  /** Players that are recommended to be picked if this current player is picked. */
  recommendedComboPlayers?: InputMaybe<ListFilterInputTypeOfComboDraftablePlayerFilterInput>;
};

export enum WorkspaceSortMode {
  CUSTOM = "CUSTOM",
  RECOMMENDED = "RECOMMENDED",
}

export type WorkspaceSortModeOperationFilterInput = {
  eq?: InputMaybe<WorkspaceSortMode>;
  in?: InputMaybe<Array<WorkspaceSortMode>>;
  neq?: InputMaybe<WorkspaceSortMode>;
  nin?: InputMaybe<Array<WorkspaceSortMode>>;
};

export type SearchPlayerFragment = {
  __typename?: "Player";
  id: string;
  playerGrade?: {
    __typename?: "PlayerGrade";
    playerId: string;
    avgClubGapypocDollars: number;
    avgSageGapypocDollars: number;
    avgSumerScoutGapypocDollars: number;
    value: {
      __typename?: "PlayerGradeValue";
      value: string;
      gapypoc: number;
      gapypocDollars: number;
    };
    seasons: Array<{
      __typename?: "PlayerGradeSeason";
      season: number;
      gradeType?: GradeWeightEnum | null;
      lastUpdated: string;
      value: {
        __typename?: "PlayerGradeValue";
        value: string;
        gapypoc: number;
        gapypocDollars: number;
      };
    }>;
  } | null;
  projectedGamesMissed: Array<{
    __typename?: "PlayerProjectedGamesMissed";
    id: string;
    season: number;
    value: number;
    projectedGamesMissed: number;
    projectedGamesMissedOverride?: number | null;
  }>;
};

export type SearchPlayerGradeFragment = {
  __typename?: "PlayerGrade";
  playerId: string;
  avgClubGapypocDollars: number;
  avgSageGapypocDollars: number;
  avgSumerScoutGapypocDollars: number;
  value: {
    __typename?: "PlayerGradeValue";
    value: string;
    rawValue?: string | null;
    rawValueOverride?: string | null;
    gapypoc: number;
    gapypocDollars: number;
  };
  seasons: Array<{
    __typename?: "PlayerGradeSeason";
    season: number;
    gradeType?: GradeWeightEnum | null;
    lastUpdated: string;
    value: {
      __typename?: "PlayerGradeValue";
      value: string;
      rawValue?: string | null;
      rawValueOverride?: string | null;
      gapypoc: number;
      gapypocDollars: number;
    };
  }>;
};

export type SearchPlayerGradeValueFragment = {
  __typename?: "PlayerGradeValue";
  value: string;
  rawValue?: string | null;
  rawValueOverride?: string | null;
  gapypoc: number;
  gapypocDollars: number;
};

export type SearchPlayerGradeSeasonFragment = {
  __typename?: "PlayerGradeSeason";
  season: number;
  gradeType?: GradeWeightEnum | null;
  lastUpdated: string;
  value: {
    __typename?: "PlayerGradeValue";
    value: string;
    rawValue?: string | null;
    rawValueOverride?: string | null;
    gapypoc: number;
    gapypocDollars: number;
  };
};

export type SearchPlayerProjectedGamesMissedFragment = {
  __typename?: "PlayerProjectedGamesMissed";
  id: string;
  season: number;
  value: number;
  projectedGamesMissed: number;
  projectedGamesMissedOverride?: number | null;
};

export type PlayerGradeFragmentFragment = {
  __typename?: "PlayerGrade";
  playerId: string;
  avgClubGapypocDollars: number;
  avgSageGapypocDollars: number;
  avgSumerScoutGapypocDollars: number;
  value: {
    __typename?: "PlayerGradeValue";
    value: string;
    gapypoc: number;
    gapypocDollars: number;
  };
  seasons: Array<{
    __typename?: "PlayerGradeSeason";
    season: number;
    gradeType?: GradeWeightEnum | null;
    lastUpdated: string;
    value: {
      __typename?: "PlayerGradeValue";
      value: string;
      gapypoc: number;
      gapypocDollars: number;
    };
  }>;
};

export type DraftScenarioRequestEvaluationRefreshMutationVariables = Exact<{
  draftId: Scalars["UUID"];
}>;

export type DraftScenarioRequestEvaluationRefreshMutation = {
  __typename?: "Mutation";
  draftScenarioRequestEvaluationRefresh: {
    __typename?: "DraftScenario";
    id: string;
    status: DraftScenarioStatus;
    version: number;
    modelRunStatus: DraftScenarioDataStatus;
  };
};

export type DraftScenarioDomainEventsQueryVariables = Exact<{
  draftId: Scalars["UUID"];
  version?: InputMaybe<Scalars["Long"]>;
}>;

export type DraftScenarioDomainEventsQuery = {
  __typename?: "Query";
  draftScenarioDomainEvents: Array<{
    __typename?: "DomainEventSummary";
    id: string;
    streamId: string;
    timestamp: string;
    version: number;
    type: string;
  }>;
};

export type DraftScenarioDomainEventQueryVariables = Exact<{
  draftId: Scalars["UUID"];
  eventId: Scalars["UUID"];
}>;

export type DraftScenarioDomainEventQuery = {
  __typename?: "Query";
  draftScenarioDomainEvent: {
    __typename?: "DomainEventSummary";
    id: string;
    payload: string;
    streamId: string;
    timestamp: string;
    version: number;
    type: string;
  };
};

export type DraftScenarioAtVersionQueryVariables = Exact<{
  draftId: Scalars["UUID"];
  version: Scalars["Long"];
}>;

export type DraftScenarioAtVersionQuery = {
  __typename?: "Query";
  draftScenarioAtPointInTime?: {
    __typename?: "DraftScenario";
    id: string;
    draftType: DraftTypeEnum;
    season: number;
    name: string;
    version: number;
    lastUpdated: string;
    latestModelRunRequestId: string;
    modelRunStatus: DraftScenarioDataStatus;
    status: DraftScenarioStatus;
    netRosterValueChangeDollars?: number | null;
    clubGradingPositionRecommendedSort?: string | null;
    workspaceSortMode: WorkspaceSortMode;
    activePick?: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    } | null;
    currentRosterValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    startingRosterValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    performanceGaps: Array<{
      __typename?: "PerformanceGap";
      performanceValueAddedDollars: number;
      remainingGapDollars: number;
      sideOfBall?: SideOfBall | null;
      sumerGeneralPositionShortName: string;
      currentPerformanceValue: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      };
      startingPerformanceValue: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      };
      targetPerformanceValue: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      };
    }>;
    assumptions: Array<{
      __typename?: "Assumption";
      constraint: Constraint;
      contractMinimumEndDate?: any | null;
      description: string;
      draftRoundMinimum?: number | null;
      id: string;
      isValid: boolean;
      name: string;
      playerId?: string | null;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        overall?: number | null;
        number?: number | null;
        round?: number | null;
        season: number;
      } | null;
      draftPickTrade?: Array<{
        __typename?: "DraftPickTransaction";
        clubId: string;
        draftPicksGained: Array<{
          __typename?: "DraftPick";
          overall?: number | null;
          number?: number | null;
          round?: number | null;
          season: number;
        }>;
        draftPicksLost: Array<{
          __typename?: "DraftPick";
          overall?: number | null;
          number?: number | null;
          round?: number | null;
          season: number;
        }>;
      }> | null;
    }>;
    clubTeamNeeds: {
      __typename?: "DraftScenarioTeamNeeds";
      positionalNeeds: Array<{
        __typename?: "DraftScenarioPositionalNeed";
        isFulfilled: boolean;
        rank: number;
        sumerGeneralPositionShortName: string;
      }>;
    };
    allTeamNeeds: Array<{
      __typename?: "DraftScenarioTeamNeeds";
      club: {
        __typename?: "Club";
        id: string;
        clubCode: string;
        clubName: string;
      };
      positionalNeeds: Array<{
        __typename?: "DraftScenarioPositionalNeed";
        isFulfilled: boolean;
        rank: number;
        sumerGeneralPositionShortName: string;
      }>;
    }>;
    club: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    };
    clubsNextPick?: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    } | null;
    draftedPlayers: Array<{
      __typename?: "DraftedPlayer";
      player: {
        __typename?: "Player";
        id: string;
        gsisId?: string | null;
        collegeGsisId?: string | null;
        firstName: string;
        lastName: string;
        eligibilityYear?: number | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        birthDate?: string | null;
        headshotUrl?: string | null;
        schoolId?: string | null;
        playerGrade?: {
          __typename?: "PlayerGrade";
          playerId: string;
          avgClubGapypocDollars: number;
          avgSageGapypocDollars: number;
          avgSumerScoutGapypocDollars: number;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
          seasons: Array<{
            __typename?: "PlayerGradeSeason";
            season: number;
            gradeType?: GradeWeightEnum | null;
            lastUpdated: string;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
          }>;
        } | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolCode?: string | null;
          schoolName: string;
        } | null;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        grades: Array<{
          __typename?: "Grade";
          playerId: string;
          gradeType: GradeTypeEnum;
          value: number;
        }>;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          projectedGamesMissed: number;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    clubDraftedPlayers: Array<{
      __typename?: "DraftedPlayer";
      player: {
        __typename?: "Player";
        id: string;
        gsisId?: string | null;
        collegeGsisId?: string | null;
        firstName: string;
        lastName: string;
        eligibilityYear?: number | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        birthDate?: string | null;
        headshotUrl?: string | null;
        schoolId?: string | null;
        playerGrade?: {
          __typename?: "PlayerGrade";
          playerId: string;
          avgClubGapypocDollars: number;
          avgSageGapypocDollars: number;
          avgSumerScoutGapypocDollars: number;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
          seasons: Array<{
            __typename?: "PlayerGradeSeason";
            season: number;
            gradeType?: GradeWeightEnum | null;
            lastUpdated: string;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
          }>;
        } | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolCode?: string | null;
          schoolName: string;
        } | null;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        grades: Array<{
          __typename?: "Grade";
          playerId: string;
          gradeType: GradeTypeEnum;
          value: number;
        }>;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          projectedGamesMissed: number;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    clubDraftAssets: Array<{
      __typename?: "DraftAsset";
      overall?: number | null;
      pickInRound?: number | null;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
    draftOrder: Array<{
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
    tradeOpportunities: Array<{
      __typename?: "PickTradeOpportunity";
      clubValue: {
        __typename?: "ClubPickValue";
        backingPlayers: Array<{
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        }>;
        value: { __typename?: "Value"; gapypoc: number; dollars: number };
      };
      ownerValue: { __typename?: "Value"; gapypoc: number; dollars: number };
      pickToTrade: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    currentWorkspacePlayers: Array<{
      __typename?: "CurrentWorkspacePlayer";
      slot: number;
      workspacePlayer: {
        __typename?: "WorkspacePlayer";
        comboPlayerModelRequestId: string;
        comboPlayerModelRunStatus: DraftScenarioDataStatus;
        player: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
        recommendedComboPlayers: Array<{
          __typename?: "ComboDraftablePlayer";
          isTopCombo: boolean;
          playerAtPick: {
            __typename?: "ClubDraftablePlayerAtPick";
            projectedRosterValueDollars?: number | null;
            marketValue: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            };
            performanceValue?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            rosterValueAdded?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            availability?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            availabilityAtClubsNextPick?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            player: {
              __typename?: "Player";
              id: string;
              firstName: string;
              lastName: string;
              height?: number | null;
              weight?: number | null;
              headshotUrl?: string | null;
              school?: {
                __typename?: "School";
                id: string;
                schoolName: string;
                schoolCode?: string | null;
              } | null;
              playerGrade?: {
                __typename?: "PlayerGrade";
                playerId: string;
                avgClubGapypocDollars: number;
                avgSageGapypocDollars: number;
                avgSumerScoutGapypocDollars: number;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
                seasons: Array<{
                  __typename?: "PlayerGradeSeason";
                  season: number;
                  gradeType?: GradeWeightEnum | null;
                  lastUpdated: string;
                  value: {
                    __typename?: "PlayerGradeValue";
                    value: string;
                    gapypoc: number;
                    gapypocDollars: number;
                  };
                }>;
              } | null;
              workouts: Array<{
                __typename?: "WorkoutPlayerMeasure";
                value: number;
                measureType: { __typename?: "MeasureType"; name: string };
              }>;
              favorite?: {
                __typename?: "PlayerFavorite";
                playerId: string;
              } | null;
              projectedGamesMissed: Array<{
                __typename?: "PlayerProjectedGamesMissed";
                value: number;
                season: number;
                projectedGamesMissed: number;
                projectedGamesMissedOverride?: number | null;
              }>;
              clubGradingPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerAtlasPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerGeneralPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
            };
          };
        }>;
      };
    }>;
    recommendedWorkspacePlayers: Array<{
      __typename?: "RecommendedWorkspacePlayer";
      clubGradingPositionShortName?: string | null;
      workspacePlayer: {
        __typename?: "WorkspacePlayer";
        player: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
        recommendedComboPlayers: Array<{
          __typename?: "ComboDraftablePlayer";
          isTopCombo: boolean;
          playerAtPick: {
            __typename?: "ClubDraftablePlayerAtPick";
            projectedRosterValueDollars?: number | null;
            marketValue: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            };
            performanceValue?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            rosterValueAdded?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            availability?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            availabilityAtClubsNextPick?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            player: {
              __typename?: "Player";
              id: string;
              firstName: string;
              lastName: string;
              height?: number | null;
              weight?: number | null;
              headshotUrl?: string | null;
              school?: {
                __typename?: "School";
                id: string;
                schoolName: string;
                schoolCode?: string | null;
              } | null;
              playerGrade?: {
                __typename?: "PlayerGrade";
                playerId: string;
                avgClubGapypocDollars: number;
                avgSageGapypocDollars: number;
                avgSumerScoutGapypocDollars: number;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
                seasons: Array<{
                  __typename?: "PlayerGradeSeason";
                  season: number;
                  gradeType?: GradeWeightEnum | null;
                  lastUpdated: string;
                  value: {
                    __typename?: "PlayerGradeValue";
                    value: string;
                    gapypoc: number;
                    gapypocDollars: number;
                  };
                }>;
              } | null;
              workouts: Array<{
                __typename?: "WorkoutPlayerMeasure";
                value: number;
                measureType: { __typename?: "MeasureType"; name: string };
              }>;
              favorite?: {
                __typename?: "PlayerFavorite";
                playerId: string;
              } | null;
              projectedGamesMissed: Array<{
                __typename?: "PlayerProjectedGamesMissed";
                value: number;
                season: number;
                projectedGamesMissed: number;
                projectedGamesMissedOverride?: number | null;
              }>;
              clubGradingPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerAtlasPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerGeneralPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
            };
          };
        }>;
      };
    }>;
    executedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
    pinnedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
    applicablePinnedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
    modelMetadata: {
      __typename?: "ModelMetadata";
      latestDamRequestBlobLocation?: string | null;
      latestDamResponseBlobLocation?: string | null;
      latestRosterValueRequestBlobLocation?: string | null;
      latestRosterValueResponseBlobLocation?: string | null;
      latestSampleSimulationRequestBlobLocation?: string | null;
      latestSampleSimulationResponseBlobLocation?: string | null;
      latestDamRequestComboPlayerBlobLocation?: string | null;
      latestDamResponseComboPlayerBlobLocation?: string | null;
      latestTargetValueRequestBlobLocation?: string | null;
      latestTargetValueResponseBlobLocation?: string | null;
    };
  } | null;
};

export type DraftAssetsAtVersionQueryVariables = Exact<{
  draftId: Scalars["UUID"];
  version: Scalars["Long"];
}>;

export type DraftAssetsAtVersionQuery = {
  __typename?: "Query";
  draftScenarioAtPointInTime?: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    clubDraftAssets: Array<{
      __typename?: "DraftAsset";
      overall?: number | null;
      pickInRound?: number | null;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
    allDraftAssets: Array<{
      __typename?: "DraftAsset";
      overall?: number | null;
      pickInRound?: number | null;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
  } | null;
};

export type GetDraftScenarioSerializedQueryVariables = Exact<{
  draftId: Scalars["UUID"];
  version: Scalars["Long"];
}>;

export type GetDraftScenarioSerializedQuery = {
  __typename?: "Query";
  draftScenarioSerialized: string;
};

export type GetRosterValueResponseForDraftQueryVariables = Exact<{
  draftId: Scalars["UUID"];
}>;

export type GetRosterValueResponseForDraftQuery = {
  __typename?: "Query";
  rosterValueResponse: string;
};

export type GetSampleSimResponseForDraftQueryVariables = Exact<{
  draftId: Scalars["UUID"];
}>;

export type GetSampleSimResponseForDraftQuery = {
  __typename?: "Query";
  sampleSimResponse: string;
};

export type GetDamResponseForDraftQueryVariables = Exact<{
  draftId: Scalars["UUID"];
}>;

export type GetDamResponseForDraftQuery = {
  __typename?: "Query";
  damResponse: string;
};

export type GetDamComboPlayersResponseForDraftQueryVariables = Exact<{
  draftId: Scalars["UUID"];
}>;

export type GetDamComboPlayersResponseForDraftQuery = {
  __typename?: "Query";
  damComboPlayerResponse: string;
};

export type GetTargetValuesForDraftQueryVariables = Exact<{
  draftId: Scalars["UUID"];
}>;

export type GetTargetValuesForDraftQuery = {
  __typename?: "Query";
  targetValueResponse: string;
};

export type UpdateDraftScenarioConfigurationMutationVariables = Exact<{
  request: UpdateDraftScenarioConfigurationRequest;
}>;

export type UpdateDraftScenarioConfigurationMutation = {
  __typename?: "Mutation";
  draftScenarioUpdateConfiguration: {
    __typename?: "DraftScenario";
    id: string;
    configuration: {
      __typename?: "DraftScenarioConfiguration";
      bestPlayersCountPerPick: number;
      clubDemandPositionsNeedDepth: number;
      maxClubDemandForPick: number;
      maximumWorkspacePlayers: number;
      availabilityThreshold: number;
      projectedSeasons: number;
      recommendedPlayersCount: number;
      tradeBackWindow: number;
      tradeOpportunityThresholdMinGapypoc: number;
      tradeOpportunityThresholdMinPercent: number;
      numOwnerPlayers: number;
      targetPerformanceValueRank: number;
    };
  };
};

export type TriggerPerformanceGapRefreshMutationVariables = Exact<{
  draftId: Scalars["UUID"];
}>;

export type TriggerPerformanceGapRefreshMutation = {
  __typename?: "Mutation";
  draftScenarioTriggerPerformanceGapRefresh: {
    __typename?: "DraftScenario";
    id: string;
    status: DraftScenarioStatus;
    version: number;
    modelRunStatus: DraftScenarioDataStatus;
  };
};

export type SelectedPickPlayerFragment = {
  __typename?: "Player";
  id: string;
  jersey?: string | null;
  firstName: string;
  lastName: string;
  namePronunciation?: string | null;
  headshotUrl?: string | null;
  birthDate?: string | null;
  latestBirthDate?: string | null;
  hometownCity?: string | null;
  hometownState?: string | null;
  hometownCountry?: string | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  clubGradingPosition?: { __typename?: "Position"; shortName: string } | null;
  school?: {
    __typename?: "School";
    schoolCode?: string | null;
    schoolName: string;
  } | null;
  playerGrade?: {
    __typename?: "PlayerGrade";
    playerId: string;
    avgClubGapypocDollars: number;
    avgSageGapypocDollars: number;
    avgSumerScoutGapypocDollars: number;
    value: {
      __typename?: "PlayerGradeValue";
      value: string;
      gapypoc: number;
      gapypocDollars: number;
    };
    seasons: Array<{
      __typename?: "PlayerGradeSeason";
      season: number;
      gradeType?: GradeWeightEnum | null;
      lastUpdated: string;
      value: {
        __typename?: "PlayerGradeValue";
        value: string;
        gapypoc: number;
        gapypocDollars: number;
      };
    }>;
  } | null;
  projectedGamesMissed: Array<{
    __typename?: "PlayerProjectedGamesMissed";
    playerId: string;
    season: number;
    value: number;
    projectedGamesMissed: number;
    projectedGamesMissedOverride?: number | null;
  }>;
  favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
};

export type GetSelectedPickPlayerQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetSelectedPickPlayerQuery = {
  __typename?: "Query";
  playerById?: {
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    namePronunciation?: string | null;
    headshotUrl?: string | null;
    birthDate?: string | null;
    latestBirthDate?: string | null;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    clubGradingPosition?: { __typename?: "Position"; shortName: string } | null;
    school?: {
      __typename?: "School";
      schoolCode?: string | null;
      schoolName: string;
    } | null;
    playerGrade?: {
      __typename?: "PlayerGrade";
      playerId: string;
      avgClubGapypocDollars: number;
      avgSageGapypocDollars: number;
      avgSumerScoutGapypocDollars: number;
      value: {
        __typename?: "PlayerGradeValue";
        value: string;
        gapypoc: number;
        gapypocDollars: number;
      };
      seasons: Array<{
        __typename?: "PlayerGradeSeason";
        season: number;
        gradeType?: GradeWeightEnum | null;
        lastUpdated: string;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
      }>;
    } | null;
    projectedGamesMissed: Array<{
      __typename?: "PlayerProjectedGamesMissed";
      playerId: string;
      season: number;
      value: number;
      projectedGamesMissed: number;
      projectedGamesMissedOverride?: number | null;
    }>;
    favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
  } | null;
};

export type DraftScenarioAssetFieldsFragment = {
  __typename?: "DraftScenario";
  id: string;
  version: number;
  lastUpdated: string;
  clubDraftAssets: Array<{
    __typename?: "DraftAsset";
    overall?: number | null;
    pickInRound?: number | null;
    round: number;
    season: number;
    club: { __typename?: "Club"; id: string };
    originalClub: { __typename?: "Club"; id: string };
  }>;
  allDraftAssets: Array<{
    __typename?: "DraftAsset";
    overall?: number | null;
    pickInRound?: number | null;
    round: number;
    season: number;
    club: { __typename?: "Club"; id: string };
    originalClub: { __typename?: "Club"; id: string };
  }>;
};

export type GetDraftAssetsQueryVariables = Exact<{
  draftId: Scalars["UUID"];
}>;

export type GetDraftAssetsQuery = {
  __typename?: "Query";
  draftScenarioById: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    clubDraftAssets: Array<{
      __typename?: "DraftAsset";
      overall?: number | null;
      pickInRound?: number | null;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
    allDraftAssets: Array<{
      __typename?: "DraftAsset";
      overall?: number | null;
      pickInRound?: number | null;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
  };
};

export type GetDraftLastUpdatedQueryVariables = Exact<{
  draftId: Scalars["UUID"];
}>;

export type GetDraftLastUpdatedQuery = {
  __typename?: "Query";
  draftScenarioById: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    modelRunStatus: DraftScenarioDataStatus;
  };
};

export type GetEvaluatedDraftPicksQueryVariables = Exact<{
  draftId: Scalars["UUID"];
}>;

export type GetEvaluatedDraftPicksQuery = {
  __typename?: "Query";
  draftScenarioById: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    evaluatedDraftPicks: Array<{
      __typename?: "EvaluatedDraftPick";
      isInAvailabilityWindow: boolean;
      isTradeOpportunity: boolean;
      clubRecommendedPlayers: Array<{
        __typename?: "ClubDraftablePlayerAtPick";
        projectedRosterValueDollars?: number | null;
        marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
        performanceValue?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
        rosterValueAdded?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
        availability?: {
          __typename?: "Availability";
          availabilityPercentage: number;
          draftPick: {
            __typename?: "CurrentSeasonDraftAsset";
            overall: number;
            pickInRound: number;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string; clubCode: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        } | null;
        availabilityAtClubsNextPick?: {
          __typename?: "Availability";
          availabilityPercentage: number;
          draftPick: {
            __typename?: "CurrentSeasonDraftAsset";
            overall: number;
            pickInRound: number;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string; clubCode: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        } | null;
        player: {
          __typename?: "Player";
          id: string;
          firstName: string;
          lastName: string;
          height?: number | null;
          weight?: number | null;
          headshotUrl?: string | null;
          school?: {
            __typename?: "School";
            id: string;
            schoolName: string;
            schoolCode?: string | null;
          } | null;
          playerGrade?: {
            __typename?: "PlayerGrade";
            playerId: string;
            avgClubGapypocDollars: number;
            avgSageGapypocDollars: number;
            avgSumerScoutGapypocDollars: number;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
            seasons: Array<{
              __typename?: "PlayerGradeSeason";
              season: number;
              gradeType?: GradeWeightEnum | null;
              lastUpdated: string;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
            }>;
          } | null;
          workouts: Array<{
            __typename?: "WorkoutPlayerMeasure";
            value: number;
            measureType: { __typename?: "MeasureType"; name: string };
          }>;
          favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
          projectedGamesMissed: Array<{
            __typename?: "PlayerProjectedGamesMissed";
            value: number;
            season: number;
            projectedGamesMissed: number;
            projectedGamesMissedOverride?: number | null;
          }>;
          clubGradingPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
          sumerAtlasPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
          sumerGeneralPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
        };
      }>;
      clubValue?: {
        __typename?: "ClubPickValue";
        backingPlayers: Array<{
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        }>;
        value: { __typename?: "Value"; gapypoc: number; dollars: number };
      } | null;
      currentOwnerValue?: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      } | null;
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
      leagueBestAvailablePlayers: Array<{
        __typename?: "DraftablePlayerAtPick";
        player: {
          __typename?: "Player";
          id: string;
          firstName: string;
          lastName: string;
          sumerGeneralPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
          clubGradingPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
        };
        marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
        availability?: {
          __typename?: "Availability";
          availabilityPercentage: number;
          draftPick: {
            __typename?: "CurrentSeasonDraftAsset";
            overall: number;
            pickInRound: number;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string; clubCode: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        } | null;
      }>;
      leagueValue?: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      } | null;
      marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
      ownerMostLikelyPlayers: Array<{
        __typename?: "DraftablePlayerAtPick";
        player: {
          __typename?: "Player";
          id: string;
          firstName: string;
          lastName: string;
          sumerGeneralPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
          clubGradingPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
        };
        marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
        availability?: {
          __typename?: "Availability";
          availabilityPercentage: number;
          draftPick: {
            __typename?: "CurrentSeasonDraftAsset";
            overall: number;
            pickInRound: number;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string; clubCode: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        } | null;
      }>;
      teamNeeds: {
        __typename?: "DraftScenarioTeamNeeds";
        positionalNeeds: Array<{
          __typename?: "DraftScenarioPositionalNeed";
          isFulfilled: boolean;
          rank: number;
          sumerGeneralPositionShortName: string;
        }>;
      };
      topClubDemandForPick: Array<{
        __typename?: "Club";
        id: string;
        clubCode: string;
        clubName: string;
      }>;
    }>;
  };
};

export type GetSearchIndexStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSearchIndexStatusQuery = {
  __typename?: "Query";
  indexStatus: {
    __typename?: "SearchIndexStatus";
    playersDocumentCount: number;
    playersDatabaseCount: number;
    schoolsDocumentCount: number;
    schoolsDatabaseCount: number;
    clubsDocumentCount: number;
    clubsDatabaseCount: number;
  };
};

export type ResetPlayerSearchIndexMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResetPlayerSearchIndexMutation = {
  __typename?: "Mutation";
  resetPlayerSearchIndices: boolean;
};

export type GetClubsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClubsQuery = {
  __typename?: "Query";
  clubs: Array<{
    __typename?: "Club";
    id: string;
    clubCode: string;
    clubName: string;
    conference: string;
    division: string;
    isActive: boolean;
    primaryColor?: string | null;
    secondaryColor?: string | null;
    logoUrl: string;
    nickName: string;
  }>;
};

export type ClubFragment = {
  __typename?: "Club";
  id: string;
  clubCode: string;
  clubName: string;
  clubRosterUrl: string;
  conference: string;
  division: string;
  outdoorStadium?: boolean | null;
  isActive: boolean;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  timeZone?: string | null;
  currentCoach: string;
  location: string;
  logoUrl: string;
  nickName: string;
  venue: string;
  yearEstablished: number;
};

export type GetClubQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetClubQuery = {
  __typename?: "Query";
  clubById?: {
    __typename?: "Club";
    id: string;
    clubCode: string;
    clubName: string;
    clubRosterUrl: string;
    conference: string;
    division: string;
    outdoorStadium?: boolean | null;
    isActive: boolean;
    primaryColor?: string | null;
    secondaryColor?: string | null;
    timeZone?: string | null;
    currentCoach: string;
    location: string;
    logoUrl: string;
    nickName: string;
    venue: string;
    yearEstablished: number;
  } | null;
};

export type GetClubIpAuditAttributesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetClubIpAuditAttributesQuery = {
  __typename?: "Query";
  clubIpAuditAttributes: string;
};

export type CreateFavoriteMutationVariables = Exact<{
  request: CreateFavoriteRequest;
}>;

export type CreateFavoriteMutation = {
  __typename?: "Mutation";
  createFavorite: { __typename?: "PlayerFavorite"; playerId: string };
};

export type DeleteFavoriteMutationVariables = Exact<{
  request: DeleteFavoriteRequest;
}>;

export type DeleteFavoriteMutation = {
  __typename?: "Mutation";
  deleteFavorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
};

export type CreateDraftMutationVariables = Exact<{
  request: CreateDraftScenarioRequest;
}>;

export type CreateDraftMutation = {
  __typename?: "Mutation";
  draftScenarioCreate: {
    __typename?: "DraftScenario";
    id: string;
    season: number;
    draftType: DraftTypeEnum;
  };
};

export type DraftScenarioCreateMutationVariables = Exact<{
  request: CreateDraftScenarioRequest;
}>;

export type DraftScenarioCreateMutation = {
  __typename?: "Mutation";
  draftScenarioCreate: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    season: number;
    draftType: DraftTypeEnum;
  };
};

export type DraftScenarioTradePicksMutationVariables = Exact<{
  request: ExecuteTradeRequest;
}>;

export type DraftScenarioTradePicksMutation = {
  __typename?: "Mutation";
  draftScenarioExecuteTrade: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    executedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
    pinnedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
  };
};

export type PinTradeMutationVariables = Exact<{
  request: PinTradeRequest;
}>;

export type PinTradeMutation = {
  __typename?: "Mutation";
  draftScenarioPinTrade: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    pinnedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
  };
};

export type UnpinTradeMutationVariables = Exact<{
  request: UnpinTradeRequest;
}>;

export type UnpinTradeMutation = {
  __typename?: "Mutation";
  draftScenarioUnpinTrade: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    pinnedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
  };
};

export type TradeChartValuesFragment = {
  __typename?: "TradeChartValues";
  jimmyJohnsonTradeValue: {
    __typename?: "JimmyJohnsonTradeValue";
    netPoints: number;
    offeringPoints: number;
    receivingPoints: number;
  };
  otcTradeValue: {
    __typename?: "OtcTradeValue";
    netPoints: number;
    offeringPoints: number;
    receivingPoints: number;
  };
  evaluatedDraftAssets: Array<{
    __typename?: "EvaluatedDraftAsset";
    jimmyJohnsonPoints: number;
    otcPoints: number;
    draftAsset: {
      __typename?: "DraftAsset";
      overall?: number | null;
      pickInRound?: number | null;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string };
      originalClub: { __typename?: "Club"; id: string };
    };
  }>;
};

export type DraftScenarioDraftPlayersMutationVariables = Exact<{
  request: DraftPlayersRequest;
}>;

export type DraftScenarioDraftPlayersMutation = {
  __typename?: "Mutation";
  draftScenarioDraftPlayers: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    modelRunStatus: DraftScenarioDataStatus;
    activePick?: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    } | null;
    clubDraftAssets: Array<{
      __typename?: "DraftAsset";
      overall?: number | null;
      pickInRound?: number | null;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
    draftOrder: Array<{
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
    draftedPlayers: Array<{
      __typename?: "DraftedPlayer";
      player: {
        __typename?: "Player";
        id: string;
        gsisId?: string | null;
        collegeGsisId?: string | null;
        firstName: string;
        lastName: string;
        eligibilityYear?: number | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        birthDate?: string | null;
        headshotUrl?: string | null;
        schoolId?: string | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolCode?: string | null;
          schoolName: string;
        } | null;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        grades: Array<{
          __typename?: "Grade";
          playerId: string;
          gradeType: GradeTypeEnum;
          value: number;
        }>;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          projectedGamesMissed: number;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    recommendedWorkspacePlayers: Array<{
      __typename?: "RecommendedWorkspacePlayer";
      clubGradingPositionShortName?: string | null;
      workspacePlayer: {
        __typename?: "WorkspacePlayer";
        player: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
        recommendedComboPlayers: Array<{
          __typename?: "ComboDraftablePlayer";
          isTopCombo: boolean;
          playerAtPick: {
            __typename?: "ClubDraftablePlayerAtPick";
            projectedRosterValueDollars?: number | null;
            marketValue: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            };
            performanceValue?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            rosterValueAdded?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            availability?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            availabilityAtClubsNextPick?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            player: {
              __typename?: "Player";
              id: string;
              firstName: string;
              lastName: string;
              height?: number | null;
              weight?: number | null;
              headshotUrl?: string | null;
              school?: {
                __typename?: "School";
                id: string;
                schoolName: string;
                schoolCode?: string | null;
              } | null;
              playerGrade?: {
                __typename?: "PlayerGrade";
                playerId: string;
                avgClubGapypocDollars: number;
                avgSageGapypocDollars: number;
                avgSumerScoutGapypocDollars: number;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
                seasons: Array<{
                  __typename?: "PlayerGradeSeason";
                  season: number;
                  gradeType?: GradeWeightEnum | null;
                  lastUpdated: string;
                  value: {
                    __typename?: "PlayerGradeValue";
                    value: string;
                    gapypoc: number;
                    gapypocDollars: number;
                  };
                }>;
              } | null;
              workouts: Array<{
                __typename?: "WorkoutPlayerMeasure";
                value: number;
                measureType: { __typename?: "MeasureType"; name: string };
              }>;
              favorite?: {
                __typename?: "PlayerFavorite";
                playerId: string;
              } | null;
              projectedGamesMissed: Array<{
                __typename?: "PlayerProjectedGamesMissed";
                value: number;
                season: number;
                projectedGamesMissed: number;
                projectedGamesMissedOverride?: number | null;
              }>;
              clubGradingPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerAtlasPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerGeneralPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
            };
          };
        }>;
      };
    }>;
  };
};

export type SimulateToPickMutationVariables = Exact<{
  request: SimulateToPickRequest;
}>;

export type SimulateToPickMutation = {
  __typename?: "Mutation";
  draftScenarioSimulateToPick: {
    __typename?: "DraftScenario";
    id: string;
    lastUpdated: string;
    version: number;
    modelRunStatus: DraftScenarioDataStatus;
    draftedPlayers: Array<{
      __typename?: "DraftedPlayer";
      player: {
        __typename?: "Player";
        id: string;
        gsisId?: string | null;
        collegeGsisId?: string | null;
        firstName: string;
        lastName: string;
        eligibilityYear?: number | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        birthDate?: string | null;
        headshotUrl?: string | null;
        schoolId?: string | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolCode?: string | null;
          schoolName: string;
        } | null;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        grades: Array<{
          __typename?: "Grade";
          playerId: string;
          gradeType: GradeTypeEnum;
          value: number;
        }>;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          projectedGamesMissed: number;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    recommendedWorkspacePlayers: Array<{
      __typename?: "RecommendedWorkspacePlayer";
      clubGradingPositionShortName?: string | null;
      workspacePlayer: {
        __typename?: "WorkspacePlayer";
        player: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
        recommendedComboPlayers: Array<{
          __typename?: "ComboDraftablePlayer";
          isTopCombo: boolean;
          playerAtPick: {
            __typename?: "ClubDraftablePlayerAtPick";
            projectedRosterValueDollars?: number | null;
            marketValue: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            };
            performanceValue?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            rosterValueAdded?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            availability?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            availabilityAtClubsNextPick?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            player: {
              __typename?: "Player";
              id: string;
              firstName: string;
              lastName: string;
              height?: number | null;
              weight?: number | null;
              headshotUrl?: string | null;
              school?: {
                __typename?: "School";
                id: string;
                schoolName: string;
                schoolCode?: string | null;
              } | null;
              playerGrade?: {
                __typename?: "PlayerGrade";
                playerId: string;
                avgClubGapypocDollars: number;
                avgSageGapypocDollars: number;
                avgSumerScoutGapypocDollars: number;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
                seasons: Array<{
                  __typename?: "PlayerGradeSeason";
                  season: number;
                  gradeType?: GradeWeightEnum | null;
                  lastUpdated: string;
                  value: {
                    __typename?: "PlayerGradeValue";
                    value: string;
                    gapypoc: number;
                    gapypocDollars: number;
                  };
                }>;
              } | null;
              workouts: Array<{
                __typename?: "WorkoutPlayerMeasure";
                value: number;
                measureType: { __typename?: "MeasureType"; name: string };
              }>;
              favorite?: {
                __typename?: "PlayerFavorite";
                playerId: string;
              } | null;
              projectedGamesMissed: Array<{
                __typename?: "PlayerProjectedGamesMissed";
                value: number;
                season: number;
                projectedGamesMissed: number;
                projectedGamesMissedOverride?: number | null;
              }>;
              clubGradingPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerAtlasPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerGeneralPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
            };
          };
        }>;
      };
    }>;
    draftOrder: Array<{
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
  };
};

export type RewindToPickMutationVariables = Exact<{
  request: RewindToPickRequest;
}>;

export type RewindToPickMutation = {
  __typename?: "Mutation";
  draftScenarioRewindToPick: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    modelRunStatus: DraftScenarioDataStatus;
    draftedPlayers: Array<{
      __typename?: "DraftedPlayer";
      player: { __typename?: "Player"; id: string };
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    draftOrder: Array<{
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
    recommendedWorkspacePlayers: Array<{
      __typename?: "RecommendedWorkspacePlayer";
      clubGradingPositionShortName?: string | null;
      workspacePlayer: {
        __typename?: "WorkspacePlayer";
        player: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
        recommendedComboPlayers: Array<{
          __typename?: "ComboDraftablePlayer";
          isTopCombo: boolean;
          playerAtPick: {
            __typename?: "ClubDraftablePlayerAtPick";
            projectedRosterValueDollars?: number | null;
            marketValue: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            };
            performanceValue?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            rosterValueAdded?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            availability?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            availabilityAtClubsNextPick?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            player: {
              __typename?: "Player";
              id: string;
              firstName: string;
              lastName: string;
              height?: number | null;
              weight?: number | null;
              headshotUrl?: string | null;
              school?: {
                __typename?: "School";
                id: string;
                schoolName: string;
                schoolCode?: string | null;
              } | null;
              playerGrade?: {
                __typename?: "PlayerGrade";
                playerId: string;
                avgClubGapypocDollars: number;
                avgSageGapypocDollars: number;
                avgSumerScoutGapypocDollars: number;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
                seasons: Array<{
                  __typename?: "PlayerGradeSeason";
                  season: number;
                  gradeType?: GradeWeightEnum | null;
                  lastUpdated: string;
                  value: {
                    __typename?: "PlayerGradeValue";
                    value: string;
                    gapypoc: number;
                    gapypocDollars: number;
                  };
                }>;
              } | null;
              workouts: Array<{
                __typename?: "WorkoutPlayerMeasure";
                value: number;
                measureType: { __typename?: "MeasureType"; name: string };
              }>;
              favorite?: {
                __typename?: "PlayerFavorite";
                playerId: string;
              } | null;
              projectedGamesMissed: Array<{
                __typename?: "PlayerProjectedGamesMissed";
                value: number;
                season: number;
                projectedGamesMissed: number;
                projectedGamesMissedOverride?: number | null;
              }>;
              clubGradingPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerAtlasPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerGeneralPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
            };
          };
        }>;
      };
    }>;
  };
};

export type UndoLastActionMutationVariables = Exact<{
  request: UndoLastActionRequest;
}>;

export type UndoLastActionMutation = {
  __typename?: "Mutation";
  draftScenarioUndoLastAction: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    modelRunStatus: DraftScenarioDataStatus;
    draftedPlayers: Array<{
      __typename?: "DraftedPlayer";
      player: { __typename?: "Player"; id: string };
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    draftOrder: Array<{
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
    recommendedWorkspacePlayers: Array<{
      __typename?: "RecommendedWorkspacePlayer";
      clubGradingPositionShortName?: string | null;
      workspacePlayer: {
        __typename?: "WorkspacePlayer";
        player: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
        recommendedComboPlayers: Array<{
          __typename?: "ComboDraftablePlayer";
          isTopCombo: boolean;
          playerAtPick: {
            __typename?: "ClubDraftablePlayerAtPick";
            projectedRosterValueDollars?: number | null;
            marketValue: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            };
            performanceValue?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            rosterValueAdded?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            availability?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            availabilityAtClubsNextPick?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            player: {
              __typename?: "Player";
              id: string;
              firstName: string;
              lastName: string;
              height?: number | null;
              weight?: number | null;
              headshotUrl?: string | null;
              school?: {
                __typename?: "School";
                id: string;
                schoolName: string;
                schoolCode?: string | null;
              } | null;
              playerGrade?: {
                __typename?: "PlayerGrade";
                playerId: string;
                avgClubGapypocDollars: number;
                avgSageGapypocDollars: number;
                avgSumerScoutGapypocDollars: number;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
                seasons: Array<{
                  __typename?: "PlayerGradeSeason";
                  season: number;
                  gradeType?: GradeWeightEnum | null;
                  lastUpdated: string;
                  value: {
                    __typename?: "PlayerGradeValue";
                    value: string;
                    gapypoc: number;
                    gapypocDollars: number;
                  };
                }>;
              } | null;
              workouts: Array<{
                __typename?: "WorkoutPlayerMeasure";
                value: number;
                measureType: { __typename?: "MeasureType"; name: string };
              }>;
              favorite?: {
                __typename?: "PlayerFavorite";
                playerId: string;
              } | null;
              projectedGamesMissed: Array<{
                __typename?: "PlayerProjectedGamesMissed";
                value: number;
                season: number;
                projectedGamesMissed: number;
                projectedGamesMissedOverride?: number | null;
              }>;
              clubGradingPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerAtlasPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerGeneralPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
            };
          };
        }>;
      };
    }>;
  };
};

export type RenameDraftMutationVariables = Exact<{
  request: RenameDraftScenarioRequest;
}>;

export type RenameDraftMutation = {
  __typename?: "Mutation";
  draftScenarioRename: {
    __typename?: "DraftScenario";
    id: string;
    name: string;
    version: number;
    lastUpdated: string;
  };
};

export type DiscardDraftMutationVariables = Exact<{
  request: DiscardDraftScenarioRequest;
}>;

export type DiscardDraftMutation = {
  __typename?: "Mutation";
  draftScenarioDiscard: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    status: DraftScenarioStatus;
  };
};

export type SetWorkspacePlayersRecommendedMutationVariables = Exact<{
  request: SetWorkspacePlayersRecommendedRequest;
}>;

export type SetWorkspacePlayersRecommendedMutation = {
  __typename?: "Mutation";
  draftScenarioSetWorkspacePlayersRecommended: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    currentWorkspacePlayers: Array<{
      __typename?: "CurrentWorkspacePlayer";
      slot: number;
      workspacePlayer: {
        __typename?: "WorkspacePlayer";
        comboPlayerModelRequestId: string;
        comboPlayerModelRunStatus: DraftScenarioDataStatus;
        player: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
        recommendedComboPlayers: Array<{
          __typename?: "ComboDraftablePlayer";
          isTopCombo: boolean;
          playerAtPick: {
            __typename?: "ClubDraftablePlayerAtPick";
            projectedRosterValueDollars?: number | null;
            marketValue: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            };
            performanceValue?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            rosterValueAdded?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            availability?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            availabilityAtClubsNextPick?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            player: {
              __typename?: "Player";
              id: string;
              firstName: string;
              lastName: string;
              height?: number | null;
              weight?: number | null;
              headshotUrl?: string | null;
              school?: {
                __typename?: "School";
                id: string;
                schoolName: string;
                schoolCode?: string | null;
              } | null;
              playerGrade?: {
                __typename?: "PlayerGrade";
                playerId: string;
                avgClubGapypocDollars: number;
                avgSageGapypocDollars: number;
                avgSumerScoutGapypocDollars: number;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
                seasons: Array<{
                  __typename?: "PlayerGradeSeason";
                  season: number;
                  gradeType?: GradeWeightEnum | null;
                  lastUpdated: string;
                  value: {
                    __typename?: "PlayerGradeValue";
                    value: string;
                    gapypoc: number;
                    gapypocDollars: number;
                  };
                }>;
              } | null;
              workouts: Array<{
                __typename?: "WorkoutPlayerMeasure";
                value: number;
                measureType: { __typename?: "MeasureType"; name: string };
              }>;
              favorite?: {
                __typename?: "PlayerFavorite";
                playerId: string;
              } | null;
              projectedGamesMissed: Array<{
                __typename?: "PlayerProjectedGamesMissed";
                value: number;
                season: number;
                projectedGamesMissed: number;
                projectedGamesMissedOverride?: number | null;
              }>;
              clubGradingPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerAtlasPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerGeneralPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
            };
          };
        }>;
      };
    }>;
  };
};

export type SetWorkspacePlayersCustomMutationVariables = Exact<{
  request: SetWorkspacePlayersCustomRequest;
}>;

export type SetWorkspacePlayersCustomMutation = {
  __typename?: "Mutation";
  draftScenarioSetWorkspacePlayersCustom: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    currentWorkspacePlayers: Array<{
      __typename?: "CurrentWorkspacePlayer";
      slot: number;
      workspacePlayer: {
        __typename?: "WorkspacePlayer";
        comboPlayerModelRequestId: string;
        comboPlayerModelRunStatus: DraftScenarioDataStatus;
        player: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
        recommendedComboPlayers: Array<{
          __typename?: "ComboDraftablePlayer";
          isTopCombo: boolean;
          playerAtPick: {
            __typename?: "ClubDraftablePlayerAtPick";
            projectedRosterValueDollars?: number | null;
            marketValue: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            };
            performanceValue?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            rosterValueAdded?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            availability?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            availabilityAtClubsNextPick?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            player: {
              __typename?: "Player";
              id: string;
              firstName: string;
              lastName: string;
              height?: number | null;
              weight?: number | null;
              headshotUrl?: string | null;
              school?: {
                __typename?: "School";
                id: string;
                schoolName: string;
                schoolCode?: string | null;
              } | null;
              playerGrade?: {
                __typename?: "PlayerGrade";
                playerId: string;
                avgClubGapypocDollars: number;
                avgSageGapypocDollars: number;
                avgSumerScoutGapypocDollars: number;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
                seasons: Array<{
                  __typename?: "PlayerGradeSeason";
                  season: number;
                  gradeType?: GradeWeightEnum | null;
                  lastUpdated: string;
                  value: {
                    __typename?: "PlayerGradeValue";
                    value: string;
                    gapypoc: number;
                    gapypocDollars: number;
                  };
                }>;
              } | null;
              workouts: Array<{
                __typename?: "WorkoutPlayerMeasure";
                value: number;
                measureType: { __typename?: "MeasureType"; name: string };
              }>;
              favorite?: {
                __typename?: "PlayerFavorite";
                playerId: string;
              } | null;
              projectedGamesMissed: Array<{
                __typename?: "PlayerProjectedGamesMissed";
                value: number;
                season: number;
                projectedGamesMissed: number;
                projectedGamesMissedOverride?: number | null;
              }>;
              clubGradingPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerAtlasPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerGeneralPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
            };
          };
        }>;
      };
    }>;
  };
};

export type AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutationVariables =
  Exact<{
    request: AssumeDoNotDraftPlayerRequest;
  }>;

export type AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutation = {
  __typename?: "Mutation";
  addLocalDraftScenarioAssumptionDoNotDraftPlayer: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    modelRunStatus: DraftScenarioDataStatus;
    assumptions: Array<{
      __typename?: "Assumption";
      constraint: Constraint;
      description: string;
      id: string;
      isValid: boolean;
      name: string;
      playerId?: string | null;
      subject: Subject;
    }>;
  };
};

export type RemoveLocalDraftScenarioAssumptionMutationVariables = Exact<{
  request: RemoveAssumptionRequest;
}>;

export type RemoveLocalDraftScenarioAssumptionMutation = {
  __typename?: "Mutation";
  removeLocalDraftScenarioAssumption: {
    __typename?: "DraftScenario";
    id: string;
    version: number;
    lastUpdated: string;
    modelRunStatus: DraftScenarioDataStatus;
    assumptions: Array<{
      __typename?: "Assumption";
      constraint: Constraint;
      description: string;
      id: string;
      isValid: boolean;
      name: string;
      playerId?: string | null;
      subject: Subject;
    }>;
  };
};

export type AssignPickToClubMutationVariables = Exact<{
  request: AssignPickToClubRequest;
}>;

export type AssignPickToClubMutation = {
  __typename?: "Mutation";
  assignPickToClub: {
    __typename?: "DraftScenario";
    id: string;
    draftOrder: Array<{
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      club: { __typename?: "Club"; id: string; clubName: string };
    }>;
  };
};

export type GetDraftQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetDraftQuery = {
  __typename?: "Query";
  draftScenarioById: {
    __typename?: "DraftScenario";
    id: string;
    draftType: DraftTypeEnum;
    createdAt: string;
    lastUpdated: string;
  };
};

export type GetMyDraftsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyDraftsQuery = {
  __typename?: "Query";
  myDraftScenarios: Array<{
    __typename?: "DraftScenario";
    id: string;
    draftType: DraftTypeEnum;
    season: number;
    status: DraftScenarioStatus;
    name: string;
    createdAt: string;
    createdBy: string;
    lastUpdated: string;
    netRosterValueChangeDollars?: number | null;
    currentRosterValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    startingRosterValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
  }>;
};

export type GetFilteredDraftsQueryVariables = Exact<{
  createdBy?: InputMaybe<Array<Scalars["UUID"]> | Scalars["UUID"]>;
}>;

export type GetFilteredDraftsQuery = {
  __typename?: "Query";
  allDraftScenarios: Array<{
    __typename?: "DraftScenario";
    id: string;
    draftType: DraftTypeEnum;
    season: number;
    status: DraftScenarioStatus;
    name: string;
    createdAt: string;
    createdBy: string;
  }>;
};

export type DraftablePlayerAtPickFieldsFragment = {
  __typename?: "DraftablePlayerAtPick";
  player: {
    __typename?: "Player";
    id: string;
    firstName: string;
    lastName: string;
    sumerGeneralPosition?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
    clubGradingPosition?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
  };
  marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
  availability?: {
    __typename?: "Availability";
    availabilityPercentage: number;
    draftPick: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    };
  } | null;
};

export type PositionFieldsFragment = {
  __typename?: "Player";
  clubGradingPosition?: {
    __typename?: "Position";
    id: string;
    shortName: string;
  } | null;
  sumerAtlasPosition?: {
    __typename?: "Position";
    id: string;
    shortName: string;
  } | null;
  sumerGeneralPosition?: {
    __typename?: "Position";
    id: string;
    shortName: string;
  } | null;
};

export type ValueFieldsFragment = {
  __typename?: "Value";
  gapypoc: number;
  dollars: number;
};

export type DraftAssetFieldsFragment = {
  __typename?: "DraftAsset";
  overall?: number | null;
  pickInRound?: number | null;
  round: number;
  season: number;
  club: { __typename?: "Club"; id: string };
  originalClub: { __typename?: "Club"; id: string };
};

export type CurrentSeasonDraftAssetFieldsFragment = {
  __typename?: "CurrentSeasonDraftAsset";
  overall: number;
  pickInRound: number;
  round: number;
  season: number;
  club: { __typename?: "Club"; id: string; clubCode: string };
  originalClub: { __typename?: "Club"; id: string };
};

export type DraftPickFieldsFragment = {
  __typename?: "DraftPick";
  overall?: number | null;
  number?: number | null;
  round?: number | null;
  season: number;
};

export type TradeAssetFieldsFragment = {
  __typename?: "TradeAsset";
  draftPick?: {
    __typename?: "DraftAsset";
    overall?: number | null;
    pickInRound?: number | null;
    round: number;
    season: number;
    club: { __typename?: "Club"; id: string };
    originalClub: { __typename?: "Club"; id: string };
  } | null;
  tradedPlayer?: {
    __typename?: "TradedPlayer";
    player: { __typename?: "Player"; firstName: string; lastName: string };
  } | null;
};

export type GetDraftScenarioByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetDraftScenarioByIdQuery = {
  __typename?: "Query";
  draftScenarioById: {
    __typename?: "DraftScenario";
    id: string;
    draftType: DraftTypeEnum;
    season: number;
    name: string;
    version: number;
    lastUpdated: string;
    latestModelRunRequestId: string;
    modelRunStatus: DraftScenarioDataStatus;
    status: DraftScenarioStatus;
    netRosterValueChangeDollars?: number | null;
    clubGradingPositionRecommendedSort?: string | null;
    workspaceSortMode: WorkspaceSortMode;
    activePick?: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    } | null;
    currentRosterValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    startingRosterValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    performanceGaps: Array<{
      __typename?: "PerformanceGap";
      performanceValueAddedDollars: number;
      remainingGapDollars: number;
      sideOfBall?: SideOfBall | null;
      sumerGeneralPositionShortName: string;
      currentPerformanceValue: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      };
      startingPerformanceValue: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      };
      targetPerformanceValue: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      };
    }>;
    assumptions: Array<{
      __typename?: "Assumption";
      constraint: Constraint;
      contractMinimumEndDate?: any | null;
      description: string;
      draftRoundMinimum?: number | null;
      id: string;
      isValid: boolean;
      name: string;
      playerId?: string | null;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        overall?: number | null;
        number?: number | null;
        round?: number | null;
        season: number;
      } | null;
      draftPickTrade?: Array<{
        __typename?: "DraftPickTransaction";
        clubId: string;
        draftPicksGained: Array<{
          __typename?: "DraftPick";
          overall?: number | null;
          number?: number | null;
          round?: number | null;
          season: number;
        }>;
        draftPicksLost: Array<{
          __typename?: "DraftPick";
          overall?: number | null;
          number?: number | null;
          round?: number | null;
          season: number;
        }>;
      }> | null;
    }>;
    clubTeamNeeds: {
      __typename?: "DraftScenarioTeamNeeds";
      positionalNeeds: Array<{
        __typename?: "DraftScenarioPositionalNeed";
        isFulfilled: boolean;
        rank: number;
        sumerGeneralPositionShortName: string;
      }>;
    };
    allTeamNeeds: Array<{
      __typename?: "DraftScenarioTeamNeeds";
      club: {
        __typename?: "Club";
        id: string;
        clubCode: string;
        clubName: string;
      };
      positionalNeeds: Array<{
        __typename?: "DraftScenarioPositionalNeed";
        isFulfilled: boolean;
        rank: number;
        sumerGeneralPositionShortName: string;
      }>;
    }>;
    club: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    };
    clubsNextPick?: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    } | null;
    draftedPlayers: Array<{
      __typename?: "DraftedPlayer";
      player: {
        __typename?: "Player";
        id: string;
        gsisId?: string | null;
        collegeGsisId?: string | null;
        firstName: string;
        lastName: string;
        eligibilityYear?: number | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        birthDate?: string | null;
        headshotUrl?: string | null;
        schoolId?: string | null;
        playerGrade?: {
          __typename?: "PlayerGrade";
          playerId: string;
          avgClubGapypocDollars: number;
          avgSageGapypocDollars: number;
          avgSumerScoutGapypocDollars: number;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
          seasons: Array<{
            __typename?: "PlayerGradeSeason";
            season: number;
            gradeType?: GradeWeightEnum | null;
            lastUpdated: string;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
          }>;
        } | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolCode?: string | null;
          schoolName: string;
        } | null;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        grades: Array<{
          __typename?: "Grade";
          playerId: string;
          gradeType: GradeTypeEnum;
          value: number;
        }>;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          projectedGamesMissed: number;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    clubDraftedPlayers: Array<{
      __typename?: "DraftedPlayer";
      player: {
        __typename?: "Player";
        id: string;
        gsisId?: string | null;
        collegeGsisId?: string | null;
        firstName: string;
        lastName: string;
        eligibilityYear?: number | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        birthDate?: string | null;
        headshotUrl?: string | null;
        schoolId?: string | null;
        playerGrade?: {
          __typename?: "PlayerGrade";
          playerId: string;
          avgClubGapypocDollars: number;
          avgSageGapypocDollars: number;
          avgSumerScoutGapypocDollars: number;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
          seasons: Array<{
            __typename?: "PlayerGradeSeason";
            season: number;
            gradeType?: GradeWeightEnum | null;
            lastUpdated: string;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
          }>;
        } | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolCode?: string | null;
          schoolName: string;
        } | null;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        grades: Array<{
          __typename?: "Grade";
          playerId: string;
          gradeType: GradeTypeEnum;
          value: number;
        }>;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          projectedGamesMissed: number;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    clubDraftAssets: Array<{
      __typename?: "DraftAsset";
      overall?: number | null;
      pickInRound?: number | null;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
    draftOrder: Array<{
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
    tradeOpportunities: Array<{
      __typename?: "PickTradeOpportunity";
      clubValue: {
        __typename?: "ClubPickValue";
        backingPlayers: Array<{
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        }>;
        value: { __typename?: "Value"; gapypoc: number; dollars: number };
      };
      ownerValue: { __typename?: "Value"; gapypoc: number; dollars: number };
      pickToTrade: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    currentWorkspacePlayers: Array<{
      __typename?: "CurrentWorkspacePlayer";
      slot: number;
      workspacePlayer: {
        __typename?: "WorkspacePlayer";
        comboPlayerModelRequestId: string;
        comboPlayerModelRunStatus: DraftScenarioDataStatus;
        player: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
        recommendedComboPlayers: Array<{
          __typename?: "ComboDraftablePlayer";
          isTopCombo: boolean;
          playerAtPick: {
            __typename?: "ClubDraftablePlayerAtPick";
            projectedRosterValueDollars?: number | null;
            marketValue: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            };
            performanceValue?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            rosterValueAdded?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            availability?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            availabilityAtClubsNextPick?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            player: {
              __typename?: "Player";
              id: string;
              firstName: string;
              lastName: string;
              height?: number | null;
              weight?: number | null;
              headshotUrl?: string | null;
              school?: {
                __typename?: "School";
                id: string;
                schoolName: string;
                schoolCode?: string | null;
              } | null;
              playerGrade?: {
                __typename?: "PlayerGrade";
                playerId: string;
                avgClubGapypocDollars: number;
                avgSageGapypocDollars: number;
                avgSumerScoutGapypocDollars: number;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
                seasons: Array<{
                  __typename?: "PlayerGradeSeason";
                  season: number;
                  gradeType?: GradeWeightEnum | null;
                  lastUpdated: string;
                  value: {
                    __typename?: "PlayerGradeValue";
                    value: string;
                    gapypoc: number;
                    gapypocDollars: number;
                  };
                }>;
              } | null;
              workouts: Array<{
                __typename?: "WorkoutPlayerMeasure";
                value: number;
                measureType: { __typename?: "MeasureType"; name: string };
              }>;
              favorite?: {
                __typename?: "PlayerFavorite";
                playerId: string;
              } | null;
              projectedGamesMissed: Array<{
                __typename?: "PlayerProjectedGamesMissed";
                value: number;
                season: number;
                projectedGamesMissed: number;
                projectedGamesMissedOverride?: number | null;
              }>;
              clubGradingPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerAtlasPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerGeneralPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
            };
          };
        }>;
      };
    }>;
    recommendedWorkspacePlayers: Array<{
      __typename?: "RecommendedWorkspacePlayer";
      clubGradingPositionShortName?: string | null;
      workspacePlayer: {
        __typename?: "WorkspacePlayer";
        player: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
        recommendedComboPlayers: Array<{
          __typename?: "ComboDraftablePlayer";
          isTopCombo: boolean;
          playerAtPick: {
            __typename?: "ClubDraftablePlayerAtPick";
            projectedRosterValueDollars?: number | null;
            marketValue: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            };
            performanceValue?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            rosterValueAdded?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            availability?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            availabilityAtClubsNextPick?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            player: {
              __typename?: "Player";
              id: string;
              firstName: string;
              lastName: string;
              height?: number | null;
              weight?: number | null;
              headshotUrl?: string | null;
              school?: {
                __typename?: "School";
                id: string;
                schoolName: string;
                schoolCode?: string | null;
              } | null;
              playerGrade?: {
                __typename?: "PlayerGrade";
                playerId: string;
                avgClubGapypocDollars: number;
                avgSageGapypocDollars: number;
                avgSumerScoutGapypocDollars: number;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
                seasons: Array<{
                  __typename?: "PlayerGradeSeason";
                  season: number;
                  gradeType?: GradeWeightEnum | null;
                  lastUpdated: string;
                  value: {
                    __typename?: "PlayerGradeValue";
                    value: string;
                    gapypoc: number;
                    gapypocDollars: number;
                  };
                }>;
              } | null;
              workouts: Array<{
                __typename?: "WorkoutPlayerMeasure";
                value: number;
                measureType: { __typename?: "MeasureType"; name: string };
              }>;
              favorite?: {
                __typename?: "PlayerFavorite";
                playerId: string;
              } | null;
              projectedGamesMissed: Array<{
                __typename?: "PlayerProjectedGamesMissed";
                value: number;
                season: number;
                projectedGamesMissed: number;
                projectedGamesMissedOverride?: number | null;
              }>;
              clubGradingPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerAtlasPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerGeneralPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
            };
          };
        }>;
      };
    }>;
    executedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
    pinnedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
    applicablePinnedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
    modelMetadata: {
      __typename?: "ModelMetadata";
      latestDamRequestBlobLocation?: string | null;
      latestDamResponseBlobLocation?: string | null;
      latestRosterValueRequestBlobLocation?: string | null;
      latestRosterValueResponseBlobLocation?: string | null;
      latestSampleSimulationRequestBlobLocation?: string | null;
      latestSampleSimulationResponseBlobLocation?: string | null;
      latestDamRequestComboPlayerBlobLocation?: string | null;
      latestDamResponseComboPlayerBlobLocation?: string | null;
      latestTargetValueRequestBlobLocation?: string | null;
      latestTargetValueResponseBlobLocation?: string | null;
    };
  };
};

export type DraftScenarioFieldsFragment = {
  __typename?: "DraftScenario";
  id: string;
  draftType: DraftTypeEnum;
  season: number;
  name: string;
  version: number;
  lastUpdated: string;
  latestModelRunRequestId: string;
  modelRunStatus: DraftScenarioDataStatus;
  status: DraftScenarioStatus;
  netRosterValueChangeDollars?: number | null;
  clubGradingPositionRecommendedSort?: string | null;
  workspaceSortMode: WorkspaceSortMode;
  activePick?: {
    __typename?: "CurrentSeasonDraftAsset";
    overall: number;
    pickInRound: number;
    round: number;
    season: number;
    club: { __typename?: "Club"; id: string; clubCode: string };
    originalClub: { __typename?: "Club"; id: string };
  } | null;
  currentRosterValue?: {
    __typename?: "Value";
    gapypoc: number;
    dollars: number;
  } | null;
  startingRosterValue?: {
    __typename?: "Value";
    gapypoc: number;
    dollars: number;
  } | null;
  performanceGaps: Array<{
    __typename?: "PerformanceGap";
    performanceValueAddedDollars: number;
    remainingGapDollars: number;
    sideOfBall?: SideOfBall | null;
    sumerGeneralPositionShortName: string;
    currentPerformanceValue: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    };
    startingPerformanceValue: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    };
    targetPerformanceValue: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    };
  }>;
  assumptions: Array<{
    __typename?: "Assumption";
    constraint: Constraint;
    contractMinimumEndDate?: any | null;
    description: string;
    draftRoundMinimum?: number | null;
    id: string;
    isValid: boolean;
    name: string;
    playerId?: string | null;
    subject: Subject;
    contracts: Array<{ __typename?: "NYM"; id: string }>;
    draftPick?: {
      __typename?: "DraftPick";
      overall?: number | null;
      number?: number | null;
      round?: number | null;
      season: number;
    } | null;
    draftPickTrade?: Array<{
      __typename?: "DraftPickTransaction";
      clubId: string;
      draftPicksGained: Array<{
        __typename?: "DraftPick";
        overall?: number | null;
        number?: number | null;
        round?: number | null;
        season: number;
      }>;
      draftPicksLost: Array<{
        __typename?: "DraftPick";
        overall?: number | null;
        number?: number | null;
        round?: number | null;
        season: number;
      }>;
    }> | null;
  }>;
  clubTeamNeeds: {
    __typename?: "DraftScenarioTeamNeeds";
    positionalNeeds: Array<{
      __typename?: "DraftScenarioPositionalNeed";
      isFulfilled: boolean;
      rank: number;
      sumerGeneralPositionShortName: string;
    }>;
  };
  allTeamNeeds: Array<{
    __typename?: "DraftScenarioTeamNeeds";
    club: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    };
    positionalNeeds: Array<{
      __typename?: "DraftScenarioPositionalNeed";
      isFulfilled: boolean;
      rank: number;
      sumerGeneralPositionShortName: string;
    }>;
  }>;
  club: { __typename?: "Club"; id: string; clubCode: string; clubName: string };
  clubsNextPick?: {
    __typename?: "CurrentSeasonDraftAsset";
    overall: number;
    pickInRound: number;
    round: number;
    season: number;
    club: { __typename?: "Club"; id: string; clubCode: string };
    originalClub: { __typename?: "Club"; id: string };
  } | null;
  draftedPlayers: Array<{
    __typename?: "DraftedPlayer";
    player: {
      __typename?: "Player";
      id: string;
      gsisId?: string | null;
      collegeGsisId?: string | null;
      firstName: string;
      lastName: string;
      eligibilityYear?: number | null;
      height?: number | null;
      weight?: number | null;
      speed?: number | null;
      birthDate?: string | null;
      headshotUrl?: string | null;
      schoolId?: string | null;
      playerGrade?: {
        __typename?: "PlayerGrade";
        playerId: string;
        avgClubGapypocDollars: number;
        avgSageGapypocDollars: number;
        avgSumerScoutGapypocDollars: number;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
        seasons: Array<{
          __typename?: "PlayerGradeSeason";
          season: number;
          gradeType?: GradeWeightEnum | null;
          lastUpdated: string;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
        }>;
      } | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolCode?: string | null;
        schoolName: string;
      } | null;
      favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
      grades: Array<{
        __typename?: "Grade";
        playerId: string;
        gradeType: GradeTypeEnum;
        value: number;
      }>;
      projectedGamesMissed: Array<{
        __typename?: "PlayerProjectedGamesMissed";
        projectedGamesMissed: number;
      }>;
      clubGradingPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerAtlasPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
    };
    draftPick: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    };
  }>;
  clubDraftedPlayers: Array<{
    __typename?: "DraftedPlayer";
    player: {
      __typename?: "Player";
      id: string;
      gsisId?: string | null;
      collegeGsisId?: string | null;
      firstName: string;
      lastName: string;
      eligibilityYear?: number | null;
      height?: number | null;
      weight?: number | null;
      speed?: number | null;
      birthDate?: string | null;
      headshotUrl?: string | null;
      schoolId?: string | null;
      playerGrade?: {
        __typename?: "PlayerGrade";
        playerId: string;
        avgClubGapypocDollars: number;
        avgSageGapypocDollars: number;
        avgSumerScoutGapypocDollars: number;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
        seasons: Array<{
          __typename?: "PlayerGradeSeason";
          season: number;
          gradeType?: GradeWeightEnum | null;
          lastUpdated: string;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
        }>;
      } | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolCode?: string | null;
        schoolName: string;
      } | null;
      favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
      grades: Array<{
        __typename?: "Grade";
        playerId: string;
        gradeType: GradeTypeEnum;
        value: number;
      }>;
      projectedGamesMissed: Array<{
        __typename?: "PlayerProjectedGamesMissed";
        projectedGamesMissed: number;
      }>;
      clubGradingPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerAtlasPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
    };
    draftPick: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    };
  }>;
  clubDraftAssets: Array<{
    __typename?: "DraftAsset";
    overall?: number | null;
    pickInRound?: number | null;
    round: number;
    season: number;
    club: { __typename?: "Club"; id: string };
    originalClub: { __typename?: "Club"; id: string };
  }>;
  draftOrder: Array<{
    __typename?: "CurrentSeasonDraftAsset";
    overall: number;
    pickInRound: number;
    round: number;
    season: number;
    club: { __typename?: "Club"; id: string; clubCode: string };
    originalClub: { __typename?: "Club"; id: string };
  }>;
  tradeOpportunities: Array<{
    __typename?: "PickTradeOpportunity";
    clubValue: {
      __typename?: "ClubPickValue";
      backingPlayers: Array<{
        __typename?: "ClubDraftablePlayerAtPick";
        projectedRosterValueDollars?: number | null;
        marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
        performanceValue?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
        rosterValueAdded?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
        availability?: {
          __typename?: "Availability";
          availabilityPercentage: number;
          draftPick: {
            __typename?: "CurrentSeasonDraftAsset";
            overall: number;
            pickInRound: number;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string; clubCode: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        } | null;
        availabilityAtClubsNextPick?: {
          __typename?: "Availability";
          availabilityPercentage: number;
          draftPick: {
            __typename?: "CurrentSeasonDraftAsset";
            overall: number;
            pickInRound: number;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string; clubCode: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        } | null;
        player: {
          __typename?: "Player";
          id: string;
          firstName: string;
          lastName: string;
          height?: number | null;
          weight?: number | null;
          headshotUrl?: string | null;
          school?: {
            __typename?: "School";
            id: string;
            schoolName: string;
            schoolCode?: string | null;
          } | null;
          playerGrade?: {
            __typename?: "PlayerGrade";
            playerId: string;
            avgClubGapypocDollars: number;
            avgSageGapypocDollars: number;
            avgSumerScoutGapypocDollars: number;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
            seasons: Array<{
              __typename?: "PlayerGradeSeason";
              season: number;
              gradeType?: GradeWeightEnum | null;
              lastUpdated: string;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
            }>;
          } | null;
          workouts: Array<{
            __typename?: "WorkoutPlayerMeasure";
            value: number;
            measureType: { __typename?: "MeasureType"; name: string };
          }>;
          favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
          projectedGamesMissed: Array<{
            __typename?: "PlayerProjectedGamesMissed";
            value: number;
            season: number;
            projectedGamesMissed: number;
            projectedGamesMissedOverride?: number | null;
          }>;
          clubGradingPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
          sumerAtlasPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
          sumerGeneralPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
        };
      }>;
      value: { __typename?: "Value"; gapypoc: number; dollars: number };
    };
    ownerValue: { __typename?: "Value"; gapypoc: number; dollars: number };
    pickToTrade: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    };
  }>;
  currentWorkspacePlayers: Array<{
    __typename?: "CurrentWorkspacePlayer";
    slot: number;
    workspacePlayer: {
      __typename?: "WorkspacePlayer";
      comboPlayerModelRequestId: string;
      comboPlayerModelRunStatus: DraftScenarioDataStatus;
      player: {
        __typename?: "ClubDraftablePlayerAtPick";
        projectedRosterValueDollars?: number | null;
        marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
        performanceValue?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
        rosterValueAdded?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
        availability?: {
          __typename?: "Availability";
          availabilityPercentage: number;
          draftPick: {
            __typename?: "CurrentSeasonDraftAsset";
            overall: number;
            pickInRound: number;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string; clubCode: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        } | null;
        availabilityAtClubsNextPick?: {
          __typename?: "Availability";
          availabilityPercentage: number;
          draftPick: {
            __typename?: "CurrentSeasonDraftAsset";
            overall: number;
            pickInRound: number;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string; clubCode: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        } | null;
        player: {
          __typename?: "Player";
          id: string;
          firstName: string;
          lastName: string;
          height?: number | null;
          weight?: number | null;
          headshotUrl?: string | null;
          school?: {
            __typename?: "School";
            id: string;
            schoolName: string;
            schoolCode?: string | null;
          } | null;
          playerGrade?: {
            __typename?: "PlayerGrade";
            playerId: string;
            avgClubGapypocDollars: number;
            avgSageGapypocDollars: number;
            avgSumerScoutGapypocDollars: number;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
            seasons: Array<{
              __typename?: "PlayerGradeSeason";
              season: number;
              gradeType?: GradeWeightEnum | null;
              lastUpdated: string;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
            }>;
          } | null;
          workouts: Array<{
            __typename?: "WorkoutPlayerMeasure";
            value: number;
            measureType: { __typename?: "MeasureType"; name: string };
          }>;
          favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
          projectedGamesMissed: Array<{
            __typename?: "PlayerProjectedGamesMissed";
            value: number;
            season: number;
            projectedGamesMissed: number;
            projectedGamesMissedOverride?: number | null;
          }>;
          clubGradingPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
          sumerAtlasPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
          sumerGeneralPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
        };
      };
      recommendedComboPlayers: Array<{
        __typename?: "ComboDraftablePlayer";
        isTopCombo: boolean;
        playerAtPick: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
      }>;
    };
  }>;
  recommendedWorkspacePlayers: Array<{
    __typename?: "RecommendedWorkspacePlayer";
    clubGradingPositionShortName?: string | null;
    workspacePlayer: {
      __typename?: "WorkspacePlayer";
      player: {
        __typename?: "ClubDraftablePlayerAtPick";
        projectedRosterValueDollars?: number | null;
        marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
        performanceValue?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
        rosterValueAdded?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
        availability?: {
          __typename?: "Availability";
          availabilityPercentage: number;
          draftPick: {
            __typename?: "CurrentSeasonDraftAsset";
            overall: number;
            pickInRound: number;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string; clubCode: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        } | null;
        availabilityAtClubsNextPick?: {
          __typename?: "Availability";
          availabilityPercentage: number;
          draftPick: {
            __typename?: "CurrentSeasonDraftAsset";
            overall: number;
            pickInRound: number;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string; clubCode: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        } | null;
        player: {
          __typename?: "Player";
          id: string;
          firstName: string;
          lastName: string;
          height?: number | null;
          weight?: number | null;
          headshotUrl?: string | null;
          school?: {
            __typename?: "School";
            id: string;
            schoolName: string;
            schoolCode?: string | null;
          } | null;
          playerGrade?: {
            __typename?: "PlayerGrade";
            playerId: string;
            avgClubGapypocDollars: number;
            avgSageGapypocDollars: number;
            avgSumerScoutGapypocDollars: number;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
            seasons: Array<{
              __typename?: "PlayerGradeSeason";
              season: number;
              gradeType?: GradeWeightEnum | null;
              lastUpdated: string;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
            }>;
          } | null;
          workouts: Array<{
            __typename?: "WorkoutPlayerMeasure";
            value: number;
            measureType: { __typename?: "MeasureType"; name: string };
          }>;
          favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
          projectedGamesMissed: Array<{
            __typename?: "PlayerProjectedGamesMissed";
            value: number;
            season: number;
            projectedGamesMissed: number;
            projectedGamesMissedOverride?: number | null;
          }>;
          clubGradingPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
          sumerAtlasPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
          sumerGeneralPosition?: {
            __typename?: "Position";
            id: string;
            shortName: string;
          } | null;
        };
      };
      recommendedComboPlayers: Array<{
        __typename?: "ComboDraftablePlayer";
        isTopCombo: boolean;
        playerAtPick: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
      }>;
    };
  }>;
  executedTrades: Array<{
    __typename?: "Trade";
    id: number;
    leagueTradeId?: number | null;
    lastUpdated: string;
    status: TradeStatus;
    tradePartners: Array<{
      __typename?: "TradePartner";
      club: { __typename?: "Club"; id: string; clubCode: string };
      offering: Array<{
        __typename?: "TradeAsset";
        draftPick?: {
          __typename?: "DraftAsset";
          overall?: number | null;
          pickInRound?: number | null;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string };
          originalClub: { __typename?: "Club"; id: string };
        } | null;
        tradedPlayer?: {
          __typename?: "TradedPlayer";
          player: {
            __typename?: "Player";
            firstName: string;
            lastName: string;
          };
        } | null;
      }>;
      receiving: Array<{
        __typename?: "TradeAsset";
        draftPick?: {
          __typename?: "DraftAsset";
          overall?: number | null;
          pickInRound?: number | null;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string };
          originalClub: { __typename?: "Club"; id: string };
        } | null;
        tradedPlayer?: {
          __typename?: "TradedPlayer";
          player: {
            __typename?: "Player";
            firstName: string;
            lastName: string;
          };
        } | null;
      }>;
    }>;
    tradeValue?: {
      __typename?: "TradeValue";
      sumerTradeValueStatus: DraftScenarioDataStatus;
      evaluatedForClub: { __typename?: "Club"; clubCode: string };
      sumerTradeValue?: {
        __typename?: "SumerTradeValue";
        netCostDollars: number;
        netValueDollars?: number | null;
        tradeValueDollars: number;
        costIncurring: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        };
        costOffloading: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        };
        valueOffering?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
        valueReceiving?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
      } | null;
      tradeChartValues: {
        __typename?: "TradeChartValues";
        jimmyJohnsonTradeValue: {
          __typename?: "JimmyJohnsonTradeValue";
          netPoints: number;
          offeringPoints: number;
          receivingPoints: number;
        };
        otcTradeValue: {
          __typename?: "OtcTradeValue";
          netPoints: number;
          offeringPoints: number;
          receivingPoints: number;
        };
        evaluatedDraftAssets: Array<{
          __typename?: "EvaluatedDraftAsset";
          jimmyJohnsonPoints: number;
          otcPoints: number;
          draftAsset: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        }>;
      };
    } | null;
  }>;
  pinnedTrades: Array<{
    __typename?: "Trade";
    id: number;
    leagueTradeId?: number | null;
    lastUpdated: string;
    status: TradeStatus;
    tradePartners: Array<{
      __typename?: "TradePartner";
      club: { __typename?: "Club"; id: string; clubCode: string };
      offering: Array<{
        __typename?: "TradeAsset";
        draftPick?: {
          __typename?: "DraftAsset";
          overall?: number | null;
          pickInRound?: number | null;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string };
          originalClub: { __typename?: "Club"; id: string };
        } | null;
        tradedPlayer?: {
          __typename?: "TradedPlayer";
          player: {
            __typename?: "Player";
            firstName: string;
            lastName: string;
          };
        } | null;
      }>;
      receiving: Array<{
        __typename?: "TradeAsset";
        draftPick?: {
          __typename?: "DraftAsset";
          overall?: number | null;
          pickInRound?: number | null;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string };
          originalClub: { __typename?: "Club"; id: string };
        } | null;
        tradedPlayer?: {
          __typename?: "TradedPlayer";
          player: {
            __typename?: "Player";
            firstName: string;
            lastName: string;
          };
        } | null;
      }>;
    }>;
    tradeValue?: {
      __typename?: "TradeValue";
      sumerTradeValueStatus: DraftScenarioDataStatus;
      evaluatedForClub: { __typename?: "Club"; clubCode: string };
      sumerTradeValue?: {
        __typename?: "SumerTradeValue";
        netCostDollars: number;
        netValueDollars?: number | null;
        tradeValueDollars: number;
        costIncurring: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        };
        costOffloading: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        };
        valueOffering?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
        valueReceiving?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
      } | null;
      tradeChartValues: {
        __typename?: "TradeChartValues";
        jimmyJohnsonTradeValue: {
          __typename?: "JimmyJohnsonTradeValue";
          netPoints: number;
          offeringPoints: number;
          receivingPoints: number;
        };
        otcTradeValue: {
          __typename?: "OtcTradeValue";
          netPoints: number;
          offeringPoints: number;
          receivingPoints: number;
        };
        evaluatedDraftAssets: Array<{
          __typename?: "EvaluatedDraftAsset";
          jimmyJohnsonPoints: number;
          otcPoints: number;
          draftAsset: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        }>;
      };
    } | null;
  }>;
  applicablePinnedTrades: Array<{
    __typename?: "Trade";
    id: number;
    leagueTradeId?: number | null;
    lastUpdated: string;
    status: TradeStatus;
    tradePartners: Array<{
      __typename?: "TradePartner";
      club: { __typename?: "Club"; id: string; clubCode: string };
      offering: Array<{
        __typename?: "TradeAsset";
        draftPick?: {
          __typename?: "DraftAsset";
          overall?: number | null;
          pickInRound?: number | null;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string };
          originalClub: { __typename?: "Club"; id: string };
        } | null;
        tradedPlayer?: {
          __typename?: "TradedPlayer";
          player: {
            __typename?: "Player";
            firstName: string;
            lastName: string;
          };
        } | null;
      }>;
      receiving: Array<{
        __typename?: "TradeAsset";
        draftPick?: {
          __typename?: "DraftAsset";
          overall?: number | null;
          pickInRound?: number | null;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string };
          originalClub: { __typename?: "Club"; id: string };
        } | null;
        tradedPlayer?: {
          __typename?: "TradedPlayer";
          player: {
            __typename?: "Player";
            firstName: string;
            lastName: string;
          };
        } | null;
      }>;
    }>;
    tradeValue?: {
      __typename?: "TradeValue";
      sumerTradeValueStatus: DraftScenarioDataStatus;
      evaluatedForClub: { __typename?: "Club"; clubCode: string };
      sumerTradeValue?: {
        __typename?: "SumerTradeValue";
        netCostDollars: number;
        netValueDollars?: number | null;
        tradeValueDollars: number;
        costIncurring: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        };
        costOffloading: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        };
        valueOffering?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
        valueReceiving?: {
          __typename?: "Value";
          gapypoc: number;
          dollars: number;
        } | null;
      } | null;
      tradeChartValues: {
        __typename?: "TradeChartValues";
        jimmyJohnsonTradeValue: {
          __typename?: "JimmyJohnsonTradeValue";
          netPoints: number;
          offeringPoints: number;
          receivingPoints: number;
        };
        otcTradeValue: {
          __typename?: "OtcTradeValue";
          netPoints: number;
          offeringPoints: number;
          receivingPoints: number;
        };
        evaluatedDraftAssets: Array<{
          __typename?: "EvaluatedDraftAsset";
          jimmyJohnsonPoints: number;
          otcPoints: number;
          draftAsset: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          };
        }>;
      };
    } | null;
  }>;
  modelMetadata: {
    __typename?: "ModelMetadata";
    latestDamRequestBlobLocation?: string | null;
    latestDamResponseBlobLocation?: string | null;
    latestRosterValueRequestBlobLocation?: string | null;
    latestRosterValueResponseBlobLocation?: string | null;
    latestSampleSimulationRequestBlobLocation?: string | null;
    latestSampleSimulationResponseBlobLocation?: string | null;
    latestDamRequestComboPlayerBlobLocation?: string | null;
    latestDamResponseComboPlayerBlobLocation?: string | null;
    latestTargetValueRequestBlobLocation?: string | null;
    latestTargetValueResponseBlobLocation?: string | null;
  };
};

export type ClubDraftablePlayerAtPickFieldsFragment = {
  __typename?: "ClubDraftablePlayerAtPick";
  projectedRosterValueDollars?: number | null;
  marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
  performanceValue?: {
    __typename?: "Value";
    gapypoc: number;
    dollars: number;
  } | null;
  rosterValueAdded?: {
    __typename?: "Value";
    gapypoc: number;
    dollars: number;
  } | null;
  availability?: {
    __typename?: "Availability";
    availabilityPercentage: number;
    draftPick: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    };
  } | null;
  availabilityAtClubsNextPick?: {
    __typename?: "Availability";
    availabilityPercentage: number;
    draftPick: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    };
  } | null;
  player: {
    __typename?: "Player";
    id: string;
    firstName: string;
    lastName: string;
    height?: number | null;
    weight?: number | null;
    headshotUrl?: string | null;
    school?: {
      __typename?: "School";
      id: string;
      schoolName: string;
      schoolCode?: string | null;
    } | null;
    playerGrade?: {
      __typename?: "PlayerGrade";
      playerId: string;
      avgClubGapypocDollars: number;
      avgSageGapypocDollars: number;
      avgSumerScoutGapypocDollars: number;
      value: {
        __typename?: "PlayerGradeValue";
        value: string;
        gapypoc: number;
        gapypocDollars: number;
      };
      seasons: Array<{
        __typename?: "PlayerGradeSeason";
        season: number;
        gradeType?: GradeWeightEnum | null;
        lastUpdated: string;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
      }>;
    } | null;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      value: number;
      measureType: { __typename?: "MeasureType"; name: string };
    }>;
    favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
    projectedGamesMissed: Array<{
      __typename?: "PlayerProjectedGamesMissed";
      value: number;
      season: number;
      projectedGamesMissed: number;
      projectedGamesMissedOverride?: number | null;
    }>;
    clubGradingPosition?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
    sumerAtlasPosition?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
  };
};

export type ComboPlayerFieldsFragment = {
  __typename?: "ComboDraftablePlayer";
  isTopCombo: boolean;
  playerAtPick: {
    __typename?: "ClubDraftablePlayerAtPick";
    projectedRosterValueDollars?: number | null;
    marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
    performanceValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    rosterValueAdded?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    availability?: {
      __typename?: "Availability";
      availabilityPercentage: number;
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    } | null;
    availabilityAtClubsNextPick?: {
      __typename?: "Availability";
      availabilityPercentage: number;
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    } | null;
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      height?: number | null;
      weight?: number | null;
      headshotUrl?: string | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolName: string;
        schoolCode?: string | null;
      } | null;
      playerGrade?: {
        __typename?: "PlayerGrade";
        playerId: string;
        avgClubGapypocDollars: number;
        avgSageGapypocDollars: number;
        avgSumerScoutGapypocDollars: number;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
        seasons: Array<{
          __typename?: "PlayerGradeSeason";
          season: number;
          gradeType?: GradeWeightEnum | null;
          lastUpdated: string;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
        }>;
      } | null;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        value: number;
        measureType: { __typename?: "MeasureType"; name: string };
      }>;
      favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
      projectedGamesMissed: Array<{
        __typename?: "PlayerProjectedGamesMissed";
        value: number;
        season: number;
        projectedGamesMissed: number;
        projectedGamesMissedOverride?: number | null;
      }>;
      clubGradingPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerAtlasPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
    };
  };
};

export type TradeFieldsFragment = {
  __typename?: "Trade";
  id: number;
  leagueTradeId?: number | null;
  lastUpdated: string;
  status: TradeStatus;
  tradePartners: Array<{
    __typename?: "TradePartner";
    club: { __typename?: "Club"; id: string; clubCode: string };
    offering: Array<{
      __typename?: "TradeAsset";
      draftPick?: {
        __typename?: "DraftAsset";
        overall?: number | null;
        pickInRound?: number | null;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string };
        originalClub: { __typename?: "Club"; id: string };
      } | null;
      tradedPlayer?: {
        __typename?: "TradedPlayer";
        player: { __typename?: "Player"; firstName: string; lastName: string };
      } | null;
    }>;
    receiving: Array<{
      __typename?: "TradeAsset";
      draftPick?: {
        __typename?: "DraftAsset";
        overall?: number | null;
        pickInRound?: number | null;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string };
        originalClub: { __typename?: "Club"; id: string };
      } | null;
      tradedPlayer?: {
        __typename?: "TradedPlayer";
        player: { __typename?: "Player"; firstName: string; lastName: string };
      } | null;
    }>;
  }>;
  tradeValue?: {
    __typename?: "TradeValue";
    sumerTradeValueStatus: DraftScenarioDataStatus;
    evaluatedForClub: { __typename?: "Club"; clubCode: string };
    sumerTradeValue?: {
      __typename?: "SumerTradeValue";
      netCostDollars: number;
      netValueDollars?: number | null;
      tradeValueDollars: number;
      costIncurring: { __typename?: "Value"; gapypoc: number; dollars: number };
      costOffloading: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      };
      valueOffering?: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      } | null;
      valueReceiving?: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      } | null;
    } | null;
    tradeChartValues: {
      __typename?: "TradeChartValues";
      jimmyJohnsonTradeValue: {
        __typename?: "JimmyJohnsonTradeValue";
        netPoints: number;
        offeringPoints: number;
        receivingPoints: number;
      };
      otcTradeValue: {
        __typename?: "OtcTradeValue";
        netPoints: number;
        offeringPoints: number;
        receivingPoints: number;
      };
      evaluatedDraftAssets: Array<{
        __typename?: "EvaluatedDraftAsset";
        jimmyJohnsonPoints: number;
        otcPoints: number;
        draftAsset: {
          __typename?: "DraftAsset";
          overall?: number | null;
          pickInRound?: number | null;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string };
          originalClub: { __typename?: "Club"; id: string };
        };
      }>;
    };
  } | null;
};

export type PositionalNeedsFieldsFragment = {
  __typename?: "DraftScenarioPositionalNeed";
  isFulfilled: boolean;
  rank: number;
  sumerGeneralPositionShortName: string;
};

export type EvaluatedDraftPickFieldsFragment = {
  __typename?: "EvaluatedDraftPick";
  isInAvailabilityWindow: boolean;
  isTradeOpportunity: boolean;
  clubRecommendedPlayers: Array<{
    __typename?: "ClubDraftablePlayerAtPick";
    projectedRosterValueDollars?: number | null;
    marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
    performanceValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    rosterValueAdded?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    availability?: {
      __typename?: "Availability";
      availabilityPercentage: number;
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    } | null;
    availabilityAtClubsNextPick?: {
      __typename?: "Availability";
      availabilityPercentage: number;
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    } | null;
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      height?: number | null;
      weight?: number | null;
      headshotUrl?: string | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolName: string;
        schoolCode?: string | null;
      } | null;
      playerGrade?: {
        __typename?: "PlayerGrade";
        playerId: string;
        avgClubGapypocDollars: number;
        avgSageGapypocDollars: number;
        avgSumerScoutGapypocDollars: number;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
        seasons: Array<{
          __typename?: "PlayerGradeSeason";
          season: number;
          gradeType?: GradeWeightEnum | null;
          lastUpdated: string;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
        }>;
      } | null;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        value: number;
        measureType: { __typename?: "MeasureType"; name: string };
      }>;
      favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
      projectedGamesMissed: Array<{
        __typename?: "PlayerProjectedGamesMissed";
        value: number;
        season: number;
        projectedGamesMissed: number;
        projectedGamesMissedOverride?: number | null;
      }>;
      clubGradingPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerAtlasPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
    };
  }>;
  clubValue?: {
    __typename?: "ClubPickValue";
    backingPlayers: Array<{
      __typename?: "ClubDraftablePlayerAtPick";
      projectedRosterValueDollars?: number | null;
      marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
      performanceValue?: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      } | null;
      rosterValueAdded?: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      } | null;
      availability?: {
        __typename?: "Availability";
        availabilityPercentage: number;
        draftPick: {
          __typename?: "CurrentSeasonDraftAsset";
          overall: number;
          pickInRound: number;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string; clubCode: string };
          originalClub: { __typename?: "Club"; id: string };
        };
      } | null;
      availabilityAtClubsNextPick?: {
        __typename?: "Availability";
        availabilityPercentage: number;
        draftPick: {
          __typename?: "CurrentSeasonDraftAsset";
          overall: number;
          pickInRound: number;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string; clubCode: string };
          originalClub: { __typename?: "Club"; id: string };
        };
      } | null;
      player: {
        __typename?: "Player";
        id: string;
        firstName: string;
        lastName: string;
        height?: number | null;
        weight?: number | null;
        headshotUrl?: string | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolName: string;
          schoolCode?: string | null;
        } | null;
        playerGrade?: {
          __typename?: "PlayerGrade";
          playerId: string;
          avgClubGapypocDollars: number;
          avgSageGapypocDollars: number;
          avgSumerScoutGapypocDollars: number;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
          seasons: Array<{
            __typename?: "PlayerGradeSeason";
            season: number;
            gradeType?: GradeWeightEnum | null;
            lastUpdated: string;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
          }>;
        } | null;
        workouts: Array<{
          __typename?: "WorkoutPlayerMeasure";
          value: number;
          measureType: { __typename?: "MeasureType"; name: string };
        }>;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          value: number;
          season: number;
          projectedGamesMissed: number;
          projectedGamesMissedOverride?: number | null;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
    }>;
    value: { __typename?: "Value"; gapypoc: number; dollars: number };
  } | null;
  currentOwnerValue?: {
    __typename?: "Value";
    gapypoc: number;
    dollars: number;
  } | null;
  draftPick: {
    __typename?: "CurrentSeasonDraftAsset";
    overall: number;
    pickInRound: number;
    round: number;
    season: number;
    club: { __typename?: "Club"; id: string; clubCode: string };
    originalClub: { __typename?: "Club"; id: string };
  };
  leagueBestAvailablePlayers: Array<{
    __typename?: "DraftablePlayerAtPick";
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      sumerGeneralPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      clubGradingPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
    };
    marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
    availability?: {
      __typename?: "Availability";
      availabilityPercentage: number;
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    } | null;
  }>;
  leagueValue?: {
    __typename?: "Value";
    gapypoc: number;
    dollars: number;
  } | null;
  marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
  ownerMostLikelyPlayers: Array<{
    __typename?: "DraftablePlayerAtPick";
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      sumerGeneralPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      clubGradingPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
    };
    marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
    availability?: {
      __typename?: "Availability";
      availabilityPercentage: number;
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    } | null;
  }>;
  teamNeeds: {
    __typename?: "DraftScenarioTeamNeeds";
    positionalNeeds: Array<{
      __typename?: "DraftScenarioPositionalNeed";
      isFulfilled: boolean;
      rank: number;
      sumerGeneralPositionShortName: string;
    }>;
  };
  topClubDemandForPick: Array<{
    __typename?: "Club";
    id: string;
    clubCode: string;
    clubName: string;
  }>;
};

export type GetTradeChartValuesQueryVariables = Exact<{
  request: GetTradeChartValuesRequest;
}>;

export type GetTradeChartValuesQuery = {
  __typename?: "Query";
  tradeChartValues: {
    __typename?: "TradeChartValues";
    jimmyJohnsonTradeValue: {
      __typename?: "JimmyJohnsonTradeValue";
      netPoints: number;
      offeringPoints: number;
      receivingPoints: number;
    };
    otcTradeValue: {
      __typename?: "OtcTradeValue";
      netPoints: number;
      offeringPoints: number;
      receivingPoints: number;
    };
    evaluatedDraftAssets: Array<{
      __typename?: "EvaluatedDraftAsset";
      jimmyJohnsonPoints: number;
      otcPoints: number;
      draftAsset: {
        __typename?: "DraftAsset";
        overall?: number | null;
        pickInRound?: number | null;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
  };
};

export type TradeChartValuesFieldsFragment = {
  __typename?: "TradeChartValues";
  jimmyJohnsonTradeValue: {
    __typename?: "JimmyJohnsonTradeValue";
    netPoints: number;
    offeringPoints: number;
    receivingPoints: number;
  };
  otcTradeValue: {
    __typename?: "OtcTradeValue";
    netPoints: number;
    offeringPoints: number;
    receivingPoints: number;
  };
  evaluatedDraftAssets: Array<{
    __typename?: "EvaluatedDraftAsset";
    jimmyJohnsonPoints: number;
    otcPoints: number;
    draftAsset: {
      __typename?: "DraftAsset";
      overall?: number | null;
      pickInRound?: number | null;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string };
      originalClub: { __typename?: "Club"; id: string };
    };
  }>;
};

export type EvaluatedAssetFieldsFragment = {
  __typename?: "EvaluatedDraftAsset";
  jimmyJohnsonPoints: number;
  otcPoints: number;
  draftAsset: {
    __typename?: "DraftAsset";
    overall?: number | null;
    pickInRound?: number | null;
    round: number;
    season: number;
    club: { __typename?: "Club"; id: string };
    originalClub: { __typename?: "Club"; id: string };
  };
};

export type PlayerGradeFragment = {
  __typename?: "Grade";
  playerId: string;
  gradeType: GradeTypeEnum;
  value: number;
};

export type DraftPlayerFragment = {
  __typename?: "Player";
  id: string;
  gsisId?: string | null;
  collegeGsisId?: string | null;
  firstName: string;
  lastName: string;
  eligibilityYear?: number | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  birthDate?: string | null;
  headshotUrl?: string | null;
  schoolId?: string | null;
  school?: {
    __typename?: "School";
    id: string;
    schoolCode?: string | null;
    schoolName: string;
  } | null;
  favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
  grades: Array<{
    __typename?: "Grade";
    playerId: string;
    gradeType: GradeTypeEnum;
    value: number;
  }>;
  projectedGamesMissed: Array<{
    __typename?: "PlayerProjectedGamesMissed";
    projectedGamesMissed: number;
  }>;
  clubGradingPosition?: {
    __typename?: "Position";
    id: string;
    shortName: string;
  } | null;
  sumerAtlasPosition?: {
    __typename?: "Position";
    id: string;
    shortName: string;
  } | null;
  sumerGeneralPosition?: {
    __typename?: "Position";
    id: string;
    shortName: string;
  } | null;
};

export type PerformanceGapFieldsFragment = {
  __typename?: "PerformanceGap";
  performanceValueAddedDollars: number;
  remainingGapDollars: number;
  sideOfBall?: SideOfBall | null;
  sumerGeneralPositionShortName: string;
  currentPerformanceValue: {
    __typename?: "Value";
    gapypoc: number;
    dollars: number;
  };
  startingPerformanceValue: {
    __typename?: "Value";
    gapypoc: number;
    dollars: number;
  };
  targetPerformanceValue: {
    __typename?: "Value";
    gapypoc: number;
    dollars: number;
  };
};

export type WorkspacePlayerFieldsFragment = {
  __typename?: "WorkspacePlayer";
  comboPlayerModelRequestId: string;
  comboPlayerModelRunStatus: DraftScenarioDataStatus;
  player: {
    __typename?: "ClubDraftablePlayerAtPick";
    projectedRosterValueDollars?: number | null;
    marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
    performanceValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    rosterValueAdded?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    availability?: {
      __typename?: "Availability";
      availabilityPercentage: number;
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    } | null;
    availabilityAtClubsNextPick?: {
      __typename?: "Availability";
      availabilityPercentage: number;
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    } | null;
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      height?: number | null;
      weight?: number | null;
      headshotUrl?: string | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolName: string;
        schoolCode?: string | null;
      } | null;
      playerGrade?: {
        __typename?: "PlayerGrade";
        playerId: string;
        avgClubGapypocDollars: number;
        avgSageGapypocDollars: number;
        avgSumerScoutGapypocDollars: number;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
        seasons: Array<{
          __typename?: "PlayerGradeSeason";
          season: number;
          gradeType?: GradeWeightEnum | null;
          lastUpdated: string;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
        }>;
      } | null;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        value: number;
        measureType: { __typename?: "MeasureType"; name: string };
      }>;
      favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
      projectedGamesMissed: Array<{
        __typename?: "PlayerProjectedGamesMissed";
        value: number;
        season: number;
        projectedGamesMissed: number;
        projectedGamesMissedOverride?: number | null;
      }>;
      clubGradingPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerAtlasPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
    };
  };
  recommendedComboPlayers: Array<{
    __typename?: "ComboDraftablePlayer";
    isTopCombo: boolean;
    playerAtPick: {
      __typename?: "ClubDraftablePlayerAtPick";
      projectedRosterValueDollars?: number | null;
      marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
      performanceValue?: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      } | null;
      rosterValueAdded?: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      } | null;
      availability?: {
        __typename?: "Availability";
        availabilityPercentage: number;
        draftPick: {
          __typename?: "CurrentSeasonDraftAsset";
          overall: number;
          pickInRound: number;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string; clubCode: string };
          originalClub: { __typename?: "Club"; id: string };
        };
      } | null;
      availabilityAtClubsNextPick?: {
        __typename?: "Availability";
        availabilityPercentage: number;
        draftPick: {
          __typename?: "CurrentSeasonDraftAsset";
          overall: number;
          pickInRound: number;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string; clubCode: string };
          originalClub: { __typename?: "Club"; id: string };
        };
      } | null;
      player: {
        __typename?: "Player";
        id: string;
        firstName: string;
        lastName: string;
        height?: number | null;
        weight?: number | null;
        headshotUrl?: string | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolName: string;
          schoolCode?: string | null;
        } | null;
        playerGrade?: {
          __typename?: "PlayerGrade";
          playerId: string;
          avgClubGapypocDollars: number;
          avgSageGapypocDollars: number;
          avgSumerScoutGapypocDollars: number;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
          seasons: Array<{
            __typename?: "PlayerGradeSeason";
            season: number;
            gradeType?: GradeWeightEnum | null;
            lastUpdated: string;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
          }>;
        } | null;
        workouts: Array<{
          __typename?: "WorkoutPlayerMeasure";
          value: number;
          measureType: { __typename?: "MeasureType"; name: string };
        }>;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          value: number;
          season: number;
          projectedGamesMissed: number;
          projectedGamesMissedOverride?: number | null;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
    };
  }>;
};

export type GetDraftDashboardPlayerListWithFiltersQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  searchText?: InputMaybe<Scalars["String"]>;
  positionFilters?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  draftYear: Scalars["Int"];
  showFavorites: Scalars["Boolean"];
  sortCriteria: DraftPlayerSortCriteriaEnum;
  includePlayers?: InputMaybe<Array<Scalars["UUID"]> | Scalars["UUID"]>;
  excludePlayers?: InputMaybe<Array<Scalars["UUID"]> | Scalars["UUID"]>;
}>;

export type GetDraftDashboardPlayerListWithFiltersQuery = {
  __typename?: "Query";
  draftDashboardPlayerListWithFilters?: {
    __typename?: "DraftDashboardPlayerListWithFiltersConnection";
    totalCount: number;
    edges?: Array<{
      __typename?: "DraftDashboardPlayerListWithFiltersEdge";
      node: {
        __typename?: "Player";
        id: string;
        gsisId?: string | null;
        collegeGsisId?: string | null;
        firstName: string;
        lastName: string;
        eligibilityYear?: number | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        birthDate?: string | null;
        headshotUrl?: string | null;
        schoolId?: string | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolCode?: string | null;
          schoolName: string;
        } | null;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        grades: Array<{
          __typename?: "Grade";
          playerId: string;
          gradeType: GradeTypeEnum;
          value: number;
        }>;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          projectedGamesMissed: number;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type GetDraftScenarioResultsQueryVariables = Exact<{
  request: ExportDraftScenarioRequest;
}>;

export type GetDraftScenarioResultsQuery = {
  __typename?: "Query";
  draftScenarioResults: {
    __typename?: "ExportDraftScenarioResult";
    picks: Array<{
      __typename?: "DraftScenarioExportedPlayer";
      overallPickNumber: string;
      clubAbbreviation: string;
      playerFirstName: string;
      playerLastName: string;
      position: string;
      collegeAbbreviation: string;
      gsisPlayerId: string;
      marvalPlayerId: string;
      pffPlayerId: string;
      clubGradeDollars: string;
      clubGradeRaw: string;
      sageGradeDollars: string;
      sumerScoutGradeDollars: string;
      sumerScoutGradeRaw: string;
      performanceValue: string;
    }>;
  };
};

export type GetActiveDraftsQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveDraftsQuery = {
  __typename?: "Query";
  myDraftScenarios: Array<{
    __typename?: "DraftScenario";
    id: string;
    draftType: DraftTypeEnum;
  }>;
};

export type GetMyDraftByIdQueryVariables = Exact<{
  draftId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetMyDraftByIdQuery = {
  __typename?: "Query";
  myDraftScenarios: Array<{
    __typename?: "DraftScenario";
    id: string;
    draftType: DraftTypeEnum;
    season: number;
    status: DraftScenarioStatus;
    name: string;
    createdAt: string;
    createdBy: string;
  }>;
};

export type GetMyUndiscardedDraftsWithFiltersQueryVariables = Exact<{
  after?: InputMaybe<Scalars["DateTime"]>;
}>;

export type GetMyUndiscardedDraftsWithFiltersQuery = {
  __typename?: "Query";
  myDraftScenarios: Array<{
    __typename?: "DraftScenario";
    id: string;
    draftType: DraftTypeEnum;
    season: number;
    status: DraftScenarioStatus;
    name: string;
    createdAt: string;
    createdBy: string;
    lastUpdated: string;
    netRosterValueChangeDollars?: number | null;
    currentRosterValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
  }>;
};

export type GetTradeEvaluationQueryVariables = Exact<{
  request: GetTradeEvaluationRequest;
}>;

export type GetTradeEvaluationQuery = {
  __typename?: "Query";
  tradeEvaluation: {
    __typename?: "SumerTradeValue";
    netCostDollars: number;
    netValueDollars?: number | null;
    tradeValueDollars: number;
    costIncurring: { __typename?: "Value"; gapypoc: number; dollars: number };
    costOffloading: { __typename?: "Value"; gapypoc: number; dollars: number };
    valueOffering?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    valueReceiving?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    sumerEvaluatedDraftAssets: Array<{
      __typename?: "SumerEvaluatedDraftAsset";
      cost: { __typename?: "Value"; gapypoc: number; dollars: number };
      net: { __typename?: "Value"; gapypoc: number; dollars: number };
      value: {
        __typename?: "ClubPickValue";
        backingPlayers: Array<{
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        }>;
        value: { __typename?: "Value"; gapypoc: number; dollars: number };
      };
      draftAsset: {
        __typename?: "DraftAsset";
        overall?: number | null;
        pickInRound?: number | null;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
  };
};

export type SumerEvaluatedDraftAssetsFieldsFragment = {
  __typename?: "SumerEvaluatedDraftAsset";
  cost: { __typename?: "Value"; gapypoc: number; dollars: number };
  net: { __typename?: "Value"; gapypoc: number; dollars: number };
  value: {
    __typename?: "ClubPickValue";
    backingPlayers: Array<{
      __typename?: "ClubDraftablePlayerAtPick";
      projectedRosterValueDollars?: number | null;
      marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
      performanceValue?: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      } | null;
      rosterValueAdded?: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      } | null;
      availability?: {
        __typename?: "Availability";
        availabilityPercentage: number;
        draftPick: {
          __typename?: "CurrentSeasonDraftAsset";
          overall: number;
          pickInRound: number;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string; clubCode: string };
          originalClub: { __typename?: "Club"; id: string };
        };
      } | null;
      availabilityAtClubsNextPick?: {
        __typename?: "Availability";
        availabilityPercentage: number;
        draftPick: {
          __typename?: "CurrentSeasonDraftAsset";
          overall: number;
          pickInRound: number;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string; clubCode: string };
          originalClub: { __typename?: "Club"; id: string };
        };
      } | null;
      player: {
        __typename?: "Player";
        id: string;
        firstName: string;
        lastName: string;
        height?: number | null;
        weight?: number | null;
        headshotUrl?: string | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolName: string;
          schoolCode?: string | null;
        } | null;
        playerGrade?: {
          __typename?: "PlayerGrade";
          playerId: string;
          avgClubGapypocDollars: number;
          avgSageGapypocDollars: number;
          avgSumerScoutGapypocDollars: number;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
          seasons: Array<{
            __typename?: "PlayerGradeSeason";
            season: number;
            gradeType?: GradeWeightEnum | null;
            lastUpdated: string;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
          }>;
        } | null;
        workouts: Array<{
          __typename?: "WorkoutPlayerMeasure";
          value: number;
          measureType: { __typename?: "MeasureType"; name: string };
        }>;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          value: number;
          season: number;
          projectedGamesMissed: number;
          projectedGamesMissedOverride?: number | null;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
    }>;
    value: { __typename?: "Value"; gapypoc: number; dollars: number };
  };
  draftAsset: {
    __typename?: "DraftAsset";
    overall?: number | null;
    pickInRound?: number | null;
    round: number;
    season: number;
    club: { __typename?: "Club"; id: string };
    originalClub: { __typename?: "Club"; id: string };
  };
};

export type ClubPickValueFieldsFragment = {
  __typename?: "ClubPickValue";
  backingPlayers: Array<{
    __typename?: "ClubDraftablePlayerAtPick";
    projectedRosterValueDollars?: number | null;
    marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
    performanceValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    rosterValueAdded?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    availability?: {
      __typename?: "Availability";
      availabilityPercentage: number;
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    } | null;
    availabilityAtClubsNextPick?: {
      __typename?: "Availability";
      availabilityPercentage: number;
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    } | null;
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      height?: number | null;
      weight?: number | null;
      headshotUrl?: string | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolName: string;
        schoolCode?: string | null;
      } | null;
      playerGrade?: {
        __typename?: "PlayerGrade";
        playerId: string;
        avgClubGapypocDollars: number;
        avgSageGapypocDollars: number;
        avgSumerScoutGapypocDollars: number;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
        seasons: Array<{
          __typename?: "PlayerGradeSeason";
          season: number;
          gradeType?: GradeWeightEnum | null;
          lastUpdated: string;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
        }>;
      } | null;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        value: number;
        measureType: { __typename?: "MeasureType"; name: string };
      }>;
      favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
      projectedGamesMissed: Array<{
        __typename?: "PlayerProjectedGamesMissed";
        value: number;
        season: number;
        projectedGamesMissed: number;
        projectedGamesMissedOverride?: number | null;
      }>;
      clubGradingPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerAtlasPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
    };
  }>;
  value: { __typename?: "Value"; gapypoc: number; dollars: number };
};

export type DraftScenarioUpdatedSubscriptionVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type DraftScenarioUpdatedSubscription = {
  __typename?: "Subscription";
  draftScenarioUpdatesById: {
    __typename?: "DraftScenario";
    id: string;
    draftType: DraftTypeEnum;
    season: number;
    name: string;
    version: number;
    lastUpdated: string;
    latestModelRunRequestId: string;
    modelRunStatus: DraftScenarioDataStatus;
    status: DraftScenarioStatus;
    netRosterValueChangeDollars?: number | null;
    clubGradingPositionRecommendedSort?: string | null;
    workspaceSortMode: WorkspaceSortMode;
    activePick?: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    } | null;
    currentRosterValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    startingRosterValue?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    performanceGaps: Array<{
      __typename?: "PerformanceGap";
      performanceValueAddedDollars: number;
      remainingGapDollars: number;
      sideOfBall?: SideOfBall | null;
      sumerGeneralPositionShortName: string;
      currentPerformanceValue: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      };
      startingPerformanceValue: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      };
      targetPerformanceValue: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      };
    }>;
    assumptions: Array<{
      __typename?: "Assumption";
      constraint: Constraint;
      contractMinimumEndDate?: any | null;
      description: string;
      draftRoundMinimum?: number | null;
      id: string;
      isValid: boolean;
      name: string;
      playerId?: string | null;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        overall?: number | null;
        number?: number | null;
        round?: number | null;
        season: number;
      } | null;
      draftPickTrade?: Array<{
        __typename?: "DraftPickTransaction";
        clubId: string;
        draftPicksGained: Array<{
          __typename?: "DraftPick";
          overall?: number | null;
          number?: number | null;
          round?: number | null;
          season: number;
        }>;
        draftPicksLost: Array<{
          __typename?: "DraftPick";
          overall?: number | null;
          number?: number | null;
          round?: number | null;
          season: number;
        }>;
      }> | null;
    }>;
    clubTeamNeeds: {
      __typename?: "DraftScenarioTeamNeeds";
      positionalNeeds: Array<{
        __typename?: "DraftScenarioPositionalNeed";
        isFulfilled: boolean;
        rank: number;
        sumerGeneralPositionShortName: string;
      }>;
    };
    allTeamNeeds: Array<{
      __typename?: "DraftScenarioTeamNeeds";
      club: {
        __typename?: "Club";
        id: string;
        clubCode: string;
        clubName: string;
      };
      positionalNeeds: Array<{
        __typename?: "DraftScenarioPositionalNeed";
        isFulfilled: boolean;
        rank: number;
        sumerGeneralPositionShortName: string;
      }>;
    }>;
    club: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    };
    clubsNextPick?: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    } | null;
    draftedPlayers: Array<{
      __typename?: "DraftedPlayer";
      player: {
        __typename?: "Player";
        id: string;
        gsisId?: string | null;
        collegeGsisId?: string | null;
        firstName: string;
        lastName: string;
        eligibilityYear?: number | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        birthDate?: string | null;
        headshotUrl?: string | null;
        schoolId?: string | null;
        playerGrade?: {
          __typename?: "PlayerGrade";
          playerId: string;
          avgClubGapypocDollars: number;
          avgSageGapypocDollars: number;
          avgSumerScoutGapypocDollars: number;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
          seasons: Array<{
            __typename?: "PlayerGradeSeason";
            season: number;
            gradeType?: GradeWeightEnum | null;
            lastUpdated: string;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
          }>;
        } | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolCode?: string | null;
          schoolName: string;
        } | null;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        grades: Array<{
          __typename?: "Grade";
          playerId: string;
          gradeType: GradeTypeEnum;
          value: number;
        }>;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          projectedGamesMissed: number;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    clubDraftedPlayers: Array<{
      __typename?: "DraftedPlayer";
      player: {
        __typename?: "Player";
        id: string;
        gsisId?: string | null;
        collegeGsisId?: string | null;
        firstName: string;
        lastName: string;
        eligibilityYear?: number | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        birthDate?: string | null;
        headshotUrl?: string | null;
        schoolId?: string | null;
        playerGrade?: {
          __typename?: "PlayerGrade";
          playerId: string;
          avgClubGapypocDollars: number;
          avgSageGapypocDollars: number;
          avgSumerScoutGapypocDollars: number;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
          seasons: Array<{
            __typename?: "PlayerGradeSeason";
            season: number;
            gradeType?: GradeWeightEnum | null;
            lastUpdated: string;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
          }>;
        } | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolCode?: string | null;
          schoolName: string;
        } | null;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        grades: Array<{
          __typename?: "Grade";
          playerId: string;
          gradeType: GradeTypeEnum;
          value: number;
        }>;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          projectedGamesMissed: number;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    clubDraftAssets: Array<{
      __typename?: "DraftAsset";
      overall?: number | null;
      pickInRound?: number | null;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
    draftOrder: Array<{
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    }>;
    tradeOpportunities: Array<{
      __typename?: "PickTradeOpportunity";
      clubValue: {
        __typename?: "ClubPickValue";
        backingPlayers: Array<{
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        }>;
        value: { __typename?: "Value"; gapypoc: number; dollars: number };
      };
      ownerValue: { __typename?: "Value"; gapypoc: number; dollars: number };
      pickToTrade: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    currentWorkspacePlayers: Array<{
      __typename?: "CurrentWorkspacePlayer";
      slot: number;
      workspacePlayer: {
        __typename?: "WorkspacePlayer";
        comboPlayerModelRequestId: string;
        comboPlayerModelRunStatus: DraftScenarioDataStatus;
        player: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
        recommendedComboPlayers: Array<{
          __typename?: "ComboDraftablePlayer";
          isTopCombo: boolean;
          playerAtPick: {
            __typename?: "ClubDraftablePlayerAtPick";
            projectedRosterValueDollars?: number | null;
            marketValue: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            };
            performanceValue?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            rosterValueAdded?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            availability?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            availabilityAtClubsNextPick?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            player: {
              __typename?: "Player";
              id: string;
              firstName: string;
              lastName: string;
              height?: number | null;
              weight?: number | null;
              headshotUrl?: string | null;
              school?: {
                __typename?: "School";
                id: string;
                schoolName: string;
                schoolCode?: string | null;
              } | null;
              playerGrade?: {
                __typename?: "PlayerGrade";
                playerId: string;
                avgClubGapypocDollars: number;
                avgSageGapypocDollars: number;
                avgSumerScoutGapypocDollars: number;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
                seasons: Array<{
                  __typename?: "PlayerGradeSeason";
                  season: number;
                  gradeType?: GradeWeightEnum | null;
                  lastUpdated: string;
                  value: {
                    __typename?: "PlayerGradeValue";
                    value: string;
                    gapypoc: number;
                    gapypocDollars: number;
                  };
                }>;
              } | null;
              workouts: Array<{
                __typename?: "WorkoutPlayerMeasure";
                value: number;
                measureType: { __typename?: "MeasureType"; name: string };
              }>;
              favorite?: {
                __typename?: "PlayerFavorite";
                playerId: string;
              } | null;
              projectedGamesMissed: Array<{
                __typename?: "PlayerProjectedGamesMissed";
                value: number;
                season: number;
                projectedGamesMissed: number;
                projectedGamesMissedOverride?: number | null;
              }>;
              clubGradingPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerAtlasPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerGeneralPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
            };
          };
        }>;
      };
    }>;
    recommendedWorkspacePlayers: Array<{
      __typename?: "RecommendedWorkspacePlayer";
      clubGradingPositionShortName?: string | null;
      workspacePlayer: {
        __typename?: "WorkspacePlayer";
        player: {
          __typename?: "ClubDraftablePlayerAtPick";
          projectedRosterValueDollars?: number | null;
          marketValue: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          performanceValue?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          rosterValueAdded?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          availability?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          availabilityAtClubsNextPick?: {
            __typename?: "Availability";
            availabilityPercentage: number;
            draftPick: {
              __typename?: "CurrentSeasonDraftAsset";
              overall: number;
              pickInRound: number;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string; clubCode: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          } | null;
          player: {
            __typename?: "Player";
            id: string;
            firstName: string;
            lastName: string;
            height?: number | null;
            weight?: number | null;
            headshotUrl?: string | null;
            school?: {
              __typename?: "School";
              id: string;
              schoolName: string;
              schoolCode?: string | null;
            } | null;
            playerGrade?: {
              __typename?: "PlayerGrade";
              playerId: string;
              avgClubGapypocDollars: number;
              avgSageGapypocDollars: number;
              avgSumerScoutGapypocDollars: number;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
              seasons: Array<{
                __typename?: "PlayerGradeSeason";
                season: number;
                gradeType?: GradeWeightEnum | null;
                lastUpdated: string;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
              }>;
            } | null;
            workouts: Array<{
              __typename?: "WorkoutPlayerMeasure";
              value: number;
              measureType: { __typename?: "MeasureType"; name: string };
            }>;
            favorite?: {
              __typename?: "PlayerFavorite";
              playerId: string;
            } | null;
            projectedGamesMissed: Array<{
              __typename?: "PlayerProjectedGamesMissed";
              value: number;
              season: number;
              projectedGamesMissed: number;
              projectedGamesMissedOverride?: number | null;
            }>;
            clubGradingPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerAtlasPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
            sumerGeneralPosition?: {
              __typename?: "Position";
              id: string;
              shortName: string;
            } | null;
          };
        };
        recommendedComboPlayers: Array<{
          __typename?: "ComboDraftablePlayer";
          isTopCombo: boolean;
          playerAtPick: {
            __typename?: "ClubDraftablePlayerAtPick";
            projectedRosterValueDollars?: number | null;
            marketValue: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            };
            performanceValue?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            rosterValueAdded?: {
              __typename?: "Value";
              gapypoc: number;
              dollars: number;
            } | null;
            availability?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            availabilityAtClubsNextPick?: {
              __typename?: "Availability";
              availabilityPercentage: number;
              draftPick: {
                __typename?: "CurrentSeasonDraftAsset";
                overall: number;
                pickInRound: number;
                round: number;
                season: number;
                club: { __typename?: "Club"; id: string; clubCode: string };
                originalClub: { __typename?: "Club"; id: string };
              };
            } | null;
            player: {
              __typename?: "Player";
              id: string;
              firstName: string;
              lastName: string;
              height?: number | null;
              weight?: number | null;
              headshotUrl?: string | null;
              school?: {
                __typename?: "School";
                id: string;
                schoolName: string;
                schoolCode?: string | null;
              } | null;
              playerGrade?: {
                __typename?: "PlayerGrade";
                playerId: string;
                avgClubGapypocDollars: number;
                avgSageGapypocDollars: number;
                avgSumerScoutGapypocDollars: number;
                value: {
                  __typename?: "PlayerGradeValue";
                  value: string;
                  gapypoc: number;
                  gapypocDollars: number;
                };
                seasons: Array<{
                  __typename?: "PlayerGradeSeason";
                  season: number;
                  gradeType?: GradeWeightEnum | null;
                  lastUpdated: string;
                  value: {
                    __typename?: "PlayerGradeValue";
                    value: string;
                    gapypoc: number;
                    gapypocDollars: number;
                  };
                }>;
              } | null;
              workouts: Array<{
                __typename?: "WorkoutPlayerMeasure";
                value: number;
                measureType: { __typename?: "MeasureType"; name: string };
              }>;
              favorite?: {
                __typename?: "PlayerFavorite";
                playerId: string;
              } | null;
              projectedGamesMissed: Array<{
                __typename?: "PlayerProjectedGamesMissed";
                value: number;
                season: number;
                projectedGamesMissed: number;
                projectedGamesMissedOverride?: number | null;
              }>;
              clubGradingPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerAtlasPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
              sumerGeneralPosition?: {
                __typename?: "Position";
                id: string;
                shortName: string;
              } | null;
            };
          };
        }>;
      };
    }>;
    executedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
    pinnedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
    applicablePinnedTrades: Array<{
      __typename?: "Trade";
      id: number;
      leagueTradeId?: number | null;
      lastUpdated: string;
      status: TradeStatus;
      tradePartners: Array<{
        __typename?: "TradePartner";
        club: { __typename?: "Club"; id: string; clubCode: string };
        offering: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
        receiving: Array<{
          __typename?: "TradeAsset";
          draftPick?: {
            __typename?: "DraftAsset";
            overall?: number | null;
            pickInRound?: number | null;
            round: number;
            season: number;
            club: { __typename?: "Club"; id: string };
            originalClub: { __typename?: "Club"; id: string };
          } | null;
          tradedPlayer?: {
            __typename?: "TradedPlayer";
            player: {
              __typename?: "Player";
              firstName: string;
              lastName: string;
            };
          } | null;
        }>;
      }>;
      tradeValue?: {
        __typename?: "TradeValue";
        sumerTradeValueStatus: DraftScenarioDataStatus;
        evaluatedForClub: { __typename?: "Club"; clubCode: string };
        sumerTradeValue?: {
          __typename?: "SumerTradeValue";
          netCostDollars: number;
          netValueDollars?: number | null;
          tradeValueDollars: number;
          costIncurring: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          costOffloading: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          };
          valueOffering?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
          valueReceiving?: {
            __typename?: "Value";
            gapypoc: number;
            dollars: number;
          } | null;
        } | null;
        tradeChartValues: {
          __typename?: "TradeChartValues";
          jimmyJohnsonTradeValue: {
            __typename?: "JimmyJohnsonTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          otcTradeValue: {
            __typename?: "OtcTradeValue";
            netPoints: number;
            offeringPoints: number;
            receivingPoints: number;
          };
          evaluatedDraftAssets: Array<{
            __typename?: "EvaluatedDraftAsset";
            jimmyJohnsonPoints: number;
            otcPoints: number;
            draftAsset: {
              __typename?: "DraftAsset";
              overall?: number | null;
              pickInRound?: number | null;
              round: number;
              season: number;
              club: { __typename?: "Club"; id: string };
              originalClub: { __typename?: "Club"; id: string };
            };
          }>;
        };
      } | null;
    }>;
    modelMetadata: {
      __typename?: "ModelMetadata";
      latestDamRequestBlobLocation?: string | null;
      latestDamResponseBlobLocation?: string | null;
      latestRosterValueRequestBlobLocation?: string | null;
      latestRosterValueResponseBlobLocation?: string | null;
      latestSampleSimulationRequestBlobLocation?: string | null;
      latestSampleSimulationResponseBlobLocation?: string | null;
      latestDamRequestComboPlayerBlobLocation?: string | null;
      latestDamResponseComboPlayerBlobLocation?: string | null;
      latestTargetValueRequestBlobLocation?: string | null;
      latestTargetValueResponseBlobLocation?: string | null;
    };
  };
};

export type CreateEvaluationMutationVariables = Exact<{
  request: CreateEvaluationRequest;
}>;

export type CreateEvaluationMutation = {
  __typename?: "Mutation";
  createEvaluation?: {
    __typename?: "Evaluation";
    id: string;
    isFinal: boolean;
    playerId: string;
    positionId: string;
    scoutGradeId?: string | null;
    summary?: string | null;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
  } | null;
};

export type UpdateEvaluationMutationVariables = Exact<{
  request: UpdateEvaluationRequest;
}>;

export type UpdateEvaluationMutation = {
  __typename?: "Mutation";
  updateEvaluation?: {
    __typename?: "Evaluation";
    id: string;
    isFinal: boolean;
    playerId: string;
    positionId: string;
    scoutGradeId?: string | null;
    summary?: string | null;
    finalizedOn?: string | null;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
  } | null;
};

export type SaveEvaluationTraitMutationVariables = Exact<{
  request: SaveEvaluationTraitRequest;
}>;

export type SaveEvaluationTraitMutation = {
  __typename?: "Mutation";
  saveEvaluationTrait?: {
    __typename?: "Evaluation";
    id: string;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
        quality: string;
      } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        shortName: string;
        sort: number;
      };
    }>;
  } | null;
};

export type SaveEvaluationFitsMutationVariables = Exact<{
  request: SaveEvaluationFitRequest;
}>;

export type SaveEvaluationFitsMutation = {
  __typename?: "Mutation";
  saveEvaluationFits?: {
    __typename?: "Evaluation";
    id: string;
    fits: Array<{
      __typename?: "EvaluationFit";
      evaluationId: string;
      fit: {
        __typename?: "Fit";
        id: string;
        position: string;
        name: string;
        abbreviation: string;
        description: string;
      };
    }>;
  } | null;
};

export type UnMarkEvaluationAsFinalMutationVariables = Exact<{
  request: UnMarkEvaluationAsFinalRequest;
}>;

export type UnMarkEvaluationAsFinalMutation = {
  __typename?: "Mutation";
  unMarkEvaluationAsFinal?: {
    __typename?: "Evaluation";
    id: string;
    isFinal: boolean;
    finalizedOn?: string | null;
  } | null;
};

export type SaveEvaluationAlertsMutationVariables = Exact<{
  request: SaveEvaluationAlertRequest;
}>;

export type SaveEvaluationAlertsMutation = {
  __typename?: "Mutation";
  saveEvaluationAlerts?: {
    __typename?: "Evaluation";
    id: string;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        description: string;
        abbreviation: string;
      };
    }>;
  } | null;
};

export type CreateGameEvaluationMutationVariables = Exact<{
  request: CreateGameEvaluationRequest;
}>;

export type CreateGameEvaluationMutation = {
  __typename?: "Mutation";
  createGameEvaluation?: {
    __typename?: "Evaluation";
    id: string;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      id: string;
      gameId: string;
      evaluationId: string;
      scoutGradeId?: string | null;
      comments?: string | null;
      game: {
        __typename?: "Game";
        kickoffTime?: string | null;
        homeTeam?: string | null;
        awayTeam?: string | null;
      };
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
    }>;
  } | null;
};

export type UpdateGameEvaluationMutationVariables = Exact<{
  request: UpdateGameEvaluationRequest;
}>;

export type UpdateGameEvaluationMutation = {
  __typename?: "Mutation";
  updateGameEvaluation?: {
    __typename?: "Evaluation";
    id: string;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      id: string;
      gameId: string;
      evaluationId: string;
      scoutGradeId?: string | null;
      comments?: string | null;
      game: {
        __typename?: "Game";
        kickoffTime?: string | null;
        homeTeam?: string | null;
        awayTeam?: string | null;
      };
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
    }>;
  } | null;
};

export type SaveEvaluationTraitGroupMutationVariables = Exact<{
  request: SaveEvaluationTraitGroupRequest;
}>;

export type SaveEvaluationTraitGroupMutation = {
  __typename?: "Mutation";
  saveEvaluationTraitGroup?: {
    __typename?: "Evaluation";
    id: string;
    scoutGradeId?: string | null;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
      quality: string;
    } | null;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      id: string;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
    }>;
  } | null;
};

export type EvalFragment = {
  __typename?: "Evaluation";
  id: string;
  created: string;
  lastUpdated: string;
  isFinal: boolean;
  finalizedOn?: string | null;
  summary?: string | null;
  scoutGrade?: {
    __typename?: "ScoutGrade";
    id: string;
    grade: number;
    quality: string;
    description: string;
  } | null;
  user: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  player: {
    __typename?: "Player";
    id: string;
    firstName: string;
    lastName: string;
    school?: { __typename?: "School"; id: string; schoolName: string } | null;
  };
  position: { __typename?: "Position"; id: string; shortName: string };
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    id: string;
    comments?: string | null;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
      quality: string;
      description: string;
    } | null;
    traitDefinition: {
      __typename?: "TraitDefinition";
      id: string;
      caption: string;
      shortName: string;
      sort: number;
    };
  }>;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      description: string;
      abbreviation: string;
    };
  }>;
  fits: Array<{
    __typename?: "EvaluationFit";
    evaluationId: string;
    fit: {
      __typename?: "Fit";
      id: string;
      position: string;
      name: string;
      abbreviation: string;
      description: string;
    };
  }>;
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    id: string;
    gameId: string;
    comments?: string | null;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
      quality: string;
      description: string;
    } | null;
    game: {
      __typename?: "Game";
      id: string;
      season: number;
      week?: number | null;
      venue?: string | null;
      homeTeam?: string | null;
      awayTeam?: string | null;
      homeTeamScore?: number | null;
      awayTeamScore?: number | null;
      kickoffTime?: string | null;
      seasonType: {
        __typename?: "SeasonType";
        id: number;
        abbreviation?: string | null;
        description?: string | null;
      };
      weekType: {
        __typename?: "WeekType";
        id: number;
        description?: string | null;
      };
      leagueType: {
        __typename?: "LeagueType";
        id: number;
        description?: string | null;
      };
    };
  }>;
  traitGroupGrades: Array<{
    __typename?: "EvaluationTraitGroup";
    id: string;
    traitGroup: { __typename?: "TraitGroup"; id: string; caption: string };
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
  }>;
};

export type EvaluationFitFragment = {
  __typename?: "EvaluationFit";
  evaluationId: string;
  fit: {
    __typename?: "Fit";
    id: string;
    position: string;
    name: string;
    abbreviation: string;
    description: string;
  };
};

export type EvaluationAlertFragment = {
  __typename?: "EvaluationAlert";
  evaluationId: string;
  alert: {
    __typename?: "Alert";
    id: string;
    caption: string;
    description: string;
    abbreviation: string;
  };
};

export type EvaluationTraitFragment = {
  __typename?: "EvaluationTrait";
  id: string;
  comments?: string | null;
  traitDefinition: {
    __typename?: "TraitDefinition";
    id: string;
    caption: string;
    shortName: string;
    sort: number;
  };
  scoutGrade?: {
    __typename?: "ScoutGrade";
    id: string;
    grade: number;
    quality: string;
    description: string;
  } | null;
};

export type TraitGroupFragment = {
  __typename?: "TraitGroup";
  id: string;
  caption: string;
  sort: number;
  traitGroupPositions: Array<{
    __typename?: "TraitGroupPosition";
    positionId: string;
    sort: number;
    traitDefinition: {
      __typename?: "TraitDefinition";
      id: string;
      caption: string;
      description?: string | null;
      sort: number;
    };
  }>;
};

export type FitFragment = {
  __typename?: "Fit";
  id: string;
  position: string;
  name: string;
  abbreviation: string;
  description: string;
};

export type AlertFragment = {
  __typename?: "Alert";
  id: string;
  caption: string;
  description: string;
  abbreviation: string;
};

export type EvaluationGameFragment = {
  __typename?: "EvaluationGame";
  id: string;
  gameId: string;
  comments?: string | null;
  scoutGrade?: {
    __typename?: "ScoutGrade";
    id: string;
    grade: number;
    quality: string;
    description: string;
  } | null;
  game: {
    __typename?: "Game";
    id: string;
    season: number;
    week?: number | null;
    venue?: string | null;
    homeTeam?: string | null;
    awayTeam?: string | null;
    homeTeamScore?: number | null;
    awayTeamScore?: number | null;
    kickoffTime?: string | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    weekType: {
      __typename?: "WeekType";
      id: number;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
  };
};

export type ScoutGradeFragment = {
  __typename?: "ScoutGrade";
  id: string;
  grade: number;
  quality: string;
  description: string;
};

export type GetEvalQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetEvalQuery = {
  __typename?: "Query";
  evalById?: {
    __typename?: "Evaluation";
    id: string;
    created: string;
    lastUpdated: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    summary?: string | null;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
      quality: string;
      description: string;
    } | null;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      school?: { __typename?: "School"; id: string; schoolName: string } | null;
    };
    position: { __typename?: "Position"; id: string; shortName: string };
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
        quality: string;
        description: string;
      } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        shortName: string;
        sort: number;
      };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        description: string;
        abbreviation: string;
      };
    }>;
    fits: Array<{
      __typename?: "EvaluationFit";
      evaluationId: string;
      fit: {
        __typename?: "Fit";
        id: string;
        position: string;
        name: string;
        abbreviation: string;
        description: string;
      };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      id: string;
      gameId: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
        quality: string;
        description: string;
      } | null;
      game: {
        __typename?: "Game";
        id: string;
        season: number;
        week?: number | null;
        venue?: string | null;
        homeTeam?: string | null;
        awayTeam?: string | null;
        homeTeamScore?: number | null;
        awayTeamScore?: number | null;
        kickoffTime?: string | null;
        seasonType: {
          __typename?: "SeasonType";
          id: number;
          abbreviation?: string | null;
          description?: string | null;
        };
        weekType: {
          __typename?: "WeekType";
          id: number;
          description?: string | null;
        };
        leagueType: {
          __typename?: "LeagueType";
          id: number;
          description?: string | null;
        };
      };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      id: string;
      traitGroup: { __typename?: "TraitGroup"; id: string; caption: string };
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
    }>;
  } | null;
};

export type GetEvalsQueryVariables = Exact<{
  position?: InputMaybe<Scalars["UUID"]>;
  user?: InputMaybe<Scalars["UUID"]>;
  playerId?: InputMaybe<Scalars["UUID"]>;
  group?: InputMaybe<EvalTypeEnum>;
}>;

export type GetEvalsQuery = {
  __typename?: "Query";
  evals: Array<{
    __typename?: "Evaluation";
    id: string;
    created: string;
    lastUpdated: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    summary?: string | null;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
      quality: string;
      description: string;
    } | null;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      school?: { __typename?: "School"; id: string; schoolName: string } | null;
    };
    position: { __typename?: "Position"; id: string; shortName: string };
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
        quality: string;
        description: string;
      } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        shortName: string;
        sort: number;
      };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        description: string;
        abbreviation: string;
      };
    }>;
    fits: Array<{
      __typename?: "EvaluationFit";
      evaluationId: string;
      fit: {
        __typename?: "Fit";
        id: string;
        position: string;
        name: string;
        abbreviation: string;
        description: string;
      };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      id: string;
      gameId: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
        quality: string;
        description: string;
      } | null;
      game: {
        __typename?: "Game";
        id: string;
        season: number;
        week?: number | null;
        venue?: string | null;
        homeTeam?: string | null;
        awayTeam?: string | null;
        homeTeamScore?: number | null;
        awayTeamScore?: number | null;
        kickoffTime?: string | null;
        seasonType: {
          __typename?: "SeasonType";
          id: number;
          abbreviation?: string | null;
          description?: string | null;
        };
        weekType: {
          __typename?: "WeekType";
          id: number;
          description?: string | null;
        };
        leagueType: {
          __typename?: "LeagueType";
          id: number;
          description?: string | null;
        };
      };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      id: string;
      traitGroup: { __typename?: "TraitGroup"; id: string; caption: string };
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
    }>;
  }>;
};

export type GetTraitGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTraitGroupsQuery = {
  __typename?: "Query";
  traitGroups: Array<{
    __typename?: "TraitGroup";
    id: string;
    caption: string;
    sort: number;
    traitGroupPositions: Array<{
      __typename?: "TraitGroupPosition";
      positionId: string;
      sort: number;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        description?: string | null;
        sort: number;
      };
    }>;
  }>;
};

export type GetScoutGradesQueryVariables = Exact<{
  scale?: InputMaybe<Scalars["String"]>;
}>;

export type GetScoutGradesQuery = {
  __typename?: "Query";
  scoutGrades: Array<{
    __typename?: "ScoutGrade";
    id: string;
    grade: number;
    quality: string;
    description: string;
  }>;
};

export type GetFitsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFitsQuery = {
  __typename?: "Query";
  fits: Array<{
    __typename?: "Fit";
    id: string;
    position: string;
    name: string;
    abbreviation: string;
    description: string;
  }>;
};

export type GetAlertsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAlertsQuery = {
  __typename?: "Query";
  alerts: Array<{
    __typename?: "Alert";
    id: string;
    caption: string;
    description: string;
    abbreviation: string;
  }>;
};

export type AddGlobalDraftWithPickAssumptionMutationVariables = Exact<{
  request: AddGlobalDraftWithPickAssumptionRequest;
}>;

export type AddGlobalDraftWithPickAssumptionMutation = {
  __typename?: "Mutation";
  addGlobalDraftWithPickAssumption: {
    __typename?: "AssumptionSet";
    id: string;
    assumptions: Array<{
      __typename?: "Assumption";
      id: string;
      name: string;
      description: string;
      isValid: boolean;
      subject: Subject;
      constraint: Constraint;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      draftRoundMinimum?: number | null;
      draftPickTrade?: Array<{
        __typename?: "DraftPickTransaction";
        clubId: string;
        draftPicksGained: Array<{
          __typename?: "DraftPick";
          season: number;
          round?: number | null;
          number?: number | null;
          overall?: number | null;
        }>;
        draftPicksLost: Array<{
          __typename?: "DraftPick";
          season: number;
          round?: number | null;
          number?: number | null;
          overall?: number | null;
        }>;
      }> | null;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        number?: number | null;
        overall?: number | null;
      } | null;
    }>;
  };
};

export type AddGlobalWaitToDraftAssumptionMutationVariables = Exact<{
  request: AddGlobalWaitToDraftAssumptionRequest;
}>;

export type AddGlobalWaitToDraftAssumptionMutation = {
  __typename?: "Mutation";
  addGlobalWaitToDraftAssumption: {
    __typename?: "AssumptionSet";
    id: string;
    assumptions: Array<{
      __typename?: "Assumption";
      id: string;
      name: string;
      description: string;
      isValid: boolean;
      subject: Subject;
      constraint: Constraint;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      draftRoundMinimum?: number | null;
      draftPickTrade?: Array<{
        __typename?: "DraftPickTransaction";
        clubId: string;
        draftPicksGained: Array<{
          __typename?: "DraftPick";
          season: number;
          round?: number | null;
          number?: number | null;
          overall?: number | null;
        }>;
        draftPicksLost: Array<{
          __typename?: "DraftPick";
          season: number;
          round?: number | null;
          number?: number | null;
          overall?: number | null;
        }>;
      }> | null;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        number?: number | null;
        overall?: number | null;
      } | null;
    }>;
  };
};

export type AddGlobalKeepAssumptionMutationVariables = Exact<{
  request: AddGlobalKeepAssumptionRequest;
}>;

export type AddGlobalKeepAssumptionMutation = {
  __typename?: "Mutation";
  addGlobalKeepAssumption: {
    __typename?: "AssumptionSet";
    id: string;
    assumptions: Array<{
      __typename?: "Assumption";
      id: string;
      name: string;
      description: string;
      isValid: boolean;
      subject: Subject;
      constraint: Constraint;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      draftRoundMinimum?: number | null;
      draftPickTrade?: Array<{
        __typename?: "DraftPickTransaction";
        clubId: string;
        draftPicksGained: Array<{
          __typename?: "DraftPick";
          season: number;
          round?: number | null;
          number?: number | null;
          overall?: number | null;
        }>;
        draftPicksLost: Array<{
          __typename?: "DraftPick";
          season: number;
          round?: number | null;
          number?: number | null;
          overall?: number | null;
        }>;
      }> | null;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        number?: number | null;
        overall?: number | null;
      } | null;
    }>;
  };
};

export type AddGlobalDoNotDraftAssumptionMutationVariables = Exact<{
  request: AddGlobalDoNotDraftAssumptionRequest;
}>;

export type AddGlobalDoNotDraftAssumptionMutation = {
  __typename?: "Mutation";
  addGlobalDoNotDraftAssumption: {
    __typename?: "AssumptionSet";
    id: string;
    assumptions: Array<{
      __typename?: "Assumption";
      id: string;
      name: string;
      description: string;
      isValid: boolean;
      subject: Subject;
      constraint: Constraint;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      draftRoundMinimum?: number | null;
      draftPickTrade?: Array<{
        __typename?: "DraftPickTransaction";
        clubId: string;
        draftPicksGained: Array<{
          __typename?: "DraftPick";
          season: number;
          round?: number | null;
          number?: number | null;
          overall?: number | null;
        }>;
        draftPicksLost: Array<{
          __typename?: "DraftPick";
          season: number;
          round?: number | null;
          number?: number | null;
          overall?: number | null;
        }>;
      }> | null;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        number?: number | null;
        overall?: number | null;
      } | null;
    }>;
  };
};

export type AddGlobalCutAssumptionMutationVariables = Exact<{
  request: AddGlobalCutAssumptionRequest;
}>;

export type AddGlobalCutAssumptionMutation = {
  __typename?: "Mutation";
  addGlobalCutAssumption: {
    __typename?: "AssumptionSet";
    id: string;
    assumptions: Array<{
      __typename?: "Assumption";
      id: string;
      name: string;
      description: string;
      isValid: boolean;
      subject: Subject;
      constraint: Constraint;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      draftRoundMinimum?: number | null;
      draftPickTrade?: Array<{
        __typename?: "DraftPickTransaction";
        clubId: string;
        draftPicksGained: Array<{
          __typename?: "DraftPick";
          season: number;
          round?: number | null;
          number?: number | null;
          overall?: number | null;
        }>;
        draftPicksLost: Array<{
          __typename?: "DraftPick";
          season: number;
          round?: number | null;
          number?: number | null;
          overall?: number | null;
        }>;
      }> | null;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        number?: number | null;
        overall?: number | null;
      } | null;
    }>;
  };
};

export type DeleteGlobalAssumptionMutationVariables = Exact<{
  request: DeleteGlobalAssumptionRequest;
}>;

export type DeleteGlobalAssumptionMutation = {
  __typename?: "Mutation";
  deleteGlobalAssumption: {
    __typename?: "AssumptionSet";
    id: string;
    assumptions: Array<{
      __typename?: "Assumption";
      id: string;
      name: string;
      description: string;
      isValid: boolean;
      subject: Subject;
      constraint: Constraint;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      draftRoundMinimum?: number | null;
      draftPickTrade?: Array<{
        __typename?: "DraftPickTransaction";
        clubId: string;
        draftPicksGained: Array<{
          __typename?: "DraftPick";
          season: number;
          round?: number | null;
          number?: number | null;
          overall?: number | null;
        }>;
        draftPicksLost: Array<{
          __typename?: "DraftPick";
          season: number;
          round?: number | null;
          number?: number | null;
          overall?: number | null;
        }>;
      }> | null;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        number?: number | null;
        overall?: number | null;
      } | null;
    }>;
  };
};

export type AssumptionFieldsFragment = {
  __typename?: "Assumption";
  id: string;
  name: string;
  description: string;
  isValid: boolean;
  subject: Subject;
  constraint: Constraint;
  playerId?: string | null;
  contractMinimumEndDate?: any | null;
  draftRoundMinimum?: number | null;
  draftPickTrade?: Array<{
    __typename?: "DraftPickTransaction";
    clubId: string;
    draftPicksGained: Array<{
      __typename?: "DraftPick";
      season: number;
      round?: number | null;
      number?: number | null;
      overall?: number | null;
    }>;
    draftPicksLost: Array<{
      __typename?: "DraftPick";
      season: number;
      round?: number | null;
      number?: number | null;
      overall?: number | null;
    }>;
  }> | null;
  contracts: Array<{ __typename?: "NYM"; id: string }>;
  draftPick?: {
    __typename?: "DraftPick";
    season: number;
    round?: number | null;
    number?: number | null;
    overall?: number | null;
  } | null;
};

export type GetGlobalAssumptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetGlobalAssumptionsQuery = {
  __typename?: "Query";
  globalAssumptions: {
    __typename?: "AssumptionSet";
    id: string;
    assumptions: Array<{
      __typename?: "Assumption";
      id: string;
      constraint: Constraint;
      description: string;
      isValid: boolean;
      name: string;
      subject: Subject;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      draftRoundMinimum?: number | null;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        number?: number | null;
        overall?: number | null;
      } | null;
    }>;
  };
};

export type GetGlobalAssumptionsWithPlayerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetGlobalAssumptionsWithPlayerQuery = {
  __typename?: "Query";
  globalAssumptionsWithPlayer: Array<{
    __typename?: "Player";
    firstName: string;
    lastName: string;
    id: string;
    headshotUrl?: string | null;
    club?: { __typename?: "Club"; clubCode: string; id: string } | null;
    clubGradingPosition?: { __typename?: "Position"; shortName: string } | null;
    assumptions: Array<{
      __typename?: "Assumption";
      id: string;
      constraint: Constraint;
      description: string;
      isValid: boolean;
      name: string;
      subject: Subject;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      draftRoundMinimum?: number | null;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        number?: number | null;
        overall?: number | null;
      } | null;
    }>;
    grades: Array<{
      __typename?: "Grade";
      gradeType: GradeTypeEnum;
      value: number;
      dollars?: number | null;
    }>;
  }>;
};

export type UpdateGradeWeightMutationVariables = Exact<{
  updateGradeWeightRequest: UpdateGradeWeightRequest;
}>;

export type UpdateGradeWeightMutation = {
  __typename?: "Mutation";
  updateGradeWeight: Array<{
    __typename?: "GradeWeight";
    id: string;
    gradeWeightType: GradeWeightEnum;
    value: number;
  }>;
};

export type OverridePlayerGradeMutationVariables = Exact<{
  overridePlayerGradeRequest: OverridePlayerGradeRequest;
}>;

export type OverridePlayerGradeMutation = {
  __typename?: "Mutation";
  overridePlayerGrade?: {
    __typename?: "PlayerGrade";
    playerId: string;
    id: string;
    avgClubGapypocDollars: number;
    value: {
      __typename?: "PlayerGradeValue";
      gapypoc: number;
      gapypocDollars: number;
    };
    seasons: Array<{
      __typename?: "PlayerGradeSeason";
      season: number;
      gradeType?: GradeWeightEnum | null;
      lastUpdated: string;
      value: {
        __typename?: "PlayerGradeValue";
        value: string;
        rawValue?: string | null;
        rawValueOverride?: string | null;
        gapypoc: number;
        gapypocDollars: number;
      };
    }>;
  } | null;
};

export type GetGradeWeightsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGradeWeightsQuery = {
  __typename?: "Query";
  gradeWeights: Array<{
    __typename?: "GradeWeight";
    id: string;
    gradeWeightType: GradeWeightEnum;
    value: number;
  }>;
};

export type CreateInjuryCollegeMutationVariables = Exact<{
  createInjuryCollegeRequest: CreatePlayerInjuryColWithDetailsRequest;
}>;

export type CreateInjuryCollegeMutation = {
  __typename?: "Mutation";
  createInjuryCollege?: {
    __typename?: "PlayerInjuryCollege";
    id: string;
    playerId: string;
    schoolId?: string | null;
    dateTime: string;
    source?: string | null;
    injuryType?: string | null;
    injuryDetail?: string | null;
    injuryStatus?: string | null;
  } | null;
};

export type CreateInjuryProMutationVariables = Exact<{
  createInjuryProRequest: CreatePlayerInjuryProWithDetailsRequest;
}>;

export type CreateInjuryProMutation = {
  __typename?: "Mutation";
  createInjuryPro?: {
    __typename?: "PlayerInjuryPro";
    id: string;
    playerId: string;
    clubId?: string | null;
    dateTime?: string | null;
    source?: string | null;
    injury1?: string | null;
    injuryDesc1?: string | null;
    injuryStatus: string;
  } | null;
};

export type UpdateInjuryCollegeMutationVariables = Exact<{
  updateInjuryCollegeRequest: UpdatePlayerInjuryCollegeWithDetailsRequest;
}>;

export type UpdateInjuryCollegeMutation = {
  __typename?: "Mutation";
  updateInjuryCollege?: {
    __typename?: "PlayerInjuryCollege";
    id: string;
    playerId: string;
    schoolId?: string | null;
    dateTime: string;
    source?: string | null;
    injuryType?: string | null;
    injuryDetail?: string | null;
    injuryStatus?: string | null;
  } | null;
};

export type UpdateInjuryProMutationVariables = Exact<{
  updateInjuryProRequest: UpdatePlayerInjuryProWithDetailsRequest;
}>;

export type UpdateInjuryProMutation = {
  __typename?: "Mutation";
  updateInjuryPro?: {
    __typename?: "PlayerInjuryPro";
    id: string;
    playerId: string;
    clubId?: string | null;
    dateTime?: string | null;
    source?: string | null;
    injury1?: string | null;
    injuryDesc1?: string | null;
    injuryStatus: string;
  } | null;
};

export type GetInjuriesForProPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetInjuriesForProPlayerQuery = {
  __typename?: "Query";
  playerInjuryPro?: {
    __typename?: "PlayerInjuryProConnection";
    edges?: Array<{
      __typename?: "PlayerInjuryProEdge";
      node: {
        __typename?: "PlayerInjuryPro";
        id: string;
        playerId: string;
        source?: string | null;
        season?: number | null;
        injury1?: string | null;
        injuryDesc1?: string | null;
        injury2?: string | null;
        injuryDesc2?: string | null;
        leagueInjuryId?: number | null;
        reportType?: string | null;
        reportDay?: number | null;
        injuryStatus: string;
        dateTime?: string | null;
        clubId?: string | null;
        club?: { __typename?: "Club"; clubCode: string } | null;
      };
    }> | null;
  } | null;
};

export type GetInjuriesForCollegePlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetInjuriesForCollegePlayerQuery = {
  __typename?: "Query";
  playerInjuryCollege?: {
    __typename?: "PlayerInjuryCollegeConnection";
    edges?: Array<{
      __typename?: "PlayerInjuryCollegeEdge";
      node: {
        __typename?: "PlayerInjuryCollege";
        id: string;
        playerId: string;
        source?: string | null;
        dateTime: string;
        injuryStatus?: string | null;
        injuryType?: string | null;
        injuryDetail?: string | null;
        injurySide?: string | null;
        school?: { __typename?: "School"; schoolCode?: string | null } | null;
      };
    }> | null;
  } | null;
};

export type GetWorkoutsByPlayerQueryVariables = Exact<{
  playerId: Scalars["UUID"];
}>;

export type GetWorkoutsByPlayerQuery = {
  __typename?: "Query";
  workoutsByPlayerId: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      workoutDate: string;
      comments?: string | null;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
      school?: { __typename?: "School"; schoolCode?: string | null } | null;
    };
    measureType: {
      __typename?: "MeasureType";
      name: string;
      shortName: string;
      isFraction: boolean;
      precision: number;
    };
  }>;
};

export type GetWorkoutMeasuresByPlayerQueryVariables = Exact<{
  playerId: Scalars["UUID"];
}>;

export type GetWorkoutMeasuresByPlayerQuery = {
  __typename?: "Query";
  workoutMeasuresByPlayerId: Array<{
    __typename?: "WorkoutMeasure";
    name: string;
    measure: number;
  }>;
};

export type GetWorkoutMeasuresBySchoolQueryVariables = Exact<{
  schoolId: Scalars["UUID"];
}>;

export type GetWorkoutMeasuresBySchoolQuery = {
  __typename?: "Query";
  workoutsBySchoolId: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      workoutDate: string;
      comments?: string | null;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
      school?: { __typename?: "School"; schoolCode?: string | null } | null;
    };
    measureType: {
      __typename?: "MeasureType";
      name: string;
      shortName: string;
      isFraction: boolean;
      precision: number;
    };
  }>;
};

export type GetMeasureTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeasureTypesQuery = {
  __typename?: "Query";
  measureTypes: Array<{
    __typename?: "MeasureType";
    id: string;
    name: string;
    shortName: string;
    description: string;
    precision: number;
    isFraction: boolean;
    priority: number;
  }>;
};

export type MeasureTypeFragment = {
  __typename?: "MeasureType";
  id: string;
  name: string;
  shortName: string;
  description: string;
  precision: number;
  isFraction: boolean;
  priority: number;
};

export type PlayerMeasureFragment = {
  __typename?: "WorkoutPlayerMeasure";
  id: string;
  playerId: string;
  value: number;
  isVerified: boolean;
  workout: {
    __typename?: "Workout";
    id: string;
    workoutDate: string;
    comments?: string | null;
    workoutType: { __typename?: "WorkoutType"; name: string; priority: number };
    school?: { __typename?: "School"; schoolCode?: string | null } | null;
  };
  measureType: {
    __typename?: "MeasureType";
    name: string;
    shortName: string;
    isFraction: boolean;
    precision: number;
  };
};

export type SearchAllQueryVariables = Exact<{
  searchTerm: Scalars["String"];
}>;

export type SearchAllQuery = {
  __typename?: "Query";
  searchAll: Array<{
    __typename?: "AnyEntitySearchResult";
    score?: number | null;
    player?: {
      __typename?: "SearchResultOfPlayerSearchDocument";
      searchScore?: number | null;
      record: {
        __typename?: "PlayerSearchDocument";
        clubCode?: string | null;
        clubId?: string | null;
        clubName?: string | null;
        schoolId?: string | null;
        schoolCode?: string | null;
        draftYear?: number | null;
        firstName?: string | null;
        lastName?: string | null;
        hometown?: string | null;
        height?: number | null;
        sumerAtlasPositionShortName?: string | null;
        sumerGeneralPositionShortName?: string | null;
        primarySchemePositionShortName?: string | null;
        player: { __typename?: "Player"; id: string };
      };
    } | null;
    school?: {
      __typename?: "SearchResultOfSchoolSearchDocument";
      searchScore?: number | null;
      record: {
        __typename?: "SchoolSearchDocument";
        id: string;
        schoolCode?: string | null;
        schoolName: string;
      };
    } | null;
    club?: {
      __typename?: "SearchResultOfClubSearchDocument";
      searchScore?: number | null;
      record: {
        __typename?: "ClubSearchDocument";
        id: string;
        clubCode: string;
        clubName: string;
      };
    } | null;
  }>;
};

export type PositionFragment = {
  __typename?: "Position";
  id: string;
  shortName: string;
  sort?: number | null;
  sideOfBall?: SideOfBall | null;
  description?: string | null;
};

export type GetPositionsQueryVariables = Exact<{
  positionTypeFilter?: InputMaybe<PositionType>;
}>;

export type GetPositionsQuery = {
  __typename?: "Query";
  positions: Array<{
    __typename?: "Position";
    id: string;
    shortName: string;
    sort?: number | null;
    sideOfBall?: SideOfBall | null;
    description?: string | null;
  }>;
};

export type GetCurrentSeasonQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentSeasonQuery = {
  __typename?: "Query";
  currentSeason?: {
    __typename?: "Calendar";
    calendarDate: string;
    season: number;
    description?: string | null;
    isRegSeason: boolean;
    isPostSeason: boolean;
    isDraft: boolean;
  } | null;
};

export type CreateRoleMutationVariables = Exact<{
  request: CreateRoleRequest;
}>;

export type CreateRoleMutation = {
  __typename?: "Mutation";
  createRole: {
    __typename?: "AppRole";
    id: string;
    name: string;
    description?: string | null;
    permissions: Array<{
      __typename?: "Permission";
      id: string;
      name: string;
      description?: string | null;
    }>;
  };
};

export type UpdateRoleMutationVariables = Exact<{
  request: UpdateRoleRequest;
}>;

export type UpdateRoleMutation = {
  __typename?: "Mutation";
  updateRole: {
    __typename?: "AppRole";
    id: string;
    name: string;
    description?: string | null;
    permissions: Array<{
      __typename?: "Permission";
      id: string;
      name: string;
      description?: string | null;
    }>;
  };
};

export type AssignPermissionsToRoleMutationVariables = Exact<{
  request: AssignPermissionsToRoleRequest;
}>;

export type AssignPermissionsToRoleMutation = {
  __typename?: "Mutation";
  assignPermissionsToRole: {
    __typename?: "AppRole";
    id: string;
    permissions: Array<{
      __typename?: "Permission";
      id: string;
      name: string;
      description?: string | null;
    }>;
  };
};

export type RemovePermissionsFromRoleMutationVariables = Exact<{
  request: RemovePermissionsFromRoleRequest;
}>;

export type RemovePermissionsFromRoleMutation = {
  __typename?: "Mutation";
  removePermissionsFromRole: {
    __typename?: "AppRole";
    id: string;
    permissions: Array<{
      __typename?: "Permission";
      id: string;
      name: string;
      description?: string | null;
    }>;
  };
};

export type CreatePermissionMutationVariables = Exact<{
  request: CreatePermissionRequest;
}>;

export type CreatePermissionMutation = {
  __typename?: "Mutation";
  createPermission: {
    __typename?: "Permission";
    id: string;
    name: string;
    description?: string | null;
  };
};

export type UpdatePermissionMutationVariables = Exact<{
  request: UpdatePermissionRequest;
}>;

export type UpdatePermissionMutation = {
  __typename?: "Mutation";
  updatePermission: {
    __typename?: "Permission";
    id: string;
    name: string;
    description?: string | null;
  };
};

export type GetPermissionByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetPermissionByIdQuery = {
  __typename?: "Query";
  permissions: Array<{
    __typename?: "Permission";
    id: string;
    name: string;
    description?: string | null;
    roles: Array<{ __typename?: "AppRole"; id: string; name: string }>;
  }>;
};

export type GetPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPermissionsQuery = {
  __typename?: "Query";
  permissions: Array<{
    __typename?: "Permission";
    id: string;
    name: string;
    description?: string | null;
    roles: Array<{ __typename?: "AppRole"; id: string; name: string }>;
  }>;
};

export type UpdatePlayerMutationVariables = Exact<{
  updatePlayerRequest: UpdatePlayerRequest;
}>;

export type UpdatePlayerMutation = {
  __typename?: "Mutation";
  updatePlayer?: { __typename?: "Player"; id: string } | null;
};

export type UpdateBasicPlayerDetailsMutationVariables = Exact<{
  updateBasicPlayerDetailsRequest: UpdateBasicPlayerDetailsRequest;
}>;

export type UpdateBasicPlayerDetailsMutation = {
  __typename?: "Mutation";
  updateBasicPlayerDetails?: { __typename?: "Player"; id: string } | null;
};

export type AssignPlayerOverrideMutationVariables = Exact<{
  assignPlayerOverride: AssignPlayerOverrideRequest;
}>;

export type AssignPlayerOverrideMutation = {
  __typename?: "Mutation";
  assignPlayerOverride?: {
    __typename?: "Player";
    id: string;
    latestBirthDate?: string | null;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
  } | null;
};

export type SaveProjectedGamesMissedMutationVariables = Exact<{
  saveProjectedGamesMissedRequest: SaveProjectedGamesMissedRequest;
}>;

export type SaveProjectedGamesMissedMutation = {
  __typename?: "Mutation";
  saveProjectedGamesMissed?: {
    __typename?: "PlayerProjectedGamesMissed";
    id: string;
    value: number;
    projectedGamesMissed: number;
    projectedGamesMissedOverride?: number | null;
  } | null;
};

export type PlayerFragment = {
  __typename?: "Player";
  id: string;
  gsisId?: string | null;
  collegeGsisId?: string | null;
  firstName: string;
  lastName: string;
  jersey?: string | null;
  draftYear?: number | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  status: PlayerStatus;
  birthDate?: string | null;
  draftRound?: number | null;
  draftSelection?: number | null;
  onClubRoster: boolean;
  onCollegeRoster: boolean;
  hometownCity?: string | null;
  hometownState?: string | null;
  hometownCountry?: string | null;
  eligibilityYear?: number | null;
  eligibility?: EligibilityType | null;
  redshirt: boolean;
  headshotUrl?: string | null;
  nflExperience: number;
  minSalaryCredits?: number | null;
  accruedSeasons?: number | null;
  latestBirthDate?: string | null;
  draftClub?: { __typename?: "Club"; clubCode: string } | null;
  clubGradingPosition?: { __typename?: "Position"; shortName: string } | null;
  sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  playerPffPackagePositions: Array<{
    __typename?: "PlayerPackagePosition";
    depth: number;
    package: { __typename?: "Package"; name: string };
    position: { __typename?: "Position"; shortName: string };
  }>;
  school?: {
    __typename?: "School";
    id: string;
    schoolCode?: string | null;
    schoolName: string;
    nickname?: string | null;
    primaryColor?: string | null;
    secondaryColor?: string | null;
  } | null;
  club?: {
    __typename?: "Club";
    id: string;
    clubCode: string;
    clubName: string;
  } | null;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      evaluationId: string;
      id: string;
      alertId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        description: string;
      };
    }>;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
      quality: string;
    } | null;
  }>;
  favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
  grades: Array<{
    __typename?: "Grade";
    playerId: string;
    gradeType: GradeTypeEnum;
    value: number;
    dollars?: number | null;
    season: number;
    id: string;
  }>;
  workouts: Array<{
    __typename?: "WorkoutPlayerMeasure";
    value: number;
    measureType: {
      __typename?: "MeasureType";
      name: string;
      shortName: string;
      isFraction: boolean;
    };
    workout: {
      __typename?: "Workout";
      workoutDate: string;
      comments?: string | null;
      workoutType: { __typename?: "WorkoutType"; name: string };
    };
  }>;
  playerGrade?: {
    __typename?: "PlayerGrade";
    playerId: string;
    avgClubGapypocDollars: number;
    avgSageGapypocDollars: number;
    avgSumerScoutGapypocDollars: number;
    value: {
      __typename?: "PlayerGradeValue";
      value: string;
      gapypoc: number;
      gapypocDollars: number;
    };
    seasons: Array<{
      __typename?: "PlayerGradeSeason";
      season: number;
      gradeType?: GradeWeightEnum | null;
      lastUpdated: string;
      value: {
        __typename?: "PlayerGradeValue";
        value: string;
        gapypoc: number;
        gapypocDollars: number;
      };
    }>;
  } | null;
  projectedGamesMissed: Array<{
    __typename?: "PlayerProjectedGamesMissed";
    playerId: string;
    season: number;
    value: number;
    projectedGamesMissed: number;
    projectedGamesMissedOverride?: number | null;
  }>;
  playerOverrides: Array<{
    __typename?: "PlayerOverride";
    field: string;
    value?: string | null;
  }>;
};

export type GetPlayerQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetPlayerQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  } | null;
  playerById?: {
    __typename?: "Player";
    id: string;
    gsisId?: string | null;
    collegeGsisId?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    draftYear?: number | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    status: PlayerStatus;
    birthDate?: string | null;
    draftRound?: number | null;
    draftSelection?: number | null;
    onClubRoster: boolean;
    onCollegeRoster: boolean;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    eligibilityYear?: number | null;
    eligibility?: EligibilityType | null;
    redshirt: boolean;
    headshotUrl?: string | null;
    nflExperience: number;
    minSalaryCredits?: number | null;
    accruedSeasons?: number | null;
    latestBirthDate?: string | null;
    draftClub?: { __typename?: "Club"; clubCode: string } | null;
    clubGradingPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    playerPffPackagePositions: Array<{
      __typename?: "PlayerPackagePosition";
      depth: number;
      package: { __typename?: "Package"; name: string };
      position: { __typename?: "Position"; shortName: string };
    }>;
    school?: {
      __typename?: "School";
      id: string;
      schoolCode?: string | null;
      schoolName: string;
      nickname?: string | null;
      primaryColor?: string | null;
      secondaryColor?: string | null;
    } | null;
    club?: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        evaluationId: string;
        id: string;
        alertId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          description: string;
        };
      }>;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
        quality: string;
      } | null;
    }>;
    favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
    grades: Array<{
      __typename?: "Grade";
      playerId: string;
      gradeType: GradeTypeEnum;
      value: number;
      dollars?: number | null;
      season: number;
      id: string;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      value: number;
      measureType: {
        __typename?: "MeasureType";
        name: string;
        shortName: string;
        isFraction: boolean;
      };
      workout: {
        __typename?: "Workout";
        workoutDate: string;
        comments?: string | null;
        workoutType: { __typename?: "WorkoutType"; name: string };
      };
    }>;
    playerGrade?: {
      __typename?: "PlayerGrade";
      playerId: string;
      avgClubGapypocDollars: number;
      avgSageGapypocDollars: number;
      avgSumerScoutGapypocDollars: number;
      value: {
        __typename?: "PlayerGradeValue";
        value: string;
        gapypoc: number;
        gapypocDollars: number;
      };
      seasons: Array<{
        __typename?: "PlayerGradeSeason";
        season: number;
        gradeType?: GradeWeightEnum | null;
        lastUpdated: string;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
      }>;
    } | null;
    projectedGamesMissed: Array<{
      __typename?: "PlayerProjectedGamesMissed";
      playerId: string;
      season: number;
      value: number;
      projectedGamesMissed: number;
      projectedGamesMissedOverride?: number | null;
    }>;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
  } | null;
};

export type GetPlayerByGsisIdQueryVariables = Exact<{
  gsisId: Scalars["String"];
}>;

export type GetPlayerByGsisIdQuery = {
  __typename?: "Query";
  playerByGsisId?: {
    __typename?: "Player";
    id: string;
    gsisId?: string | null;
    collegeGsisId?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    draftYear?: number | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    status: PlayerStatus;
    birthDate?: string | null;
    draftRound?: number | null;
    draftSelection?: number | null;
    onClubRoster: boolean;
    onCollegeRoster: boolean;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    eligibilityYear?: number | null;
    eligibility?: EligibilityType | null;
    redshirt: boolean;
    headshotUrl?: string | null;
    nflExperience: number;
    minSalaryCredits?: number | null;
    accruedSeasons?: number | null;
    latestBirthDate?: string | null;
    draftClub?: { __typename?: "Club"; clubCode: string } | null;
    clubGradingPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    playerPffPackagePositions: Array<{
      __typename?: "PlayerPackagePosition";
      depth: number;
      package: { __typename?: "Package"; name: string };
      position: { __typename?: "Position"; shortName: string };
    }>;
    school?: {
      __typename?: "School";
      id: string;
      schoolCode?: string | null;
      schoolName: string;
      nickname?: string | null;
      primaryColor?: string | null;
      secondaryColor?: string | null;
    } | null;
    club?: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        evaluationId: string;
        id: string;
        alertId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          description: string;
        };
      }>;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
        quality: string;
      } | null;
    }>;
    favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
    grades: Array<{
      __typename?: "Grade";
      playerId: string;
      gradeType: GradeTypeEnum;
      value: number;
      dollars?: number | null;
      season: number;
      id: string;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      value: number;
      measureType: {
        __typename?: "MeasureType";
        name: string;
        shortName: string;
        isFraction: boolean;
      };
      workout: {
        __typename?: "Workout";
        workoutDate: string;
        comments?: string | null;
        workoutType: { __typename?: "WorkoutType"; name: string };
      };
    }>;
    playerGrade?: {
      __typename?: "PlayerGrade";
      playerId: string;
      avgClubGapypocDollars: number;
      avgSageGapypocDollars: number;
      avgSumerScoutGapypocDollars: number;
      value: {
        __typename?: "PlayerGradeValue";
        value: string;
        gapypoc: number;
        gapypocDollars: number;
      };
      seasons: Array<{
        __typename?: "PlayerGradeSeason";
        season: number;
        gradeType?: GradeWeightEnum | null;
        lastUpdated: string;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
      }>;
    } | null;
    projectedGamesMissed: Array<{
      __typename?: "PlayerProjectedGamesMissed";
      playerId: string;
      season: number;
      value: number;
      projectedGamesMissed: number;
      projectedGamesMissedOverride?: number | null;
    }>;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
  } | null;
};

export type PlayerPositionFragment = {
  __typename?: "PlayerPackagePosition";
  depth: number;
  package: { __typename?: "Package"; name: string };
  position: { __typename?: "Position"; shortName: string };
};

export type PlayerAvailabilityFragment = {
  __typename?: "Availability";
  availabilityPercentage: number;
  draftPick: {
    __typename?: "CurrentSeasonDraftAsset";
    overall: number;
    pickInRound: number;
    round: number;
    season: number;
    club: { __typename?: "Club"; id: string; clubCode: string };
    originalClub: { __typename?: "Club"; id: string };
  };
};

export type GetPlayersQueryVariables = Exact<{
  name?: InputMaybe<Scalars["String"]>;
  clubName?: InputMaybe<Scalars["String"]>;
  schoolCode?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  positionGroup?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  draftYear?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
}>;

export type GetPlayersQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  } | null;
  players: Array<{
    __typename?: "Player";
    id: string;
    gsisId?: string | null;
    collegeGsisId?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    draftYear?: number | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    status: PlayerStatus;
    birthDate?: string | null;
    draftRound?: number | null;
    draftSelection?: number | null;
    onClubRoster: boolean;
    onCollegeRoster: boolean;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    eligibilityYear?: number | null;
    eligibility?: EligibilityType | null;
    redshirt: boolean;
    headshotUrl?: string | null;
    nflExperience: number;
    minSalaryCredits?: number | null;
    accruedSeasons?: number | null;
    latestBirthDate?: string | null;
    draftClub?: { __typename?: "Club"; clubCode: string } | null;
    clubGradingPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    playerPffPackagePositions: Array<{
      __typename?: "PlayerPackagePosition";
      depth: number;
      package: { __typename?: "Package"; name: string };
      position: { __typename?: "Position"; shortName: string };
    }>;
    school?: {
      __typename?: "School";
      id: string;
      schoolCode?: string | null;
      schoolName: string;
      nickname?: string | null;
      primaryColor?: string | null;
      secondaryColor?: string | null;
    } | null;
    club?: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        evaluationId: string;
        id: string;
        alertId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          description: string;
        };
      }>;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
        quality: string;
      } | null;
    }>;
    favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
    grades: Array<{
      __typename?: "Grade";
      playerId: string;
      gradeType: GradeTypeEnum;
      value: number;
      dollars?: number | null;
      season: number;
      id: string;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      value: number;
      measureType: {
        __typename?: "MeasureType";
        name: string;
        shortName: string;
        isFraction: boolean;
      };
      workout: {
        __typename?: "Workout";
        workoutDate: string;
        comments?: string | null;
        workoutType: { __typename?: "WorkoutType"; name: string };
      };
    }>;
    playerGrade?: {
      __typename?: "PlayerGrade";
      playerId: string;
      avgClubGapypocDollars: number;
      avgSageGapypocDollars: number;
      avgSumerScoutGapypocDollars: number;
      value: {
        __typename?: "PlayerGradeValue";
        value: string;
        gapypoc: number;
        gapypocDollars: number;
      };
      seasons: Array<{
        __typename?: "PlayerGradeSeason";
        season: number;
        gradeType?: GradeWeightEnum | null;
        lastUpdated: string;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
      }>;
    } | null;
    projectedGamesMissed: Array<{
      __typename?: "PlayerProjectedGamesMissed";
      playerId: string;
      season: number;
      value: number;
      projectedGamesMissed: number;
      projectedGamesMissedOverride?: number | null;
    }>;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
  }>;
};

export type GetPlayersPagedQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  clubName?: InputMaybe<Scalars["String"]>;
  schoolCode?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  positionGroup?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  draftYear?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
}>;

export type GetPlayersPagedQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  } | null;
  playersPaged?: {
    __typename?: "PlayersPagedConnection";
    totalCount: number;
    edges?: Array<{
      __typename?: "PlayersPagedEdge";
      node: {
        __typename?: "Player";
        id: string;
        gsisId?: string | null;
        collegeGsisId?: string | null;
        firstName: string;
        lastName: string;
        jersey?: string | null;
        draftYear?: number | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        status: PlayerStatus;
        birthDate?: string | null;
        draftRound?: number | null;
        draftSelection?: number | null;
        onClubRoster: boolean;
        onCollegeRoster: boolean;
        hometownCity?: string | null;
        hometownState?: string | null;
        hometownCountry?: string | null;
        eligibilityYear?: number | null;
        eligibility?: EligibilityType | null;
        redshirt: boolean;
        headshotUrl?: string | null;
        nflExperience: number;
        minSalaryCredits?: number | null;
        accruedSeasons?: number | null;
        latestBirthDate?: string | null;
        draftClub?: { __typename?: "Club"; clubCode: string } | null;
        clubGradingPosition?: {
          __typename?: "Position";
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          shortName: string;
        } | null;
        playerPffPackagePositions: Array<{
          __typename?: "PlayerPackagePosition";
          depth: number;
          package: { __typename?: "Package"; name: string };
          position: { __typename?: "Position"; shortName: string };
        }>;
        school?: {
          __typename?: "School";
          id: string;
          schoolCode?: string | null;
          schoolName: string;
          nickname?: string | null;
          primaryColor?: string | null;
          secondaryColor?: string | null;
        } | null;
        club?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
          clubName: string;
        } | null;
        evaluations: Array<{
          __typename?: "Evaluation";
          id: string;
          alerts: Array<{
            __typename?: "EvaluationAlert";
            evaluationId: string;
            id: string;
            alertId: string;
            alert: {
              __typename?: "Alert";
              id: string;
              caption: string;
              abbreviation: string;
              description: string;
            };
          }>;
          scoutGrade?: {
            __typename?: "ScoutGrade";
            id: string;
            grade: number;
            quality: string;
          } | null;
        }>;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        grades: Array<{
          __typename?: "Grade";
          playerId: string;
          gradeType: GradeTypeEnum;
          value: number;
          dollars?: number | null;
          season: number;
          id: string;
        }>;
        workouts: Array<{
          __typename?: "WorkoutPlayerMeasure";
          value: number;
          measureType: {
            __typename?: "MeasureType";
            name: string;
            shortName: string;
            isFraction: boolean;
          };
          workout: {
            __typename?: "Workout";
            workoutDate: string;
            comments?: string | null;
            workoutType: { __typename?: "WorkoutType"; name: string };
          };
        }>;
        playerGrade?: {
          __typename?: "PlayerGrade";
          playerId: string;
          avgClubGapypocDollars: number;
          avgSageGapypocDollars: number;
          avgSumerScoutGapypocDollars: number;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
          seasons: Array<{
            __typename?: "PlayerGradeSeason";
            season: number;
            gradeType?: GradeWeightEnum | null;
            lastUpdated: string;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
          }>;
        } | null;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          playerId: string;
          season: number;
          value: number;
          projectedGamesMissed: number;
          projectedGamesMissedOverride?: number | null;
        }>;
        playerOverrides: Array<{
          __typename?: "PlayerOverride";
          field: string;
          value?: string | null;
        }>;
      };
    }> | null;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type GetOnRosterPlayersQueryVariables = Exact<{
  clubName?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
}>;

export type GetOnRosterPlayersQuery = {
  __typename?: "Query";
  players: Array<{
    __typename?: "Player";
    id: string;
    gsisId?: string | null;
    collegeGsisId?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    draftYear?: number | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    status: PlayerStatus;
    birthDate?: string | null;
    draftRound?: number | null;
    draftSelection?: number | null;
    onClubRoster: boolean;
    onCollegeRoster: boolean;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    eligibilityYear?: number | null;
    eligibility?: EligibilityType | null;
    redshirt: boolean;
    headshotUrl?: string | null;
    nflExperience: number;
    minSalaryCredits?: number | null;
    accruedSeasons?: number | null;
    latestBirthDate?: string | null;
    draftClub?: { __typename?: "Club"; clubCode: string } | null;
    clubGradingPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    playerPffPackagePositions: Array<{
      __typename?: "PlayerPackagePosition";
      depth: number;
      package: { __typename?: "Package"; name: string };
      position: { __typename?: "Position"; shortName: string };
    }>;
    school?: {
      __typename?: "School";
      id: string;
      schoolCode?: string | null;
      schoolName: string;
      nickname?: string | null;
      primaryColor?: string | null;
      secondaryColor?: string | null;
    } | null;
    club?: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        evaluationId: string;
        id: string;
        alertId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          description: string;
        };
      }>;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
        quality: string;
      } | null;
    }>;
    favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
    grades: Array<{
      __typename?: "Grade";
      playerId: string;
      gradeType: GradeTypeEnum;
      value: number;
      dollars?: number | null;
      season: number;
      id: string;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      value: number;
      measureType: {
        __typename?: "MeasureType";
        name: string;
        shortName: string;
        isFraction: boolean;
      };
      workout: {
        __typename?: "Workout";
        workoutDate: string;
        comments?: string | null;
        workoutType: { __typename?: "WorkoutType"; name: string };
      };
    }>;
    playerGrade?: {
      __typename?: "PlayerGrade";
      playerId: string;
      avgClubGapypocDollars: number;
      avgSageGapypocDollars: number;
      avgSumerScoutGapypocDollars: number;
      value: {
        __typename?: "PlayerGradeValue";
        value: string;
        gapypoc: number;
        gapypocDollars: number;
      };
      seasons: Array<{
        __typename?: "PlayerGradeSeason";
        season: number;
        gradeType?: GradeWeightEnum | null;
        lastUpdated: string;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
      }>;
    } | null;
    projectedGamesMissed: Array<{
      __typename?: "PlayerProjectedGamesMissed";
      playerId: string;
      season: number;
      value: number;
      projectedGamesMissed: number;
      projectedGamesMissedOverride?: number | null;
    }>;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
  }>;
};

export type SearchPlayersQueryVariables = Exact<{
  searchTerm: Scalars["String"];
  orderByFields?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  filters?: InputMaybe<PlayerFiltersInput>;
  options?: InputMaybe<SearchOptionsInput>;
}>;

export type SearchPlayersQuery = {
  __typename?: "Query";
  searchPlayers: {
    __typename?: "PlayerSearchResults";
    total: number;
    players: Array<{
      __typename?: "SearchResultOfPlayerSearchDocument";
      record: {
        __typename?: "PlayerSearchDocument";
        clubCode?: string | null;
        clubId?: string | null;
        clubName?: string | null;
        schoolId?: string | null;
        schoolCode?: string | null;
        schoolName?: string | null;
        draftYear?: number | null;
        firstName?: string | null;
        lastName?: string | null;
        hometown?: string | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        jersey?: string | null;
        displayPositionShortName?: string | null;
        sumerAtlasPositionShortName?: string | null;
        sumerGeneralPositionShortName?: string | null;
        primarySchemePositionShortName?: string | null;
        redshirt?: boolean | null;
        headshotUrl?: string | null;
        eligibility?: string | null;
        eligibilityYear?: number | null;
        bestClubGrade?: number | null;
        bestClubGradeType?: string | null;
        player: {
          __typename?: "Player";
          id: string;
          playerGrade?: {
            __typename?: "PlayerGrade";
            playerId: string;
            avgClubGapypocDollars: number;
            avgSageGapypocDollars: number;
            avgSumerScoutGapypocDollars: number;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
            seasons: Array<{
              __typename?: "PlayerGradeSeason";
              season: number;
              gradeType?: GradeWeightEnum | null;
              lastUpdated: string;
              value: {
                __typename?: "PlayerGradeValue";
                value: string;
                gapypoc: number;
                gapypocDollars: number;
              };
            }>;
          } | null;
          projectedGamesMissed: Array<{
            __typename?: "PlayerProjectedGamesMissed";
            id: string;
            season: number;
            value: number;
            projectedGamesMissed: number;
            projectedGamesMissedOverride?: number | null;
          }>;
        };
      };
    }>;
  };
};

export type GetDraftablePlayerPoolQueryVariables = Exact<{
  request: LoadDraftablePlayerPoolRequest;
}>;

export type GetDraftablePlayerPoolQuery = {
  __typename?: "Query";
  draftablePlayerPool: {
    __typename?: "DraftablePlayerPool";
    atPick?: {
      __typename?: "CurrentSeasonDraftAsset";
      overall: number;
      pickInRound: number;
      round: number;
      season: number;
      club: { __typename?: "Club"; id: string; clubCode: string };
      originalClub: { __typename?: "Club"; id: string };
    } | null;
    players: Array<{
      __typename?: "ClubDraftablePlayerAtPick";
      preDraftExpectedPick?: number | null;
      recommendationScore: number;
      projectedRosterValueDollars?: number | null;
      marketValue: { __typename?: "Value"; gapypoc: number; dollars: number };
      performanceValue?: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      } | null;
      rosterValueAdded?: {
        __typename?: "Value";
        gapypoc: number;
        dollars: number;
      } | null;
      availability?: {
        __typename?: "Availability";
        availabilityPercentage: number;
        draftPick: {
          __typename?: "CurrentSeasonDraftAsset";
          overall: number;
          pickInRound: number;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string; clubCode: string };
          originalClub: { __typename?: "Club"; id: string };
        };
      } | null;
      availabilityAtClubsNextPick?: {
        __typename?: "Availability";
        availabilityPercentage: number;
        draftPick: {
          __typename?: "CurrentSeasonDraftAsset";
          overall: number;
          pickInRound: number;
          round: number;
          season: number;
          club: { __typename?: "Club"; id: string; clubCode: string };
          originalClub: { __typename?: "Club"; id: string };
        };
      } | null;
      player: {
        __typename?: "Player";
        id: string;
        firstName: string;
        lastName: string;
        height?: number | null;
        weight?: number | null;
        headshotUrl?: string | null;
        school?: {
          __typename?: "School";
          id: string;
          schoolName: string;
          schoolCode?: string | null;
        } | null;
        playerGrade?: {
          __typename?: "PlayerGrade";
          playerId: string;
          avgClubGapypocDollars: number;
          avgSageGapypocDollars: number;
          avgSumerScoutGapypocDollars: number;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
          seasons: Array<{
            __typename?: "PlayerGradeSeason";
            season: number;
            gradeType?: GradeWeightEnum | null;
            lastUpdated: string;
            value: {
              __typename?: "PlayerGradeValue";
              value: string;
              gapypoc: number;
              gapypocDollars: number;
            };
          }>;
        } | null;
        workouts: Array<{
          __typename?: "WorkoutPlayerMeasure";
          value: number;
          measureType: { __typename?: "MeasureType"; name: string };
        }>;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        projectedGamesMissed: Array<{
          __typename?: "PlayerProjectedGamesMissed";
          value: number;
          season: number;
          projectedGamesMissed: number;
          projectedGamesMissedOverride?: number | null;
        }>;
        clubGradingPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          id: string;
          shortName: string;
        } | null;
      };
    }>;
  };
};

export type GetDraftablePlayerQueryVariables = Exact<{
  request: GetDraftablePlayerRequest;
}>;

export type GetDraftablePlayerQuery = {
  __typename?: "Query";
  draftablePlayer: {
    __typename?: "ClubDraftablePlayer";
    preDraftExpectedPick?: number | null;
    availabilities: Array<{
      __typename?: "Availability";
      availabilityPercentage: number;
      draftPick: {
        __typename?: "CurrentSeasonDraftAsset";
        overall: number;
        pickInRound: number;
        round: number;
        season: number;
        club: { __typename?: "Club"; id: string; clubCode: string };
        originalClub: { __typename?: "Club"; id: string };
      };
    }>;
    rosterValueAdded?: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    } | null;
    player: {
      __typename?: "Player";
      id: string;
      gsisId?: string | null;
      collegeGsisId?: string | null;
      firstName: string;
      lastName: string;
      jersey?: string | null;
      draftYear?: number | null;
      height?: number | null;
      weight?: number | null;
      speed?: number | null;
      status: PlayerStatus;
      birthDate?: string | null;
      draftRound?: number | null;
      draftSelection?: number | null;
      onClubRoster: boolean;
      onCollegeRoster: boolean;
      hometownCity?: string | null;
      hometownState?: string | null;
      hometownCountry?: string | null;
      eligibilityYear?: number | null;
      eligibility?: EligibilityType | null;
      redshirt: boolean;
      headshotUrl?: string | null;
      nflExperience: number;
      minSalaryCredits?: number | null;
      accruedSeasons?: number | null;
      latestBirthDate?: string | null;
      draftClub?: { __typename?: "Club"; clubCode: string } | null;
      clubGradingPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      sumerAtlasPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      playerPffPackagePositions: Array<{
        __typename?: "PlayerPackagePosition";
        depth: number;
        package: { __typename?: "Package"; name: string };
        position: { __typename?: "Position"; shortName: string };
      }>;
      school?: {
        __typename?: "School";
        id: string;
        schoolCode?: string | null;
        schoolName: string;
        nickname?: string | null;
        primaryColor?: string | null;
        secondaryColor?: string | null;
      } | null;
      club?: {
        __typename?: "Club";
        id: string;
        clubCode: string;
        clubName: string;
      } | null;
      evaluations: Array<{
        __typename?: "Evaluation";
        id: string;
        alerts: Array<{
          __typename?: "EvaluationAlert";
          evaluationId: string;
          id: string;
          alertId: string;
          alert: {
            __typename?: "Alert";
            id: string;
            caption: string;
            abbreviation: string;
            description: string;
          };
        }>;
        scoutGrade?: {
          __typename?: "ScoutGrade";
          id: string;
          grade: number;
          quality: string;
        } | null;
      }>;
      favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
      grades: Array<{
        __typename?: "Grade";
        playerId: string;
        gradeType: GradeTypeEnum;
        value: number;
        dollars?: number | null;
        season: number;
        id: string;
      }>;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        value: number;
        measureType: {
          __typename?: "MeasureType";
          name: string;
          shortName: string;
          isFraction: boolean;
        };
        workout: {
          __typename?: "Workout";
          workoutDate: string;
          comments?: string | null;
          workoutType: { __typename?: "WorkoutType"; name: string };
        };
      }>;
      playerGrade?: {
        __typename?: "PlayerGrade";
        playerId: string;
        avgClubGapypocDollars: number;
        avgSageGapypocDollars: number;
        avgSumerScoutGapypocDollars: number;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
        seasons: Array<{
          __typename?: "PlayerGradeSeason";
          season: number;
          gradeType?: GradeWeightEnum | null;
          lastUpdated: string;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
        }>;
      } | null;
      projectedGamesMissed: Array<{
        __typename?: "PlayerProjectedGamesMissed";
        playerId: string;
        season: number;
        value: number;
        projectedGamesMissed: number;
        projectedGamesMissedOverride?: number | null;
      }>;
      playerOverrides: Array<{
        __typename?: "PlayerOverride";
        field: string;
        value?: string | null;
      }>;
    };
  };
};

export type GetCurrentRosterValueQueryVariables = Exact<{
  request: GetCurrentRosterValueRequest;
}>;

export type GetCurrentRosterValueQuery = {
  __typename?: "Query";
  currentRosterValue: Array<{
    __typename?: "RosterValuePlayer";
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      headshotUrl?: string | null;
      school?: { __typename?: "School"; schoolName: string } | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      clubGradingPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      playerGrade?: {
        __typename?: "PlayerGrade";
        playerId: string;
        avgClubGapypocDollars: number;
        avgSageGapypocDollars: number;
        avgSumerScoutGapypocDollars: number;
        value: {
          __typename?: "PlayerGradeValue";
          value: string;
          gapypoc: number;
          gapypocDollars: number;
        };
        seasons: Array<{
          __typename?: "PlayerGradeSeason";
          season: number;
          gradeType?: GradeWeightEnum | null;
          lastUpdated: string;
          value: {
            __typename?: "PlayerGradeValue";
            value: string;
            gapypoc: number;
            gapypocDollars: number;
          };
        }>;
      } | null;
      projectedGamesMissed: Array<{
        __typename?: "PlayerProjectedGamesMissed";
        id: string;
        season: number;
        value: number;
        projectedGamesMissed: number;
        projectedGamesMissedOverride?: number | null;
      }>;
    };
    rosterValueAdded: {
      __typename?: "Value";
      dollars: number;
      gapypoc: number;
    };
  }>;
};

export type GetGradeWeightPreviewQueryVariables = Exact<{
  request: GetGradeWeightPreviewRequest;
}>;

export type GetGradeWeightPreviewQuery = {
  __typename?: "Query";
  gradeWeightPreview: Array<{
    __typename?: "RosterValuePlayerWeighted";
    playerId: string;
    overallGrade: {
      __typename?: "PlayerGrade";
      value: {
        __typename?: "PlayerGradeValue";
        gapypoc: number;
        gapypocDollars: number;
      };
    };
    rosterValueAdded: {
      __typename?: "Value";
      gapypoc: number;
      dollars: number;
    };
  }>;
};

export type GetRoleByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetRoleByIdQuery = {
  __typename?: "Query";
  roles: Array<{
    __typename?: "AppRole";
    id: string;
    name: string;
    type: RoleType;
    permissions: Array<{
      __typename?: "Permission";
      id: string;
      name: string;
      description?: string | null;
    }>;
  }>;
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = {
  __typename?: "Query";
  roles: Array<{
    __typename?: "AppRole";
    id: string;
    name: string;
    type: RoleType;
    permissions: Array<{
      __typename?: "Permission";
      id: string;
      name: string;
      description?: string | null;
      roles: Array<{ __typename?: "AppRole"; id: string; name: string }>;
    }>;
  }>;
};

export type AssumptionDetailsFragment = {
  __typename?: "Assumption";
  id: string;
  description: string;
  playerId?: string | null;
  contractMinimumEndDate?: any | null;
  constraint: Constraint;
  subject: Subject;
  contracts: Array<{ __typename?: "NYM"; id: string }>;
  draftPick?: {
    __typename?: "DraftPick";
    season: number;
    round?: number | null;
    overall?: number | null;
    number?: number | null;
  } | null;
};

export type CreateScenarioMutationVariables = Exact<{
  request: CreateScenarioRequestInput;
}>;

export type CreateScenarioMutation = {
  __typename?: "Mutation";
  createScenario: { __typename?: "Scenario"; id: string; name: string };
};

export type ImportGlobalAssumptionsMutationVariables = Exact<{
  scenarioId: Scalars["UUID"];
}>;

export type ImportGlobalAssumptionsMutation = {
  __typename?: "Mutation";
  importGlobalAssumptions: {
    __typename?: "Scenario";
    id: string;
    assumptionsStale: boolean;
    assumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
  };
};

export type SetOptimizationSettingsMutationVariables = Exact<{
  request: SetOptimizationSettingsRequest;
}>;

export type SetOptimizationSettingsMutation = {
  __typename?: "Mutation";
  setOptimizationSettings: {
    __typename?: "Scenario";
    id: string;
    name: string;
    optimizationSettings: {
      __typename?: "OptimizationSettings";
      horizonYears: number;
      startingSeason: number;
      seasonWeights: Array<number>;
    };
  };
};

export type HideScenarioMutationVariables = Exact<{
  scenarioId: Scalars["UUID"];
}>;

export type HideScenarioMutation = {
  __typename?: "Mutation";
  hideScenario: {
    __typename?: "Scenario";
    name: string;
    id: string;
    isHidden: boolean;
  };
};

export type UnHideScenarioMutationVariables = Exact<{
  scenarioId: Scalars["UUID"];
}>;

export type UnHideScenarioMutation = {
  __typename?: "Mutation";
  unHideScenario: {
    __typename?: "Scenario";
    name: string;
    id: string;
    isHidden: boolean;
  };
};

export type RunOptimizerMutationVariables = Exact<{
  request: StartOptimizationCommandRequest;
}>;

export type RunOptimizerMutation = {
  __typename?: "Mutation";
  runOptimizer: {
    __typename?: "Scenario";
    id: string;
    optimizerRunInProgress: boolean;
  };
};

export type RenameScenarioMutationVariables = Exact<{
  request: RenameScenarioCommandRequest;
}>;

export type RenameScenarioMutation = {
  __typename?: "Mutation";
  renameScenario: { __typename?: "Scenario"; id: string; name: string };
};

export type DeleteAssumptionMutationVariables = Exact<{
  request: DeleteAssumptionCommandRequest;
}>;

export type DeleteAssumptionMutation = {
  __typename?: "Mutation";
  deleteAssumption: {
    __typename?: "Scenario";
    id: string;
    localAssumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
  };
};

export type AssumeDoNotDraftMutationVariables = Exact<{
  request: AddDoNotDraftAssumptionCommandRequest;
}>;

export type AssumeDoNotDraftMutation = {
  __typename?: "Mutation";
  assumeDoNotDraft: {
    __typename?: "Scenario";
    id: string;
    localAssumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
  };
};

export type AssumeDoNotDraftUntilRoundMutationVariables = Exact<{
  request: AddDoNotDraftUntilRoundAssumptionCommandRequest;
}>;

export type AssumeDoNotDraftUntilRoundMutation = {
  __typename?: "Mutation";
  assumeDoNotDraftUntilRound: {
    __typename?: "Scenario";
    id: string;
    localAssumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
  };
};

export type AssumePlayerDraftedAtPickMutationVariables = Exact<{
  request: AddDraftWithPickAssumptionCommandRequest;
}>;

export type AssumePlayerDraftedAtPickMutation = {
  __typename?: "Mutation";
  assumePlayerDraftedAtPick: {
    __typename?: "Scenario";
    id: string;
    localAssumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
  };
};

export type AssumeSignedPlayerMutationVariables = Exact<{
  request: AddMustSignPlayerAssumptionCommandRequest;
}>;

export type AssumeSignedPlayerMutation = {
  __typename?: "Mutation";
  assumeSignedPlayer: {
    __typename?: "Scenario";
    id: string;
    localAssumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
  };
};

export type AssumePlayerCutMutationVariables = Exact<{
  request: AddCutPlayerAssumptionCommandRequest;
}>;

export type AssumePlayerCutMutation = {
  __typename?: "Mutation";
  assumePlayerCut: {
    __typename?: "Scenario";
    id: string;
    localAssumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
  };
};

export type AssumePlayerKeptUntilDateMutationVariables = Exact<{
  request: AddKeepUntilDateAssumptionCommandRequest;
}>;

export type AssumePlayerKeptUntilDateMutation = {
  __typename?: "Mutation";
  assumePlayerKeptUntilDate: {
    __typename?: "Scenario";
    id: string;
    localAssumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
  };
};

export type AssumeSignedPlayerThatCantBeCutMutationVariables = Exact<{
  request: AddMustSignPlayerThatCantBeCutAssumptionCommandRequest;
}>;

export type AssumeSignedPlayerThatCantBeCutMutation = {
  __typename?: "Mutation";
  assumeSignedPlayerThatCantBeCut: {
    __typename?: "Scenario";
    id: string;
    localAssumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
  };
};

export type AssumeSignedPlayerToAnyContractMutationVariables = Exact<{
  request: AddSignPlayerToAnyContractAssumptionCommandRequest;
}>;

export type AssumeSignedPlayerToAnyContractMutation = {
  __typename?: "Mutation";
  assumeSignedPlayerToAnyContract: {
    __typename?: "Scenario";
    id: string;
    localAssumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
  };
};

export type AssumeMustNotSignPlayerMutationVariables = Exact<{
  request: AddMustNotSignPlayerCommandRequest;
}>;

export type AssumeMustNotSignPlayerMutation = {
  __typename?: "Mutation";
  assumeMustNotSignPlayer: {
    __typename?: "Scenario";
    id: string;
    localAssumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
  };
};

export type ScenarioUpdatesSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ScenarioUpdatesSubscription = {
  __typename?: "Subscription";
  scenarioUpdates: {
    __typename?: "ScenarioUpdatedEvent";
    id: string;
    scenarioLastUpdated: string;
    scenarioId: string;
  };
};

export type ScenarioUpdatedSubscriptionVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type ScenarioUpdatedSubscription = {
  __typename?: "Subscription";
  scenarioUpdated: {
    __typename?: "ScenarioUpdatedEvent";
    id: string;
    scenarioLastUpdated: string;
    scenarioId: string;
  };
};

export type GetScenarioDraftAvailabilitiesQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetScenarioDraftAvailabilitiesQuery = {
  __typename?: "Query";
  scenarioById: {
    __typename?: "Scenario";
    name: string;
    id: string;
    baseState: {
      __typename?: "BaseScenarioState";
      draftAvailabilitiesStale: boolean;
      draftAvailabilities: Array<{
        __typename?: "DraftAvailability";
        playerId: string;
        pick: {
          __typename?: "DraftPick";
          season: number;
          overall?: number | null;
          round?: number | null;
        };
      }>;
    };
    clubDraftPicks: Array<{
      __typename?: "DraftPick";
      season: number;
      round?: number | null;
      overall?: number | null;
    }>;
  };
};

export type GetScenarioQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetScenarioQuery = {
  __typename?: "Query";
  scenarioById: {
    __typename?: "Scenario";
    name: string;
    id: string;
    createdAt: string;
    lastUpdated: string;
    isHidden: boolean;
    assumptionsStale: boolean;
    optimizerInputVersion: number;
    optimizerRunInProgress: boolean;
    optimizerRunStartTime?: string | null;
    optimizationSettings: {
      __typename?: "OptimizationSettings";
      horizonYears: number;
      startingSeason: number;
      seasonWeights: Array<number>;
    };
    baseState: {
      __typename?: "BaseScenarioState";
      draftAvailabilitiesStale: boolean;
    };
    localAssumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
    assumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
    optimizerRunResult?: {
      __typename?: "OptimizerRunResult";
      recordedAt: string;
      inputVersion: number;
    } | null;
    optimizerRunError?: {
      __typename?: "OptimizerRunError";
      error?: string | null;
      errorCode?: number | null;
      recordedAt: string;
      inputVersion: number;
    } | null;
    clubDraftPicks: Array<{
      __typename?: "DraftPick";
      season: number;
      round?: number | null;
      overall?: number | null;
    }>;
  };
};

export type GetScenariosQueryVariables = Exact<{ [key: string]: never }>;

export type GetScenariosQuery = {
  __typename?: "Query";
  scenarios: Array<{
    __typename?: "Scenario";
    name: string;
    id: string;
    lastUpdated: string;
    optimizerRunInProgress: boolean;
    localAssumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
    assumptions: Array<{
      __typename?: "Assumption";
      id: string;
      description: string;
      playerId?: string | null;
      contractMinimumEndDate?: any | null;
      constraint: Constraint;
      subject: Subject;
      contracts: Array<{ __typename?: "NYM"; id: string }>;
      draftPick?: {
        __typename?: "DraftPick";
        season: number;
        round?: number | null;
        overall?: number | null;
        number?: number | null;
      } | null;
    }>;
    optimizerRunResult?: {
      __typename?: "OptimizerRunResult";
      recordedAt: string;
    } | null;
    optimizerRunError?: {
      __typename?: "OptimizerRunError";
      error?: string | null;
      errorCode?: number | null;
      recordedAt: string;
    } | null;
  }>;
};

export type SchoolFragment = {
  __typename?: "School";
  id: string;
  schoolCode?: string | null;
  schoolName: string;
  nickname?: string | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  conference?: string | null;
  division?: string | null;
};

export type GetSchoolsByDivisionQueryVariables = Exact<{
  division?: InputMaybe<Scalars["String"]>;
}>;

export type GetSchoolsByDivisionQuery = {
  __typename?: "Query";
  schools: Array<{
    __typename?: "School";
    id: string;
    schoolCode?: string | null;
    schoolName: string;
    nickname?: string | null;
    primaryColor?: string | null;
    secondaryColor?: string | null;
    conference?: string | null;
    division?: string | null;
  }>;
};

export type GetSchoolQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetSchoolQuery = {
  __typename?: "Query";
  schoolById?: {
    __typename?: "School";
    id: string;
    schoolCode?: string | null;
    schoolName: string;
    nickname?: string | null;
    primaryColor?: string | null;
    secondaryColor?: string | null;
    conference?: string | null;
    division?: string | null;
  } | null;
};

export type GetSchoolsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSchoolsQuery = {
  __typename?: "Query";
  schools: Array<{
    __typename?: "School";
    id: string;
    schoolCode?: string | null;
    schoolName: string;
    nickname?: string | null;
    primaryColor?: string | null;
    secondaryColor?: string | null;
    conference?: string | null;
    division?: string | null;
  }>;
};

export type GetGameByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetGameByIdQuery = {
  __typename?: "Query";
  games: Array<{
    __typename?: "Game";
    id: string;
    season: number;
    week?: number | null;
    venue?: string | null;
    homeTeam?: string | null;
    awayTeam?: string | null;
    homeTeamScore?: number | null;
    awayTeamScore?: number | null;
    kickoffTime?: string | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    weekType: {
      __typename?: "WeekType";
      id: number;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
  }>;
};

export type GetTeamGamesBySeasonQueryVariables = Exact<{
  season: Scalars["Int"];
  league: Scalars["Int"];
  team?: InputMaybe<Scalars["String"]>;
}>;

export type GetTeamGamesBySeasonQuery = {
  __typename?: "Query";
  games: Array<{
    __typename?: "Game";
    id: string;
    season: number;
    week?: number | null;
    venue?: string | null;
    homeTeam?: string | null;
    awayTeam?: string | null;
    homeTeamScore?: number | null;
    awayTeamScore?: number | null;
    kickoffTime?: string | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    weekType: {
      __typename?: "WeekType";
      id: number;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
  }>;
};

export type GameFragment = {
  __typename?: "Game";
  id: string;
  season: number;
  week?: number | null;
  venue?: string | null;
  homeTeam?: string | null;
  awayTeam?: string | null;
  homeTeamScore?: number | null;
  awayTeamScore?: number | null;
  kickoffTime?: string | null;
  seasonType: {
    __typename?: "SeasonType";
    id: number;
    abbreviation?: string | null;
    description?: string | null;
  };
  weekType: {
    __typename?: "WeekType";
    id: number;
    description?: string | null;
  };
  leagueType: {
    __typename?: "LeagueType";
    id: number;
    description?: string | null;
  };
};

export type GetCareerStatsForPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetCareerStatsForPlayerQuery = {
  __typename?: "Query";
  careerStats: Array<{
    __typename?: "CareerStat";
    perGameStatsGamesPlayed?: number | null;
    perGameStatsPassAttemptsPerGame?: number | null;
    perGameStatsPassingYardsPerGame?: number | null;
    perGameStatsReceivingYardsPerGame?: number | null;
    perGameStatsReceptionsPerGame?: number | null;
    perGameStatsRushAttemptsPerGame?: number | null;
    perGameStatsRushingYardsPerGame?: number | null;
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    playerStat?: {
      __typename?: "PlayerStat";
      rushingStatsYards?: number | null;
      rushingStatsAttempts?: number | null;
      rushingStatsTouchdowns?: number | null;
      rushingStatsRunsTwentyYardsPlus?: number | null;
      rushingStatsRunsFortyYardsPlus?: number | null;
      rushingStatsLongestRun?: number | null;
      rushingStatsFirstDownRushes?: number | null;
      rushingStatsFirstDownPercentage?: number | null;
      rushingStatsFumbles?: number | null;
      rushingStatsYardsAfterContact?: number | null;
      rushingStatsYardsAfterContactPerRush?: number | null;
      rushingStatsTacklesAvoided?: number | null;
      rushingStatsFumblesRecovered?: number | null;
      rushingStatsFumblesLost?: number | null;
      rushingStatsHurriesAllowed?: number | null;
      rushingStatsPenalties?: number | null;
      rushingStatsInjuriesInGame?: number | null;
      rushingStatsPressuresAllowed?: number | null;
      rushingStatsSacksAllowed?: number | null;
      rushingStatsYardsPerRush?: number | null;
      rushingStatsSnaps?: number | null;
      rushingStatsTeamSnaps?: number | null;
      rushingStatsSnapPercentage?: number | null;
      rushingStatsTwoPointAttempts?: number | null;
      rushingStatsTwoPointConversions?: number | null;
      returnStatsKickReturnYardsAverage?: number | null;
      returnStatsKickReturnAttempts?: number | null;
      returnStatsKickReturnYards?: number | null;
      returnStatsKickReturnTouchdowns?: number | null;
      returnStatsKickReturnsTwentyYardsPlus?: number | null;
      returnStatsKickReturnsFortyYardsPlus?: number | null;
      returnStatsKickReturnLongestYards?: number | null;
      returnStatsKickReturnFairCatches?: number | null;
      returnStatsKickReturnFumbles?: number | null;
      returnStatsKickReturnYardsAfterContact?: number | null;
      returnStatsKickReturnYardsAfterContactAverage?: number | null;
      returnStatsKickReturnSnaps?: number | null;
      returnStatsKickReturnTeamSnaps?: number | null;
      returnStatsKickReturnSnapPercentage?: number | null;
      returnStatsPuntReturnYardsAverage?: number | null;
      returnStatsPuntReturnAttempts?: number | null;
      returnStatsPuntReturnYards?: number | null;
      returnStatsPuntReturnTouchdowns?: number | null;
      returnStatsPuntReturnsTwentyYardsPlus?: number | null;
      returnStatsPuntReturnsFortyYardsPlus?: number | null;
      returnStatsPuntReturnLongestYards?: number | null;
      returnStatsPuntReturnFairCatches?: number | null;
      returnStatsPuntReturnFumbles?: number | null;
      returnStatsPuntReturnYardsAfterContact?: number | null;
      returnStatsPuntReturnYardsAfterContactAverage?: number | null;
      returnStatsPuntReturnSnaps?: number | null;
      returnStatsPuntReturnTeamSnaps?: number | null;
      returnStatsPuntReturnSnapPercentage?: number | null;
      receivingStatsReceptions?: number | null;
      receivingStatsTouchdowns?: number | null;
      receivingStatsYards?: number | null;
      receivingStatsYardsPerReception?: number | null;
      receivingStatsReceptionsTwentyYardsPlus?: number | null;
      receivingStatsReceptionsFortyYardsPlus?: number | null;
      receivingStatsLongestReception?: number | null;
      receivingStatsFirstDowns?: number | null;
      receivingStatsFirstDownPercentage?: number | null;
      receivingStatsReceivingFumbles?: number | null;
      receivingStatsYardsAfterCatch?: number | null;
      receivingStatsYardAfterCatchPerReception?: number | null;
      receivingStatsTargets?: number | null;
      receivingStatsTacklesAvoided?: number | null;
      receivingStatsDrops?: number | null;
      receivingStatsDropsPercentage?: number | null;
      receivingStatsYardsAfterContact?: number | null;
      receivingStatsInjuriesInGame?: number | null;
      receivingStatsPenalties?: number | null;
      receivingStatsCatchableThrows?: number | null;
      receivingStatsPassRouteDepthAverage?: number | null;
      receivingStatsSnaps?: number | null;
      receivingStatsTeamSnaps?: number | null;
      receivingStatsSnapPercentage?: number | null;
      receivingStatsPassRoutesRun?: number | null;
      receivingStatsReceptionsPerTouchdown?: number | null;
      receivingStatsTwoPointAttempts?: number | null;
      receivingStatsTwoPointConversions?: number | null;
      passingStatsYards?: number | null;
      passingStatsAttempts?: number | null;
      passingStatsCompletions?: number | null;
      passingStatsCompletionPercentage?: number | null;
      passingStatsYardsPerCompletionAverage?: number | null;
      passingStatsTouchdowns?: number | null;
      passingStatsTouchdownPercentage?: number | null;
      passingStatsInterceptions?: number | null;
      passingStatsInterceptionPercentage?: number | null;
      passingStatsPasserRating?: number | null;
      passingStatsFirstDowns?: number | null;
      passingStatsFirstDownPercentage?: number | null;
      passingStatsCompletionsTwentyYardsPlus?: number | null;
      passingStatsCompletionsFortyYardsPlus?: number | null;
      passingStatsLongestCompletion?: number | null;
      passingStatsSacks?: number | null;
      passingStatsSackYards?: number | null;
      passingStatsYardsAfterCatch?: number | null;
      passingStatsDrops?: number | null;
      passingStatsInjuriesInGame?: number | null;
      passingStatsFumbles?: number | null;
      passingStatsFumblesRecovered?: number | null;
      passingStatsHurriesAllowed?: number | null;
      passingStatsPassDepthAverage?: number | null;
      passingStatsPassDepthAttempts?: number | null;
      passingStatsCatchableThrows?: number | null;
      passingStatsCatchableThrowPercentage?: number | null;
      passingStatsPenalties?: number | null;
      passingStatsTimeToPressureAverage?: number | null;
      passingStatsTimeToPressureAttempts?: number | null;
      passingStatsTimeToThrowAverage?: number | null;
      passingStatsTimeToThrowAttempts?: number | null;
      passingStatsDropbackDepthAverage?: number | null;
      passingStatsDropbackAttempts?: number | null;
      passingStatsSnaps?: number | null;
      passingStatsTeamSnaps?: number | null;
      passingStatsSnapPercentage?: number | null;
      passingStatsPumpFakes?: number | null;
      passingStatsPassingEfficiency?: number | null;
      passingStatsYardsPerAttempt?: number | null;
      passingStatsTouchdownInterceptionRatio?: number | null;
      passingStatsTwoPointAttempts?: number | null;
      passingStatsTwoPointConversions?: number | null;
      defenseStatsFumblesForced?: number | null;
      defenseStatsTacklesSolo?: number | null;
      defenseStatsSacks?: number | null;
      defenseStatsSackPlays?: number | null;
      defenseStatsSackPercentage?: number | null;
      defenseStatsSnaps?: number | null;
      defenseStatsTeamSnaps?: number | null;
      defenseStatsSnapPercentage?: number | null;
      defenseStatsBattedPasses?: number | null;
      defenseStatsQbHits?: number | null;
      defenseStatsQbHitPercentage?: number | null;
      defenseStatsFumblesRecovered?: number | null;
      defenseStatsInjuriesInGame?: number | null;
      defenseStatsMissedTacklesOnQb?: number | null;
      defenseStatsPassBreakups?: number | null;
      defenseStatsStops?: number | null;
      defenseStatsTouchdowns?: number | null;
      defenseStatsPassRushAttempts?: number | null;
      defenseStatsCoverageAttempts?: number | null;
      defenseStatsBurns?: number | null;
      defenseStatsDefensiveTargets?: number | null;
      defenseStatsQbPressures?: number | null;
      defenseStatsFumblesRecoveredForTouchdown?: number | null;
      defenseStatsQbPressurePercentage?: number | null;
      defenseStatsQbHurries?: number | null;
      defenseStatsQbHurryPercentage?: number | null;
      defenseStatsMissedTackles?: number | null;
      defenseStatsPuntsBlocked?: number | null;
      defenseStatsFieldGoalsBlocked?: number | null;
      defenseStatsExtraPointsBlocked?: number | null;
      defenseStatsTacklesForLoss?: number | null;
      defenseStatsDroppedInterceptions?: number | null;
      defenseStatsInterceptions?: number | null;
      defenseStatsInterceptionTouchdowns?: number | null;
      defenseStatsInterceptionYards?: number | null;
      defenseStatsInterceptionLongestReturn?: number | null;
      defenseStatsInterceptionPercentage?: number | null;
      defenseStatsTacklesCombined?: number | null;
      defenseStatsTacklesAssisted?: number | null;
      kickingStatsFieldGoalsMade?: number | null;
      kickingStatsFieldGoalAttempts?: number | null;
      kickingStatsFieldGoalPercentage?: number | null;
      kickingStatsFieldGoals1To19Attempted?: number | null;
      kickingStatsFieldGoals1To19Made?: number | null;
      kickingStatsFieldGoals1To19Percentage?: number | null;
      kickingStatsFieldGoals20To29Attempted?: number | null;
      kickingStatsFieldGoals20To29Made?: number | null;
      kickingStatsFieldGoals20To29Percentage?: number | null;
      kickingStatsFieldGoals30To39Attempted?: number | null;
      kickingStatsFieldGoals30To39Made?: number | null;
      kickingStatsFieldGoals30To39Percentage?: number | null;
      kickingStatsFieldGoals40To49Attempted?: number | null;
      kickingStatsFieldGoals40To49Made?: number | null;
      kickingStatsFieldGoals40To49Percentage?: number | null;
      kickingStatsFieldGoals50To59Attempted?: number | null;
      kickingStatsFieldGoals50To59Made?: number | null;
      kickingStatsFieldGoals50To59Percentage?: number | null;
      kickingStatsFieldGoals60PlusAttempted?: number | null;
      kickingStatsFieldGoals60PlusMade?: number | null;
      kickingStatsFieldGoals60PlusPercentage?: number | null;
      kickingStatsFieldGoalMadeLongest?: number | null;
      kickingStatsFieldGoalsBlocked?: number | null;
      kickingStatsFieldGoalSnaps?: number | null;
      kickingStatsFieldGoalTeamSnaps?: number | null;
      kickingStatsFieldGoalSnapPercentage?: number | null;
      kickingStatsFieldGoalYardsAverage?: number | null;
      kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
      kickingStatsExtraPointAttempts?: number | null;
      kickingStatsExtraPointsMade?: number | null;
      kickingStatsExtraPointsBlocked?: number | null;
      kickingStatsExtraPointsPercentage?: number | null;
      kickingStatsPuntYardsAverage?: number | null;
      kickingStatsPuntYardsNetAverage?: number | null;
      kickingStatsPuntAttempts?: number | null;
      kickingStatsPuntYardsLongest?: number | null;
      kickingStatsPuntYards?: number | null;
      kickingStatsPuntsIn20?: number | null;
      kickingStatsPuntsOutOfBounds?: number | null;
      kickingStatsPuntsDowned?: number | null;
      kickingStatsPuntsTouchbacks?: number | null;
      kickingStatsPuntsFairCaught?: number | null;
      kickingStatsPuntsReturned?: number | null;
      kickingStatsPuntReturnYards?: number | null;
      kickingStatsPuntReturnTouchdowns?: number | null;
      kickingStatsPuntsBlocked?: number | null;
      kickingStatsPuntSnaps?: number | null;
      kickingStatsPuntTeamSnaps?: number | null;
      kickingStatsPuntSnapPercentage?: number | null;
      kickingStatsPuntReturnYardsAverage?: number | null;
      kickingStatsPuntYardsNet?: number | null;
      kickingStatsPuntHangTimeAverage?: number | null;
      kickingStatsPuntOperationTimeAverage?: number | null;
      kickingStatsKickoffAttempts?: number | null;
      kickingStatsKickoffYards?: number | null;
      kickingStatsKickoffReturnYards?: number | null;
      kickingStatsKickoffTouchbacks?: number | null;
      kickingStatsKickoffTouchbackPercentage?: number | null;
      kickingStatsKickoffsReturned?: number | null;
      kickingStatsKickoffReturnLong?: number | null;
      kickingStatsKickoffReturnYardsAverage?: number | null;
      kickingStatsKickoffOnsideKicks?: number | null;
      kickingStatsKickoffOnsideKicksRecovered?: number | null;
      kickingStatsKickoffsOutOfBounds?: number | null;
      kickingStatsKickoffReturnTouchdowns?: number | null;
      kickingStatsKickoffDepthAverage?: number | null;
      kickingStatsKickoffYardsAverage?: number | null;
      kickingStatsKickoffSnaps?: number | null;
      kickingStatsKickoffTeamSnaps?: number | null;
      kickingStatsKickoffSnapPercentage?: number | null;
      kickingStatsKickoffDepthAttempts?: number | null;
    } | null;
  }>;
};

export type CareerStatsFragment = {
  __typename?: "CareerStat";
  perGameStatsGamesPlayed?: number | null;
  perGameStatsPassAttemptsPerGame?: number | null;
  perGameStatsPassingYardsPerGame?: number | null;
  perGameStatsReceivingYardsPerGame?: number | null;
  perGameStatsReceptionsPerGame?: number | null;
  perGameStatsRushAttemptsPerGame?: number | null;
  perGameStatsRushingYardsPerGame?: number | null;
  leagueType: {
    __typename?: "LeagueType";
    id: number;
    description?: string | null;
  };
  seasonType: {
    __typename?: "SeasonType";
    id: number;
    abbreviation?: string | null;
    description?: string | null;
  };
  playerStat?: {
    __typename?: "PlayerStat";
    rushingStatsYards?: number | null;
    rushingStatsAttempts?: number | null;
    rushingStatsTouchdowns?: number | null;
    rushingStatsRunsTwentyYardsPlus?: number | null;
    rushingStatsRunsFortyYardsPlus?: number | null;
    rushingStatsLongestRun?: number | null;
    rushingStatsFirstDownRushes?: number | null;
    rushingStatsFirstDownPercentage?: number | null;
    rushingStatsFumbles?: number | null;
    rushingStatsYardsAfterContact?: number | null;
    rushingStatsYardsAfterContactPerRush?: number | null;
    rushingStatsTacklesAvoided?: number | null;
    rushingStatsFumblesRecovered?: number | null;
    rushingStatsFumblesLost?: number | null;
    rushingStatsHurriesAllowed?: number | null;
    rushingStatsPenalties?: number | null;
    rushingStatsInjuriesInGame?: number | null;
    rushingStatsPressuresAllowed?: number | null;
    rushingStatsSacksAllowed?: number | null;
    rushingStatsYardsPerRush?: number | null;
    rushingStatsSnaps?: number | null;
    rushingStatsTeamSnaps?: number | null;
    rushingStatsSnapPercentage?: number | null;
    rushingStatsTwoPointAttempts?: number | null;
    rushingStatsTwoPointConversions?: number | null;
    returnStatsKickReturnYardsAverage?: number | null;
    returnStatsKickReturnAttempts?: number | null;
    returnStatsKickReturnYards?: number | null;
    returnStatsKickReturnTouchdowns?: number | null;
    returnStatsKickReturnsTwentyYardsPlus?: number | null;
    returnStatsKickReturnsFortyYardsPlus?: number | null;
    returnStatsKickReturnLongestYards?: number | null;
    returnStatsKickReturnFairCatches?: number | null;
    returnStatsKickReturnFumbles?: number | null;
    returnStatsKickReturnYardsAfterContact?: number | null;
    returnStatsKickReturnYardsAfterContactAverage?: number | null;
    returnStatsKickReturnSnaps?: number | null;
    returnStatsKickReturnTeamSnaps?: number | null;
    returnStatsKickReturnSnapPercentage?: number | null;
    returnStatsPuntReturnYardsAverage?: number | null;
    returnStatsPuntReturnAttempts?: number | null;
    returnStatsPuntReturnYards?: number | null;
    returnStatsPuntReturnTouchdowns?: number | null;
    returnStatsPuntReturnsTwentyYardsPlus?: number | null;
    returnStatsPuntReturnsFortyYardsPlus?: number | null;
    returnStatsPuntReturnLongestYards?: number | null;
    returnStatsPuntReturnFairCatches?: number | null;
    returnStatsPuntReturnFumbles?: number | null;
    returnStatsPuntReturnYardsAfterContact?: number | null;
    returnStatsPuntReturnYardsAfterContactAverage?: number | null;
    returnStatsPuntReturnSnaps?: number | null;
    returnStatsPuntReturnTeamSnaps?: number | null;
    returnStatsPuntReturnSnapPercentage?: number | null;
    receivingStatsReceptions?: number | null;
    receivingStatsTouchdowns?: number | null;
    receivingStatsYards?: number | null;
    receivingStatsYardsPerReception?: number | null;
    receivingStatsReceptionsTwentyYardsPlus?: number | null;
    receivingStatsReceptionsFortyYardsPlus?: number | null;
    receivingStatsLongestReception?: number | null;
    receivingStatsFirstDowns?: number | null;
    receivingStatsFirstDownPercentage?: number | null;
    receivingStatsReceivingFumbles?: number | null;
    receivingStatsYardsAfterCatch?: number | null;
    receivingStatsYardAfterCatchPerReception?: number | null;
    receivingStatsTargets?: number | null;
    receivingStatsTacklesAvoided?: number | null;
    receivingStatsDrops?: number | null;
    receivingStatsDropsPercentage?: number | null;
    receivingStatsYardsAfterContact?: number | null;
    receivingStatsInjuriesInGame?: number | null;
    receivingStatsPenalties?: number | null;
    receivingStatsCatchableThrows?: number | null;
    receivingStatsPassRouteDepthAverage?: number | null;
    receivingStatsSnaps?: number | null;
    receivingStatsTeamSnaps?: number | null;
    receivingStatsSnapPercentage?: number | null;
    receivingStatsPassRoutesRun?: number | null;
    receivingStatsReceptionsPerTouchdown?: number | null;
    receivingStatsTwoPointAttempts?: number | null;
    receivingStatsTwoPointConversions?: number | null;
    passingStatsYards?: number | null;
    passingStatsAttempts?: number | null;
    passingStatsCompletions?: number | null;
    passingStatsCompletionPercentage?: number | null;
    passingStatsYardsPerCompletionAverage?: number | null;
    passingStatsTouchdowns?: number | null;
    passingStatsTouchdownPercentage?: number | null;
    passingStatsInterceptions?: number | null;
    passingStatsInterceptionPercentage?: number | null;
    passingStatsPasserRating?: number | null;
    passingStatsFirstDowns?: number | null;
    passingStatsFirstDownPercentage?: number | null;
    passingStatsCompletionsTwentyYardsPlus?: number | null;
    passingStatsCompletionsFortyYardsPlus?: number | null;
    passingStatsLongestCompletion?: number | null;
    passingStatsSacks?: number | null;
    passingStatsSackYards?: number | null;
    passingStatsYardsAfterCatch?: number | null;
    passingStatsDrops?: number | null;
    passingStatsInjuriesInGame?: number | null;
    passingStatsFumbles?: number | null;
    passingStatsFumblesRecovered?: number | null;
    passingStatsHurriesAllowed?: number | null;
    passingStatsPassDepthAverage?: number | null;
    passingStatsPassDepthAttempts?: number | null;
    passingStatsCatchableThrows?: number | null;
    passingStatsCatchableThrowPercentage?: number | null;
    passingStatsPenalties?: number | null;
    passingStatsTimeToPressureAverage?: number | null;
    passingStatsTimeToPressureAttempts?: number | null;
    passingStatsTimeToThrowAverage?: number | null;
    passingStatsTimeToThrowAttempts?: number | null;
    passingStatsDropbackDepthAverage?: number | null;
    passingStatsDropbackAttempts?: number | null;
    passingStatsSnaps?: number | null;
    passingStatsTeamSnaps?: number | null;
    passingStatsSnapPercentage?: number | null;
    passingStatsPumpFakes?: number | null;
    passingStatsPassingEfficiency?: number | null;
    passingStatsYardsPerAttempt?: number | null;
    passingStatsTouchdownInterceptionRatio?: number | null;
    passingStatsTwoPointAttempts?: number | null;
    passingStatsTwoPointConversions?: number | null;
    defenseStatsFumblesForced?: number | null;
    defenseStatsTacklesSolo?: number | null;
    defenseStatsSacks?: number | null;
    defenseStatsSackPlays?: number | null;
    defenseStatsSackPercentage?: number | null;
    defenseStatsSnaps?: number | null;
    defenseStatsTeamSnaps?: number | null;
    defenseStatsSnapPercentage?: number | null;
    defenseStatsBattedPasses?: number | null;
    defenseStatsQbHits?: number | null;
    defenseStatsQbHitPercentage?: number | null;
    defenseStatsFumblesRecovered?: number | null;
    defenseStatsInjuriesInGame?: number | null;
    defenseStatsMissedTacklesOnQb?: number | null;
    defenseStatsPassBreakups?: number | null;
    defenseStatsStops?: number | null;
    defenseStatsTouchdowns?: number | null;
    defenseStatsPassRushAttempts?: number | null;
    defenseStatsCoverageAttempts?: number | null;
    defenseStatsBurns?: number | null;
    defenseStatsDefensiveTargets?: number | null;
    defenseStatsQbPressures?: number | null;
    defenseStatsFumblesRecoveredForTouchdown?: number | null;
    defenseStatsQbPressurePercentage?: number | null;
    defenseStatsQbHurries?: number | null;
    defenseStatsQbHurryPercentage?: number | null;
    defenseStatsMissedTackles?: number | null;
    defenseStatsPuntsBlocked?: number | null;
    defenseStatsFieldGoalsBlocked?: number | null;
    defenseStatsExtraPointsBlocked?: number | null;
    defenseStatsTacklesForLoss?: number | null;
    defenseStatsDroppedInterceptions?: number | null;
    defenseStatsInterceptions?: number | null;
    defenseStatsInterceptionTouchdowns?: number | null;
    defenseStatsInterceptionYards?: number | null;
    defenseStatsInterceptionLongestReturn?: number | null;
    defenseStatsInterceptionPercentage?: number | null;
    defenseStatsTacklesCombined?: number | null;
    defenseStatsTacklesAssisted?: number | null;
    kickingStatsFieldGoalsMade?: number | null;
    kickingStatsFieldGoalAttempts?: number | null;
    kickingStatsFieldGoalPercentage?: number | null;
    kickingStatsFieldGoals1To19Attempted?: number | null;
    kickingStatsFieldGoals1To19Made?: number | null;
    kickingStatsFieldGoals1To19Percentage?: number | null;
    kickingStatsFieldGoals20To29Attempted?: number | null;
    kickingStatsFieldGoals20To29Made?: number | null;
    kickingStatsFieldGoals20To29Percentage?: number | null;
    kickingStatsFieldGoals30To39Attempted?: number | null;
    kickingStatsFieldGoals30To39Made?: number | null;
    kickingStatsFieldGoals30To39Percentage?: number | null;
    kickingStatsFieldGoals40To49Attempted?: number | null;
    kickingStatsFieldGoals40To49Made?: number | null;
    kickingStatsFieldGoals40To49Percentage?: number | null;
    kickingStatsFieldGoals50To59Attempted?: number | null;
    kickingStatsFieldGoals50To59Made?: number | null;
    kickingStatsFieldGoals50To59Percentage?: number | null;
    kickingStatsFieldGoals60PlusAttempted?: number | null;
    kickingStatsFieldGoals60PlusMade?: number | null;
    kickingStatsFieldGoals60PlusPercentage?: number | null;
    kickingStatsFieldGoalMadeLongest?: number | null;
    kickingStatsFieldGoalsBlocked?: number | null;
    kickingStatsFieldGoalSnaps?: number | null;
    kickingStatsFieldGoalTeamSnaps?: number | null;
    kickingStatsFieldGoalSnapPercentage?: number | null;
    kickingStatsFieldGoalYardsAverage?: number | null;
    kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
    kickingStatsExtraPointAttempts?: number | null;
    kickingStatsExtraPointsMade?: number | null;
    kickingStatsExtraPointsBlocked?: number | null;
    kickingStatsExtraPointsPercentage?: number | null;
    kickingStatsPuntYardsAverage?: number | null;
    kickingStatsPuntYardsNetAverage?: number | null;
    kickingStatsPuntAttempts?: number | null;
    kickingStatsPuntYardsLongest?: number | null;
    kickingStatsPuntYards?: number | null;
    kickingStatsPuntsIn20?: number | null;
    kickingStatsPuntsOutOfBounds?: number | null;
    kickingStatsPuntsDowned?: number | null;
    kickingStatsPuntsTouchbacks?: number | null;
    kickingStatsPuntsFairCaught?: number | null;
    kickingStatsPuntsReturned?: number | null;
    kickingStatsPuntReturnYards?: number | null;
    kickingStatsPuntReturnTouchdowns?: number | null;
    kickingStatsPuntsBlocked?: number | null;
    kickingStatsPuntSnaps?: number | null;
    kickingStatsPuntTeamSnaps?: number | null;
    kickingStatsPuntSnapPercentage?: number | null;
    kickingStatsPuntReturnYardsAverage?: number | null;
    kickingStatsPuntYardsNet?: number | null;
    kickingStatsPuntHangTimeAverage?: number | null;
    kickingStatsPuntOperationTimeAverage?: number | null;
    kickingStatsKickoffAttempts?: number | null;
    kickingStatsKickoffYards?: number | null;
    kickingStatsKickoffReturnYards?: number | null;
    kickingStatsKickoffTouchbacks?: number | null;
    kickingStatsKickoffTouchbackPercentage?: number | null;
    kickingStatsKickoffsReturned?: number | null;
    kickingStatsKickoffReturnLong?: number | null;
    kickingStatsKickoffReturnYardsAverage?: number | null;
    kickingStatsKickoffOnsideKicks?: number | null;
    kickingStatsKickoffOnsideKicksRecovered?: number | null;
    kickingStatsKickoffsOutOfBounds?: number | null;
    kickingStatsKickoffReturnTouchdowns?: number | null;
    kickingStatsKickoffDepthAverage?: number | null;
    kickingStatsKickoffYardsAverage?: number | null;
    kickingStatsKickoffSnaps?: number | null;
    kickingStatsKickoffTeamSnaps?: number | null;
    kickingStatsKickoffSnapPercentage?: number | null;
    kickingStatsKickoffDepthAttempts?: number | null;
  } | null;
};

export type GetSeasonStatsForPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetSeasonStatsForPlayerQuery = {
  __typename?: "Query";
  seasonStats: Array<{
    __typename?: "SeasonStat";
    season: number;
    perGameStatsGamesPlayed?: number | null;
    perGameStatsPassAttemptsPerGame?: number | null;
    perGameStatsPassingYardsPerGame?: number | null;
    perGameStatsReceivingYardsPerGame?: number | null;
    perGameStatsReceptionsPerGame?: number | null;
    perGameStatsRushAttemptsPerGame?: number | null;
    perGameStatsRushingYardsPerGame?: number | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
    playerStat?: {
      __typename?: "PlayerStat";
      rushingStatsYards?: number | null;
      rushingStatsAttempts?: number | null;
      rushingStatsTouchdowns?: number | null;
      rushingStatsRunsTwentyYardsPlus?: number | null;
      rushingStatsRunsFortyYardsPlus?: number | null;
      rushingStatsLongestRun?: number | null;
      rushingStatsFirstDownRushes?: number | null;
      rushingStatsFirstDownPercentage?: number | null;
      rushingStatsFumbles?: number | null;
      rushingStatsYardsAfterContact?: number | null;
      rushingStatsYardsAfterContactPerRush?: number | null;
      rushingStatsTacklesAvoided?: number | null;
      rushingStatsFumblesRecovered?: number | null;
      rushingStatsFumblesLost?: number | null;
      rushingStatsHurriesAllowed?: number | null;
      rushingStatsPenalties?: number | null;
      rushingStatsInjuriesInGame?: number | null;
      rushingStatsPressuresAllowed?: number | null;
      rushingStatsSacksAllowed?: number | null;
      rushingStatsYardsPerRush?: number | null;
      rushingStatsSnaps?: number | null;
      rushingStatsTeamSnaps?: number | null;
      rushingStatsSnapPercentage?: number | null;
      rushingStatsTwoPointAttempts?: number | null;
      rushingStatsTwoPointConversions?: number | null;
      returnStatsKickReturnYardsAverage?: number | null;
      returnStatsKickReturnAttempts?: number | null;
      returnStatsKickReturnYards?: number | null;
      returnStatsKickReturnTouchdowns?: number | null;
      returnStatsKickReturnsTwentyYardsPlus?: number | null;
      returnStatsKickReturnsFortyYardsPlus?: number | null;
      returnStatsKickReturnLongestYards?: number | null;
      returnStatsKickReturnFairCatches?: number | null;
      returnStatsKickReturnFumbles?: number | null;
      returnStatsKickReturnYardsAfterContact?: number | null;
      returnStatsKickReturnYardsAfterContactAverage?: number | null;
      returnStatsKickReturnSnaps?: number | null;
      returnStatsKickReturnTeamSnaps?: number | null;
      returnStatsKickReturnSnapPercentage?: number | null;
      returnStatsPuntReturnYardsAverage?: number | null;
      returnStatsPuntReturnAttempts?: number | null;
      returnStatsPuntReturnYards?: number | null;
      returnStatsPuntReturnTouchdowns?: number | null;
      returnStatsPuntReturnsTwentyYardsPlus?: number | null;
      returnStatsPuntReturnsFortyYardsPlus?: number | null;
      returnStatsPuntReturnLongestYards?: number | null;
      returnStatsPuntReturnFairCatches?: number | null;
      returnStatsPuntReturnFumbles?: number | null;
      returnStatsPuntReturnYardsAfterContact?: number | null;
      returnStatsPuntReturnYardsAfterContactAverage?: number | null;
      returnStatsPuntReturnSnaps?: number | null;
      returnStatsPuntReturnTeamSnaps?: number | null;
      returnStatsPuntReturnSnapPercentage?: number | null;
      receivingStatsReceptions?: number | null;
      receivingStatsTouchdowns?: number | null;
      receivingStatsYards?: number | null;
      receivingStatsYardsPerReception?: number | null;
      receivingStatsReceptionsTwentyYardsPlus?: number | null;
      receivingStatsReceptionsFortyYardsPlus?: number | null;
      receivingStatsLongestReception?: number | null;
      receivingStatsFirstDowns?: number | null;
      receivingStatsFirstDownPercentage?: number | null;
      receivingStatsReceivingFumbles?: number | null;
      receivingStatsYardsAfterCatch?: number | null;
      receivingStatsYardAfterCatchPerReception?: number | null;
      receivingStatsTargets?: number | null;
      receivingStatsTacklesAvoided?: number | null;
      receivingStatsDrops?: number | null;
      receivingStatsDropsPercentage?: number | null;
      receivingStatsYardsAfterContact?: number | null;
      receivingStatsInjuriesInGame?: number | null;
      receivingStatsPenalties?: number | null;
      receivingStatsCatchableThrows?: number | null;
      receivingStatsPassRouteDepthAverage?: number | null;
      receivingStatsSnaps?: number | null;
      receivingStatsTeamSnaps?: number | null;
      receivingStatsSnapPercentage?: number | null;
      receivingStatsPassRoutesRun?: number | null;
      receivingStatsReceptionsPerTouchdown?: number | null;
      receivingStatsTwoPointAttempts?: number | null;
      receivingStatsTwoPointConversions?: number | null;
      passingStatsYards?: number | null;
      passingStatsAttempts?: number | null;
      passingStatsCompletions?: number | null;
      passingStatsCompletionPercentage?: number | null;
      passingStatsYardsPerCompletionAverage?: number | null;
      passingStatsTouchdowns?: number | null;
      passingStatsTouchdownPercentage?: number | null;
      passingStatsInterceptions?: number | null;
      passingStatsInterceptionPercentage?: number | null;
      passingStatsPasserRating?: number | null;
      passingStatsFirstDowns?: number | null;
      passingStatsFirstDownPercentage?: number | null;
      passingStatsCompletionsTwentyYardsPlus?: number | null;
      passingStatsCompletionsFortyYardsPlus?: number | null;
      passingStatsLongestCompletion?: number | null;
      passingStatsSacks?: number | null;
      passingStatsSackYards?: number | null;
      passingStatsYardsAfterCatch?: number | null;
      passingStatsDrops?: number | null;
      passingStatsInjuriesInGame?: number | null;
      passingStatsFumbles?: number | null;
      passingStatsFumblesRecovered?: number | null;
      passingStatsHurriesAllowed?: number | null;
      passingStatsPassDepthAverage?: number | null;
      passingStatsPassDepthAttempts?: number | null;
      passingStatsCatchableThrows?: number | null;
      passingStatsCatchableThrowPercentage?: number | null;
      passingStatsPenalties?: number | null;
      passingStatsTimeToPressureAverage?: number | null;
      passingStatsTimeToPressureAttempts?: number | null;
      passingStatsTimeToThrowAverage?: number | null;
      passingStatsTimeToThrowAttempts?: number | null;
      passingStatsDropbackDepthAverage?: number | null;
      passingStatsDropbackAttempts?: number | null;
      passingStatsSnaps?: number | null;
      passingStatsTeamSnaps?: number | null;
      passingStatsSnapPercentage?: number | null;
      passingStatsPumpFakes?: number | null;
      passingStatsPassingEfficiency?: number | null;
      passingStatsYardsPerAttempt?: number | null;
      passingStatsTouchdownInterceptionRatio?: number | null;
      passingStatsTwoPointAttempts?: number | null;
      passingStatsTwoPointConversions?: number | null;
      defenseStatsFumblesForced?: number | null;
      defenseStatsTacklesSolo?: number | null;
      defenseStatsSacks?: number | null;
      defenseStatsSackPlays?: number | null;
      defenseStatsSackPercentage?: number | null;
      defenseStatsSnaps?: number | null;
      defenseStatsTeamSnaps?: number | null;
      defenseStatsSnapPercentage?: number | null;
      defenseStatsBattedPasses?: number | null;
      defenseStatsQbHits?: number | null;
      defenseStatsQbHitPercentage?: number | null;
      defenseStatsFumblesRecovered?: number | null;
      defenseStatsInjuriesInGame?: number | null;
      defenseStatsMissedTacklesOnQb?: number | null;
      defenseStatsPassBreakups?: number | null;
      defenseStatsStops?: number | null;
      defenseStatsTouchdowns?: number | null;
      defenseStatsPassRushAttempts?: number | null;
      defenseStatsCoverageAttempts?: number | null;
      defenseStatsBurns?: number | null;
      defenseStatsDefensiveTargets?: number | null;
      defenseStatsQbPressures?: number | null;
      defenseStatsFumblesRecoveredForTouchdown?: number | null;
      defenseStatsQbPressurePercentage?: number | null;
      defenseStatsQbHurries?: number | null;
      defenseStatsQbHurryPercentage?: number | null;
      defenseStatsMissedTackles?: number | null;
      defenseStatsPuntsBlocked?: number | null;
      defenseStatsFieldGoalsBlocked?: number | null;
      defenseStatsExtraPointsBlocked?: number | null;
      defenseStatsTacklesForLoss?: number | null;
      defenseStatsDroppedInterceptions?: number | null;
      defenseStatsInterceptions?: number | null;
      defenseStatsInterceptionTouchdowns?: number | null;
      defenseStatsInterceptionYards?: number | null;
      defenseStatsInterceptionLongestReturn?: number | null;
      defenseStatsInterceptionPercentage?: number | null;
      defenseStatsTacklesCombined?: number | null;
      defenseStatsTacklesAssisted?: number | null;
      kickingStatsFieldGoalsMade?: number | null;
      kickingStatsFieldGoalAttempts?: number | null;
      kickingStatsFieldGoalPercentage?: number | null;
      kickingStatsFieldGoals1To19Attempted?: number | null;
      kickingStatsFieldGoals1To19Made?: number | null;
      kickingStatsFieldGoals1To19Percentage?: number | null;
      kickingStatsFieldGoals20To29Attempted?: number | null;
      kickingStatsFieldGoals20To29Made?: number | null;
      kickingStatsFieldGoals20To29Percentage?: number | null;
      kickingStatsFieldGoals30To39Attempted?: number | null;
      kickingStatsFieldGoals30To39Made?: number | null;
      kickingStatsFieldGoals30To39Percentage?: number | null;
      kickingStatsFieldGoals40To49Attempted?: number | null;
      kickingStatsFieldGoals40To49Made?: number | null;
      kickingStatsFieldGoals40To49Percentage?: number | null;
      kickingStatsFieldGoals50To59Attempted?: number | null;
      kickingStatsFieldGoals50To59Made?: number | null;
      kickingStatsFieldGoals50To59Percentage?: number | null;
      kickingStatsFieldGoals60PlusAttempted?: number | null;
      kickingStatsFieldGoals60PlusMade?: number | null;
      kickingStatsFieldGoals60PlusPercentage?: number | null;
      kickingStatsFieldGoalMadeLongest?: number | null;
      kickingStatsFieldGoalsBlocked?: number | null;
      kickingStatsFieldGoalSnaps?: number | null;
      kickingStatsFieldGoalTeamSnaps?: number | null;
      kickingStatsFieldGoalSnapPercentage?: number | null;
      kickingStatsFieldGoalYardsAverage?: number | null;
      kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
      kickingStatsExtraPointAttempts?: number | null;
      kickingStatsExtraPointsMade?: number | null;
      kickingStatsExtraPointsBlocked?: number | null;
      kickingStatsExtraPointsPercentage?: number | null;
      kickingStatsPuntYardsAverage?: number | null;
      kickingStatsPuntYardsNetAverage?: number | null;
      kickingStatsPuntAttempts?: number | null;
      kickingStatsPuntYardsLongest?: number | null;
      kickingStatsPuntYards?: number | null;
      kickingStatsPuntsIn20?: number | null;
      kickingStatsPuntsOutOfBounds?: number | null;
      kickingStatsPuntsDowned?: number | null;
      kickingStatsPuntsTouchbacks?: number | null;
      kickingStatsPuntsFairCaught?: number | null;
      kickingStatsPuntsReturned?: number | null;
      kickingStatsPuntReturnYards?: number | null;
      kickingStatsPuntReturnTouchdowns?: number | null;
      kickingStatsPuntsBlocked?: number | null;
      kickingStatsPuntSnaps?: number | null;
      kickingStatsPuntTeamSnaps?: number | null;
      kickingStatsPuntSnapPercentage?: number | null;
      kickingStatsPuntReturnYardsAverage?: number | null;
      kickingStatsPuntYardsNet?: number | null;
      kickingStatsPuntHangTimeAverage?: number | null;
      kickingStatsPuntOperationTimeAverage?: number | null;
      kickingStatsKickoffAttempts?: number | null;
      kickingStatsKickoffYards?: number | null;
      kickingStatsKickoffReturnYards?: number | null;
      kickingStatsKickoffTouchbacks?: number | null;
      kickingStatsKickoffTouchbackPercentage?: number | null;
      kickingStatsKickoffsReturned?: number | null;
      kickingStatsKickoffReturnLong?: number | null;
      kickingStatsKickoffReturnYardsAverage?: number | null;
      kickingStatsKickoffOnsideKicks?: number | null;
      kickingStatsKickoffOnsideKicksRecovered?: number | null;
      kickingStatsKickoffsOutOfBounds?: number | null;
      kickingStatsKickoffReturnTouchdowns?: number | null;
      kickingStatsKickoffDepthAverage?: number | null;
      kickingStatsKickoffYardsAverage?: number | null;
      kickingStatsKickoffSnaps?: number | null;
      kickingStatsKickoffTeamSnaps?: number | null;
      kickingStatsKickoffSnapPercentage?: number | null;
      kickingStatsKickoffDepthAttempts?: number | null;
    } | null;
  }>;
};

export type SeasonStatsFragment = {
  __typename?: "SeasonStat";
  season: number;
  perGameStatsGamesPlayed?: number | null;
  perGameStatsPassAttemptsPerGame?: number | null;
  perGameStatsPassingYardsPerGame?: number | null;
  perGameStatsReceivingYardsPerGame?: number | null;
  perGameStatsReceptionsPerGame?: number | null;
  perGameStatsRushAttemptsPerGame?: number | null;
  perGameStatsRushingYardsPerGame?: number | null;
  seasonType: {
    __typename?: "SeasonType";
    id: number;
    abbreviation?: string | null;
    description?: string | null;
  };
  leagueType: {
    __typename?: "LeagueType";
    id: number;
    description?: string | null;
  };
  playerStat?: {
    __typename?: "PlayerStat";
    rushingStatsYards?: number | null;
    rushingStatsAttempts?: number | null;
    rushingStatsTouchdowns?: number | null;
    rushingStatsRunsTwentyYardsPlus?: number | null;
    rushingStatsRunsFortyYardsPlus?: number | null;
    rushingStatsLongestRun?: number | null;
    rushingStatsFirstDownRushes?: number | null;
    rushingStatsFirstDownPercentage?: number | null;
    rushingStatsFumbles?: number | null;
    rushingStatsYardsAfterContact?: number | null;
    rushingStatsYardsAfterContactPerRush?: number | null;
    rushingStatsTacklesAvoided?: number | null;
    rushingStatsFumblesRecovered?: number | null;
    rushingStatsFumblesLost?: number | null;
    rushingStatsHurriesAllowed?: number | null;
    rushingStatsPenalties?: number | null;
    rushingStatsInjuriesInGame?: number | null;
    rushingStatsPressuresAllowed?: number | null;
    rushingStatsSacksAllowed?: number | null;
    rushingStatsYardsPerRush?: number | null;
    rushingStatsSnaps?: number | null;
    rushingStatsTeamSnaps?: number | null;
    rushingStatsSnapPercentage?: number | null;
    rushingStatsTwoPointAttempts?: number | null;
    rushingStatsTwoPointConversions?: number | null;
    returnStatsKickReturnYardsAverage?: number | null;
    returnStatsKickReturnAttempts?: number | null;
    returnStatsKickReturnYards?: number | null;
    returnStatsKickReturnTouchdowns?: number | null;
    returnStatsKickReturnsTwentyYardsPlus?: number | null;
    returnStatsKickReturnsFortyYardsPlus?: number | null;
    returnStatsKickReturnLongestYards?: number | null;
    returnStatsKickReturnFairCatches?: number | null;
    returnStatsKickReturnFumbles?: number | null;
    returnStatsKickReturnYardsAfterContact?: number | null;
    returnStatsKickReturnYardsAfterContactAverage?: number | null;
    returnStatsKickReturnSnaps?: number | null;
    returnStatsKickReturnTeamSnaps?: number | null;
    returnStatsKickReturnSnapPercentage?: number | null;
    returnStatsPuntReturnYardsAverage?: number | null;
    returnStatsPuntReturnAttempts?: number | null;
    returnStatsPuntReturnYards?: number | null;
    returnStatsPuntReturnTouchdowns?: number | null;
    returnStatsPuntReturnsTwentyYardsPlus?: number | null;
    returnStatsPuntReturnsFortyYardsPlus?: number | null;
    returnStatsPuntReturnLongestYards?: number | null;
    returnStatsPuntReturnFairCatches?: number | null;
    returnStatsPuntReturnFumbles?: number | null;
    returnStatsPuntReturnYardsAfterContact?: number | null;
    returnStatsPuntReturnYardsAfterContactAverage?: number | null;
    returnStatsPuntReturnSnaps?: number | null;
    returnStatsPuntReturnTeamSnaps?: number | null;
    returnStatsPuntReturnSnapPercentage?: number | null;
    receivingStatsReceptions?: number | null;
    receivingStatsTouchdowns?: number | null;
    receivingStatsYards?: number | null;
    receivingStatsYardsPerReception?: number | null;
    receivingStatsReceptionsTwentyYardsPlus?: number | null;
    receivingStatsReceptionsFortyYardsPlus?: number | null;
    receivingStatsLongestReception?: number | null;
    receivingStatsFirstDowns?: number | null;
    receivingStatsFirstDownPercentage?: number | null;
    receivingStatsReceivingFumbles?: number | null;
    receivingStatsYardsAfterCatch?: number | null;
    receivingStatsYardAfterCatchPerReception?: number | null;
    receivingStatsTargets?: number | null;
    receivingStatsTacklesAvoided?: number | null;
    receivingStatsDrops?: number | null;
    receivingStatsDropsPercentage?: number | null;
    receivingStatsYardsAfterContact?: number | null;
    receivingStatsInjuriesInGame?: number | null;
    receivingStatsPenalties?: number | null;
    receivingStatsCatchableThrows?: number | null;
    receivingStatsPassRouteDepthAverage?: number | null;
    receivingStatsSnaps?: number | null;
    receivingStatsTeamSnaps?: number | null;
    receivingStatsSnapPercentage?: number | null;
    receivingStatsPassRoutesRun?: number | null;
    receivingStatsReceptionsPerTouchdown?: number | null;
    receivingStatsTwoPointAttempts?: number | null;
    receivingStatsTwoPointConversions?: number | null;
    passingStatsYards?: number | null;
    passingStatsAttempts?: number | null;
    passingStatsCompletions?: number | null;
    passingStatsCompletionPercentage?: number | null;
    passingStatsYardsPerCompletionAverage?: number | null;
    passingStatsTouchdowns?: number | null;
    passingStatsTouchdownPercentage?: number | null;
    passingStatsInterceptions?: number | null;
    passingStatsInterceptionPercentage?: number | null;
    passingStatsPasserRating?: number | null;
    passingStatsFirstDowns?: number | null;
    passingStatsFirstDownPercentage?: number | null;
    passingStatsCompletionsTwentyYardsPlus?: number | null;
    passingStatsCompletionsFortyYardsPlus?: number | null;
    passingStatsLongestCompletion?: number | null;
    passingStatsSacks?: number | null;
    passingStatsSackYards?: number | null;
    passingStatsYardsAfterCatch?: number | null;
    passingStatsDrops?: number | null;
    passingStatsInjuriesInGame?: number | null;
    passingStatsFumbles?: number | null;
    passingStatsFumblesRecovered?: number | null;
    passingStatsHurriesAllowed?: number | null;
    passingStatsPassDepthAverage?: number | null;
    passingStatsPassDepthAttempts?: number | null;
    passingStatsCatchableThrows?: number | null;
    passingStatsCatchableThrowPercentage?: number | null;
    passingStatsPenalties?: number | null;
    passingStatsTimeToPressureAverage?: number | null;
    passingStatsTimeToPressureAttempts?: number | null;
    passingStatsTimeToThrowAverage?: number | null;
    passingStatsTimeToThrowAttempts?: number | null;
    passingStatsDropbackDepthAverage?: number | null;
    passingStatsDropbackAttempts?: number | null;
    passingStatsSnaps?: number | null;
    passingStatsTeamSnaps?: number | null;
    passingStatsSnapPercentage?: number | null;
    passingStatsPumpFakes?: number | null;
    passingStatsPassingEfficiency?: number | null;
    passingStatsYardsPerAttempt?: number | null;
    passingStatsTouchdownInterceptionRatio?: number | null;
    passingStatsTwoPointAttempts?: number | null;
    passingStatsTwoPointConversions?: number | null;
    defenseStatsFumblesForced?: number | null;
    defenseStatsTacklesSolo?: number | null;
    defenseStatsSacks?: number | null;
    defenseStatsSackPlays?: number | null;
    defenseStatsSackPercentage?: number | null;
    defenseStatsSnaps?: number | null;
    defenseStatsTeamSnaps?: number | null;
    defenseStatsSnapPercentage?: number | null;
    defenseStatsBattedPasses?: number | null;
    defenseStatsQbHits?: number | null;
    defenseStatsQbHitPercentage?: number | null;
    defenseStatsFumblesRecovered?: number | null;
    defenseStatsInjuriesInGame?: number | null;
    defenseStatsMissedTacklesOnQb?: number | null;
    defenseStatsPassBreakups?: number | null;
    defenseStatsStops?: number | null;
    defenseStatsTouchdowns?: number | null;
    defenseStatsPassRushAttempts?: number | null;
    defenseStatsCoverageAttempts?: number | null;
    defenseStatsBurns?: number | null;
    defenseStatsDefensiveTargets?: number | null;
    defenseStatsQbPressures?: number | null;
    defenseStatsFumblesRecoveredForTouchdown?: number | null;
    defenseStatsQbPressurePercentage?: number | null;
    defenseStatsQbHurries?: number | null;
    defenseStatsQbHurryPercentage?: number | null;
    defenseStatsMissedTackles?: number | null;
    defenseStatsPuntsBlocked?: number | null;
    defenseStatsFieldGoalsBlocked?: number | null;
    defenseStatsExtraPointsBlocked?: number | null;
    defenseStatsTacklesForLoss?: number | null;
    defenseStatsDroppedInterceptions?: number | null;
    defenseStatsInterceptions?: number | null;
    defenseStatsInterceptionTouchdowns?: number | null;
    defenseStatsInterceptionYards?: number | null;
    defenseStatsInterceptionLongestReturn?: number | null;
    defenseStatsInterceptionPercentage?: number | null;
    defenseStatsTacklesCombined?: number | null;
    defenseStatsTacklesAssisted?: number | null;
    kickingStatsFieldGoalsMade?: number | null;
    kickingStatsFieldGoalAttempts?: number | null;
    kickingStatsFieldGoalPercentage?: number | null;
    kickingStatsFieldGoals1To19Attempted?: number | null;
    kickingStatsFieldGoals1To19Made?: number | null;
    kickingStatsFieldGoals1To19Percentage?: number | null;
    kickingStatsFieldGoals20To29Attempted?: number | null;
    kickingStatsFieldGoals20To29Made?: number | null;
    kickingStatsFieldGoals20To29Percentage?: number | null;
    kickingStatsFieldGoals30To39Attempted?: number | null;
    kickingStatsFieldGoals30To39Made?: number | null;
    kickingStatsFieldGoals30To39Percentage?: number | null;
    kickingStatsFieldGoals40To49Attempted?: number | null;
    kickingStatsFieldGoals40To49Made?: number | null;
    kickingStatsFieldGoals40To49Percentage?: number | null;
    kickingStatsFieldGoals50To59Attempted?: number | null;
    kickingStatsFieldGoals50To59Made?: number | null;
    kickingStatsFieldGoals50To59Percentage?: number | null;
    kickingStatsFieldGoals60PlusAttempted?: number | null;
    kickingStatsFieldGoals60PlusMade?: number | null;
    kickingStatsFieldGoals60PlusPercentage?: number | null;
    kickingStatsFieldGoalMadeLongest?: number | null;
    kickingStatsFieldGoalsBlocked?: number | null;
    kickingStatsFieldGoalSnaps?: number | null;
    kickingStatsFieldGoalTeamSnaps?: number | null;
    kickingStatsFieldGoalSnapPercentage?: number | null;
    kickingStatsFieldGoalYardsAverage?: number | null;
    kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
    kickingStatsExtraPointAttempts?: number | null;
    kickingStatsExtraPointsMade?: number | null;
    kickingStatsExtraPointsBlocked?: number | null;
    kickingStatsExtraPointsPercentage?: number | null;
    kickingStatsPuntYardsAverage?: number | null;
    kickingStatsPuntYardsNetAverage?: number | null;
    kickingStatsPuntAttempts?: number | null;
    kickingStatsPuntYardsLongest?: number | null;
    kickingStatsPuntYards?: number | null;
    kickingStatsPuntsIn20?: number | null;
    kickingStatsPuntsOutOfBounds?: number | null;
    kickingStatsPuntsDowned?: number | null;
    kickingStatsPuntsTouchbacks?: number | null;
    kickingStatsPuntsFairCaught?: number | null;
    kickingStatsPuntsReturned?: number | null;
    kickingStatsPuntReturnYards?: number | null;
    kickingStatsPuntReturnTouchdowns?: number | null;
    kickingStatsPuntsBlocked?: number | null;
    kickingStatsPuntSnaps?: number | null;
    kickingStatsPuntTeamSnaps?: number | null;
    kickingStatsPuntSnapPercentage?: number | null;
    kickingStatsPuntReturnYardsAverage?: number | null;
    kickingStatsPuntYardsNet?: number | null;
    kickingStatsPuntHangTimeAverage?: number | null;
    kickingStatsPuntOperationTimeAverage?: number | null;
    kickingStatsKickoffAttempts?: number | null;
    kickingStatsKickoffYards?: number | null;
    kickingStatsKickoffReturnYards?: number | null;
    kickingStatsKickoffTouchbacks?: number | null;
    kickingStatsKickoffTouchbackPercentage?: number | null;
    kickingStatsKickoffsReturned?: number | null;
    kickingStatsKickoffReturnLong?: number | null;
    kickingStatsKickoffReturnYardsAverage?: number | null;
    kickingStatsKickoffOnsideKicks?: number | null;
    kickingStatsKickoffOnsideKicksRecovered?: number | null;
    kickingStatsKickoffsOutOfBounds?: number | null;
    kickingStatsKickoffReturnTouchdowns?: number | null;
    kickingStatsKickoffDepthAverage?: number | null;
    kickingStatsKickoffYardsAverage?: number | null;
    kickingStatsKickoffSnaps?: number | null;
    kickingStatsKickoffTeamSnaps?: number | null;
    kickingStatsKickoffSnapPercentage?: number | null;
    kickingStatsKickoffDepthAttempts?: number | null;
  } | null;
};

export type GetGameStatsForPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetGameStatsForPlayerQuery = {
  __typename?: "Query";
  gameStats: Array<{
    __typename?: "GameStat";
    season: number;
    gameId: string;
    teamId?: string | null;
    game: {
      __typename?: "Game";
      id: string;
      week?: number | null;
      season: number;
      homeTeam?: string | null;
      awayTeam?: string | null;
      weekType: { __typename?: "WeekType"; description?: string | null };
    };
    weekType: {
      __typename?: "WeekType";
      id: number;
      description?: string | null;
    };
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
    playerStat: {
      __typename?: "PlayerStat";
      rushingStatsYards?: number | null;
      rushingStatsAttempts?: number | null;
      rushingStatsTouchdowns?: number | null;
      rushingStatsRunsTwentyYardsPlus?: number | null;
      rushingStatsRunsFortyYardsPlus?: number | null;
      rushingStatsLongestRun?: number | null;
      rushingStatsFirstDownRushes?: number | null;
      rushingStatsFirstDownPercentage?: number | null;
      rushingStatsFumbles?: number | null;
      rushingStatsYardsAfterContact?: number | null;
      rushingStatsYardsAfterContactPerRush?: number | null;
      rushingStatsTacklesAvoided?: number | null;
      rushingStatsFumblesRecovered?: number | null;
      rushingStatsFumblesLost?: number | null;
      rushingStatsHurriesAllowed?: number | null;
      rushingStatsPenalties?: number | null;
      rushingStatsInjuriesInGame?: number | null;
      rushingStatsPressuresAllowed?: number | null;
      rushingStatsSacksAllowed?: number | null;
      rushingStatsYardsPerRush?: number | null;
      rushingStatsSnaps?: number | null;
      rushingStatsTeamSnaps?: number | null;
      rushingStatsSnapPercentage?: number | null;
      rushingStatsTwoPointAttempts?: number | null;
      rushingStatsTwoPointConversions?: number | null;
      returnStatsKickReturnYardsAverage?: number | null;
      returnStatsKickReturnAttempts?: number | null;
      returnStatsKickReturnYards?: number | null;
      returnStatsKickReturnTouchdowns?: number | null;
      returnStatsKickReturnsTwentyYardsPlus?: number | null;
      returnStatsKickReturnsFortyYardsPlus?: number | null;
      returnStatsKickReturnLongestYards?: number | null;
      returnStatsKickReturnFairCatches?: number | null;
      returnStatsKickReturnFumbles?: number | null;
      returnStatsKickReturnYardsAfterContact?: number | null;
      returnStatsKickReturnYardsAfterContactAverage?: number | null;
      returnStatsKickReturnSnaps?: number | null;
      returnStatsKickReturnTeamSnaps?: number | null;
      returnStatsKickReturnSnapPercentage?: number | null;
      returnStatsPuntReturnYardsAverage?: number | null;
      returnStatsPuntReturnAttempts?: number | null;
      returnStatsPuntReturnYards?: number | null;
      returnStatsPuntReturnTouchdowns?: number | null;
      returnStatsPuntReturnsTwentyYardsPlus?: number | null;
      returnStatsPuntReturnsFortyYardsPlus?: number | null;
      returnStatsPuntReturnLongestYards?: number | null;
      returnStatsPuntReturnFairCatches?: number | null;
      returnStatsPuntReturnFumbles?: number | null;
      returnStatsPuntReturnYardsAfterContact?: number | null;
      returnStatsPuntReturnYardsAfterContactAverage?: number | null;
      returnStatsPuntReturnSnaps?: number | null;
      returnStatsPuntReturnTeamSnaps?: number | null;
      returnStatsPuntReturnSnapPercentage?: number | null;
      receivingStatsReceptions?: number | null;
      receivingStatsTouchdowns?: number | null;
      receivingStatsYards?: number | null;
      receivingStatsYardsPerReception?: number | null;
      receivingStatsReceptionsTwentyYardsPlus?: number | null;
      receivingStatsReceptionsFortyYardsPlus?: number | null;
      receivingStatsLongestReception?: number | null;
      receivingStatsFirstDowns?: number | null;
      receivingStatsFirstDownPercentage?: number | null;
      receivingStatsReceivingFumbles?: number | null;
      receivingStatsYardsAfterCatch?: number | null;
      receivingStatsYardAfterCatchPerReception?: number | null;
      receivingStatsTargets?: number | null;
      receivingStatsTacklesAvoided?: number | null;
      receivingStatsDrops?: number | null;
      receivingStatsDropsPercentage?: number | null;
      receivingStatsYardsAfterContact?: number | null;
      receivingStatsInjuriesInGame?: number | null;
      receivingStatsPenalties?: number | null;
      receivingStatsCatchableThrows?: number | null;
      receivingStatsPassRouteDepthAverage?: number | null;
      receivingStatsSnaps?: number | null;
      receivingStatsTeamSnaps?: number | null;
      receivingStatsSnapPercentage?: number | null;
      receivingStatsPassRoutesRun?: number | null;
      receivingStatsReceptionsPerTouchdown?: number | null;
      receivingStatsTwoPointAttempts?: number | null;
      receivingStatsTwoPointConversions?: number | null;
      passingStatsYards?: number | null;
      passingStatsAttempts?: number | null;
      passingStatsCompletions?: number | null;
      passingStatsCompletionPercentage?: number | null;
      passingStatsYardsPerCompletionAverage?: number | null;
      passingStatsTouchdowns?: number | null;
      passingStatsTouchdownPercentage?: number | null;
      passingStatsInterceptions?: number | null;
      passingStatsInterceptionPercentage?: number | null;
      passingStatsPasserRating?: number | null;
      passingStatsFirstDowns?: number | null;
      passingStatsFirstDownPercentage?: number | null;
      passingStatsCompletionsTwentyYardsPlus?: number | null;
      passingStatsCompletionsFortyYardsPlus?: number | null;
      passingStatsLongestCompletion?: number | null;
      passingStatsSacks?: number | null;
      passingStatsSackYards?: number | null;
      passingStatsYardsAfterCatch?: number | null;
      passingStatsDrops?: number | null;
      passingStatsInjuriesInGame?: number | null;
      passingStatsFumbles?: number | null;
      passingStatsFumblesRecovered?: number | null;
      passingStatsHurriesAllowed?: number | null;
      passingStatsPassDepthAverage?: number | null;
      passingStatsPassDepthAttempts?: number | null;
      passingStatsCatchableThrows?: number | null;
      passingStatsCatchableThrowPercentage?: number | null;
      passingStatsPenalties?: number | null;
      passingStatsTimeToPressureAverage?: number | null;
      passingStatsTimeToPressureAttempts?: number | null;
      passingStatsTimeToThrowAverage?: number | null;
      passingStatsTimeToThrowAttempts?: number | null;
      passingStatsDropbackDepthAverage?: number | null;
      passingStatsDropbackAttempts?: number | null;
      passingStatsSnaps?: number | null;
      passingStatsTeamSnaps?: number | null;
      passingStatsSnapPercentage?: number | null;
      passingStatsPumpFakes?: number | null;
      passingStatsPassingEfficiency?: number | null;
      passingStatsYardsPerAttempt?: number | null;
      passingStatsTouchdownInterceptionRatio?: number | null;
      passingStatsTwoPointAttempts?: number | null;
      passingStatsTwoPointConversions?: number | null;
      defenseStatsFumblesForced?: number | null;
      defenseStatsTacklesSolo?: number | null;
      defenseStatsSacks?: number | null;
      defenseStatsSackPlays?: number | null;
      defenseStatsSackPercentage?: number | null;
      defenseStatsSnaps?: number | null;
      defenseStatsTeamSnaps?: number | null;
      defenseStatsSnapPercentage?: number | null;
      defenseStatsBattedPasses?: number | null;
      defenseStatsQbHits?: number | null;
      defenseStatsQbHitPercentage?: number | null;
      defenseStatsFumblesRecovered?: number | null;
      defenseStatsInjuriesInGame?: number | null;
      defenseStatsMissedTacklesOnQb?: number | null;
      defenseStatsPassBreakups?: number | null;
      defenseStatsStops?: number | null;
      defenseStatsTouchdowns?: number | null;
      defenseStatsPassRushAttempts?: number | null;
      defenseStatsCoverageAttempts?: number | null;
      defenseStatsBurns?: number | null;
      defenseStatsDefensiveTargets?: number | null;
      defenseStatsQbPressures?: number | null;
      defenseStatsFumblesRecoveredForTouchdown?: number | null;
      defenseStatsQbPressurePercentage?: number | null;
      defenseStatsQbHurries?: number | null;
      defenseStatsQbHurryPercentage?: number | null;
      defenseStatsMissedTackles?: number | null;
      defenseStatsPuntsBlocked?: number | null;
      defenseStatsFieldGoalsBlocked?: number | null;
      defenseStatsExtraPointsBlocked?: number | null;
      defenseStatsTacklesForLoss?: number | null;
      defenseStatsDroppedInterceptions?: number | null;
      defenseStatsInterceptions?: number | null;
      defenseStatsInterceptionTouchdowns?: number | null;
      defenseStatsInterceptionYards?: number | null;
      defenseStatsInterceptionLongestReturn?: number | null;
      defenseStatsInterceptionPercentage?: number | null;
      defenseStatsTacklesCombined?: number | null;
      defenseStatsTacklesAssisted?: number | null;
      kickingStatsFieldGoalsMade?: number | null;
      kickingStatsFieldGoalAttempts?: number | null;
      kickingStatsFieldGoalPercentage?: number | null;
      kickingStatsFieldGoals1To19Attempted?: number | null;
      kickingStatsFieldGoals1To19Made?: number | null;
      kickingStatsFieldGoals1To19Percentage?: number | null;
      kickingStatsFieldGoals20To29Attempted?: number | null;
      kickingStatsFieldGoals20To29Made?: number | null;
      kickingStatsFieldGoals20To29Percentage?: number | null;
      kickingStatsFieldGoals30To39Attempted?: number | null;
      kickingStatsFieldGoals30To39Made?: number | null;
      kickingStatsFieldGoals30To39Percentage?: number | null;
      kickingStatsFieldGoals40To49Attempted?: number | null;
      kickingStatsFieldGoals40To49Made?: number | null;
      kickingStatsFieldGoals40To49Percentage?: number | null;
      kickingStatsFieldGoals50To59Attempted?: number | null;
      kickingStatsFieldGoals50To59Made?: number | null;
      kickingStatsFieldGoals50To59Percentage?: number | null;
      kickingStatsFieldGoals60PlusAttempted?: number | null;
      kickingStatsFieldGoals60PlusMade?: number | null;
      kickingStatsFieldGoals60PlusPercentage?: number | null;
      kickingStatsFieldGoalMadeLongest?: number | null;
      kickingStatsFieldGoalsBlocked?: number | null;
      kickingStatsFieldGoalSnaps?: number | null;
      kickingStatsFieldGoalTeamSnaps?: number | null;
      kickingStatsFieldGoalSnapPercentage?: number | null;
      kickingStatsFieldGoalYardsAverage?: number | null;
      kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
      kickingStatsExtraPointAttempts?: number | null;
      kickingStatsExtraPointsMade?: number | null;
      kickingStatsExtraPointsBlocked?: number | null;
      kickingStatsExtraPointsPercentage?: number | null;
      kickingStatsPuntYardsAverage?: number | null;
      kickingStatsPuntYardsNetAverage?: number | null;
      kickingStatsPuntAttempts?: number | null;
      kickingStatsPuntYardsLongest?: number | null;
      kickingStatsPuntYards?: number | null;
      kickingStatsPuntsIn20?: number | null;
      kickingStatsPuntsOutOfBounds?: number | null;
      kickingStatsPuntsDowned?: number | null;
      kickingStatsPuntsTouchbacks?: number | null;
      kickingStatsPuntsFairCaught?: number | null;
      kickingStatsPuntsReturned?: number | null;
      kickingStatsPuntReturnYards?: number | null;
      kickingStatsPuntReturnTouchdowns?: number | null;
      kickingStatsPuntsBlocked?: number | null;
      kickingStatsPuntSnaps?: number | null;
      kickingStatsPuntTeamSnaps?: number | null;
      kickingStatsPuntSnapPercentage?: number | null;
      kickingStatsPuntReturnYardsAverage?: number | null;
      kickingStatsPuntYardsNet?: number | null;
      kickingStatsPuntHangTimeAverage?: number | null;
      kickingStatsPuntOperationTimeAverage?: number | null;
      kickingStatsKickoffAttempts?: number | null;
      kickingStatsKickoffYards?: number | null;
      kickingStatsKickoffReturnYards?: number | null;
      kickingStatsKickoffTouchbacks?: number | null;
      kickingStatsKickoffTouchbackPercentage?: number | null;
      kickingStatsKickoffsReturned?: number | null;
      kickingStatsKickoffReturnLong?: number | null;
      kickingStatsKickoffReturnYardsAverage?: number | null;
      kickingStatsKickoffOnsideKicks?: number | null;
      kickingStatsKickoffOnsideKicksRecovered?: number | null;
      kickingStatsKickoffsOutOfBounds?: number | null;
      kickingStatsKickoffReturnTouchdowns?: number | null;
      kickingStatsKickoffDepthAverage?: number | null;
      kickingStatsKickoffYardsAverage?: number | null;
      kickingStatsKickoffSnaps?: number | null;
      kickingStatsKickoffTeamSnaps?: number | null;
      kickingStatsKickoffSnapPercentage?: number | null;
      kickingStatsKickoffDepthAttempts?: number | null;
    };
  }>;
};

export type GameStatsFragment = {
  __typename?: "GameStat";
  season: number;
  gameId: string;
  teamId?: string | null;
  game: {
    __typename?: "Game";
    id: string;
    week?: number | null;
    season: number;
    homeTeam?: string | null;
    awayTeam?: string | null;
    weekType: { __typename?: "WeekType"; description?: string | null };
  };
  weekType: {
    __typename?: "WeekType";
    id: number;
    description?: string | null;
  };
  seasonType: {
    __typename?: "SeasonType";
    id: number;
    abbreviation?: string | null;
    description?: string | null;
  };
  leagueType: {
    __typename?: "LeagueType";
    id: number;
    description?: string | null;
  };
  playerStat: {
    __typename?: "PlayerStat";
    rushingStatsYards?: number | null;
    rushingStatsAttempts?: number | null;
    rushingStatsTouchdowns?: number | null;
    rushingStatsRunsTwentyYardsPlus?: number | null;
    rushingStatsRunsFortyYardsPlus?: number | null;
    rushingStatsLongestRun?: number | null;
    rushingStatsFirstDownRushes?: number | null;
    rushingStatsFirstDownPercentage?: number | null;
    rushingStatsFumbles?: number | null;
    rushingStatsYardsAfterContact?: number | null;
    rushingStatsYardsAfterContactPerRush?: number | null;
    rushingStatsTacklesAvoided?: number | null;
    rushingStatsFumblesRecovered?: number | null;
    rushingStatsFumblesLost?: number | null;
    rushingStatsHurriesAllowed?: number | null;
    rushingStatsPenalties?: number | null;
    rushingStatsInjuriesInGame?: number | null;
    rushingStatsPressuresAllowed?: number | null;
    rushingStatsSacksAllowed?: number | null;
    rushingStatsYardsPerRush?: number | null;
    rushingStatsSnaps?: number | null;
    rushingStatsTeamSnaps?: number | null;
    rushingStatsSnapPercentage?: number | null;
    rushingStatsTwoPointAttempts?: number | null;
    rushingStatsTwoPointConversions?: number | null;
    returnStatsKickReturnYardsAverage?: number | null;
    returnStatsKickReturnAttempts?: number | null;
    returnStatsKickReturnYards?: number | null;
    returnStatsKickReturnTouchdowns?: number | null;
    returnStatsKickReturnsTwentyYardsPlus?: number | null;
    returnStatsKickReturnsFortyYardsPlus?: number | null;
    returnStatsKickReturnLongestYards?: number | null;
    returnStatsKickReturnFairCatches?: number | null;
    returnStatsKickReturnFumbles?: number | null;
    returnStatsKickReturnYardsAfterContact?: number | null;
    returnStatsKickReturnYardsAfterContactAverage?: number | null;
    returnStatsKickReturnSnaps?: number | null;
    returnStatsKickReturnTeamSnaps?: number | null;
    returnStatsKickReturnSnapPercentage?: number | null;
    returnStatsPuntReturnYardsAverage?: number | null;
    returnStatsPuntReturnAttempts?: number | null;
    returnStatsPuntReturnYards?: number | null;
    returnStatsPuntReturnTouchdowns?: number | null;
    returnStatsPuntReturnsTwentyYardsPlus?: number | null;
    returnStatsPuntReturnsFortyYardsPlus?: number | null;
    returnStatsPuntReturnLongestYards?: number | null;
    returnStatsPuntReturnFairCatches?: number | null;
    returnStatsPuntReturnFumbles?: number | null;
    returnStatsPuntReturnYardsAfterContact?: number | null;
    returnStatsPuntReturnYardsAfterContactAverage?: number | null;
    returnStatsPuntReturnSnaps?: number | null;
    returnStatsPuntReturnTeamSnaps?: number | null;
    returnStatsPuntReturnSnapPercentage?: number | null;
    receivingStatsReceptions?: number | null;
    receivingStatsTouchdowns?: number | null;
    receivingStatsYards?: number | null;
    receivingStatsYardsPerReception?: number | null;
    receivingStatsReceptionsTwentyYardsPlus?: number | null;
    receivingStatsReceptionsFortyYardsPlus?: number | null;
    receivingStatsLongestReception?: number | null;
    receivingStatsFirstDowns?: number | null;
    receivingStatsFirstDownPercentage?: number | null;
    receivingStatsReceivingFumbles?: number | null;
    receivingStatsYardsAfterCatch?: number | null;
    receivingStatsYardAfterCatchPerReception?: number | null;
    receivingStatsTargets?: number | null;
    receivingStatsTacklesAvoided?: number | null;
    receivingStatsDrops?: number | null;
    receivingStatsDropsPercentage?: number | null;
    receivingStatsYardsAfterContact?: number | null;
    receivingStatsInjuriesInGame?: number | null;
    receivingStatsPenalties?: number | null;
    receivingStatsCatchableThrows?: number | null;
    receivingStatsPassRouteDepthAverage?: number | null;
    receivingStatsSnaps?: number | null;
    receivingStatsTeamSnaps?: number | null;
    receivingStatsSnapPercentage?: number | null;
    receivingStatsPassRoutesRun?: number | null;
    receivingStatsReceptionsPerTouchdown?: number | null;
    receivingStatsTwoPointAttempts?: number | null;
    receivingStatsTwoPointConversions?: number | null;
    passingStatsYards?: number | null;
    passingStatsAttempts?: number | null;
    passingStatsCompletions?: number | null;
    passingStatsCompletionPercentage?: number | null;
    passingStatsYardsPerCompletionAverage?: number | null;
    passingStatsTouchdowns?: number | null;
    passingStatsTouchdownPercentage?: number | null;
    passingStatsInterceptions?: number | null;
    passingStatsInterceptionPercentage?: number | null;
    passingStatsPasserRating?: number | null;
    passingStatsFirstDowns?: number | null;
    passingStatsFirstDownPercentage?: number | null;
    passingStatsCompletionsTwentyYardsPlus?: number | null;
    passingStatsCompletionsFortyYardsPlus?: number | null;
    passingStatsLongestCompletion?: number | null;
    passingStatsSacks?: number | null;
    passingStatsSackYards?: number | null;
    passingStatsYardsAfterCatch?: number | null;
    passingStatsDrops?: number | null;
    passingStatsInjuriesInGame?: number | null;
    passingStatsFumbles?: number | null;
    passingStatsFumblesRecovered?: number | null;
    passingStatsHurriesAllowed?: number | null;
    passingStatsPassDepthAverage?: number | null;
    passingStatsPassDepthAttempts?: number | null;
    passingStatsCatchableThrows?: number | null;
    passingStatsCatchableThrowPercentage?: number | null;
    passingStatsPenalties?: number | null;
    passingStatsTimeToPressureAverage?: number | null;
    passingStatsTimeToPressureAttempts?: number | null;
    passingStatsTimeToThrowAverage?: number | null;
    passingStatsTimeToThrowAttempts?: number | null;
    passingStatsDropbackDepthAverage?: number | null;
    passingStatsDropbackAttempts?: number | null;
    passingStatsSnaps?: number | null;
    passingStatsTeamSnaps?: number | null;
    passingStatsSnapPercentage?: number | null;
    passingStatsPumpFakes?: number | null;
    passingStatsPassingEfficiency?: number | null;
    passingStatsYardsPerAttempt?: number | null;
    passingStatsTouchdownInterceptionRatio?: number | null;
    passingStatsTwoPointAttempts?: number | null;
    passingStatsTwoPointConversions?: number | null;
    defenseStatsFumblesForced?: number | null;
    defenseStatsTacklesSolo?: number | null;
    defenseStatsSacks?: number | null;
    defenseStatsSackPlays?: number | null;
    defenseStatsSackPercentage?: number | null;
    defenseStatsSnaps?: number | null;
    defenseStatsTeamSnaps?: number | null;
    defenseStatsSnapPercentage?: number | null;
    defenseStatsBattedPasses?: number | null;
    defenseStatsQbHits?: number | null;
    defenseStatsQbHitPercentage?: number | null;
    defenseStatsFumblesRecovered?: number | null;
    defenseStatsInjuriesInGame?: number | null;
    defenseStatsMissedTacklesOnQb?: number | null;
    defenseStatsPassBreakups?: number | null;
    defenseStatsStops?: number | null;
    defenseStatsTouchdowns?: number | null;
    defenseStatsPassRushAttempts?: number | null;
    defenseStatsCoverageAttempts?: number | null;
    defenseStatsBurns?: number | null;
    defenseStatsDefensiveTargets?: number | null;
    defenseStatsQbPressures?: number | null;
    defenseStatsFumblesRecoveredForTouchdown?: number | null;
    defenseStatsQbPressurePercentage?: number | null;
    defenseStatsQbHurries?: number | null;
    defenseStatsQbHurryPercentage?: number | null;
    defenseStatsMissedTackles?: number | null;
    defenseStatsPuntsBlocked?: number | null;
    defenseStatsFieldGoalsBlocked?: number | null;
    defenseStatsExtraPointsBlocked?: number | null;
    defenseStatsTacklesForLoss?: number | null;
    defenseStatsDroppedInterceptions?: number | null;
    defenseStatsInterceptions?: number | null;
    defenseStatsInterceptionTouchdowns?: number | null;
    defenseStatsInterceptionYards?: number | null;
    defenseStatsInterceptionLongestReturn?: number | null;
    defenseStatsInterceptionPercentage?: number | null;
    defenseStatsTacklesCombined?: number | null;
    defenseStatsTacklesAssisted?: number | null;
    kickingStatsFieldGoalsMade?: number | null;
    kickingStatsFieldGoalAttempts?: number | null;
    kickingStatsFieldGoalPercentage?: number | null;
    kickingStatsFieldGoals1To19Attempted?: number | null;
    kickingStatsFieldGoals1To19Made?: number | null;
    kickingStatsFieldGoals1To19Percentage?: number | null;
    kickingStatsFieldGoals20To29Attempted?: number | null;
    kickingStatsFieldGoals20To29Made?: number | null;
    kickingStatsFieldGoals20To29Percentage?: number | null;
    kickingStatsFieldGoals30To39Attempted?: number | null;
    kickingStatsFieldGoals30To39Made?: number | null;
    kickingStatsFieldGoals30To39Percentage?: number | null;
    kickingStatsFieldGoals40To49Attempted?: number | null;
    kickingStatsFieldGoals40To49Made?: number | null;
    kickingStatsFieldGoals40To49Percentage?: number | null;
    kickingStatsFieldGoals50To59Attempted?: number | null;
    kickingStatsFieldGoals50To59Made?: number | null;
    kickingStatsFieldGoals50To59Percentage?: number | null;
    kickingStatsFieldGoals60PlusAttempted?: number | null;
    kickingStatsFieldGoals60PlusMade?: number | null;
    kickingStatsFieldGoals60PlusPercentage?: number | null;
    kickingStatsFieldGoalMadeLongest?: number | null;
    kickingStatsFieldGoalsBlocked?: number | null;
    kickingStatsFieldGoalSnaps?: number | null;
    kickingStatsFieldGoalTeamSnaps?: number | null;
    kickingStatsFieldGoalSnapPercentage?: number | null;
    kickingStatsFieldGoalYardsAverage?: number | null;
    kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
    kickingStatsExtraPointAttempts?: number | null;
    kickingStatsExtraPointsMade?: number | null;
    kickingStatsExtraPointsBlocked?: number | null;
    kickingStatsExtraPointsPercentage?: number | null;
    kickingStatsPuntYardsAverage?: number | null;
    kickingStatsPuntYardsNetAverage?: number | null;
    kickingStatsPuntAttempts?: number | null;
    kickingStatsPuntYardsLongest?: number | null;
    kickingStatsPuntYards?: number | null;
    kickingStatsPuntsIn20?: number | null;
    kickingStatsPuntsOutOfBounds?: number | null;
    kickingStatsPuntsDowned?: number | null;
    kickingStatsPuntsTouchbacks?: number | null;
    kickingStatsPuntsFairCaught?: number | null;
    kickingStatsPuntsReturned?: number | null;
    kickingStatsPuntReturnYards?: number | null;
    kickingStatsPuntReturnTouchdowns?: number | null;
    kickingStatsPuntsBlocked?: number | null;
    kickingStatsPuntSnaps?: number | null;
    kickingStatsPuntTeamSnaps?: number | null;
    kickingStatsPuntSnapPercentage?: number | null;
    kickingStatsPuntReturnYardsAverage?: number | null;
    kickingStatsPuntYardsNet?: number | null;
    kickingStatsPuntHangTimeAverage?: number | null;
    kickingStatsPuntOperationTimeAverage?: number | null;
    kickingStatsKickoffAttempts?: number | null;
    kickingStatsKickoffYards?: number | null;
    kickingStatsKickoffReturnYards?: number | null;
    kickingStatsKickoffTouchbacks?: number | null;
    kickingStatsKickoffTouchbackPercentage?: number | null;
    kickingStatsKickoffsReturned?: number | null;
    kickingStatsKickoffReturnLong?: number | null;
    kickingStatsKickoffReturnYardsAverage?: number | null;
    kickingStatsKickoffOnsideKicks?: number | null;
    kickingStatsKickoffOnsideKicksRecovered?: number | null;
    kickingStatsKickoffsOutOfBounds?: number | null;
    kickingStatsKickoffReturnTouchdowns?: number | null;
    kickingStatsKickoffDepthAverage?: number | null;
    kickingStatsKickoffYardsAverage?: number | null;
    kickingStatsKickoffSnaps?: number | null;
    kickingStatsKickoffTeamSnaps?: number | null;
    kickingStatsKickoffSnapPercentage?: number | null;
    kickingStatsKickoffDepthAttempts?: number | null;
  };
};

export type LeagueTypeFragment = {
  __typename?: "LeagueType";
  id: number;
  description?: string | null;
};

export type SeasonTypeFragment = {
  __typename?: "SeasonType";
  id: number;
  abbreviation?: string | null;
  description?: string | null;
};

export type StatsFragment = {
  __typename?: "PlayerStat";
  rushingStatsYards?: number | null;
  rushingStatsAttempts?: number | null;
  rushingStatsTouchdowns?: number | null;
  rushingStatsRunsTwentyYardsPlus?: number | null;
  rushingStatsRunsFortyYardsPlus?: number | null;
  rushingStatsLongestRun?: number | null;
  rushingStatsFirstDownRushes?: number | null;
  rushingStatsFirstDownPercentage?: number | null;
  rushingStatsFumbles?: number | null;
  rushingStatsYardsAfterContact?: number | null;
  rushingStatsYardsAfterContactPerRush?: number | null;
  rushingStatsTacklesAvoided?: number | null;
  rushingStatsFumblesRecovered?: number | null;
  rushingStatsFumblesLost?: number | null;
  rushingStatsHurriesAllowed?: number | null;
  rushingStatsPenalties?: number | null;
  rushingStatsInjuriesInGame?: number | null;
  rushingStatsPressuresAllowed?: number | null;
  rushingStatsSacksAllowed?: number | null;
  rushingStatsYardsPerRush?: number | null;
  rushingStatsSnaps?: number | null;
  rushingStatsTeamSnaps?: number | null;
  rushingStatsSnapPercentage?: number | null;
  rushingStatsTwoPointAttempts?: number | null;
  rushingStatsTwoPointConversions?: number | null;
  returnStatsKickReturnYardsAverage?: number | null;
  returnStatsKickReturnAttempts?: number | null;
  returnStatsKickReturnYards?: number | null;
  returnStatsKickReturnTouchdowns?: number | null;
  returnStatsKickReturnsTwentyYardsPlus?: number | null;
  returnStatsKickReturnsFortyYardsPlus?: number | null;
  returnStatsKickReturnLongestYards?: number | null;
  returnStatsKickReturnFairCatches?: number | null;
  returnStatsKickReturnFumbles?: number | null;
  returnStatsKickReturnYardsAfterContact?: number | null;
  returnStatsKickReturnYardsAfterContactAverage?: number | null;
  returnStatsKickReturnSnaps?: number | null;
  returnStatsKickReturnTeamSnaps?: number | null;
  returnStatsKickReturnSnapPercentage?: number | null;
  returnStatsPuntReturnYardsAverage?: number | null;
  returnStatsPuntReturnAttempts?: number | null;
  returnStatsPuntReturnYards?: number | null;
  returnStatsPuntReturnTouchdowns?: number | null;
  returnStatsPuntReturnsTwentyYardsPlus?: number | null;
  returnStatsPuntReturnsFortyYardsPlus?: number | null;
  returnStatsPuntReturnLongestYards?: number | null;
  returnStatsPuntReturnFairCatches?: number | null;
  returnStatsPuntReturnFumbles?: number | null;
  returnStatsPuntReturnYardsAfterContact?: number | null;
  returnStatsPuntReturnYardsAfterContactAverage?: number | null;
  returnStatsPuntReturnSnaps?: number | null;
  returnStatsPuntReturnTeamSnaps?: number | null;
  returnStatsPuntReturnSnapPercentage?: number | null;
  receivingStatsReceptions?: number | null;
  receivingStatsTouchdowns?: number | null;
  receivingStatsYards?: number | null;
  receivingStatsYardsPerReception?: number | null;
  receivingStatsReceptionsTwentyYardsPlus?: number | null;
  receivingStatsReceptionsFortyYardsPlus?: number | null;
  receivingStatsLongestReception?: number | null;
  receivingStatsFirstDowns?: number | null;
  receivingStatsFirstDownPercentage?: number | null;
  receivingStatsReceivingFumbles?: number | null;
  receivingStatsYardsAfterCatch?: number | null;
  receivingStatsYardAfterCatchPerReception?: number | null;
  receivingStatsTargets?: number | null;
  receivingStatsTacklesAvoided?: number | null;
  receivingStatsDrops?: number | null;
  receivingStatsDropsPercentage?: number | null;
  receivingStatsYardsAfterContact?: number | null;
  receivingStatsInjuriesInGame?: number | null;
  receivingStatsPenalties?: number | null;
  receivingStatsCatchableThrows?: number | null;
  receivingStatsPassRouteDepthAverage?: number | null;
  receivingStatsSnaps?: number | null;
  receivingStatsTeamSnaps?: number | null;
  receivingStatsSnapPercentage?: number | null;
  receivingStatsPassRoutesRun?: number | null;
  receivingStatsReceptionsPerTouchdown?: number | null;
  receivingStatsTwoPointAttempts?: number | null;
  receivingStatsTwoPointConversions?: number | null;
  passingStatsYards?: number | null;
  passingStatsAttempts?: number | null;
  passingStatsCompletions?: number | null;
  passingStatsCompletionPercentage?: number | null;
  passingStatsYardsPerCompletionAverage?: number | null;
  passingStatsTouchdowns?: number | null;
  passingStatsTouchdownPercentage?: number | null;
  passingStatsInterceptions?: number | null;
  passingStatsInterceptionPercentage?: number | null;
  passingStatsPasserRating?: number | null;
  passingStatsFirstDowns?: number | null;
  passingStatsFirstDownPercentage?: number | null;
  passingStatsCompletionsTwentyYardsPlus?: number | null;
  passingStatsCompletionsFortyYardsPlus?: number | null;
  passingStatsLongestCompletion?: number | null;
  passingStatsSacks?: number | null;
  passingStatsSackYards?: number | null;
  passingStatsYardsAfterCatch?: number | null;
  passingStatsDrops?: number | null;
  passingStatsInjuriesInGame?: number | null;
  passingStatsFumbles?: number | null;
  passingStatsFumblesRecovered?: number | null;
  passingStatsHurriesAllowed?: number | null;
  passingStatsPassDepthAverage?: number | null;
  passingStatsPassDepthAttempts?: number | null;
  passingStatsCatchableThrows?: number | null;
  passingStatsCatchableThrowPercentage?: number | null;
  passingStatsPenalties?: number | null;
  passingStatsTimeToPressureAverage?: number | null;
  passingStatsTimeToPressureAttempts?: number | null;
  passingStatsTimeToThrowAverage?: number | null;
  passingStatsTimeToThrowAttempts?: number | null;
  passingStatsDropbackDepthAverage?: number | null;
  passingStatsDropbackAttempts?: number | null;
  passingStatsSnaps?: number | null;
  passingStatsTeamSnaps?: number | null;
  passingStatsSnapPercentage?: number | null;
  passingStatsPumpFakes?: number | null;
  passingStatsPassingEfficiency?: number | null;
  passingStatsYardsPerAttempt?: number | null;
  passingStatsTouchdownInterceptionRatio?: number | null;
  passingStatsTwoPointAttempts?: number | null;
  passingStatsTwoPointConversions?: number | null;
  defenseStatsFumblesForced?: number | null;
  defenseStatsTacklesSolo?: number | null;
  defenseStatsSacks?: number | null;
  defenseStatsSackPlays?: number | null;
  defenseStatsSackPercentage?: number | null;
  defenseStatsSnaps?: number | null;
  defenseStatsTeamSnaps?: number | null;
  defenseStatsSnapPercentage?: number | null;
  defenseStatsBattedPasses?: number | null;
  defenseStatsQbHits?: number | null;
  defenseStatsQbHitPercentage?: number | null;
  defenseStatsFumblesRecovered?: number | null;
  defenseStatsInjuriesInGame?: number | null;
  defenseStatsMissedTacklesOnQb?: number | null;
  defenseStatsPassBreakups?: number | null;
  defenseStatsStops?: number | null;
  defenseStatsTouchdowns?: number | null;
  defenseStatsPassRushAttempts?: number | null;
  defenseStatsCoverageAttempts?: number | null;
  defenseStatsBurns?: number | null;
  defenseStatsDefensiveTargets?: number | null;
  defenseStatsQbPressures?: number | null;
  defenseStatsFumblesRecoveredForTouchdown?: number | null;
  defenseStatsQbPressurePercentage?: number | null;
  defenseStatsQbHurries?: number | null;
  defenseStatsQbHurryPercentage?: number | null;
  defenseStatsMissedTackles?: number | null;
  defenseStatsPuntsBlocked?: number | null;
  defenseStatsFieldGoalsBlocked?: number | null;
  defenseStatsExtraPointsBlocked?: number | null;
  defenseStatsTacklesForLoss?: number | null;
  defenseStatsDroppedInterceptions?: number | null;
  defenseStatsInterceptions?: number | null;
  defenseStatsInterceptionTouchdowns?: number | null;
  defenseStatsInterceptionYards?: number | null;
  defenseStatsInterceptionLongestReturn?: number | null;
  defenseStatsInterceptionPercentage?: number | null;
  defenseStatsTacklesCombined?: number | null;
  defenseStatsTacklesAssisted?: number | null;
  kickingStatsFieldGoalsMade?: number | null;
  kickingStatsFieldGoalAttempts?: number | null;
  kickingStatsFieldGoalPercentage?: number | null;
  kickingStatsFieldGoals1To19Attempted?: number | null;
  kickingStatsFieldGoals1To19Made?: number | null;
  kickingStatsFieldGoals1To19Percentage?: number | null;
  kickingStatsFieldGoals20To29Attempted?: number | null;
  kickingStatsFieldGoals20To29Made?: number | null;
  kickingStatsFieldGoals20To29Percentage?: number | null;
  kickingStatsFieldGoals30To39Attempted?: number | null;
  kickingStatsFieldGoals30To39Made?: number | null;
  kickingStatsFieldGoals30To39Percentage?: number | null;
  kickingStatsFieldGoals40To49Attempted?: number | null;
  kickingStatsFieldGoals40To49Made?: number | null;
  kickingStatsFieldGoals40To49Percentage?: number | null;
  kickingStatsFieldGoals50To59Attempted?: number | null;
  kickingStatsFieldGoals50To59Made?: number | null;
  kickingStatsFieldGoals50To59Percentage?: number | null;
  kickingStatsFieldGoals60PlusAttempted?: number | null;
  kickingStatsFieldGoals60PlusMade?: number | null;
  kickingStatsFieldGoals60PlusPercentage?: number | null;
  kickingStatsFieldGoalMadeLongest?: number | null;
  kickingStatsFieldGoalsBlocked?: number | null;
  kickingStatsFieldGoalSnaps?: number | null;
  kickingStatsFieldGoalTeamSnaps?: number | null;
  kickingStatsFieldGoalSnapPercentage?: number | null;
  kickingStatsFieldGoalYardsAverage?: number | null;
  kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
  kickingStatsExtraPointAttempts?: number | null;
  kickingStatsExtraPointsMade?: number | null;
  kickingStatsExtraPointsBlocked?: number | null;
  kickingStatsExtraPointsPercentage?: number | null;
  kickingStatsPuntYardsAverage?: number | null;
  kickingStatsPuntYardsNetAverage?: number | null;
  kickingStatsPuntAttempts?: number | null;
  kickingStatsPuntYardsLongest?: number | null;
  kickingStatsPuntYards?: number | null;
  kickingStatsPuntsIn20?: number | null;
  kickingStatsPuntsOutOfBounds?: number | null;
  kickingStatsPuntsDowned?: number | null;
  kickingStatsPuntsTouchbacks?: number | null;
  kickingStatsPuntsFairCaught?: number | null;
  kickingStatsPuntsReturned?: number | null;
  kickingStatsPuntReturnYards?: number | null;
  kickingStatsPuntReturnTouchdowns?: number | null;
  kickingStatsPuntsBlocked?: number | null;
  kickingStatsPuntSnaps?: number | null;
  kickingStatsPuntTeamSnaps?: number | null;
  kickingStatsPuntSnapPercentage?: number | null;
  kickingStatsPuntReturnYardsAverage?: number | null;
  kickingStatsPuntYardsNet?: number | null;
  kickingStatsPuntHangTimeAverage?: number | null;
  kickingStatsPuntOperationTimeAverage?: number | null;
  kickingStatsKickoffAttempts?: number | null;
  kickingStatsKickoffYards?: number | null;
  kickingStatsKickoffReturnYards?: number | null;
  kickingStatsKickoffTouchbacks?: number | null;
  kickingStatsKickoffTouchbackPercentage?: number | null;
  kickingStatsKickoffsReturned?: number | null;
  kickingStatsKickoffReturnLong?: number | null;
  kickingStatsKickoffReturnYardsAverage?: number | null;
  kickingStatsKickoffOnsideKicks?: number | null;
  kickingStatsKickoffOnsideKicksRecovered?: number | null;
  kickingStatsKickoffsOutOfBounds?: number | null;
  kickingStatsKickoffReturnTouchdowns?: number | null;
  kickingStatsKickoffDepthAverage?: number | null;
  kickingStatsKickoffYardsAverage?: number | null;
  kickingStatsKickoffSnaps?: number | null;
  kickingStatsKickoffTeamSnaps?: number | null;
  kickingStatsKickoffSnapPercentage?: number | null;
  kickingStatsKickoffDepthAttempts?: number | null;
};

export type UpdatePositionDepthSurveyMutationVariables = Exact<{
  newLimits: Array<PositionDepthLimitInput> | PositionDepthLimitInput;
}>;

export type UpdatePositionDepthSurveyMutation = {
  __typename?: "Mutation";
  updatePositionDepthSurvey: {
    __typename?: "PositionDepthLimitSurvey";
    id: string;
    limits: Array<{
      __typename?: "PositionDepthLimit";
      positionNodeId: string;
      minimumDepth: number;
      maximumDepth: number;
      group: string;
      rosterSize: number;
    }>;
  };
};

export type UpdatePositionalImportanceSurveyMutationVariables = Exact<{
  newPage: PositionalImportancePageInput;
}>;

export type UpdatePositionalImportanceSurveyMutation = {
  __typename?: "Mutation";
  updatePositionalImportanceSurvey: {
    __typename?: "PositionalImportanceSurvey";
    id: string;
    pages: Array<{
      __typename?: "PositionalImportancePage";
      id: string;
      title: string;
      surveyType: string;
      metrics: Array<{
        __typename?: "PositionalImportanceMetric";
        value?: number | null;
        expertDefault: number;
        marketDefault: number;
        group: string;
        clubNodeId: string;
        id: string;
      }>;
    }>;
  };
};

export type GetPositionalImportanceSurveyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPositionalImportanceSurveyQuery = {
  __typename?: "Query";
  positionalImportanceSurvey: {
    __typename?: "PositionalImportanceSurvey";
    id: string;
    pages: Array<{
      __typename?: "PositionalImportancePage";
      id: string;
      title: string;
      surveyType: string;
      metrics: Array<{
        __typename?: "PositionalImportanceMetric";
        value?: number | null;
        expertDefault: number;
        marketDefault: number;
        group: string;
        clubNodeId: string;
        id: string;
      }>;
    }>;
  };
};

export type GetPositionDepthSurveyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPositionDepthSurveyQuery = {
  __typename?: "Query";
  positionDepthSurvey: {
    __typename?: "PositionDepthLimitSurvey";
    id: string;
    limits: Array<{
      __typename?: "PositionDepthLimit";
      positionNodeId: string;
      minimumDepth: number;
      maximumDepth: number;
      group: string;
      rosterSize: number;
    }>;
  };
};

export type PositionalImportanceMetricsFieldsFragment = {
  __typename?: "PositionalImportanceMetric";
  value?: number | null;
  expertDefault: number;
  marketDefault: number;
  group: string;
  clubNodeId: string;
  id: string;
};

export type PositionalImportancePageFieldsFragment = {
  __typename?: "PositionalImportancePage";
  id: string;
  title: string;
  surveyType: string;
  metrics: Array<{
    __typename?: "PositionalImportanceMetric";
    value?: number | null;
    expertDefault: number;
    marketDefault: number;
    group: string;
    clubNodeId: string;
    id: string;
  }>;
};

export type PositionalImportanceSurveyFieldsFragment = {
  __typename?: "PositionalImportanceSurvey";
  id: string;
  pages: Array<{
    __typename?: "PositionalImportancePage";
    id: string;
    title: string;
    surveyType: string;
    metrics: Array<{
      __typename?: "PositionalImportanceMetric";
      value?: number | null;
      expertDefault: number;
      marketDefault: number;
      group: string;
      clubNodeId: string;
      id: string;
    }>;
  }>;
};

export type OverrideTeamNeedsMutationVariables = Exact<{
  orverrideTeamNeedsForClubRequest: OverrideTeamNeedsForClubRequest;
}>;

export type OverrideTeamNeedsMutation = {
  __typename?: "Mutation";
  overrideTeamNeeds: Array<{
    __typename?: "TeamNeedsWrapper";
    isSumerDefault: boolean;
    clubId: string;
    positionalNeeds: Array<{
      __typename?: "PositionalNeed";
      rank: number;
      sumerGeneralPosition: string;
    }>;
  }>;
};

export type GetTeamNeedsForClubQueryVariables = Exact<{
  clubId: Scalars["UUID"];
  draftSeason: Scalars["Int"];
}>;

export type GetTeamNeedsForClubQuery = {
  __typename?: "Query";
  teamNeeds: Array<{
    __typename?: "TeamNeedsWrapper";
    isSumerDefault: boolean;
    clubId: string;
    positionalNeeds: Array<{
      __typename?: "PositionalNeed";
      rank: number;
      sumerGeneralPosition: string;
    }>;
  }>;
};

export type GetTeamNeedsPositionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTeamNeedsPositionsQuery = {
  __typename?: "Query";
  positions: Array<{
    __typename?: "Position";
    id: string;
    shortName: string;
    sideOfBall?: SideOfBall | null;
  }>;
};

export type GetClubGradingPositionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetClubGradingPositionsQuery = {
  __typename?: "Query";
  positions: Array<{
    __typename?: "Position";
    id: string;
    shortName: string;
    sideOfBall?: SideOfBall | null;
  }>;
};

export type GetTransactionsByDateRangeQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  fromDate: Scalars["DateTime"];
  toDate: Scalars["DateTime"];
}>;

export type GetTransactionsByDateRangeQuery = {
  __typename?: "Query";
  transactionsByDateRange?: {
    __typename?: "TransactionsByDateRangeConnection";
    totalCount: number;
    edges?: Array<{
      __typename?: "TransactionsByDateRangeEdge";
      node: {
        __typename?: "Transaction";
        id: string;
        season: number;
        transactionDate: string;
        transactionDescription: {
          __typename?: "TransactionDescription";
          transactionDescAbbr: string;
          transactionDesc: string;
        };
        startClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        resultClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        potentialClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        initialStatus: {
          __typename?: "TransactionStatus";
          transStatusDesc: string;
          transStatusDescAbbr: string;
          transStatusShortDesc: string;
        };
        resultStatus: {
          __typename?: "TransactionStatus";
          transStatusDesc: string;
          transStatusDescAbbr: string;
          transStatusShortDesc: string;
        };
        player: {
          __typename?: "Player";
          id: string;
          firstName: string;
          lastName: string;
          height?: number | null;
          weight?: number | null;
          clubGradingPosition?: {
            __typename?: "Position";
            shortName: string;
          } | null;
          sumerAtlasPosition?: {
            __typename?: "Position";
            shortName: string;
          } | null;
          sumerGeneralPosition?: {
            __typename?: "Position";
            shortName: string;
          } | null;
        };
      };
    }> | null;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type GetTransactionsForPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetTransactionsForPlayerQuery = {
  __typename?: "Query";
  transactionsByDateRange?: {
    __typename?: "TransactionsByDateRangeConnection";
    totalCount: number;
    edges?: Array<{
      __typename?: "TransactionsByDateRangeEdge";
      node: {
        __typename?: "Transaction";
        id: string;
        season: number;
        transactionDate: string;
        transactionDescription: {
          __typename?: "TransactionDescription";
          transactionDescAbbr: string;
          transactionDesc: string;
        };
        startClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        resultClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        potentialClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        initialStatus: {
          __typename?: "TransactionStatus";
          transStatusDesc: string;
          transStatusDescAbbr: string;
          transStatusShortDesc: string;
        };
        resultStatus: {
          __typename?: "TransactionStatus";
          transStatusDesc: string;
          transStatusDescAbbr: string;
          transStatusShortDesc: string;
        };
      };
    }> | null;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type AdminCreateUserMutationVariables = Exact<{
  createAppUserRequest: CreateUserRequest;
}>;

export type AdminCreateUserMutation = {
  __typename?: "Mutation";
  createUser: { __typename?: "User"; id: string };
};

export type EditAppUserMutationVariables = Exact<{
  editUserRequest: UpdateUserRequest;
}>;

export type EditAppUserMutation = {
  __typename?: "Mutation";
  updateUser: { __typename?: "User"; id: string };
};

export type ProvisionUserMutationVariables = Exact<{
  provisionUserRequest: ProvisionUserRequest;
}>;

export type ProvisionUserMutation = {
  __typename?: "Mutation";
  provisionUser: { __typename?: "User"; id: string };
};

export type DeactivateUserMutationVariables = Exact<{
  deactivateUserRequest: DeactivateUserRequest;
}>;

export type DeactivateUserMutation = {
  __typename?: "Mutation";
  deactivateUser: { __typename?: "User"; id: string };
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetUserByIdQuery = {
  __typename?: "Query";
  userById?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    authenticatedId?: string | null;
    firstName: string;
    lastName: string;
    emailAddress: string;
    status: UserStatus;
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  } | null;
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
  __typename?: "Query";
  users: Array<{
    __typename?: "User";
    id: string;
    authenticatedId?: string | null;
    firstName: string;
    lastName: string;
    emailAddress: string;
    status: UserStatus;
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  }>;
};

export type GetAuthorizedIntercomKeyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAuthorizedIntercomKeyQuery = {
  __typename?: "Query";
  authorizedIntercomKey: string;
};

export type PermissionFragment = { __typename?: "Permission"; name: string };

export const PlayerGradeFragmentFragmentDoc = gql`
  fragment PlayerGradeFragment on PlayerGrade {
    playerId
    avgClubGapypocDollars
    avgSageGapypocDollars
    avgSumerScoutGapypocDollars
    value {
      value
      gapypoc
      gapypocDollars
    }
    seasons {
      season
      gradeType
      lastUpdated
      value {
        value
        gapypoc
        gapypocDollars
      }
    }
  }
`;
export const SearchPlayerProjectedGamesMissedFragmentDoc = gql`
  fragment SearchPlayerProjectedGamesMissed on PlayerProjectedGamesMissed {
    id
    season
    value
    projectedGamesMissed
    projectedGamesMissedOverride
  }
`;
export const SearchPlayerFragmentDoc = gql`
  fragment SearchPlayer on Player {
    id
    playerGrade {
      ...PlayerGradeFragment
    }
    projectedGamesMissed {
      ...SearchPlayerProjectedGamesMissed
    }
  }
  ${PlayerGradeFragmentFragmentDoc}
  ${SearchPlayerProjectedGamesMissedFragmentDoc}
`;
export const SearchPlayerGradeValueFragmentDoc = gql`
  fragment SearchPlayerGradeValue on PlayerGradeValue {
    value
    rawValue
    rawValueOverride
    gapypoc
    gapypocDollars
  }
`;
export const SearchPlayerGradeSeasonFragmentDoc = gql`
  fragment SearchPlayerGradeSeason on PlayerGradeSeason {
    season
    gradeType
    lastUpdated
    value {
      ...SearchPlayerGradeValue
    }
  }
  ${SearchPlayerGradeValueFragmentDoc}
`;
export const SearchPlayerGradeFragmentDoc = gql`
  fragment SearchPlayerGrade on PlayerGrade {
    playerId
    avgClubGapypocDollars
    avgSageGapypocDollars
    avgSumerScoutGapypocDollars
    value {
      ...SearchPlayerGradeValue
    }
    seasons {
      ...SearchPlayerGradeSeason
    }
  }
  ${SearchPlayerGradeValueFragmentDoc}
  ${SearchPlayerGradeSeasonFragmentDoc}
`;
export const SelectedPickPlayerFragmentDoc = gql`
  fragment SelectedPickPlayer on Player {
    id
    jersey
    firstName
    lastName
    namePronunciation
    headshotUrl
    birthDate
    latestBirthDate
    hometownCity
    hometownState
    hometownCountry
    height
    weight
    speed
    sumerGeneralPosition {
      shortName
    }
    clubGradingPosition {
      shortName
    }
    school {
      schoolCode
      schoolName
    }
    playerGrade {
      ...PlayerGradeFragment
    }
    projectedGamesMissed {
      playerId
      season
      value
      projectedGamesMissed
      projectedGamesMissedOverride
    }
    favorite {
      playerId
    }
  }
  ${PlayerGradeFragmentFragmentDoc}
`;
export const DraftAssetFieldsFragmentDoc = gql`
  fragment draftAssetFields on DraftAsset {
    club {
      id
    }
    originalClub {
      id
    }
    overall
    pickInRound
    round
    season
  }
`;
export const DraftScenarioAssetFieldsFragmentDoc = gql`
  fragment draftScenarioAssetFields on DraftScenario {
    id
    version
    lastUpdated
    clubDraftAssets {
      ...draftAssetFields
    }
    allDraftAssets {
      ...draftAssetFields
    }
  }
  ${DraftAssetFieldsFragmentDoc}
`;
export const ClubFragmentDoc = gql`
  fragment Club on Club {
    id
    clubCode
    clubName
    clubRosterUrl
    conference
    division
    outdoorStadium
    isActive
    primaryColor
    secondaryColor
    timeZone
    currentCoach
    location
    logoUrl
    nickName
    venue
    yearEstablished
  }
`;
export const TradeChartValuesFragmentDoc = gql`
  fragment tradeChartValues on TradeChartValues {
    jimmyJohnsonTradeValue {
      netPoints
      offeringPoints
      receivingPoints
    }
    otcTradeValue {
      netPoints
      offeringPoints
      receivingPoints
    }
    evaluatedDraftAssets {
      jimmyJohnsonPoints
      otcPoints
      draftAsset {
        ...draftAssetFields
      }
    }
  }
  ${DraftAssetFieldsFragmentDoc}
`;
export const CurrentSeasonDraftAssetFieldsFragmentDoc = gql`
  fragment currentSeasonDraftAssetFields on CurrentSeasonDraftAsset {
    club {
      id
      clubCode
    }
    originalClub {
      id
    }
    overall
    pickInRound
    round
    season
  }
`;
export const ValueFieldsFragmentDoc = gql`
  fragment valueFields on Value {
    gapypoc
    dollars
  }
`;
export const PerformanceGapFieldsFragmentDoc = gql`
  fragment PerformanceGapFields on PerformanceGap {
    currentPerformanceValue {
      ...valueFields
    }
    performanceValueAddedDollars
    remainingGapDollars
    sideOfBall
    startingPerformanceValue {
      ...valueFields
    }
    sumerGeneralPositionShortName
    targetPerformanceValue {
      ...valueFields
    }
  }
  ${ValueFieldsFragmentDoc}
`;
export const DraftPickFieldsFragmentDoc = gql`
  fragment draftPickFields on DraftPick {
    overall
    number
    round
    season
  }
`;
export const PositionalNeedsFieldsFragmentDoc = gql`
  fragment positionalNeedsFields on DraftScenarioPositionalNeed {
    isFulfilled
    rank
    sumerGeneralPositionShortName
  }
`;
export const PlayerGradeFragmentDoc = gql`
  fragment PlayerGrade on Grade {
    playerId
    gradeType
    value
  }
`;
export const PositionFieldsFragmentDoc = gql`
  fragment positionFields on Player {
    clubGradingPosition {
      id
      shortName
    }
    sumerAtlasPosition {
      id
      shortName
    }
    sumerGeneralPosition {
      id
      shortName
    }
  }
`;
export const DraftPlayerFragmentDoc = gql`
  fragment DraftPlayer on Player {
    id
    gsisId
    collegeGsisId
    firstName
    lastName
    eligibilityYear
    height
    weight
    speed
    birthDate
    headshotUrl
    schoolId
    school {
      id
      schoolCode
      schoolName
    }
    favorite {
      playerId
    }
    grades {
      ...PlayerGrade
    }
    projectedGamesMissed {
      projectedGamesMissed
    }
    ...positionFields
  }
  ${PlayerGradeFragmentDoc}
  ${PositionFieldsFragmentDoc}
`;
export const ClubDraftablePlayerAtPickFieldsFragmentDoc = gql`
  fragment clubDraftablePlayerAtPickFields on ClubDraftablePlayerAtPick {
    marketValue {
      ...valueFields
    }
    performanceValue {
      ...valueFields
    }
    projectedRosterValueDollars
    rosterValueAdded {
      ...valueFields
    }
    availability {
      availabilityPercentage
      draftPick {
        ...currentSeasonDraftAssetFields
      }
    }
    availabilityAtClubsNextPick {
      availabilityPercentage
      draftPick {
        ...currentSeasonDraftAssetFields
      }
    }
    player {
      id
      firstName
      lastName
      school {
        id
        schoolName
        schoolCode
      }
      height
      weight
      playerGrade {
        ...PlayerGradeFragment
      }
      workouts {
        value
        measureType {
          name
        }
      }
      favorite {
        playerId
      }
      ...positionFields
      projectedGamesMissed {
        value
        season
        projectedGamesMissed
        projectedGamesMissedOverride
      }
      headshotUrl
    }
  }
  ${ValueFieldsFragmentDoc}
  ${CurrentSeasonDraftAssetFieldsFragmentDoc}
  ${PlayerGradeFragmentFragmentDoc}
  ${PositionFieldsFragmentDoc}
`;
export const ClubPickValueFieldsFragmentDoc = gql`
  fragment clubPickValueFields on ClubPickValue {
    backingPlayers {
      ...clubDraftablePlayerAtPickFields
    }
    value {
      ...valueFields
    }
  }
  ${ClubDraftablePlayerAtPickFieldsFragmentDoc}
  ${ValueFieldsFragmentDoc}
`;
export const ComboPlayerFieldsFragmentDoc = gql`
  fragment comboPlayerFields on ComboDraftablePlayer {
    isTopCombo
    playerAtPick {
      ...clubDraftablePlayerAtPickFields
    }
  }
  ${ClubDraftablePlayerAtPickFieldsFragmentDoc}
`;
export const WorkspacePlayerFieldsFragmentDoc = gql`
  fragment WorkspacePlayerFields on WorkspacePlayer {
    comboPlayerModelRequestId
    comboPlayerModelRunStatus
    player {
      ...clubDraftablePlayerAtPickFields
    }
    recommendedComboPlayers {
      ...comboPlayerFields
    }
  }
  ${ClubDraftablePlayerAtPickFieldsFragmentDoc}
  ${ComboPlayerFieldsFragmentDoc}
`;
export const TradeAssetFieldsFragmentDoc = gql`
  fragment tradeAssetFields on TradeAsset {
    draftPick {
      ...draftAssetFields
    }
    tradedPlayer {
      player {
        firstName
        lastName
      }
    }
  }
  ${DraftAssetFieldsFragmentDoc}
`;
export const EvaluatedAssetFieldsFragmentDoc = gql`
  fragment evaluatedAssetFields on EvaluatedDraftAsset {
    jimmyJohnsonPoints
    otcPoints
    draftAsset {
      ...draftAssetFields
    }
  }
  ${DraftAssetFieldsFragmentDoc}
`;
export const TradeChartValuesFieldsFragmentDoc = gql`
  fragment tradeChartValuesFields on TradeChartValues {
    jimmyJohnsonTradeValue {
      netPoints
      offeringPoints
      receivingPoints
    }
    otcTradeValue {
      netPoints
      offeringPoints
      receivingPoints
    }
    evaluatedDraftAssets {
      ...evaluatedAssetFields
    }
  }
  ${EvaluatedAssetFieldsFragmentDoc}
`;
export const TradeFieldsFragmentDoc = gql`
  fragment tradeFields on Trade {
    id
    leagueTradeId
    lastUpdated
    status
    tradePartners {
      club {
        id
        clubCode
      }
      offering {
        ...tradeAssetFields
      }
      receiving {
        ...tradeAssetFields
      }
    }
    tradeValue {
      evaluatedForClub {
        clubCode
      }
      sumerTradeValue {
        costIncurring {
          ...valueFields
        }
        costOffloading {
          ...valueFields
        }
        valueOffering {
          ...valueFields
        }
        valueReceiving {
          ...valueFields
        }
        netCostDollars
        netValueDollars
        tradeValueDollars
      }
      sumerTradeValueStatus
      tradeChartValues {
        ...tradeChartValuesFields
      }
    }
  }
  ${TradeAssetFieldsFragmentDoc}
  ${ValueFieldsFragmentDoc}
  ${TradeChartValuesFieldsFragmentDoc}
`;
export const DraftScenarioFieldsFragmentDoc = gql`
  fragment draftScenarioFields on DraftScenario {
    id
    draftType
    season
    name
    version
    lastUpdated
    latestModelRunRequestId
    modelRunStatus
    status
    activePick {
      ...currentSeasonDraftAssetFields
    }
    currentRosterValue {
      ...valueFields
    }
    startingRosterValue {
      ...valueFields
    }
    netRosterValueChangeDollars
    performanceGaps {
      ...PerformanceGapFields
    }
    assumptions {
      constraint
      contractMinimumEndDate
      contracts {
        id
      }
      description
      draftPick {
        ...draftPickFields
      }
      draftPickTrade {
        clubId
        draftPicksGained {
          ...draftPickFields
        }
        draftPicksLost {
          ...draftPickFields
        }
      }
      draftRoundMinimum
      id
      isValid
      name
      playerId
      subject
    }
    clubTeamNeeds {
      positionalNeeds {
        ...positionalNeedsFields
      }
    }
    allTeamNeeds {
      club {
        id
        clubCode
        clubName
      }
      positionalNeeds {
        isFulfilled
        rank
        sumerGeneralPositionShortName
      }
    }
    club {
      id
      clubCode
      clubName
    }
    clubsNextPick {
      ...currentSeasonDraftAssetFields
    }
    clubGradingPositionRecommendedSort
    draftedPlayers {
      player {
        ...DraftPlayer
        playerGrade {
          ...PlayerGradeFragment
        }
      }
      draftPick {
        ...currentSeasonDraftAssetFields
      }
    }
    clubDraftedPlayers {
      player {
        ...DraftPlayer
        playerGrade {
          ...PlayerGradeFragment
        }
      }
      draftPick {
        ...currentSeasonDraftAssetFields
      }
    }
    clubDraftAssets {
      ...draftAssetFields
    }
    draftOrder {
      ...currentSeasonDraftAssetFields
    }
    tradeOpportunities {
      clubValue {
        ...clubPickValueFields
      }
      ownerValue {
        ...valueFields
      }
      pickToTrade {
        ...currentSeasonDraftAssetFields
      }
    }
    workspaceSortMode
    currentWorkspacePlayers {
      slot
      workspacePlayer {
        ...WorkspacePlayerFields
      }
    }
    recommendedWorkspacePlayers {
      clubGradingPositionShortName
      workspacePlayer {
        player {
          ...clubDraftablePlayerAtPickFields
        }
        recommendedComboPlayers {
          ...comboPlayerFields
        }
      }
    }
    executedTrades {
      ...tradeFields
    }
    pinnedTrades {
      ...tradeFields
    }
    applicablePinnedTrades {
      ...tradeFields
    }
    modelMetadata {
      latestDamRequestBlobLocation
      latestDamResponseBlobLocation
      latestRosterValueRequestBlobLocation
      latestRosterValueResponseBlobLocation
      latestSampleSimulationRequestBlobLocation
      latestSampleSimulationResponseBlobLocation
      latestDamRequestComboPlayerBlobLocation
      latestDamResponseComboPlayerBlobLocation
      latestTargetValueRequestBlobLocation
      latestTargetValueResponseBlobLocation
    }
  }
  ${CurrentSeasonDraftAssetFieldsFragmentDoc}
  ${ValueFieldsFragmentDoc}
  ${PerformanceGapFieldsFragmentDoc}
  ${DraftPickFieldsFragmentDoc}
  ${PositionalNeedsFieldsFragmentDoc}
  ${DraftPlayerFragmentDoc}
  ${PlayerGradeFragmentFragmentDoc}
  ${DraftAssetFieldsFragmentDoc}
  ${ClubPickValueFieldsFragmentDoc}
  ${WorkspacePlayerFieldsFragmentDoc}
  ${ClubDraftablePlayerAtPickFieldsFragmentDoc}
  ${ComboPlayerFieldsFragmentDoc}
  ${TradeFieldsFragmentDoc}
`;
export const DraftablePlayerAtPickFieldsFragmentDoc = gql`
  fragment draftablePlayerAtPickFields on DraftablePlayerAtPick {
    player {
      id
      firstName
      lastName
      sumerGeneralPosition {
        id
        shortName
      }
      clubGradingPosition {
        id
        shortName
      }
    }
    marketValue {
      ...valueFields
    }
    availability {
      draftPick {
        ...currentSeasonDraftAssetFields
      }
      availabilityPercentage
    }
  }
  ${ValueFieldsFragmentDoc}
  ${CurrentSeasonDraftAssetFieldsFragmentDoc}
`;
export const EvaluatedDraftPickFieldsFragmentDoc = gql`
  fragment evaluatedDraftPickFields on EvaluatedDraftPick {
    clubRecommendedPlayers {
      ...clubDraftablePlayerAtPickFields
    }
    clubValue {
      ...clubPickValueFields
    }
    currentOwnerValue {
      ...valueFields
    }
    draftPick {
      ...currentSeasonDraftAssetFields
    }
    isInAvailabilityWindow
    isTradeOpportunity
    leagueBestAvailablePlayers {
      ...draftablePlayerAtPickFields
    }
    leagueValue {
      ...valueFields
    }
    marketValue {
      ...valueFields
    }
    ownerMostLikelyPlayers {
      ...draftablePlayerAtPickFields
    }
    teamNeeds {
      positionalNeeds {
        ...positionalNeedsFields
      }
    }
    topClubDemandForPick {
      id
      clubCode
      clubName
    }
  }
  ${ClubDraftablePlayerAtPickFieldsFragmentDoc}
  ${ClubPickValueFieldsFragmentDoc}
  ${ValueFieldsFragmentDoc}
  ${CurrentSeasonDraftAssetFieldsFragmentDoc}
  ${DraftablePlayerAtPickFieldsFragmentDoc}
  ${PositionalNeedsFieldsFragmentDoc}
`;
export const SumerEvaluatedDraftAssetsFieldsFragmentDoc = gql`
  fragment sumerEvaluatedDraftAssetsFields on SumerEvaluatedDraftAsset {
    cost {
      ...valueFields
    }
    net {
      ...valueFields
    }
    value {
      ...clubPickValueFields
    }
    draftAsset {
      ...draftAssetFields
    }
  }
  ${ValueFieldsFragmentDoc}
  ${ClubPickValueFieldsFragmentDoc}
  ${DraftAssetFieldsFragmentDoc}
`;
export const ScoutGradeFragmentDoc = gql`
  fragment ScoutGrade on ScoutGrade {
    id
    grade
    quality
    description
  }
`;
export const AlertFragmentDoc = gql`
  fragment Alert on Alert {
    id
    caption
    description
    abbreviation
  }
`;
export const EvaluationAlertFragmentDoc = gql`
  fragment EvaluationAlert on EvaluationAlert {
    evaluationId
    alert {
      ...Alert
    }
  }
  ${AlertFragmentDoc}
`;
export const FitFragmentDoc = gql`
  fragment Fit on Fit {
    id
    position
    name
    abbreviation
    description
  }
`;
export const EvaluationFitFragmentDoc = gql`
  fragment EvaluationFit on EvaluationFit {
    evaluationId
    fit {
      ...Fit
    }
  }
  ${FitFragmentDoc}
`;
export const SeasonTypeFragmentDoc = gql`
  fragment SeasonType on SeasonType {
    id
    abbreviation
    description
  }
`;
export const LeagueTypeFragmentDoc = gql`
  fragment LeagueType on LeagueType {
    id
    description
  }
`;
export const GameFragmentDoc = gql`
  fragment Game on Game {
    id
    season
    seasonType {
      ...SeasonType
    }
    week
    weekType {
      id
      description
    }
    leagueType {
      ...LeagueType
    }
    venue
    homeTeam
    awayTeam
    homeTeamScore
    awayTeamScore
    kickoffTime
  }
  ${SeasonTypeFragmentDoc}
  ${LeagueTypeFragmentDoc}
`;
export const EvaluationGameFragmentDoc = gql`
  fragment EvaluationGame on EvaluationGame {
    id
    gameId
    scoutGrade {
      ...ScoutGrade
    }
    game {
      ...Game
    }
    comments
  }
  ${ScoutGradeFragmentDoc}
  ${GameFragmentDoc}
`;
export const EvalFragmentDoc = gql`
  fragment Eval on Evaluation {
    id
    scoutGrade {
      ...ScoutGrade
    }
    created
    lastUpdated
    isFinal
    finalizedOn
    user {
      id
      firstName
      lastName
    }
    player {
      id
      firstName
      lastName
      school {
        id
        schoolName
      }
    }
    position {
      id
      shortName
    }
    traitGrades {
      id
      scoutGrade {
        ...ScoutGrade
      }
      traitDefinition {
        id
        caption
        shortName
        sort
      }
      comments
    }
    alerts {
      ...EvaluationAlert
    }
    fits {
      ...EvaluationFit
    }
    gameGrades {
      ...EvaluationGame
    }
    traitGroupGrades {
      id
      traitGroup {
        id
        caption
      }
      scoutGrade {
        id
        grade
      }
    }
    summary
  }
  ${ScoutGradeFragmentDoc}
  ${EvaluationAlertFragmentDoc}
  ${EvaluationFitFragmentDoc}
  ${EvaluationGameFragmentDoc}
`;
export const EvaluationTraitFragmentDoc = gql`
  fragment EvaluationTrait on EvaluationTrait {
    id
    traitDefinition {
      id
      caption
      shortName
      sort
    }
    scoutGrade {
      ...ScoutGrade
    }
    comments
  }
  ${ScoutGradeFragmentDoc}
`;
export const TraitGroupFragmentDoc = gql`
  fragment TraitGroup on TraitGroup {
    id
    caption
    sort
    traitGroupPositions {
      positionId
      sort
      traitDefinition {
        id
        caption
        description
        sort
      }
    }
  }
`;
export const AssumptionFieldsFragmentDoc = gql`
  fragment assumptionFields on Assumption {
    id
    draftPickTrade {
      clubId
      draftPicksGained {
        season
        round
        number
        overall
      }
      draftPicksLost {
        season
        round
        number
        overall
      }
    }
    name
    description
    isValid
    subject
    constraint
    playerId
    contracts {
      id
    }
    contractMinimumEndDate
    draftPick {
      season
      round
      number
      overall
    }
    draftRoundMinimum
  }
`;
export const MeasureTypeFragmentDoc = gql`
  fragment MeasureType on MeasureType {
    id
    name
    shortName
    description
    precision
    isFraction
    priority
  }
`;
export const PlayerMeasureFragmentDoc = gql`
  fragment PlayerMeasure on WorkoutPlayerMeasure {
    id
    playerId
    workout {
      workoutType {
        name
        priority
      }
      id
      workoutDate
      school {
        schoolCode
      }
      comments
    }
    measureType {
      name
      shortName
      isFraction
      precision
    }
    value
    isVerified
  }
`;
export const PositionFragmentDoc = gql`
  fragment Position on Position {
    id
    shortName
    sort
    sideOfBall
    description
  }
`;
export const PlayerPositionFragmentDoc = gql`
  fragment PlayerPosition on PlayerPackagePosition {
    depth
    package {
      name
    }
    position {
      shortName
    }
  }
`;
export const PlayerFragmentDoc = gql`
  fragment Player on Player {
    id
    gsisId
    collegeGsisId
    firstName
    lastName
    jersey
    draftYear
    height
    weight
    speed
    status
    birthDate
    draftRound
    draftSelection
    draftClub {
      clubCode
    }
    onClubRoster
    onCollegeRoster
    hometownCity
    hometownState
    hometownCountry
    eligibilityYear
    eligibility
    redshirt
    headshotUrl
    clubGradingPosition {
      shortName
    }
    nflExperience
    minSalaryCredits
    accruedSeasons
    sumerAtlasPosition {
      shortName
    }
    sumerGeneralPosition {
      shortName
    }
    playerPffPackagePositions {
      ...PlayerPosition
    }
    school {
      id
      schoolCode
      schoolName
      nickname
      primaryColor
      secondaryColor
    }
    club {
      id
      clubCode
      clubName
    }
    evaluations {
      id
      alerts {
        evaluationId
        id
        alertId
        alert {
          id
          caption
          abbreviation
          description
        }
      }
      scoutGrade {
        id
        grade
        quality
      }
    }
    favorite {
      playerId
    }
    grades {
      playerId
      gradeType
      value
      dollars
      season
      id
    }
    workouts {
      value
      measureType {
        name
        shortName
        isFraction
      }
      workout {
        workoutType {
          name
        }
        workoutDate
        comments
      }
    }
    clubGradingPosition {
      shortName
    }
    playerGrade {
      ...PlayerGradeFragment
    }
    projectedGamesMissed {
      playerId
      season
      value
      projectedGamesMissed
      projectedGamesMissedOverride
    }
    latestBirthDate
    playerOverrides {
      field
      value
    }
  }
  ${PlayerPositionFragmentDoc}
  ${PlayerGradeFragmentFragmentDoc}
`;
export const PlayerAvailabilityFragmentDoc = gql`
  fragment PlayerAvailability on Availability {
    availabilityPercentage
    draftPick {
      ...currentSeasonDraftAssetFields
    }
  }
  ${CurrentSeasonDraftAssetFieldsFragmentDoc}
`;
export const AssumptionDetailsFragmentDoc = gql`
  fragment AssumptionDetails on Assumption {
    id
    description
    playerId
    contracts {
      id
    }
    draftPick {
      season
      round
      overall
      number
    }
    contractMinimumEndDate
    constraint
    subject
  }
`;
export const SchoolFragmentDoc = gql`
  fragment School on School {
    id
    schoolCode
    schoolName
    nickname
    primaryColor
    secondaryColor
    conference
    division
  }
`;
export const StatsFragmentDoc = gql`
  fragment Stats on PlayerStat {
    rushingStatsYards
    rushingStatsAttempts
    rushingStatsTouchdowns
    rushingStatsRunsTwentyYardsPlus
    rushingStatsRunsFortyYardsPlus
    rushingStatsLongestRun
    rushingStatsFirstDownRushes
    rushingStatsFirstDownPercentage
    rushingStatsFumbles
    rushingStatsYardsAfterContact
    rushingStatsYardsAfterContactPerRush
    rushingStatsTacklesAvoided
    rushingStatsFumblesRecovered
    rushingStatsFumblesLost
    rushingStatsHurriesAllowed
    rushingStatsPenalties
    rushingStatsInjuriesInGame
    rushingStatsPressuresAllowed
    rushingStatsSacksAllowed
    rushingStatsYardsPerRush
    rushingStatsSnaps
    rushingStatsTeamSnaps
    rushingStatsSnapPercentage
    rushingStatsTwoPointAttempts
    rushingStatsTwoPointConversions
    returnStatsKickReturnYardsAverage
    returnStatsKickReturnAttempts
    returnStatsKickReturnYards
    returnStatsKickReturnTouchdowns
    returnStatsKickReturnsTwentyYardsPlus
    returnStatsKickReturnsFortyYardsPlus
    returnStatsKickReturnLongestYards
    returnStatsKickReturnFairCatches
    returnStatsKickReturnFumbles
    returnStatsKickReturnYardsAfterContact
    returnStatsKickReturnYardsAfterContactAverage
    returnStatsKickReturnSnaps
    returnStatsKickReturnTeamSnaps
    returnStatsKickReturnSnapPercentage
    returnStatsPuntReturnYardsAverage
    returnStatsPuntReturnAttempts
    returnStatsPuntReturnYards
    returnStatsPuntReturnTouchdowns
    returnStatsPuntReturnsTwentyYardsPlus
    returnStatsPuntReturnsFortyYardsPlus
    returnStatsPuntReturnLongestYards
    returnStatsPuntReturnFairCatches
    returnStatsPuntReturnFumbles
    returnStatsPuntReturnYardsAfterContact
    returnStatsPuntReturnYardsAfterContactAverage
    returnStatsPuntReturnSnaps
    returnStatsPuntReturnTeamSnaps
    returnStatsPuntReturnSnapPercentage
    receivingStatsReceptions
    receivingStatsTouchdowns
    receivingStatsYards
    receivingStatsYardsPerReception
    receivingStatsReceptionsTwentyYardsPlus
    receivingStatsReceptionsFortyYardsPlus
    receivingStatsLongestReception
    receivingStatsFirstDowns
    receivingStatsFirstDownPercentage
    receivingStatsReceivingFumbles
    receivingStatsYardsAfterCatch
    receivingStatsYardAfterCatchPerReception
    receivingStatsTargets
    receivingStatsTacklesAvoided
    receivingStatsDrops
    receivingStatsDropsPercentage
    receivingStatsYardsAfterContact
    receivingStatsInjuriesInGame
    receivingStatsPenalties
    receivingStatsCatchableThrows
    receivingStatsPassRouteDepthAverage
    receivingStatsSnaps
    receivingStatsTeamSnaps
    receivingStatsSnapPercentage
    receivingStatsPassRoutesRun
    receivingStatsReceptionsPerTouchdown
    receivingStatsTwoPointAttempts
    receivingStatsTwoPointConversions
    passingStatsYards
    passingStatsAttempts
    passingStatsCompletions
    passingStatsCompletionPercentage
    passingStatsYardsPerCompletionAverage
    passingStatsTouchdowns
    passingStatsTouchdownPercentage
    passingStatsInterceptions
    passingStatsInterceptionPercentage
    passingStatsPasserRating
    passingStatsFirstDowns
    passingStatsFirstDownPercentage
    passingStatsCompletionsTwentyYardsPlus
    passingStatsCompletionsFortyYardsPlus
    passingStatsLongestCompletion
    passingStatsSacks
    passingStatsSackYards
    passingStatsYardsAfterCatch
    passingStatsDrops
    passingStatsInjuriesInGame
    passingStatsFumbles
    passingStatsFumblesRecovered
    passingStatsHurriesAllowed
    passingStatsPassDepthAverage
    passingStatsPassDepthAttempts
    passingStatsCatchableThrows
    passingStatsCatchableThrowPercentage
    passingStatsPenalties
    passingStatsTimeToPressureAverage
    passingStatsTimeToPressureAttempts
    passingStatsTimeToThrowAverage
    passingStatsTimeToThrowAttempts
    passingStatsDropbackDepthAverage
    passingStatsDropbackAttempts
    passingStatsSnaps
    passingStatsTeamSnaps
    passingStatsSnapPercentage
    passingStatsPumpFakes
    passingStatsPassingEfficiency
    passingStatsYardsPerAttempt
    passingStatsTouchdownInterceptionRatio
    passingStatsTwoPointAttempts
    passingStatsTwoPointConversions
    defenseStatsFumblesForced
    defenseStatsTacklesSolo
    defenseStatsSacks
    defenseStatsSackPlays
    defenseStatsSackPercentage
    defenseStatsSnaps
    defenseStatsTeamSnaps
    defenseStatsSnapPercentage
    defenseStatsBattedPasses
    defenseStatsQbHits
    defenseStatsQbHitPercentage
    defenseStatsFumblesRecovered
    defenseStatsInjuriesInGame
    defenseStatsMissedTacklesOnQb
    defenseStatsPassBreakups
    defenseStatsStops
    defenseStatsTouchdowns
    defenseStatsPassRushAttempts
    defenseStatsCoverageAttempts
    defenseStatsBurns
    defenseStatsDefensiveTargets
    defenseStatsQbPressures
    defenseStatsFumblesRecoveredForTouchdown
    defenseStatsQbPressurePercentage
    defenseStatsQbHurries
    defenseStatsQbHurryPercentage
    defenseStatsMissedTackles
    defenseStatsPuntsBlocked
    defenseStatsFieldGoalsBlocked
    defenseStatsExtraPointsBlocked
    defenseStatsTacklesForLoss
    defenseStatsDroppedInterceptions
    defenseStatsInterceptions
    defenseStatsInterceptionTouchdowns
    defenseStatsInterceptionYards
    defenseStatsInterceptionLongestReturn
    defenseStatsInterceptionPercentage
    defenseStatsTacklesCombined
    defenseStatsTacklesAssisted
    kickingStatsFieldGoalsMade
    kickingStatsFieldGoalAttempts
    kickingStatsFieldGoalPercentage
    kickingStatsFieldGoals1To19Attempted
    kickingStatsFieldGoals1To19Made
    kickingStatsFieldGoals1To19Percentage
    kickingStatsFieldGoals20To29Attempted
    kickingStatsFieldGoals20To29Made
    kickingStatsFieldGoals20To29Percentage
    kickingStatsFieldGoals30To39Attempted
    kickingStatsFieldGoals30To39Made
    kickingStatsFieldGoals30To39Percentage
    kickingStatsFieldGoals40To49Attempted
    kickingStatsFieldGoals40To49Made
    kickingStatsFieldGoals40To49Percentage
    kickingStatsFieldGoals50To59Attempted
    kickingStatsFieldGoals50To59Made
    kickingStatsFieldGoals50To59Percentage
    kickingStatsFieldGoals60PlusAttempted
    kickingStatsFieldGoals60PlusMade
    kickingStatsFieldGoals60PlusPercentage
    kickingStatsFieldGoalMadeLongest
    kickingStatsFieldGoalsBlocked
    kickingStatsFieldGoalSnaps
    kickingStatsFieldGoalTeamSnaps
    kickingStatsFieldGoalSnapPercentage
    kickingStatsFieldGoalYardsAverage
    kickingStatsBlockedFieldGoalsAndExtraPoints
    kickingStatsExtraPointAttempts
    kickingStatsExtraPointsMade
    kickingStatsExtraPointsBlocked
    kickingStatsExtraPointsPercentage
    kickingStatsPuntYardsAverage
    kickingStatsPuntYardsNetAverage
    kickingStatsPuntAttempts
    kickingStatsPuntYardsLongest
    kickingStatsPuntYards
    kickingStatsPuntsIn20
    kickingStatsPuntsOutOfBounds
    kickingStatsPuntsDowned
    kickingStatsPuntsTouchbacks
    kickingStatsPuntsFairCaught
    kickingStatsPuntsReturned
    kickingStatsPuntReturnYards
    kickingStatsPuntReturnTouchdowns
    kickingStatsPuntsBlocked
    kickingStatsPuntSnaps
    kickingStatsPuntTeamSnaps
    kickingStatsPuntSnapPercentage
    kickingStatsPuntReturnYardsAverage
    kickingStatsPuntYardsNet
    kickingStatsPuntHangTimeAverage
    kickingStatsPuntOperationTimeAverage
    kickingStatsKickoffAttempts
    kickingStatsKickoffYards
    kickingStatsKickoffReturnYards
    kickingStatsKickoffTouchbacks
    kickingStatsKickoffTouchbackPercentage
    kickingStatsKickoffsReturned
    kickingStatsKickoffReturnLong
    kickingStatsKickoffReturnYardsAverage
    kickingStatsKickoffOnsideKicks
    kickingStatsKickoffOnsideKicksRecovered
    kickingStatsKickoffsOutOfBounds
    kickingStatsKickoffReturnTouchdowns
    kickingStatsKickoffDepthAverage
    kickingStatsKickoffYardsAverage
    kickingStatsKickoffSnaps
    kickingStatsKickoffTeamSnaps
    kickingStatsKickoffSnapPercentage
    kickingStatsKickoffDepthAttempts
  }
`;
export const CareerStatsFragmentDoc = gql`
  fragment CareerStats on CareerStat {
    leagueType {
      ...LeagueType
    }
    seasonType {
      ...SeasonType
    }
    playerStat {
      ...Stats
    }
    perGameStatsGamesPlayed
    perGameStatsPassAttemptsPerGame
    perGameStatsPassingYardsPerGame
    perGameStatsReceivingYardsPerGame
    perGameStatsReceptionsPerGame
    perGameStatsRushAttemptsPerGame
    perGameStatsRushingYardsPerGame
  }
  ${LeagueTypeFragmentDoc}
  ${SeasonTypeFragmentDoc}
  ${StatsFragmentDoc}
`;
export const SeasonStatsFragmentDoc = gql`
  fragment SeasonStats on SeasonStat {
    season
    seasonType {
      ...SeasonType
    }
    leagueType {
      ...LeagueType
    }
    playerStat {
      ...Stats
    }
    perGameStatsGamesPlayed
    perGameStatsPassAttemptsPerGame
    perGameStatsPassingYardsPerGame
    perGameStatsReceivingYardsPerGame
    perGameStatsReceptionsPerGame
    perGameStatsRushAttemptsPerGame
    perGameStatsRushingYardsPerGame
  }
  ${SeasonTypeFragmentDoc}
  ${LeagueTypeFragmentDoc}
  ${StatsFragmentDoc}
`;
export const GameStatsFragmentDoc = gql`
  fragment GameStats on GameStat {
    season
    gameId
    game {
      id
      week
      weekType {
        description
      }
      season
      homeTeam
      awayTeam
    }
    weekType {
      id
      description
    }
    teamId
    seasonType {
      ...SeasonType
    }
    leagueType {
      ...LeagueType
    }
    playerStat {
      ...Stats
    }
  }
  ${SeasonTypeFragmentDoc}
  ${LeagueTypeFragmentDoc}
  ${StatsFragmentDoc}
`;
export const PositionalImportanceMetricsFieldsFragmentDoc = gql`
  fragment PositionalImportanceMetricsFields on PositionalImportanceMetric {
    value
    expertDefault
    marketDefault
    group
    clubNodeId
    id
  }
`;
export const PositionalImportancePageFieldsFragmentDoc = gql`
  fragment PositionalImportancePageFields on PositionalImportancePage {
    id
    title
    surveyType
    metrics {
      ...PositionalImportanceMetricsFields
    }
  }
  ${PositionalImportanceMetricsFieldsFragmentDoc}
`;
export const PositionalImportanceSurveyFieldsFragmentDoc = gql`
  fragment PositionalImportanceSurveyFields on PositionalImportanceSurvey {
    id
    pages {
      ...PositionalImportancePageFields
    }
  }
  ${PositionalImportancePageFieldsFragmentDoc}
`;
export const PermissionFragmentDoc = gql`
  fragment Permission on Permission {
    name
  }
`;
export const DraftScenarioRequestEvaluationRefreshDocument = gql`
  mutation draftScenarioRequestEvaluationRefresh($draftId: UUID!) {
    draftScenarioRequestEvaluationRefresh(request: { draftId: $draftId }) {
      id
      status
      version
      modelRunStatus
    }
  }
`;
export type DraftScenarioRequestEvaluationRefreshMutationFn =
  Apollo.MutationFunction<
    DraftScenarioRequestEvaluationRefreshMutation,
    DraftScenarioRequestEvaluationRefreshMutationVariables
  >;

/**
 * __useDraftScenarioRequestEvaluationRefreshMutation__
 *
 * To run a mutation, you first call `useDraftScenarioRequestEvaluationRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftScenarioRequestEvaluationRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftScenarioRequestEvaluationRefreshMutation, { data, loading, error }] = useDraftScenarioRequestEvaluationRefreshMutation({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useDraftScenarioRequestEvaluationRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DraftScenarioRequestEvaluationRefreshMutation,
    DraftScenarioRequestEvaluationRefreshMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DraftScenarioRequestEvaluationRefreshMutation,
    DraftScenarioRequestEvaluationRefreshMutationVariables
  >(DraftScenarioRequestEvaluationRefreshDocument, options);
}
export type DraftScenarioRequestEvaluationRefreshMutationHookResult =
  ReturnType<typeof useDraftScenarioRequestEvaluationRefreshMutation>;
export type DraftScenarioRequestEvaluationRefreshMutationResult =
  Apollo.MutationResult<DraftScenarioRequestEvaluationRefreshMutation>;
export type DraftScenarioRequestEvaluationRefreshMutationOptions =
  Apollo.BaseMutationOptions<
    DraftScenarioRequestEvaluationRefreshMutation,
    DraftScenarioRequestEvaluationRefreshMutationVariables
  >;
export const DraftScenarioDomainEventsDocument = gql`
  query draftScenarioDomainEvents($draftId: UUID!, $version: Long) {
    draftScenarioDomainEvents(draftId: $draftId, endVersion: $version) {
      id
      streamId
      timestamp
      version
      type
    }
  }
`;

/**
 * __useDraftScenarioDomainEventsQuery__
 *
 * To run a query within a React component, call `useDraftScenarioDomainEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftScenarioDomainEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftScenarioDomainEventsQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDraftScenarioDomainEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DraftScenarioDomainEventsQuery,
    DraftScenarioDomainEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DraftScenarioDomainEventsQuery,
    DraftScenarioDomainEventsQueryVariables
  >(DraftScenarioDomainEventsDocument, options);
}
export function useDraftScenarioDomainEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DraftScenarioDomainEventsQuery,
    DraftScenarioDomainEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DraftScenarioDomainEventsQuery,
    DraftScenarioDomainEventsQueryVariables
  >(DraftScenarioDomainEventsDocument, options);
}
export type DraftScenarioDomainEventsQueryHookResult = ReturnType<
  typeof useDraftScenarioDomainEventsQuery
>;
export type DraftScenarioDomainEventsLazyQueryHookResult = ReturnType<
  typeof useDraftScenarioDomainEventsLazyQuery
>;
export type DraftScenarioDomainEventsQueryResult = Apollo.QueryResult<
  DraftScenarioDomainEventsQuery,
  DraftScenarioDomainEventsQueryVariables
>;
export const DraftScenarioDomainEventDocument = gql`
  query draftScenarioDomainEvent($draftId: UUID!, $eventId: UUID!) {
    draftScenarioDomainEvent(draftId: $draftId, eventId: $eventId) {
      id
      payload
      streamId
      timestamp
      version
      type
    }
  }
`;

/**
 * __useDraftScenarioDomainEventQuery__
 *
 * To run a query within a React component, call `useDraftScenarioDomainEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftScenarioDomainEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftScenarioDomainEventQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDraftScenarioDomainEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    DraftScenarioDomainEventQuery,
    DraftScenarioDomainEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DraftScenarioDomainEventQuery,
    DraftScenarioDomainEventQueryVariables
  >(DraftScenarioDomainEventDocument, options);
}
export function useDraftScenarioDomainEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DraftScenarioDomainEventQuery,
    DraftScenarioDomainEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DraftScenarioDomainEventQuery,
    DraftScenarioDomainEventQueryVariables
  >(DraftScenarioDomainEventDocument, options);
}
export type DraftScenarioDomainEventQueryHookResult = ReturnType<
  typeof useDraftScenarioDomainEventQuery
>;
export type DraftScenarioDomainEventLazyQueryHookResult = ReturnType<
  typeof useDraftScenarioDomainEventLazyQuery
>;
export type DraftScenarioDomainEventQueryResult = Apollo.QueryResult<
  DraftScenarioDomainEventQuery,
  DraftScenarioDomainEventQueryVariables
>;
export const DraftScenarioAtVersionDocument = gql`
  query draftScenarioAtVersion($draftId: UUID!, $version: Long!) {
    draftScenarioAtPointInTime(draftId: $draftId, atEventVersion: $version) {
      ...draftScenarioFields
    }
  }
  ${DraftScenarioFieldsFragmentDoc}
`;

/**
 * __useDraftScenarioAtVersionQuery__
 *
 * To run a query within a React component, call `useDraftScenarioAtVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftScenarioAtVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftScenarioAtVersionQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDraftScenarioAtVersionQuery(
  baseOptions: Apollo.QueryHookOptions<
    DraftScenarioAtVersionQuery,
    DraftScenarioAtVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DraftScenarioAtVersionQuery,
    DraftScenarioAtVersionQueryVariables
  >(DraftScenarioAtVersionDocument, options);
}
export function useDraftScenarioAtVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DraftScenarioAtVersionQuery,
    DraftScenarioAtVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DraftScenarioAtVersionQuery,
    DraftScenarioAtVersionQueryVariables
  >(DraftScenarioAtVersionDocument, options);
}
export type DraftScenarioAtVersionQueryHookResult = ReturnType<
  typeof useDraftScenarioAtVersionQuery
>;
export type DraftScenarioAtVersionLazyQueryHookResult = ReturnType<
  typeof useDraftScenarioAtVersionLazyQuery
>;
export type DraftScenarioAtVersionQueryResult = Apollo.QueryResult<
  DraftScenarioAtVersionQuery,
  DraftScenarioAtVersionQueryVariables
>;
export const DraftAssetsAtVersionDocument = gql`
  query draftAssetsAtVersion($draftId: UUID!, $version: Long!) {
    draftScenarioAtPointInTime(draftId: $draftId, atEventVersion: $version) {
      ...draftScenarioAssetFields
    }
  }
  ${DraftScenarioAssetFieldsFragmentDoc}
`;

/**
 * __useDraftAssetsAtVersionQuery__
 *
 * To run a query within a React component, call `useDraftAssetsAtVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftAssetsAtVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftAssetsAtVersionQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDraftAssetsAtVersionQuery(
  baseOptions: Apollo.QueryHookOptions<
    DraftAssetsAtVersionQuery,
    DraftAssetsAtVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DraftAssetsAtVersionQuery,
    DraftAssetsAtVersionQueryVariables
  >(DraftAssetsAtVersionDocument, options);
}
export function useDraftAssetsAtVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DraftAssetsAtVersionQuery,
    DraftAssetsAtVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DraftAssetsAtVersionQuery,
    DraftAssetsAtVersionQueryVariables
  >(DraftAssetsAtVersionDocument, options);
}
export type DraftAssetsAtVersionQueryHookResult = ReturnType<
  typeof useDraftAssetsAtVersionQuery
>;
export type DraftAssetsAtVersionLazyQueryHookResult = ReturnType<
  typeof useDraftAssetsAtVersionLazyQuery
>;
export type DraftAssetsAtVersionQueryResult = Apollo.QueryResult<
  DraftAssetsAtVersionQuery,
  DraftAssetsAtVersionQueryVariables
>;
export const GetDraftScenarioSerializedDocument = gql`
  query GetDraftScenarioSerialized($draftId: UUID!, $version: Long!) {
    draftScenarioSerialized(draftId: $draftId, atEventVersion: $version)
  }
`;

/**
 * __useGetDraftScenarioSerializedQuery__
 *
 * To run a query within a React component, call `useGetDraftScenarioSerializedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftScenarioSerializedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftScenarioSerializedQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetDraftScenarioSerializedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDraftScenarioSerializedQuery,
    GetDraftScenarioSerializedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDraftScenarioSerializedQuery,
    GetDraftScenarioSerializedQueryVariables
  >(GetDraftScenarioSerializedDocument, options);
}
export function useGetDraftScenarioSerializedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftScenarioSerializedQuery,
    GetDraftScenarioSerializedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDraftScenarioSerializedQuery,
    GetDraftScenarioSerializedQueryVariables
  >(GetDraftScenarioSerializedDocument, options);
}
export type GetDraftScenarioSerializedQueryHookResult = ReturnType<
  typeof useGetDraftScenarioSerializedQuery
>;
export type GetDraftScenarioSerializedLazyQueryHookResult = ReturnType<
  typeof useGetDraftScenarioSerializedLazyQuery
>;
export type GetDraftScenarioSerializedQueryResult = Apollo.QueryResult<
  GetDraftScenarioSerializedQuery,
  GetDraftScenarioSerializedQueryVariables
>;
export const GetRosterValueResponseForDraftDocument = gql`
  query GetRosterValueResponseForDraft($draftId: UUID!) {
    rosterValueResponse(draftId: $draftId)
  }
`;

/**
 * __useGetRosterValueResponseForDraftQuery__
 *
 * To run a query within a React component, call `useGetRosterValueResponseForDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRosterValueResponseForDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRosterValueResponseForDraftQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useGetRosterValueResponseForDraftQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRosterValueResponseForDraftQuery,
    GetRosterValueResponseForDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRosterValueResponseForDraftQuery,
    GetRosterValueResponseForDraftQueryVariables
  >(GetRosterValueResponseForDraftDocument, options);
}
export function useGetRosterValueResponseForDraftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRosterValueResponseForDraftQuery,
    GetRosterValueResponseForDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRosterValueResponseForDraftQuery,
    GetRosterValueResponseForDraftQueryVariables
  >(GetRosterValueResponseForDraftDocument, options);
}
export type GetRosterValueResponseForDraftQueryHookResult = ReturnType<
  typeof useGetRosterValueResponseForDraftQuery
>;
export type GetRosterValueResponseForDraftLazyQueryHookResult = ReturnType<
  typeof useGetRosterValueResponseForDraftLazyQuery
>;
export type GetRosterValueResponseForDraftQueryResult = Apollo.QueryResult<
  GetRosterValueResponseForDraftQuery,
  GetRosterValueResponseForDraftQueryVariables
>;
export const GetSampleSimResponseForDraftDocument = gql`
  query GetSampleSimResponseForDraft($draftId: UUID!) {
    sampleSimResponse(draftId: $draftId)
  }
`;

/**
 * __useGetSampleSimResponseForDraftQuery__
 *
 * To run a query within a React component, call `useGetSampleSimResponseForDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSampleSimResponseForDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSampleSimResponseForDraftQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useGetSampleSimResponseForDraftQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSampleSimResponseForDraftQuery,
    GetSampleSimResponseForDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSampleSimResponseForDraftQuery,
    GetSampleSimResponseForDraftQueryVariables
  >(GetSampleSimResponseForDraftDocument, options);
}
export function useGetSampleSimResponseForDraftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSampleSimResponseForDraftQuery,
    GetSampleSimResponseForDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSampleSimResponseForDraftQuery,
    GetSampleSimResponseForDraftQueryVariables
  >(GetSampleSimResponseForDraftDocument, options);
}
export type GetSampleSimResponseForDraftQueryHookResult = ReturnType<
  typeof useGetSampleSimResponseForDraftQuery
>;
export type GetSampleSimResponseForDraftLazyQueryHookResult = ReturnType<
  typeof useGetSampleSimResponseForDraftLazyQuery
>;
export type GetSampleSimResponseForDraftQueryResult = Apollo.QueryResult<
  GetSampleSimResponseForDraftQuery,
  GetSampleSimResponseForDraftQueryVariables
>;
export const GetDamResponseForDraftDocument = gql`
  query GetDamResponseForDraft($draftId: UUID!) {
    damResponse(draftId: $draftId)
  }
`;

/**
 * __useGetDamResponseForDraftQuery__
 *
 * To run a query within a React component, call `useGetDamResponseForDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDamResponseForDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDamResponseForDraftQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useGetDamResponseForDraftQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDamResponseForDraftQuery,
    GetDamResponseForDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDamResponseForDraftQuery,
    GetDamResponseForDraftQueryVariables
  >(GetDamResponseForDraftDocument, options);
}
export function useGetDamResponseForDraftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDamResponseForDraftQuery,
    GetDamResponseForDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDamResponseForDraftQuery,
    GetDamResponseForDraftQueryVariables
  >(GetDamResponseForDraftDocument, options);
}
export type GetDamResponseForDraftQueryHookResult = ReturnType<
  typeof useGetDamResponseForDraftQuery
>;
export type GetDamResponseForDraftLazyQueryHookResult = ReturnType<
  typeof useGetDamResponseForDraftLazyQuery
>;
export type GetDamResponseForDraftQueryResult = Apollo.QueryResult<
  GetDamResponseForDraftQuery,
  GetDamResponseForDraftQueryVariables
>;
export const GetDamComboPlayersResponseForDraftDocument = gql`
  query GetDamComboPlayersResponseForDraft($draftId: UUID!) {
    damComboPlayerResponse(draftId: $draftId)
  }
`;

/**
 * __useGetDamComboPlayersResponseForDraftQuery__
 *
 * To run a query within a React component, call `useGetDamComboPlayersResponseForDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDamComboPlayersResponseForDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDamComboPlayersResponseForDraftQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useGetDamComboPlayersResponseForDraftQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDamComboPlayersResponseForDraftQuery,
    GetDamComboPlayersResponseForDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDamComboPlayersResponseForDraftQuery,
    GetDamComboPlayersResponseForDraftQueryVariables
  >(GetDamComboPlayersResponseForDraftDocument, options);
}
export function useGetDamComboPlayersResponseForDraftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDamComboPlayersResponseForDraftQuery,
    GetDamComboPlayersResponseForDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDamComboPlayersResponseForDraftQuery,
    GetDamComboPlayersResponseForDraftQueryVariables
  >(GetDamComboPlayersResponseForDraftDocument, options);
}
export type GetDamComboPlayersResponseForDraftQueryHookResult = ReturnType<
  typeof useGetDamComboPlayersResponseForDraftQuery
>;
export type GetDamComboPlayersResponseForDraftLazyQueryHookResult = ReturnType<
  typeof useGetDamComboPlayersResponseForDraftLazyQuery
>;
export type GetDamComboPlayersResponseForDraftQueryResult = Apollo.QueryResult<
  GetDamComboPlayersResponseForDraftQuery,
  GetDamComboPlayersResponseForDraftQueryVariables
>;
export const GetTargetValuesForDraftDocument = gql`
  query GetTargetValuesForDraft($draftId: UUID!) {
    targetValueResponse(draftId: $draftId)
  }
`;

/**
 * __useGetTargetValuesForDraftQuery__
 *
 * To run a query within a React component, call `useGetTargetValuesForDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetValuesForDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetValuesForDraftQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useGetTargetValuesForDraftQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTargetValuesForDraftQuery,
    GetTargetValuesForDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTargetValuesForDraftQuery,
    GetTargetValuesForDraftQueryVariables
  >(GetTargetValuesForDraftDocument, options);
}
export function useGetTargetValuesForDraftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTargetValuesForDraftQuery,
    GetTargetValuesForDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTargetValuesForDraftQuery,
    GetTargetValuesForDraftQueryVariables
  >(GetTargetValuesForDraftDocument, options);
}
export type GetTargetValuesForDraftQueryHookResult = ReturnType<
  typeof useGetTargetValuesForDraftQuery
>;
export type GetTargetValuesForDraftLazyQueryHookResult = ReturnType<
  typeof useGetTargetValuesForDraftLazyQuery
>;
export type GetTargetValuesForDraftQueryResult = Apollo.QueryResult<
  GetTargetValuesForDraftQuery,
  GetTargetValuesForDraftQueryVariables
>;
export const UpdateDraftScenarioConfigurationDocument = gql`
  mutation UpdateDraftScenarioConfiguration(
    $request: UpdateDraftScenarioConfigurationRequest!
  ) {
    draftScenarioUpdateConfiguration(request: $request) {
      id
      configuration {
        bestPlayersCountPerPick
        clubDemandPositionsNeedDepth
        maxClubDemandForPick
        maximumWorkspacePlayers
        availabilityThreshold
        projectedSeasons
        recommendedPlayersCount
        tradeBackWindow
        tradeOpportunityThresholdMinGapypoc
        tradeOpportunityThresholdMinPercent
        numOwnerPlayers
        targetPerformanceValueRank
      }
    }
  }
`;
export type UpdateDraftScenarioConfigurationMutationFn =
  Apollo.MutationFunction<
    UpdateDraftScenarioConfigurationMutation,
    UpdateDraftScenarioConfigurationMutationVariables
  >;

/**
 * __useUpdateDraftScenarioConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateDraftScenarioConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftScenarioConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftScenarioConfigurationMutation, { data, loading, error }] = useUpdateDraftScenarioConfigurationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateDraftScenarioConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDraftScenarioConfigurationMutation,
    UpdateDraftScenarioConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDraftScenarioConfigurationMutation,
    UpdateDraftScenarioConfigurationMutationVariables
  >(UpdateDraftScenarioConfigurationDocument, options);
}
export type UpdateDraftScenarioConfigurationMutationHookResult = ReturnType<
  typeof useUpdateDraftScenarioConfigurationMutation
>;
export type UpdateDraftScenarioConfigurationMutationResult =
  Apollo.MutationResult<UpdateDraftScenarioConfigurationMutation>;
export type UpdateDraftScenarioConfigurationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDraftScenarioConfigurationMutation,
    UpdateDraftScenarioConfigurationMutationVariables
  >;
export const TriggerPerformanceGapRefreshDocument = gql`
  mutation TriggerPerformanceGapRefresh($draftId: UUID!) {
    draftScenarioTriggerPerformanceGapRefresh(draftId: $draftId) {
      id
      status
      version
      modelRunStatus
    }
  }
`;
export type TriggerPerformanceGapRefreshMutationFn = Apollo.MutationFunction<
  TriggerPerformanceGapRefreshMutation,
  TriggerPerformanceGapRefreshMutationVariables
>;

/**
 * __useTriggerPerformanceGapRefreshMutation__
 *
 * To run a mutation, you first call `useTriggerPerformanceGapRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerPerformanceGapRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerPerformanceGapRefreshMutation, { data, loading, error }] = useTriggerPerformanceGapRefreshMutation({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useTriggerPerformanceGapRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerPerformanceGapRefreshMutation,
    TriggerPerformanceGapRefreshMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TriggerPerformanceGapRefreshMutation,
    TriggerPerformanceGapRefreshMutationVariables
  >(TriggerPerformanceGapRefreshDocument, options);
}
export type TriggerPerformanceGapRefreshMutationHookResult = ReturnType<
  typeof useTriggerPerformanceGapRefreshMutation
>;
export type TriggerPerformanceGapRefreshMutationResult =
  Apollo.MutationResult<TriggerPerformanceGapRefreshMutation>;
export type TriggerPerformanceGapRefreshMutationOptions =
  Apollo.BaseMutationOptions<
    TriggerPerformanceGapRefreshMutation,
    TriggerPerformanceGapRefreshMutationVariables
  >;
export const GetSelectedPickPlayerDocument = gql`
  query getSelectedPickPlayer($id: UUID!) {
    playerById(id: $id) {
      ...SelectedPickPlayer
    }
  }
  ${SelectedPickPlayerFragmentDoc}
`;

/**
 * __useGetSelectedPickPlayerQuery__
 *
 * To run a query within a React component, call `useGetSelectedPickPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedPickPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedPickPlayerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSelectedPickPlayerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSelectedPickPlayerQuery,
    GetSelectedPickPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSelectedPickPlayerQuery,
    GetSelectedPickPlayerQueryVariables
  >(GetSelectedPickPlayerDocument, options);
}
export function useGetSelectedPickPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSelectedPickPlayerQuery,
    GetSelectedPickPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSelectedPickPlayerQuery,
    GetSelectedPickPlayerQueryVariables
  >(GetSelectedPickPlayerDocument, options);
}
export type GetSelectedPickPlayerQueryHookResult = ReturnType<
  typeof useGetSelectedPickPlayerQuery
>;
export type GetSelectedPickPlayerLazyQueryHookResult = ReturnType<
  typeof useGetSelectedPickPlayerLazyQuery
>;
export type GetSelectedPickPlayerQueryResult = Apollo.QueryResult<
  GetSelectedPickPlayerQuery,
  GetSelectedPickPlayerQueryVariables
>;
export const GetDraftAssetsDocument = gql`
  query GetDraftAssets($draftId: UUID!) {
    draftScenarioById(id: $draftId) {
      ...draftScenarioAssetFields
    }
  }
  ${DraftScenarioAssetFieldsFragmentDoc}
`;

/**
 * __useGetDraftAssetsQuery__
 *
 * To run a query within a React component, call `useGetDraftAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftAssetsQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useGetDraftAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDraftAssetsQuery,
    GetDraftAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDraftAssetsQuery, GetDraftAssetsQueryVariables>(
    GetDraftAssetsDocument,
    options
  );
}
export function useGetDraftAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftAssetsQuery,
    GetDraftAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDraftAssetsQuery, GetDraftAssetsQueryVariables>(
    GetDraftAssetsDocument,
    options
  );
}
export type GetDraftAssetsQueryHookResult = ReturnType<
  typeof useGetDraftAssetsQuery
>;
export type GetDraftAssetsLazyQueryHookResult = ReturnType<
  typeof useGetDraftAssetsLazyQuery
>;
export type GetDraftAssetsQueryResult = Apollo.QueryResult<
  GetDraftAssetsQuery,
  GetDraftAssetsQueryVariables
>;
export const GetDraftLastUpdatedDocument = gql`
  query GetDraftLastUpdated($draftId: UUID!) {
    draftScenarioById(id: $draftId) {
      id
      version
      lastUpdated
      modelRunStatus
    }
  }
`;

/**
 * __useGetDraftLastUpdatedQuery__
 *
 * To run a query within a React component, call `useGetDraftLastUpdatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftLastUpdatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftLastUpdatedQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useGetDraftLastUpdatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDraftLastUpdatedQuery,
    GetDraftLastUpdatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDraftLastUpdatedQuery,
    GetDraftLastUpdatedQueryVariables
  >(GetDraftLastUpdatedDocument, options);
}
export function useGetDraftLastUpdatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftLastUpdatedQuery,
    GetDraftLastUpdatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDraftLastUpdatedQuery,
    GetDraftLastUpdatedQueryVariables
  >(GetDraftLastUpdatedDocument, options);
}
export type GetDraftLastUpdatedQueryHookResult = ReturnType<
  typeof useGetDraftLastUpdatedQuery
>;
export type GetDraftLastUpdatedLazyQueryHookResult = ReturnType<
  typeof useGetDraftLastUpdatedLazyQuery
>;
export type GetDraftLastUpdatedQueryResult = Apollo.QueryResult<
  GetDraftLastUpdatedQuery,
  GetDraftLastUpdatedQueryVariables
>;
export const GetEvaluatedDraftPicksDocument = gql`
  query GetEvaluatedDraftPicks($draftId: UUID!) {
    draftScenarioById(id: $draftId) {
      id
      version
      lastUpdated
      evaluatedDraftPicks {
        ...evaluatedDraftPickFields
      }
    }
  }
  ${EvaluatedDraftPickFieldsFragmentDoc}
`;

/**
 * __useGetEvaluatedDraftPicksQuery__
 *
 * To run a query within a React component, call `useGetEvaluatedDraftPicksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvaluatedDraftPicksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvaluatedDraftPicksQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useGetEvaluatedDraftPicksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEvaluatedDraftPicksQuery,
    GetEvaluatedDraftPicksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEvaluatedDraftPicksQuery,
    GetEvaluatedDraftPicksQueryVariables
  >(GetEvaluatedDraftPicksDocument, options);
}
export function useGetEvaluatedDraftPicksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEvaluatedDraftPicksQuery,
    GetEvaluatedDraftPicksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEvaluatedDraftPicksQuery,
    GetEvaluatedDraftPicksQueryVariables
  >(GetEvaluatedDraftPicksDocument, options);
}
export type GetEvaluatedDraftPicksQueryHookResult = ReturnType<
  typeof useGetEvaluatedDraftPicksQuery
>;
export type GetEvaluatedDraftPicksLazyQueryHookResult = ReturnType<
  typeof useGetEvaluatedDraftPicksLazyQuery
>;
export type GetEvaluatedDraftPicksQueryResult = Apollo.QueryResult<
  GetEvaluatedDraftPicksQuery,
  GetEvaluatedDraftPicksQueryVariables
>;
export const GetSearchIndexStatusDocument = gql`
  query GetSearchIndexStatus {
    indexStatus {
      playersDocumentCount
      playersDatabaseCount
      schoolsDocumentCount
      schoolsDatabaseCount
      clubsDocumentCount
      clubsDatabaseCount
    }
  }
`;

/**
 * __useGetSearchIndexStatusQuery__
 *
 * To run a query within a React component, call `useGetSearchIndexStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchIndexStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchIndexStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSearchIndexStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSearchIndexStatusQuery,
    GetSearchIndexStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchIndexStatusQuery,
    GetSearchIndexStatusQueryVariables
  >(GetSearchIndexStatusDocument, options);
}
export function useGetSearchIndexStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchIndexStatusQuery,
    GetSearchIndexStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchIndexStatusQuery,
    GetSearchIndexStatusQueryVariables
  >(GetSearchIndexStatusDocument, options);
}
export type GetSearchIndexStatusQueryHookResult = ReturnType<
  typeof useGetSearchIndexStatusQuery
>;
export type GetSearchIndexStatusLazyQueryHookResult = ReturnType<
  typeof useGetSearchIndexStatusLazyQuery
>;
export type GetSearchIndexStatusQueryResult = Apollo.QueryResult<
  GetSearchIndexStatusQuery,
  GetSearchIndexStatusQueryVariables
>;
export const ResetPlayerSearchIndexDocument = gql`
  mutation ResetPlayerSearchIndex {
    resetPlayerSearchIndices(
      resetPlayerSearchIndicesRequest: {
        skipIndexRecreation: false
        refreshClubs: true
        refreshPlayers: true
        refreshSchools: true
      }
    )
  }
`;
export type ResetPlayerSearchIndexMutationFn = Apollo.MutationFunction<
  ResetPlayerSearchIndexMutation,
  ResetPlayerSearchIndexMutationVariables
>;

/**
 * __useResetPlayerSearchIndexMutation__
 *
 * To run a mutation, you first call `useResetPlayerSearchIndexMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPlayerSearchIndexMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPlayerSearchIndexMutation, { data, loading, error }] = useResetPlayerSearchIndexMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetPlayerSearchIndexMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPlayerSearchIndexMutation,
    ResetPlayerSearchIndexMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPlayerSearchIndexMutation,
    ResetPlayerSearchIndexMutationVariables
  >(ResetPlayerSearchIndexDocument, options);
}
export type ResetPlayerSearchIndexMutationHookResult = ReturnType<
  typeof useResetPlayerSearchIndexMutation
>;
export type ResetPlayerSearchIndexMutationResult =
  Apollo.MutationResult<ResetPlayerSearchIndexMutation>;
export type ResetPlayerSearchIndexMutationOptions = Apollo.BaseMutationOptions<
  ResetPlayerSearchIndexMutation,
  ResetPlayerSearchIndexMutationVariables
>;
export const GetClubsDocument = gql`
  query GetClubs {
    clubs(order: { clubCode: ASC }) {
      id
      clubCode
      clubName
      conference
      division
      isActive
      primaryColor
      secondaryColor
      logoUrl
      nickName
    }
  }
`;

/**
 * __useGetClubsQuery__
 *
 * To run a query within a React component, call `useGetClubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClubsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetClubsQuery, GetClubsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClubsQuery, GetClubsQueryVariables>(
    GetClubsDocument,
    options
  );
}
export function useGetClubsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubsQuery,
    GetClubsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClubsQuery, GetClubsQueryVariables>(
    GetClubsDocument,
    options
  );
}
export type GetClubsQueryHookResult = ReturnType<typeof useGetClubsQuery>;
export type GetClubsLazyQueryHookResult = ReturnType<
  typeof useGetClubsLazyQuery
>;
export type GetClubsQueryResult = Apollo.QueryResult<
  GetClubsQuery,
  GetClubsQueryVariables
>;
export const GetClubDocument = gql`
  query getClub($id: UUID!) {
    clubById(id: $id) {
      ...Club
    }
  }
  ${ClubFragmentDoc}
`;

/**
 * __useGetClubQuery__
 *
 * To run a query within a React component, call `useGetClubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClubQuery(
  baseOptions: Apollo.QueryHookOptions<GetClubQuery, GetClubQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClubQuery, GetClubQueryVariables>(
    GetClubDocument,
    options
  );
}
export function useGetClubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClubQuery, GetClubQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClubQuery, GetClubQueryVariables>(
    GetClubDocument,
    options
  );
}
export type GetClubQueryHookResult = ReturnType<typeof useGetClubQuery>;
export type GetClubLazyQueryHookResult = ReturnType<typeof useGetClubLazyQuery>;
export type GetClubQueryResult = Apollo.QueryResult<
  GetClubQuery,
  GetClubQueryVariables
>;
export const GetClubIpAuditAttributesDocument = gql`
  query GetClubIpAuditAttributes {
    clubIpAuditAttributes
  }
`;

/**
 * __useGetClubIpAuditAttributesQuery__
 *
 * To run a query within a React component, call `useGetClubIpAuditAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubIpAuditAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubIpAuditAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClubIpAuditAttributesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClubIpAuditAttributesQuery,
    GetClubIpAuditAttributesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClubIpAuditAttributesQuery,
    GetClubIpAuditAttributesQueryVariables
  >(GetClubIpAuditAttributesDocument, options);
}
export function useGetClubIpAuditAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubIpAuditAttributesQuery,
    GetClubIpAuditAttributesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClubIpAuditAttributesQuery,
    GetClubIpAuditAttributesQueryVariables
  >(GetClubIpAuditAttributesDocument, options);
}
export type GetClubIpAuditAttributesQueryHookResult = ReturnType<
  typeof useGetClubIpAuditAttributesQuery
>;
export type GetClubIpAuditAttributesLazyQueryHookResult = ReturnType<
  typeof useGetClubIpAuditAttributesLazyQuery
>;
export type GetClubIpAuditAttributesQueryResult = Apollo.QueryResult<
  GetClubIpAuditAttributesQuery,
  GetClubIpAuditAttributesQueryVariables
>;
export const CreateFavoriteDocument = gql`
  mutation CreateFavorite($request: CreateFavoriteRequest!) {
    createFavorite(request: $request) {
      playerId
    }
  }
`;
export type CreateFavoriteMutationFn = Apollo.MutationFunction<
  CreateFavoriteMutation,
  CreateFavoriteMutationVariables
>;

/**
 * __useCreateFavoriteMutation__
 *
 * To run a mutation, you first call `useCreateFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoriteMutation, { data, loading, error }] = useCreateFavoriteMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFavoriteMutation,
    CreateFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFavoriteMutation,
    CreateFavoriteMutationVariables
  >(CreateFavoriteDocument, options);
}
export type CreateFavoriteMutationHookResult = ReturnType<
  typeof useCreateFavoriteMutation
>;
export type CreateFavoriteMutationResult =
  Apollo.MutationResult<CreateFavoriteMutation>;
export type CreateFavoriteMutationOptions = Apollo.BaseMutationOptions<
  CreateFavoriteMutation,
  CreateFavoriteMutationVariables
>;
export const DeleteFavoriteDocument = gql`
  mutation DeleteFavorite($request: DeleteFavoriteRequest!) {
    deleteFavorite(request: $request) {
      playerId
    }
  }
`;
export type DeleteFavoriteMutationFn = Apollo.MutationFunction<
  DeleteFavoriteMutation,
  DeleteFavoriteMutationVariables
>;

/**
 * __useDeleteFavoriteMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteMutation, { data, loading, error }] = useDeleteFavoriteMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDeleteFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFavoriteMutation,
    DeleteFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFavoriteMutation,
    DeleteFavoriteMutationVariables
  >(DeleteFavoriteDocument, options);
}
export type DeleteFavoriteMutationHookResult = ReturnType<
  typeof useDeleteFavoriteMutation
>;
export type DeleteFavoriteMutationResult =
  Apollo.MutationResult<DeleteFavoriteMutation>;
export type DeleteFavoriteMutationOptions = Apollo.BaseMutationOptions<
  DeleteFavoriteMutation,
  DeleteFavoriteMutationVariables
>;
export const CreateDraftDocument = gql`
  mutation CreateDraft($request: CreateDraftScenarioRequest!) {
    draftScenarioCreate(request: $request) {
      id
      season
      draftType
    }
  }
`;
export type CreateDraftMutationFn = Apollo.MutationFunction<
  CreateDraftMutation,
  CreateDraftMutationVariables
>;

/**
 * __useCreateDraftMutation__
 *
 * To run a mutation, you first call `useCreateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftMutation, { data, loading, error }] = useCreateDraftMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDraftMutation,
    CreateDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDraftMutation, CreateDraftMutationVariables>(
    CreateDraftDocument,
    options
  );
}
export type CreateDraftMutationHookResult = ReturnType<
  typeof useCreateDraftMutation
>;
export type CreateDraftMutationResult =
  Apollo.MutationResult<CreateDraftMutation>;
export type CreateDraftMutationOptions = Apollo.BaseMutationOptions<
  CreateDraftMutation,
  CreateDraftMutationVariables
>;
export const DraftScenarioCreateDocument = gql`
  mutation DraftScenarioCreate($request: CreateDraftScenarioRequest!) {
    draftScenarioCreate(request: $request) {
      id
      version
      lastUpdated
      season
      draftType
    }
  }
`;
export type DraftScenarioCreateMutationFn = Apollo.MutationFunction<
  DraftScenarioCreateMutation,
  DraftScenarioCreateMutationVariables
>;

/**
 * __useDraftScenarioCreateMutation__
 *
 * To run a mutation, you first call `useDraftScenarioCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftScenarioCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftScenarioCreateMutation, { data, loading, error }] = useDraftScenarioCreateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDraftScenarioCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DraftScenarioCreateMutation,
    DraftScenarioCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DraftScenarioCreateMutation,
    DraftScenarioCreateMutationVariables
  >(DraftScenarioCreateDocument, options);
}
export type DraftScenarioCreateMutationHookResult = ReturnType<
  typeof useDraftScenarioCreateMutation
>;
export type DraftScenarioCreateMutationResult =
  Apollo.MutationResult<DraftScenarioCreateMutation>;
export type DraftScenarioCreateMutationOptions = Apollo.BaseMutationOptions<
  DraftScenarioCreateMutation,
  DraftScenarioCreateMutationVariables
>;
export const DraftScenarioTradePicksDocument = gql`
  mutation DraftScenarioTradePicks($request: ExecuteTradeRequest!) {
    draftScenarioExecuteTrade(request: $request) {
      id
      version
      executedTrades {
        ...tradeFields
      }
      pinnedTrades {
        ...tradeFields
      }
    }
  }
  ${TradeFieldsFragmentDoc}
`;
export type DraftScenarioTradePicksMutationFn = Apollo.MutationFunction<
  DraftScenarioTradePicksMutation,
  DraftScenarioTradePicksMutationVariables
>;

/**
 * __useDraftScenarioTradePicksMutation__
 *
 * To run a mutation, you first call `useDraftScenarioTradePicksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftScenarioTradePicksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftScenarioTradePicksMutation, { data, loading, error }] = useDraftScenarioTradePicksMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDraftScenarioTradePicksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DraftScenarioTradePicksMutation,
    DraftScenarioTradePicksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DraftScenarioTradePicksMutation,
    DraftScenarioTradePicksMutationVariables
  >(DraftScenarioTradePicksDocument, options);
}
export type DraftScenarioTradePicksMutationHookResult = ReturnType<
  typeof useDraftScenarioTradePicksMutation
>;
export type DraftScenarioTradePicksMutationResult =
  Apollo.MutationResult<DraftScenarioTradePicksMutation>;
export type DraftScenarioTradePicksMutationOptions = Apollo.BaseMutationOptions<
  DraftScenarioTradePicksMutation,
  DraftScenarioTradePicksMutationVariables
>;
export const PinTradeDocument = gql`
  mutation PinTrade($request: PinTradeRequest!) {
    draftScenarioPinTrade(request: $request) {
      id
      version
      pinnedTrades {
        ...tradeFields
      }
    }
  }
  ${TradeFieldsFragmentDoc}
`;
export type PinTradeMutationFn = Apollo.MutationFunction<
  PinTradeMutation,
  PinTradeMutationVariables
>;

/**
 * __usePinTradeMutation__
 *
 * To run a mutation, you first call `usePinTradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinTradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinTradeMutation, { data, loading, error }] = usePinTradeMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function usePinTradeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PinTradeMutation,
    PinTradeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PinTradeMutation, PinTradeMutationVariables>(
    PinTradeDocument,
    options
  );
}
export type PinTradeMutationHookResult = ReturnType<typeof usePinTradeMutation>;
export type PinTradeMutationResult = Apollo.MutationResult<PinTradeMutation>;
export type PinTradeMutationOptions = Apollo.BaseMutationOptions<
  PinTradeMutation,
  PinTradeMutationVariables
>;
export const UnpinTradeDocument = gql`
  mutation UnpinTrade($request: UnpinTradeRequest!) {
    draftScenarioUnpinTrade(request: $request) {
      id
      version
      pinnedTrades {
        ...tradeFields
      }
    }
  }
  ${TradeFieldsFragmentDoc}
`;
export type UnpinTradeMutationFn = Apollo.MutationFunction<
  UnpinTradeMutation,
  UnpinTradeMutationVariables
>;

/**
 * __useUnpinTradeMutation__
 *
 * To run a mutation, you first call `useUnpinTradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpinTradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpinTradeMutation, { data, loading, error }] = useUnpinTradeMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUnpinTradeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnpinTradeMutation,
    UnpinTradeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnpinTradeMutation, UnpinTradeMutationVariables>(
    UnpinTradeDocument,
    options
  );
}
export type UnpinTradeMutationHookResult = ReturnType<
  typeof useUnpinTradeMutation
>;
export type UnpinTradeMutationResult =
  Apollo.MutationResult<UnpinTradeMutation>;
export type UnpinTradeMutationOptions = Apollo.BaseMutationOptions<
  UnpinTradeMutation,
  UnpinTradeMutationVariables
>;
export const DraftScenarioDraftPlayersDocument = gql`
  mutation DraftScenarioDraftPlayers($request: DraftPlayersRequest!) {
    draftScenarioDraftPlayers(request: $request) {
      id
      version
      lastUpdated
      modelRunStatus
      activePick {
        ...currentSeasonDraftAssetFields
      }
      clubDraftAssets {
        ...draftAssetFields
      }
      draftOrder {
        ...currentSeasonDraftAssetFields
      }
      draftedPlayers {
        player {
          id
        }
        draftPick {
          ...currentSeasonDraftAssetFields
        }
        player {
          ...DraftPlayer
        }
      }
      recommendedWorkspacePlayers {
        clubGradingPositionShortName
        workspacePlayer {
          player {
            ...clubDraftablePlayerAtPickFields
          }
          recommendedComboPlayers {
            ...comboPlayerFields
          }
        }
      }
    }
  }
  ${CurrentSeasonDraftAssetFieldsFragmentDoc}
  ${DraftAssetFieldsFragmentDoc}
  ${DraftPlayerFragmentDoc}
  ${ClubDraftablePlayerAtPickFieldsFragmentDoc}
  ${ComboPlayerFieldsFragmentDoc}
`;
export type DraftScenarioDraftPlayersMutationFn = Apollo.MutationFunction<
  DraftScenarioDraftPlayersMutation,
  DraftScenarioDraftPlayersMutationVariables
>;

/**
 * __useDraftScenarioDraftPlayersMutation__
 *
 * To run a mutation, you first call `useDraftScenarioDraftPlayersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftScenarioDraftPlayersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftScenarioDraftPlayersMutation, { data, loading, error }] = useDraftScenarioDraftPlayersMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDraftScenarioDraftPlayersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DraftScenarioDraftPlayersMutation,
    DraftScenarioDraftPlayersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DraftScenarioDraftPlayersMutation,
    DraftScenarioDraftPlayersMutationVariables
  >(DraftScenarioDraftPlayersDocument, options);
}
export type DraftScenarioDraftPlayersMutationHookResult = ReturnType<
  typeof useDraftScenarioDraftPlayersMutation
>;
export type DraftScenarioDraftPlayersMutationResult =
  Apollo.MutationResult<DraftScenarioDraftPlayersMutation>;
export type DraftScenarioDraftPlayersMutationOptions =
  Apollo.BaseMutationOptions<
    DraftScenarioDraftPlayersMutation,
    DraftScenarioDraftPlayersMutationVariables
  >;
export const SimulateToPickDocument = gql`
  mutation SimulateToPick($request: SimulateToPickRequest!) {
    draftScenarioSimulateToPick(request: $request) {
      id
      lastUpdated
      version
      modelRunStatus
      draftedPlayers {
        player {
          ...DraftPlayer
        }
        draftPick {
          ...currentSeasonDraftAssetFields
        }
      }
      recommendedWorkspacePlayers {
        clubGradingPositionShortName
        workspacePlayer {
          player {
            ...clubDraftablePlayerAtPickFields
          }
          recommendedComboPlayers {
            ...comboPlayerFields
          }
        }
      }
      draftOrder {
        ...currentSeasonDraftAssetFields
      }
    }
  }
  ${DraftPlayerFragmentDoc}
  ${CurrentSeasonDraftAssetFieldsFragmentDoc}
  ${ClubDraftablePlayerAtPickFieldsFragmentDoc}
  ${ComboPlayerFieldsFragmentDoc}
`;
export type SimulateToPickMutationFn = Apollo.MutationFunction<
  SimulateToPickMutation,
  SimulateToPickMutationVariables
>;

/**
 * __useSimulateToPickMutation__
 *
 * To run a mutation, you first call `useSimulateToPickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulateToPickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulateToPickMutation, { data, loading, error }] = useSimulateToPickMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSimulateToPickMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SimulateToPickMutation,
    SimulateToPickMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SimulateToPickMutation,
    SimulateToPickMutationVariables
  >(SimulateToPickDocument, options);
}
export type SimulateToPickMutationHookResult = ReturnType<
  typeof useSimulateToPickMutation
>;
export type SimulateToPickMutationResult =
  Apollo.MutationResult<SimulateToPickMutation>;
export type SimulateToPickMutationOptions = Apollo.BaseMutationOptions<
  SimulateToPickMutation,
  SimulateToPickMutationVariables
>;
export const RewindToPickDocument = gql`
  mutation RewindToPick($request: RewindToPickRequest!) {
    draftScenarioRewindToPick(request: $request) {
      id
      version
      lastUpdated
      modelRunStatus
      draftedPlayers {
        player {
          id
        }
        draftPick {
          ...currentSeasonDraftAssetFields
        }
      }
      draftOrder {
        ...currentSeasonDraftAssetFields
      }
      recommendedWorkspacePlayers {
        clubGradingPositionShortName
        workspacePlayer {
          player {
            ...clubDraftablePlayerAtPickFields
          }
          recommendedComboPlayers {
            ...comboPlayerFields
          }
        }
      }
    }
  }
  ${CurrentSeasonDraftAssetFieldsFragmentDoc}
  ${ClubDraftablePlayerAtPickFieldsFragmentDoc}
  ${ComboPlayerFieldsFragmentDoc}
`;
export type RewindToPickMutationFn = Apollo.MutationFunction<
  RewindToPickMutation,
  RewindToPickMutationVariables
>;

/**
 * __useRewindToPickMutation__
 *
 * To run a mutation, you first call `useRewindToPickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRewindToPickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rewindToPickMutation, { data, loading, error }] = useRewindToPickMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRewindToPickMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RewindToPickMutation,
    RewindToPickMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RewindToPickMutation,
    RewindToPickMutationVariables
  >(RewindToPickDocument, options);
}
export type RewindToPickMutationHookResult = ReturnType<
  typeof useRewindToPickMutation
>;
export type RewindToPickMutationResult =
  Apollo.MutationResult<RewindToPickMutation>;
export type RewindToPickMutationOptions = Apollo.BaseMutationOptions<
  RewindToPickMutation,
  RewindToPickMutationVariables
>;
export const UndoLastActionDocument = gql`
  mutation UndoLastAction($request: UndoLastActionRequest!) {
    draftScenarioUndoLastAction(request: $request) {
      id
      version
      lastUpdated
      modelRunStatus
      draftedPlayers {
        player {
          id
        }
        draftPick {
          ...currentSeasonDraftAssetFields
        }
      }
      draftOrder {
        ...currentSeasonDraftAssetFields
      }
      recommendedWorkspacePlayers {
        clubGradingPositionShortName
        workspacePlayer {
          player {
            ...clubDraftablePlayerAtPickFields
          }
          recommendedComboPlayers {
            ...comboPlayerFields
          }
        }
      }
    }
  }
  ${CurrentSeasonDraftAssetFieldsFragmentDoc}
  ${ClubDraftablePlayerAtPickFieldsFragmentDoc}
  ${ComboPlayerFieldsFragmentDoc}
`;
export type UndoLastActionMutationFn = Apollo.MutationFunction<
  UndoLastActionMutation,
  UndoLastActionMutationVariables
>;

/**
 * __useUndoLastActionMutation__
 *
 * To run a mutation, you first call `useUndoLastActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoLastActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoLastActionMutation, { data, loading, error }] = useUndoLastActionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUndoLastActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UndoLastActionMutation,
    UndoLastActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UndoLastActionMutation,
    UndoLastActionMutationVariables
  >(UndoLastActionDocument, options);
}
export type UndoLastActionMutationHookResult = ReturnType<
  typeof useUndoLastActionMutation
>;
export type UndoLastActionMutationResult =
  Apollo.MutationResult<UndoLastActionMutation>;
export type UndoLastActionMutationOptions = Apollo.BaseMutationOptions<
  UndoLastActionMutation,
  UndoLastActionMutationVariables
>;
export const RenameDraftDocument = gql`
  mutation RenameDraft($request: RenameDraftScenarioRequest!) {
    draftScenarioRename(request: $request) {
      id
      name
      version
      lastUpdated
    }
  }
`;
export type RenameDraftMutationFn = Apollo.MutationFunction<
  RenameDraftMutation,
  RenameDraftMutationVariables
>;

/**
 * __useRenameDraftMutation__
 *
 * To run a mutation, you first call `useRenameDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameDraftMutation, { data, loading, error }] = useRenameDraftMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRenameDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameDraftMutation,
    RenameDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenameDraftMutation, RenameDraftMutationVariables>(
    RenameDraftDocument,
    options
  );
}
export type RenameDraftMutationHookResult = ReturnType<
  typeof useRenameDraftMutation
>;
export type RenameDraftMutationResult =
  Apollo.MutationResult<RenameDraftMutation>;
export type RenameDraftMutationOptions = Apollo.BaseMutationOptions<
  RenameDraftMutation,
  RenameDraftMutationVariables
>;
export const DiscardDraftDocument = gql`
  mutation DiscardDraft($request: DiscardDraftScenarioRequest!) {
    draftScenarioDiscard(request: $request) {
      id
      version
      lastUpdated
      status
    }
  }
`;
export type DiscardDraftMutationFn = Apollo.MutationFunction<
  DiscardDraftMutation,
  DiscardDraftMutationVariables
>;

/**
 * __useDiscardDraftMutation__
 *
 * To run a mutation, you first call `useDiscardDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardDraftMutation, { data, loading, error }] = useDiscardDraftMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDiscardDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DiscardDraftMutation,
    DiscardDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DiscardDraftMutation,
    DiscardDraftMutationVariables
  >(DiscardDraftDocument, options);
}
export type DiscardDraftMutationHookResult = ReturnType<
  typeof useDiscardDraftMutation
>;
export type DiscardDraftMutationResult =
  Apollo.MutationResult<DiscardDraftMutation>;
export type DiscardDraftMutationOptions = Apollo.BaseMutationOptions<
  DiscardDraftMutation,
  DiscardDraftMutationVariables
>;
export const SetWorkspacePlayersRecommendedDocument = gql`
  mutation SetWorkspacePlayersRecommended(
    $request: SetWorkspacePlayersRecommendedRequest!
  ) {
    draftScenarioSetWorkspacePlayersRecommended(request: $request) {
      id
      version
      lastUpdated
      currentWorkspacePlayers {
        slot
        workspacePlayer {
          comboPlayerModelRequestId
          comboPlayerModelRunStatus
          player {
            ...clubDraftablePlayerAtPickFields
          }
          recommendedComboPlayers {
            ...comboPlayerFields
          }
        }
      }
    }
  }
  ${ClubDraftablePlayerAtPickFieldsFragmentDoc}
  ${ComboPlayerFieldsFragmentDoc}
`;
export type SetWorkspacePlayersRecommendedMutationFn = Apollo.MutationFunction<
  SetWorkspacePlayersRecommendedMutation,
  SetWorkspacePlayersRecommendedMutationVariables
>;

/**
 * __useSetWorkspacePlayersRecommendedMutation__
 *
 * To run a mutation, you first call `useSetWorkspacePlayersRecommendedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkspacePlayersRecommendedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkspacePlayersRecommendedMutation, { data, loading, error }] = useSetWorkspacePlayersRecommendedMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSetWorkspacePlayersRecommendedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetWorkspacePlayersRecommendedMutation,
    SetWorkspacePlayersRecommendedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetWorkspacePlayersRecommendedMutation,
    SetWorkspacePlayersRecommendedMutationVariables
  >(SetWorkspacePlayersRecommendedDocument, options);
}
export type SetWorkspacePlayersRecommendedMutationHookResult = ReturnType<
  typeof useSetWorkspacePlayersRecommendedMutation
>;
export type SetWorkspacePlayersRecommendedMutationResult =
  Apollo.MutationResult<SetWorkspacePlayersRecommendedMutation>;
export type SetWorkspacePlayersRecommendedMutationOptions =
  Apollo.BaseMutationOptions<
    SetWorkspacePlayersRecommendedMutation,
    SetWorkspacePlayersRecommendedMutationVariables
  >;
export const SetWorkspacePlayersCustomDocument = gql`
  mutation SetWorkspacePlayersCustom(
    $request: SetWorkspacePlayersCustomRequest!
  ) {
    draftScenarioSetWorkspacePlayersCustom(request: $request) {
      id
      version
      lastUpdated
      currentWorkspacePlayers {
        slot
        workspacePlayer {
          comboPlayerModelRequestId
          comboPlayerModelRunStatus
          player {
            ...clubDraftablePlayerAtPickFields
          }
          recommendedComboPlayers {
            ...comboPlayerFields
          }
        }
      }
    }
  }
  ${ClubDraftablePlayerAtPickFieldsFragmentDoc}
  ${ComboPlayerFieldsFragmentDoc}
`;
export type SetWorkspacePlayersCustomMutationFn = Apollo.MutationFunction<
  SetWorkspacePlayersCustomMutation,
  SetWorkspacePlayersCustomMutationVariables
>;

/**
 * __useSetWorkspacePlayersCustomMutation__
 *
 * To run a mutation, you first call `useSetWorkspacePlayersCustomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkspacePlayersCustomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkspacePlayersCustomMutation, { data, loading, error }] = useSetWorkspacePlayersCustomMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSetWorkspacePlayersCustomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetWorkspacePlayersCustomMutation,
    SetWorkspacePlayersCustomMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetWorkspacePlayersCustomMutation,
    SetWorkspacePlayersCustomMutationVariables
  >(SetWorkspacePlayersCustomDocument, options);
}
export type SetWorkspacePlayersCustomMutationHookResult = ReturnType<
  typeof useSetWorkspacePlayersCustomMutation
>;
export type SetWorkspacePlayersCustomMutationResult =
  Apollo.MutationResult<SetWorkspacePlayersCustomMutation>;
export type SetWorkspacePlayersCustomMutationOptions =
  Apollo.BaseMutationOptions<
    SetWorkspacePlayersCustomMutation,
    SetWorkspacePlayersCustomMutationVariables
  >;
export const AddLocalDraftScenarioAssumptionDoNotDraftPlayerDocument = gql`
  mutation AddLocalDraftScenarioAssumptionDoNotDraftPlayer(
    $request: AssumeDoNotDraftPlayerRequest!
  ) {
    addLocalDraftScenarioAssumptionDoNotDraftPlayer(request: $request) {
      id
      version
      lastUpdated
      modelRunStatus
      assumptions {
        constraint
        description
        id
        isValid
        name
        playerId
        subject
      }
    }
  }
`;
export type AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutationFn =
  Apollo.MutationFunction<
    AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutation,
    AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutationVariables
  >;

/**
 * __useAddLocalDraftScenarioAssumptionDoNotDraftPlayerMutation__
 *
 * To run a mutation, you first call `useAddLocalDraftScenarioAssumptionDoNotDraftPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLocalDraftScenarioAssumptionDoNotDraftPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLocalDraftScenarioAssumptionDoNotDraftPlayerMutation, { data, loading, error }] = useAddLocalDraftScenarioAssumptionDoNotDraftPlayerMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddLocalDraftScenarioAssumptionDoNotDraftPlayerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutation,
    AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutation,
    AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutationVariables
  >(AddLocalDraftScenarioAssumptionDoNotDraftPlayerDocument, options);
}
export type AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutationHookResult =
  ReturnType<typeof useAddLocalDraftScenarioAssumptionDoNotDraftPlayerMutation>;
export type AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutationResult =
  Apollo.MutationResult<AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutation>;
export type AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutationOptions =
  Apollo.BaseMutationOptions<
    AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutation,
    AddLocalDraftScenarioAssumptionDoNotDraftPlayerMutationVariables
  >;
export const RemoveLocalDraftScenarioAssumptionDocument = gql`
  mutation RemoveLocalDraftScenarioAssumption(
    $request: RemoveAssumptionRequest!
  ) {
    removeLocalDraftScenarioAssumption(request: $request) {
      id
      version
      lastUpdated
      modelRunStatus
      assumptions {
        constraint
        description
        id
        isValid
        name
        playerId
        subject
      }
    }
  }
`;
export type RemoveLocalDraftScenarioAssumptionMutationFn =
  Apollo.MutationFunction<
    RemoveLocalDraftScenarioAssumptionMutation,
    RemoveLocalDraftScenarioAssumptionMutationVariables
  >;

/**
 * __useRemoveLocalDraftScenarioAssumptionMutation__
 *
 * To run a mutation, you first call `useRemoveLocalDraftScenarioAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLocalDraftScenarioAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLocalDraftScenarioAssumptionMutation, { data, loading, error }] = useRemoveLocalDraftScenarioAssumptionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRemoveLocalDraftScenarioAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveLocalDraftScenarioAssumptionMutation,
    RemoveLocalDraftScenarioAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveLocalDraftScenarioAssumptionMutation,
    RemoveLocalDraftScenarioAssumptionMutationVariables
  >(RemoveLocalDraftScenarioAssumptionDocument, options);
}
export type RemoveLocalDraftScenarioAssumptionMutationHookResult = ReturnType<
  typeof useRemoveLocalDraftScenarioAssumptionMutation
>;
export type RemoveLocalDraftScenarioAssumptionMutationResult =
  Apollo.MutationResult<RemoveLocalDraftScenarioAssumptionMutation>;
export type RemoveLocalDraftScenarioAssumptionMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveLocalDraftScenarioAssumptionMutation,
    RemoveLocalDraftScenarioAssumptionMutationVariables
  >;
export const AssignPickToClubDocument = gql`
  mutation AssignPickToClub($request: AssignPickToClubRequest!) {
    assignPickToClub(request: $request) {
      id
      draftOrder {
        club {
          id
          clubName
        }
        overall
      }
    }
  }
`;
export type AssignPickToClubMutationFn = Apollo.MutationFunction<
  AssignPickToClubMutation,
  AssignPickToClubMutationVariables
>;

/**
 * __useAssignPickToClubMutation__
 *
 * To run a mutation, you first call `useAssignPickToClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPickToClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPickToClubMutation, { data, loading, error }] = useAssignPickToClubMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssignPickToClubMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignPickToClubMutation,
    AssignPickToClubMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignPickToClubMutation,
    AssignPickToClubMutationVariables
  >(AssignPickToClubDocument, options);
}
export type AssignPickToClubMutationHookResult = ReturnType<
  typeof useAssignPickToClubMutation
>;
export type AssignPickToClubMutationResult =
  Apollo.MutationResult<AssignPickToClubMutation>;
export type AssignPickToClubMutationOptions = Apollo.BaseMutationOptions<
  AssignPickToClubMutation,
  AssignPickToClubMutationVariables
>;
export const GetDraftDocument = gql`
  query getDraft($id: UUID!) {
    draftScenarioById(id: $id) {
      id
      draftType
      createdAt
      lastUpdated
    }
  }
`;

/**
 * __useGetDraftQuery__
 *
 * To run a query within a React component, call `useGetDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDraftQuery(
  baseOptions: Apollo.QueryHookOptions<GetDraftQuery, GetDraftQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDraftQuery, GetDraftQueryVariables>(
    GetDraftDocument,
    options
  );
}
export function useGetDraftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftQuery,
    GetDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDraftQuery, GetDraftQueryVariables>(
    GetDraftDocument,
    options
  );
}
export type GetDraftQueryHookResult = ReturnType<typeof useGetDraftQuery>;
export type GetDraftLazyQueryHookResult = ReturnType<
  typeof useGetDraftLazyQuery
>;
export type GetDraftQueryResult = Apollo.QueryResult<
  GetDraftQuery,
  GetDraftQueryVariables
>;
export const GetMyDraftsDocument = gql`
  query getMyDrafts {
    myDraftScenarios {
      id
      draftType
      season
      status
      name
      createdAt
      createdBy
      lastUpdated
      currentRosterValue {
        ...valueFields
      }
      startingRosterValue {
        ...valueFields
      }
      netRosterValueChangeDollars
    }
  }
  ${ValueFieldsFragmentDoc}
`;

/**
 * __useGetMyDraftsQuery__
 *
 * To run a query within a React component, call `useGetMyDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDraftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyDraftsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyDraftsQuery,
    GetMyDraftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyDraftsQuery, GetMyDraftsQueryVariables>(
    GetMyDraftsDocument,
    options
  );
}
export function useGetMyDraftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyDraftsQuery,
    GetMyDraftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyDraftsQuery, GetMyDraftsQueryVariables>(
    GetMyDraftsDocument,
    options
  );
}
export type GetMyDraftsQueryHookResult = ReturnType<typeof useGetMyDraftsQuery>;
export type GetMyDraftsLazyQueryHookResult = ReturnType<
  typeof useGetMyDraftsLazyQuery
>;
export type GetMyDraftsQueryResult = Apollo.QueryResult<
  GetMyDraftsQuery,
  GetMyDraftsQueryVariables
>;
export const GetFilteredDraftsDocument = gql`
  query getFilteredDrafts($createdBy: [UUID!]) {
    allDraftScenarios(where: { createdBy: { in: $createdBy } }) {
      id
      draftType
      season
      status
      name
      createdAt
      createdBy
    }
  }
`;

/**
 * __useGetFilteredDraftsQuery__
 *
 * To run a query within a React component, call `useGetFilteredDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredDraftsQuery({
 *   variables: {
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useGetFilteredDraftsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFilteredDraftsQuery,
    GetFilteredDraftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFilteredDraftsQuery,
    GetFilteredDraftsQueryVariables
  >(GetFilteredDraftsDocument, options);
}
export function useGetFilteredDraftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFilteredDraftsQuery,
    GetFilteredDraftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFilteredDraftsQuery,
    GetFilteredDraftsQueryVariables
  >(GetFilteredDraftsDocument, options);
}
export type GetFilteredDraftsQueryHookResult = ReturnType<
  typeof useGetFilteredDraftsQuery
>;
export type GetFilteredDraftsLazyQueryHookResult = ReturnType<
  typeof useGetFilteredDraftsLazyQuery
>;
export type GetFilteredDraftsQueryResult = Apollo.QueryResult<
  GetFilteredDraftsQuery,
  GetFilteredDraftsQueryVariables
>;
export const GetDraftScenarioByIdDocument = gql`
  query GetDraftScenarioById($id: UUID!) {
    draftScenarioById(id: $id) {
      ...draftScenarioFields
    }
  }
  ${DraftScenarioFieldsFragmentDoc}
`;

/**
 * __useGetDraftScenarioByIdQuery__
 *
 * To run a query within a React component, call `useGetDraftScenarioByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftScenarioByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftScenarioByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDraftScenarioByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDraftScenarioByIdQuery,
    GetDraftScenarioByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDraftScenarioByIdQuery,
    GetDraftScenarioByIdQueryVariables
  >(GetDraftScenarioByIdDocument, options);
}
export function useGetDraftScenarioByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftScenarioByIdQuery,
    GetDraftScenarioByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDraftScenarioByIdQuery,
    GetDraftScenarioByIdQueryVariables
  >(GetDraftScenarioByIdDocument, options);
}
export type GetDraftScenarioByIdQueryHookResult = ReturnType<
  typeof useGetDraftScenarioByIdQuery
>;
export type GetDraftScenarioByIdLazyQueryHookResult = ReturnType<
  typeof useGetDraftScenarioByIdLazyQuery
>;
export type GetDraftScenarioByIdQueryResult = Apollo.QueryResult<
  GetDraftScenarioByIdQuery,
  GetDraftScenarioByIdQueryVariables
>;
export const GetTradeChartValuesDocument = gql`
  query GetTradeChartValues($request: GetTradeChartValuesRequest!) {
    tradeChartValues(request: $request) {
      ...tradeChartValuesFields
    }
  }
  ${TradeChartValuesFieldsFragmentDoc}
`;

/**
 * __useGetTradeChartValuesQuery__
 *
 * To run a query within a React component, call `useGetTradeChartValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradeChartValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradeChartValuesQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetTradeChartValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTradeChartValuesQuery,
    GetTradeChartValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTradeChartValuesQuery,
    GetTradeChartValuesQueryVariables
  >(GetTradeChartValuesDocument, options);
}
export function useGetTradeChartValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTradeChartValuesQuery,
    GetTradeChartValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTradeChartValuesQuery,
    GetTradeChartValuesQueryVariables
  >(GetTradeChartValuesDocument, options);
}
export type GetTradeChartValuesQueryHookResult = ReturnType<
  typeof useGetTradeChartValuesQuery
>;
export type GetTradeChartValuesLazyQueryHookResult = ReturnType<
  typeof useGetTradeChartValuesLazyQuery
>;
export type GetTradeChartValuesQueryResult = Apollo.QueryResult<
  GetTradeChartValuesQuery,
  GetTradeChartValuesQueryVariables
>;
export const GetDraftDashboardPlayerListWithFiltersDocument = gql`
  query GetDraftDashboardPlayerListWithFilters(
    $after: String
    $before: String
    $last: Int
    $first: Int
    $searchText: String
    $positionFilters: [String!]
    $draftYear: Int!
    $showFavorites: Boolean!
    $sortCriteria: DraftPlayerSortCriteriaEnum!
    $includePlayers: [UUID!]
    $excludePlayers: [UUID!]
  ) {
    draftDashboardPlayerListWithFilters(
      after: $after
      before: $before
      last: $last
      first: $first
      searchText: $searchText
      positionFilters: $positionFilters
      draftYear: $draftYear
      showFavorites: $showFavorites
      sortCriteria: $sortCriteria
      includePlayers: $includePlayers
      excludePlayers: $excludePlayers
    ) {
      edges {
        node {
          ...DraftPlayer
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${DraftPlayerFragmentDoc}
`;

/**
 * __useGetDraftDashboardPlayerListWithFiltersQuery__
 *
 * To run a query within a React component, call `useGetDraftDashboardPlayerListWithFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftDashboardPlayerListWithFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftDashboardPlayerListWithFiltersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      searchText: // value for 'searchText'
 *      positionFilters: // value for 'positionFilters'
 *      draftYear: // value for 'draftYear'
 *      showFavorites: // value for 'showFavorites'
 *      sortCriteria: // value for 'sortCriteria'
 *      includePlayers: // value for 'includePlayers'
 *      excludePlayers: // value for 'excludePlayers'
 *   },
 * });
 */
export function useGetDraftDashboardPlayerListWithFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDraftDashboardPlayerListWithFiltersQuery,
    GetDraftDashboardPlayerListWithFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDraftDashboardPlayerListWithFiltersQuery,
    GetDraftDashboardPlayerListWithFiltersQueryVariables
  >(GetDraftDashboardPlayerListWithFiltersDocument, options);
}
export function useGetDraftDashboardPlayerListWithFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftDashboardPlayerListWithFiltersQuery,
    GetDraftDashboardPlayerListWithFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDraftDashboardPlayerListWithFiltersQuery,
    GetDraftDashboardPlayerListWithFiltersQueryVariables
  >(GetDraftDashboardPlayerListWithFiltersDocument, options);
}
export type GetDraftDashboardPlayerListWithFiltersQueryHookResult = ReturnType<
  typeof useGetDraftDashboardPlayerListWithFiltersQuery
>;
export type GetDraftDashboardPlayerListWithFiltersLazyQueryHookResult =
  ReturnType<typeof useGetDraftDashboardPlayerListWithFiltersLazyQuery>;
export type GetDraftDashboardPlayerListWithFiltersQueryResult =
  Apollo.QueryResult<
    GetDraftDashboardPlayerListWithFiltersQuery,
    GetDraftDashboardPlayerListWithFiltersQueryVariables
  >;
export const GetDraftScenarioResultsDocument = gql`
  query GetDraftScenarioResults($request: ExportDraftScenarioRequest!) {
    draftScenarioResults(request: $request) {
      picks {
        overallPickNumber
        clubAbbreviation
        playerFirstName
        playerLastName
        position
        collegeAbbreviation
        gsisPlayerId
        marvalPlayerId
        pffPlayerId
        clubGradeDollars
        clubGradeRaw
        sageGradeDollars
        sumerScoutGradeDollars
        sumerScoutGradeRaw
        performanceValue
      }
    }
  }
`;

/**
 * __useGetDraftScenarioResultsQuery__
 *
 * To run a query within a React component, call `useGetDraftScenarioResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftScenarioResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftScenarioResultsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetDraftScenarioResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDraftScenarioResultsQuery,
    GetDraftScenarioResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDraftScenarioResultsQuery,
    GetDraftScenarioResultsQueryVariables
  >(GetDraftScenarioResultsDocument, options);
}
export function useGetDraftScenarioResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftScenarioResultsQuery,
    GetDraftScenarioResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDraftScenarioResultsQuery,
    GetDraftScenarioResultsQueryVariables
  >(GetDraftScenarioResultsDocument, options);
}
export type GetDraftScenarioResultsQueryHookResult = ReturnType<
  typeof useGetDraftScenarioResultsQuery
>;
export type GetDraftScenarioResultsLazyQueryHookResult = ReturnType<
  typeof useGetDraftScenarioResultsLazyQuery
>;
export type GetDraftScenarioResultsQueryResult = Apollo.QueryResult<
  GetDraftScenarioResultsQuery,
  GetDraftScenarioResultsQueryVariables
>;
export const GetActiveDraftsDocument = gql`
  query GetActiveDrafts {
    myDraftScenarios(where: { status: { eq: ACTIVE } }) {
      id
      draftType
    }
  }
`;

/**
 * __useGetActiveDraftsQuery__
 *
 * To run a query within a React component, call `useGetActiveDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveDraftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveDraftsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveDraftsQuery,
    GetActiveDraftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActiveDraftsQuery, GetActiveDraftsQueryVariables>(
    GetActiveDraftsDocument,
    options
  );
}
export function useGetActiveDraftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveDraftsQuery,
    GetActiveDraftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveDraftsQuery,
    GetActiveDraftsQueryVariables
  >(GetActiveDraftsDocument, options);
}
export type GetActiveDraftsQueryHookResult = ReturnType<
  typeof useGetActiveDraftsQuery
>;
export type GetActiveDraftsLazyQueryHookResult = ReturnType<
  typeof useGetActiveDraftsLazyQuery
>;
export type GetActiveDraftsQueryResult = Apollo.QueryResult<
  GetActiveDraftsQuery,
  GetActiveDraftsQueryVariables
>;
export const GetMyDraftByIdDocument = gql`
  query GetMyDraftById($draftId: UUID) {
    myDraftScenarios(where: { id: { eq: $draftId } }) {
      id
      draftType
      season
      status
      name
      createdAt
      createdBy
    }
  }
`;

/**
 * __useGetMyDraftByIdQuery__
 *
 * To run a query within a React component, call `useGetMyDraftByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDraftByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDraftByIdQuery({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useGetMyDraftByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyDraftByIdQuery,
    GetMyDraftByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyDraftByIdQuery, GetMyDraftByIdQueryVariables>(
    GetMyDraftByIdDocument,
    options
  );
}
export function useGetMyDraftByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyDraftByIdQuery,
    GetMyDraftByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyDraftByIdQuery, GetMyDraftByIdQueryVariables>(
    GetMyDraftByIdDocument,
    options
  );
}
export type GetMyDraftByIdQueryHookResult = ReturnType<
  typeof useGetMyDraftByIdQuery
>;
export type GetMyDraftByIdLazyQueryHookResult = ReturnType<
  typeof useGetMyDraftByIdLazyQuery
>;
export type GetMyDraftByIdQueryResult = Apollo.QueryResult<
  GetMyDraftByIdQuery,
  GetMyDraftByIdQueryVariables
>;
export const GetMyUndiscardedDraftsWithFiltersDocument = gql`
  query GetMyUndiscardedDraftsWithFilters($after: DateTime) {
    myDraftScenarios(
      where: { createdAt: { gte: $after }, status: { neq: DISCARDED } }
    ) {
      id
      draftType
      season
      status
      name
      createdAt
      createdBy
      lastUpdated
      netRosterValueChangeDollars
      currentRosterValue {
        ...valueFields
      }
    }
  }
  ${ValueFieldsFragmentDoc}
`;

/**
 * __useGetMyUndiscardedDraftsWithFiltersQuery__
 *
 * To run a query within a React component, call `useGetMyUndiscardedDraftsWithFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUndiscardedDraftsWithFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUndiscardedDraftsWithFiltersQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetMyUndiscardedDraftsWithFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyUndiscardedDraftsWithFiltersQuery,
    GetMyUndiscardedDraftsWithFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyUndiscardedDraftsWithFiltersQuery,
    GetMyUndiscardedDraftsWithFiltersQueryVariables
  >(GetMyUndiscardedDraftsWithFiltersDocument, options);
}
export function useGetMyUndiscardedDraftsWithFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyUndiscardedDraftsWithFiltersQuery,
    GetMyUndiscardedDraftsWithFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyUndiscardedDraftsWithFiltersQuery,
    GetMyUndiscardedDraftsWithFiltersQueryVariables
  >(GetMyUndiscardedDraftsWithFiltersDocument, options);
}
export type GetMyUndiscardedDraftsWithFiltersQueryHookResult = ReturnType<
  typeof useGetMyUndiscardedDraftsWithFiltersQuery
>;
export type GetMyUndiscardedDraftsWithFiltersLazyQueryHookResult = ReturnType<
  typeof useGetMyUndiscardedDraftsWithFiltersLazyQuery
>;
export type GetMyUndiscardedDraftsWithFiltersQueryResult = Apollo.QueryResult<
  GetMyUndiscardedDraftsWithFiltersQuery,
  GetMyUndiscardedDraftsWithFiltersQueryVariables
>;
export const GetTradeEvaluationDocument = gql`
  query GetTradeEvaluation($request: GetTradeEvaluationRequest!) {
    tradeEvaluation(request: $request) {
      costIncurring {
        ...valueFields
      }
      costOffloading {
        ...valueFields
      }
      valueOffering {
        ...valueFields
      }
      valueReceiving {
        ...valueFields
      }
      netCostDollars
      netValueDollars
      tradeValueDollars
      sumerEvaluatedDraftAssets {
        ...sumerEvaluatedDraftAssetsFields
      }
    }
  }
  ${ValueFieldsFragmentDoc}
  ${SumerEvaluatedDraftAssetsFieldsFragmentDoc}
`;

/**
 * __useGetTradeEvaluationQuery__
 *
 * To run a query within a React component, call `useGetTradeEvaluationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradeEvaluationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradeEvaluationQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetTradeEvaluationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTradeEvaluationQuery,
    GetTradeEvaluationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTradeEvaluationQuery,
    GetTradeEvaluationQueryVariables
  >(GetTradeEvaluationDocument, options);
}
export function useGetTradeEvaluationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTradeEvaluationQuery,
    GetTradeEvaluationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTradeEvaluationQuery,
    GetTradeEvaluationQueryVariables
  >(GetTradeEvaluationDocument, options);
}
export type GetTradeEvaluationQueryHookResult = ReturnType<
  typeof useGetTradeEvaluationQuery
>;
export type GetTradeEvaluationLazyQueryHookResult = ReturnType<
  typeof useGetTradeEvaluationLazyQuery
>;
export type GetTradeEvaluationQueryResult = Apollo.QueryResult<
  GetTradeEvaluationQuery,
  GetTradeEvaluationQueryVariables
>;
export const DraftScenarioUpdatedDocument = gql`
  subscription DraftScenarioUpdated($id: UUID!) {
    draftScenarioUpdatesById(id: $id) {
      ...draftScenarioFields
    }
  }
  ${DraftScenarioFieldsFragmentDoc}
`;

/**
 * __useDraftScenarioUpdatedSubscription__
 *
 * To run a query within a React component, call `useDraftScenarioUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDraftScenarioUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftScenarioUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftScenarioUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    DraftScenarioUpdatedSubscription,
    DraftScenarioUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    DraftScenarioUpdatedSubscription,
    DraftScenarioUpdatedSubscriptionVariables
  >(DraftScenarioUpdatedDocument, options);
}
export type DraftScenarioUpdatedSubscriptionHookResult = ReturnType<
  typeof useDraftScenarioUpdatedSubscription
>;
export type DraftScenarioUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<DraftScenarioUpdatedSubscription>;
export const CreateEvaluationDocument = gql`
  mutation CreateEvaluation($request: CreateEvaluationRequest!) {
    createEvaluation(request: $request) {
      id
      isFinal
      playerId
      positionId
      scoutGradeId
      scoutGrade {
        id
        grade
      }
      summary
    }
  }
`;
export type CreateEvaluationMutationFn = Apollo.MutationFunction<
  CreateEvaluationMutation,
  CreateEvaluationMutationVariables
>;

/**
 * __useCreateEvaluationMutation__
 *
 * To run a mutation, you first call `useCreateEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvaluationMutation, { data, loading, error }] = useCreateEvaluationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEvaluationMutation,
    CreateEvaluationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEvaluationMutation,
    CreateEvaluationMutationVariables
  >(CreateEvaluationDocument, options);
}
export type CreateEvaluationMutationHookResult = ReturnType<
  typeof useCreateEvaluationMutation
>;
export type CreateEvaluationMutationResult =
  Apollo.MutationResult<CreateEvaluationMutation>;
export type CreateEvaluationMutationOptions = Apollo.BaseMutationOptions<
  CreateEvaluationMutation,
  CreateEvaluationMutationVariables
>;
export const UpdateEvaluationDocument = gql`
  mutation UpdateEvaluation($request: UpdateEvaluationRequest!) {
    updateEvaluation(request: $request) {
      id
      isFinal
      playerId
      positionId
      scoutGradeId
      scoutGrade {
        id
        grade
      }
      summary
      finalizedOn
    }
  }
`;
export type UpdateEvaluationMutationFn = Apollo.MutationFunction<
  UpdateEvaluationMutation,
  UpdateEvaluationMutationVariables
>;

/**
 * __useUpdateEvaluationMutation__
 *
 * To run a mutation, you first call `useUpdateEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvaluationMutation, { data, loading, error }] = useUpdateEvaluationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEvaluationMutation,
    UpdateEvaluationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEvaluationMutation,
    UpdateEvaluationMutationVariables
  >(UpdateEvaluationDocument, options);
}
export type UpdateEvaluationMutationHookResult = ReturnType<
  typeof useUpdateEvaluationMutation
>;
export type UpdateEvaluationMutationResult =
  Apollo.MutationResult<UpdateEvaluationMutation>;
export type UpdateEvaluationMutationOptions = Apollo.BaseMutationOptions<
  UpdateEvaluationMutation,
  UpdateEvaluationMutationVariables
>;
export const SaveEvaluationTraitDocument = gql`
  mutation SaveEvaluationTrait($request: SaveEvaluationTraitRequest!) {
    saveEvaluationTrait(request: $request) {
      id
      traitGrades {
        id
        scoutGrade {
          id
          grade
          quality
        }
        traitDefinition {
          id
          caption
          shortName
          sort
        }
        comments
      }
    }
  }
`;
export type SaveEvaluationTraitMutationFn = Apollo.MutationFunction<
  SaveEvaluationTraitMutation,
  SaveEvaluationTraitMutationVariables
>;

/**
 * __useSaveEvaluationTraitMutation__
 *
 * To run a mutation, you first call `useSaveEvaluationTraitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEvaluationTraitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEvaluationTraitMutation, { data, loading, error }] = useSaveEvaluationTraitMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveEvaluationTraitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEvaluationTraitMutation,
    SaveEvaluationTraitMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveEvaluationTraitMutation,
    SaveEvaluationTraitMutationVariables
  >(SaveEvaluationTraitDocument, options);
}
export type SaveEvaluationTraitMutationHookResult = ReturnType<
  typeof useSaveEvaluationTraitMutation
>;
export type SaveEvaluationTraitMutationResult =
  Apollo.MutationResult<SaveEvaluationTraitMutation>;
export type SaveEvaluationTraitMutationOptions = Apollo.BaseMutationOptions<
  SaveEvaluationTraitMutation,
  SaveEvaluationTraitMutationVariables
>;
export const SaveEvaluationFitsDocument = gql`
  mutation SaveEvaluationFits($request: SaveEvaluationFitRequest!) {
    saveEvaluationFits(request: $request) {
      id
      fits {
        ...EvaluationFit
      }
    }
  }
  ${EvaluationFitFragmentDoc}
`;
export type SaveEvaluationFitsMutationFn = Apollo.MutationFunction<
  SaveEvaluationFitsMutation,
  SaveEvaluationFitsMutationVariables
>;

/**
 * __useSaveEvaluationFitsMutation__
 *
 * To run a mutation, you first call `useSaveEvaluationFitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEvaluationFitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEvaluationFitsMutation, { data, loading, error }] = useSaveEvaluationFitsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveEvaluationFitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEvaluationFitsMutation,
    SaveEvaluationFitsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveEvaluationFitsMutation,
    SaveEvaluationFitsMutationVariables
  >(SaveEvaluationFitsDocument, options);
}
export type SaveEvaluationFitsMutationHookResult = ReturnType<
  typeof useSaveEvaluationFitsMutation
>;
export type SaveEvaluationFitsMutationResult =
  Apollo.MutationResult<SaveEvaluationFitsMutation>;
export type SaveEvaluationFitsMutationOptions = Apollo.BaseMutationOptions<
  SaveEvaluationFitsMutation,
  SaveEvaluationFitsMutationVariables
>;
export const UnMarkEvaluationAsFinalDocument = gql`
  mutation UnMarkEvaluationAsFinal($request: UnMarkEvaluationAsFinalRequest!) {
    unMarkEvaluationAsFinal(request: $request) {
      id
      isFinal
      finalizedOn
    }
  }
`;
export type UnMarkEvaluationAsFinalMutationFn = Apollo.MutationFunction<
  UnMarkEvaluationAsFinalMutation,
  UnMarkEvaluationAsFinalMutationVariables
>;

/**
 * __useUnMarkEvaluationAsFinalMutation__
 *
 * To run a mutation, you first call `useUnMarkEvaluationAsFinalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnMarkEvaluationAsFinalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unMarkEvaluationAsFinalMutation, { data, loading, error }] = useUnMarkEvaluationAsFinalMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUnMarkEvaluationAsFinalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnMarkEvaluationAsFinalMutation,
    UnMarkEvaluationAsFinalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnMarkEvaluationAsFinalMutation,
    UnMarkEvaluationAsFinalMutationVariables
  >(UnMarkEvaluationAsFinalDocument, options);
}
export type UnMarkEvaluationAsFinalMutationHookResult = ReturnType<
  typeof useUnMarkEvaluationAsFinalMutation
>;
export type UnMarkEvaluationAsFinalMutationResult =
  Apollo.MutationResult<UnMarkEvaluationAsFinalMutation>;
export type UnMarkEvaluationAsFinalMutationOptions = Apollo.BaseMutationOptions<
  UnMarkEvaluationAsFinalMutation,
  UnMarkEvaluationAsFinalMutationVariables
>;
export const SaveEvaluationAlertsDocument = gql`
  mutation SaveEvaluationAlerts($request: SaveEvaluationAlertRequest!) {
    saveEvaluationAlerts(request: $request) {
      id
      alerts {
        ...EvaluationAlert
      }
    }
  }
  ${EvaluationAlertFragmentDoc}
`;
export type SaveEvaluationAlertsMutationFn = Apollo.MutationFunction<
  SaveEvaluationAlertsMutation,
  SaveEvaluationAlertsMutationVariables
>;

/**
 * __useSaveEvaluationAlertsMutation__
 *
 * To run a mutation, you first call `useSaveEvaluationAlertsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEvaluationAlertsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEvaluationAlertsMutation, { data, loading, error }] = useSaveEvaluationAlertsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveEvaluationAlertsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEvaluationAlertsMutation,
    SaveEvaluationAlertsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveEvaluationAlertsMutation,
    SaveEvaluationAlertsMutationVariables
  >(SaveEvaluationAlertsDocument, options);
}
export type SaveEvaluationAlertsMutationHookResult = ReturnType<
  typeof useSaveEvaluationAlertsMutation
>;
export type SaveEvaluationAlertsMutationResult =
  Apollo.MutationResult<SaveEvaluationAlertsMutation>;
export type SaveEvaluationAlertsMutationOptions = Apollo.BaseMutationOptions<
  SaveEvaluationAlertsMutation,
  SaveEvaluationAlertsMutationVariables
>;
export const CreateGameEvaluationDocument = gql`
  mutation CreateGameEvaluation($request: CreateGameEvaluationRequest!) {
    createGameEvaluation(request: $request) {
      id
      gameGrades {
        id
        gameId
        game {
          kickoffTime
          homeTeam
          awayTeam
        }
        evaluationId
        scoutGradeId
        scoutGrade {
          id
          grade
        }
        comments
      }
    }
  }
`;
export type CreateGameEvaluationMutationFn = Apollo.MutationFunction<
  CreateGameEvaluationMutation,
  CreateGameEvaluationMutationVariables
>;

/**
 * __useCreateGameEvaluationMutation__
 *
 * To run a mutation, you first call `useCreateGameEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameEvaluationMutation, { data, loading, error }] = useCreateGameEvaluationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateGameEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGameEvaluationMutation,
    CreateGameEvaluationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGameEvaluationMutation,
    CreateGameEvaluationMutationVariables
  >(CreateGameEvaluationDocument, options);
}
export type CreateGameEvaluationMutationHookResult = ReturnType<
  typeof useCreateGameEvaluationMutation
>;
export type CreateGameEvaluationMutationResult =
  Apollo.MutationResult<CreateGameEvaluationMutation>;
export type CreateGameEvaluationMutationOptions = Apollo.BaseMutationOptions<
  CreateGameEvaluationMutation,
  CreateGameEvaluationMutationVariables
>;
export const UpdateGameEvaluationDocument = gql`
  mutation UpdateGameEvaluation($request: UpdateGameEvaluationRequest!) {
    updateGameEvaluation(request: $request) {
      id
      gameGrades {
        id
        gameId
        game {
          kickoffTime
          homeTeam
          awayTeam
        }
        evaluationId
        scoutGradeId
        scoutGrade {
          id
          grade
        }
        comments
      }
    }
  }
`;
export type UpdateGameEvaluationMutationFn = Apollo.MutationFunction<
  UpdateGameEvaluationMutation,
  UpdateGameEvaluationMutationVariables
>;

/**
 * __useUpdateGameEvaluationMutation__
 *
 * To run a mutation, you first call `useUpdateGameEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameEvaluationMutation, { data, loading, error }] = useUpdateGameEvaluationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateGameEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGameEvaluationMutation,
    UpdateGameEvaluationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGameEvaluationMutation,
    UpdateGameEvaluationMutationVariables
  >(UpdateGameEvaluationDocument, options);
}
export type UpdateGameEvaluationMutationHookResult = ReturnType<
  typeof useUpdateGameEvaluationMutation
>;
export type UpdateGameEvaluationMutationResult =
  Apollo.MutationResult<UpdateGameEvaluationMutation>;
export type UpdateGameEvaluationMutationOptions = Apollo.BaseMutationOptions<
  UpdateGameEvaluationMutation,
  UpdateGameEvaluationMutationVariables
>;
export const SaveEvaluationTraitGroupDocument = gql`
  mutation SaveEvaluationTraitGroup(
    $request: SaveEvaluationTraitGroupRequest!
  ) {
    saveEvaluationTraitGroup(request: $request) {
      id
      scoutGradeId
      scoutGrade {
        id
        grade
        quality
      }
      traitGroupGrades {
        id
        scoutGrade {
          id
          grade
        }
      }
    }
  }
`;
export type SaveEvaluationTraitGroupMutationFn = Apollo.MutationFunction<
  SaveEvaluationTraitGroupMutation,
  SaveEvaluationTraitGroupMutationVariables
>;

/**
 * __useSaveEvaluationTraitGroupMutation__
 *
 * To run a mutation, you first call `useSaveEvaluationTraitGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEvaluationTraitGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEvaluationTraitGroupMutation, { data, loading, error }] = useSaveEvaluationTraitGroupMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveEvaluationTraitGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEvaluationTraitGroupMutation,
    SaveEvaluationTraitGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveEvaluationTraitGroupMutation,
    SaveEvaluationTraitGroupMutationVariables
  >(SaveEvaluationTraitGroupDocument, options);
}
export type SaveEvaluationTraitGroupMutationHookResult = ReturnType<
  typeof useSaveEvaluationTraitGroupMutation
>;
export type SaveEvaluationTraitGroupMutationResult =
  Apollo.MutationResult<SaveEvaluationTraitGroupMutation>;
export type SaveEvaluationTraitGroupMutationOptions =
  Apollo.BaseMutationOptions<
    SaveEvaluationTraitGroupMutation,
    SaveEvaluationTraitGroupMutationVariables
  >;
export const GetEvalDocument = gql`
  query GetEval($id: UUID!) {
    evalById(id: $id) {
      ...Eval
    }
  }
  ${EvalFragmentDoc}
`;

/**
 * __useGetEvalQuery__
 *
 * To run a query within a React component, call `useGetEvalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEvalQuery(
  baseOptions: Apollo.QueryHookOptions<GetEvalQuery, GetEvalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEvalQuery, GetEvalQueryVariables>(
    GetEvalDocument,
    options
  );
}
export function useGetEvalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEvalQuery, GetEvalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEvalQuery, GetEvalQueryVariables>(
    GetEvalDocument,
    options
  );
}
export type GetEvalQueryHookResult = ReturnType<typeof useGetEvalQuery>;
export type GetEvalLazyQueryHookResult = ReturnType<typeof useGetEvalLazyQuery>;
export type GetEvalQueryResult = Apollo.QueryResult<
  GetEvalQuery,
  GetEvalQueryVariables
>;
export const GetEvalsDocument = gql`
  query GetEvals(
    $position: UUID
    $user: UUID
    $playerId: UUID
    $group: EvalTypeEnum
  ) {
    evals(
      order: { lastUpdated: DESC }
      positionFilter: $position
      userFilter: $user
      playerFilter: $playerId
      groupFilter: $group
    ) {
      ...Eval
    }
  }
  ${EvalFragmentDoc}
`;

/**
 * __useGetEvalsQuery__
 *
 * To run a query within a React component, call `useGetEvalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvalsQuery({
 *   variables: {
 *      position: // value for 'position'
 *      user: // value for 'user'
 *      playerId: // value for 'playerId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useGetEvalsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEvalsQuery, GetEvalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEvalsQuery, GetEvalsQueryVariables>(
    GetEvalsDocument,
    options
  );
}
export function useGetEvalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEvalsQuery,
    GetEvalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEvalsQuery, GetEvalsQueryVariables>(
    GetEvalsDocument,
    options
  );
}
export type GetEvalsQueryHookResult = ReturnType<typeof useGetEvalsQuery>;
export type GetEvalsLazyQueryHookResult = ReturnType<
  typeof useGetEvalsLazyQuery
>;
export type GetEvalsQueryResult = Apollo.QueryResult<
  GetEvalsQuery,
  GetEvalsQueryVariables
>;
export const GetTraitGroupsDocument = gql`
  query GetTraitGroups {
    traitGroups(order: { sort: ASC }) {
      ...TraitGroup
    }
  }
  ${TraitGroupFragmentDoc}
`;

/**
 * __useGetTraitGroupsQuery__
 *
 * To run a query within a React component, call `useGetTraitGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTraitGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTraitGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTraitGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTraitGroupsQuery,
    GetTraitGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTraitGroupsQuery, GetTraitGroupsQueryVariables>(
    GetTraitGroupsDocument,
    options
  );
}
export function useGetTraitGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTraitGroupsQuery,
    GetTraitGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTraitGroupsQuery, GetTraitGroupsQueryVariables>(
    GetTraitGroupsDocument,
    options
  );
}
export type GetTraitGroupsQueryHookResult = ReturnType<
  typeof useGetTraitGroupsQuery
>;
export type GetTraitGroupsLazyQueryHookResult = ReturnType<
  typeof useGetTraitGroupsLazyQuery
>;
export type GetTraitGroupsQueryResult = Apollo.QueryResult<
  GetTraitGroupsQuery,
  GetTraitGroupsQueryVariables
>;
export const GetScoutGradesDocument = gql`
  query GetScoutGrades($scale: String) {
    scoutGrades(
      order: { sort: ASC }
      where: {
        and: [{ scale: { contains: $scale } }, { isDeleted: { eq: false } }]
      }
    ) {
      ...ScoutGrade
    }
  }
  ${ScoutGradeFragmentDoc}
`;

/**
 * __useGetScoutGradesQuery__
 *
 * To run a query within a React component, call `useGetScoutGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScoutGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScoutGradesQuery({
 *   variables: {
 *      scale: // value for 'scale'
 *   },
 * });
 */
export function useGetScoutGradesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetScoutGradesQuery,
    GetScoutGradesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScoutGradesQuery, GetScoutGradesQueryVariables>(
    GetScoutGradesDocument,
    options
  );
}
export function useGetScoutGradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScoutGradesQuery,
    GetScoutGradesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScoutGradesQuery, GetScoutGradesQueryVariables>(
    GetScoutGradesDocument,
    options
  );
}
export type GetScoutGradesQueryHookResult = ReturnType<
  typeof useGetScoutGradesQuery
>;
export type GetScoutGradesLazyQueryHookResult = ReturnType<
  typeof useGetScoutGradesLazyQuery
>;
export type GetScoutGradesQueryResult = Apollo.QueryResult<
  GetScoutGradesQuery,
  GetScoutGradesQueryVariables
>;
export const GetFitsDocument = gql`
  query GetFits {
    fits(order: { sort: ASC }, where: { isDeleted: { eq: false } }) {
      ...Fit
    }
  }
  ${FitFragmentDoc}
`;

/**
 * __useGetFitsQuery__
 *
 * To run a query within a React component, call `useGetFitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFitsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFitsQuery, GetFitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFitsQuery, GetFitsQueryVariables>(
    GetFitsDocument,
    options
  );
}
export function useGetFitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFitsQuery, GetFitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFitsQuery, GetFitsQueryVariables>(
    GetFitsDocument,
    options
  );
}
export type GetFitsQueryHookResult = ReturnType<typeof useGetFitsQuery>;
export type GetFitsLazyQueryHookResult = ReturnType<typeof useGetFitsLazyQuery>;
export type GetFitsQueryResult = Apollo.QueryResult<
  GetFitsQuery,
  GetFitsQueryVariables
>;
export const GetAlertsDocument = gql`
  query GetAlerts {
    alerts(order: { sort: ASC }, where: { isDeleted: { eq: false } }) {
      ...Alert
    }
  }
  ${AlertFragmentDoc}
`;

/**
 * __useGetAlertsQuery__
 *
 * To run a query within a React component, call `useGetAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAlertsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAlertsQuery, GetAlertsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAlertsQuery, GetAlertsQueryVariables>(
    GetAlertsDocument,
    options
  );
}
export function useGetAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAlertsQuery,
    GetAlertsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAlertsQuery, GetAlertsQueryVariables>(
    GetAlertsDocument,
    options
  );
}
export type GetAlertsQueryHookResult = ReturnType<typeof useGetAlertsQuery>;
export type GetAlertsLazyQueryHookResult = ReturnType<
  typeof useGetAlertsLazyQuery
>;
export type GetAlertsQueryResult = Apollo.QueryResult<
  GetAlertsQuery,
  GetAlertsQueryVariables
>;
export const AddGlobalDraftWithPickAssumptionDocument = gql`
  mutation AddGlobalDraftWithPickAssumption(
    $request: AddGlobalDraftWithPickAssumptionRequest!
  ) {
    addGlobalDraftWithPickAssumption(request: $request) {
      id
      assumptions {
        ...assumptionFields
      }
    }
  }
  ${AssumptionFieldsFragmentDoc}
`;
export type AddGlobalDraftWithPickAssumptionMutationFn =
  Apollo.MutationFunction<
    AddGlobalDraftWithPickAssumptionMutation,
    AddGlobalDraftWithPickAssumptionMutationVariables
  >;

/**
 * __useAddGlobalDraftWithPickAssumptionMutation__
 *
 * To run a mutation, you first call `useAddGlobalDraftWithPickAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGlobalDraftWithPickAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGlobalDraftWithPickAssumptionMutation, { data, loading, error }] = useAddGlobalDraftWithPickAssumptionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddGlobalDraftWithPickAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGlobalDraftWithPickAssumptionMutation,
    AddGlobalDraftWithPickAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGlobalDraftWithPickAssumptionMutation,
    AddGlobalDraftWithPickAssumptionMutationVariables
  >(AddGlobalDraftWithPickAssumptionDocument, options);
}
export type AddGlobalDraftWithPickAssumptionMutationHookResult = ReturnType<
  typeof useAddGlobalDraftWithPickAssumptionMutation
>;
export type AddGlobalDraftWithPickAssumptionMutationResult =
  Apollo.MutationResult<AddGlobalDraftWithPickAssumptionMutation>;
export type AddGlobalDraftWithPickAssumptionMutationOptions =
  Apollo.BaseMutationOptions<
    AddGlobalDraftWithPickAssumptionMutation,
    AddGlobalDraftWithPickAssumptionMutationVariables
  >;
export const AddGlobalWaitToDraftAssumptionDocument = gql`
  mutation AddGlobalWaitToDraftAssumption(
    $request: AddGlobalWaitToDraftAssumptionRequest!
  ) {
    addGlobalWaitToDraftAssumption(request: $request) {
      id
      assumptions {
        ...assumptionFields
      }
    }
  }
  ${AssumptionFieldsFragmentDoc}
`;
export type AddGlobalWaitToDraftAssumptionMutationFn = Apollo.MutationFunction<
  AddGlobalWaitToDraftAssumptionMutation,
  AddGlobalWaitToDraftAssumptionMutationVariables
>;

/**
 * __useAddGlobalWaitToDraftAssumptionMutation__
 *
 * To run a mutation, you first call `useAddGlobalWaitToDraftAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGlobalWaitToDraftAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGlobalWaitToDraftAssumptionMutation, { data, loading, error }] = useAddGlobalWaitToDraftAssumptionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddGlobalWaitToDraftAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGlobalWaitToDraftAssumptionMutation,
    AddGlobalWaitToDraftAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGlobalWaitToDraftAssumptionMutation,
    AddGlobalWaitToDraftAssumptionMutationVariables
  >(AddGlobalWaitToDraftAssumptionDocument, options);
}
export type AddGlobalWaitToDraftAssumptionMutationHookResult = ReturnType<
  typeof useAddGlobalWaitToDraftAssumptionMutation
>;
export type AddGlobalWaitToDraftAssumptionMutationResult =
  Apollo.MutationResult<AddGlobalWaitToDraftAssumptionMutation>;
export type AddGlobalWaitToDraftAssumptionMutationOptions =
  Apollo.BaseMutationOptions<
    AddGlobalWaitToDraftAssumptionMutation,
    AddGlobalWaitToDraftAssumptionMutationVariables
  >;
export const AddGlobalKeepAssumptionDocument = gql`
  mutation AddGlobalKeepAssumption($request: AddGlobalKeepAssumptionRequest!) {
    addGlobalKeepAssumption(request: $request) {
      id
      assumptions {
        ...assumptionFields
      }
    }
  }
  ${AssumptionFieldsFragmentDoc}
`;
export type AddGlobalKeepAssumptionMutationFn = Apollo.MutationFunction<
  AddGlobalKeepAssumptionMutation,
  AddGlobalKeepAssumptionMutationVariables
>;

/**
 * __useAddGlobalKeepAssumptionMutation__
 *
 * To run a mutation, you first call `useAddGlobalKeepAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGlobalKeepAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGlobalKeepAssumptionMutation, { data, loading, error }] = useAddGlobalKeepAssumptionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddGlobalKeepAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGlobalKeepAssumptionMutation,
    AddGlobalKeepAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGlobalKeepAssumptionMutation,
    AddGlobalKeepAssumptionMutationVariables
  >(AddGlobalKeepAssumptionDocument, options);
}
export type AddGlobalKeepAssumptionMutationHookResult = ReturnType<
  typeof useAddGlobalKeepAssumptionMutation
>;
export type AddGlobalKeepAssumptionMutationResult =
  Apollo.MutationResult<AddGlobalKeepAssumptionMutation>;
export type AddGlobalKeepAssumptionMutationOptions = Apollo.BaseMutationOptions<
  AddGlobalKeepAssumptionMutation,
  AddGlobalKeepAssumptionMutationVariables
>;
export const AddGlobalDoNotDraftAssumptionDocument = gql`
  mutation AddGlobalDoNotDraftAssumption(
    $request: AddGlobalDoNotDraftAssumptionRequest!
  ) {
    addGlobalDoNotDraftAssumption(request: $request) {
      id
      assumptions {
        ...assumptionFields
      }
    }
  }
  ${AssumptionFieldsFragmentDoc}
`;
export type AddGlobalDoNotDraftAssumptionMutationFn = Apollo.MutationFunction<
  AddGlobalDoNotDraftAssumptionMutation,
  AddGlobalDoNotDraftAssumptionMutationVariables
>;

/**
 * __useAddGlobalDoNotDraftAssumptionMutation__
 *
 * To run a mutation, you first call `useAddGlobalDoNotDraftAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGlobalDoNotDraftAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGlobalDoNotDraftAssumptionMutation, { data, loading, error }] = useAddGlobalDoNotDraftAssumptionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddGlobalDoNotDraftAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGlobalDoNotDraftAssumptionMutation,
    AddGlobalDoNotDraftAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGlobalDoNotDraftAssumptionMutation,
    AddGlobalDoNotDraftAssumptionMutationVariables
  >(AddGlobalDoNotDraftAssumptionDocument, options);
}
export type AddGlobalDoNotDraftAssumptionMutationHookResult = ReturnType<
  typeof useAddGlobalDoNotDraftAssumptionMutation
>;
export type AddGlobalDoNotDraftAssumptionMutationResult =
  Apollo.MutationResult<AddGlobalDoNotDraftAssumptionMutation>;
export type AddGlobalDoNotDraftAssumptionMutationOptions =
  Apollo.BaseMutationOptions<
    AddGlobalDoNotDraftAssumptionMutation,
    AddGlobalDoNotDraftAssumptionMutationVariables
  >;
export const AddGlobalCutAssumptionDocument = gql`
  mutation AddGlobalCutAssumption($request: AddGlobalCutAssumptionRequest!) {
    addGlobalCutAssumption(request: $request) {
      id
      assumptions {
        ...assumptionFields
      }
    }
  }
  ${AssumptionFieldsFragmentDoc}
`;
export type AddGlobalCutAssumptionMutationFn = Apollo.MutationFunction<
  AddGlobalCutAssumptionMutation,
  AddGlobalCutAssumptionMutationVariables
>;

/**
 * __useAddGlobalCutAssumptionMutation__
 *
 * To run a mutation, you first call `useAddGlobalCutAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGlobalCutAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGlobalCutAssumptionMutation, { data, loading, error }] = useAddGlobalCutAssumptionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddGlobalCutAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGlobalCutAssumptionMutation,
    AddGlobalCutAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGlobalCutAssumptionMutation,
    AddGlobalCutAssumptionMutationVariables
  >(AddGlobalCutAssumptionDocument, options);
}
export type AddGlobalCutAssumptionMutationHookResult = ReturnType<
  typeof useAddGlobalCutAssumptionMutation
>;
export type AddGlobalCutAssumptionMutationResult =
  Apollo.MutationResult<AddGlobalCutAssumptionMutation>;
export type AddGlobalCutAssumptionMutationOptions = Apollo.BaseMutationOptions<
  AddGlobalCutAssumptionMutation,
  AddGlobalCutAssumptionMutationVariables
>;
export const DeleteGlobalAssumptionDocument = gql`
  mutation DeleteGlobalAssumption($request: DeleteGlobalAssumptionRequest!) {
    deleteGlobalAssumption(request: $request) {
      id
      assumptions {
        ...assumptionFields
      }
    }
  }
  ${AssumptionFieldsFragmentDoc}
`;
export type DeleteGlobalAssumptionMutationFn = Apollo.MutationFunction<
  DeleteGlobalAssumptionMutation,
  DeleteGlobalAssumptionMutationVariables
>;

/**
 * __useDeleteGlobalAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteGlobalAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGlobalAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGlobalAssumptionMutation, { data, loading, error }] = useDeleteGlobalAssumptionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDeleteGlobalAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGlobalAssumptionMutation,
    DeleteGlobalAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGlobalAssumptionMutation,
    DeleteGlobalAssumptionMutationVariables
  >(DeleteGlobalAssumptionDocument, options);
}
export type DeleteGlobalAssumptionMutationHookResult = ReturnType<
  typeof useDeleteGlobalAssumptionMutation
>;
export type DeleteGlobalAssumptionMutationResult =
  Apollo.MutationResult<DeleteGlobalAssumptionMutation>;
export type DeleteGlobalAssumptionMutationOptions = Apollo.BaseMutationOptions<
  DeleteGlobalAssumptionMutation,
  DeleteGlobalAssumptionMutationVariables
>;
export const GetGlobalAssumptionsDocument = gql`
  query GetGlobalAssumptions {
    globalAssumptions {
      id
      assumptions {
        id
        constraint
        description
        isValid
        name
        subject
        playerId
        contracts {
          id
        }
        draftPick {
          season
          round
          number
          overall
        }
        contractMinimumEndDate
        draftRoundMinimum
      }
    }
  }
`;

/**
 * __useGetGlobalAssumptionsQuery__
 *
 * To run a query within a React component, call `useGetGlobalAssumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalAssumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalAssumptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalAssumptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGlobalAssumptionsQuery,
    GetGlobalAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGlobalAssumptionsQuery,
    GetGlobalAssumptionsQueryVariables
  >(GetGlobalAssumptionsDocument, options);
}
export function useGetGlobalAssumptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGlobalAssumptionsQuery,
    GetGlobalAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGlobalAssumptionsQuery,
    GetGlobalAssumptionsQueryVariables
  >(GetGlobalAssumptionsDocument, options);
}
export type GetGlobalAssumptionsQueryHookResult = ReturnType<
  typeof useGetGlobalAssumptionsQuery
>;
export type GetGlobalAssumptionsLazyQueryHookResult = ReturnType<
  typeof useGetGlobalAssumptionsLazyQuery
>;
export type GetGlobalAssumptionsQueryResult = Apollo.QueryResult<
  GetGlobalAssumptionsQuery,
  GetGlobalAssumptionsQueryVariables
>;
export const GetGlobalAssumptionsWithPlayerDocument = gql`
  query GetGlobalAssumptionsWithPlayer {
    globalAssumptionsWithPlayer {
      firstName
      lastName
      id
      club {
        clubCode
        id
      }
      headshotUrl
      clubGradingPosition {
        shortName
      }
      assumptions {
        id
        constraint
        description
        isValid
        name
        subject
        playerId
        contracts {
          id
        }
        draftPick {
          season
          round
          number
          overall
        }
        contractMinimumEndDate
        draftRoundMinimum
      }
      grades {
        gradeType
        value
        dollars
      }
    }
  }
`;

/**
 * __useGetGlobalAssumptionsWithPlayerQuery__
 *
 * To run a query within a React component, call `useGetGlobalAssumptionsWithPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalAssumptionsWithPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalAssumptionsWithPlayerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalAssumptionsWithPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGlobalAssumptionsWithPlayerQuery,
    GetGlobalAssumptionsWithPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGlobalAssumptionsWithPlayerQuery,
    GetGlobalAssumptionsWithPlayerQueryVariables
  >(GetGlobalAssumptionsWithPlayerDocument, options);
}
export function useGetGlobalAssumptionsWithPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGlobalAssumptionsWithPlayerQuery,
    GetGlobalAssumptionsWithPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGlobalAssumptionsWithPlayerQuery,
    GetGlobalAssumptionsWithPlayerQueryVariables
  >(GetGlobalAssumptionsWithPlayerDocument, options);
}
export type GetGlobalAssumptionsWithPlayerQueryHookResult = ReturnType<
  typeof useGetGlobalAssumptionsWithPlayerQuery
>;
export type GetGlobalAssumptionsWithPlayerLazyQueryHookResult = ReturnType<
  typeof useGetGlobalAssumptionsWithPlayerLazyQuery
>;
export type GetGlobalAssumptionsWithPlayerQueryResult = Apollo.QueryResult<
  GetGlobalAssumptionsWithPlayerQuery,
  GetGlobalAssumptionsWithPlayerQueryVariables
>;
export const UpdateGradeWeightDocument = gql`
  mutation UpdateGradeWeight(
    $updateGradeWeightRequest: UpdateGradeWeightRequest!
  ) {
    updateGradeWeight(updateGradeWeightRequest: $updateGradeWeightRequest) {
      id
      gradeWeightType
      value
    }
  }
`;
export type UpdateGradeWeightMutationFn = Apollo.MutationFunction<
  UpdateGradeWeightMutation,
  UpdateGradeWeightMutationVariables
>;

/**
 * __useUpdateGradeWeightMutation__
 *
 * To run a mutation, you first call `useUpdateGradeWeightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGradeWeightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGradeWeightMutation, { data, loading, error }] = useUpdateGradeWeightMutation({
 *   variables: {
 *      updateGradeWeightRequest: // value for 'updateGradeWeightRequest'
 *   },
 * });
 */
export function useUpdateGradeWeightMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGradeWeightMutation,
    UpdateGradeWeightMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGradeWeightMutation,
    UpdateGradeWeightMutationVariables
  >(UpdateGradeWeightDocument, options);
}
export type UpdateGradeWeightMutationHookResult = ReturnType<
  typeof useUpdateGradeWeightMutation
>;
export type UpdateGradeWeightMutationResult =
  Apollo.MutationResult<UpdateGradeWeightMutation>;
export type UpdateGradeWeightMutationOptions = Apollo.BaseMutationOptions<
  UpdateGradeWeightMutation,
  UpdateGradeWeightMutationVariables
>;
export const OverridePlayerGradeDocument = gql`
  mutation OverridePlayerGrade(
    $overridePlayerGradeRequest: OverridePlayerGradeRequest!
  ) {
    overridePlayerGrade(request: $overridePlayerGradeRequest) {
      playerId
      id
      avgClubGapypocDollars
      value {
        gapypoc
        gapypocDollars
      }
      seasons {
        season
        gradeType
        lastUpdated
        value {
          value
          rawValue
          rawValueOverride
          gapypoc
          gapypocDollars
        }
      }
    }
  }
`;
export type OverridePlayerGradeMutationFn = Apollo.MutationFunction<
  OverridePlayerGradeMutation,
  OverridePlayerGradeMutationVariables
>;

/**
 * __useOverridePlayerGradeMutation__
 *
 * To run a mutation, you first call `useOverridePlayerGradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverridePlayerGradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overridePlayerGradeMutation, { data, loading, error }] = useOverridePlayerGradeMutation({
 *   variables: {
 *      overridePlayerGradeRequest: // value for 'overridePlayerGradeRequest'
 *   },
 * });
 */
export function useOverridePlayerGradeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OverridePlayerGradeMutation,
    OverridePlayerGradeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OverridePlayerGradeMutation,
    OverridePlayerGradeMutationVariables
  >(OverridePlayerGradeDocument, options);
}
export type OverridePlayerGradeMutationHookResult = ReturnType<
  typeof useOverridePlayerGradeMutation
>;
export type OverridePlayerGradeMutationResult =
  Apollo.MutationResult<OverridePlayerGradeMutation>;
export type OverridePlayerGradeMutationOptions = Apollo.BaseMutationOptions<
  OverridePlayerGradeMutation,
  OverridePlayerGradeMutationVariables
>;
export const GetGradeWeightsDocument = gql`
  query getGradeWeights {
    gradeWeights {
      id
      gradeWeightType
      value
    }
  }
`;

/**
 * __useGetGradeWeightsQuery__
 *
 * To run a query within a React component, call `useGetGradeWeightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradeWeightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradeWeightsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGradeWeightsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGradeWeightsQuery,
    GetGradeWeightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGradeWeightsQuery, GetGradeWeightsQueryVariables>(
    GetGradeWeightsDocument,
    options
  );
}
export function useGetGradeWeightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGradeWeightsQuery,
    GetGradeWeightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGradeWeightsQuery,
    GetGradeWeightsQueryVariables
  >(GetGradeWeightsDocument, options);
}
export type GetGradeWeightsQueryHookResult = ReturnType<
  typeof useGetGradeWeightsQuery
>;
export type GetGradeWeightsLazyQueryHookResult = ReturnType<
  typeof useGetGradeWeightsLazyQuery
>;
export type GetGradeWeightsQueryResult = Apollo.QueryResult<
  GetGradeWeightsQuery,
  GetGradeWeightsQueryVariables
>;
export const CreateInjuryCollegeDocument = gql`
  mutation CreateInjuryCollege(
    $createInjuryCollegeRequest: CreatePlayerInjuryColWithDetailsRequest!
  ) {
    createInjuryCollege(
      createInjuryCollegeRequest: $createInjuryCollegeRequest
    ) {
      id
      playerId
      schoolId
      dateTime
      source
      injuryType
      injuryDetail
      injuryStatus
    }
  }
`;
export type CreateInjuryCollegeMutationFn = Apollo.MutationFunction<
  CreateInjuryCollegeMutation,
  CreateInjuryCollegeMutationVariables
>;

/**
 * __useCreateInjuryCollegeMutation__
 *
 * To run a mutation, you first call `useCreateInjuryCollegeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInjuryCollegeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInjuryCollegeMutation, { data, loading, error }] = useCreateInjuryCollegeMutation({
 *   variables: {
 *      createInjuryCollegeRequest: // value for 'createInjuryCollegeRequest'
 *   },
 * });
 */
export function useCreateInjuryCollegeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInjuryCollegeMutation,
    CreateInjuryCollegeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInjuryCollegeMutation,
    CreateInjuryCollegeMutationVariables
  >(CreateInjuryCollegeDocument, options);
}
export type CreateInjuryCollegeMutationHookResult = ReturnType<
  typeof useCreateInjuryCollegeMutation
>;
export type CreateInjuryCollegeMutationResult =
  Apollo.MutationResult<CreateInjuryCollegeMutation>;
export type CreateInjuryCollegeMutationOptions = Apollo.BaseMutationOptions<
  CreateInjuryCollegeMutation,
  CreateInjuryCollegeMutationVariables
>;
export const CreateInjuryProDocument = gql`
  mutation CreateInjuryPro(
    $createInjuryProRequest: CreatePlayerInjuryProWithDetailsRequest!
  ) {
    createInjuryPro(createInjuryProRequest: $createInjuryProRequest) {
      id
      playerId
      clubId
      dateTime
      source
      injury1
      injuryDesc1
      injuryStatus
    }
  }
`;
export type CreateInjuryProMutationFn = Apollo.MutationFunction<
  CreateInjuryProMutation,
  CreateInjuryProMutationVariables
>;

/**
 * __useCreateInjuryProMutation__
 *
 * To run a mutation, you first call `useCreateInjuryProMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInjuryProMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInjuryProMutation, { data, loading, error }] = useCreateInjuryProMutation({
 *   variables: {
 *      createInjuryProRequest: // value for 'createInjuryProRequest'
 *   },
 * });
 */
export function useCreateInjuryProMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInjuryProMutation,
    CreateInjuryProMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInjuryProMutation,
    CreateInjuryProMutationVariables
  >(CreateInjuryProDocument, options);
}
export type CreateInjuryProMutationHookResult = ReturnType<
  typeof useCreateInjuryProMutation
>;
export type CreateInjuryProMutationResult =
  Apollo.MutationResult<CreateInjuryProMutation>;
export type CreateInjuryProMutationOptions = Apollo.BaseMutationOptions<
  CreateInjuryProMutation,
  CreateInjuryProMutationVariables
>;
export const UpdateInjuryCollegeDocument = gql`
  mutation UpdateInjuryCollege(
    $updateInjuryCollegeRequest: UpdatePlayerInjuryCollegeWithDetailsRequest!
  ) {
    updateInjuryCollege(
      updateInjuryCollegeRequest: $updateInjuryCollegeRequest
    ) {
      id
      playerId
      schoolId
      dateTime
      source
      injuryType
      injuryDetail
      injuryStatus
    }
  }
`;
export type UpdateInjuryCollegeMutationFn = Apollo.MutationFunction<
  UpdateInjuryCollegeMutation,
  UpdateInjuryCollegeMutationVariables
>;

/**
 * __useUpdateInjuryCollegeMutation__
 *
 * To run a mutation, you first call `useUpdateInjuryCollegeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInjuryCollegeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInjuryCollegeMutation, { data, loading, error }] = useUpdateInjuryCollegeMutation({
 *   variables: {
 *      updateInjuryCollegeRequest: // value for 'updateInjuryCollegeRequest'
 *   },
 * });
 */
export function useUpdateInjuryCollegeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInjuryCollegeMutation,
    UpdateInjuryCollegeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInjuryCollegeMutation,
    UpdateInjuryCollegeMutationVariables
  >(UpdateInjuryCollegeDocument, options);
}
export type UpdateInjuryCollegeMutationHookResult = ReturnType<
  typeof useUpdateInjuryCollegeMutation
>;
export type UpdateInjuryCollegeMutationResult =
  Apollo.MutationResult<UpdateInjuryCollegeMutation>;
export type UpdateInjuryCollegeMutationOptions = Apollo.BaseMutationOptions<
  UpdateInjuryCollegeMutation,
  UpdateInjuryCollegeMutationVariables
>;
export const UpdateInjuryProDocument = gql`
  mutation UpdateInjuryPro(
    $updateInjuryProRequest: UpdatePlayerInjuryProWithDetailsRequest!
  ) {
    updateInjuryPro(updateInjuryProRequest: $updateInjuryProRequest) {
      id
      playerId
      clubId
      dateTime
      source
      injury1
      injuryDesc1
      injuryStatus
    }
  }
`;
export type UpdateInjuryProMutationFn = Apollo.MutationFunction<
  UpdateInjuryProMutation,
  UpdateInjuryProMutationVariables
>;

/**
 * __useUpdateInjuryProMutation__
 *
 * To run a mutation, you first call `useUpdateInjuryProMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInjuryProMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInjuryProMutation, { data, loading, error }] = useUpdateInjuryProMutation({
 *   variables: {
 *      updateInjuryProRequest: // value for 'updateInjuryProRequest'
 *   },
 * });
 */
export function useUpdateInjuryProMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInjuryProMutation,
    UpdateInjuryProMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInjuryProMutation,
    UpdateInjuryProMutationVariables
  >(UpdateInjuryProDocument, options);
}
export type UpdateInjuryProMutationHookResult = ReturnType<
  typeof useUpdateInjuryProMutation
>;
export type UpdateInjuryProMutationResult =
  Apollo.MutationResult<UpdateInjuryProMutation>;
export type UpdateInjuryProMutationOptions = Apollo.BaseMutationOptions<
  UpdateInjuryProMutation,
  UpdateInjuryProMutationVariables
>;
export const GetInjuriesForProPlayerDocument = gql`
  query GetInjuriesForProPlayer($playerId: UUID) {
    playerInjuryPro(where: { playerId: { eq: $playerId } }) {
      edges {
        node {
          id
          playerId
          source
          season
          injury1
          injuryDesc1
          injury2
          injuryDesc2
          leagueInjuryId
          reportType
          reportDay
          injuryStatus
          dateTime
          clubId
          club {
            clubCode
          }
        }
      }
    }
  }
`;

/**
 * __useGetInjuriesForProPlayerQuery__
 *
 * To run a query within a React component, call `useGetInjuriesForProPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInjuriesForProPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInjuriesForProPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetInjuriesForProPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInjuriesForProPlayerQuery,
    GetInjuriesForProPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInjuriesForProPlayerQuery,
    GetInjuriesForProPlayerQueryVariables
  >(GetInjuriesForProPlayerDocument, options);
}
export function useGetInjuriesForProPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInjuriesForProPlayerQuery,
    GetInjuriesForProPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInjuriesForProPlayerQuery,
    GetInjuriesForProPlayerQueryVariables
  >(GetInjuriesForProPlayerDocument, options);
}
export type GetInjuriesForProPlayerQueryHookResult = ReturnType<
  typeof useGetInjuriesForProPlayerQuery
>;
export type GetInjuriesForProPlayerLazyQueryHookResult = ReturnType<
  typeof useGetInjuriesForProPlayerLazyQuery
>;
export type GetInjuriesForProPlayerQueryResult = Apollo.QueryResult<
  GetInjuriesForProPlayerQuery,
  GetInjuriesForProPlayerQueryVariables
>;
export const GetInjuriesForCollegePlayerDocument = gql`
  query GetInjuriesForCollegePlayer($playerId: UUID) {
    playerInjuryCollege(where: { playerId: { eq: $playerId } }) {
      edges {
        node {
          id
          playerId
          source
          school {
            schoolCode
          }
          dateTime
          injuryStatus
          injuryType
          injuryDetail
          injurySide
        }
      }
    }
  }
`;

/**
 * __useGetInjuriesForCollegePlayerQuery__
 *
 * To run a query within a React component, call `useGetInjuriesForCollegePlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInjuriesForCollegePlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInjuriesForCollegePlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetInjuriesForCollegePlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInjuriesForCollegePlayerQuery,
    GetInjuriesForCollegePlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInjuriesForCollegePlayerQuery,
    GetInjuriesForCollegePlayerQueryVariables
  >(GetInjuriesForCollegePlayerDocument, options);
}
export function useGetInjuriesForCollegePlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInjuriesForCollegePlayerQuery,
    GetInjuriesForCollegePlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInjuriesForCollegePlayerQuery,
    GetInjuriesForCollegePlayerQueryVariables
  >(GetInjuriesForCollegePlayerDocument, options);
}
export type GetInjuriesForCollegePlayerQueryHookResult = ReturnType<
  typeof useGetInjuriesForCollegePlayerQuery
>;
export type GetInjuriesForCollegePlayerLazyQueryHookResult = ReturnType<
  typeof useGetInjuriesForCollegePlayerLazyQuery
>;
export type GetInjuriesForCollegePlayerQueryResult = Apollo.QueryResult<
  GetInjuriesForCollegePlayerQuery,
  GetInjuriesForCollegePlayerQueryVariables
>;
export const GetWorkoutsByPlayerDocument = gql`
  query getWorkoutsByPlayer($playerId: UUID!) {
    workoutsByPlayerId(playerId: $playerId) {
      ...PlayerMeasure
    }
  }
  ${PlayerMeasureFragmentDoc}
`;

/**
 * __useGetWorkoutsByPlayerQuery__
 *
 * To run a query within a React component, call `useGetWorkoutsByPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutsByPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutsByPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetWorkoutsByPlayerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkoutsByPlayerQuery,
    GetWorkoutsByPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkoutsByPlayerQuery,
    GetWorkoutsByPlayerQueryVariables
  >(GetWorkoutsByPlayerDocument, options);
}
export function useGetWorkoutsByPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkoutsByPlayerQuery,
    GetWorkoutsByPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkoutsByPlayerQuery,
    GetWorkoutsByPlayerQueryVariables
  >(GetWorkoutsByPlayerDocument, options);
}
export type GetWorkoutsByPlayerQueryHookResult = ReturnType<
  typeof useGetWorkoutsByPlayerQuery
>;
export type GetWorkoutsByPlayerLazyQueryHookResult = ReturnType<
  typeof useGetWorkoutsByPlayerLazyQuery
>;
export type GetWorkoutsByPlayerQueryResult = Apollo.QueryResult<
  GetWorkoutsByPlayerQuery,
  GetWorkoutsByPlayerQueryVariables
>;
export const GetWorkoutMeasuresByPlayerDocument = gql`
  query getWorkoutMeasuresByPlayer($playerId: UUID!) {
    workoutMeasuresByPlayerId(playerId: $playerId) {
      name
      measure
    }
  }
`;

/**
 * __useGetWorkoutMeasuresByPlayerQuery__
 *
 * To run a query within a React component, call `useGetWorkoutMeasuresByPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutMeasuresByPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutMeasuresByPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetWorkoutMeasuresByPlayerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkoutMeasuresByPlayerQuery,
    GetWorkoutMeasuresByPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkoutMeasuresByPlayerQuery,
    GetWorkoutMeasuresByPlayerQueryVariables
  >(GetWorkoutMeasuresByPlayerDocument, options);
}
export function useGetWorkoutMeasuresByPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkoutMeasuresByPlayerQuery,
    GetWorkoutMeasuresByPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkoutMeasuresByPlayerQuery,
    GetWorkoutMeasuresByPlayerQueryVariables
  >(GetWorkoutMeasuresByPlayerDocument, options);
}
export type GetWorkoutMeasuresByPlayerQueryHookResult = ReturnType<
  typeof useGetWorkoutMeasuresByPlayerQuery
>;
export type GetWorkoutMeasuresByPlayerLazyQueryHookResult = ReturnType<
  typeof useGetWorkoutMeasuresByPlayerLazyQuery
>;
export type GetWorkoutMeasuresByPlayerQueryResult = Apollo.QueryResult<
  GetWorkoutMeasuresByPlayerQuery,
  GetWorkoutMeasuresByPlayerQueryVariables
>;
export const GetWorkoutMeasuresBySchoolDocument = gql`
  query getWorkoutMeasuresBySchool($schoolId: UUID!) {
    workoutsBySchoolId(schoolId: $schoolId) {
      ...PlayerMeasure
    }
  }
  ${PlayerMeasureFragmentDoc}
`;

/**
 * __useGetWorkoutMeasuresBySchoolQuery__
 *
 * To run a query within a React component, call `useGetWorkoutMeasuresBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutMeasuresBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutMeasuresBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetWorkoutMeasuresBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkoutMeasuresBySchoolQuery,
    GetWorkoutMeasuresBySchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkoutMeasuresBySchoolQuery,
    GetWorkoutMeasuresBySchoolQueryVariables
  >(GetWorkoutMeasuresBySchoolDocument, options);
}
export function useGetWorkoutMeasuresBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkoutMeasuresBySchoolQuery,
    GetWorkoutMeasuresBySchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkoutMeasuresBySchoolQuery,
    GetWorkoutMeasuresBySchoolQueryVariables
  >(GetWorkoutMeasuresBySchoolDocument, options);
}
export type GetWorkoutMeasuresBySchoolQueryHookResult = ReturnType<
  typeof useGetWorkoutMeasuresBySchoolQuery
>;
export type GetWorkoutMeasuresBySchoolLazyQueryHookResult = ReturnType<
  typeof useGetWorkoutMeasuresBySchoolLazyQuery
>;
export type GetWorkoutMeasuresBySchoolQueryResult = Apollo.QueryResult<
  GetWorkoutMeasuresBySchoolQuery,
  GetWorkoutMeasuresBySchoolQueryVariables
>;
export const GetMeasureTypesDocument = gql`
  query getMeasureTypes {
    measureTypes {
      ...MeasureType
    }
  }
  ${MeasureTypeFragmentDoc}
`;

/**
 * __useGetMeasureTypesQuery__
 *
 * To run a query within a React component, call `useGetMeasureTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasureTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasureTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeasureTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMeasureTypesQuery,
    GetMeasureTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeasureTypesQuery, GetMeasureTypesQueryVariables>(
    GetMeasureTypesDocument,
    options
  );
}
export function useGetMeasureTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMeasureTypesQuery,
    GetMeasureTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMeasureTypesQuery,
    GetMeasureTypesQueryVariables
  >(GetMeasureTypesDocument, options);
}
export type GetMeasureTypesQueryHookResult = ReturnType<
  typeof useGetMeasureTypesQuery
>;
export type GetMeasureTypesLazyQueryHookResult = ReturnType<
  typeof useGetMeasureTypesLazyQuery
>;
export type GetMeasureTypesQueryResult = Apollo.QueryResult<
  GetMeasureTypesQuery,
  GetMeasureTypesQueryVariables
>;
export const SearchAllDocument = gql`
  query SearchAll($searchTerm: String!) {
    searchAll(searchTerm: $searchTerm) {
      score
      player {
        searchScore
        record {
          player {
            id
          }
          clubCode
          clubId
          clubName
          schoolId
          schoolCode
          draftYear
          firstName
          lastName
          hometown
          height
          sumerAtlasPositionShortName
          sumerGeneralPositionShortName
          primarySchemePositionShortName
        }
      }
      school {
        searchScore
        record {
          id
          schoolCode
          schoolName
        }
      }
      club {
        searchScore
        record {
          id
          clubCode
          clubName
        }
      }
    }
  }
`;

/**
 * __useSearchAllQuery__
 *
 * To run a query within a React component, call `useSearchAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAllQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useSearchAllQuery(
  baseOptions: Apollo.QueryHookOptions<SearchAllQuery, SearchAllQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchAllQuery, SearchAllQueryVariables>(
    SearchAllDocument,
    options
  );
}
export function useSearchAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAllQuery,
    SearchAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchAllQuery, SearchAllQueryVariables>(
    SearchAllDocument,
    options
  );
}
export type SearchAllQueryHookResult = ReturnType<typeof useSearchAllQuery>;
export type SearchAllLazyQueryHookResult = ReturnType<
  typeof useSearchAllLazyQuery
>;
export type SearchAllQueryResult = Apollo.QueryResult<
  SearchAllQuery,
  SearchAllQueryVariables
>;
export const GetPositionsDocument = gql`
  query GetPositions($positionTypeFilter: PositionType) {
    positions(positionTypeFilter: $positionTypeFilter, order: { sort: ASC }) {
      ...Position
    }
  }
  ${PositionFragmentDoc}
`;

/**
 * __useGetPositionsQuery__
 *
 * To run a query within a React component, call `useGetPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionsQuery({
 *   variables: {
 *      positionTypeFilter: // value for 'positionTypeFilter'
 *   },
 * });
 */
export function useGetPositionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPositionsQuery,
    GetPositionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPositionsQuery, GetPositionsQueryVariables>(
    GetPositionsDocument,
    options
  );
}
export function useGetPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPositionsQuery,
    GetPositionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPositionsQuery, GetPositionsQueryVariables>(
    GetPositionsDocument,
    options
  );
}
export type GetPositionsQueryHookResult = ReturnType<
  typeof useGetPositionsQuery
>;
export type GetPositionsLazyQueryHookResult = ReturnType<
  typeof useGetPositionsLazyQuery
>;
export type GetPositionsQueryResult = Apollo.QueryResult<
  GetPositionsQuery,
  GetPositionsQueryVariables
>;
export const GetCurrentSeasonDocument = gql`
  query GetCurrentSeason {
    currentSeason {
      calendarDate
      season
      description
      isRegSeason
      isPostSeason
      isDraft
    }
  }
`;

/**
 * __useGetCurrentSeasonQuery__
 *
 * To run a query within a React component, call `useGetCurrentSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSeasonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentSeasonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentSeasonQuery,
    GetCurrentSeasonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentSeasonQuery, GetCurrentSeasonQueryVariables>(
    GetCurrentSeasonDocument,
    options
  );
}
export function useGetCurrentSeasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentSeasonQuery,
    GetCurrentSeasonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentSeasonQuery,
    GetCurrentSeasonQueryVariables
  >(GetCurrentSeasonDocument, options);
}
export type GetCurrentSeasonQueryHookResult = ReturnType<
  typeof useGetCurrentSeasonQuery
>;
export type GetCurrentSeasonLazyQueryHookResult = ReturnType<
  typeof useGetCurrentSeasonLazyQuery
>;
export type GetCurrentSeasonQueryResult = Apollo.QueryResult<
  GetCurrentSeasonQuery,
  GetCurrentSeasonQueryVariables
>;
export const CreateRoleDocument = gql`
  mutation CreateRole($request: CreateRoleRequest!) {
    createRole(request: $request) {
      id
      name
      description
      permissions {
        id
        name
        description
      }
    }
  }
`;
export type CreateRoleMutationFn = Apollo.MutationFunction<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(
    CreateRoleDocument,
    options
  );
}
export type CreateRoleMutationHookResult = ReturnType<
  typeof useCreateRoleMutation
>;
export type CreateRoleMutationResult =
  Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;
export const UpdateRoleDocument = gql`
  mutation UpdateRole($request: UpdateRoleRequest!) {
    updateRole(request: $request) {
      id
      name
      description
      permissions {
        id
        name
        description
      }
    }
  }
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(
    UpdateRoleDocument,
    options
  );
}
export type UpdateRoleMutationHookResult = ReturnType<
  typeof useUpdateRoleMutation
>;
export type UpdateRoleMutationResult =
  Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;
export const AssignPermissionsToRoleDocument = gql`
  mutation AssignPermissionsToRole($request: AssignPermissionsToRoleRequest!) {
    assignPermissionsToRole(request: $request) {
      id
      permissions {
        id
        name
        description
      }
    }
  }
`;
export type AssignPermissionsToRoleMutationFn = Apollo.MutationFunction<
  AssignPermissionsToRoleMutation,
  AssignPermissionsToRoleMutationVariables
>;

/**
 * __useAssignPermissionsToRoleMutation__
 *
 * To run a mutation, you first call `useAssignPermissionsToRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPermissionsToRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPermissionsToRoleMutation, { data, loading, error }] = useAssignPermissionsToRoleMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssignPermissionsToRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignPermissionsToRoleMutation,
    AssignPermissionsToRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignPermissionsToRoleMutation,
    AssignPermissionsToRoleMutationVariables
  >(AssignPermissionsToRoleDocument, options);
}
export type AssignPermissionsToRoleMutationHookResult = ReturnType<
  typeof useAssignPermissionsToRoleMutation
>;
export type AssignPermissionsToRoleMutationResult =
  Apollo.MutationResult<AssignPermissionsToRoleMutation>;
export type AssignPermissionsToRoleMutationOptions = Apollo.BaseMutationOptions<
  AssignPermissionsToRoleMutation,
  AssignPermissionsToRoleMutationVariables
>;
export const RemovePermissionsFromRoleDocument = gql`
  mutation RemovePermissionsFromRole(
    $request: RemovePermissionsFromRoleRequest!
  ) {
    removePermissionsFromRole(request: $request) {
      id
      permissions {
        id
        name
        description
      }
    }
  }
`;
export type RemovePermissionsFromRoleMutationFn = Apollo.MutationFunction<
  RemovePermissionsFromRoleMutation,
  RemovePermissionsFromRoleMutationVariables
>;

/**
 * __useRemovePermissionsFromRoleMutation__
 *
 * To run a mutation, you first call `useRemovePermissionsFromRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePermissionsFromRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePermissionsFromRoleMutation, { data, loading, error }] = useRemovePermissionsFromRoleMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRemovePermissionsFromRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePermissionsFromRoleMutation,
    RemovePermissionsFromRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemovePermissionsFromRoleMutation,
    RemovePermissionsFromRoleMutationVariables
  >(RemovePermissionsFromRoleDocument, options);
}
export type RemovePermissionsFromRoleMutationHookResult = ReturnType<
  typeof useRemovePermissionsFromRoleMutation
>;
export type RemovePermissionsFromRoleMutationResult =
  Apollo.MutationResult<RemovePermissionsFromRoleMutation>;
export type RemovePermissionsFromRoleMutationOptions =
  Apollo.BaseMutationOptions<
    RemovePermissionsFromRoleMutation,
    RemovePermissionsFromRoleMutationVariables
  >;
export const CreatePermissionDocument = gql`
  mutation CreatePermission($request: CreatePermissionRequest!) {
    createPermission(request: $request) {
      id
      name
      description
    }
  }
`;
export type CreatePermissionMutationFn = Apollo.MutationFunction<
  CreatePermissionMutation,
  CreatePermissionMutationVariables
>;

/**
 * __useCreatePermissionMutation__
 *
 * To run a mutation, you first call `useCreatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPermissionMutation, { data, loading, error }] = useCreatePermissionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreatePermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePermissionMutation,
    CreatePermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePermissionMutation,
    CreatePermissionMutationVariables
  >(CreatePermissionDocument, options);
}
export type CreatePermissionMutationHookResult = ReturnType<
  typeof useCreatePermissionMutation
>;
export type CreatePermissionMutationResult =
  Apollo.MutationResult<CreatePermissionMutation>;
export type CreatePermissionMutationOptions = Apollo.BaseMutationOptions<
  CreatePermissionMutation,
  CreatePermissionMutationVariables
>;
export const UpdatePermissionDocument = gql`
  mutation UpdatePermission($request: UpdatePermissionRequest!) {
    updatePermission(request: $request) {
      id
      name
      description
    }
  }
`;
export type UpdatePermissionMutationFn = Apollo.MutationFunction<
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables
>;

/**
 * __useUpdatePermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionMutation, { data, loading, error }] = useUpdatePermissionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdatePermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePermissionMutation,
    UpdatePermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePermissionMutation,
    UpdatePermissionMutationVariables
  >(UpdatePermissionDocument, options);
}
export type UpdatePermissionMutationHookResult = ReturnType<
  typeof useUpdatePermissionMutation
>;
export type UpdatePermissionMutationResult =
  Apollo.MutationResult<UpdatePermissionMutation>;
export type UpdatePermissionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables
>;
export const GetPermissionByIdDocument = gql`
  query GetPermissionById($id: UUID!) {
    permissions(where: { id: { eq: $id } }) {
      id
      name
      description
      roles {
        id
        name
      }
    }
  }
`;

/**
 * __useGetPermissionByIdQuery__
 *
 * To run a query within a React component, call `useGetPermissionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPermissionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPermissionByIdQuery,
    GetPermissionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPermissionByIdQuery,
    GetPermissionByIdQueryVariables
  >(GetPermissionByIdDocument, options);
}
export function useGetPermissionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermissionByIdQuery,
    GetPermissionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPermissionByIdQuery,
    GetPermissionByIdQueryVariables
  >(GetPermissionByIdDocument, options);
}
export type GetPermissionByIdQueryHookResult = ReturnType<
  typeof useGetPermissionByIdQuery
>;
export type GetPermissionByIdLazyQueryHookResult = ReturnType<
  typeof useGetPermissionByIdLazyQuery
>;
export type GetPermissionByIdQueryResult = Apollo.QueryResult<
  GetPermissionByIdQuery,
  GetPermissionByIdQueryVariables
>;
export const GetPermissionsDocument = gql`
  query GetPermissions {
    permissions {
      id
      name
      description
      roles {
        id
        name
      }
    }
  }
`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export function useGetPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export type GetPermissionsQueryHookResult = ReturnType<
  typeof useGetPermissionsQuery
>;
export type GetPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetPermissionsLazyQuery
>;
export type GetPermissionsQueryResult = Apollo.QueryResult<
  GetPermissionsQuery,
  GetPermissionsQueryVariables
>;
export const UpdatePlayerDocument = gql`
  mutation UpdatePlayer($updatePlayerRequest: UpdatePlayerRequest!) {
    updatePlayer(updatePlayerRequest: $updatePlayerRequest) {
      id
    }
  }
`;
export type UpdatePlayerMutationFn = Apollo.MutationFunction<
  UpdatePlayerMutation,
  UpdatePlayerMutationVariables
>;

/**
 * __useUpdatePlayerMutation__
 *
 * To run a mutation, you first call `useUpdatePlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayerMutation, { data, loading, error }] = useUpdatePlayerMutation({
 *   variables: {
 *      updatePlayerRequest: // value for 'updatePlayerRequest'
 *   },
 * });
 */
export function useUpdatePlayerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlayerMutation,
    UpdatePlayerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlayerMutation,
    UpdatePlayerMutationVariables
  >(UpdatePlayerDocument, options);
}
export type UpdatePlayerMutationHookResult = ReturnType<
  typeof useUpdatePlayerMutation
>;
export type UpdatePlayerMutationResult =
  Apollo.MutationResult<UpdatePlayerMutation>;
export type UpdatePlayerMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlayerMutation,
  UpdatePlayerMutationVariables
>;
export const UpdateBasicPlayerDetailsDocument = gql`
  mutation UpdateBasicPlayerDetails(
    $updateBasicPlayerDetailsRequest: UpdateBasicPlayerDetailsRequest!
  ) {
    updateBasicPlayerDetails(
      updateBasicPlayerDetailsRequest: $updateBasicPlayerDetailsRequest
    ) {
      id
    }
  }
`;
export type UpdateBasicPlayerDetailsMutationFn = Apollo.MutationFunction<
  UpdateBasicPlayerDetailsMutation,
  UpdateBasicPlayerDetailsMutationVariables
>;

/**
 * __useUpdateBasicPlayerDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateBasicPlayerDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBasicPlayerDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBasicPlayerDetailsMutation, { data, loading, error }] = useUpdateBasicPlayerDetailsMutation({
 *   variables: {
 *      updateBasicPlayerDetailsRequest: // value for 'updateBasicPlayerDetailsRequest'
 *   },
 * });
 */
export function useUpdateBasicPlayerDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBasicPlayerDetailsMutation,
    UpdateBasicPlayerDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBasicPlayerDetailsMutation,
    UpdateBasicPlayerDetailsMutationVariables
  >(UpdateBasicPlayerDetailsDocument, options);
}
export type UpdateBasicPlayerDetailsMutationHookResult = ReturnType<
  typeof useUpdateBasicPlayerDetailsMutation
>;
export type UpdateBasicPlayerDetailsMutationResult =
  Apollo.MutationResult<UpdateBasicPlayerDetailsMutation>;
export type UpdateBasicPlayerDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateBasicPlayerDetailsMutation,
    UpdateBasicPlayerDetailsMutationVariables
  >;
export const AssignPlayerOverrideDocument = gql`
  mutation AssignPlayerOverride(
    $assignPlayerOverride: AssignPlayerOverrideRequest!
  ) {
    assignPlayerOverride(assignPlayerOverride: $assignPlayerOverride) {
      id
      latestBirthDate
      playerOverrides {
        field
        value
      }
    }
  }
`;
export type AssignPlayerOverrideMutationFn = Apollo.MutationFunction<
  AssignPlayerOverrideMutation,
  AssignPlayerOverrideMutationVariables
>;

/**
 * __useAssignPlayerOverrideMutation__
 *
 * To run a mutation, you first call `useAssignPlayerOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPlayerOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPlayerOverrideMutation, { data, loading, error }] = useAssignPlayerOverrideMutation({
 *   variables: {
 *      assignPlayerOverride: // value for 'assignPlayerOverride'
 *   },
 * });
 */
export function useAssignPlayerOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignPlayerOverrideMutation,
    AssignPlayerOverrideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignPlayerOverrideMutation,
    AssignPlayerOverrideMutationVariables
  >(AssignPlayerOverrideDocument, options);
}
export type AssignPlayerOverrideMutationHookResult = ReturnType<
  typeof useAssignPlayerOverrideMutation
>;
export type AssignPlayerOverrideMutationResult =
  Apollo.MutationResult<AssignPlayerOverrideMutation>;
export type AssignPlayerOverrideMutationOptions = Apollo.BaseMutationOptions<
  AssignPlayerOverrideMutation,
  AssignPlayerOverrideMutationVariables
>;
export const SaveProjectedGamesMissedDocument = gql`
  mutation SaveProjectedGamesMissed(
    $saveProjectedGamesMissedRequest: SaveProjectedGamesMissedRequest!
  ) {
    saveProjectedGamesMissed(request: $saveProjectedGamesMissedRequest) {
      id
      value
      projectedGamesMissed
      projectedGamesMissedOverride
    }
  }
`;
export type SaveProjectedGamesMissedMutationFn = Apollo.MutationFunction<
  SaveProjectedGamesMissedMutation,
  SaveProjectedGamesMissedMutationVariables
>;

/**
 * __useSaveProjectedGamesMissedMutation__
 *
 * To run a mutation, you first call `useSaveProjectedGamesMissedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectedGamesMissedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectedGamesMissedMutation, { data, loading, error }] = useSaveProjectedGamesMissedMutation({
 *   variables: {
 *      saveProjectedGamesMissedRequest: // value for 'saveProjectedGamesMissedRequest'
 *   },
 * });
 */
export function useSaveProjectedGamesMissedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveProjectedGamesMissedMutation,
    SaveProjectedGamesMissedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveProjectedGamesMissedMutation,
    SaveProjectedGamesMissedMutationVariables
  >(SaveProjectedGamesMissedDocument, options);
}
export type SaveProjectedGamesMissedMutationHookResult = ReturnType<
  typeof useSaveProjectedGamesMissedMutation
>;
export type SaveProjectedGamesMissedMutationResult =
  Apollo.MutationResult<SaveProjectedGamesMissedMutation>;
export type SaveProjectedGamesMissedMutationOptions =
  Apollo.BaseMutationOptions<
    SaveProjectedGamesMissedMutation,
    SaveProjectedGamesMissedMutationVariables
  >;
export const GetPlayerDocument = gql`
  query getPlayer($id: UUID!) {
    user {
      role {
        name
        permissions {
          ...Permission
        }
      }
    }
    playerById(id: $id) {
      ...Player
    }
  }
  ${PermissionFragmentDoc}
  ${PlayerFragmentDoc}
`;

/**
 * __useGetPlayerQuery__
 *
 * To run a query within a React component, call `useGetPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlayerQuery, GetPlayerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlayerQuery, GetPlayerQueryVariables>(
    GetPlayerDocument,
    options
  );
}
export function useGetPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerQuery,
    GetPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlayerQuery, GetPlayerQueryVariables>(
    GetPlayerDocument,
    options
  );
}
export type GetPlayerQueryHookResult = ReturnType<typeof useGetPlayerQuery>;
export type GetPlayerLazyQueryHookResult = ReturnType<
  typeof useGetPlayerLazyQuery
>;
export type GetPlayerQueryResult = Apollo.QueryResult<
  GetPlayerQuery,
  GetPlayerQueryVariables
>;
export const GetPlayerByGsisIdDocument = gql`
  query GetPlayerByGsisId($gsisId: String!) {
    playerByGsisId(gsisId: $gsisId) {
      ...Player
    }
  }
  ${PlayerFragmentDoc}
`;

/**
 * __useGetPlayerByGsisIdQuery__
 *
 * To run a query within a React component, call `useGetPlayerByGsisIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerByGsisIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerByGsisIdQuery({
 *   variables: {
 *      gsisId: // value for 'gsisId'
 *   },
 * });
 */
export function useGetPlayerByGsisIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayerByGsisIdQuery,
    GetPlayerByGsisIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlayerByGsisIdQuery,
    GetPlayerByGsisIdQueryVariables
  >(GetPlayerByGsisIdDocument, options);
}
export function useGetPlayerByGsisIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerByGsisIdQuery,
    GetPlayerByGsisIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlayerByGsisIdQuery,
    GetPlayerByGsisIdQueryVariables
  >(GetPlayerByGsisIdDocument, options);
}
export type GetPlayerByGsisIdQueryHookResult = ReturnType<
  typeof useGetPlayerByGsisIdQuery
>;
export type GetPlayerByGsisIdLazyQueryHookResult = ReturnType<
  typeof useGetPlayerByGsisIdLazyQuery
>;
export type GetPlayerByGsisIdQueryResult = Apollo.QueryResult<
  GetPlayerByGsisIdQuery,
  GetPlayerByGsisIdQueryVariables
>;
export const GetPlayersDocument = gql`
  query GetPlayers(
    $name: String
    $clubName: String
    $schoolCode: String
    $position: String
    $positionGroup: String
    $group: String
    $draftYear: String
    $sortCriteria: PlayerSearchSortCriteriaEnum!
  ) {
    user {
      role {
        name
        permissions {
          ...Permission
        }
      }
    }
    players(
      sumerAtlasPositionFilter: $position
      clubFilter: $clubName
      schoolFilter: $schoolCode
      sumerGeneralPositionFilter: $positionGroup
      groupFilter: $group
      draftYearFilter: $draftYear
      sortCriteria: $sortCriteria
      where: {
        or: [
          { firstName: { contains: $name } }
          { lastName: { contains: $name } }
        ]
      }
    ) {
      ...Player
    }
  }
  ${PermissionFragmentDoc}
  ${PlayerFragmentDoc}
`;

/**
 * __useGetPlayersQuery__
 *
 * To run a query within a React component, call `useGetPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayersQuery({
 *   variables: {
 *      name: // value for 'name'
 *      clubName: // value for 'clubName'
 *      schoolCode: // value for 'schoolCode'
 *      position: // value for 'position'
 *      positionGroup: // value for 'positionGroup'
 *      group: // value for 'group'
 *      draftYear: // value for 'draftYear'
 *      sortCriteria: // value for 'sortCriteria'
 *   },
 * });
 */
export function useGetPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayersQuery,
    GetPlayersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlayersQuery, GetPlayersQueryVariables>(
    GetPlayersDocument,
    options
  );
}
export function useGetPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayersQuery,
    GetPlayersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlayersQuery, GetPlayersQueryVariables>(
    GetPlayersDocument,
    options
  );
}
export type GetPlayersQueryHookResult = ReturnType<typeof useGetPlayersQuery>;
export type GetPlayersLazyQueryHookResult = ReturnType<
  typeof useGetPlayersLazyQuery
>;
export type GetPlayersQueryResult = Apollo.QueryResult<
  GetPlayersQuery,
  GetPlayersQueryVariables
>;
export const GetPlayersPagedDocument = gql`
  query GetPlayersPaged(
    $after: String
    $before: String
    $last: Int
    $first: Int
    $name: String
    $clubName: String
    $schoolCode: String
    $position: String
    $positionGroup: String
    $group: String
    $draftYear: String
    $sortCriteria: PlayerSearchSortCriteriaEnum!
  ) {
    user {
      role {
        name
        permissions {
          ...Permission
        }
      }
    }
    playersPaged(
      after: $after
      before: $before
      last: $last
      first: $first
      sumerAtlasPositionFilter: $position
      clubFilter: $clubName
      schoolFilter: $schoolCode
      sumerGeneralPositionFilter: $positionGroup
      groupFilter: $group
      draftYearFilter: $draftYear
      sortCriteria: $sortCriteria
      where: {
        or: [
          { firstName: { contains: $name } }
          { lastName: { contains: $name } }
        ]
      }
    ) {
      edges {
        node {
          ...Player
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${PermissionFragmentDoc}
  ${PlayerFragmentDoc}
`;

/**
 * __useGetPlayersPagedQuery__
 *
 * To run a query within a React component, call `useGetPlayersPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayersPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayersPagedQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      name: // value for 'name'
 *      clubName: // value for 'clubName'
 *      schoolCode: // value for 'schoolCode'
 *      position: // value for 'position'
 *      positionGroup: // value for 'positionGroup'
 *      group: // value for 'group'
 *      draftYear: // value for 'draftYear'
 *      sortCriteria: // value for 'sortCriteria'
 *   },
 * });
 */
export function useGetPlayersPagedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayersPagedQuery,
    GetPlayersPagedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlayersPagedQuery, GetPlayersPagedQueryVariables>(
    GetPlayersPagedDocument,
    options
  );
}
export function useGetPlayersPagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayersPagedQuery,
    GetPlayersPagedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlayersPagedQuery,
    GetPlayersPagedQueryVariables
  >(GetPlayersPagedDocument, options);
}
export type GetPlayersPagedQueryHookResult = ReturnType<
  typeof useGetPlayersPagedQuery
>;
export type GetPlayersPagedLazyQueryHookResult = ReturnType<
  typeof useGetPlayersPagedLazyQuery
>;
export type GetPlayersPagedQueryResult = Apollo.QueryResult<
  GetPlayersPagedQuery,
  GetPlayersPagedQueryVariables
>;
export const GetOnRosterPlayersDocument = gql`
  query GetOnRosterPlayers(
    $clubName: String
    $group: String
    $sortCriteria: PlayerSearchSortCriteriaEnum!
  ) {
    players(
      clubFilter: $clubName
      groupFilter: $group
      sortCriteria: $sortCriteria
    ) {
      ...Player
    }
  }
  ${PlayerFragmentDoc}
`;

/**
 * __useGetOnRosterPlayersQuery__
 *
 * To run a query within a React component, call `useGetOnRosterPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnRosterPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnRosterPlayersQuery({
 *   variables: {
 *      clubName: // value for 'clubName'
 *      group: // value for 'group'
 *      sortCriteria: // value for 'sortCriteria'
 *   },
 * });
 */
export function useGetOnRosterPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnRosterPlayersQuery,
    GetOnRosterPlayersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOnRosterPlayersQuery,
    GetOnRosterPlayersQueryVariables
  >(GetOnRosterPlayersDocument, options);
}
export function useGetOnRosterPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnRosterPlayersQuery,
    GetOnRosterPlayersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOnRosterPlayersQuery,
    GetOnRosterPlayersQueryVariables
  >(GetOnRosterPlayersDocument, options);
}
export type GetOnRosterPlayersQueryHookResult = ReturnType<
  typeof useGetOnRosterPlayersQuery
>;
export type GetOnRosterPlayersLazyQueryHookResult = ReturnType<
  typeof useGetOnRosterPlayersLazyQuery
>;
export type GetOnRosterPlayersQueryResult = Apollo.QueryResult<
  GetOnRosterPlayersQuery,
  GetOnRosterPlayersQueryVariables
>;
export const SearchPlayersDocument = gql`
  query SearchPlayers(
    $searchTerm: String!
    $orderByFields: [String!]
    $filters: PlayerFiltersInput
    $options: SearchOptionsInput
  ) {
    searchPlayers(
      searchTerm: $searchTerm
      orderByFields: $orderByFields
      filters: $filters
      options: $options
    ) {
      total
      players {
        record {
          player {
            id
            playerGrade {
              ...PlayerGradeFragment
            }
            projectedGamesMissed {
              id
              season
              value
              projectedGamesMissed
              projectedGamesMissedOverride
            }
          }
          clubCode
          clubId
          clubName
          schoolId
          schoolCode
          schoolName
          draftYear
          firstName
          lastName
          hometown
          height
          weight
          speed
          jersey
          displayPositionShortName
          sumerAtlasPositionShortName
          sumerGeneralPositionShortName
          primarySchemePositionShortName
          redshirt
          headshotUrl
          eligibility
          eligibilityYear
          bestClubGrade
          bestClubGradeType
        }
      }
    }
  }
  ${PlayerGradeFragmentFragmentDoc}
`;

/**
 * __useSearchPlayersQuery__
 *
 * To run a query within a React component, call `useSearchPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPlayersQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      orderByFields: // value for 'orderByFields'
 *      filters: // value for 'filters'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSearchPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchPlayersQuery,
    SearchPlayersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPlayersQuery, SearchPlayersQueryVariables>(
    SearchPlayersDocument,
    options
  );
}
export function useSearchPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPlayersQuery,
    SearchPlayersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPlayersQuery, SearchPlayersQueryVariables>(
    SearchPlayersDocument,
    options
  );
}
export type SearchPlayersQueryHookResult = ReturnType<
  typeof useSearchPlayersQuery
>;
export type SearchPlayersLazyQueryHookResult = ReturnType<
  typeof useSearchPlayersLazyQuery
>;
export type SearchPlayersQueryResult = Apollo.QueryResult<
  SearchPlayersQuery,
  SearchPlayersQueryVariables
>;
export const GetDraftablePlayerPoolDocument = gql`
  query GetDraftablePlayerPool($request: LoadDraftablePlayerPoolRequest!) {
    draftablePlayerPool(request: $request) {
      atPick {
        ...currentSeasonDraftAssetFields
      }
      players {
        ...clubDraftablePlayerAtPickFields
        preDraftExpectedPick
        recommendationScore
      }
    }
  }
  ${CurrentSeasonDraftAssetFieldsFragmentDoc}
  ${ClubDraftablePlayerAtPickFieldsFragmentDoc}
`;

/**
 * __useGetDraftablePlayerPoolQuery__
 *
 * To run a query within a React component, call `useGetDraftablePlayerPoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftablePlayerPoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftablePlayerPoolQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetDraftablePlayerPoolQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDraftablePlayerPoolQuery,
    GetDraftablePlayerPoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDraftablePlayerPoolQuery,
    GetDraftablePlayerPoolQueryVariables
  >(GetDraftablePlayerPoolDocument, options);
}
export function useGetDraftablePlayerPoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftablePlayerPoolQuery,
    GetDraftablePlayerPoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDraftablePlayerPoolQuery,
    GetDraftablePlayerPoolQueryVariables
  >(GetDraftablePlayerPoolDocument, options);
}
export type GetDraftablePlayerPoolQueryHookResult = ReturnType<
  typeof useGetDraftablePlayerPoolQuery
>;
export type GetDraftablePlayerPoolLazyQueryHookResult = ReturnType<
  typeof useGetDraftablePlayerPoolLazyQuery
>;
export type GetDraftablePlayerPoolQueryResult = Apollo.QueryResult<
  GetDraftablePlayerPoolQuery,
  GetDraftablePlayerPoolQueryVariables
>;
export const GetDraftablePlayerDocument = gql`
  query GetDraftablePlayer($request: GetDraftablePlayerRequest!) {
    draftablePlayer(request: $request) {
      preDraftExpectedPick
      availabilities {
        ...PlayerAvailability
      }
      rosterValueAdded {
        ...valueFields
      }
      player {
        ...Player
      }
    }
  }
  ${PlayerAvailabilityFragmentDoc}
  ${ValueFieldsFragmentDoc}
  ${PlayerFragmentDoc}
`;

/**
 * __useGetDraftablePlayerQuery__
 *
 * To run a query within a React component, call `useGetDraftablePlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftablePlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftablePlayerQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetDraftablePlayerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDraftablePlayerQuery,
    GetDraftablePlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDraftablePlayerQuery,
    GetDraftablePlayerQueryVariables
  >(GetDraftablePlayerDocument, options);
}
export function useGetDraftablePlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftablePlayerQuery,
    GetDraftablePlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDraftablePlayerQuery,
    GetDraftablePlayerQueryVariables
  >(GetDraftablePlayerDocument, options);
}
export type GetDraftablePlayerQueryHookResult = ReturnType<
  typeof useGetDraftablePlayerQuery
>;
export type GetDraftablePlayerLazyQueryHookResult = ReturnType<
  typeof useGetDraftablePlayerLazyQuery
>;
export type GetDraftablePlayerQueryResult = Apollo.QueryResult<
  GetDraftablePlayerQuery,
  GetDraftablePlayerQueryVariables
>;
export const GetCurrentRosterValueDocument = gql`
  query GetCurrentRosterValue($request: GetCurrentRosterValueRequest!) {
    currentRosterValue(currentRosterValue: $request) {
      player {
        id
        firstName
        lastName
        headshotUrl
        school {
          schoolName
        }
        sumerGeneralPosition {
          shortName
        }
        clubGradingPosition {
          shortName
        }
        playerGrade {
          ...PlayerGradeFragment
        }
        projectedGamesMissed {
          id
          season
          value
          projectedGamesMissed
          projectedGamesMissedOverride
        }
      }
      rosterValueAdded {
        dollars
        gapypoc
      }
    }
  }
  ${PlayerGradeFragmentFragmentDoc}
`;

/**
 * __useGetCurrentRosterValueQuery__
 *
 * To run a query within a React component, call `useGetCurrentRosterValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentRosterValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentRosterValueQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetCurrentRosterValueQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentRosterValueQuery,
    GetCurrentRosterValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentRosterValueQuery,
    GetCurrentRosterValueQueryVariables
  >(GetCurrentRosterValueDocument, options);
}
export function useGetCurrentRosterValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentRosterValueQuery,
    GetCurrentRosterValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentRosterValueQuery,
    GetCurrentRosterValueQueryVariables
  >(GetCurrentRosterValueDocument, options);
}
export type GetCurrentRosterValueQueryHookResult = ReturnType<
  typeof useGetCurrentRosterValueQuery
>;
export type GetCurrentRosterValueLazyQueryHookResult = ReturnType<
  typeof useGetCurrentRosterValueLazyQuery
>;
export type GetCurrentRosterValueQueryResult = Apollo.QueryResult<
  GetCurrentRosterValueQuery,
  GetCurrentRosterValueQueryVariables
>;
export const GetGradeWeightPreviewDocument = gql`
  query GetGradeWeightPreview($request: GetGradeWeightPreviewRequest!) {
    gradeWeightPreview(previewGradeWeights: $request) {
      playerId
      overallGrade {
        value {
          gapypoc
          gapypocDollars
        }
      }
      rosterValueAdded {
        gapypoc
        dollars
      }
    }
  }
`;

/**
 * __useGetGradeWeightPreviewQuery__
 *
 * To run a query within a React component, call `useGetGradeWeightPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradeWeightPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradeWeightPreviewQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetGradeWeightPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGradeWeightPreviewQuery,
    GetGradeWeightPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGradeWeightPreviewQuery,
    GetGradeWeightPreviewQueryVariables
  >(GetGradeWeightPreviewDocument, options);
}
export function useGetGradeWeightPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGradeWeightPreviewQuery,
    GetGradeWeightPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGradeWeightPreviewQuery,
    GetGradeWeightPreviewQueryVariables
  >(GetGradeWeightPreviewDocument, options);
}
export type GetGradeWeightPreviewQueryHookResult = ReturnType<
  typeof useGetGradeWeightPreviewQuery
>;
export type GetGradeWeightPreviewLazyQueryHookResult = ReturnType<
  typeof useGetGradeWeightPreviewLazyQuery
>;
export type GetGradeWeightPreviewQueryResult = Apollo.QueryResult<
  GetGradeWeightPreviewQuery,
  GetGradeWeightPreviewQueryVariables
>;
export const GetRoleByIdDocument = gql`
  query GetRoleById($id: UUID!) {
    roles(where: { id: { eq: $id } }) {
      id
      name
      type
      permissions {
        id
        name
        description
      }
    }
  }
`;

/**
 * __useGetRoleByIdQuery__
 *
 * To run a query within a React component, call `useGetRoleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoleByIdQuery,
    GetRoleByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoleByIdQuery, GetRoleByIdQueryVariables>(
    GetRoleByIdDocument,
    options
  );
}
export function useGetRoleByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoleByIdQuery,
    GetRoleByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoleByIdQuery, GetRoleByIdQueryVariables>(
    GetRoleByIdDocument,
    options
  );
}
export type GetRoleByIdQueryHookResult = ReturnType<typeof useGetRoleByIdQuery>;
export type GetRoleByIdLazyQueryHookResult = ReturnType<
  typeof useGetRoleByIdLazyQuery
>;
export type GetRoleByIdQueryResult = Apollo.QueryResult<
  GetRoleByIdQuery,
  GetRoleByIdQueryVariables
>;
export const GetRolesDocument = gql`
  query GetRoles {
    roles {
      id
      name
      type
      permissions {
        id
        name
        description
        roles {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    options
  );
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRolesQuery,
    GetRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    options
  );
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<
  typeof useGetRolesLazyQuery
>;
export type GetRolesQueryResult = Apollo.QueryResult<
  GetRolesQuery,
  GetRolesQueryVariables
>;
export const CreateScenarioDocument = gql`
  mutation CreateScenario($request: CreateScenarioRequestInput!) {
    createScenario(request: $request) {
      id
      name
    }
  }
`;
export type CreateScenarioMutationFn = Apollo.MutationFunction<
  CreateScenarioMutation,
  CreateScenarioMutationVariables
>;

/**
 * __useCreateScenarioMutation__
 *
 * To run a mutation, you first call `useCreateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenarioMutation, { data, loading, error }] = useCreateScenarioMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScenarioMutation,
    CreateScenarioMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScenarioMutation,
    CreateScenarioMutationVariables
  >(CreateScenarioDocument, options);
}
export type CreateScenarioMutationHookResult = ReturnType<
  typeof useCreateScenarioMutation
>;
export type CreateScenarioMutationResult =
  Apollo.MutationResult<CreateScenarioMutation>;
export type CreateScenarioMutationOptions = Apollo.BaseMutationOptions<
  CreateScenarioMutation,
  CreateScenarioMutationVariables
>;
export const ImportGlobalAssumptionsDocument = gql`
  mutation ImportGlobalAssumptions($scenarioId: UUID!) {
    importGlobalAssumptions(scenarioId: $scenarioId) {
      id
      assumptionsStale
      assumptions {
        ...AssumptionDetails
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;
export type ImportGlobalAssumptionsMutationFn = Apollo.MutationFunction<
  ImportGlobalAssumptionsMutation,
  ImportGlobalAssumptionsMutationVariables
>;

/**
 * __useImportGlobalAssumptionsMutation__
 *
 * To run a mutation, you first call `useImportGlobalAssumptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportGlobalAssumptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importGlobalAssumptionsMutation, { data, loading, error }] = useImportGlobalAssumptionsMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useImportGlobalAssumptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportGlobalAssumptionsMutation,
    ImportGlobalAssumptionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportGlobalAssumptionsMutation,
    ImportGlobalAssumptionsMutationVariables
  >(ImportGlobalAssumptionsDocument, options);
}
export type ImportGlobalAssumptionsMutationHookResult = ReturnType<
  typeof useImportGlobalAssumptionsMutation
>;
export type ImportGlobalAssumptionsMutationResult =
  Apollo.MutationResult<ImportGlobalAssumptionsMutation>;
export type ImportGlobalAssumptionsMutationOptions = Apollo.BaseMutationOptions<
  ImportGlobalAssumptionsMutation,
  ImportGlobalAssumptionsMutationVariables
>;
export const SetOptimizationSettingsDocument = gql`
  mutation SetOptimizationSettings($request: SetOptimizationSettingsRequest!) {
    setOptimizationSettings(request: $request) {
      id
      name
      optimizationSettings {
        horizonYears
        startingSeason
        seasonWeights
      }
    }
  }
`;
export type SetOptimizationSettingsMutationFn = Apollo.MutationFunction<
  SetOptimizationSettingsMutation,
  SetOptimizationSettingsMutationVariables
>;

/**
 * __useSetOptimizationSettingsMutation__
 *
 * To run a mutation, you first call `useSetOptimizationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOptimizationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOptimizationSettingsMutation, { data, loading, error }] = useSetOptimizationSettingsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSetOptimizationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOptimizationSettingsMutation,
    SetOptimizationSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetOptimizationSettingsMutation,
    SetOptimizationSettingsMutationVariables
  >(SetOptimizationSettingsDocument, options);
}
export type SetOptimizationSettingsMutationHookResult = ReturnType<
  typeof useSetOptimizationSettingsMutation
>;
export type SetOptimizationSettingsMutationResult =
  Apollo.MutationResult<SetOptimizationSettingsMutation>;
export type SetOptimizationSettingsMutationOptions = Apollo.BaseMutationOptions<
  SetOptimizationSettingsMutation,
  SetOptimizationSettingsMutationVariables
>;
export const HideScenarioDocument = gql`
  mutation HideScenario($scenarioId: UUID!) {
    hideScenario(scenarioId: $scenarioId) {
      name
      id
      isHidden
    }
  }
`;
export type HideScenarioMutationFn = Apollo.MutationFunction<
  HideScenarioMutation,
  HideScenarioMutationVariables
>;

/**
 * __useHideScenarioMutation__
 *
 * To run a mutation, you first call `useHideScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideScenarioMutation, { data, loading, error }] = useHideScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useHideScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HideScenarioMutation,
    HideScenarioMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HideScenarioMutation,
    HideScenarioMutationVariables
  >(HideScenarioDocument, options);
}
export type HideScenarioMutationHookResult = ReturnType<
  typeof useHideScenarioMutation
>;
export type HideScenarioMutationResult =
  Apollo.MutationResult<HideScenarioMutation>;
export type HideScenarioMutationOptions = Apollo.BaseMutationOptions<
  HideScenarioMutation,
  HideScenarioMutationVariables
>;
export const UnHideScenarioDocument = gql`
  mutation UnHideScenario($scenarioId: UUID!) {
    unHideScenario(scenarioId: $scenarioId) {
      name
      id
      isHidden
    }
  }
`;
export type UnHideScenarioMutationFn = Apollo.MutationFunction<
  UnHideScenarioMutation,
  UnHideScenarioMutationVariables
>;

/**
 * __useUnHideScenarioMutation__
 *
 * To run a mutation, you first call `useUnHideScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnHideScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unHideScenarioMutation, { data, loading, error }] = useUnHideScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useUnHideScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnHideScenarioMutation,
    UnHideScenarioMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnHideScenarioMutation,
    UnHideScenarioMutationVariables
  >(UnHideScenarioDocument, options);
}
export type UnHideScenarioMutationHookResult = ReturnType<
  typeof useUnHideScenarioMutation
>;
export type UnHideScenarioMutationResult =
  Apollo.MutationResult<UnHideScenarioMutation>;
export type UnHideScenarioMutationOptions = Apollo.BaseMutationOptions<
  UnHideScenarioMutation,
  UnHideScenarioMutationVariables
>;
export const RunOptimizerDocument = gql`
  mutation RunOptimizer($request: StartOptimizationCommandRequest!) {
    runOptimizer(request: $request) {
      id
      optimizerRunInProgress
    }
  }
`;
export type RunOptimizerMutationFn = Apollo.MutationFunction<
  RunOptimizerMutation,
  RunOptimizerMutationVariables
>;

/**
 * __useRunOptimizerMutation__
 *
 * To run a mutation, you first call `useRunOptimizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunOptimizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runOptimizerMutation, { data, loading, error }] = useRunOptimizerMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRunOptimizerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunOptimizerMutation,
    RunOptimizerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunOptimizerMutation,
    RunOptimizerMutationVariables
  >(RunOptimizerDocument, options);
}
export type RunOptimizerMutationHookResult = ReturnType<
  typeof useRunOptimizerMutation
>;
export type RunOptimizerMutationResult =
  Apollo.MutationResult<RunOptimizerMutation>;
export type RunOptimizerMutationOptions = Apollo.BaseMutationOptions<
  RunOptimizerMutation,
  RunOptimizerMutationVariables
>;
export const RenameScenarioDocument = gql`
  mutation RenameScenario($request: RenameScenarioCommandRequest!) {
    renameScenario(request: $request) {
      id
      name
    }
  }
`;
export type RenameScenarioMutationFn = Apollo.MutationFunction<
  RenameScenarioMutation,
  RenameScenarioMutationVariables
>;

/**
 * __useRenameScenarioMutation__
 *
 * To run a mutation, you first call `useRenameScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameScenarioMutation, { data, loading, error }] = useRenameScenarioMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRenameScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameScenarioMutation,
    RenameScenarioMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RenameScenarioMutation,
    RenameScenarioMutationVariables
  >(RenameScenarioDocument, options);
}
export type RenameScenarioMutationHookResult = ReturnType<
  typeof useRenameScenarioMutation
>;
export type RenameScenarioMutationResult =
  Apollo.MutationResult<RenameScenarioMutation>;
export type RenameScenarioMutationOptions = Apollo.BaseMutationOptions<
  RenameScenarioMutation,
  RenameScenarioMutationVariables
>;
export const DeleteAssumptionDocument = gql`
  mutation DeleteAssumption($request: DeleteAssumptionCommandRequest!) {
    deleteAssumption(request: $request) {
      id
      localAssumptions {
        ...AssumptionDetails
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;
export type DeleteAssumptionMutationFn = Apollo.MutationFunction<
  DeleteAssumptionMutation,
  DeleteAssumptionMutationVariables
>;

/**
 * __useDeleteAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssumptionMutation, { data, loading, error }] = useDeleteAssumptionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDeleteAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssumptionMutation,
    DeleteAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAssumptionMutation,
    DeleteAssumptionMutationVariables
  >(DeleteAssumptionDocument, options);
}
export type DeleteAssumptionMutationHookResult = ReturnType<
  typeof useDeleteAssumptionMutation
>;
export type DeleteAssumptionMutationResult =
  Apollo.MutationResult<DeleteAssumptionMutation>;
export type DeleteAssumptionMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssumptionMutation,
  DeleteAssumptionMutationVariables
>;
export const AssumeDoNotDraftDocument = gql`
  mutation AssumeDoNotDraft($request: AddDoNotDraftAssumptionCommandRequest!) {
    assumeDoNotDraft(request: $request) {
      id
      localAssumptions {
        ...AssumptionDetails
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;
export type AssumeDoNotDraftMutationFn = Apollo.MutationFunction<
  AssumeDoNotDraftMutation,
  AssumeDoNotDraftMutationVariables
>;

/**
 * __useAssumeDoNotDraftMutation__
 *
 * To run a mutation, you first call `useAssumeDoNotDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssumeDoNotDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assumeDoNotDraftMutation, { data, loading, error }] = useAssumeDoNotDraftMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssumeDoNotDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssumeDoNotDraftMutation,
    AssumeDoNotDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssumeDoNotDraftMutation,
    AssumeDoNotDraftMutationVariables
  >(AssumeDoNotDraftDocument, options);
}
export type AssumeDoNotDraftMutationHookResult = ReturnType<
  typeof useAssumeDoNotDraftMutation
>;
export type AssumeDoNotDraftMutationResult =
  Apollo.MutationResult<AssumeDoNotDraftMutation>;
export type AssumeDoNotDraftMutationOptions = Apollo.BaseMutationOptions<
  AssumeDoNotDraftMutation,
  AssumeDoNotDraftMutationVariables
>;
export const AssumeDoNotDraftUntilRoundDocument = gql`
  mutation AssumeDoNotDraftUntilRound(
    $request: AddDoNotDraftUntilRoundAssumptionCommandRequest!
  ) {
    assumeDoNotDraftUntilRound(request: $request) {
      id
      localAssumptions {
        ...AssumptionDetails
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;
export type AssumeDoNotDraftUntilRoundMutationFn = Apollo.MutationFunction<
  AssumeDoNotDraftUntilRoundMutation,
  AssumeDoNotDraftUntilRoundMutationVariables
>;

/**
 * __useAssumeDoNotDraftUntilRoundMutation__
 *
 * To run a mutation, you first call `useAssumeDoNotDraftUntilRoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssumeDoNotDraftUntilRoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assumeDoNotDraftUntilRoundMutation, { data, loading, error }] = useAssumeDoNotDraftUntilRoundMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssumeDoNotDraftUntilRoundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssumeDoNotDraftUntilRoundMutation,
    AssumeDoNotDraftUntilRoundMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssumeDoNotDraftUntilRoundMutation,
    AssumeDoNotDraftUntilRoundMutationVariables
  >(AssumeDoNotDraftUntilRoundDocument, options);
}
export type AssumeDoNotDraftUntilRoundMutationHookResult = ReturnType<
  typeof useAssumeDoNotDraftUntilRoundMutation
>;
export type AssumeDoNotDraftUntilRoundMutationResult =
  Apollo.MutationResult<AssumeDoNotDraftUntilRoundMutation>;
export type AssumeDoNotDraftUntilRoundMutationOptions =
  Apollo.BaseMutationOptions<
    AssumeDoNotDraftUntilRoundMutation,
    AssumeDoNotDraftUntilRoundMutationVariables
  >;
export const AssumePlayerDraftedAtPickDocument = gql`
  mutation AssumePlayerDraftedAtPick(
    $request: AddDraftWithPickAssumptionCommandRequest!
  ) {
    assumePlayerDraftedAtPick(request: $request) {
      id
      localAssumptions {
        ...AssumptionDetails
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;
export type AssumePlayerDraftedAtPickMutationFn = Apollo.MutationFunction<
  AssumePlayerDraftedAtPickMutation,
  AssumePlayerDraftedAtPickMutationVariables
>;

/**
 * __useAssumePlayerDraftedAtPickMutation__
 *
 * To run a mutation, you first call `useAssumePlayerDraftedAtPickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssumePlayerDraftedAtPickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assumePlayerDraftedAtPickMutation, { data, loading, error }] = useAssumePlayerDraftedAtPickMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssumePlayerDraftedAtPickMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssumePlayerDraftedAtPickMutation,
    AssumePlayerDraftedAtPickMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssumePlayerDraftedAtPickMutation,
    AssumePlayerDraftedAtPickMutationVariables
  >(AssumePlayerDraftedAtPickDocument, options);
}
export type AssumePlayerDraftedAtPickMutationHookResult = ReturnType<
  typeof useAssumePlayerDraftedAtPickMutation
>;
export type AssumePlayerDraftedAtPickMutationResult =
  Apollo.MutationResult<AssumePlayerDraftedAtPickMutation>;
export type AssumePlayerDraftedAtPickMutationOptions =
  Apollo.BaseMutationOptions<
    AssumePlayerDraftedAtPickMutation,
    AssumePlayerDraftedAtPickMutationVariables
  >;
export const AssumeSignedPlayerDocument = gql`
  mutation AssumeSignedPlayer(
    $request: AddMustSignPlayerAssumptionCommandRequest!
  ) {
    assumeSignedPlayer(request: $request) {
      id
      localAssumptions {
        ...AssumptionDetails
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;
export type AssumeSignedPlayerMutationFn = Apollo.MutationFunction<
  AssumeSignedPlayerMutation,
  AssumeSignedPlayerMutationVariables
>;

/**
 * __useAssumeSignedPlayerMutation__
 *
 * To run a mutation, you first call `useAssumeSignedPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssumeSignedPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assumeSignedPlayerMutation, { data, loading, error }] = useAssumeSignedPlayerMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssumeSignedPlayerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssumeSignedPlayerMutation,
    AssumeSignedPlayerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssumeSignedPlayerMutation,
    AssumeSignedPlayerMutationVariables
  >(AssumeSignedPlayerDocument, options);
}
export type AssumeSignedPlayerMutationHookResult = ReturnType<
  typeof useAssumeSignedPlayerMutation
>;
export type AssumeSignedPlayerMutationResult =
  Apollo.MutationResult<AssumeSignedPlayerMutation>;
export type AssumeSignedPlayerMutationOptions = Apollo.BaseMutationOptions<
  AssumeSignedPlayerMutation,
  AssumeSignedPlayerMutationVariables
>;
export const AssumePlayerCutDocument = gql`
  mutation AssumePlayerCut($request: AddCutPlayerAssumptionCommandRequest!) {
    assumePlayerCut(request: $request) {
      id
      localAssumptions {
        ...AssumptionDetails
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;
export type AssumePlayerCutMutationFn = Apollo.MutationFunction<
  AssumePlayerCutMutation,
  AssumePlayerCutMutationVariables
>;

/**
 * __useAssumePlayerCutMutation__
 *
 * To run a mutation, you first call `useAssumePlayerCutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssumePlayerCutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assumePlayerCutMutation, { data, loading, error }] = useAssumePlayerCutMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssumePlayerCutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssumePlayerCutMutation,
    AssumePlayerCutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssumePlayerCutMutation,
    AssumePlayerCutMutationVariables
  >(AssumePlayerCutDocument, options);
}
export type AssumePlayerCutMutationHookResult = ReturnType<
  typeof useAssumePlayerCutMutation
>;
export type AssumePlayerCutMutationResult =
  Apollo.MutationResult<AssumePlayerCutMutation>;
export type AssumePlayerCutMutationOptions = Apollo.BaseMutationOptions<
  AssumePlayerCutMutation,
  AssumePlayerCutMutationVariables
>;
export const AssumePlayerKeptUntilDateDocument = gql`
  mutation AssumePlayerKeptUntilDate(
    $request: AddKeepUntilDateAssumptionCommandRequest!
  ) {
    assumePlayerKeptUntilDate(request: $request) {
      id
      localAssumptions {
        ...AssumptionDetails
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;
export type AssumePlayerKeptUntilDateMutationFn = Apollo.MutationFunction<
  AssumePlayerKeptUntilDateMutation,
  AssumePlayerKeptUntilDateMutationVariables
>;

/**
 * __useAssumePlayerKeptUntilDateMutation__
 *
 * To run a mutation, you first call `useAssumePlayerKeptUntilDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssumePlayerKeptUntilDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assumePlayerKeptUntilDateMutation, { data, loading, error }] = useAssumePlayerKeptUntilDateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssumePlayerKeptUntilDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssumePlayerKeptUntilDateMutation,
    AssumePlayerKeptUntilDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssumePlayerKeptUntilDateMutation,
    AssumePlayerKeptUntilDateMutationVariables
  >(AssumePlayerKeptUntilDateDocument, options);
}
export type AssumePlayerKeptUntilDateMutationHookResult = ReturnType<
  typeof useAssumePlayerKeptUntilDateMutation
>;
export type AssumePlayerKeptUntilDateMutationResult =
  Apollo.MutationResult<AssumePlayerKeptUntilDateMutation>;
export type AssumePlayerKeptUntilDateMutationOptions =
  Apollo.BaseMutationOptions<
    AssumePlayerKeptUntilDateMutation,
    AssumePlayerKeptUntilDateMutationVariables
  >;
export const AssumeSignedPlayerThatCantBeCutDocument = gql`
  mutation AssumeSignedPlayerThatCantBeCut(
    $request: AddMustSignPlayerThatCantBeCutAssumptionCommandRequest!
  ) {
    assumeSignedPlayerThatCantBeCut(request: $request) {
      id
      localAssumptions {
        ...AssumptionDetails
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;
export type AssumeSignedPlayerThatCantBeCutMutationFn = Apollo.MutationFunction<
  AssumeSignedPlayerThatCantBeCutMutation,
  AssumeSignedPlayerThatCantBeCutMutationVariables
>;

/**
 * __useAssumeSignedPlayerThatCantBeCutMutation__
 *
 * To run a mutation, you first call `useAssumeSignedPlayerThatCantBeCutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssumeSignedPlayerThatCantBeCutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assumeSignedPlayerThatCantBeCutMutation, { data, loading, error }] = useAssumeSignedPlayerThatCantBeCutMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssumeSignedPlayerThatCantBeCutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssumeSignedPlayerThatCantBeCutMutation,
    AssumeSignedPlayerThatCantBeCutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssumeSignedPlayerThatCantBeCutMutation,
    AssumeSignedPlayerThatCantBeCutMutationVariables
  >(AssumeSignedPlayerThatCantBeCutDocument, options);
}
export type AssumeSignedPlayerThatCantBeCutMutationHookResult = ReturnType<
  typeof useAssumeSignedPlayerThatCantBeCutMutation
>;
export type AssumeSignedPlayerThatCantBeCutMutationResult =
  Apollo.MutationResult<AssumeSignedPlayerThatCantBeCutMutation>;
export type AssumeSignedPlayerThatCantBeCutMutationOptions =
  Apollo.BaseMutationOptions<
    AssumeSignedPlayerThatCantBeCutMutation,
    AssumeSignedPlayerThatCantBeCutMutationVariables
  >;
export const AssumeSignedPlayerToAnyContractDocument = gql`
  mutation AssumeSignedPlayerToAnyContract(
    $request: AddSignPlayerToAnyContractAssumptionCommandRequest!
  ) {
    assumeSignedPlayerToAnyContract(request: $request) {
      id
      localAssumptions {
        ...AssumptionDetails
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;
export type AssumeSignedPlayerToAnyContractMutationFn = Apollo.MutationFunction<
  AssumeSignedPlayerToAnyContractMutation,
  AssumeSignedPlayerToAnyContractMutationVariables
>;

/**
 * __useAssumeSignedPlayerToAnyContractMutation__
 *
 * To run a mutation, you first call `useAssumeSignedPlayerToAnyContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssumeSignedPlayerToAnyContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assumeSignedPlayerToAnyContractMutation, { data, loading, error }] = useAssumeSignedPlayerToAnyContractMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssumeSignedPlayerToAnyContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssumeSignedPlayerToAnyContractMutation,
    AssumeSignedPlayerToAnyContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssumeSignedPlayerToAnyContractMutation,
    AssumeSignedPlayerToAnyContractMutationVariables
  >(AssumeSignedPlayerToAnyContractDocument, options);
}
export type AssumeSignedPlayerToAnyContractMutationHookResult = ReturnType<
  typeof useAssumeSignedPlayerToAnyContractMutation
>;
export type AssumeSignedPlayerToAnyContractMutationResult =
  Apollo.MutationResult<AssumeSignedPlayerToAnyContractMutation>;
export type AssumeSignedPlayerToAnyContractMutationOptions =
  Apollo.BaseMutationOptions<
    AssumeSignedPlayerToAnyContractMutation,
    AssumeSignedPlayerToAnyContractMutationVariables
  >;
export const AssumeMustNotSignPlayerDocument = gql`
  mutation AssumeMustNotSignPlayer(
    $request: AddMustNotSignPlayerCommandRequest!
  ) {
    assumeMustNotSignPlayer(request: $request) {
      id
      localAssumptions {
        ...AssumptionDetails
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;
export type AssumeMustNotSignPlayerMutationFn = Apollo.MutationFunction<
  AssumeMustNotSignPlayerMutation,
  AssumeMustNotSignPlayerMutationVariables
>;

/**
 * __useAssumeMustNotSignPlayerMutation__
 *
 * To run a mutation, you first call `useAssumeMustNotSignPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssumeMustNotSignPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assumeMustNotSignPlayerMutation, { data, loading, error }] = useAssumeMustNotSignPlayerMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssumeMustNotSignPlayerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssumeMustNotSignPlayerMutation,
    AssumeMustNotSignPlayerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssumeMustNotSignPlayerMutation,
    AssumeMustNotSignPlayerMutationVariables
  >(AssumeMustNotSignPlayerDocument, options);
}
export type AssumeMustNotSignPlayerMutationHookResult = ReturnType<
  typeof useAssumeMustNotSignPlayerMutation
>;
export type AssumeMustNotSignPlayerMutationResult =
  Apollo.MutationResult<AssumeMustNotSignPlayerMutation>;
export type AssumeMustNotSignPlayerMutationOptions = Apollo.BaseMutationOptions<
  AssumeMustNotSignPlayerMutation,
  AssumeMustNotSignPlayerMutationVariables
>;
export const ScenarioUpdatesDocument = gql`
  subscription ScenarioUpdates {
    scenarioUpdates {
      id
      scenarioLastUpdated
      scenarioId
    }
  }
`;

/**
 * __useScenarioUpdatesSubscription__
 *
 * To run a query within a React component, call `useScenarioUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useScenarioUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenarioUpdatesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useScenarioUpdatesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ScenarioUpdatesSubscription,
    ScenarioUpdatesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ScenarioUpdatesSubscription,
    ScenarioUpdatesSubscriptionVariables
  >(ScenarioUpdatesDocument, options);
}
export type ScenarioUpdatesSubscriptionHookResult = ReturnType<
  typeof useScenarioUpdatesSubscription
>;
export type ScenarioUpdatesSubscriptionResult =
  Apollo.SubscriptionResult<ScenarioUpdatesSubscription>;
export const ScenarioUpdatedDocument = gql`
  subscription ScenarioUpdated($id: UUID!) {
    scenarioUpdated(id: $id) {
      id
      scenarioLastUpdated
      scenarioId
    }
  }
`;

/**
 * __useScenarioUpdatedSubscription__
 *
 * To run a query within a React component, call `useScenarioUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useScenarioUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenarioUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScenarioUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ScenarioUpdatedSubscription,
    ScenarioUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ScenarioUpdatedSubscription,
    ScenarioUpdatedSubscriptionVariables
  >(ScenarioUpdatedDocument, options);
}
export type ScenarioUpdatedSubscriptionHookResult = ReturnType<
  typeof useScenarioUpdatedSubscription
>;
export type ScenarioUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<ScenarioUpdatedSubscription>;
export const GetScenarioDraftAvailabilitiesDocument = gql`
  query getScenarioDraftAvailabilities($id: UUID!) {
    scenarioById(id: $id) {
      name
      id
      baseState {
        draftAvailabilitiesStale
        draftAvailabilities {
          playerId
          pick {
            season
            overall
            round
          }
        }
      }
      clubDraftPicks {
        season
        round
        overall
      }
    }
  }
`;

/**
 * __useGetScenarioDraftAvailabilitiesQuery__
 *
 * To run a query within a React component, call `useGetScenarioDraftAvailabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioDraftAvailabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioDraftAvailabilitiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScenarioDraftAvailabilitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScenarioDraftAvailabilitiesQuery,
    GetScenarioDraftAvailabilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetScenarioDraftAvailabilitiesQuery,
    GetScenarioDraftAvailabilitiesQueryVariables
  >(GetScenarioDraftAvailabilitiesDocument, options);
}
export function useGetScenarioDraftAvailabilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScenarioDraftAvailabilitiesQuery,
    GetScenarioDraftAvailabilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetScenarioDraftAvailabilitiesQuery,
    GetScenarioDraftAvailabilitiesQueryVariables
  >(GetScenarioDraftAvailabilitiesDocument, options);
}
export type GetScenarioDraftAvailabilitiesQueryHookResult = ReturnType<
  typeof useGetScenarioDraftAvailabilitiesQuery
>;
export type GetScenarioDraftAvailabilitiesLazyQueryHookResult = ReturnType<
  typeof useGetScenarioDraftAvailabilitiesLazyQuery
>;
export type GetScenarioDraftAvailabilitiesQueryResult = Apollo.QueryResult<
  GetScenarioDraftAvailabilitiesQuery,
  GetScenarioDraftAvailabilitiesQueryVariables
>;
export const GetScenarioDocument = gql`
  query getScenario($id: UUID!) {
    scenarioById(id: $id) {
      name
      id
      createdAt
      lastUpdated
      isHidden
      optimizationSettings {
        horizonYears
        startingSeason
        seasonWeights
      }
      baseState {
        draftAvailabilitiesStale
      }
      assumptionsStale
      localAssumptions {
        ...AssumptionDetails
      }
      assumptions {
        ...AssumptionDetails
      }
      optimizerInputVersion
      optimizerRunInProgress
      optimizerRunStartTime
      optimizerRunResult {
        recordedAt
        inputVersion
      }
      optimizerRunError {
        error
        errorCode
        recordedAt
        inputVersion
      }
      clubDraftPicks {
        season
        round
        overall
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;

/**
 * __useGetScenarioQuery__
 *
 * To run a query within a React component, call `useGetScenarioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScenarioQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScenarioQuery,
    GetScenarioQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScenarioQuery, GetScenarioQueryVariables>(
    GetScenarioDocument,
    options
  );
}
export function useGetScenarioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScenarioQuery,
    GetScenarioQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScenarioQuery, GetScenarioQueryVariables>(
    GetScenarioDocument,
    options
  );
}
export type GetScenarioQueryHookResult = ReturnType<typeof useGetScenarioQuery>;
export type GetScenarioLazyQueryHookResult = ReturnType<
  typeof useGetScenarioLazyQuery
>;
export type GetScenarioQueryResult = Apollo.QueryResult<
  GetScenarioQuery,
  GetScenarioQueryVariables
>;
export const GetScenariosDocument = gql`
  query getScenarios {
    scenarios {
      name
      id
      lastUpdated
      localAssumptions {
        ...AssumptionDetails
      }
      assumptions {
        ...AssumptionDetails
      }
      optimizerRunInProgress
      optimizerRunResult {
        recordedAt
      }
      optimizerRunError {
        error
        errorCode
        recordedAt
      }
    }
  }
  ${AssumptionDetailsFragmentDoc}
`;

/**
 * __useGetScenariosQuery__
 *
 * To run a query within a React component, call `useGetScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenariosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScenariosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetScenariosQuery,
    GetScenariosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScenariosQuery, GetScenariosQueryVariables>(
    GetScenariosDocument,
    options
  );
}
export function useGetScenariosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScenariosQuery,
    GetScenariosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScenariosQuery, GetScenariosQueryVariables>(
    GetScenariosDocument,
    options
  );
}
export type GetScenariosQueryHookResult = ReturnType<
  typeof useGetScenariosQuery
>;
export type GetScenariosLazyQueryHookResult = ReturnType<
  typeof useGetScenariosLazyQuery
>;
export type GetScenariosQueryResult = Apollo.QueryResult<
  GetScenariosQuery,
  GetScenariosQueryVariables
>;
export const GetSchoolsByDivisionDocument = gql`
  query getSchoolsByDivision($division: String) {
    schools(
      order: { schoolCode: ASC }
      where: { and: [{ division: { eq: $division } }] }
    ) {
      ...School
    }
  }
  ${SchoolFragmentDoc}
`;

/**
 * __useGetSchoolsByDivisionQuery__
 *
 * To run a query within a React component, call `useGetSchoolsByDivisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsByDivisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsByDivisionQuery({
 *   variables: {
 *      division: // value for 'division'
 *   },
 * });
 */
export function useGetSchoolsByDivisionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolsByDivisionQuery,
    GetSchoolsByDivisionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolsByDivisionQuery,
    GetSchoolsByDivisionQueryVariables
  >(GetSchoolsByDivisionDocument, options);
}
export function useGetSchoolsByDivisionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolsByDivisionQuery,
    GetSchoolsByDivisionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolsByDivisionQuery,
    GetSchoolsByDivisionQueryVariables
  >(GetSchoolsByDivisionDocument, options);
}
export type GetSchoolsByDivisionQueryHookResult = ReturnType<
  typeof useGetSchoolsByDivisionQuery
>;
export type GetSchoolsByDivisionLazyQueryHookResult = ReturnType<
  typeof useGetSchoolsByDivisionLazyQuery
>;
export type GetSchoolsByDivisionQueryResult = Apollo.QueryResult<
  GetSchoolsByDivisionQuery,
  GetSchoolsByDivisionQueryVariables
>;
export const GetSchoolDocument = gql`
  query getSchool($id: UUID!) {
    schoolById(id: $id) {
      ...School
    }
  }
  ${SchoolFragmentDoc}
`;

/**
 * __useGetSchoolQuery__
 *
 * To run a query within a React component, call `useGetSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSchoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSchoolQuery, GetSchoolQueryVariables>(
    GetSchoolDocument,
    options
  );
}
export function useGetSchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolQuery,
    GetSchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSchoolQuery, GetSchoolQueryVariables>(
    GetSchoolDocument,
    options
  );
}
export type GetSchoolQueryHookResult = ReturnType<typeof useGetSchoolQuery>;
export type GetSchoolLazyQueryHookResult = ReturnType<
  typeof useGetSchoolLazyQuery
>;
export type GetSchoolQueryResult = Apollo.QueryResult<
  GetSchoolQuery,
  GetSchoolQueryVariables
>;
export const GetSchoolsDocument = gql`
  query getSchools {
    schools(order: { schoolCode: ASC }) {
      ...School
    }
  }
  ${SchoolFragmentDoc}
`;

/**
 * __useGetSchoolsQuery__
 *
 * To run a query within a React component, call `useGetSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSchoolsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolsQuery,
    GetSchoolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(
    GetSchoolsDocument,
    options
  );
}
export function useGetSchoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolsQuery,
    GetSchoolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(
    GetSchoolsDocument,
    options
  );
}
export type GetSchoolsQueryHookResult = ReturnType<typeof useGetSchoolsQuery>;
export type GetSchoolsLazyQueryHookResult = ReturnType<
  typeof useGetSchoolsLazyQuery
>;
export type GetSchoolsQueryResult = Apollo.QueryResult<
  GetSchoolsQuery,
  GetSchoolsQueryVariables
>;
export const GetGameByIdDocument = gql`
  query GetGameById($id: UUID!) {
    games(where: { id: { eq: $id } }) {
      ...Game
    }
  }
  ${GameFragmentDoc}
`;

/**
 * __useGetGameByIdQuery__
 *
 * To run a query within a React component, call `useGetGameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGameByIdQuery,
    GetGameByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGameByIdQuery, GetGameByIdQueryVariables>(
    GetGameByIdDocument,
    options
  );
}
export function useGetGameByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameByIdQuery,
    GetGameByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGameByIdQuery, GetGameByIdQueryVariables>(
    GetGameByIdDocument,
    options
  );
}
export type GetGameByIdQueryHookResult = ReturnType<typeof useGetGameByIdQuery>;
export type GetGameByIdLazyQueryHookResult = ReturnType<
  typeof useGetGameByIdLazyQuery
>;
export type GetGameByIdQueryResult = Apollo.QueryResult<
  GetGameByIdQuery,
  GetGameByIdQueryVariables
>;
export const GetTeamGamesBySeasonDocument = gql`
  query GetTeamGamesBySeason($season: Int!, $league: Int!, $team: String) {
    games(
      order: { week: ASC }
      where: {
        and: [
          { season: { eq: $season } }
          { leagueType: { id: { eq: $league } } }
        ]
        or: [{ homeTeam: { eq: $team } }, { awayTeam: { eq: $team } }]
      }
    ) {
      ...Game
    }
  }
  ${GameFragmentDoc}
`;

/**
 * __useGetTeamGamesBySeasonQuery__
 *
 * To run a query within a React component, call `useGetTeamGamesBySeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamGamesBySeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamGamesBySeasonQuery({
 *   variables: {
 *      season: // value for 'season'
 *      league: // value for 'league'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useGetTeamGamesBySeasonQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamGamesBySeasonQuery,
    GetTeamGamesBySeasonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTeamGamesBySeasonQuery,
    GetTeamGamesBySeasonQueryVariables
  >(GetTeamGamesBySeasonDocument, options);
}
export function useGetTeamGamesBySeasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamGamesBySeasonQuery,
    GetTeamGamesBySeasonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTeamGamesBySeasonQuery,
    GetTeamGamesBySeasonQueryVariables
  >(GetTeamGamesBySeasonDocument, options);
}
export type GetTeamGamesBySeasonQueryHookResult = ReturnType<
  typeof useGetTeamGamesBySeasonQuery
>;
export type GetTeamGamesBySeasonLazyQueryHookResult = ReturnType<
  typeof useGetTeamGamesBySeasonLazyQuery
>;
export type GetTeamGamesBySeasonQueryResult = Apollo.QueryResult<
  GetTeamGamesBySeasonQuery,
  GetTeamGamesBySeasonQueryVariables
>;
export const GetCareerStatsForPlayerDocument = gql`
  query GetCareerStatsForPlayer($playerId: UUID) {
    careerStats(where: { playerId: { eq: $playerId } }) {
      ...CareerStats
    }
  }
  ${CareerStatsFragmentDoc}
`;

/**
 * __useGetCareerStatsForPlayerQuery__
 *
 * To run a query within a React component, call `useGetCareerStatsForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareerStatsForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareerStatsForPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetCareerStatsForPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCareerStatsForPlayerQuery,
    GetCareerStatsForPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCareerStatsForPlayerQuery,
    GetCareerStatsForPlayerQueryVariables
  >(GetCareerStatsForPlayerDocument, options);
}
export function useGetCareerStatsForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCareerStatsForPlayerQuery,
    GetCareerStatsForPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCareerStatsForPlayerQuery,
    GetCareerStatsForPlayerQueryVariables
  >(GetCareerStatsForPlayerDocument, options);
}
export type GetCareerStatsForPlayerQueryHookResult = ReturnType<
  typeof useGetCareerStatsForPlayerQuery
>;
export type GetCareerStatsForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetCareerStatsForPlayerLazyQuery
>;
export type GetCareerStatsForPlayerQueryResult = Apollo.QueryResult<
  GetCareerStatsForPlayerQuery,
  GetCareerStatsForPlayerQueryVariables
>;
export const GetSeasonStatsForPlayerDocument = gql`
  query GetSeasonStatsForPlayer($playerId: UUID) {
    seasonStats(where: { playerId: { eq: $playerId } }) {
      ...SeasonStats
    }
  }
  ${SeasonStatsFragmentDoc}
`;

/**
 * __useGetSeasonStatsForPlayerQuery__
 *
 * To run a query within a React component, call `useGetSeasonStatsForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonStatsForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonStatsForPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetSeasonStatsForPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSeasonStatsForPlayerQuery,
    GetSeasonStatsForPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSeasonStatsForPlayerQuery,
    GetSeasonStatsForPlayerQueryVariables
  >(GetSeasonStatsForPlayerDocument, options);
}
export function useGetSeasonStatsForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSeasonStatsForPlayerQuery,
    GetSeasonStatsForPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSeasonStatsForPlayerQuery,
    GetSeasonStatsForPlayerQueryVariables
  >(GetSeasonStatsForPlayerDocument, options);
}
export type GetSeasonStatsForPlayerQueryHookResult = ReturnType<
  typeof useGetSeasonStatsForPlayerQuery
>;
export type GetSeasonStatsForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetSeasonStatsForPlayerLazyQuery
>;
export type GetSeasonStatsForPlayerQueryResult = Apollo.QueryResult<
  GetSeasonStatsForPlayerQuery,
  GetSeasonStatsForPlayerQueryVariables
>;
export const GetGameStatsForPlayerDocument = gql`
  query GetGameStatsForPlayer($playerId: UUID) {
    gameStats(where: { playerId: { eq: $playerId } }) {
      ...GameStats
    }
  }
  ${GameStatsFragmentDoc}
`;

/**
 * __useGetGameStatsForPlayerQuery__
 *
 * To run a query within a React component, call `useGetGameStatsForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameStatsForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameStatsForPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetGameStatsForPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGameStatsForPlayerQuery,
    GetGameStatsForPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGameStatsForPlayerQuery,
    GetGameStatsForPlayerQueryVariables
  >(GetGameStatsForPlayerDocument, options);
}
export function useGetGameStatsForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameStatsForPlayerQuery,
    GetGameStatsForPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGameStatsForPlayerQuery,
    GetGameStatsForPlayerQueryVariables
  >(GetGameStatsForPlayerDocument, options);
}
export type GetGameStatsForPlayerQueryHookResult = ReturnType<
  typeof useGetGameStatsForPlayerQuery
>;
export type GetGameStatsForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetGameStatsForPlayerLazyQuery
>;
export type GetGameStatsForPlayerQueryResult = Apollo.QueryResult<
  GetGameStatsForPlayerQuery,
  GetGameStatsForPlayerQueryVariables
>;
export const UpdatePositionDepthSurveyDocument = gql`
  mutation UpdatePositionDepthSurvey($newLimits: [PositionDepthLimitInput!]!) {
    updatePositionDepthSurvey(newLimits: $newLimits) {
      id
      limits {
        positionNodeId
        minimumDepth
        maximumDepth
        group
        rosterSize
      }
    }
  }
`;
export type UpdatePositionDepthSurveyMutationFn = Apollo.MutationFunction<
  UpdatePositionDepthSurveyMutation,
  UpdatePositionDepthSurveyMutationVariables
>;

/**
 * __useUpdatePositionDepthSurveyMutation__
 *
 * To run a mutation, you first call `useUpdatePositionDepthSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionDepthSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionDepthSurveyMutation, { data, loading, error }] = useUpdatePositionDepthSurveyMutation({
 *   variables: {
 *      newLimits: // value for 'newLimits'
 *   },
 * });
 */
export function useUpdatePositionDepthSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePositionDepthSurveyMutation,
    UpdatePositionDepthSurveyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePositionDepthSurveyMutation,
    UpdatePositionDepthSurveyMutationVariables
  >(UpdatePositionDepthSurveyDocument, options);
}
export type UpdatePositionDepthSurveyMutationHookResult = ReturnType<
  typeof useUpdatePositionDepthSurveyMutation
>;
export type UpdatePositionDepthSurveyMutationResult =
  Apollo.MutationResult<UpdatePositionDepthSurveyMutation>;
export type UpdatePositionDepthSurveyMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePositionDepthSurveyMutation,
    UpdatePositionDepthSurveyMutationVariables
  >;
export const UpdatePositionalImportanceSurveyDocument = gql`
  mutation UpdatePositionalImportanceSurvey(
    $newPage: PositionalImportancePageInput!
  ) {
    updatePositionalImportanceSurvey(newPage: $newPage) {
      ...PositionalImportanceSurveyFields
    }
  }
  ${PositionalImportanceSurveyFieldsFragmentDoc}
`;
export type UpdatePositionalImportanceSurveyMutationFn =
  Apollo.MutationFunction<
    UpdatePositionalImportanceSurveyMutation,
    UpdatePositionalImportanceSurveyMutationVariables
  >;

/**
 * __useUpdatePositionalImportanceSurveyMutation__
 *
 * To run a mutation, you first call `useUpdatePositionalImportanceSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionalImportanceSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionalImportanceSurveyMutation, { data, loading, error }] = useUpdatePositionalImportanceSurveyMutation({
 *   variables: {
 *      newPage: // value for 'newPage'
 *   },
 * });
 */
export function useUpdatePositionalImportanceSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePositionalImportanceSurveyMutation,
    UpdatePositionalImportanceSurveyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePositionalImportanceSurveyMutation,
    UpdatePositionalImportanceSurveyMutationVariables
  >(UpdatePositionalImportanceSurveyDocument, options);
}
export type UpdatePositionalImportanceSurveyMutationHookResult = ReturnType<
  typeof useUpdatePositionalImportanceSurveyMutation
>;
export type UpdatePositionalImportanceSurveyMutationResult =
  Apollo.MutationResult<UpdatePositionalImportanceSurveyMutation>;
export type UpdatePositionalImportanceSurveyMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePositionalImportanceSurveyMutation,
    UpdatePositionalImportanceSurveyMutationVariables
  >;
export const GetPositionalImportanceSurveyDocument = gql`
  query GetPositionalImportanceSurvey {
    positionalImportanceSurvey {
      ...PositionalImportanceSurveyFields
    }
  }
  ${PositionalImportanceSurveyFieldsFragmentDoc}
`;

/**
 * __useGetPositionalImportanceSurveyQuery__
 *
 * To run a query within a React component, call `useGetPositionalImportanceSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionalImportanceSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionalImportanceSurveyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPositionalImportanceSurveyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPositionalImportanceSurveyQuery,
    GetPositionalImportanceSurveyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPositionalImportanceSurveyQuery,
    GetPositionalImportanceSurveyQueryVariables
  >(GetPositionalImportanceSurveyDocument, options);
}
export function useGetPositionalImportanceSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPositionalImportanceSurveyQuery,
    GetPositionalImportanceSurveyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPositionalImportanceSurveyQuery,
    GetPositionalImportanceSurveyQueryVariables
  >(GetPositionalImportanceSurveyDocument, options);
}
export type GetPositionalImportanceSurveyQueryHookResult = ReturnType<
  typeof useGetPositionalImportanceSurveyQuery
>;
export type GetPositionalImportanceSurveyLazyQueryHookResult = ReturnType<
  typeof useGetPositionalImportanceSurveyLazyQuery
>;
export type GetPositionalImportanceSurveyQueryResult = Apollo.QueryResult<
  GetPositionalImportanceSurveyQuery,
  GetPositionalImportanceSurveyQueryVariables
>;
export const GetPositionDepthSurveyDocument = gql`
  query GetPositionDepthSurvey {
    positionDepthSurvey {
      id
      limits {
        positionNodeId
        minimumDepth
        maximumDepth
        group
        rosterSize
      }
    }
  }
`;

/**
 * __useGetPositionDepthSurveyQuery__
 *
 * To run a query within a React component, call `useGetPositionDepthSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionDepthSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionDepthSurveyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPositionDepthSurveyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPositionDepthSurveyQuery,
    GetPositionDepthSurveyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPositionDepthSurveyQuery,
    GetPositionDepthSurveyQueryVariables
  >(GetPositionDepthSurveyDocument, options);
}
export function useGetPositionDepthSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPositionDepthSurveyQuery,
    GetPositionDepthSurveyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPositionDepthSurveyQuery,
    GetPositionDepthSurveyQueryVariables
  >(GetPositionDepthSurveyDocument, options);
}
export type GetPositionDepthSurveyQueryHookResult = ReturnType<
  typeof useGetPositionDepthSurveyQuery
>;
export type GetPositionDepthSurveyLazyQueryHookResult = ReturnType<
  typeof useGetPositionDepthSurveyLazyQuery
>;
export type GetPositionDepthSurveyQueryResult = Apollo.QueryResult<
  GetPositionDepthSurveyQuery,
  GetPositionDepthSurveyQueryVariables
>;
export const OverrideTeamNeedsDocument = gql`
  mutation OverrideTeamNeeds(
    $orverrideTeamNeedsForClubRequest: OverrideTeamNeedsForClubRequest!
  ) {
    overrideTeamNeeds(request: $orverrideTeamNeedsForClubRequest) {
      isSumerDefault
      clubId
      positionalNeeds {
        rank
        sumerGeneralPosition
      }
    }
  }
`;
export type OverrideTeamNeedsMutationFn = Apollo.MutationFunction<
  OverrideTeamNeedsMutation,
  OverrideTeamNeedsMutationVariables
>;

/**
 * __useOverrideTeamNeedsMutation__
 *
 * To run a mutation, you first call `useOverrideTeamNeedsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverrideTeamNeedsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overrideTeamNeedsMutation, { data, loading, error }] = useOverrideTeamNeedsMutation({
 *   variables: {
 *      orverrideTeamNeedsForClubRequest: // value for 'orverrideTeamNeedsForClubRequest'
 *   },
 * });
 */
export function useOverrideTeamNeedsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OverrideTeamNeedsMutation,
    OverrideTeamNeedsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OverrideTeamNeedsMutation,
    OverrideTeamNeedsMutationVariables
  >(OverrideTeamNeedsDocument, options);
}
export type OverrideTeamNeedsMutationHookResult = ReturnType<
  typeof useOverrideTeamNeedsMutation
>;
export type OverrideTeamNeedsMutationResult =
  Apollo.MutationResult<OverrideTeamNeedsMutation>;
export type OverrideTeamNeedsMutationOptions = Apollo.BaseMutationOptions<
  OverrideTeamNeedsMutation,
  OverrideTeamNeedsMutationVariables
>;
export const GetTeamNeedsForClubDocument = gql`
  query GetTeamNeedsForClub($clubId: UUID!, $draftSeason: Int!) {
    teamNeeds(request: { clubId: $clubId, draftSeason: $draftSeason }) {
      isSumerDefault
      clubId
      positionalNeeds {
        rank
        sumerGeneralPosition
      }
    }
  }
`;

/**
 * __useGetTeamNeedsForClubQuery__
 *
 * To run a query within a React component, call `useGetTeamNeedsForClubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamNeedsForClubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamNeedsForClubQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      draftSeason: // value for 'draftSeason'
 *   },
 * });
 */
export function useGetTeamNeedsForClubQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamNeedsForClubQuery,
    GetTeamNeedsForClubQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTeamNeedsForClubQuery,
    GetTeamNeedsForClubQueryVariables
  >(GetTeamNeedsForClubDocument, options);
}
export function useGetTeamNeedsForClubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamNeedsForClubQuery,
    GetTeamNeedsForClubQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTeamNeedsForClubQuery,
    GetTeamNeedsForClubQueryVariables
  >(GetTeamNeedsForClubDocument, options);
}
export type GetTeamNeedsForClubQueryHookResult = ReturnType<
  typeof useGetTeamNeedsForClubQuery
>;
export type GetTeamNeedsForClubLazyQueryHookResult = ReturnType<
  typeof useGetTeamNeedsForClubLazyQuery
>;
export type GetTeamNeedsForClubQueryResult = Apollo.QueryResult<
  GetTeamNeedsForClubQuery,
  GetTeamNeedsForClubQueryVariables
>;
export const GetTeamNeedsPositionsDocument = gql`
  query GetTeamNeedsPositions {
    positions(positionTypeFilter: SUMER_GENERAL) {
      id
      shortName
      sideOfBall
    }
  }
`;

/**
 * __useGetTeamNeedsPositionsQuery__
 *
 * To run a query within a React component, call `useGetTeamNeedsPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamNeedsPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamNeedsPositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamNeedsPositionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTeamNeedsPositionsQuery,
    GetTeamNeedsPositionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTeamNeedsPositionsQuery,
    GetTeamNeedsPositionsQueryVariables
  >(GetTeamNeedsPositionsDocument, options);
}
export function useGetTeamNeedsPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamNeedsPositionsQuery,
    GetTeamNeedsPositionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTeamNeedsPositionsQuery,
    GetTeamNeedsPositionsQueryVariables
  >(GetTeamNeedsPositionsDocument, options);
}
export type GetTeamNeedsPositionsQueryHookResult = ReturnType<
  typeof useGetTeamNeedsPositionsQuery
>;
export type GetTeamNeedsPositionsLazyQueryHookResult = ReturnType<
  typeof useGetTeamNeedsPositionsLazyQuery
>;
export type GetTeamNeedsPositionsQueryResult = Apollo.QueryResult<
  GetTeamNeedsPositionsQuery,
  GetTeamNeedsPositionsQueryVariables
>;
export const GetClubGradingPositionsDocument = gql`
  query GetClubGradingPositions {
    positions(positionTypeFilter: CLUB_GRADING) {
      id
      shortName
      sideOfBall
    }
  }
`;

/**
 * __useGetClubGradingPositionsQuery__
 *
 * To run a query within a React component, call `useGetClubGradingPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubGradingPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubGradingPositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClubGradingPositionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClubGradingPositionsQuery,
    GetClubGradingPositionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClubGradingPositionsQuery,
    GetClubGradingPositionsQueryVariables
  >(GetClubGradingPositionsDocument, options);
}
export function useGetClubGradingPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubGradingPositionsQuery,
    GetClubGradingPositionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClubGradingPositionsQuery,
    GetClubGradingPositionsQueryVariables
  >(GetClubGradingPositionsDocument, options);
}
export type GetClubGradingPositionsQueryHookResult = ReturnType<
  typeof useGetClubGradingPositionsQuery
>;
export type GetClubGradingPositionsLazyQueryHookResult = ReturnType<
  typeof useGetClubGradingPositionsLazyQuery
>;
export type GetClubGradingPositionsQueryResult = Apollo.QueryResult<
  GetClubGradingPositionsQuery,
  GetClubGradingPositionsQueryVariables
>;
export const GetTransactionsByDateRangeDocument = gql`
  query GetTransactionsByDateRange(
    $after: String
    $before: String
    $last: Int
    $first: Int
    $fromDate: DateTime!
    $toDate: DateTime!
  ) {
    transactionsByDateRange(
      after: $after
      before: $before
      last: $last
      first: $first
      where: {
        and: [
          { transactionDate: { gte: $fromDate } }
          { transactionDate: { lte: $toDate } }
        ]
      }
    ) {
      edges {
        node {
          id
          season
          transactionDate
          transactionDescription {
            transactionDescAbbr
            transactionDesc
          }
          startClub {
            id
            clubCode
          }
          resultClub {
            id
            clubCode
          }
          potentialClub {
            id
            clubCode
          }
          initialStatus {
            transStatusDesc
            transStatusDescAbbr
            transStatusShortDesc
          }
          resultStatus {
            transStatusDesc
            transStatusDescAbbr
            transStatusShortDesc
          }
          player {
            id
            firstName
            lastName
            height
            weight
            clubGradingPosition {
              shortName
            }
            sumerAtlasPosition {
              shortName
            }
            sumerGeneralPosition {
              shortName
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetTransactionsByDateRangeQuery__
 *
 * To run a query within a React component, call `useGetTransactionsByDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsByDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsByDateRangeQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetTransactionsByDateRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >(GetTransactionsByDateRangeDocument, options);
}
export function useGetTransactionsByDateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >(GetTransactionsByDateRangeDocument, options);
}
export type GetTransactionsByDateRangeQueryHookResult = ReturnType<
  typeof useGetTransactionsByDateRangeQuery
>;
export type GetTransactionsByDateRangeLazyQueryHookResult = ReturnType<
  typeof useGetTransactionsByDateRangeLazyQuery
>;
export type GetTransactionsByDateRangeQueryResult = Apollo.QueryResult<
  GetTransactionsByDateRangeQuery,
  GetTransactionsByDateRangeQueryVariables
>;
export const GetTransactionsForPlayerDocument = gql`
  query GetTransactionsForPlayer($playerId: UUID) {
    transactionsByDateRange(where: { playerId: { eq: $playerId } }) {
      edges {
        node {
          id
          season
          transactionDate
          transactionDescription {
            transactionDescAbbr
            transactionDesc
          }
          startClub {
            id
            clubCode
          }
          resultClub {
            id
            clubCode
          }
          potentialClub {
            id
            clubCode
          }
          initialStatus {
            transStatusDesc
            transStatusDescAbbr
            transStatusShortDesc
          }
          resultStatus {
            transStatusDesc
            transStatusDescAbbr
            transStatusShortDesc
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetTransactionsForPlayerQuery__
 *
 * To run a query within a React component, call `useGetTransactionsForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsForPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetTransactionsForPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTransactionsForPlayerQuery,
    GetTransactionsForPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTransactionsForPlayerQuery,
    GetTransactionsForPlayerQueryVariables
  >(GetTransactionsForPlayerDocument, options);
}
export function useGetTransactionsForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionsForPlayerQuery,
    GetTransactionsForPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTransactionsForPlayerQuery,
    GetTransactionsForPlayerQueryVariables
  >(GetTransactionsForPlayerDocument, options);
}
export type GetTransactionsForPlayerQueryHookResult = ReturnType<
  typeof useGetTransactionsForPlayerQuery
>;
export type GetTransactionsForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetTransactionsForPlayerLazyQuery
>;
export type GetTransactionsForPlayerQueryResult = Apollo.QueryResult<
  GetTransactionsForPlayerQuery,
  GetTransactionsForPlayerQueryVariables
>;
export const AdminCreateUserDocument = gql`
  mutation AdminCreateUser($createAppUserRequest: CreateUserRequest!) {
    createUser(request: $createAppUserRequest) {
      id
    }
  }
`;
export type AdminCreateUserMutationFn = Apollo.MutationFunction<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;

/**
 * __useAdminCreateUserMutation__
 *
 * To run a mutation, you first call `useAdminCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateUserMutation, { data, loading, error }] = useAdminCreateUserMutation({
 *   variables: {
 *      createAppUserRequest: // value for 'createAppUserRequest'
 *   },
 * });
 */
export function useAdminCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateUserMutation,
    AdminCreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateUserMutation,
    AdminCreateUserMutationVariables
  >(AdminCreateUserDocument, options);
}
export type AdminCreateUserMutationHookResult = ReturnType<
  typeof useAdminCreateUserMutation
>;
export type AdminCreateUserMutationResult =
  Apollo.MutationResult<AdminCreateUserMutation>;
export type AdminCreateUserMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;
export const EditAppUserDocument = gql`
  mutation EditAppUser($editUserRequest: UpdateUserRequest!) {
    updateUser(request: $editUserRequest) {
      id
    }
  }
`;
export type EditAppUserMutationFn = Apollo.MutationFunction<
  EditAppUserMutation,
  EditAppUserMutationVariables
>;

/**
 * __useEditAppUserMutation__
 *
 * To run a mutation, you first call `useEditAppUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAppUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAppUserMutation, { data, loading, error }] = useEditAppUserMutation({
 *   variables: {
 *      editUserRequest: // value for 'editUserRequest'
 *   },
 * });
 */
export function useEditAppUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditAppUserMutation,
    EditAppUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditAppUserMutation, EditAppUserMutationVariables>(
    EditAppUserDocument,
    options
  );
}
export type EditAppUserMutationHookResult = ReturnType<
  typeof useEditAppUserMutation
>;
export type EditAppUserMutationResult =
  Apollo.MutationResult<EditAppUserMutation>;
export type EditAppUserMutationOptions = Apollo.BaseMutationOptions<
  EditAppUserMutation,
  EditAppUserMutationVariables
>;
export const ProvisionUserDocument = gql`
  mutation ProvisionUser($provisionUserRequest: ProvisionUserRequest!) {
    provisionUser(request: $provisionUserRequest) {
      id
    }
  }
`;
export type ProvisionUserMutationFn = Apollo.MutationFunction<
  ProvisionUserMutation,
  ProvisionUserMutationVariables
>;

/**
 * __useProvisionUserMutation__
 *
 * To run a mutation, you first call `useProvisionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvisionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provisionUserMutation, { data, loading, error }] = useProvisionUserMutation({
 *   variables: {
 *      provisionUserRequest: // value for 'provisionUserRequest'
 *   },
 * });
 */
export function useProvisionUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProvisionUserMutation,
    ProvisionUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProvisionUserMutation,
    ProvisionUserMutationVariables
  >(ProvisionUserDocument, options);
}
export type ProvisionUserMutationHookResult = ReturnType<
  typeof useProvisionUserMutation
>;
export type ProvisionUserMutationResult =
  Apollo.MutationResult<ProvisionUserMutation>;
export type ProvisionUserMutationOptions = Apollo.BaseMutationOptions<
  ProvisionUserMutation,
  ProvisionUserMutationVariables
>;
export const DeactivateUserDocument = gql`
  mutation DeactivateUser($deactivateUserRequest: DeactivateUserRequest!) {
    deactivateUser(request: $deactivateUserRequest) {
      id
    }
  }
`;
export type DeactivateUserMutationFn = Apollo.MutationFunction<
  DeactivateUserMutation,
  DeactivateUserMutationVariables
>;

/**
 * __useDeactivateUserMutation__
 *
 * To run a mutation, you first call `useDeactivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserMutation, { data, loading, error }] = useDeactivateUserMutation({
 *   variables: {
 *      deactivateUserRequest: // value for 'deactivateUserRequest'
 *   },
 * });
 */
export function useDeactivateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateUserMutation,
    DeactivateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateUserMutation,
    DeactivateUserMutationVariables
  >(DeactivateUserDocument, options);
}
export type DeactivateUserMutationHookResult = ReturnType<
  typeof useDeactivateUserMutation
>;
export type DeactivateUserMutationResult =
  Apollo.MutationResult<DeactivateUserMutation>;
export type DeactivateUserMutationOptions = Apollo.BaseMutationOptions<
  DeactivateUserMutation,
  DeactivateUserMutationVariables
>;
export const GetUserByIdDocument = gql`
  query GetUserById($id: UUID!) {
    userById(id: $id) {
      id
      firstName
      lastName
      emailAddress
      role {
        name
        permissions {
          ...Permission
        }
      }
    }
  }
  ${PermissionFragmentDoc}
`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserByIdQuery,
    GetUserByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options
  );
}
export function useGetUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserByIdQuery,
    GetUserByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options
  );
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<
  typeof useGetUserByIdLazyQuery
>;
export type GetUserByIdQueryResult = Apollo.QueryResult<
  GetUserByIdQuery,
  GetUserByIdQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser {
    user {
      id
      authenticatedId
      firstName
      lastName
      emailAddress
      status
      role {
        name
        permissions {
          ...Permission
        }
      }
    }
  }
  ${PermissionFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetUsersDocument = gql`
  query GetUsers {
    users {
      id
      authenticatedId
      firstName
      lastName
      emailAddress
      status
      role {
        name
        permissions {
          ...Permission
        }
      }
    }
  }
  ${PermissionFragmentDoc}
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>;
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>;
export const GetAuthorizedIntercomKeyDocument = gql`
  query GetAuthorizedIntercomKey {
    authorizedIntercomKey
  }
`;

/**
 * __useGetAuthorizedIntercomKeyQuery__
 *
 * To run a query within a React component, call `useGetAuthorizedIntercomKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorizedIntercomKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorizedIntercomKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthorizedIntercomKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAuthorizedIntercomKeyQuery,
    GetAuthorizedIntercomKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAuthorizedIntercomKeyQuery,
    GetAuthorizedIntercomKeyQueryVariables
  >(GetAuthorizedIntercomKeyDocument, options);
}
export function useGetAuthorizedIntercomKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthorizedIntercomKeyQuery,
    GetAuthorizedIntercomKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuthorizedIntercomKeyQuery,
    GetAuthorizedIntercomKeyQueryVariables
  >(GetAuthorizedIntercomKeyDocument, options);
}
export type GetAuthorizedIntercomKeyQueryHookResult = ReturnType<
  typeof useGetAuthorizedIntercomKeyQuery
>;
export type GetAuthorizedIntercomKeyLazyQueryHookResult = ReturnType<
  typeof useGetAuthorizedIntercomKeyLazyQuery
>;
export type GetAuthorizedIntercomKeyQueryResult = Apollo.QueryResult<
  GetAuthorizedIntercomKeyQuery,
  GetAuthorizedIntercomKeyQueryVariables
>;
