import React, { useEffect } from "react";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import { MsalProvider } from "@azure/msal-react";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  LogLevel,
} from "@azure/msal-browser";
import createCache, { EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { useRouter } from "next/router";
import { CustomNavigationClient } from "../services/activeDirectory/CustomNavigationClient";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/nextjs";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
  Operation,
  Observable,
  FetchResult,
  ApolloLink,
} from "@apollo/client";

import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { mergePageInfoQuery } from "../utils/pagination";
import { clientConfig, MUI_LICENSE } from "../config/config";
import { PageContainer } from "../components/design/PageContainer";
import { ThemeProvider } from "@mui/material/styles";
import { getClubAttributesFromConfig } from "../utils/clubs";
import { LicenseInfo } from "@mui/x-license-pro";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { IntercomProvider } from "react-use-intercom";
import { setThemeForUser } from "../utils/themes";
import { DraftDashboardQueryProvider } from "../components/draft/home/useDraftDashboard";
import { Client, ClientOptions, createClient } from "graphql-sse";
import { print } from "graphql";
import DatadogInit from "../components/datadog/datadog-init";

LicenseInfo.setLicenseKey(MUI_LICENSE);
const INTERCOM_APP_ID = "wnsvlbqg";

// Client-side cache, shared for the whole session of the user in the browser.
// see https://github.com/mui/material-ui/tree/7bddc86c9f658142841ac3a202d9373ac95343bf/examples/nextjs/pages
const clientSideEmotionCache = createCache({ key: "css", prepend: true });

const httpLink = createHttpLink({
  uri: `${clientConfig.services.url}/gateway-schema/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = (
    await msalInstance.acquireTokenSilent({
      scopes: clientConfig.azureDirectory.customerScopes,
    })
  ).accessToken;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

class SSELink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        {
          ...operation,
          query: print(operation.query),
        },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        }
      );
    });
  }
}

export const link = new SSELink({
  url: `${clientConfig.services.url}/marval/graphql`,
  headers: async () => {
    const token = await msalInstance.acquireTokenSilent({
      scopes: clientConfig.azureDirectory.customerScopes,
    });
    return {
      Authorization: `Bearer ${token.accessToken}`,
    };
  },
});

const splitLink = link
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      link,
      authLink.concat(httpLink)
    )
  : authLink.concat(httpLink);

// TODO: Followup for https://github.com/aws-amplify/amplify-ui/issues/1780
// A React or NJS bug is causing build to fail for some rare uses of hooks
// ApolloGQL can trigger this
const client = new ApolloClient({
  assumeImmutableResults: true,
  defaultOptions: {
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      TeamNeedsWrapper: {
        keyFields: ["clubId"],
      },
      DraftScenario: {
        fields: {
          draftOrder: {
            // Avoids a warning that apollo may not have correctly updated this array
            // tldr: it has
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing: any[], incoming: any[]) {
              return incoming;
            },
          },
          allDraftAssets: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing: any[], incoming: any[]) {
              return incoming;
            },
          },
          clubDraftAssets: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing: any[], incoming: any[]) {
              return incoming;
            },
          },
          evaluatedDraftPicks: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing: any[], incoming: any[]) {
              return incoming;
            },
          },
          currentWorkspacePlayers: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing: any[], incoming: any[]) {
              return incoming;
            },
          },
          recommendedWorkspacePlayers: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing: any[], incoming: any[]) {
              return incoming;
            },
          },
        },
      },
      Query: {
        fields: {
          // This will allow getDraftScenarioById queries to check the cache for all existing fields
          //  before going straight to the network, even if their query isn't an exact match as previous
          //  (matches draft by id)
          // in apollo terms this is called a cache redirect
          draftScenarioById: {
            read(_a, { args, toReference }) {
              return toReference({
                __typename: "DraftScenario",
                id: args?.id,
              });
            },
          },
          players: {
            // Cache results separately for these arguments
            keyArgs: [
              "$name",
              "$clubName",
              "$schoolCode",
              "$position",
              "$positionGroup",
              "$group",
              "$draftYear",
              "$sortCriteria",
            ],
          },
          playersPaged: {
            // Cache results separately for these arguments
            keyArgs: [
              "$name",
              "$clubName",
              "$schoolCode",
              "$position",
              "$positionGroup",
              "$group",
              "$draftYear",
              "$sortCriteria",
              "$after",
              "$before",
              "$first",
              "$last",
            ],
          },
          draftDashboardPlayerListWithFilters: {
            // Cache results separately for these arguments
            keyArgs: [
              "$positionFilters",
              "$draftYear",
              "$showFavorites",
              "$sortCriteria",
              "$includePlayers",
              "$excludePlayers",
              "$searchText",
            ],
            // Concatenate the incoming list items with
            // the existing list items.
            merge: mergePageInfoQuery,
          },
        },
      },
    },
  }),
});

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: clientConfig.azureDirectory.clientId,
    authority: "https://login.microsoftonline.com/common",
    // See:
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
    // for why this is blank.html
    redirectUri: "/blank.html",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message) => {
        console.log(`[${level}]${message}`);
      },
      logLevel: LogLevel.Error,
    },
  },
});

msalInstance.addEventCallback((event: EventMessage) => {
  // Clear Apollo GQL cache on logout
  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    client.clearStore();
  }

  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(payload.account);

    // If just logged-in, go ahead and reset every GQL query
    client.refetchQueries({
      include: "all",
    });

    Sentry.setUser({
      id: payload.account?.localAccountId,
      username: payload.account?.username,
    });
  }
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const clubAttributes = getClubAttributesFromConfig(clientConfig.clubCode);

function App({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: { emotionCache: EmotionCache } & AppProps) {
  const router = useRouter();
  const navigationClient = new CustomNavigationClient(router);

  msalInstance.setNavigationClient(navigationClient);
  const account = msalInstance?.getActiveAccount();
  const oid = account?.localAccountId;
  const username = account?.username;

  useEffect(() => {
    if (username) {
      Sentry.setUser({
        id: oid,
        username,
      });
    }
  }, [username, oid]);

  if (clubAttributes == null) {
    // TODO: Some initial loading state, we are deferring here until the club context is ready
    return <div></div>;
  }

  const muiTheme = setThemeForUser(clubAttributes);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={muiTheme}>
          <ApolloProvider client={client}>
            <QueryClientProvider client={queryClient}>
              <MsalProvider instance={msalInstance}>
                <IntercomProvider appId={INTERCOM_APP_ID}>
                  <DraftDashboardQueryProvider>
                    <PageContainer>
                      <DatadogInit />
                      <Component {...pageProps} />
                    </PageContainer>
                  </DraftDashboardQueryProvider>
                </IntercomProvider>
              </MsalProvider>
            </QueryClientProvider>
          </ApolloProvider>
        </ThemeProvider>
        <ToastContainer theme="dark" autoClose={2500} hideProgressBar />
      </CacheProvider>
    </LocalizationProvider>
  );
}

export default App;
