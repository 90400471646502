import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useStartDraft } from "./useStartDraft";
import { useGetActiveDrafts } from "./useGetActiveDrafts";
import { DraftTypeEnum } from "../../../graphql/generated/graphql";

export const useNavigateLiveDraft = () => {
  const router = useRouter();
  const [navigating, setNavigating] = useState(false);
  const [liveDraftError, setLiveDraftError] = useState(false);
  const { startNewDraft, createDraftLoading, createDraftError } =
    useStartDraft();
  const { activeLiveDrafts } = useGetActiveDrafts();

  const loading = navigating || createDraftLoading;
  const error = liveDraftError || !!createDraftError;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (createDraftLoading) {
      timeoutId = setTimeout(() => {
        setLiveDraftError(true);
        setNavigating(false);
      }, 10000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [createDraftLoading]);

  const startLiveDraft = () => {
    startNewDraft(DraftTypeEnum.LIVE, (data) => {
      if (data.draftScenarioCreate) {
        setNavigating(true);
        router.push(`/draft/${data.draftScenarioCreate?.id}`);
      } else {
        setLiveDraftError(true);
        setNavigating(false);
      }
    });
  };

  const handleStart = () => {
    if (activeLiveDrafts.length > 0) {
      setNavigating(true);
      const activeDraft = activeLiveDrafts[0];
      router.push(`/draft/${activeDraft?.id}`);
    } else {
      startLiveDraft();
    }
  };

  const getDaysTillDraft = (draftDate: string): number => {
    const today = new Date();
    const futureDate = new Date(draftDate);
    const diffInMs = futureDate.getTime() - today.getTime();
    const daysDiff = diffInMs / (1000 * 60 * 60 * 24);
    return Math.round(daysDiff);
  };

  // TODO: Implement dynamic draft day retrieval
  const daysTillDraft = getDaysTillDraft("April 25, 2024");

  return { handleStart, daysTillDraft, loading, error };
};
